import { createWebHistory, createRouter } from "vue-router";
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import Login from "../components/Login/Login.vue";
import InvalidAccount from "../components/error/InvalidAccount.vue";
import ApplicationError from "../components/error/ApplicationError.vue";
import store from '../store';
import Home  from "../components/home/Home.vue";

const routes = [
  {
    path: '/callback',
    component: Login
  },
  {
    path: '/',
    component: Home
  },
  {
    name: 'HomePage',
    path: '/:tenantId/:facilityId',
    component: Home
  },
  // {
  //   path: '/',
  //   redirect: '/schedule',
  //   meta: { key: 'Schedule', title: 'Schedule Maintenance' },
  // },
  {
    name: 'Schedule',
    path: '/schedule/:tenantId?/:facilityId?/:departmentId?/:scheduleId?',
    meta: { key: 'Schedule', title: 'Schedule Management - Schedule' },
    component: () => import('../components/home/ScheduleView.vue')
  },
  {
    name: 'DashboardPage',
    path: '/dashboard/:tenantId?/:facilityId?',
    component: () => import('../components/home/Home.vue')
    // component: Home
  },
  {
    name: 'StaffPage',
    path: '/staff/:tenantId?/:facilityId?',
    component: () => import('../components/home/Home.vue')
    // component: Home
  },

  {
    path: '/invalidaccount',
    component: InvalidAccount
  },
  {
    path: '/error',
    component: ApplicationError
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store))
router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.path) {
        // Start the route progress bar.
       // NProgress.start()
    }
    next()
})

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    //NProgress.done()
})

export default router;