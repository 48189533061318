import axios from 'axios'
import { DepartmentScheduleModel } from '@/models/scheduleManagement'
import {
  AssignmentCancelModel,
  AssignmentModel,
} from '@/models/scheduleManagement/shiftPanels.model'

export async function getDepartmentSchedule(
  params: any
): Promise<Array<DepartmentScheduleModel>> {
  const queryStrArr: Array<String> = []
  Object.keys(params).forEach((k) => {
    if (
      ['names'].includes(k) ||
      ['skillIds'].includes(k) ||
      ['employeeTypeIds'].includes(k)
    ) {
      queryStrArr.push(`${k}=${params[k]}`)
    }
  })

  const response = await axios.get(
    `${process.env.VUE_APP_BASE_API_URL}/Helper/${params.selectedTenant}/Schedule/${
      params.selectedScheduleId
    }?${queryStrArr.join('&')}`
  )
  return response.data
}

export async function getSkillLevels(
  params: any,
  tenantId: string
): Promise<any> {
  const response = await axios.get(
    `${process.env.VUE_APP_BASE_API_URL}/SkillLevel/ByDepartment/${
      params.departmentId
    }/${params.date.slice(0, -9)}?tenantId=${tenantId}`
  )
  return response.data
}
export async function getEmployeeIds(tenantId: string): Promise<any> {
  const response = await axios.get(
    `${process.env.VUE_APP_BASE_API_URL}/EmployeeTypes/GetEmployeeTypes/${tenantId}`
  )
  return response.data
}

export async function cancelAssignment(
  payload: any,
  assignmentId: any
): Promise<AssignmentCancelModel> {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/Assignments/${assignmentId}/cancel`,
      payload
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
export async function noteAssignment(
  payload: any
): Promise<AssignmentCancelModel> {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/NeedNotes`,
      payload
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function addAssignment(payload: AssignmentModel): Promise<any> {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/Assignments`,
      payload
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateAssignment(payload: AssignmentModel): Promise<any> {
  try {
    const response = await axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/Helper/${payload?.tenantId}/Hold/${payload?.assignmentId}`,
      payload
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
export async function deleteAssignment(assignmentId: string, tenantId: string): Promise<any> {
  try {
    const response = await axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/Schedules/Assigment/${tenantId}/${assignmentId}`
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function cancelReasons(departmentId: number): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/cancelreasons/department/${departmentId}`
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function departmentShifts(departmentId: string): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/DepartmentShifts/active/${departmentId}`
    )
    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getScheduleRequest(params: any): Promise<any> {
  const response = await axios.post(
    `${process.env.VUE_APP_BASE_API_URL}/Helper/${params.selectedTenant}/Request/${params.selectedScheduleId}/${params.partOfDayId}`
  )
  return response.data
}
export async function getUndoScheduleRequest(params: any): Promise<any> {
  const response = await axios.put(
    `${process.env.VUE_APP_BASE_API_URL}/Helper/${params.selectedTenant}/Undo/${params.selectedScheduleId}`
  )
  return response.data
}
export async function getRejctScheduleRequest(params: any): Promise<any> {
  const response = await axios.post(
    `${process.env.VUE_APP_BASE_API_URL}/Schedule/${params.selectedTenant}/reject/${params.selectedScheduleId}`
  )
  return response.data
}
