<template>
    <div v-for="(data, index) in dailyRosterExpandedReportInput" :key="data">
        <div class="dailyRosterDetail-card p-2 mb-2" style="background-color: white" :data-departmentid="data.departmentId" :data-businessday="GetBusinessDay(data.businessDay)">
            <div :class="['heading ', index != 0 ? 'dailyRoasterPrintPageBreak': '']"> 
                <div class="row">
                    <div class="col-md-5">
                        <h5>{{'(' + data.facilityCoId + ') ' + data.facilityName}}</h5> 
                    </div>
                    <div class="col-md-2 buttonGroup" v-if="index == 0">
                        <button title="Previous Date" id="btnPreviousDate" @click="onDayClick(false)">{{'<'}}</button>
                        <button title="Selected Date" id="btnSelectedDate" style="">{{GetSummarySectionDate(data.businessDay)}}</button>
                        <button title ="Next Date" id="btnNextDate" @click="onDayClick(true)">{{'>'}}</button>
                    </div>
                    <div class="col">
                        <h5 class="float-end">{{ '(' + data.departmentCode + ') ' + data.departmentName}}</h5> 
                    </div> 
                </div>
            </div> 
            <hr> 
            <template v-if="data.rosterSummary != null">
                <div class="row"> 
                    <div class="col-md-6"> 
                        <h5>Daily Roster Expanded</h5>
                        <table class="table table-bordered dailyroster-expanded-summary daily-roster-expanded-print">
                            <tbody>
                                <tr> 
                                    <td colspan="2" scope="row">{{'(' + data.facilityCoId + ') ' + data.facilityName}}</td> 
                                </tr>
                                <tr> 
                                    <td colspan="2" scope="row">{{'(' + data.departmentCode + ') ' + data.departmentName}}</td> 
                                </tr>
                                <tr> 
                                    <td scope="row">{{GetSummarySectionDate(data.businessDay)}}</td>
                                    <td scope="row">{{GetSummaryTime(data.rosterSummary.startDateTime, data.rosterSummary.endDateTime)}}</td> 
                                </tr>
                                <tr> 
                                    <td scope="row">Forecast Census: <b>{{GetForcastActualstaffTo(data.rosterSummary.forecastCensus)}}</b></td>
                                    <td scope="row">Actual Census: <b>{{GetForcastActualstaffTo(data.rosterSummary.actualCensus)}}</b> </td> 
                                </tr>
                                <tr> 
                                    <td scope="row">Staff to: <b>{{GetForcastActualstaffTo(data.rosterSummary.staffTo)}}</b></td>
                                    <td scope="row"></td>
                                </tr>
                                <template v-if="data.rosterSummary.censusNote != null">
                                    <tr> 
                                        <td colspan="2" scope="row">{{ data.rosterSummary.censusNote }}</td> 
                                    </tr>
                                </template>
                            </tbody> 
                        </table> 
                    </div> 
                    <div class="col"></div> 
                </div>
            </template>
            <template v-if="data.rosterSummary != null">
                <div class="row"> 
                    <div class="col-md-6">
                        <div class="table-responsive"> 
                            <table class="table table-bordered dailyRosterExpanded-skillsummary-grid daily-roster-expanded-print">
                                <thead> 
                                    <tr> 
                                        <td width="40%">Skill</td> 
                                        <td>Sched</td> 
                                        <td>Target</td> 
                                        <td>Diff</td> 
                                        <td>% Sched</td> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="data.rosterSummary.skillLevels != null && data.rosterSummary.skillLevels.length > 0">
                                        <template v-for="skill in data.rosterSummary.skillLevels" :key="skill">
                                            <template v-if="skill.scheduled != 0 || skill.target != 0">
                                                <tr :class="[skill.skillLevelDesc.toLowerCase() === 'total' ? 'total-row' : '']">
                                                    <td :class="[skill.skillLevelDesc.toLowerCase() === 'total' ? 'font-weight-bold' : '']">{{skill.skillLevelDesc}}</td>
                                                    <td :class="[skill.skillLevelDesc.toLowerCase() === 'total' ? 'font-weight-bold' : '']">{{skill.scheduled}}</td>
                                                    <td :class="[skill.skillLevelDesc.toLowerCase() === 'total' ? 'font-weight-bold' : '']">{{skill.target}}</td>
                                                    <td :class="[skill.skillLevelDesc.toLowerCase() === 'total' ? 'font-weight-bold' : '',
                                                                skill.difference < 0 ? 'gridUnderStaff' : skill.difference > 0 ? 'gridOverStaff' : '']">{{skill.difference}}</td>
                                                    <td :class="[skill.skillLevelDesc.toLowerCase() === 'total' ? 'font-weight-bold' : '',
                                                                skill.difference < 0 ? 'gridUnderStaff' : skill.difference > 0 ? 'gridOverStaff' : '']">{{skill.schedulePercentage}}</td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </tbody> 
                            </table>
                        </div>
                    </div> 
                    <div class="col"></div> 
                </div>
            </template>
            <div class="dailyroster-skills-census-summaries" v-if="data.assignments != null && data.assignments.length > 0">
                <div class="row" >
                    <div class="col">
                        <div class="dailyRoster-assignments-grid">
                            <div color="plain-0" class="p-0 m-0">
                                <table class="neutable daily-roster-expanded-print">
                                    <colgroup>
                                        <template v-for="column in Object.keys(columnHeaders)" :key="column">
                                            <col :style="{'width': columnHeaders[column] }" >
                                        </template>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <th v-for="column in Object.keys(columnHeaders)" :key="column">
                                                {{ column }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody align="left">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <tr  v-for="dataItem in data.assignments" :slot="column"
                                                    :key="dataItem" :class="getAssignmentClassForRow(dataItem)" @click="onEditingShift(dataItem)" class="pointer">
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.departmentCode" class="department-col">
                                                <span v-if="dataItem.origDeptId !== dataItem.departmentId">
                                                {{ dataItem.departmentCode }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentCode}}  </span>
                                              </span>
                                           <span v-else> {{ dataItem.departmentCode }}</span>
                                               <!-- {{ dataItem.departmentCode }}   -->
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td :title="dataItem.staffLastName + ' ' + dataItem.staffFirstName" class="employee-col" :class="[]">
                                                
                                                 <span :class="[(dataItem.origDeptId !== dataItem.departmentId && dataItem.origDeptId === data.departmentId)? 'strikethrough': '']"> 
                                                {{ dataItem.staffLastName + ' ' + dataItem.staffFirstName }} </span>
                                                <span v-if="dataItem.charge">
                                                    <font-awesome-icon :icon="['fa', 'user-md']" size="sm" class="chargeIcon" />
                                                </span>
                                                <span v-if="dataItem.associationType === defaults.secondaryStaff" >                                                                                        
                                                    <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                                </span> 
                                                <span v-if="dataItem.associationType === defaults.unassociatedStaff">
                                                    <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                                </span>
                                                <span v-if="dataItem.float">
                                                    <img class="associaionIcon" src="@/assets/float.png" alt="Float" />
                                                </span>     
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.credentialsExpiration">
                                                {{ dataItem.credentialsExpiration }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td :title="dataItem.skillLevelDescription">
                                                <span :class="[(dataItem.origDeptId !== dataItem.departmentId && dataItem.origDeptId === data.departmentId)? 'strikethrough': '']"> 
                                                    {{ dataItem.skillLevelDescription }} </span>
                                               
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td :title="dataItem.hours" 
                                                :class="[(dataItem.isOvertime && !dataItem.cancel) ? 'hours-col overtime': 'hours-col']">
                                                {{ dataItem.hours }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.employeeType">
                                                {{ dataItem.employeeType }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.fte">  
                                                {{ dataItem.fte }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.shiftCode"  @click="onEditingShift(dataItem)"
                                                :class="[(dataItem.overtimeShift && !dataItem.cancel) ? 'shift-col overtime': 'shift-col']">
                                                {{ dataItem.shiftCode }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td class="notes-col">
                                                <span v-if="dataItem.hasNotes">
                                                    <span class="tooltip" @mouseover="showToolTip($event)"><font-awesome-icon :icon="['fa', 'sticky-note']" size="sm"
                                                        @click="onEditingShift(dataItem)" />
                                                        <span class="tooltiptext">Notes: <br> {{ setGridDateFormat(dataItem.notedate) }} <br> {{  dataItem.note.length > 50 ? dataItem.note.replace(' ','').substring(0,50) + "..." : dataItem.note }}</span>
                                                    </span>
                                                </span>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <template v-if="data.plusMetrics != null">
                <PlusMetricsReport :plusMetricsReportInput="data.plusMetrics"
                                    :facilityName="data.facilityName"
                                    :departmentName="data.departmentName"/>
            </template>
            <template v-if="data.clockIns != null && data.clockIns.shiftClockIns != null && data.clockIns.shiftClockIns.length > 0">
                <ClockInsReport :clockInsInput="data.clockIns" />
            </template>
        </div>
    </div>
    <template v-if="isEditAssignment">
        <EditAssignment :assignmentInput="assignmentInput" :assignmentType="assignmentType" 
                    @close-modal="closeEditAssignment"/>
    </template>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import PlusMetricsReport from './PlusMetricsReport.vue';
import ClockInsReport from './ClockInsReport.vue';
import EditAssignment from './EditAssignment.vue';

class Props {
    dailyRosterExpandedReportInput!: any;
}
@Options({
    components: {
        PlusMetricsReport,
        ClockInsReport,
        EditAssignment
    }
})
export default class DailyRosterExpandedReport extends Vue.with(Props) {  
    //Data properties
    defaults: any= {
        config: {},
        numberOfSummariesToDisplayPerRow: 6,
        otherProductiveAssignment: 2,
        nonProductiveAssignment: 3,
        callAssignment:4,
        unassociatedStaff: 3,
        secondaryStaff: 2,
        timeOnlyFormat: 'hh:mm A'
    };
    columnHeaders: any = {
        Dept: "6%",
        Employee: "20%",
        Credentials: "20%",
        Skills: "13%",
        Hours: "6%",
        Type: "10%",
        FTE: "7%",
        Shift: "9%",
        Notes: "9%"
    };
    columnIcons: any = {
        Department: "none",
        Employee: "none",
        Credentials: "none",
        Skills: "none",
        Hours: "none",
        Type: "none",
        FTE: "none",
        Shift: "",
        Notes: ""
    };
    timeOnlyFormat: string = 'hh:mm A';
    numberOfSummariesToDisplayPerRow: number = 6;
    viewModel: any = {
        reportData: [],
        lastEditedAssignmentId:null
    };
    isEditAssignment: boolean = false;
    assignmentInput: any = '';
    assignmentType: any = 'View';

    //Lifecycle hooks
    mounted(){
        this.dailyRosterExpandedReportInput.forEach((data: any) => {              
            this.viewModel.reportData.push(data);
        });
    }
     
    created(){
        this.emitter.on("unloadAssignment", this.unloadAssignment);
    }

    unmounted(){
        this.emitter.off("unloadAssignment", this.unloadAssignment);
    }
    //Methods
    showToolTip(event: any){
            // eslint-disable-next-line 
            const tooltip : NodeListOf<HTMLSpanElement> = document.querySelectorAll(".tooltiptext");
            const mouseX = event.clientX;
            const mouseY = event.clientY;
            console.log(tooltip)
            if(tooltip != null){
                for(let i = 0; i < tooltip.length; i++){
                    tooltip[i].style.left = mouseX - 410 + 'px';
                    tooltip[i].style.top = mouseY - 40 + 'px';
                }
            }            
        }
    setGridDateFormat(date: any) {
        if (date != null && date != '') {
            var formattedDate: any = moment(date).format("MM/DD/YYYY");
            return formattedDate;
        }
        else {
            return '';
        }
    }
    GetBusinessDay(businessDay: any){
       return moment(businessDay).format('MM-DD-YYYY');
    }
    
    GetSummarySectionDate(businessDay: any){
        return moment(businessDay).format('MM/DD/YYYY');
    }

    GetSummaryTime(startDateTime: any, endDateTime: any){ 
        return moment(startDateTime).format(this.defaults.timeOnlyFormat) + ' to ' + moment(endDateTime).format(this.defaults.timeOnlyFormat)
    }

    GetForcastActualstaffTo(value: any){
        return typeof value === 'number' ? value : '-';
    }

    GetSkillText(skills: any){
        var skillsText = '';
        if (skills != null && skills.length > 0) {
            skills.forEach((skill: any) => {
                skillsText += skill.skillLevelDesc + ' : ' + skill.count + ' | ';
            });
        }
        return skillsText.slice(0, -3);
    }

    GetTotalSchedule(skills: any){
        var totalScheduled = 0;
        if (skills != null && skills.length > 0) {
            skills.forEach((skill: any) => {
                totalScheduled += Number(skill.count);
            });
        }
        return totalScheduled;
    }

    AssignmentStartTime(startTime: any){ 
        return moment(startTime).format(this.timeOnlyFormat);
    }

    AssignmentEndTime(endTime: any){
        return moment(endTime).format(this.timeOnlyFormat);
    }

    GetCompetencies(assignment: any){
        var competencies=[];

        if (!assignment) {
            return '';
        }

        competencies = assignment.competencies.map(function (comp: any) {
            return comp.code;
        });

        return competencies.toString(); 
    }

    GetDailyRosterSummaries(summaries: any){
        var splitSummaries = this.splitIntoChunks(summaries, this.numberOfSummariesToDisplayPerRow);
        return splitSummaries;
    }

    GetDailyRosterSummariesSkills(summaries: any){
        var skillList: any[] = [];

        if(summaries != null && summaries.length > 0){
            var skills = summaries[0].skillLevels;

            for(let i=0; i<skills.length; i++){
                var skillRow: any[] = [];
                var isMetricsAvailable: boolean = false;

                //@ts-ignore
                isMetricsAvailable = jslinq(summaries)
                    .any(function (item: any) {
                        return item.skillLevels[i].scheduled != 0 || item.skillLevels[i].target != 0;
                    });

                if(isMetricsAvailable){
                    skillRow.push(skills[i].skillLevelDesc)
                    summaries.forEach((item: any) => {
                        skillRow.push(item.skillLevels[i].scheduled)
                        skillRow.push(item.skillLevels[i].target)
                        skillRow.push(item.skillLevels[i].difference)
                    });
                    skillList.push(skillRow);
                }
            }
        }
        return skillList;
    }

    GetDailyRosterSummariesSkillsTotal(summaries: any){
        var skillList: any[] = [];
        

        skillList.push('Total');
        if(summaries != null && summaries.length > 0){
            summaries.forEach((item: any) => {
                var scheduledTotal = 0,
                    targetTotal = 0,
                    differenceTotal = 0;
                if(item.skillLevels != null &&  item.skillLevels.length > 0){
                    item.skillLevels.forEach((skill: any) => {
                        scheduledTotal += skill.scheduled;
                        targetTotal += skill.target;
                        differenceTotal += skill.difference;
                    });
                }
                skillList.push(scheduledTotal.toFixed(2));
                skillList.push(targetTotal.toFixed(2));
                skillList.push(differenceTotal.toFixed(2)); 
            });
        }
        return skillList;
    }

    splitIntoChunks(array: any, limit: any) {
        var result = [],
            copyArray = array.slice(),
            itemsInArray = true;

        while (itemsInArray) {
            result.push(copyArray.splice(0, limit));
            itemsInArray = copyArray.length;
        }

        return result;
    }
    
    getAssignmentClassForRow(assignment: any) {
        var className = '';

        if (!assignment) {
            return className;
        }

        className = assignment.cancel ? " cancelledAssignment" : "";
        className += assignment.profileCharge && assignment.charge ? " chargeAssignment" : "";
        className += assignment.float ? " floatedAssignment" : "";
        className += assignment.cancel ? " strikethrough" : "";

        switch (+assignment.productiveId) {
            case this.defaults.nonProductiveAssignment:
                className += " non-productive";
                break;
            case this.defaults.otherProductiveAssignment:
                className += " other-productive";
                break;
            case this.defaults.callAssignment:
                className += " call-assignment";
                break;
            default:
                break;
        }
        return className.trim();
    }

    
    closeEditAssignment(){
        this.unloadAssignment();
    }
    
    unloadAssignment() {
        this.isEditAssignment = false;
        this.emitter.emit('refreshDailyRoster');
    }

    onDayClick(isAddDate: any){
        this.emitter.emit('onDayClick', isAddDate);
    }

    onEditingShift(dataItem: any) {
        var assigmentId = dataItem.assignmentId;
        var departmentId = dataItem.departmentId;

        var assigmentDetails = this.findAssignmentInDepartment(departmentId, assigmentId);
        var assigmentData = this.mapAssignmentToBeUpdated(assigmentDetails);

        this.viewModel.lastEditedAssignmentId = assigmentId;           
        this.assignmentInput = assigmentData;
        this.isEditAssignment = true;
    }

    findAssignmentInDepartment (departmentId: any, assignmentId: any) {
        var reportData = this.viewModel.reportData, i;

        if (reportData ){
            for (i = 0; i < reportData.length; i++) {
                if (reportData[i].departmentId === departmentId) {
                    var assignmentInfo = this.findAssignment(reportData[i].assignments, assignmentId);
                    if(assignmentInfo != undefined)
                    {
                        assignmentInfo.facilityId = reportData[i].facilityId;
                        assignmentInfo.facilityName = reportData[i].facilityName;
                        assignmentInfo.clusterId = reportData[i].clusterId;
                        assignmentInfo.clusterName = reportData[i].clusterName;
                        assignmentInfo.facilityPriorDatesEditable = reportData[i].facilityPriorDatesEditable;
                        return assignmentInfo;
                    }
                }
            }
        }
    }

    findAssignment (assignmentList: any, assignmentId: any) {
        var i;
        if (assignmentList) {
            for (i = 0; i < assignmentList.length; i++) {
                if (assignmentList[i].assignmentId === assignmentId) {
                    return assignmentList[i];
                }
            }
        }
    }
    
    mapAssignmentToBeUpdated (a: any) {
        return {
            actualEndTime: a.actualEndTime,
            actualStartTime: a.actualStartTime,
            allSkills: "",                                                  
            assignId: a.assignmentId,
            assignmentDateChanged: null,                    
            assignmentDateCreated: null,                    
            associationType: a.associationType,
            cancelComment: a.comment,
            cancelReason: null,
            cancelReasonId: a.cancelReasonId,
            charge: a.charge,
            clusterId: a.clusterId,                           
            clusterName: a.clusterName,       
            competencies: [],
            countType: a.countTypeDescription,
            countTypeId: a.countTypeId,
            credentialCode: null,                   
            credentialNumber: null,                 
            credentialRenewalDate: null,
            credentialTrackingStatusId: 0,
            date: a.date,
            day: null,
            departmentCode: a.departmentCode,
            departmentId: a.departmentId,
            departmentName: a.departmentDescription,
            departmentShiftId: a.departmentShiftId,
            departmentSummaryId: 0,
            employeeType: a.employeeType,
            endTime: a.endTime, 
            fte: a.fte,
            facilityId: a.facilityId,
            facilityName: a.facilityName,
            firstName: a.staffFirstName,
            hasNotes: false,
            hireDate: null,                      
            hours: a.hours,
            hoursWorked: a.hoursWorked,
            isCancelled: a.cancel,
            isFloat: a.float,
            isOvertime: a.isOvertime,
            lastCanceledDate: a.lastCanceled, 
            lastFloatedDate: a.lastFloated,  
            lastName: a.staffLastName,
            name: a.employeeFullName,
            needId: a.needId,
            needStatusId: a.needStatusId,
            origDepartmentCode: a.origDepartmentCode,
            origDepartmentId: a.origDeptId,
            origDepartmentName: a.origDepartmentDescription,
            overTimeOccurence: 0,
            overtimeShift: a.overtimeShift,
            phoneNumber: a.phoneNumber,
            productiveDescription: a.productiveDescription,
            productiveId: a.productiveId,
            profileCharge: a.profileCharge,
            secondarySkills: a.secondarySkills,
            seniorityDate: a.seniorityDate,   
            shiftCode: a.shiftCode.trim(),
            skillLevelDescription: a.skillLevelDescription,
            skillLevelId: a.skillLevelId,
            skillSort: a.skillSortOrder,
            staffId: a.staffId,
            staffOrigDepartmentId: a.staffOrigDepartmentId,
            staffShift: null,
            staffVolume: 0,
            startTime: a.startTime, 
            unscheduledAbsence: a.unscheduledAbsence,
            unscheduledAbsenceOption: 0,
            actionableDepartment: {
                FacilityId: a.facilityId,
                FacilityName: a.facilityName,
                ClusterId: a.clusterId,                           
                ClusterName: a.clusterName,       
                DepartmentId: a.departmentId            
            },
            competencyCodes: null,
            config: [],
            dirty: false,
            dirtyFields: {},
            facilityConfig: { FacilityPriorDatesEditable: a.facilityPriorDatesEditable },      
            loadedFromDailyRoster: true
        };
    }
}
</script>
<style scoped>
.table-bordered {
    border: 1px solid #CED1D7;
}

.overtime {
    background-color: Red;
    padding: 5px;
    color: white;
}

.cancelledAssignment {
    color: #B33E3E !important;
    background-color: #F6DBDB !important;
}

.strikethrough {
    text-decoration: line-through !important;
}

.floatedAssignment {
    background-color: #D9EFFA !important;
}

.chargeAssignment {
    font-weight: bold !important;
}

.non-productive {
    color: #B33E3E !important;
    background-color: #DFE6E9 !important;
}

.other-productive {
    color: #B33E3E !important;
    background-color: #EFEFEF !important;
}

.call-assignment {
    color: #B33E3E !important;
    background-color: #D9EAD3 !important;
}

#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid {
    border: 1px solid #dee2e6;
    table-layout: fixed;
    display: block;
}

#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid td.gridUnderStaff,
#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid td.gridOverStaff,
#dailyRosterSearchModal table.dailyRosterExpanded-skillsummary-grid td.gridOverStaff {
        border: 1px solid #dee2e6 !important;
}

#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid td,
#dailyRosterSearchModal table.dailyRoster-skills-summary-grid td,
#dailyRosterSearchModal table.dailyRoster-assignments-grid td,
#dailyRosterSearchModal table.dailyRoster-assignments-grid th {
    font-size: 12px;
    word-wrap: break-word;
    padding: 0.3rem;
}

#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid td:nth-child(1),
#dailyRosterSearchModal table.dailyRoster-skills-summary-grid td:nth-child(1) {
    font-size: 13px;
    word-wrap: break-word;
    padding: 0.2rem;
    width: 150px;
}

#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid tr.dr-date-row > td {
    color: white;
    background-color: #004c8b;
    padding: 0.4rem;
}

#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid tr.dr-skills-heading > td,
#dailyRosterSearchModal table.dailyRosterExpanded-skillsummary-grid thead td {
    color: white;
    background-color: #004c8b;
}

#dailyRosterSearchModal table.dailyRoster-assignments-grid th, table.dailyRoster-assignments-grid td {
    font-size: 13px;
    word-wrap: break-word;
}

#dailyRosterSearchModal table.dailyRoster-assignments-grid th {
    padding: 0.4rem;
    color: white;
    background-color: #004c8b;
}

#dailyRosterSearchModal table.dailyRoster-assignments-grid td {
    padding: 0.2rem;
}

#dailyRosterSearchModal .care-assignment-sheet-grid td.overtime,
#dailyRosterSearchModal .dailyRoster-assignments-grid td.overtime {
    background-color: Red;
    display: inline-block;
    padding: 7px 10px;
}


#dailyRosterSearchModal table.dailyRosterSummaries-skill-grid > :not(:first-child) {
    border-top: 0px solid currentColor;
}

.font-weight-bold{
    font-weight: 700 !important;
}

.NumColum{
    width:40px; 
}

.neutable {
  border-collapse: collapse;
  color: #1f2532;
  table-layout: fixed;
  width: 100%;
  font-size: 0.875em;
}

.neutable th {
  text-align: left;
  font-family: "HCA-Mark-Medium", "Arial", sans-serif;
  color: #58595B;
  border-bottom: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
.neutable td {
  border-bottom: 0.063rem solid #bcbcbc;
  border-top: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
.pointer {
    cursor: pointer;
}

.chargeIcon{
    float: right; 
    font-size: 16px;
    margin-right: 5px;
}

#btnPreviousDate {
    width: 30px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-style: solid;
    border-width: 2px;
}

#btnSelectedDate {
    border-style: solid;
    border-width: 2px 0 2px 0;
}

#btnNextDate {
    width: 30px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-style: solid;
    border-width: 2px;
}

.k-pdf-export .buttonGroup { 
    display: none;
}

.tooltip {
  position: relative;
  display: contents !important;  
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #dbecf7 !important;
  color: #00435e;
  text-align: left;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem !important;
  font-weight: 600;
  overflow-wrap: break-word;
  word-break: break-word;
  position: fixed;
  z-index: 1;  
}

.tooltip .tooltiptext::after{    
    rotate: 90deg;
    content: "";
    position: absolute;
    left: 396px !important;
    transform: translateX(-110%);    
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent transparent #dbecf7 transparent !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media print {
    table.daily-roster-expanded-print td {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    table.daily-roster-expanded-print th {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    .chargeIcon{
        float: right; 
        font-size: 13px;
        margin-right: 5px;
    }

    .associaionIcon {
        margin-right: 5px;
        float: right;
        height: 15px;
        width: 15px;
    }
}
</style>