<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Reports</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="dailyRosterModal" :class="['panleBody scrollBar border txtLeft', isShowScroll ? 'contentScroll': '']" >                                
                <div id="dailyRosterSearchModal" style="display:block">
                    <div class="dailyRosterSearchModal-container" style="background-color: #EFEFEF">

                        <div id="dailyRosterSearchModal-content-loading" class="loadingDiv1" v-show="isShowLoader" style="padding: 5px;">
                            <div style="text-align: center">
                                <div class="fa-2x">
                                    <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                                </div>
                                <p><b>Processing...</b></p>
                            </div>
                        </div>

                        <div id="dailyRosterSearchModal-searchForm-row" class="noPrint">
                            <form style="font-size:13px" id="dailyRosterSearchForm">

                                <div class="row p-2 mt-2">
                                    <div class="col-md-3 mt-2 ">
                                        <label class="text-uppercase default-font-size">FILTER NAME</label>
                                        <select class="neu-select ddlFilterName" v-if="ddlFilterName" name="filter" id="filterDropdown"
                                                v-model="selectedFilter" @change="onFilterChange">
                                            <option :value="-1" selected>--Select--</option> 
                                            <option v-for="filter in filters" :value="filter.fsUserDeptGroupID" :key="filter.fsUserDeptGroupID">
                                                {{filter.name}}
                                            </option>    
                                        </select>
                                        <neu-icon v-if="ddlFilterName" id="filterArrow" class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>

                                        <input class="form-control txtFilterName" v-if="txtFilterName" v-model="txtFilterNameText" type="text" maxlength="50" placeholder="Enter Filter Name"/>
                                    </div>
                                    <div class="col-md-1">
                                        <a class="float-start dailyRosterSearchAddFilter" v-if="dailyRosterSearchAddFilter" @click="onAddFilter" href="#" style="color: black; font-size: 1em; bottom: 10%; padding-top:35px" title="Add Filter">
                                            <i aria-hidden="true">
                                                <font-awesome-icon :icon="['fas', 'plus']" size="xl" />
                                            </i>ADD
                                        </a>
                                        <a class="float-start dailyRosterSearchSaveFilter" v-if="dailyRosterSearchSaveFilter" @click="onSaveFilter" href="#" style="color: black; font-size: 1em; bottom: 10%; padding-top: 35px" title="Add Filter">
                                            <i aria-hidden="true">
                                                <font-awesome-icon :icon="['fas', 'save']" size="xl" />
                                            </i>Save
                                        </a>
                                    </div>
                                    <div class="col-md-1">
                                        <a class="float-start dailyRosterSearchCancelFilter" v-if="dailyRosterSearchCancelFilter" @click="onCancelFilter" href="#" style="color: black; font-size: 1em; bottom: 10%; padding-top: 35px" title="Add Filter">
                                            <i aria-hidden="true">
                                                <font-awesome-icon :icon="['fas', 'times']" size="xl" />
                                            </i>Cancel
                                        </a>
                                        <a class="float-end dailyRosterSearchDeleteFilter" v-if="dailyRosterSearchDeleteFilter" @click="onDeleteFilter" href="#" style="color: black; font-size: 1em; bottom: 10%; padding-top: 35px" title="Delete Filter">
                                            <i aria-hidden="true">
                                                <font-awesome-icon :icon="['fas', 'trash']" size="xl" />
                                            </i>DELETE
                                        </a>
                                    </div>
                                    <div class="col-md-1">
                                        <a class="float-end dailyRosterSearchUpdateFilter me-2 " v-if="dailyRosterSearchUpdateFilter" @click="onUpdateFilter" href="#" style="color:black; font-size: 1em;bottom: 10%; padding-top: 35px" title="Delete Filter">
                                            <i aria-hidden="true">
                                                <font-awesome-icon :icon="['fas', 'save']" size="xl" />
                                            </i>UPDATE
                                        </a>
                                    </div>
                                    <div class="col-md-5 mt-4">  
                                        <VueMultiselect  v-model="selectedReportType" 
                                                :options="reportTypes"
                                                :multiple="true"
                                                :searchable="true"
                                                :close-on-select="true"
                                                @update:modelValue="shouldAllowSelection"                     
                                                label="Text"
                                                track-by="Text"
                                                placeholder="Select reports..."
                                                id="selReportType">                            
                                        </VueMultiselect>
                                    </div>
                                    <div class="col-md-1 mt-2 noPrint">
                                        <a class="float-end dailyRosterSearchToggleBtn" href="#" style="color: black; font-size: 1.35em;" title="Hide/Show" @click="toggleFilterControlVisibility"> 
                                            <font-awesome-icon :icon="['fas', 'sliders-h']" size="lg" />
                                        </a>
                                        <a :class="['float-end exportToPdfBtn me-2', !isShowDailyRosterDetails ? 'btn-disabled' : '']"  :disabled="!isShowDailyRosterDetails"
                                            href="#" style="color:black; font-size: 1.35em; cursor: pointer" title="Export to PDF" 
                                            @click="!isShowDailyRosterDetails? null : printDailyRoster()"> 
                                            <font-awesome-icon :icon="['fas', 'file-pdf']" size="lg" />
                                        </a>                                       
                                    </div>
                                </div>

                                <div class="main-search-controls" v-show="isHideFilterControl">
                                    <div class="row p-2 mt-2">
                                        <div class="col-6">
                                            <label class="text-uppercase default-font-size">facility</label>
                                            <VueMultiselect v-model="selectedFacilities"   
                                                    :options="facilities"
                                                    :multiple="true"
                                                    :searchable="true"
                                                    :close-on-select="true"  
                                                    @update:modelValue="onFacilityChangeEvent"                        
                                                    label="Text"
                                                    track-by="Text"
                                                    placeholder="All Facilities..."
                                                    id="selFacilities">                            
                                            </VueMultiselect>
                                        </div>
                                        <div class="col-3">
                                            <label class="control-label" id="dtStart-label">START DATE</label>
                                            <Datepicker v-model="selectedStartDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate noPrint" style="width: 100%;"
                                                :enableTimePicker="false" autoApply ></Datepicker>
                                            <div class="form-control dailyRosterDate"> {{getFormattedDate(selectedStartDate)}} </div>
                                        </div>
                                        <div class="col-3">
                                            <label class="control-label" id="dtEnd-label">END DATE</label>
                                            <Datepicker v-model="selectedEndDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate noPrint" style="width: 100%;"
                                                :enableTimePicker="false" autoApply ></Datepicker>
                                            <div class="form-control dailyRosterDate"> {{getFormattedDate(selectedEndDate)}} </div>
                                        </div>
                                    </div>

                                    <div class="row p-2 mt-2">
                                        <div class="col-6">
                                            <label class="text-uppercase default-font-size">department</label>
                                            <VueMultiselect v-model="selectedDepartments"
                                                            :options="departments"
                                                            :multiple="true"
                                                            :searchable="true"
                                                            :close-on-select="true"
                                                            @update:modelValue="onDepartmentChangeEvent"  
                                                            placeholder="All Departments..."
                                                            label="Text"
                                                            track-by="Text"
                                                            id="selDepartments">
                                            </VueMultiselect>
                                        </div>
                                        <div class="col-3">  
                                            <label class="text-uppercase default-font-size">PART OF DAY</label>
                                            <select class="neu-select" name="facility" id="partOfDayDropdown"
                                                    v-model="selectedFromPartOfDay" @change="onFromPartOfDayChange"> 
                                                <option v-for="partOfDay in partOfDays" :value="partOfDay.partOfDayId" :key="partOfDay.partOfDayId">
                                                    {{partOfDay.partOfDayName}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                        </div>
                                        <div class="col-3">
                                             <label class="text-uppercase default-font-size">PART OF DAY</label>
                                            <select class="neu-select" name="partOfDay" id="partOfDayDropdown"
                                                    v-model="selectedToPartOfDay" @change="onToPartOfDayChange">
                                                <option v-for="partOfDay in partOfDays" :value="partOfDay.partOfDayId" :key="partOfDay.partOfDayId">
                                                    {{partOfDay.partOfDayName}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                        </div>
                                    </div>

                                    <div class="row p-2 mt-2">
                                        <div class="col-md-6">
                                            <label class="text-uppercase">SORT BY</label>
                                            <select class="neu-select" name="facility" id="partOfDayDropdown"
                                                    v-model="selectedSortingOption">
                                                <option v-for="sortingOption in sortingOptions" :value="sortingOption.Value" :key="sortingOption.Value">
                                                    {{sortingOption.Text}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                        </div>
                                        <div class="col-md-3">
                                            <template v-if="isShowFromTime"> 
                                                <label class="text-uppercase dailyRosterSearchForm-fromTime-lbl">TIME</label><br/>
                                                <vue-timepicker format="h:mm A" name="startTime" 
                                                    v-model="selectedFromTime"  
                                                    manual-input auto-scroll close-on-complete>
                                                </vue-timepicker>
                                            </template>
                                        </div>
                                        <div class="col-md-3">
                                            <template v-if="isShowToTime">
                                                <label class="text-uppercase dailyRosterSearchForm-toTime-lbl">TIME</label><br/>
                                                <vue-timepicker format="h:mm A" name="startTime" 
                                                    v-model="selectedToTime"  
                                                    manual-input auto-scroll close-on-complete>
                                                </vue-timepicker>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="row p-2 mt-2">  
                                        <div class="col-2">  
                                            <label class="text-uppercase default-font-size">PRINT FONT SIZE</label>
                                            <select class="neu-select" name="facility" id="partOfDayDropdown"
                                                    v-model="selectedFontSizeForPrint" > 
                                                <option class="smallTxt" value="0.5" >Small</option>    
                                                <option class="mediumTxt" value="0.6" >Medium</option> 
                                                <option class="largeTxt" value="0.7" >Large</option> 
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                        </div>  
                                        <div class="col-2">  
                                            <label class="text-uppercase default-font-size">PRINT ORIENTATION</label>
                                            <select class="neu-select" name="orientation" id="orientation"
                                                    v-model="printOrientation" > 
                                                <option :value="false" data-type="boolean" >Portrait</option>    
                                                <option :value="true" data-type="boolean" >Landscape</option> 
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                        </div>                                      
                                        <div class="col-8">  
                                            <label class="text-uppercase default-font-size">PRINT PAGE BREAK</label>
                                            <div class="pt-2">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="radio" id="pagebreak_multipage"
                                                           name="isMultiPagePrint" :value="true" data-type="boolean" 
                                                           v-model="isMultiPagePrint"  /> Print dept. on separate page 
                                                </label>
                                                <label class="form-check-label pl-4">
                                                    <input class="form-check-input" type="radio" id="pagebreak_multipage"
                                                           name="isMultiPagePrint" :value="false" data-type="boolean" 
                                                           v-model="isMultiPagePrint"  /> Print multiple dept. on single page
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="row p-2 noPrint" v-show="isHideFilterControl">  
                            <div class="col dailyRoster-action-buttons">
                                <button type="button" :class="['btn dailyRosterSearchForm-print float-end ms-2', !isShowDailyRosterDetails? 'btn-disabled' : 'btn-primary']" 
                                        :disabled="!isShowDailyRosterDetails" @click="!isShowDailyRosterDetails? null : printDailyRoster()">Print</button>
                                <button type="button" class="btn btn-primary dailyRosterSearchForm-printSelection float-end ms-2" @click="printSelection">Preview</button>
                                <button type="button" class="btn btn-light dailyRosterSearchForm-clear float-end" @click="onClearClick">Clear</button>
                            </div>
                        </div> 
                        <div id="dailyRoster-reports-container" class="dailyRoster-details-container p-2" v-if="isShowDailyRosterDetails" ref="container">
                            <CareAssignmentSheetReport :careAssignmentSheetReportInput="careAssignmentSheetReportInput" v-if="isShowCareAssignmentReport"  >
                            </CareAssignmentSheetReport>
                            <DailyRosterCompressedReport :dailyRosterCompressedReportInput="dailyRosterCompressedReportInput" v-if="isShowDailyRosterCompressedReport"  >
                            </DailyRosterCompressedReport>  
                            <DailyRosterExpandedReport :dailyRosterExpandedReportInput="dailyRosterExpandedReportInput" v-if="isShowDailyRosterExpandedReport"></DailyRosterExpandedReport>
                        </div>
                    </div>

                    <div id="confirmDialog" class="dialogueContent visibility d-none" >
                        <div class="row">
                            <div class="col-md-10 mainHeading popupHeading">
                                <span>Daily Roster Filter</span>
                            </div>
                            <div class="col-md-2">
                                <span id="closeConfirmDialog" class="k-icon k-i-close closeDialog" style="float: right; cursor: pointer;"></span>
                            </div>
                        </div>
                        <hr class="hrFirst" />
                        <div>
                            <div>
                                <span id="deleteConfirm">Are you sure you want to delete this filter?</span>
                            </div>
                            <br />
                            <div id="divDeleteActions" class="right">
                                <button type="button" id="btnSaveconfirmDialog" class="btn btn-primary btnCancelMain"><span class="btnCancelSpan">OK</span></button>
                                <button type="button" id="btnCancelconfirmDialog" class="btn btn-light btnCancelMain"><span class="btnCancelSpan">CANCEL</span></button>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="dailyroster-assigment-edit-modal-container">
                    <div class="daily-assigment-edit-modal">
                        <div id="dailyroster-assignment-container"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Toaster -->
    <div v-if="isToasterVisible"> 
        <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isConfirmModalVisible">
        <ConfirmationPopup @cancelled="isConfirmModalVisible = false"
                            @confirmed="confirmedClicked"
                            :msgValue="confirmMsgValue"></ConfirmationPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import { KeyPairModel } from '@/models/common/index';
import jslinq from "jslinq";
import { PDFExport, savePDF } from "@progress/kendo-vue-pdf";
import { markRaw, h } from 'vue';

//import model and enum
import { Profile } from '@/models/profile/index';
//Import Componenets
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ToasterPopup from '../shared/ToasterPopup.vue';
import CareAssignmentSheetReport from './CareAssignmentSheetReport.vue';
import DailyRosterCompressedReport from './DailyRosterCompressedReport.vue';
import DailyRosterExpandedReport from './DailyRosterExpandedReport.vue';
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue';
import {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox
} from "@neutron/vue";

//@ts-ignore
import VueMultiselect from 'vue-multiselect';
import ConfirmationPopup from "../shared/ConfirmationPopup.vue";

class Props {
    dailyRosterInput!: any
}

@Options({
  computed: {
    ...mapState('shared', ['facilitiesByUser', 'departmentsByUser']),
    ...mapState('dailyroster', ['partOfDayTemplates', 'allDailyRosterFilters', 'dailyRosterFilterBreakdown', 'careAssignmentRosterByDay', 'compressedRosterByDay', 'expandedRosterByDay', 'saveDailyRosterFilters', 'deleteDailyRosterFilters','updateDailyRosterFilters']),
    ...mapState('profile', ['profileData']),  
},
  components: {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox,
    Datepicker,
    VueMultiselect,
    ToasterPopup,
    CareAssignmentSheetReport,
    DailyRosterCompressedReport,
    DailyRosterExpandedReport,
    VueTimepicker,
    ConfirmationPopup
  }
})
export default class DailyRoster extends Vue.with(Props) {
    //Data properties
    dailyRosterInputData: any = null;
    facilitiesByUser!: any;
    departmentsByUser!: any;
    careAssignmentRosterByDay!: any;
    dailyRosterFilterBreakdown!: any;
    compressedRosterByDay!: any;
    expandedRosterByDay!: any;
    facilities: any = [];
    selectedFacilities: any = null;
    allDepartments: any = [];
    departments: any = [];
    selectedDepartments: any = null;
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    partOfDayTemplates: any;
    partOfDays: any = [];
    selectedFromPartOfDay: any = null;
    selectedToPartOfDay: any = null;
    startDate: Date = new Date();
    endDate: Date = new Date();
    selectedStartDate: any = new Date();
    selectedEndDate: any = new Date();
    allDailyRosterFilters: any;
    filters: any = [];
    selectedFilter: any = -1;
    reportTypes: any = [];
    selectedReportType: any = [];
    sortingOptions: any = [];
    selectedSortingOption: any = null;
    isShowLoader: boolean = false;
    isShowDailyRosterDetails: boolean = false;
    selectedFromTime: any = {
        "h": "",
        "mm": "",
        "A": ""
    };
    selectedToTime: any = {
        "h": "",
        "mm": "",
        "A": ""
    }
    isHideFilterControl: boolean = true;
    careAssignmentSheetReportInput: any[] = [];
    isShowCareAssignmentReport: boolean = false;
    dailyRosterCompressedReportInput: any[] = []; 
    isShowDailyRosterCompressedReport: boolean = false;
    dailyRosterExpandedReportInput: any[] = []; 
    isShowDailyRosterExpandedReport: boolean = false;
    isShowFromTime: boolean = false;
    isShowToTime: boolean = false;
    
    ddlFilterName: boolean = true;
    txtFilterName: boolean = false;
    dailyRosterSearchAddFilter: boolean = true;
    dailyRosterSearchSaveFilter: boolean = false;
    dailyRosterSearchCancelFilter: boolean = false;
    dailyRosterSearchDeleteFilter: boolean = false;
    dailyRosterSearchUpdateFilter: boolean = false;

    txtFilterNameText: any = '';
    saveDailyRosterFilters!: any;
    deleteDailyRosterFilters!: any;
    isConfirmModalVisible:boolean = false;
    confirmMsgValue!: string;
    filterIdTodelete: number = 0;
    updateDailyRosterFilters!: any;
    profileData!: Profile;
    selectedFontSizeForPrint: any = "0.5";
    printOrientation: boolean = false;

    isShowScroll:boolean = true;
    isMultiPagePrint:boolean = false;
    defaults: any = {
        serverSideDateFormat: 'YYYY-MM-DDTHH:mm:ss',
        dateOnlyFormat: 'MM/DD/YYYY',
        partOfDayIds: [1, 2, 3],
        timePartOfDay: 0,
        daysPartOfday: 1,
        nightsPartOfday: 3,
        partOfDayTime: moment(new Date().toDateString()).format('YYYY-MM-DD HH:MM A'),
        errorMessages: {
            errorRetrievingReportData: "An error has occurred while processing your request. We apologize for any inconvenience this has caused. Please try refreshing the page again, and if the error persists, please contact Facility Scheduler support.",
            errorRetrievingPartOfDays: "Unable to retrieve Part of Days",
            invalidReportSelection: "User may select only 1 report between Daily Roster Compressed, Care Assignment Sheet and Daily Roster Expanded.",
            errorLoadingFilters: "Error occurred while retrieving Daily Roster Filters."
        },
        badRequestErrorCode: 400,
        reportType: {
            dailyRosterCompressed: 1,
            ppdDetails: 2,
            clockIns:3,
            careAssignmentSheetView: 4,
            dailyRosterExpanded: 5,
        },
        defaultFilteritem: {
            fsUserDeptGroupId: 0,
            name: '--Select--'
        },
        defaultReport: [{ Value: 1, Text: "Daily Roster Compressed" }]
    }

    //Lifecycle hooks
    async mounted(){
        await this.loadDailyRoster();
        this.loadDefaultData();
        if(this.$route.query.Source=='ScheduleBrowse')
        {
            this.selectedToPartOfDay=this.defaults.nightsPartOfday;
            this.selectedReportType=this.defaults.defaultReport;
            this.printSelection();
            this.$router.replace({query:undefined});
        }
    }

    created(){
        this.emitter.on("refreshDailyRoster", this.refreshDailyRoster);  
        this.emitter.on("onDayClick", this.onDayClick);   
    }

    unmounted(){
        this.emitter.off("refreshDailyRoster", this.refreshDailyRoster);  
        this.emitter.on("onDayClick", this.onDayClick);        
    }

    //Methods
    refreshDailyRoster(){
        this.printSelection();
    }

    onDayClick(isAddDate: any){
        this.selectedStartDate = this.calculateDate(this.selectedStartDate, isAddDate);
        this.selectedEndDate = this.calculateDate(this.selectedEndDate, isAddDate);
        this.printSelection();
    }

    calculateDate(date: any, isAddDate: any){
        return moment(date).add(isAddDate ? 1 : -1, 'days').format('MM/DD/YYYY');
    }

    async loadDailyRoster(){

        this.dailyRosterInputData = {
            userId: this.dailyRosterInput.userId,
            facilities: this.dailyRosterInput.facilities,
            departments: this.dailyRosterInput.departments,
            tenantId: this.dailyRosterInput.tenantId
        }
        // this.isShowTabList = true;
        await this.loadFacilities();
        await this.loadDepartments();
        await this.loadPartOfDays();
        await this.loadFilters();
        this.bindReportTypes();
        this.bindSortBy();
    }

    loadDefaultData(){
        this.selectedSortingOption = this.sortingOptions[2].Value;
        this.selectedStartDate = moment(this.dailyRosterInput.startDate).format(this.defaults.dateOnlyFormat);
        this.selectedEndDate = moment(this.dailyRosterInput.endDate).format(this.defaults.dateOnlyFormat);
        this.selectedFilter = -1;
        this.isShowDailyRosterDetails = false;
        this.selectedReportType = [];

        if (this.dailyRosterInput.isDefaultReport) {
            this.selectedReportType =  this.defaults.defaultReport;

            //from partofday
            this.selectedFromPartOfDay = this.defaults.daysPartOfday;

            //to partofday
            this.selectedToPartOfDay = this.defaults.nightsPartOfday;

            this.printSelection();
        }
        else{
            this.selectedFromPartOfDay = this.defaults.daysPartOfday;
            this.selectedToPartOfDay = this.defaults.daysPartOfday;
        }
    }

    findFirst(array: any, fn: any) {
        var found;
        for (var i = 0; i < array.length; i++) {
            var elm = array[i];
            if (fn(elm)) {
                found = elm;
                break;
            }
        }
        return found;
    }

    bindReportTypes(){
        var reportTypeList: any =  [
            { reportId: 1, reportName: "Daily Roster Compressed" },
            { reportId: 4, reportName: "Care Assignment Sheet" },
            { reportId: 5, reportName: "Daily Roster Expanded" },
            { reportId: 2, reportName: "PPD Details" },
            { reportId: 3, reportName: "Clock-In Details" }
        ];

        var reportTypes: any = [];
        reportTypeList.forEach((reportType: any) => {
            reportTypes.push({
                Text: reportType.reportName,
                Value: reportType.reportId,
            });
        });
        this.reportTypes = reportTypes;
    }

    bindSortBy(){
        var sortingOptionsList: any = [
            { sortingOptionId: 1, description: "Status, Productive, Skill, Name, Start" },
            { sortingOptionId: 2, description: "Seniority Date, Status, Productive, Skill, Name, Start" },
            { sortingOptionId: 3, description: "Shift, Status, Productive, Skill, Name, Start" },
            { sortingOptionId: 4, description: "Start, Shift, Status, Productive, Skill, Name" }
        ];

        var sortingOptions: any = [];
        sortingOptionsList.forEach((reportType: any) => {
            sortingOptions.push({
                Text: reportType.description,
                Value: reportType.sortingOptionId,
            });
        });
        this.sortingOptions = sortingOptions;
    }

    async loadFilters(){
        var inputData = {
            UserId: this.dailyRosterInputData.userId
        }
        await this.$store
            .dispatch("dailyroster/getAllDailyRosterFilters", inputData)
            .then(() => {
                if(this.allDailyRosterFilters != null && this.allDailyRosterFilters.length > 0){
                    this.filters = this.allDailyRosterFilters;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Daily Roster Filters.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadPartOfDays(){
        var inputData = {
         
         TenantId:  this.dailyRosterInputData.tenantId
     }
        await this.$store
            .dispatch("dailyroster/getPartOfDayTemplates",inputData)
            .then(() => {
                if(this.partOfDayTemplates != null && this.partOfDayTemplates.length > 0){
                    var partOfdays: any[] = [];
                    this.partOfDayTemplates.forEach((item: any) =>{
                        if(this.defaults.partOfDayIds.indexOf(item.partOfDayId) >= 0){
                            partOfdays.push(item);
                        }
                    });

                    partOfdays.push({ partOfDayId: 0, partOfDayName: 'Time' });

                    this.partOfDays = partOfdays;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Part of Day.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }
    onFacilityChangeEvent(){
        this.refreshDepartmentList();
        this.selectedDepartments = null;
        this.dailyRosterSearchUpdateFilter = (this.selectedFilter != -1 && this.ddlFilterName) ? true : false;
    }

    onDepartmentChangeEvent() {
        this.dailyRosterSearchUpdateFilter = (this.selectedFilter != -1 && this.ddlFilterName) ? true : false;
    }

    getDistinct(collection: KeyPairModel[] | null) {
        if (collection != null) {
            //@ts-ignore
            var results = jslinq(collection)
                .select(function (item: any) {
                    return { Value: item.Value, Text: item.Text };
                })
                .distinct()
                .orderBy(function (item: any) {
                    return item.Value;
                })
                .toList();

            return results;
        }
        else {
            return [];
        }
    }

    refreshDepartmentList() {
        var selectedFacilities = this.selectedFacilities;

        if (selectedFacilities) {
            //@ts-ignore
            var filteredDepartments = this.sortAscByText(this.getDistinct(
                this.getDepartments(selectedFacilities, this.allDepartments)));
            this.departments = filteredDepartments;

        } else {
            this.departments = this.allDepartments;
        }
    }

    getDepartments(selectedFaciities: any[], collection: KeyPairModel[] | null) {
        var result: any[] = [];

        for (var i = 0; i < selectedFaciities.length; i++) {
            var facilityId = selectedFaciities[i].FacilityId;

            //@ts-ignore
            var items = jslinq(collection).where(function (item) { return item.FacilityId === facilityId; }).distinct().toList();

            items.forEach((value) => { result.push(value); });
        }

        result = this.sortAscByText(result);

        return result;
    }


    sortAscByText(collection: KeyPairModel[]) {
            //@ts-ignore
            var result = jslinq(collection).distinct().orderBy(function (item: any) {
                return item.Text;
            }).toList();

            return result;
        }

    async loadFacilities(){
        var inputData = {
            UserId: this.dailyRosterInputData.userId,            
            TenantId: this.dailyRosterInputData.tenantId
        }
        await this.$store
            .dispatch("shared/getFacilitiesByUser", inputData)
            .then(() => {
                if(this.facilitiesByUser != null && this.facilitiesByUser.length > 0){
                    var facilitiesByUser = this.facilitiesByUser;
                    var facilityIds = this.dailyRosterInputData.facilities.map((facility: any) => { return +facility.Value; });
                    var facilities: any = [];
                    facilitiesByUser = this.sortBy(this.facilitiesByUser, "name", "asc");
                    facilitiesByUser.forEach((facility: any) => {
                        if (facilityIds.indexOf(+facility.facilityId) >= 0) {
                            facilities.push({
                                FacilityId: facility.facilityId,
                                Text: facility.name + ' - ' + facility.coid,
                                Value: facility.facilityId,
                                Coid: facility.coid
                            });
                        }
                    });
                    this.facilities = facilities;
                    if(this.dailyRosterInput.selectedFacilities != null && this.dailyRosterInput.selectedFacilities.length > 0){
                        let facilityList: any[] = [];
                        this.dailyRosterInput.selectedFacilities.forEach((facility: any) =>{
                            //@ts-ignore
                            var selectFacility = jslinq(this.facilities)
                                                .where((item: any) => {
                                                    return item.FacilityId == Number(facility.Value);
                                                }).firstOrDefault();
                            facilityList.push(selectFacility);
                        });
                        this.selectedFacilities = facilityList;
                    }
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Facility.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

     async loadDepartments(){
        var inputData = {
            UserId: this.dailyRosterInputData.userId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                if(this.departmentsByUser != null && this.departmentsByUser.length > 0){
                    var finalDepartments: any = [];
                    var departmentIds = this.dailyRosterInputData.departments.map((department: any) => { return +department.Value; });
                    var departmentsByUser = this.departmentsByUser;

                    //Sorting based on dept code
                    departmentsByUser.sort(this.GetSortOrder("DepartmentCode"));

                    departmentsByUser.forEach((department: any) => {
                        if (departmentIds.indexOf(+department.DepatmentId) >= 0) {
                            finalDepartments.push({
                                FacilityId: department.FacilityId,
                                Text:  department.DepartmentCode + ' - ' + department.DepartmentName,
                                Value: department.DepatmentId,
                                DepartmentCode: department.DepartmentCode
                            });
                        }
                    });

                    this.allDepartments = finalDepartments;
                    this.refreshDepartmentList();
                    if(this.dailyRosterInput.selectedDepartments != null && this.dailyRosterInput.selectedDepartments.length > 0){
                        let deptList: any[] = [];
                        this.dailyRosterInput.selectedDepartments.forEach((dept: any) =>{
                            //@ts-ignore
                            var selectedDeptment = jslinq(this.departments)
                                                .where((item: any) => {
                                                    return item.Value == Number(dept.Value);
                                                }).firstOrDefault();
                            deptList.push(selectedDeptment);
                        });
                        this.selectedDepartments = deptList;
                    }
                }
            })
            .catch((err: any) => {
                if (err) {
                     var errorData = {
                            message: "An error occured while retrieving Department.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }
    GetSortOrder(prop: any) {
        return function (a: any, b: any) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            } return 0;
        }
    }
    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }
    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }
    close(){
        this.$emit("close-modal");
    }

    isSelectedReport(reportId: any) {
        var selectedReports = this.selectedReportType;
        for (var i = 0; i < selectedReports.length; i++) {
            if (selectedReports[i].Value === reportId) {
                return true;
            }
        }
        return false;
    }

    showPageLoader() {
        this.isShowLoader = true;
    }

    hidePageLoader() {
        this.isShowLoader = false;
    }

    isFilterFormValid(){
        var isValid = true;

        if (this.selectedDepartments == null || this.selectedDepartments.length <= 0) {
            this.showErrorMessage('You must select a valid department before processing your request.');
            return false;
        }

        if (this.selectedReportType == null || this.selectedReportType.length <= 0) {
            this.showErrorMessage('You must select a valid report before processing your request.');
            return false;
        }


        if (!this.isDateRangeValid(moment(this.selectedStartDate).format(this.defaults.dateOnlyFormat), moment(this.selectedEndDate).format(this.defaults.dateOnlyFormat))) {
            this.showErrorMessage('End Date must be greater than Start Date.')
            return false;
        }

        if (this.selectedFromPartOfDay === this.defaults.timePartOfDay
            && this.selectedToPartOfDay === this.defaults.timePartOfDay
            && (this.selectedFromTime.h == "" || this.selectedFromTime.mm == "" || this.selectedFromTime.A == "")
            && (this.selectedToTime.h == "" || this.selectedToTime.mm == "" || this.selectedToTime.A == "")) {
            this.showErrorMessage("Please select a valid 'From' time and 'To' time");
            return false;
        }

        if (this.selectedFromPartOfDay === this.defaults.timePartOfDay
            && (this.selectedFromTime.h == "" || this.selectedFromTime.mm == "" || this.selectedFromTime.A == "")) {
            this.showErrorMessage("Please select a valid 'From' time");
            return false;
        }

        if (this.selectedToPartOfDay === this.defaults.timePartOfDay
            && (this.selectedToTime.h == "" || this.selectedToTime.mm == "" || this.selectedToTime.A == "")) {
            this.showErrorMessage("Please select a valid 'To' time");
            return false;
        }

        var isSameDay = this.isSameDay(moment(this.selectedStartDate).format(this.defaults.dateOnlyFormat), moment(this.selectedEndDate).format(this.defaults.dateOnlyFormat));

        if (isSameDay
            && this.selectedFromPartOfDay !== this.defaults.timePartOfDay
            && this.selectedToPartOfDay !== this.defaults.timePartOfDay
            && this.selectedFromPartOfDay > this.selectedToPartOfDay) {
            this.showErrorMessage("From 'Part of Day' must be set earlier than To 'Part of Day' ")
            return false;
        }

        if (this.selectedFromPartOfDay === this.defaults.timePartOfDay  && this.selectedToPartOfDay === this.defaults.timePartOfDay) {
            var fromDt = moment(this.selectedStartDate).format(this.defaults.dateOnlyFormat);
            var selectedFromTimeTxt = this.selectedFromTime.h + ':'+ this.selectedFromTime.mm + ' ' + this.selectedFromTime.A;
            var fromDtTime = moment(selectedFromTimeTxt, ["h:mm A"]).format('HH:mm');
            var fromDtAndTime = moment(fromDt + ' ' + fromDtTime).format('MM/DD/YYYY HH:mm');

            var toDt = moment(this.selectedEndDate).format(this.defaults.dateOnlyFormat);
            var selectedToTimeTxt = this.selectedToTime.h + ':'+ this.selectedToTime.mm + ' ' + this.selectedToTime.A;
            var toDtTime = moment(selectedToTimeTxt, ["h:mm A"]).format('HH:mm');
            var toDtAndTime = moment(toDt + ' ' + toDtTime).format('MM/DD/YYYY HH:mm');

            if (!moment(fromDtAndTime).isValid() && !moment(toDtAndTime).isValid()) {
                this.showErrorMessage("Please select a valid 'From' time and 'To' time");
                return false;
            } else if (!moment(fromDtAndTime).isValid()) {
                this.showErrorMessage("Please select a valid 'From' time");
                return false;
            } else if (!moment(toDtAndTime).isValid()) {
                this.showErrorMessage("Please select a valid 'To' time");
                return false;
            }

            if (!this.isDateRangeValid(fromDtAndTime, toDtAndTime)) {
                this.showErrorMessage("'From' time must be set earlier than 'To' time");
                return false;
            }
        }
        return isValid;
    }

    isDateRangeValid(startDatTime: any, endDateTime: any) {
        if (startDatTime && endDateTime) {
            var fromDate = moment(startDatTime);
            var toDate = moment(endDateTime);
            return fromDate.isSameOrBefore(toDate);
        }
    }

    isSameDay(startDatTime: any, endDateTime: any) {
        if (startDatTime && endDateTime) {
            var fromDate = moment(startDatTime);
            var toDate = moment(endDateTime);
            return fromDate.isSame(toDate);
        }
    }

    showErrorMessage(msg: string){
        this.msgValue = msg;
        this.classFlag = 'error';
        this.isToasterVisible = true;
    }

    printSelection(){
        if (!this.isFilterFormValid()) {
            return;
        }

        this.hideAllReport();
        this.showPageLoader();

        if (this.isSelectedReport(this.defaults.reportType.careAssignmentSheetView)) {
            this.getCareAssignmentSheetBreakdownByDay();
        } else if (this.isSelectedReport(this.defaults.reportType.dailyRosterExpanded)) {
            this.getDailyRosterBreakdownByDay();
        } else {
            this.getDailyRosterCompressedBreakdownByDay();
        }
    }

    hideAllReport(){
        this.isShowCareAssignmentReport = false;
        this.isShowDailyRosterCompressedReport = false;
        this.isShowDailyRosterExpandedReport = false;
        this.dailyRosterCompressedReportInput = [];
        this.careAssignmentSheetReportInput = [];
        this.dailyRosterExpandedReportInput = [];
    }

    onClearClick(){
        this.selectedDepartments = [];
        this.selectedFacilities = [];
        this.selectedStartDate = moment(new Date()).format('YYYY-MM-DD');
        this.selectedEndDate = moment(new Date()).format('YYYY-MM-DD');

        this.selectedReportType = [];

        this.selectedFromPartOfDay = this.defaults.daysPartOfday;
        this.selectedToPartOfDay = this.defaults.daysPartOfday;

        this.resetPartOfDaysTimeInputs();
    }

    resetPartOfDaysTimeInputs() {
        this.isShowFromTime = false;
        this.selectedFromTime = {
            "h": "",
            "mm": "",
            "A": ""
        };

        this.isShowToTime = false;
        this.selectedToTime = {
            "h": "",
            "mm": "",
            "A": ""
        };
    }

    onFromPartOfDayChange() {
        if (this.selectedFromPartOfDay === this.defaults.timePartOfDay) {
            this.selectedFromPartOfDay = this.defaults.timePartOfDay;
            this.isShowFromTime = true;
        } else {
            this.isShowFromTime = false;
            this.selectedFromTime = {
                "h": "",
                "mm": "",
                "A": ""
            };
        }
    }

    onToPartOfDayChange() {
        if (this.selectedToPartOfDay === 0) {
            this.isShowToTime = true;
        } else {
            this.isShowToTime = false;
            this.selectedToTime = {
                "h": "",
                "mm": "",
                "A": ""
            };
        }
    }

    clearDailyRosterData(){
        this.isShowDailyRosterDetails = false;
    }

    showDailyRosterData(){
        this.isShowDailyRosterDetails = true;
    }

    createDailyRosterRequestModel() {
        var selectedDepartments = this.selectedDepartments,
            fromPartOfDay = this.selectedFromPartOfDay,
            toPartOfDay = this.selectedToPartOfDay,
            fromPartOfDayTime = this.selectedFromTime.h + ':'+ this.selectedFromTime.mm + ' ' + this.selectedFromTime.A,
            toPartOfDayTime = this.selectedToTime.h + ':'+ this.selectedToTime.mm + ' ' + this.selectedToTime.A,
            fromTimeSelected = fromPartOfDay === 0,
            toTimeSelected = toPartOfDay === 0;

        return {
            Departments: selectedDepartments.map((dept: any) => { return +dept.Value }),
            FromDate: moment(this.selectedStartDate).format('YYYY-MM-DD'),
            ToDate: moment(this.selectedEndDate).format('YYYY-MM-DD'),
            FromPartOfDay: fromTimeSelected ? null : fromPartOfDay,
            FromTime: fromTimeSelected && moment(fromPartOfDayTime, ["h:mm A"]).isValid() ? moment(fromPartOfDayTime, ["h:mm A"]).format('HH:mm:ss') : null,
            ToPartOfDay: toTimeSelected ? null : toPartOfDay,
            ToTime: toTimeSelected && moment(toPartOfDayTime, ["h:mm A"]).isValid() ? moment(toPartOfDayTime, ["h:mm A"]).format('HH:mm:ss') : null,
        }
    }

    async getCareAssignmentSheetBreakdownByDay(){
        await this.$store
            .dispatch("dailyroster/getDailyRosterFilterBreakdown", this.createDailyRosterRequestModel())
            .then(() => {
                if (this.dailyRosterFilterBreakdown != null && this.dailyRosterFilterBreakdown.length > 0) {
                    this.getCareAssignmentSheetDetails();
                }
                else{
                    this.hidePageLoader();
                }
            })
            .catch((err: any) => {
                if (err) {
                    this.hidePageLoader();
                    this.clearDailyRosterData();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    getCareAssignmentSheetDetails(){
        var isCareAssignmentViewSelected = false,
            isPlusMetricSelected = false,
            isClockInSelected = false,
            selectedReports = this.selectedReportType,
            sortingOption = this.selectedSortingOption;
        var careAssignmentSheetRequests: any[] = [];

        this.clearDailyRosterData();

        this.showPageLoader();

        selectedReports.forEach((report: any) => {
            switch (report.Value) {
                case this.defaults.reportType.careAssignmentSheetView:
                    isCareAssignmentViewSelected = true;
                    break;
                case this.defaults.reportType.ppdDetails:
                    isPlusMetricSelected = true;
                    break;
                case this.defaults.reportType.clockIns:
                    isClockInSelected = true;
                    break;
                default:
            }
        });
        var self=this;
        this.dailyRosterFilterBreakdown.forEach((result: any) => {
            result.forEach((deptInfo: any) => {
                careAssignmentSheetRequests.push({
                    DepartmentId: deptInfo.departmentId,
                    StartDateTime: deptInfo.startDateTime,
                    EndDateTime: deptInfo.endDateTime,
                    RosterViewEnabled: isCareAssignmentViewSelected,
                    PlusMetricsViewEnabled: isPlusMetricSelected,
                    ClockInViewEnabled: isClockInSelected,
                    CurrentTimeStamp: moment().format(this.defaults.serverSideDateFormat),
                    SortOrder: sortingOption.Value,
                    DashboardId: self.profileData.DashboardId,
                    TenantId:self.profileData.TenantId
                });
            });
        });
        this.getCareAssignmentRosterByDay(careAssignmentSheetRequests);
    }

    async getCareAssignmentRosterByDay(careAssignmentSheetRequests: any){
        await this.$store
            .dispatch("dailyroster/getCareAssignmentRosterByDay", careAssignmentSheetRequests)
            .then(() => {
                if (this.careAssignmentRosterByDay != null && this.careAssignmentRosterByDay.length > 0) {
                    this.showDailyRosterData();
                    this.careAssignmentSheetReportInput = this.sortBy(this.careAssignmentRosterByDay, "BusinessDay", "asc");
                    this.isShowCareAssignmentReport = true;
                }
                this.hidePageLoader();
            })
            .catch((err: any) => {
                if (err) {
                    this.hidePageLoader();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
            });
    }


    getDailyRosterCompressedDetails(results: any) {
        var isDailyRosterSelected = false,
            isPlusMetricSelected = false,
            isClockInSelected = false,
            selectedReports = this.selectedReportType,
            breakDownDetails = results,
            sortingOption = this.selectedSortingOption

        this.clearDailyRosterData();

        if (!breakDownDetails || breakDownDetails.length <= 0) {
            this.hidePageLoader();
            return;
        }

        this.showPageLoader();

        selectedReports.forEach((report: any) => {
            switch (report.Value) {
                case this.defaults.reportType.dailyRosterCompressed:
                    isDailyRosterSelected = true;
                    break;
                case this.defaults.reportType.ppdDetails:
                    isPlusMetricSelected = true;
                    break;
                case this.defaults.reportType.clockIns:
                    isClockInSelected = true;
                    break;
                default:
            }
        });

        var dailyRosterRequests: any[] = [];
        var self=this;
        breakDownDetails.forEach( (result: any) => {
            result.forEach(function (deptInfo: any) {
                dailyRosterRequests.push({
                    DepartmentId: deptInfo.departmentId,
                    StartDateTime: deptInfo.startDateTime,
                    EndDateTime: deptInfo.endDateTime,
                    RosterViewEnabled: isDailyRosterSelected,
                    PlusMetricsViewEnabled: isPlusMetricSelected,
                    ClockInViewEnabled: isClockInSelected,
                    CurrentTimeStamp: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    SortOrder: sortingOption,
                    DashboardId: self.profileData.DashboardId,
                    TenantId:self.profileData.TenantId
                });
            });            
        });

        this.getDailyRosterCompressedByDay(dailyRosterRequests);
    }

    getDailyRosterExpandedDetails(results: any) {
        var isDailyRosterSelected = false,
            isPlusMetricSelected = false,
            isClockInSelected = false,
            selectedReports = this.selectedReportType,
            breakDownDetails = results,
            sortingOption = this.selectedSortingOption

        this.clearDailyRosterData();

        if (!breakDownDetails || breakDownDetails.length <= 0) {
            this.hidePageLoader();
            return;
        }

        this.showPageLoader();

        selectedReports.forEach((report: any) => {
            switch (report.Value) {
                case this.defaults.reportType.dailyRosterExpanded:
                    isDailyRosterSelected = true;
                    break;
                case this.defaults.reportType.ppdDetails:
                    isPlusMetricSelected = true;
                    break;
                case this.defaults.reportType.clockIns:
                    isClockInSelected = true;
                    break;
                default:
            }
        });

        var dailyRosterRequests: any[] = [];
        var self=this;
        breakDownDetails.forEach( (result: any) => {
            result.forEach(function (deptInfo: any) {
                dailyRosterRequests.push({
                    DepartmentId: deptInfo.departmentId,
                    StartDateTime: deptInfo.startDateTime,
                    EndDateTime: deptInfo.endDateTime,
                    RosterViewEnabled: isDailyRosterSelected,
                    PlusMetricsViewEnabled: isPlusMetricSelected,
                    ClockInViewEnabled: isClockInSelected,
                    CurrentTimeStamp: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    SortOrder: sortingOption,
                    DashboardId: self.profileData.DashboardId,
                    TenantId:self.profileData.TenantId
                });
            });            
        });

        this.getDailyRosterExpandedByDay(dailyRosterRequests);
    }

    async getDailyRosterCompressedByDay (request: any) {
        await this.$store
        .dispatch("dailyroster/getCompressedRosterByDay", request)
        .then(() => {
            if(this.compressedRosterByDay != null && this.compressedRosterByDay.length > 0){
                this.showDailyRosterData();
                this.dailyRosterCompressedReportInput = this.sortBy(this.compressedRosterByDay, "BusinessDay", "asc");
                this.isShowDailyRosterCompressedReport = true;
            }
            this.hidePageLoader();
        })
        .catch((err: any) => {
            if (err) {
                    this.hidePageLoader();
                    this.clearDailyRosterData();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
        });
    }

    async getDailyRosterExpandedByDay(request: any) {
        await this.$store
        .dispatch("dailyroster/getDailyRosterExpandedByDay", request)
        .then(() => {
            if(this.expandedRosterByDay != null && this.expandedRosterByDay.length > 0){
                this.showDailyRosterData();
                this.dailyRosterExpandedReportInput = this.sortBy(this.expandedRosterByDay, "BusinessDay", "asc");
                this.isShowDailyRosterExpandedReport = true; 
            }
            this.hidePageLoader();
        })
        .catch((err: any) => {
            if (err) {
                    this.hidePageLoader();
                    this.clearDailyRosterData();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
        });
    }

    async getDailyRosterCompressedBreakdownByDay () {
        var inputData: any = this.createDailyRosterRequestModel()
        await this.$store
        .dispatch("dailyroster/getDailyRosterFilterBreakdown", inputData)
        .then(() => {
            if(this.dailyRosterFilterBreakdown != null && this.dailyRosterFilterBreakdown.length > 0){
                this.getDailyRosterCompressedDetails(this.dailyRosterFilterBreakdown);
            }
            else{
                this.hidePageLoader();
            }
        })
        .catch((err: any) => {
            if (err) {
                    this.hidePageLoader();
                    this.clearDailyRosterData();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
        });
    }

    async getDailyRosterBreakdownByDay() {
        var inputData: any = this.createDailyRosterRequestModel()
        await this.$store
        .dispatch("dailyroster/getDailyRosterFilterBreakdown", inputData)
        .then(() => {
            if(this.dailyRosterFilterBreakdown != null && this.dailyRosterFilterBreakdown.length > 0){
                this.getDailyRosterExpandedDetails(this.dailyRosterFilterBreakdown);
            }
            else{
                this.hidePageLoader();
            }
        })
        .catch((err: any) => {
            if (err) {
                    this.hidePageLoader();
                    this.clearDailyRosterData();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
        });
    }

    onFilterChange(){
        var selectedFilter : any = -1;
        this.filters.forEach( (filter: any) => {
                if (filter.fsUserDeptGroupID == this.selectedFilter) {
                    selectedFilter= filter;
                }
            });
        
        var allDepartments = this.allDepartments;
        var allFacilities = this.facilities;
        var facilities: any = [];
        var selectedFacilities: any = [];
        var selectedDepartments: any = [];
        var deptarr: any = [];

        if (selectedFilter != '-1') {
            if (selectedFilter.departments != null) {
                deptarr = selectedFilter.departments.split(',');
            }

            allDepartments.forEach( (value: any) => {
                if (deptarr.indexOf(String(value.Value)) >= 0) {
                    selectedDepartments.push(value);//{ Value : value.Value, Text : value.Text });
                    if (facilities.indexOf(String(value.FacilityId)) < 0) {
                        facilities.push(value.FacilityId);
                    }
                }
            });

            allFacilities.forEach( (value: any) => {
                if (facilities.indexOf(value.Value) >= 0) {
                    selectedFacilities.push(value);
                }
            });

            this.selectedFacilities = selectedFacilities;
            this.departments = this.filterDepartmentByFacilities(selectedFacilities);
            this.selectedDepartments = selectedDepartments;
            this.setFilterControlStatus(true);
            this.dailyRosterSearchDeleteFilter = true;
        }
        else {
            this.dailyRosterSearchDeleteFilter = false;
            this.dailyRosterSearchUpdateFilter = false;
        }
    }

    setFilterControlStatus (action: any) {
       this.ddlFilterName = action;
       this.txtFilterName = !action;
       this.dailyRosterSearchAddFilter = action;
       this.dailyRosterSearchSaveFilter = !action;
       this.dailyRosterSearchCancelFilter = !action;
       this.dailyRosterSearchDeleteFilter = false;
       this.dailyRosterSearchUpdateFilter = false;            
    }
   
    resetFacilitySelection () {
        this.selectedFacilities = [];
        this.departments = [];
        this.selectedDepartments = [];
        this.selectedFilter = -1;// this.defaults.defaultFilteritem;
    }

    onAddFilter () {
        this.txtFilterNameText = '';
        this.setFilterControlStatus(false);
    }
    
    onSaveFilter () {
        this.createDailyRosterFilters(function () {});            
    }

    onCancelFilter () {
        this.setFilterControlStatus(true);
        this.resetFacilitySelection();
    }

    onDeleteFilter (itemId: any) {
        this.confirmMsgValue = "Are you sure you want to delete this filter?";
        this.isConfirmModalVisible = true;
        this.filterIdTodelete = itemId;      
    }

    confirmedClicked(){
        this.isConfirmModalVisible = false;
        this.delDailyRosterFilters(function () {
            
        });  
    }
    
    async delDailyRosterFilters (callback: any) {
        await this.$store
            .dispatch("dailyroster/deleteDailyRosterFilters", this.selectedFilter)
            .then(() => {
                if(this.deleteDailyRosterFilters != null && this.deleteDailyRosterFilters.length >= 0){
                    this.bindFilterData(this.deleteDailyRosterFilters);
                    this.msgValue = 'Filter deleted successfully.';
                    this.classFlag = 'success'; 
                    this.isToasterVisible = true;                   
                    this.setFilterControlStatus(true);
                    this.resetFacilitySelection();
                }
                this.hidePageLoader();
            })
            .catch((err: any) => {
                if (err) {
                    this.hidePageLoader();
                    this.classFlag = 'error';          
                    console.error(err); // Handle errors any way you want
                    this.msgValue = 'Error occurred while deleting Daily Roster Filter.'; 
                    this.isToasterVisible = true;
                }
            });
    }

    onUpdateFilter () {
        // update functionality is not used 
        this.updateDailyRosterFilter(function () {});
    }
    async updateDailyRosterFilter(callback: any) {
        var self=this;
        var data: any = {};
        data.fsUserDeptGroupId = this.selectedFilter;
        data.fsUserId = this.dailyRosterInput.userId;
       // data.name = this.txtFilterNameText;
        var filterName = '';
        var filterData = this.filters.filter(
            function (item: any) {
                return (item.fsUserDeptGroupID === self.selectedFilter);
            });

        if (filterData) {
            filterName = filterData[0].name;
        }
        // data.name = this.txtFilterNameText;
        data.name = filterName;
        data.fsUserDeptGroupTypeId = 1;
        if (data.name.trim() == '') {
            this.msgValue = 'Please enter Filter name.';
            this.classFlag = 'error'; 
            this.isToasterVisible = true;
            return;
        }
        data.departments = '';

        if(this.selectedDepartments != null){
            this.selectedDepartments.forEach((value: any, index: any) => {
                if (data.departments == '') {
                    data.departments = value.Value;
                }
                else {
                    data.departments += ',' + value.Value;
                }
            });
            data.departments=data.departments.toString();
        }

        if (data.departments == '' || this.selectedDepartments == null) {
            this.msgValue = 'Please select atleast one department.';
            this.classFlag = 'error'; 
            this.isToasterVisible = true;
            return false;
        }
        this.showPageLoader();
        await this.$store
            .dispatch("dailyroster/updateDailyRosterFilters", data)
            .then(() => {
                if (this.updateDailyRosterFilters != null && this.updateDailyRosterFilters.length > 0) {
                    this.bindFilterData(this.updateDailyRosterFilters);
                    this.msgValue = 'Filter updated successfully.';
                    this.classFlag = 'success';
                    this.isToasterVisible = true;
                    this.setFilterControlStatus(true);
                    this.resetFacilitySelection();
                }
                this.hidePageLoader();
            })
            .catch((err: any) => {
                if (err) {
                    this.hidePageLoader();
                    this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                    this.classFlag = 'error';
                    console.error(err); // Handle errors any way you want
                    if (err && err.status == 409) {
                        this.msgValue = err.responseJSON.Message;
                    } else {
                        this.msgValue = 'Error occurred while updating Daily Roster Filter.';
                    }
                    this.isToasterVisible = true;
                }
            });

    }
    async createDailyRosterFilters (callback: any) {
        var data: any = {};
        data.fsUserDeptGroupId = '00000000-0000-0000-0000-000000000000';
        data.fsUserId = this.dailyRosterInput.userId;
        data.name = this.txtFilterNameText;
        data.fsUserDeptGroupTypeId = 1;
        if (data.name.trim() == '') {
            this.msgValue = 'Please enter Filter name.';
            this.classFlag = 'error'; 
            this.isToasterVisible = true;
            return;
        }
        data.departments = '';

        if(this.selectedDepartments != null){
            this.selectedDepartments.forEach((value: any, index: any) => {
                if (data.departments == '') {
                    data.departments = value.Value;
                }
                else {
                    data.departments += ',' + value.Value;
                }
            });
            data.departments = data.departments.toString();
        }

        if (data.departments == '' || this.selectedDepartments == null) {
            this.msgValue = 'Please select atleast one department.';
            this.classFlag = 'error'; 
            this.isToasterVisible = true;
            return false;
        }
       
        this.showPageLoader();

        await this.$store
        .dispatch("dailyroster/createDailyRosterFilters", data)
        .then(() => {
            if(this.saveDailyRosterFilters != null && this.saveDailyRosterFilters.length > 0){
                this.bindFilterData(this.saveDailyRosterFilters);
                this.msgValue = 'Filter saved successfully.';
                this.classFlag = 'success'; 
                this.isToasterVisible = true;
                this.setFilterControlStatus(true);
                this.resetFacilitySelection();
            }
            this.hidePageLoader();
        })
        .catch((err: any) => {
            if (err) {
                this.hidePageLoader();
                this.msgValue = this.defaults.errorMessages.errorRetrievingReportData;
                this.classFlag = 'error';          
                console.error(err); // Handle errors any way you want
                if (err && err.status == 409) {
                    this.msgValue = err.responseJSON.Message;
                } else {
                    this.msgValue = 'Error occurred while creating Daily Roster Filter.';
                }   
                this.isToasterVisible = true;
            }
        });
    }
    
    bindFilterData (result: any) {
        var data : any = [];
        if (result && result.length > 0) {                
            result.forEach(function (value: any) {
                if (value.name.trim() != '') {
                    var item = {
                        fsUserDeptGroupID: value.fsUserDeptGroupID,
                        name: value.name,
                        departments: value.departments,
                        fsUserId: value.fsUserId
                    };
                    data.push(item);
                }
            });
        }
        this.filters = data;
    }

    filterDepartmentByFacilities (facilities: any) {
        var facilityMap: any = {},
            filteredDepartments: any = [],
            allDepartments : any = this.allDepartments;// dailyRosterViewModel.get("allDepartments");

        if (facilities.length > 0) {

            facilities.forEach(function (facility: any) {
                if (!facilityMap[facility.Value]) {
                    facilityMap[facility.Value] = facility;
                }
            });

            allDepartments.forEach(function (department: any) {
                if (facilityMap[+department.FacilityId]) {
                    filteredDepartments.push(department);
                }
            });
        }
        return filteredDepartments;
    }

    toggleFilterControlVisibility(){
        this.isHideFilterControl = !this.isHideFilterControl;
    } 



    printDailyRoster()
    {
        //<-- generate PDF using Kendo plugin START -->
        this.showPageLoader();
        this.isShowScroll = false;
        var filename = "";
        var fileArray: any = [];

        //derive filename 
        if(this.selectedReportType.length == 1) {
            filename = this.selectedReportType[0].Text;
        } else if(this.selectedReportType.length > 1) {
            this.selectedReportType.forEach(function(value: any, index: any){
                if(value.Value == 1) {
                    fileArray.push("DR Comp");
                } else if(value.Value == 4) {
                    fileArray.push("Care Assign");
                } else if(value.Value == 5) {
                    fileArray.push("DR Exp");
                } else if(value.Value == 2) {
                    fileArray.push("PPS");
                } else if(value.Value == 3) {
                    fileArray.push("Clock In");
                }
            });    
            filename = fileArray.join();
        }
        //end

        setTimeout(() => {
            //@ts-ignore
            savePDF(this.$refs.container, {
                    paperSize: "A4",
                    margin: "1mm",
                    fileName: filename,
                    scale: Number(this.selectedFontSizeForPrint),
                    landscape: this.printOrientation,
                    forcePageBreak: this.isMultiPagePrint ? ".dailyRoasterPrintPageBreak": undefined,
                    pageTemplate: markRaw({
                                    props: {
                                        pageNum: Number,
                                        totalPages: Number
                                    },                                
                                    render: function(value:any) {
                                        return h('div',{
                                            style: {position: 'absolute', bottom: '2px', left: '10px'}
                                        },['Page ' + value.pageNum + ' of ' + value.totalPages]);
                                    }
                        })
                }, 

                () => {  
                    this.isShowScroll = true; 
                    this.hidePageLoader(); 
                    // this.emitter.emit('removeFontSizeForExpandedReport'); 
                });
            }, 1000);
        //<-- generate PDF using Kendo plugin END -->
        return true;
    }

    getFormattedDate(dt: any){
        return moment(dt).format(this.defaults.dateOnlyFormat);
    }

    shouldAllowSelection(){
        var singleSelectedIds = [
            this.defaults.reportType.dailyRosterCompressed,
            this.defaults.reportType.careAssignmentSheetView,
            this.defaults.reportType.dailyRosterExpanded];
        
        if (this.selectedReportType.length <= 1) {
            return true;
        }

        let reportCount = 0;
        for (var i = 0; i < this.selectedReportType.length; i++) {
            if (singleSelectedIds.indexOf(this.selectedReportType[i].Value) >= 0) {
                reportCount = reportCount + 1;
            }

            if(reportCount > 1){
                this.selectedReportType.pop();
                var errorData = {
                        message: this.defaults.errorMessages.invalidReportSelection,
                        flag: "error"
                    }
                this.showToasterPopup(errorData);
                break;
            }
        }

        return true;
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 90%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    margin: 5px;
    padding: 5px 12px;
    height:90%;
}
.panelTabData{
    word-break: break-all;
    padding-bottom: 23px;
}

.contentScroll{
    overflow: auto;
}

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.125rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}
.btn-disabled{
    background: #c9c9c9 !important;
    color:  #6f6f6f !important;
    border-color: #c9c9c9 !important;
}
</style>