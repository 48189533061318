<template>
   <div id="contractLaborRequestSingleDaySection">
        <div id="contract-labor-management-modal-content-loading" class="loadingDiv1" 
            style="padding: 5px;" v-if="isShowContractLaborLoader">
            <div style="text-align: center">
                <div class="fa-2x">
                    <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                </div>
                <p><b>Processing...</b></p>
            </div>
        </div>
        <div id="contractLaborRequestSingleDayView" class="txtLeft">
            <div class="row rowSingleDay mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">facility</label>
                    <select class="neu-select" name="facility" id="singleDayFacilityDDL"
                            data-role="dropdownlist"
                            v-model="selectedFacility" 
                            :class="[isFacilityError ? 'hasError': '']"
                            @change="onFacilityChange"> 
                        <option v-for="fac in facilities" :value="fac.Value" :key="fac.Value">
                            {{fac.Text}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isFacilityError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">department</label>
                     <select class="neu-select" name="department" id="singleDayDepartmentDDL"
                            data-role="dropdownlist"
                            v-model="selectedDepartment" 
                            :class="[isDepartmentError ? 'hasError': '']"
                            @change="onDepartmentChange"> 
                        <option v-for="dept in departments" :value="dept.Value" :key="dept.Value">
                            {{dept.Text}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isDepartmentError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">date</label>
                    <Datepicker v-model="requestDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                    :enableTimePicker="false" :class="[isRequestDateError ? 'hasError': '']" autoApply ></Datepicker>
                    <neu-icon v-if="isRequestDateError" class="requiredControl material-icons" aria-hidden="true" style="top: 2.2rem !important;">error</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">shift</label>
                    <select class="neu-select" name="shift" id="singleDayShiftsDDL"
                            data-role="dropdownlist"
                            :class="[isShiftError ? 'hasError': '']"
                            v-model="selectedShift" >
                        <option v-for="shft in shifts" :value="shft.deptShiftId" :key="shft.deptShiftId">
                            {{shft.deptShiftDescription}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max" >arrow_drop_down</neu-icon>
                    <neu-icon v-if="isShiftError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">skill</label>
                    <select class="neu-select" name="skill" id="singleDaySkill"
                            data-role="dropdownlist"
                            :class="[isSkillError ? 'hasError': '']"
                            v-model="selectedSkill">
                        <option v-for="skl in skills" :value="skl.skillLevelId" :key="skl.skillLevelId">
                            {{skl.description}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max" >arrow_drop_down</neu-icon>
                    <neu-icon v-if="isSkillError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
            </div>
            <div class="row rowSingleDay mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">specialization</label>
                    <select class="neu-select" name="skillSpecialization" id="singleDaySpecialization"
                            data-role="dropdownlist" :class="[isSpecializationError ? 'hasError': '']"
                            v-model="selectedSkillSpecialization" >
                        <option v-for="fac in skillSpecializations" :value="fac.wfFoldergroupID" :key="fac.wfFoldergroupID">
                            {{fac.wfFolderGroupName}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isSpecializationError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">number of resources</label>
                    <neu-input id="resource-count" v-model="resourceCount" :class="[isResourcesCountError ? 'hasError': '']" ></neu-input>
                    <neu-icon v-if="isResourcesCountError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-4">
                    <label class="text-uppercase default-font-size">notes</label>
                    <neu-input id="notes" v-model="notes" ></neu-input>
                </div>
                <div class="col-2 search-wrapper" style="padding-top:20px; padding-left: 0;">
                    <neu-button type="button" fill="raised" :disabled="isDisableCreateRequest" id="WFRequestStatusSearch" @click="isDisableCreateRequest ? null : searchClick()">
                        SUBMIT REQUEST
                    </neu-button>
                </div>
            </div>
        </div>
        <div class="row rowSingleDay">
            <div class="col-12">
                <div class="assignment-list ms-1 me-1" > <!-- v-show="showList" -->
                    <neu-tablist :value="selectedTab" class="mx-4 tabList txtLeft">
                        <neu-tab
                            white
                            tab="RequestConfirmationTab"  
                            @click="setSelectedTab('RequestConfirmationTab')">
                            <div>Request Confirmation </div>
                        </neu-tab>
                        <neu-tab
                            white
                            tab="ActivityLogTab"
                            @click="setSelectedTab('ActivityLogTab')">
                            <div>Activity Log </div>
                        </neu-tab>
                         <neu-tab
                            white
                            tab="ManageContractTab"
                            @click="setSelectedTab('ManageContractTab')">
                            <div>Manage Contract </div>
                        </neu-tab> 
                    </neu-tablist> 
                    <div id="singleDayRequestConfirmationTabContent" class="panelTabData scrollBar" v-if="isRequestConfirmationVisible" role="tabpanel">
                        <div v-if="isContractLaborNeedAutomationEnabled">
                            <div class="vertical-center">
                                <div class="row">
                                    <div class="col-md-3 fa-xl" style="text-align: right ">
                                        <font-awesome-icon :icon="['fas', 'warning']" size="2xl" style="color:red;" />
                                    </div>
                                    <div class="col-md-9" style="text-align: left">
                                        <span>Automation has been enabled for this Department. Manual submissions are not allowed at this time.</span>
                                        <br/>
                                        <span>Use the WF Automation Status Tool by <span class="clickSpan" @click="showWorkforceRequestStatusPopup">Clicking here</span> to see Automation activity for this department.</span>  
                                    </div>       
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row singleDayNoRequestHeading" v-if="isShowSingleDayNoRequestHeading">
                                <div class="col d-flex justify-content-center mt-5">
                                    <h4>No requests submitted. Please request one above.</h4>
                                </div>
                            </div> 

                            <div id="singleDayRequestConfirmarionContainer" v-if="isShowSingleDayRequestConfirmarion">
                                <div class="row">
                                    <div class="col d-flex justify-content-center mt-2">
                                        <div class="h3">
                                            Resource Count
                                            <span class="requestConfirmationResourceCount">{{requestConfirmationResourceCount}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md-4">
                                        <span class="col col-form-label font-weight-bold">Facility</span><br />
                                        <span class="col col-form-label" id="requestConfirmationFacility">{{requestConfirmationFacility}}</span><br />
                                    </div>
                                    <div class="col-md-4">
                                        <span class="col-sm-2 col-form-label font-weight-bold">Dept</span><br />
                                        <span class="col col-form-label" id="requestConfirmationDepartment">{{requestConfirmationDepartment}}</span><br />
                                    </div>
                                    <div class="col-md-4">
                                        <span class="col-sm-2 col-form-label font-weight-bold">Shift</span><br />
                                        <span class="col col-form-label" id="requestConfirmationShift">{{requestConfirmationShift}}</span><br />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md-4">
                                        <span class="col-sm-2 col-form-label font-weight-bold">Date</span><br />
                                        <span class="col col-form-label" id="requestConfirmationDate">{{requestConfirmationDate}}</span><br />
                                    </div>
                                    <div class="col-md-4">
                                        <span class="col-sm-2 col-form-label font-weight-bold">Skill</span><br />
                                        <span class="col col-form-label" id="requestConfirmationSkill">{{requestConfirmationSkill}}</span><br />
                                    </div>
                                    <div class="col-md-4">
                                        <span class="col-sm-2 col-form-label font-weight-bold">Specialization</span><br />
                                        <span class="col col-form-label" id="requestConfirmationSpecialization">{{requestConfirmationSpecialization}}</span><br />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <span class="col-sm-2 col-form-label font-weight-bold">Notes</span><br />
                                        <span class="col col-form-label" id="requestConfirmationNotes">{{requestConfirmationNotes}}</span><br />
                                    </div>
                                </div>
                                <div class="row mt-6">
                                    <div class="col d-flex justify-content-center mt-5">
                                        <neu-button type="button" fill="raised" id="singleDayCancelRequest" 
                                            @click="onCancelRequests">
                                            CANCEL SUBMITTED REQUESTS
                                        </neu-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div id="singleDayMyRecentActivityTabContent" class="panelTabData scrollBar" v-if="isActivityLogVisible" role="tabpanel">
                        <neu-card color="plain-0" class="p-0 m-0" >
                            <neu-table>
                                <neu-table-row :columns="columnHeaders" header>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column"
                                        style="padding-top: 25px !important;">
                                    {{ column }}
                                    </neu-table-heading>
                                </neu-table-row>
                                <neu-table-body align="left">
                                    <template v-if="activityLogGridData != null && activityLogGridData.length > 0">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-table-row :columns="columnHeaders" v-for="activityLog in activityLogGridData" :key="activityLog" :slot="column">
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Requested At" :title="activityLog.createdDate">{{ activityLog.createdDate }}</neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Action" :title="activityLog.transactionName">{{ activityLog.transactionName }}</neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Facility" :title="activityLog.facilityName">{{ activityLog.facilityName }}</neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Department" :title="activityLog.departmentName">
                                                {{ activityLog.departmentName }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Skill" :title="activityLog.skill">
                                                 {{ activityLog.skill }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Requested For" :title="activityLog.requestedForDate">
                                                 {{ activityLog.requestedForDate }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="User" :title="activityLog.userInfo">
                                                 {{ activityLog.userInfo }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Details" :title="activityLog.details">
                                                {{ activityLog.details }}
                                            </neu-label>
                                        </neu-table-row>
                                    </template>
                                    <template v-else>
                                        <p id="period-message-text">No data available on current page.</p>
                                    </template>
                                </neu-table-body>
                            </neu-table>
                        </neu-card>
                        <neu-container fluid="" v-if="activityLogGridData != null && activityLogGridData.length > 0">
                            <neu-row>
                                <neu-col md="6" cols="12" class="neu-text-center neu-text-md-left">
                                    <!-- eslint-disable-next-line vue/no-parsing-error  -->
                                    <neu-paginator class="justify-content-start" @v-neu-change="(ev: CustomEvent) => this.pageNum = ev.detail.pageNumber"
                                        :total="activityLogGridData != null && activityLogGridData.length"
                                        :rowsPerPage="rowsPerPage"
                                        >
                                    </neu-paginator>
                                </neu-col>
                                <neu-col md="6" cols="12" class="neu-text-center neu-text-md-right">
                                    <neu-table-row-count
                                        class="mt-3"
                                        section="number"
                                        :total="activityLogGridData != null && activityLogGridData.length"
                                        :page-number="pageNum"
                                        @v-neu-change="rowCountChange"
                                    >
                                    </neu-table-row-count>
                                    
                                </neu-col>
                            </neu-row>
                        </neu-container>
                    </div>
                    <div id="singleDayManageContractTabContent" class="panelTabData scrollBar" v-if="isManageContractVisible" role="tabpanel">
                        <br />
                        <div class="col">
                            <a id="manage-contract-labor-lnk" class="mr-1" :href="contractLaborLink" target="_blank">
                                Manage Contract Labor</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuTab,
    NeuTablist
} from "@neutron/vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ToasterPopup from '../shared/ToasterPopup.vue';

class Props {
    singleDayInput!: any 
}

@Options({
  computed: {   
    ...mapState('actionable', ['departmentShifts', 'createdcontractLaborRequest', 'cancelledcontractLaborRequest', 'deptCLNeedAutomationStatus']),
    ...mapState('shared', ['departmentSkillList', 'skillSpecialization']),
    ...mapState('dashboard', ['recentActivityLogs']),
    ...mapState('profile', ['profileData'])
  },
  components: {
    NeuTab,
    NeuTablist,
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    Datepicker,
    ToasterPopup
  }
}) 
export default class SingleDay extends Vue.with(Props) {
    //Data properties
    departmentSkillList!: any;
    skillSpecialization!: any;
    departmentShifts!: any;
    recentActivityLogs!: any;
    profileData!: any;
    createdcontractLaborRequest!: any;
    cancelledcontractLaborRequest!: any;

    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    
    selectedTab: string = 'RequestConfirmationTab';
    isRequestConfirmationVisible: boolean = true; 
    isActivityLogVisible: boolean = false;
    isManageContractVisible: boolean = false;

    facilities: any = [];
    selectedFacility: any = null;
    allDepartments: any = [];
    departments: any = [];
    selectedDepartment: any = null;
    selectedDate: any = null;
    requestDate: Date = new Date();
    shifts: any = [];
    selectedShift: any = null;
    skills: any = [];
    selectedSkill: any = null;
    skillSpecializations: any = [];
    selectedSkillSpecialization: any = null;
    resourceCount: number = 1;
    notes: string = '';
    createdRequests: any = [];
    requestLogs: any = [];
    isShowContractLaborLoader: boolean = false;
    contractLaborLink: string = '';
    isShowSingleDayNoRequestHeading: boolean = false;
    isShowSingleDayRequestConfirmarion: boolean = false;
    activityLogGridData: any = null;
    isDisableCreateRequest: boolean = false;
    deptCLNeedAutomationStatus!: any;
    isContractLaborNeedAutomationEnabled: boolean = false;

    pageNum: number = 1;
    rowsPerPage: number = 10;
    first: number = 1;
    last: number = 10;

    requestConfirmationFacility: string = '';
    requestConfirmationDepartment: string = '';
    requestConfirmationShift: string = '';
    requestConfirmationDate: string = '';
    requestConfirmationSkill: string = '';
    requestConfirmationSpecialization: string = '';
    requestConfirmationResourceCount: string = '';
    requestConfirmationNotes: string = '';

    defaults : any = {
        productiveShift: 1,
        administrativeCancel: 5,
        errorMessages: {
            createRequestsFailMessage: "Unable to process your contract labor request.",
            createRequestSuccessMessage: "Your request has been submitted.",
            cancelRequestFailMessage: "Unable to process your cancellation request. ",
            cancelRequestSuccessMessage: "Your cancellation request has been submitted.",
            loadActivityLogFailMessage:"Unable to load activity logs."

        },
        localDateFormat: 'MM/DD/YYYY h:mm A',
        serverSideDateFormat: 'YYYY-MM-DDTHH:mm:ss',
        maxNumberOfResources: 999,
        maxNumberOfResourcesWhenKafkaIsOff:10
    };

    columnHeaders: any ={ 
        "Requested At": "17%", 
        Action: "8%", 
        Facility: "15%",
        Department: "15%", 
        Skill: "8%", 
        "Requested For": "13%", 
        User: "12%", 
        Details: "12%", 
    };
    columnIcons: any = {    
        "Requested At": "none", 
        Action: "none", 
        Facility: "none",
        Department: "none", 
        Skill: "none", 
        "Requested For": "none", 
        User: "none", 
        Details: "none",
    };

    //Validation Flag
    isFacilityError: boolean = false;
    isDepartmentError: boolean = false;
    isShiftError: boolean = false;
    isSkillError: boolean = false;
    isSpecializationError: boolean = false;
    isResourcesCountError: boolean = false;
    isRequestDateError: boolean = false;

    //Lifecycle hooks
    async mounted(){
        await this.loadSingleDay();
    }

    //Methods
    async loadSingleDay(){
        await this.loadFacilitiesAndDepartments();
        this.contractLaborLink = this.profileData.AppUrl + 'Requests/ContractLaborRequest.aspx';
        this.showRequestConfirmation(false);
        this.refreshMyRecentActityGrid();
    }

    async onFacilityChange(){
        await this.loadDepartmentByFacility();
    }

    async onDepartmentChange(){
        //check for department atomation enabled
        await this.loadDeptCLNeedAutomationStatus();
        await this.loadShiftAndSkillAndSpecializations();
    }

    async loadDeptCLNeedAutomationStatus() {

        if (this.selectedDepartment != null) {
            var inputData = {
                departmentId: this.selectedDepartment
            }
            await this.$store
                .dispatch("actionable/getDepartmentContractLaborNeedAutomationStatus", inputData)
                .then(() => {
                    if (this.deptCLNeedAutomationStatus != null) {
                        this.isContractLaborNeedAutomationEnabled = this.deptCLNeedAutomationStatus;
                        this.isDisableCreateRequest = this.deptCLNeedAutomationStatus;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Contract Labor Automation Status.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
        
    }

    async loadFacilitiesAndDepartments(){

        this.facilities = this.singleDayInput.facilities;
        this.selectedFacility = this.singleDayInput.facilities[0].Value;
        this.allDepartments = this.singleDayInput.departments;

        await this.loadDepartmentByFacility();

    }

    async loadDepartmentByFacility() {
        
        var facility = this.selectedFacility;
        var allDepartments = this.allDepartments;

        //@ts-ignore
        var facilityDepartments: any = jslinq(allDepartments)
                .where((item: any) => {
                    if (item.FacilityId === +facility && item.AllowManualClRequests === true){
                    return item;
                    }
                })
            .toList();
      
        if (facilityDepartments.length > 0) {
            this.departments = facilityDepartments;
            this.selectedDepartment = facilityDepartments[0].Value;
           
        }
        else {
            this.departments = [];
            this.selectedDepartment = null;
            var errorData = {
                message: "No departments are available in this facility to send requests.",
                flag: "warning"
            }
            this.showToasterPopup(errorData); 
        }
        await this.loadDeptCLNeedAutomationStatus();
        await this.loadShiftAndSkillAndSpecializations();

    }

    async loadShiftAndSkillAndSpecializations(){
        var selectedDepartment = this.selectedDepartment;

        if(selectedDepartment != null){
            this.isShowContractLaborLoader = true;
            var today = moment().format('DD-MMM-YYYY');

            //@ts-ignore
            var facilityCoid: any = jslinq(this.facilities)
                .where((item: any) => {
                    if(item.FacilityId === this.selectedFacility){
                    return item;
                    }
                })
                .toList();

            //@ts-ignore
            var deptCodes: any = jslinq(this.departments)
                .where((item: any) => {
                    if(item.Value === this.selectedDepartment){
                    return item;
                    }
                })
                .toList();

            await this.loadDepartmentShifts(selectedDepartment, today);
            await this.loadSkills(selectedDepartment, today);
            await this.loadSkillSpecializations(facilityCoid[0].Coid, deptCodes[0].Value);
            this.isShowContractLaborLoader = false;
        }
    }

    async loadDepartmentShifts(deptId: any, date: any){
        var inputData = {
            departmentId: deptId,
            date: moment(date).format('YYYY-MM-DD')
        }
        await this.$store
                .dispatch("actionable/getDepartmentShifts", inputData)
                .then(() => {
                    if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                        var productiveShifts = this.filterShiftsByType(this.departmentShifts);
                        this.shifts = productiveShifts; 
                        this.selectedShift = productiveShifts[0].deptShiftId                   
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        this.isShowContractLaborLoader = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    filterShiftsByType(allShifts: any) {
        //@ts-ignore
        var filteredShifts = jslinq(allShifts)
                  .where((shift: any) => {
                      if(shift.productiveId === 1){
                        return shift;
                      }
                  })
              .toList();

        var updatedShifts = filteredShifts.map((item: any) => {
            item.shiftText = item.code.trim() + ' ' + moment(item.startTime).format('h:mm A') + ' to ' + moment(item.endTime).format('h:mm A');
            return item;
        });

        return updatedShifts;
    }

    async loadSkills(deptId: any, date: any){
        var input = {
            deptId: deptId,
            date: date,
            tenantId: this.profileData.TenantId
        }

        await this.$store
            .dispatch("shared/getDepartmentSkills", input)
            .then(() => {
                if (this.departmentSkillList != null && this.departmentSkillList.length > 0) {
                    var skills: any = this.sortBy(this.departmentSkillList, 'description', 'asc');
                    this.skills = skills; 
                    this.selectedSkill = skills[0].skillLevelId       
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving Department Skills.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    this.isShowContractLaborLoader = true;
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    async loadSkillSpecializations(FacilityCoid: any, DepartmentId: any){
        var inputData = {
            FacilityCoid: FacilityCoid,
            DepartmentId: DepartmentId
        }
        await this.$store
                .dispatch("shared/getSkillSpecializations", inputData)
                .then(() => {
                    if (this.skillSpecialization != null && this.skillSpecialization.length > 0) {
                        var flags = [];
                        var distinctSkillSpecializations = [];
                        for (var index = 0; index < this.skillSpecialization.length; ++index) {
                            if (!flags[this.skillSpecialization[index].wfFoldergroupID]) {
                                flags[this.skillSpecialization[index].wfFoldergroupID] = true;
                                distinctSkillSpecializations.push(this.skillSpecialization[index]);
                            }
                        }
                        this.skillSpecializations = distinctSkillSpecializations; 
                        this.selectedSkillSpecialization =  this.skillSpecialization[0].wfFoldergroupID;                  
                    }
                    else{
                       var errorData = {
                            message: "No specialization available for this department.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData); 
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Skill Specialization.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        this.isShowContractLaborLoader = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    setSelectedTab(tab: string){
        this.selectedTab = tab;
        this.isRequestConfirmationVisible = false; 
        this.isActivityLogVisible = false;
        this.isManageContractVisible = false; 
        
        switch (this.selectedTab) {
            case 'RequestConfirmationTab':    
                this.isRequestConfirmationVisible = true;
                break;
            case 'ActivityLogTab':
                this.isActivityLogVisible = true;
                break;
            case 'ManageContractTab':
                this.isManageContractVisible = true;
                break;
        }
    }

    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    async refreshMyRecentActityGrid() {
        var createDateFrom = moment().subtract(24, 'hours').utc().format(this.defaults.serverSideDateFormat);
        var createDateTo = moment().add(5,'minutes').utc().format(this.defaults.serverSideDateFormat);

        var request = {
            FsUserId: this.singleDayInput.userId,
            FromCreatedOnDateUtc: createDateFrom,
            ToCreatedOnDateUtc: createDateTo,
            TenantId: this.profileData.TenantId
        };

        await this.loadRecentActivityLogGrid(request);     

    }

    async loadRecentActivityLogGrid(request: any){
        await this.$store
                .dispatch("dashboard/getRecentActivityLogs", request)
                .then(() => {
                    if(this.recentActivityLogs != null && this.recentActivityLogs.length > 0){
                        this.activityLogGridData = this.recentActivityLogs;
                        this.activityLogGridData = this.activityLogGridData.map((activity: any) => {
                            activity.userInfo = activity.fsUserName + ', ' + activity.fsLoginName;
                            activity.createdDate =
                                moment.utc(activity.createdOnUtc).local().format('MM/DD/YYYY hh:mm A');
                            activity.skill = activity.skillDescription;
                            activity.requestedForDate = moment(activity.shiftRequestedForDate).format('MM/DD/YYYY');
                            return activity;
                        });
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: this.defaults.errorMessages.loadActivityLogFailMessage,
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    showRequestConfirmation(show: boolean) {
        if (show) {
            this.isShowSingleDayRequestConfirmarion = true;
            this.isShowSingleDayNoRequestHeading = false;
        } else {
            this.isShowSingleDayRequestConfirmarion = false;
            this.isShowSingleDayNoRequestHeading = true;
        }
    }

    rowCountChange(ev: CustomEvent) {
      if (ev.detail && ev.detail.first && ev.detail.last) {
        this.first = ev.detail.first;
        this.last = ev.detail.last;
      }
      if (ev.detail && ev.detail.value) this.rowsPerPage = ev.detail.value;
    }

    async searchClick(){

        var isFormValid: boolean = this.validateFormControls();
        
        if (!isFormValid) {
            return;
        }

        //@ts-ignore
        var facility: any = jslinq(this.facilities)
            .where((item: any) => {
                if(item.FacilityId === this.selectedFacility){
                return item;
                }
            })
            .toList();

        //@ts-ignore
        var facility: any = jslinq(this.facilities)
            .where((item: any) => {
                if(item.FacilityId === this.selectedFacility){
                return item;
                }
            })
            .toList();

        //@ts-ignore
        var department: any = jslinq(this.departments)
            .where((item: any) => {
                if(item.Value === this.selectedDepartment){
                return item;
                }
            })
            .toList();
        
        //@ts-ignore
        var shift: any = jslinq(this.shifts)
            .where((item: any) => {
                if(item.deptShiftId === this.selectedShift){
                return item;
                }
            })
            .toList();

        //@ts-ignore
        var skill: any = jslinq(this.skills)
            .where((item: any) => {
                if(item.skillLevelId === this.selectedSkill){
                return item;
                }
            })
            .toList();
 
        //@ts-ignore
        var skillSpecialization: any = jslinq(this.skillSpecializations)
            .where((item: any) => {
                if(item.wfFoldergroupID === this.selectedSkillSpecialization){
                return item;
                }
            })
            .toList();
 

        var requestContractModel: any = {
            FacilityCoId: facility[0].Coid,
            DepartmentId: this.selectedDepartment,
            ShiftRequestDetails: [{
                "Date": moment(this.requestDate).format('MM/DD/YYYY'),
                "FolderGroupId": this.selectedSkillSpecialization,
                "Note": this.notes,
                "DepartmentShiftId": this.selectedShift,
                "fsSkillId": this.selectedSkill,
                "requestCount": this.resourceCount
            }]
        };

        this.isShowContractLaborLoader = true;
        await this.$store
            .dispatch("actionable/createContractLaborRequests", requestContractModel)
            .then(() => {
                if (this.createdcontractLaborRequest != null){
                    var successData = {
                        message: this.defaults.errorMessages.createRequestSuccessMessage,
                        flag: "success"
                    }
                    this.showToasterPopup(successData);

                    this.loadRequestConfirmation({
                        facilityName: facility[0].Text,
                        departmentName: department[0].Text,
                        shiftDescription: shift[0].deptShiftDescription,
                        createdDate: moment(this.requestDate).format('Do MMMM YYYY'),
                        skillDescription: skill[0].description,
                        specializationDescription: skillSpecialization[0].wfFolderGroupName,
                        resourceCount: this.resourceCount,
                        notes: this.notes
                    });
                    var createdRequests = this.createdcontractLaborRequest.map((request: any) => {
                        return { contractLaborShiftId: request.contractLaborShiftId }
                    });

                    this.createdRequests = createdRequests;

                    this.showRequestConfirmation(true);
                    this.refreshMyRecentActityGrid();
                    this.isShowContractLaborLoader = false;
                }
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborLoader = false;
                    var errorData = {
                        message: this.defaults.errorMessages.createRequestsFailMessage,
                        flag: "error"
                    }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    validateFormControls(): boolean{
        var isFormValid = true;
        
        this.removeValidation();

        if (!this.selectedFacility == null) {
            this.isFacilityError = true;
            isFormValid = false;
        }

        if (this.selectedDepartment == null) {
            this.isDepartmentError = true;
            isFormValid = false;
        }

        if (this.selectedShift == null) {
            this.isShiftError = true;
            isFormValid = false;
        }

        if (this.selectedSkill == null) {
            this.isSkillError = true;
            isFormValid = false;
        }

        if (this.selectedSkillSpecialization == null) {
            this.isSpecializationError = true;
            isFormValid = false;
        }

        if (this.requestDate == null) {
            this.isRequestDateError = true;
            isFormValid = false;
        }

        if (this.resourceCount == null || isNaN(this.resourceCount)) {
            this.isRequestDateError = true;
            isFormValid = false;
        }

        if (!isFormValid) {
            var errorData = {
                message: 'Please select missing information',
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return isFormValid;
        }

        if (isFormValid) {
            //@ts-ignore
            var selectedShiftData: any = jslinq(this.shifts)
                .where((item: any) => {
                    if(item.deptShiftId === this.selectedShift){
                    return item;
                    }
                }).firstOrDefault();

            if (this.isShiftInvalid(this.requestDate, selectedShiftData.startTime)) {
                this.isShiftError = true;
                var errData = {
                    message: 'Shift has already started. Please select a valid future shift',
                    flag: "error"
                }
                this.showToasterPopup(errData);
                isFormValid = false;
                return isFormValid;
            }
        }

        var isKafkaDisabled = this.singleDayInput.contractLaborUsingKafkaEnabled === undefined
                || this.singleDayInput.contractLaborUsingKafkaEnabled === false;

        if (isKafkaDisabled && this.resourceCount > this.defaults.maxNumberOfResourcesWhenKafkaIsOff) {
            this.isResourcesCountError = true;
            var errDt = {
                message: 'Number of resources must be between 1 and ' + this.defaults.maxNumberOfResourcesWhenKafkaIsOff,
                flag: "error"
            }
            this.showToasterPopup(errDt);
            isFormValid = false;
            return isFormValid;
        }
        
        if (this.resourceCount > this.defaults.maxNumberOfResources || this.resourceCount <= 0) {
            this.isResourcesCountError = true;
            var errData = {
                message: 'Number of resources must be between 1 and ' + this.defaults.maxNumberOfResources,
                flag: "error"
            }
            this.showToasterPopup(errData);
            isFormValid = false;
            return isFormValid;
        }

        return isFormValid;
    }

    removeValidation(){
        this.isFacilityError = false;
        this.isDepartmentError = false;
        this.isShiftError = false;
        this.isSkillError = false;
        this.isSpecializationError = false;
        this.isResourcesCountError = false;
        this.isRequestDateError = false;
    }

    isShiftInvalid(requestDate: any, shiftStartTime: any) {
        if (requestDate != null && shiftStartTime != null) {
            var now = moment();
            var requestDate: any = moment(requestDate).format('YYYY-MM-DD');
            var startTime: any = moment(shiftStartTime).format('h:mm a');
            var formattedStartTime: any = moment(requestDate + ' ' + startTime, 'YYYY-MM-DD hh:mm A');
            return formattedStartTime.isSameOrBefore(now);
        }
    }

    loadRequestConfirmation(requestConfirmation: any) {
        this.requestConfirmationFacility = requestConfirmation.facilityName;
        this.requestConfirmationDepartment = requestConfirmation.departmentName;
        this.requestConfirmationShift = requestConfirmation.shiftDescription;
        this.requestConfirmationDate = requestConfirmation.createdDate;
        this.requestConfirmationSkill = requestConfirmation.skillDescription;
        this.requestConfirmationSpecialization = requestConfirmation.specializationDescription;
        this.requestConfirmationResourceCount = requestConfirmation.resourceCount
        this.requestConfirmationNotes = requestConfirmation.notes;
    }

    async onCancelRequests() {   
        var createdRequests = this.createdRequests;

        if (createdRequests.length <= 0) {
            return;
        }

        var cancelRequest = {
            CancellationReasonId: this.defaults.administrativeCancel,
            Notes: '',
            ContractLaborShiftIds: createdRequests.map((request: any) => {
                return request.contractLaborShiftId;
            })
        };

        this.isShowContractLaborLoader = true;

        await this.$store
            .dispatch("actionable/cancelContractLbrRequests", cancelRequest)
            .then(() => {
                if (this.cancelledcontractLaborRequest != null){
                    var successData = {
                        message: this.defaults.errorMessages.cancelRequestSuccessMessage,
                        flag: "success"
                    }
                    this.showToasterPopup(successData);

                    this.createdRequests = [];
                    this.resetConfirmationTab();
                    this.resourceCount = 1;
                    this.notes = '';
                    this.refreshMyRecentActityGrid();
                    this.isShowContractLaborLoader = false;
                }
                else{
                    var errData = {
                        message: this.defaults.errorMessages.cancelRequestFailMessage,
                        flag: "error"
                    }
                    this.showToasterPopup(errData);
                }
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborLoader = false;
                    var response = JSON.parse(err.request.response);
                    var erroMsg: any = this.defaults.errorMessages.cancelRequestFailMessage + response.Message;
                    var errorData = {
                        message: erroMsg,
                        flag: "error"
                    }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
        }

        resetConfirmationTab(){
            this.loadRequestConfirmation({
                facilityName: '',
                departmentName: '',
                shiftDescription: '',
                createdDate: '',
                skillDescription: '',
                specializationDescription: '',
                resourceCount: '',
                notes:''
            });
            this.showRequestConfirmation(false);
        }

        showWorkforceRequestStatusPopup(){   
            
             //@ts-ignore
            var deptCodes: any = jslinq(this.departments)
                .where((item: any) => {
                    if(item.Value === this.selectedDepartment){
                    return item;
                    }
                })
                .toList();

            var dataSet: any =  { DepartmentId: this.selectedDepartment,
                DepartmentName: deptCodes[0].Text, 
                FacilityId: this.selectedFacility, 
                DepartmentCode: deptCodes[0].DepartmentCode };

            this.emitter.emit('loadWorkforceRequestStatusPopupWithSelectedFacAndDept', dataSet);      
        }
}
</script>
<style scoped>
.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.125rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.rowSingleDay{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.requiredControl{
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 37px;
    z-index: 2147483647;
}

.hasError{
    border: 2px solid #ce3637 !important;
}

.vertical-center {
    margin-top: 90px;
    font-size: 14px;
}

.clickSpan{
    text-decoration: underline;
    color:blue;
    cursor: pointer;
}

</style>