import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ name: 'FilterModule', stateFactory: true })
export default class FilterModule extends VuexModule {
  // State

  filters: any = [
    {
      filterColumn: 'statuses',
      filterMatch: 'equal',
      filterValue: 'pending',
    },
  ]
  talentfilters: any = [
    {
      filterColumn: 'statuses',
      filterMatch: 'equal',
      filterValue: 'pending',
    },
  ]
  tradefilters: any = [
    {
      filterColumn: 'statuses',
      filterMatch: 'equal',
      filterValue: 'pending',
    },
  ]
  needfilters: any = [
    {
      filterColumn: 'statuses',
      filterMatch: 'equal',
      filterValue: 'pending',
    },
  ]
  sortedGrids: any = {}
  selectedFilters: any = []

  get sortedGridList() {
    return this.sortedGrids
  }

  get getApprovalFilter() {
    return this.filters
  }
  get getApprovalTalentFilter() {
    return this.talentfilters
  }
  get getApprovalTradeFilter() {
    return this.tradefilters
  }
  get getApprovalNeedFilter() {
    return this.needfilters
  }
  get getSelectedFilter() {
    return this.selectedFilters
  }

  @Mutation
  mutateApprovalFilter(filters: any) {
    this.filters = filters
  }
  @Mutation
  mutateApprovalTalentFilter(filters: any) {
    this.talentfilters = filters
  }
  @Mutation
  mutateApprovalTradeFilter(filters: any) {
    this.tradefilters = filters
  }
  @Mutation
  mutateApprovalNeedFilter(filters: any) {
    this.needfilters = filters
  }

  @Mutation
  setSortedRows(data: any) {
    this.sortedGrids = data
  }

  @Mutation
  setFilters(data: any) {
    this.selectedFilters = data
  }

  @Action({ rawError: true })
  public async setApprovalFilter(filters: any) {
    this.context.commit('mutateApprovalFilter', filters)
  }
  @Action({ rawError: true })
  public async setApprovalTalentFilter(filters: any) {
    this.context.commit('mutateApprovalTalentFilter', filters)
  }
  @Action({ rawError: true })
  public async setApprovalTradeFilter(filters: any) {
    this.context.commit('mutateApprovalTradeFilter', filters)
  }
  @Action({ rawError: true })
  public async setApprovalNeedFilter(filters: any) {
    this.context.commit('mutateApprovalNeedFilter', filters)
  }
}
