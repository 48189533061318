import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";
import camelcaseKeys from "camelcase-keys";

const namespaced = true

interface State {
    partOfDayTemplates: any,
    allDailyRosterFilters: any,
    dailyRosterFilterBreakdown: any,
    careAssignmentRosterByDay: any,
    compressedRosterByDay: any,
    expandedRosterByDay: any,
    saveDailyRosterFilters: any,
    deleteDailyRosterFilters: any,
    updateDailyRosterFilters: any
}

//state
const state: State = {
    partOfDayTemplates: null,
    allDailyRosterFilters: null,
    dailyRosterFilterBreakdown: null,
    careAssignmentRosterByDay: null,
    compressedRosterByDay: null,
    expandedRosterByDay: null,
    saveDailyRosterFilters: null,
    deleteDailyRosterFilters: null,
    updateDailyRosterFilters:null
}

//actions
const actions: ActionTree<State, RootState> = {

    getPartOfDayTemplates({ commit, rootState }, inputs: any) {
        
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/getpartofdaytemplates/${inputs.TenantId}`;
      
        return http
            .get(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                   
                    commit('setPartOfDayTemplates', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getAllDailyRosterFilters({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRosterFilter/${inputs.UserId}`; 

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setAllDailyRosterFilters', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },

    getDailyRosterFilterBreakdown({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRoster/GetDailyRosterFilterBreakdown`

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                   commit('setDailyRosterFilterBreakdown', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getCareAssignmentRosterByDay({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRoster/CareAssignmentRosterByDay`; 

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    commit('setCareAssignmentRosterByDay', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getCompressedRosterByDay({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRoster/CompressedRosterByDay`; 

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    commit('setCompressedRosterByDay', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDailyRosterExpandedByDay({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRoster/ExpandedRosterByDay`; 

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    commit('setExpandedRosterByDay', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    createDailyRosterFilters ({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRosterFilter`; 

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    commit('setSaveDailyRosterFilters', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteDailyRosterFilters ({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRosterFilter/${inputs}`; 

        return http
            .delete(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setDeleteDailyRosterFilters', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    updateDailyRosterFilters ({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DailyRosterFilter`; 

        return http
            .put(apiUrl,inputs)
            .then((res) => {
                if(res != null){
                   commit('setUpdateDailyRosterFilters', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
}

// mutations
const mutations: MutationTree<State> = {   
    setPartOfDayTemplates(state, result: any){
        state.partOfDayTemplates = result;
    },
    setAllDailyRosterFilters(state, result: any){
        state.allDailyRosterFilters = result;
    },
    setDailyRosterFilterBreakdown(state, result: any){
        state.dailyRosterFilterBreakdown = result;
    },
    setCareAssignmentRosterByDay(state, result: any){
        state.careAssignmentRosterByDay = result;
    },
    setCompressedRosterByDay(state, result: any){
        state.compressedRosterByDay = result;
    },
    setExpandedRosterByDay(state, result: any){
        state.expandedRosterByDay = result;
    },
    setSaveDailyRosterFilters(state, result: any){
        state.saveDailyRosterFilters = result;
    },
    setDeleteDailyRosterFilters(state, result: any){
        state.deleteDailyRosterFilters = result;
    },
    setUpdateDailyRosterFilters(state, result: any){
        state.updateDailyRosterFilters = result;
    },
}

export const dailyroster: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

