<template>
    <div id="exportToExcelLayout" ref="exportToExcelLayout" class="care-assingnment">
        <div style="text-align:left;">
            <h5 style="font-size: 35px;">
                <span v-if="payloadExportToExcel.tabName == 'home'"> Department Details </span>
                <span v-else-if="payloadExportToExcel.tabName == 'other'"> Service Line </span>
                <span v-else> Unscheduled Staff</span>
            </h5>
            <div class="col-sm-12 div-filter">
                <div>
                    <label class="care-assignment-title">Facility : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.facilityName
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Department : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.departmentName
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Service Line : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.serviceLineName
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Staff To : </label>
                    <span class="care-assignment-title-value" v-if="exportToExcelViewModel.staffTo">
                        {{
                            exportToExcelViewModel.staffTo
                        }}
                    </span>
                    <span class="care-assignment-title-value" v-else> - </span>
                </div>
                <div>
                    <label class="care-assignment-title">Actual Census : </label>
                    <span class="care-assignment-title-value" v-if="exportToExcelViewModel.actualCensus">
                        {{
                            exportToExcelViewModel.actualCensus
                        }}
                    </span>
                    <span class="care-assignment-title-value" v-else> - </span>
                </div>
                <div>
                    <label class="care-assignment-title">Forecast : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.forecastCensus
                        }}
                    </span>
                </div>

                <div>
                    <label class="care-assignment-title">Date : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.startDate
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Start Time : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.startTime
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">End Time : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.endTime
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Skill Level Description : </label>
                    <span class="care-assignment-title-value">
                        {{
                            exportToExcelViewModel.skillLevelDescription
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div class="padding">
        </div>
        <div id="grdCareAssignment" v-show="isShowExportToExcelContent">
            <table border="1" class="tableClass" v-if="payloadExportToExcel.tabName == 'home'">
                <thead>
                    <tr header class="tableRow" style="padding-top: 25px !important; padding-bottom: 10px !important;">
                        
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Charge
                        </th>
                        <th class="tableRowHeading tableRowHeading_large"
                            style="width:15%;">
                            Name
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Skills
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:13%;">
                            Comps
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:13%;">
                            Department
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Shift
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            Start Time
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            End Time
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:8%;">
                            Type
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:8%;">
                            Hours
                        </th>
                        <th v-if="isRNExperienceFeatureEnabled" class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            RN Experience
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Hire Date
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Last Cancelled
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Last Floated
                        </th>
                    </tr>
                </thead>
                <tbody align="left">
                    <template v-if="exportToExcelData != null && exportToExcelData.length > 0">
                        
                        <tr v-for="dataItem in exportToExcelData" 
                            :key="dataItem" :uid="dataItem.uid" :staffId="dataItem.staffId"
                            :class="[dataItem.productiveId == 2 ? 'other-productive': '',
                            dataItem.productiveId==3 ? 'non-productive' : '' ,
                            dataItem.productiveId==4 ? 'call-assignment' : '' ,
                            dataItem.profileCharge && dataItem.charge ? 'chargeAssignment' : '' ,
                            (dataItem.cancelReasonId ||
                            (dataItem.cancelComment && dataItem.cancelComment.length>
                            0) || dataItem.isCancelled) ? 'cancelledAssignment': '',
                            dataItem.origDepartmentId !== dataItem.departmentId && !(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'floatedAssignment' : '','tableRow']">
                            <td class="tableRow_cell" slot="Name" :title="dataItem.name" style="width:8%;">
                                <span v-if="dataItem.charge">
                                    Yes
                                </span>
                            </td>
                            
                            <td class="tableRow_cell" slot="Name" :title="dataItem.name" style="width:15%;">
                                {{ dataItem.name }}
                                <span v-if="dataItem.charge">
                                    <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                </span>
                                <span v-if="dataItem.associationType === associationTypes.secondary">
                                    <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                </span>
                                <span v-if="dataItem.associationType === associationTypes.unassociated">
                                    <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                </span>
                                <span v-if="dataItem.isFloat">
                                    <img style="width: 24px;float: right" src="@/assets/float.png" alt="Float" />
                                </span>
                            </td>
                            
                            <td slot="Skill" :title="dataItem.allSkills" style="width:6%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                                (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']"
                                :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled)  
                                || (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId)?{'text-decoration': 'line-through'}:'']">
                                {{ dataItem.allSkills }}
                            </td>
                            
                            <td slot="Competency" style="width:10%;" class="tableRow_cell">
                                {{ dataItem.competencyCodes }}
                            </td>
                            
                            <td slot="Department" :title="dataItem.departmentName" style="width:13%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                    (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']"
                                :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled)  
                               ?{'text-decoration': 'line-through'}:'']">
                               
                               <span v-if="dataItem.origDepartmentId !== dataItem.departmentId">
                                    {{ dataItem.departmentName }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentName}}  </span>
                                </span>
                                <span v-else> {{ dataItem.departmentName }}</span>
                            </td>
                            
                            <td slot="Shift" style="width:6%;"
                                :class="[dataItem.overtimeShift ? 'gridOverTime': '',
                                (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                0) || dataItem.isCancelled) ? 'strikethrough': '',
                                (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']"
                                  :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled)  
                                || (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId)?{'text-decoration': 'line-through'}:'']">
                               
                                {{ dataItem.shiftCode }}
                            </td>
                            
                            <td slot="Start Time" style="width:7%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                            (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']"
                                  :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled)  
                                || (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId)?{'text-decoration': 'line-through'}:'']">
                               
                               {{ setGridTimeFormat(dataItem.startTime) }}
                            </td>
                            
                            <td slot="End Time" style="width:7%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                            (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']"
                                  :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled)  
                                || (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId)?{'text-decoration': 'line-through'}:'']">
                               
                                {{ setGridTimeFormat(dataItem.endTime) }}
                            </td>
                            
                            <td slot="Type" style="width:8%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                            (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']"
                                :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled)  
                                || (dataItem.origDepartmentId !== dataItem.departmentId && payloadExportToExcel.departmentId === dataItem.origDepartmentId)?{'text-decoration': 'line-through'}:'']">
                                 {{ dataItem.employeeType }}
                            </td>
                            
                            <td slot="Hours" style="width:8%;"
                                :class="[dataItem.isOvertime ? 'gridOverTime': '','tableRow_cell']">
                                {{ dataItem.hours }}
                            </td>
                            
                            <td slot="RN Experience" style="width:10%;" v-if="dataItem.rnExperienceFeatureEnabled"
                                :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '','tableRow_cell']">
                                {{ dataItem.rnExperience }}
                            </td>
                            
                            <td slot="Hire Date">
                                {{ setGridDateFormat(dataItem.hireDate) }}
                            </td>
                            
                            <td slot="Last Cancelled">
                                {{ setGridDateFormat(dataItem.lastCanceledDate) }}
                            </td>
                            
                            <td slot="Last Floated">
                                {{ setGridDateFormat(dataItem.lastFloatedDate) }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <table border="1" class="tableClass" v-else-if="payloadExportToExcel.tabName == 'other'">
                <thead>
                    <tr header class="tableRow" style="padding-top: 25px !important; padding-bottom: 10px !important;">
                        
                        <th class="tableRowHeading tableRowHeading_large"
                            style="width:15%;">
                            Charge
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Name
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:13%;">
                            Skills
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:13%;">
                            Comps
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Department
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Shift
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            Start Time
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            End Time
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:8%;">
                            Type
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:8%;">
                            Hours
                        </th>
                        <th v-if="isRNExperienceFeatureEnabled" class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            RN Experience
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Hire Date
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Last Cancelled
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Last Floated
                        </th>
                    </tr>
                </thead>
                <tbody align="left">
                    <template v-if="exportToExcelData != null && exportToExcelData.length > 0">
                        <template v-for="dept in exportToExcelData" :key="dept">
                            
                            <tr class="departmentGroupRow"  >
                                
                                <td slot="DepartmentGroup">
                                    <font-awesome-icon :icon="['fas', 'caret-right']" size="sm"
                                                       :class="[dept.isDeptExpand ? 'collapseIcon': '']"
                                                       style="padding-right: 5px;" />
                                    <b>{{dept.DepartmentName}}</b>
                                    <template v-for="skillVol in dept.SkillList" :key="skillVol">
                                        <template v-if="skillVol.StaffVolume < 0">
                                            &nbsp;
                                            <span class="status-under-staffed">{{skillVol.SkillName}} ({{skillVol.StaffVolume}})</span>
                                        </template>
                                        <template v-if="skillVol.StaffVolume > 0">
                                            &nbsp;
                                            <span class="status-over-staffed">{{skillVol.SkillName}} ({{skillVol.StaffVolume}})</span>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                            <template v-for="skill in dept.SkillList" :key="skill">
                                
                                <tr  
                                               :class="[!dept.isDeptExpand ? 'd-none': '','skillGroupRow']">
                                    
                                    <td slot="SkillGroup">
                                        <font-awesome-icon :icon="['fas', 'caret-right']" size="sm"
                                                           :class="[skill.isSkillExpand ? 'collapseIcon': '']"
                                                           style="padding-right: 5px;" />
                                        <b>Skill: {{skill.SkillName}}</b>
                                        <template v-if="skill.StaffVolume < 0">
                                            &nbsp;
                                            <span class="status-under-staffed">Under Staffed ({{skill.StaffVolume}})</span>
                                        </template>
                                        <template v-if="skill.StaffVolume > 0">
                                            &nbsp;
                                            <span class="status-over-staffed">Over Staffed ({{skill.StaffVolume}})</span>
                                        </template>
                                    </td>
                                </tr>
                                
                                <tr  v-for="dataItem in skill.otherGridData" 
                                    :key="dataItem" :uid="dataItem.uid" :staffId="dataItem.staffId"
                                    :class="[dataItem.profileCharge ? 'chargeAssignment': '',
                                    !dept.isDeptExpand ? 'd-none' : '' ,
                                    !skill.isSkillExpand ? 'd-none' : '' ,
                                    (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                    0) || dataItem.isCancelled) ? 'cancelledAssignment': '',
                                    dataItem.origDepartmentId !== dataItem.departmentId && !(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'floatedAssignment' : '']"
                                    @click="onRowSelect(dataItem)">
                                    
                                    <td class="tableRow_cell" slot="Name" style="width:8%;">
                                        <span v-if="dataItem.charge">
                                            Yes
                                        </span>
                                    </td>
                                    <td slot="Name" :title="dataItem.name">
                                        {{ dataItem.name }}
                                        <span v-if="dataItem.charge">
                                            <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                        </span>
                                        <span v-if="dataItem.associationType === associationTypes.secondary">
                                            <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                        </span>
                                        <span v-if="dataItem.associationType === associationTypes.unassociated">
                                            <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                        </span>
                                        <span v-if="dataItem.isFloat">
                                            <img style="width: 24px;float: right" src="@/assets/float.png" alt="Float" />
                                        </span>
                                    </td>
                                    
                                    <td slot="Skill" :title="dataItem.skillLevelDescription"
                                        :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                               
                                        {{ dataItem.skillLevelDescription }}
                                    </td>
                                    
                                    <td slot="Comps" style="white-space:nowrap;" class="col-comps"
                                        :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                                        {{ dataItem.competencyCodes }}
                                    </td>
                                    
                                    <td slot="Department" :title="dataItem.departmentName"
                                        :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                                        <span v-if="dataItem.origDepartmentId !== dataItem.departmentId">
                                            {{ dataItem.departmentName }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentName}}  </span>
                                        </span>
                                        <span v-else> {{ dataItem.departmentName }}</span>
                                    </td>
                                    
                                    <td slot="Shift"
                                        :class="[dataItem.overtimeShift ? 'gridOverTime': '',
                                        (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                        0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                                        {{ dataItem.shiftCode }}
                                    </td>
                                    
                                    <td slot="Start Time"
                                        :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                                        {{ setGridTimeFormat(dataItem.startTime) }}
                                    </td>
                                    
                                    <td slot="End Time"
                                        :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                                        {{ setGridTimeFormat(dataItem.endTime) }}
                                    </td>
                                    
                                    <td slot="Type"
                                        :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']"
                                        :style="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? {'text-decoration': 'line-through'}:'']">
                                        {{ dataItem.employeeType }}
                                    </td>
                                    
                                    <td slot="Hours"
                                        :class="[dataItem.isOvertime ? 'gridOverTime': '']">
                                        {{ dataItem.hours }}
                                    </td>
                                    
                                    <td slot="RN Experience" v-if="dataItem.rnExperienceFeatureEnabled"
                                        :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '']">
                                        {{ dataItem.rnExperience }}
                                    </td>
                                    
                                    <td slot="Hire Date" :title="setGridDateFormat(dataItem.hireDate)">
                                        {{ setGridDateFormat(dataItem.hireDate) }}
                                    </td>
                                    
                                    <td slot="Last Cancelled" :title="setGridDateFormat(dataItem.lastCanceledDate)">
                                        {{ setGridDateFormat(dataItem.lastCanceledDate) }}
                                    </td>
                                    
                                    <td slot="Last Floated" :title="setGridDateFormat(dataItem.lastFloatedDate)">
                                        {{ setGridDateFormat(dataItem.lastFloatedDate) }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
            <table border="1" class="tableClass" v-else>
                    <thead>
                        <tr header class="tableRow" style="padding-top: 25px !important; padding-bottom: 10px !important;">
                            
                            <th class="tableRowHeading tableRowHeading_large"
                                style="width:15%;">
                                Name
                            </th>
                            <th class="tableRowHeading tableRowHeading_large"
                                style="width:15%;">
                                Association
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                                Skill
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:8%;">
                                Type
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                                Worked Hours
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                                Scheduled Hours
                            </th>
                            <th v-if="isRNExperienceFeatureEnabled" class="tableRowHeading tableRowHeading_large" style="width:10%;">
                                RN Experience
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                                Last Worked Date
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                                Last Worked Time
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                                Next Assignment Date
                            </th>
                            <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                                Next Assignment Time
                            </th>
                        </tr>
                    </thead>
                    <tbody align="left">
                        <template v-if="exportToExcelData != null && exportToExcelData.length > 0">
                            <tr v-for="dataItem in exportToExcelData" 
                                           :key="dataItem" 
                                           :class="[dataItem.charge ? 'chargeAssignment': '']">
                                
                                <td slot="Name">
                                    {{dataItem.name}}
                                </td>
                                <td>
                                    <span v-if="dataItem.associationType === associationTypes.secondary">
                                        Secondary
                                    </span>
                                    <span v-else-if="dataItem.associationType === associationTypes.unassociated">
                                        Unassociated
                                    </span>
                                </td>
                                <td slot="Skill" >
                                    {{ dataItem.skillDescription }}
                                </td>
                                
                                <td slot="Type">
                                    {{ dataItem.employeeType }}
                                </td>
                                
                                <td slot="Worked Hrs" :class="[dataItem.hoursWorked > dataItem.hours ? 'gridOverTime': '']">
                                    {{ dataItem.hoursWorked }}
                                </td>
                                
                                <td slot="Sched. Hrs">
                                    {{ dataItem.hours }}
                                </td>
                                <td slot="RN Experience" v-if="dataItem.rnExperienceFeatureEnabled"
                                           :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '']" >
                                    {{ dataItem.rnExperience }}
                                </td>
                                
                                <td slot="Last Worked Date">
                                    {{ setGridDateFormat(dataItem.lastWorked) }}
                                </td>
                                
                                <td slot="Last Worked Time">
                                    {{ setGridTimeFormat(dataItem.lastWorked) }}
                                </td>
                                
                                <td slot="Next Assignment Date">
                                    {{ setGridDateFormat(dataItem.nextAssignmentDate) }}
                                </td>
                                
                                <td slot="Next Assignment Time">
                                    {{ setGridTimeFormat(dataItem.nextAssignmentStart) }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
        </div>
        <div id="exportToExcelMessage" class="care-no-assingnments" v-if="isShowExportToExcelMessage" style="padding-top: 25px !important; padding-bottom: 10px !important;">
            <p id="home-message-text"> {{exportToExcelMessage}}</p>
        </div>
    </div>
</template>

<script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";

    //Import Componenets
    import {
        NeuTableRowCount,
        NeuTable,
        NeuTableRow,
        NeuLabel,
        NeuTableBody,
        NeuTableHeading,
        NeuCard
    } from "@neutron/vue";

    class Props {
        payloadExportToExcel!: any
    }

    @Options({
        components: {
            NeuTableRowCount,
            NeuTable,
            NeuTableRow,
            NeuLabel,
            NeuTableBody,
            NeuTableHeading,
            NeuCard
        }
    })
    export default class ExportToExcelForTabs extends Vue.with(Props) {

        shortDateFormat: any = 'MM/DD/YYYY';
        shortTimeFormat: any = 'h:mm a';
        shortDateFormatPresentation: any = 'MM/DD/YYYY';
        shortDateTimeFormat: any = 'YYYY-MM-DD h:mm a';
        homeDepartmentId: any = 0;
        exportToExcelViewModel: any = {
            facilityName: null,
            serviceLineName: null,
            departmentName: false,
            startDate: null,
            startTime: null,
            endTime: null,
            staffTo: null,
            actualCensus: null,
            forecastCensus: null,
            skillLevelDescription: null
        };
        isShowExportToExcelContent: boolean = false;
        isShowExportToExcelMessage: boolean = false;
        isRNExperienceFeatureEnabled: boolean = false;
        exportToExcelMessage: string = "";
        exportToExcelData!: any;
        associationTypes: any = {
            primary: 1,
            secondary: 2,
            unassociated: 3
        };

        mounted() {
            this.loadExportToExcelDetails();
        }

        loadExportToExcelDetails() {
            if (this.payloadExportToExcel != null) {
                this.exportToExcelViewModel.facilityName = this.payloadExportToExcel.headerInfo.fullFacilityName;
                this.exportToExcelViewModel.serviceLineName = this.payloadExportToExcel.headerInfo.serviceLineName;
                this.exportToExcelViewModel.departmentName = this.payloadExportToExcel.headerInfo.fullDepartmentName;
                this.exportToExcelViewModel.startDate = this.payloadExportToExcel.headerInfo.startDate;
                this.exportToExcelViewModel.startTime = this.payloadExportToExcel.headerInfo.startTime;
                this.exportToExcelViewModel.endTime = this.payloadExportToExcel.headerInfo.endTime;
                this.exportToExcelViewModel.staffTo = this.payloadExportToExcel.headerInfo.staffTo;
                this.exportToExcelViewModel.actualCensus = this.payloadExportToExcel.headerInfo.actualCensus;
                this.exportToExcelViewModel.forecastCensus = this.payloadExportToExcel.headerInfo.forecastCensus;
                this.exportToExcelViewModel.skillLevelDescription = this.payloadExportToExcel.skillLevelDescription;
                
                if (this.payloadExportToExcel.dataSource != null && this.payloadExportToExcel.dataSource.length > 0) {
                    this.isShowExportToExcelContent = true;
                    this.isShowExportToExcelMessage = false;

                    this.exportToExcelData = this.payloadExportToExcel.dataSource;
                    if (this.payloadExportToExcel.tabName.toLowerCase() == "other") {
                        this.isRNExperienceFeatureEnabled = this.payloadExportToExcel.dataSource[0].SkillList[0].otherGridData[0].rnExperienceFeatureEnabled;
                    }
                    else {
                        this.isRNExperienceFeatureEnabled = this.payloadExportToExcel.dataSource[0].rnExperienceFeatureEnabled;
                    }
                }
                else {
                    this.isShowExportToExcelMessage = true;
                    this.isShowExportToExcelContent = false;
                    this.exportToExcelMessage = "No resources found";
                }

                var that = this;
                setTimeout(() => {
                    that.exportToExcel();
                }, 1000);
            }
        }

        setGridTimeFormat(time: any) {
            if (time != null && time != '') {
                var formattedTime: any = moment(time).format("hh:mm A");
                return formattedTime;
            }
            else {
                return '';
            }
        }
        setGridDateFormat(date: any) {
            if (date != null && date != '') {
                var formattedDate: any = moment(date).format("MM/DD/YYYY");
                return formattedDate;
            }
            else {
                return '';
            }
        }

        exportToExcel() {
            var downloadLink;
            var dataType = 'application/vnd.ms-excel';
            var tableSelect: any = document.getElementById('exportToExcelLayout');
            var tableHTML = tableSelect.outerHTML;

            var filename: any;

            // Specify file name
            filename = (this.payloadExportToExcel.tabName.toLowerCase() == "home" ? 'Department Details.xls' : (this.payloadExportToExcel.tabName.toLowerCase() == "other" ? 'Service Line.xls' : (this.payloadExportToExcel.tabName.toLowerCase() == "unscheduled" ? 'Unscheduled Staff.xls' : '')));

            // Create download link element
            downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            var blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });

            var blobUrl = URL.createObjectURL(blob);

            // Create a link to the file
            downloadLink.href = blobUrl;
            
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
            
            this.$emit('closeExcelTemplate');
        }
    }
</script>
<style scoped>
    .d-none {
        display: none;
    }

    .tableClass {
        width: 100%;
        overflow: hidden;
    }

    .tableRow {
        --selected-color: var(--neu-color-primary-20, #aecde5);
        width: 100%;
        border-bottom: 1px solid var(--neu-color-gray-40, #939393);
    }

    .tableRow_cell {
        font-size: small !important;
        text-align: center;
    }

    .tableRowHeading_large {
        height: 51px;
        max-height: 51px;
    }

    .tableRowHeading {
        justify-content: space-between;
        background: transparent;
        font-size: small !important;
        text-align: center;
    }

    @media print {
        .cancelledAssignment {
            color: #B33E3E !important;
            background-color: #F6DBDB !important;
        }

        .rn-experience {
            font-weight: bold;
            background-color: #FFC845;
            color: black;
        }
    }
</style>