<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">My Preferences</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody">
                <neu-tablist :value="selectedTab" class="mx-4 tabList">
                    <neu-tab
                        white
                        tab="Filters"
                        @click="showFiltersTab">
                        <div>Filters </div>
                    </neu-tab>
                    <neu-tab
                        white
                        tab="EDSurge"
                        @click="showEDSurgeTab">
                        <div>ED Surge </div>
                    </neu-tab>
                </neu-tablist>
                <div id="filters-details" class="panelTabData scrollBar" v-if="isFiltersVisible" role="tabpanel">
                    <Filter/>
                </div>
                <div id="ED-surge-details" class="panelTabData scrollBar" v-if="isEDSurgeVisible" role="tabpanel">
                    <EDSurge/>
                </div>
            </div>  
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';

//import components
import {
  NeuTab,
  NeuTablist
} from "@neutron/vue";
import Filter from './Filter.vue';
import EDSurge from './EDSurge.vue';

@Options({
  components: {
    Filter,
    EDSurge,
    NeuTab,
    NeuTablist,
  },
})
export default class UserPreferences extends Vue {
  //Data properties
    isFiltersVisible: boolean = true;
    isEDSurgeVisible: boolean = false;
    selectedTab: string = "Filters";

  //Lifecycle hooks
  mounted() {
    
  }

  //Methods
  showFiltersTab(){
    this.isFiltersVisible = true;
    this.isEDSurgeVisible = false;
  }
  showEDSurgeTab(){
    this.isEDSurgeVisible = true;
    this.isFiltersVisible = false;
  }
  close() {
        this.$emit("close-modal");
    }
}
</script>
<style scoped>

.c4c-modal {
    width: 90%;
    height: 90%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    padding: 5px 12px;
    height: 91%;
}
.panelTabData{
    overflow: auto;
    padding-top: 1rem;
    height: 91%;
}

</style>