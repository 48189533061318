<template>
    <div v-if="isShowClockInContent">
        <br> 
        <div class="row"> 
            <div class="col-md-7">
                <div class="clockin-details-container"> 
                    <h5>Clock-In Details</h5> 
                </div>
                <div class="clockin-details-grid">
                    <table class="table table-bordered plusmetric-summary clockin-print">
                        <colgroup>
                            <col style="width: 27px;">
                            <col>
                            <col style="width:20%">
                            <col style="width:20%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="heading"></th>
                                <th class="heading">Name</th>
                                <th class="heading">Shift Start Time</th>
                                <th class="heading">Clock-In Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="clockInDataList != null && clockInDataList.length > 0">
                                <template v-for="clock in clockInDataList" :key="clock">
                                    <tr class="clockInStatusGroupRow grouping-row" >
                                        <td colspan="4">
                                            <p>{{ clock.clockedInGroupText }}</p>
                                        </td>
                                    </tr>
                                    <template v-for="dataItem in clock.clockInList" :key="dataItem">
                                        <tr>  
                                            <td class="group-cell">
                                                
                                            </td>          
                                            <td  :title="dataItem.name">
                                                {{ dataItem.name }}
                                            </td>
                                            <td >
                                                {{ dataItem.beginningShift }}
                                            </td>
                                            <td >
                                                {{ dataItem.actualTimeIn }}
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets


class Props {
    clockInsInput!: any
}
@Options({
    components: {

    }
})
export default class ClockInsReport extends Vue.with(Props) {  
    //Data properties
    clockInDetails: any = null;
    clockInDataList: any = null;
    isShowClockInContent: boolean = false;
    groupingTextOrder: any[] = ['NOT CLOCKED IN', 'CLOCKED IN & UNSCHEDULED', 'CLOCKED IN'];

    //Lifecycle hooks
    mounted(){
        this.loadClockInGrid()
    }
    //Methods
    loadClockInGrid(){
        var timeDetails: any = this.clockInsInput.shiftClockIns;

        if(timeDetails != null && timeDetails.length > 0){
                this.clockInDetails = [];
                timeDetails.forEach((item: any) => {
                var empData: any = {};
                empData.name = item.employeeName;
                empData.beginningShift = (item.beginShiftDate === null || item.beginShiftDate === '') ? '-' : moment(item.beginShiftDate).format('hh:mm A');
                empData.actualTimeIn = (item.clockIn === null || item.clockIn === '') ? '-' : moment(item.clockIn).format('hh:mm A');
                empData.clockedIn = (item.clockInStatus.toLowerCase() === 'Clocked In'.toLowerCase()) ? 'Yes' : 'No';
                empData.clockedIn = (item.clockInStatus.toLowerCase() === 'Clocked In And Unscheduled'.toLowerCase()) ? 'unscheduled' : empData.clockedIn;
                empData.clockedInText = this.getClockInGroupText(empData.clockedIn); 
                this.clockInDetails.push(empData);
            });
            this.generateGridData();
            this.isShowClockInContent = true;
        }
        else{
            this.isShowClockInContent = false;
        }
    }

    getClockInGroupText(clockInStatus: any){
        var groupingtext: string = ''
            switch (clockInStatus) {
                case "No":
                    groupingtext = 'NOT CLOCKED IN';
                    break;
                case "unscheduled":
                    groupingtext = 'CLOCKED IN & UNSCHEDULED';
                    break;
                case "Yes":
                    groupingtext = 'CLOCKED IN'
                    break;
            }
        return groupingtext;
    }

    generateGridData(){
        if(this.clockInDetails != null && this.clockInDetails.length > 0){
            this.clockInDataList = [];

            //@ts-ignore
            var distinctClockInStatus: any = jslinq(this.clockInDetails)
                    .select((x: any)=> x.clockedInText)
                    .distinct()
                    .toList();

            if(distinctClockInStatus != null && distinctClockInStatus.length > 0){
                distinctClockInStatus.sort((a: any, b: any) => {
                    return this.groupingTextOrder.indexOf(a) - this.groupingTextOrder.indexOf(b)
                });
                
                distinctClockInStatus.forEach((item: any)=>{
                    var clockData: any = {};

                    clockData.clockedInGroupText = item;

                    //@ts-ignore
                    var clockInList: any = jslinq(this.clockInDetails)
                    .where((data: any) => { 
                        if(data.clockedInText == item){
                            return data;
                        }})
                    .toList();

                    if(clockInList != null && clockInList.length > 0){
                        clockData.clockInList = clockInList;
                    }

                    this.clockInDataList.push(clockData);
                });
            }
        }
    }
}
</script>
<style scoped>
.table-bordered {
    border: 1px solid #CED1D7;
}

.grouping-row td{
    border-bottom-width: 0;
    overflow: visible;
    color: #003f59;
    font-weight: bolder;
    background-color: #d9ecf5;
    font-size: 13px;
}

.grouping-row p{
    display: inline-block;
    vertical-align: middle;
    margin-left: -0.6em;
    padding: 0 0.6em;
}

.group-cell{
    border-bottom-width: 0;
    border-top-width: 0;
    background-color: #d9ecf5;
}
#dailyRosterSearchModal table.plusmetric-summary td.heading {
        color: white;
        background-color: #004c8b;
    }

#dailyRosterSearchModal table.plusmetric-summary th.heading {
        color: white;
        background-color: #004c8b;
    }

    @media print {
    table.clockin-print td {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    table.clockin-print th {
        font-size: xx-small !important;
        padding: 0px !important;
    }
}
</style>