import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8767b500"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "grid-container",
  style: {"width":"100%"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { style: {"display":"inline-flex"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "spinner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubFilterComponent = _resolveComponent("SubFilterComponent")!
  const _component_MetricComponent = _resolveComponent("MetricComponent")!
  const _component_neu_label = _resolveComponent("neu-label")!
  const _component_neu_icon = _resolveComponent("neu-icon")!
  const _component_neu_tooltip = _resolveComponent("neu-tooltip")!
  const _component_neu_image = _resolveComponent("neu-image")!
  const _component_GridComponent = _resolveComponent("GridComponent")!
  const _component_ScheduleSummaries = _resolveComponent("ScheduleSummaries")!
  const _component_LegendIcons = _resolveComponent("LegendIcons")!
  const _component_neu_container = _resolveComponent("neu-container")!
  const _component_neu_spinner = _resolveComponent("neu-spinner")!
  const _component_NeuContainer = _resolveComponent("NeuContainer")!
  const _component_AddOrCancelShiftModal = _resolveComponent("AddOrCancelShiftModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SubFilterComponent, {
      "show-date-range": "",
      "filter-columns": _ctx.ScheduleFilterColumns,
      "default-filters": [],
      onSubnavinitialized: _ctx.subNavInitialized,
      onFiltersApplied: _ctx.filtersApplied,
      "schedule-phase-end": _ctx.departmentSchedule?.schedulePhaseEnd,
      "schedule-status": _ctx.departmentSchedule?.scheduleStatus,
      "helper-enabled": _ctx.departmentSchedule?.helperEnabled,
      "is-editable": _ctx.isEditable,
      "is-schedule-rejected": _ctx.isScheduleRejected,
      "tier-name": _ctx.departmentSchedule?.tierName
    }, null, 8, ["filter-columns", "onSubnavinitialized", "onFiltersApplied", "schedule-phase-end", "schedule-status", "helper-enabled", "is-editable", "is-schedule-rejected", "tier-name"]),
    (_ctx.isGridVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.screenLoading)
            ? (_openBlock(), _createBlock(_component_neu_container, {
                key: 0,
                class: "mt-2 mw-100 content-wrapper grid-margin-top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MetricComponent, {
                    "schedule-status": _ctx.scheduleStatus,
                    metrics: _ctx.metrics,
                    "helper-enabled": _ctx.helperEnabled
                  }, null, 8, ["schedule-status", "metrics", "helper-enabled"]),
                  _createElementVNode("div", _hoisted_2, [
                    (!_ctx.gridLoading)
                      ? (_openBlock(), _createBlock(_component_GridComponent, {
                          ref: "gridSection",
                          key: _ctx.gridKey,
                          "show-checkbox": false,
                          "show-filters": true,
                          "show-pagination": false,
                          "show-row-count": false,
                          "show-fixed-columns": true,
                          "columns-data": _ctx.gridColumns,
                          "rows-data": _ctx.scheduleRows,
                          "is-api-data": true,
                          "store-data": true,
                          "infinity-scroll": true,
                          "preferences-triangle-modal": _ctx.preferencesTriangleToggle,
                          loader: false,
                          "default-filters": [],
                          onFilterData: _ctx.filterData,
                          onClearFilter: _ctx.clearFilter
                        }, {
                          indication: _withCtx((props) => [
                            (props.row.indicationType === 'OVER')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px"} }, {
                                    default: _withCtx(() => [
                                      (props.row.indicationToolTip)
                                        ? (_openBlock(), _createBlock(_component_neu_label, {
                                            key: 0,
                                            slot: "tooltip",
                                            class: "left-text-align"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(props.row.indicationToolTip), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_neu_icon, {
                                        slot: "content",
                                        left: true,
                                        color: "red-40"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("event_busy")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true),
                            (props.row.indicationType == 'UNDER')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px"} }, {
                                    default: _withCtx(() => [
                                      (props.row.indicationToolTip)
                                        ? (_openBlock(), _createBlock(_component_neu_label, {
                                            key: 0,
                                            slot: "tooltip",
                                            class: "left-text-align"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(props.row.indicationToolTip), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_neu_icon, {
                                        slot: "content",
                                        left: true,
                                        color: "yellow-40"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" calendar_today ")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          icon: _withCtx((props) => [
                            _createElementVNode("div", _hoisted_5, [
                              (props.row.rnOneYear === '< 1 YR')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px","top":"3px"} }, {
                                      default: _withCtx(() => [
                                        (props.row.rnOneYear)
                                          ? (_openBlock(), _createBlock(_component_neu_label, {
                                              key: 0,
                                              slot: "tooltip",
                                              class: "left-text-align"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" Staff with <1 year experience ")
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_neu_icon, {
                                          slot: "content",
                                          left: true,
                                          color: "blue-60"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("star_outline")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : _createCommentVNode("", true),
                              (props.row.overtime === true)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px","top":"3px"} }, {
                                      default: _withCtx(() => [
                                        (props.row.overtimeTooltip)
                                          ? (_openBlock(), _createBlock(_component_neu_label, {
                                              key: 0,
                                              slot: "tooltip",
                                              class: "left-text-align"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(props.row.overtimeTooltip), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_neu_icon, {
                                          slot: "content",
                                          left: true,
                                          color: "red-60"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("more_time")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : _createCommentVNode("", true),
                              (props.row.preceptor === true)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px","top":"3px"} }, {
                                      default: _withCtx(() => [
                                        (props.row.preceptor === true)
                                          ? (_openBlock(), _createBlock(_component_neu_label, {
                                              key: 0,
                                              slot: "tooltip",
                                              class: "left-text-align"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(props.row.preceptorTooltip), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_neu_icon, {
                                          slot: "content",
                                          left: true,
                                          color: "blue-60"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("supervisor_account")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : _createCommentVNode("", true),
                              (props.row.orientee === true)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px","top":"3px"} }, {
                                      default: _withCtx(() => [
                                        (props.row.orientee === true)
                                          ? (_openBlock(), _createBlock(_component_neu_label, {
                                              key: 0,
                                              slot: "tooltip",
                                              class: "left-text-align"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(props.row.orienteeTooltip), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_neu_icon, {
                                          slot: "content",
                                          left: true,
                                          color: "blue-60"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("school")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            (props.row.departmentAssociationType == 2)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                  _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px"} }, {
                                    default: _withCtx(() => [
                                      (props.row.indicationToolTip)
                                        ? (_openBlock(), _createBlock(_component_neu_label, {
                                            key: 0,
                                            slot: "tooltip",
                                            class: "left-text-align"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" Secondary Association ")
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_neu_icon, {
                                        slot: "content",
                                        left: true,
                                        color: "blue-60"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" supervised_user_circle ")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true),
                            (props.row.departmentAssociationType == 3)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createVNode(_component_neu_tooltip, { style: {"margin-left":"5px"} }, {
                                    default: _withCtx(() => [
                                      (props.row.indicationToolTip)
                                        ? (_openBlock(), _createBlock(_component_neu_label, {
                                            key: 0,
                                            slot: "tooltip",
                                            class: "left-text-align"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" Unassociated ")
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_neu_icon, {
                                        slot: "content",
                                        left: true,
                                        color: "blue-60"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" person_pin ")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          date: _withCtx((props) => [
                            _createElementVNode("div", {
                              class: _normalizeClass(["data_cell", 
                _ctx.deriveDateCellClassesAndTooltip(
                  props,
                  props.column.id,
                  props.row.row_id
                )
              ]),
                              onClick: ($event: any) => (_ctx.dateCellClick(props, $event))
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStaffAvailiblityIcon(props.column.id, props.row.row_id, props.column.year), (list) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: `${props.row.row_id}-${list.type}`
                                }, [
                                  (list.display && list.img)
                                    ? (_openBlock(), _createBlock(_component_neu_tooltip, {
                                        key: 0,
                                        style: {"position":"absolute !important","left":"0","top":"0"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_neu_label, { slot: "tooltip" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(list.tooltip), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_neu_image, {
                                            width: "25px",
                                            src: _ctx.getImgUrl(list),
                                            slot: "content"
                                          }, null, 8, ["src"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _createElementVNode("span", {
                                class: _normalizeClass(_ctx.getAssignmentCodeColor(props.row[props.column.id]))
                              }, _toDisplayString(props.row[props.column.id]?.text), 3)
                            ], 10, _hoisted_12)
                          ]),
                          _: 1
                        }, 8, ["columns-data", "rows-data", "preferences-triangle-modal", "onFilterData", "onClearFilter"]))
                      : _createCommentVNode("", true),
                    (!_ctx.gridLoading)
                      ? (_openBlock(), _createBlock(_component_ScheduleSummaries, {
                          key: 1,
                          ref: "scheduleSummary",
                          "columns-data": _ctx.gridColumns,
                          dateCellProps: _ctx.dateCellProps,
                          onScheduleData: _ctx.triggerSchedule,
                          "is-editable": _ctx.isEditable
                        }, null, 8, ["columns-data", "dateCellProps", "onScheduleData", "is-editable"]))
                      : _createCommentVNode("", true)
                  ]),
                  _withDirectives(_createVNode(_component_LegendIcons, {
                    status: _ctx.scheduleStatus,
                    "helper-enabled": _ctx.helperEnabled
                  }, null, 8, ["status", "helper-enabled"]), [
                    [_vShow, !_ctx.gridLoading]
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.screenLoading || _ctx.gridLoading)
            ? (_openBlock(), _createBlock(_component_neu_container, {
                key: 1,
                class: "mt-9 mw-100"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_neu_spinner, {
                      class: "spinner",
                      color: "primary",
                      sm: "true"
                    })
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_NeuContainer, {
          key: 1,
          class: "text-wrap"
        }, {
          default: _withCtx(() => [
            (       
        _ctx.securityStore.getSelectedFacility === ''
      )
              ? (_openBlock(), _createBlock(_component_neu_label, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Select a facility to access available department list ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
        _ctx.securityStore.getSelectedFacility != '' &&
        _ctx.securityStore.getSelectedDepartment === ''
      )
              ? (_openBlock(), _createBlock(_component_neu_label, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Select a department to access available date list ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })),
    (_ctx.isSidebarOpen && _ctx.isViewable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["sidebar-container", { sidebar_opened: _ctx.isSidebarOpen }])
        }, [
          (_ctx.openAddShift)
            ? (_openBlock(), _createBlock(_component_AddOrCancelShiftModal, {
                key: _ctx.selectCellKey + 1,
                "date-cell-props": _ctx.dateCellProps,
                "selected-department-id": _ctx.selectedDepartmentId,
                onModal_close: _ctx.closeSidebar,
                onSubmitModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submitData($event))),
                "is-editable": _ctx.isEditable,
                "selected-date": _ctx.selectedDate
              }, null, 8, ["date-cell-props", "selected-department-id", "onModal_close", "is-editable", "selected-date"]))
            : _createCommentVNode("", true),
          (_ctx.openCancelShift)
            ? (_openBlock(), _createBlock(_component_AddOrCancelShiftModal, {
                key: _ctx.selectCellKey + 2,
                "date-cell-props": _ctx.dateCellProps,
                "modal-title": "Shift Details",
                "cancel-modal": true,
                "schedule-status": _ctx.scheduleStatus,
                "helper-enabled": _ctx.helperEnabled,
                "selected-department-id": _ctx.selectedDepartmentId,
                "modal-save": 'Cancel Shift',
                onModal_close: _ctx.closeSidebar,
                onSubmitModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitData($event))),
                onUpdateModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateAssignmentData($event))),
                "is-editable": _ctx.isEditable,
                "selected-date": _ctx.selectedDate,
                onDeleteModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteAssignmentData()))
              }, null, 8, ["date-cell-props", "schedule-status", "helper-enabled", "selected-department-id", "onModal_close", "is-editable", "selected-date"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}