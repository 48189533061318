import {
  AssignmentCancelModel,
  AssignmentModel,
} from '@/models/scheduleManagement/shiftPanels.model'
import {
  addAssignment,
  cancelAssignment,
  cancelReasons,
  departmentShifts,
  getDepartmentSchedule,
  getSkillLevels,
  getEmployeeIds,
  getScheduleRequest,
  updateAssignment,
  getUndoScheduleRequest,
  getRejctScheduleRequest,
  noteAssignment,
deleteAssignment,
} from '@/services/schedule'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ name: 'scheduleModule', stateFactory: true })
export default class ScheduleModule extends VuexModule {
  departmentScheduleList: any = []
  addShiftPanel: Boolean = false
  cancelShiftPanel: Boolean = false
  overStaffPanel: Boolean = false
  underStaffPanel: Boolean = false
  cancelReasonsData: any = []
  departmentShiftsData: any = []
  isDirty: Boolean = false
  skillLevels: any = {}
  employeeIds: any = {}
  scheduleRequest: any = {}
  scheduleRequestLoading: boolean = true
  isSummaryUpdate: boolean = false

  get departmentSchedule() {
    return this.departmentScheduleList
  }
  get addShiftModal() {
    return this.addShiftPanel
  }
  get cancelShiftModal() {
    return this.cancelShiftPanel
  }
  get overStaffModal() {
    return this.overStaffPanel
  }
  get underStaffModal() {
    return this.underStaffPanel
  }
  get getCancelReasons() {
    return this.cancelReasonsData
  }
  get getDepartmentShifts() {
    return this.departmentShiftsData
  }
  get dirty() {
    return this.isDirty
  }
  get getSkillLevelData() {
    return this.skillLevels
  }
  get getEmployeeIdData() {
    return this.employeeIds
  }
  get getGenerateScheduleRequest() {
    return this.scheduleRequest
  }
  get getGenerateScheduleRequestLoading() {
    return this.scheduleRequestLoading
  }
  get getSummaryUpdate() {
    return this.isSummaryUpdate
  }

  @Mutation
  setDepartmentSchedule(data: any) {
    this.departmentScheduleList = data
  }
  @Mutation
  setOpenAddShift(val: boolean) {
    this.addShiftPanel = val
  }

  @Mutation
  setOpenCancelShift(val: boolean) {
    this.cancelShiftPanel = val
  }

  @Mutation
  setOverstaffShift(val: boolean) {
    this.overStaffPanel = val
  }

  @Mutation
  setUnderStafShift(val: boolean) {
    this.underStaffPanel = val
  }

  @Mutation
  setCancelReasons(data: any) {
    this.cancelReasonsData = data
  }
  @Mutation
  setDepartmentShifts(data: any) {
    this.departmentShiftsData = data
  }
  @Mutation
  setSkillLevels(data: any) {
    this.skillLevels = data
  }
  @Mutation
  setEmployeeIds(data: any) {
    this.employeeIds = data
  }

  @Mutation
  setDirty(val: boolean) {
    this.isDirty = val
  }
  @Mutation
  setScheduleRequest(val: boolean) {
    this.scheduleRequest = val
  }
  @Mutation
  setGenerateScheduleLoading(val: boolean) {
    this.scheduleRequestLoading = val
  }
  @Mutation
  setAssignmentNotes(data: any) {
    this.departmentScheduleList?.staff?.forEach((staff: any) => {
      const assignmentIndex = staff.assignments?.findIndex((assignment: any) => assignment?.assignmentId === data.id);
      if (assignmentIndex > -1) {
        staff.assignments[assignmentIndex].notes?.push(data.note)
      }
    })
  }
  @Mutation
  setAddAssignment(data: any) {
    const assignmentRes = data?.data?.results
    const staffIndex = this.departmentScheduleList?.staff?.findIndex((staff: any) => staff.staffId === assignmentRes?.staffId)
    if (staffIndex > -1) {
      const assignmentObject = {
        actualEndTime: assignmentRes.actualEndTime,
        actualStartTime: assignmentRes.actualStartTime,
        endTime: assignmentRes.endTime,
        startTime: assignmentRes.startTime,
        assignmentId: assignmentRes.assignmentId,
        code: assignmentRes.shiftCode,
        cancel: assignmentRes.cancel,
        charge: assignmentRes.charge,
        date: assignmentRes.date,
        departmentShiftId: assignmentRes.departmentShiftId,
        durationHours: assignmentRes.hours,
        needId: assignmentRes.needId,
        skillLevelId: assignmentRes.skillLevelId,
      }
      this.departmentScheduleList?.staff[staffIndex].assignments.push(assignmentObject)
    }
  }
  @Mutation
  updateSummaries(data: any) {
    this.isSummaryUpdate = data
  }

  @Action
  async allDepartmentSchedule(params: any) {
    try {
      await getDepartmentSchedule(params).then((res) => {
        this.context.commit('setDepartmentSchedule', res)
      })
    } catch (error) {
      this.context.commit('setDepartmentSchedule', {})
      return error
    }
  }

  @Action
  async allSkillLevelsByDeaprtment(tenantId: any) {
    try {
      const params = {
        departmentId: this.context.getters.departmentSchedule?.departmentId,
        date: this.context.getters.departmentSchedule?.scheduleStart,
      }
      await getSkillLevels(params, tenantId).then((res) => {
        this.context.commit('setSkillLevels', res)
      })
    } catch (error) {
      return error
    }
  }
  @Action
  async allEmployeeIds(tenantId: any) {
    try {
      await getEmployeeIds(tenantId).then((res) => {
        this.context.commit('setEmployeeIds', res)
      })
    } catch (error) {
      return error
    }
  }

  @Action
  async cancelAssignmentRequest(params: AssignmentCancelModel) {
    try {
      const assignmentId = params?.assignmentId
      await cancelAssignment(params, assignmentId)
      const payload = {
        selectedTenant: params.tenantId,
        selectedScheduleId: this.context.getters.departmentSchedule?.scheduleId
      }
      await getDepartmentSchedule(payload).then((res) => {
        this.context.commit('setDepartmentSchedule', res)
      })
    } catch (error: any) {
      return error
    }
  }
  @Action
  async deleteAssignmentRequest(params: any) {
    try {
      await deleteAssignment(params?.assignmentId, params?.tenantId)
      const payload = {
        selectedTenant: params?.tenantId,
        selectedScheduleId: this.context.getters.departmentSchedule?.scheduleId
      }
      await getDepartmentSchedule(payload).then((res) => {
        this.context.commit('setDepartmentSchedule', res)
      })
    } catch (error: any) {
      return error
    }
  }
  @Action
  async noteAssignmentRequest(params: any) {
    try {
      await noteAssignment(params)
    } catch (error: any) {
      return error
    }
  }

  @Action
  async addAssignmentRequest(params: AssignmentModel) {
    try {
      await addAssignment(params).then((res) => {
        this.context.commit('setAddAssignment', res)
        const payload = {
          selectedTenant: params.tenantId,
          selectedScheduleId: this.context.getters.departmentSchedule?.scheduleId
        }
        setTimeout(async() => {
          await getDepartmentSchedule(payload).then((res) => {
            this.context.commit('setDepartmentSchedule', res)
            this.context.commit('updateSummaries', true)
          })
        }, 500);
      })
    } catch (error: any) {
      return error
    }
  }
  @Action
  async updateAssignmentRequest(params: AssignmentModel) {
    try {
      await updateAssignment(params)
      const payload = {
        selectedTenant: params.tenantId,
        selectedScheduleId: this.context.getters.departmentSchedule?.scheduleId
      }
      await getDepartmentSchedule(payload).then((res) => {
        this.context.commit('setDepartmentSchedule', res)
      })
    } catch (error: any) {
      return error
    }
  }

  @Action
  async cancelReasonsRequest(id: any) {
    try {
      const response = await cancelReasons(id)
      this.context.commit('setCancelReasons', response)
    } catch (error: any) {
      return error
    }
  }

  @Action
  async departmentShiftsRequest(id: string) {
    try {
      const response = await departmentShifts(id)
      this.context.commit('setDepartmentShifts', response)
    } catch (error: any) {
      return error
    }
  }
  @Action
  async generateScheduleRequest(params: any) {
    try {
      const response = await getScheduleRequest(params)
      this.context.commit('setScheduleRequest', response)
    } catch (error: any) {
      return error
    }
  }
  @Action
  async undoScheduleRequest(params: any) {
    try {
      const response = await getUndoScheduleRequest(params)
      this.context.commit('setScheduleRequest', response)
    } catch (error: any) {
      return error
    }
  }
  @Action
  async rejectScheduleRequest(params: any) {
    try {
      const response = await getRejctScheduleRequest(params)
      this.context.commit('setScheduleRequest', response)
    } catch (error: any) {
      return error
    }
  }
}
