import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";

const namespaced = true

interface State {
    surgeSelectedDepartments: any
    searchDepartmentResult: any
    surgeAlert: any
}

//state
const state: State = {
    surgeSelectedDepartments: null,
    searchDepartmentResult: null,
    surgeAlert: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getSelectedDepartments({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/surgealertpreferences/${inputs.tenantId}/${inputs.fsUserId}/`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setSelectedDepartments', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    saveSelectedDepartment({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/surgealertpreferences/${inputs.tenantId}/${inputs.fsUserId}/`;

        return http
            .post(apiUrl, inputs.requestmodel)
            .then((res) => {
                if(res != null){
                    commit('setSelectedDepartments', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    searchDepartments({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/surgealertpreferences/${inputs.tenantId}/${inputs.fsUserId}?searchArgument=${inputs.searchText}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && res.data != null && res.data.data != null && res.data.data.results != null){
                    commit('setSearchDepartments', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSurgeAlerts({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/surgealerts/${inputs.tenantId}/${inputs.fsUserId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && res.data != null && res.data.data != null && res.data.data.results != null){
                    commit('setSurgeAlerts', res.data.data.results);
                }
                else{
                    commit('setSurgeAlerts', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
}

// mutations
const mutations: MutationTree<State> = {
    setSelectedDepartments(state, result: any){
        state.surgeSelectedDepartments = result;
    },
    setSearchDepartments(state, result: any){
        state.searchDepartmentResult = result;
    },
    setSurgeAlerts(state, result: any){
        state.surgeAlert = result;
    },
}

export const surgealert: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

