<template>
    <div id="home-content-loading" class="text-center mt-3" v-show="isShowLoader">
        <div class="fa-2x">
            <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
        </div>
        <p><b>Loading...</b></p>
    </div>
    <div id="home-content" v-if="isShowHomeContent">
        <div id="grdHome">
            <neu-card color="plain-0" class="p-0 m-0">
                <neu-table>
                    <neu-table-row :columns="columnHeaders" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column" style="padding-top: 25px !important;" @v-neu-change="sortData($event,column)">
                            <template v-if="(column != 'RN Experience' || (column == 'RN Experience' && isRNExperienceFeatureEnabled))">
                                {{ column }}
                                <font-awesome-icon :icon="['fas', 'filter']" size="lg"
                                                   id="filterIcon" :column="column"
                                                   @click="onFilterVisible($event, column)"
                                                   v-if="filterColumnList.includes(column)"
                                                   style="margin-left: 5px;" />
                            </template>
                        </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                        <template v-if="homeGridData != null && homeGridData.length > 0">
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-row :columns="columnHeaders" v-for="dataItem in homeGridData" :slot="column"
                                           :key="dataItem" :uid="dataItem.uid" :staffId="dataItem.staffId"
                                           :class="[dataItem.productiveId == 2 ? 'other-productive': '',
                                           dataItem.productiveId==3 ? 'non-productive' : '' ,
                                           dataItem.productiveId==4 ? 'call-assignment' : '' ,
                                           dataItem.profileCharge && dataItem.charge ? 'chargeAssignment' : '' ,
                                           (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                0) || dataItem.isCancelled) ? 'cancelledAssignment': '',
                                dataItem.origDepartmentId !== dataItem.departmentId && !(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'floatedAssignment' : '']"
                                @click="onRowSelect(dataItem)">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Name" :title="dataItem.name">
                                    {{ dataItem.name }}
                                    <span v-if="dataItem.charge">
                                        <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                    </span>
                                    <span v-if="dataItem.associationType === associationTypes.secondary">
                                        <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                    </span>
                                    <span v-if="dataItem.associationType === associationTypes.unassociated">
                                        <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                    </span>
                                    <span v-if="dataItem.isFloat">
                                        <img style="width: 24px;float: right" src="@/assets/float.png" alt="Float" />
                                    </span>
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Skill" :title="dataItem.skillLevelDescription"
                                           :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                                (dataItem.origDepartmentId !== dataItem.departmentId && homeTabInput.DataSet.DepartmentId === dataItem.origDepartmentId) ? 'strikethrough' : '']">
                                    {{ dataItem.skillLevelDescription }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Comps" style="white-space:nowrap;" class="col-comps"
                                           :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                    {{ dataItem.competencyCodes }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Department" :title="dataItem.departmentName"
                                           :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                    <span v-if="dataItem.origDepartmentId !== dataItem.departmentId">
                                        {{ dataItem.departmentName }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentName}}  </span>
                                    </span>
                                    <span v-else> {{ dataItem.departmentName }}</span>
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Shift" :title="dataItem.shiftCode"
                                           :class="[dataItem.overtimeShift ? 'gridOverTime': '',
                                           (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                    0) || dataItem.isCancelled) ? 'strikethrough': '',
                                    (dataItem.origDepartmentId !== dataItem.departmentId && homeTabInput.DataSet.DepartmentId === dataItem.origDepartmentId) ? 'strikethrough' : '']">
                                    {{ dataItem.shiftCode }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Start Time" 
                                           :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                            (dataItem.origDepartmentId !== dataItem.departmentId && homeTabInput.DataSet.DepartmentId === dataItem.origDepartmentId) ? 'strikethrough' : '']">
                                    {{ setGridTimeFormat(dataItem.actualStartTime) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="End Time"
                                           :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                            (dataItem.origDepartmentId !== dataItem.departmentId && homeTabInput.DataSet.DepartmentId === dataItem.origDepartmentId) ? 'strikethrough' : '']">
                                    {{ setGridTimeFormat(dataItem.actualEndTime) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Type" :title="dataItem.employeeType"
                                           :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                            (dataItem.origDepartmentId !== dataItem.departmentId && homeTabInput.DataSet.DepartmentId === dataItem.origDepartmentId) ? 'strikethrough' : '']">
                                    {{ dataItem.employeeType }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Hours" :title="dataItem.hours"
                                           :class="[dataItem.isOvertime ? 'gridOverTime': '']">
                                    {{ dataItem.hours }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="RN Experience" v-if="dataItem.rnExperienceFeatureEnabled"
                                           :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '']">
                                    {{ dataItem.rnExperience }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Hire Date">
                                    {{ setGridDateFormat(dataItem.hireDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Seniority Date">
                                    {{ setGridDateFormat(dataItem.seniorityDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Cancelled">
                                    {{ setGridDateFormat(dataItem.lastCanceledDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Floated">
                                    {{ setGridDateFormat(dataItem.lastFloatedDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Notes">
                                    <span v-if="dataItem.hasNotes">                                        
                                        <span class="tooltip" @mouseover="showToolTip($event)"><font-awesome-icon :icon="['fa', 'sticky-note']" size="sm" />
                                            <span class="tooltiptext" id="tooltip">Notes: <br> {{ setGridDateFormat(dataItem.notedate) }} <br> {{  dataItem.note.length > 50 ? dataItem.note.replace(' ','').substring(0,50) + "..." : dataItem.note }}</span>
                                        </span>                                     
                                    </span>
                                </neu-label>
                            </neu-table-row>
                        </template>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
        <div id="filterModel" class="filterPanel d-none">
            <div class="p-2">
                <div class="pb-2">Filter by: </div>
                <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedNumberFilter" v-if="filterColumn == 'Hours'">
                    <option v-for="numFtr in numberFilter" :value="numFtr.Id" :key="numFtr.Id">{{numFtr.Value}}</option>
                </select>
                <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedTextFilter" v-else>
                    <option v-for="txtFtr in textFilter" :value="txtFtr.Id" :key="txtFtr.Id">{{txtFtr.Value}}</option>
                </select>
                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                <input title="Value" v-model="NumberfilterText" v-if="filterColumn == 'Hours'" class="neu-input mb-2 filterInput" type="number">
                <input title="Value" v-model="filterText" class="neu-input mb-2 filterInput" type="text" v-else>
                <div>
                    <button type="submit" title="Filter" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilter">Filter</button>
                    <button type="reset" title="Clear" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClear">Clear</button>
                    <button type="button" title="Close" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClose">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div id="home-message" class="text-center mt-3" v-if="isShowHomeMessage">
        <p id="home-message-text">{{homeMessage}}</p>
    </div>
    <div id="care-assignment-container"></div>
    <!-- Toaster -->
    <div v-if="isToasterVisible">
        <ToasterPopup @closeModal="closeToasterPopup"
                      :msgValue="msgValue"
                      :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isShowCareAssignmentSheetPopup">
        <CareAssignment @closePrintTemplate="closeCareAssignmentSheetPopup" :payloadCareAssignment="payloadCareAssignment">
        </CareAssignment>
    </div>
    <div v-if="isShowExportToExcel">
        <ExportToExcelForTabs @closeExcelTemplate="closeHomeTabExportToExcel" :payloadExportToExcel="payloadExportToExcel">
        </ExportToExcelForTabs>
    </div>
</template>
<script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";
    import { mapState } from "vuex";
    import jslinq from "jslinq";

    //imports models and enums
    import { CountType, ProductiveTypes, NeedStatus, NumberFilterType } from "@/enums/enum";
    import { AppMode} from "@/enums/enum";

    //Import Componenets
    import ToasterPopup from "../../shared/ToasterPopup.vue";
    import CareAssignment from '../header/CareAssignment.vue';
    import ExportToExcelForTabs from '../header/ExportToExcelForTabs.vue';

    import {
        NeuTableRowCount,
        NeuTable,
        NeuTableRow,
        NeuLabel,
        NeuTableBody,
        NeuTableHeading,
        NeuCard               
    } from "@neutron/vue";

    class Props {
        homeTabInput!: any
    }

    @Options({
        computed: {
            ...mapState('assignments', ['assignmentHomeDept']),
        },
        components: {
            ToasterPopup,
            NeuTableRowCount,
            NeuTable,
            NeuTableRow,
            NeuLabel,
            NeuTableBody,
            NeuTableHeading,
            NeuCard,
            CareAssignment,
            ExportToExcelForTabs
        }
    })
    export default class HomeDepartment extends Vue.with(Props) {
        //Data properties
        assignmentHomeDept!: any;
        isShowLoader: boolean = false;
        isShowHomeContent: boolean = false;
        isShowHomeMessage: boolean = false;
        isFilterDataFromHeader: boolean = false;
        isRNExperienceFeatureEnabled: boolean = false;
        homeMessage: string = "";
        homeGridData: any = null;
        filterColumn: string = "";
        filterText: string = "";
        NumberfilterText: string = "";
        filterTextList: any[] = [];
        filterColumnList: any[] = ["Name", "Skill", "Comps", "Shift", "Type", "Hours"];
        textFilter: any[] = [{
            Id: 1,
            Value: "Contains"
        }];
        numberFilter: any[] = [
            {
                Id: 2,
                Value: "Is equal to"
            },
            {
                Id: 3,
                Value: "Greater than or equal to"
            },
            {
                Id: 4,
                Value: "Less than or equal to"
            }
        ];
        selectedNumberFilter: any = 2;
        selectedTextFilter: any = 1;
        filteredData: any = null;
        associationTypes: any = {
            primary: 1,
            secondary: 2,
            unassociated: 3
        };
        columnHeaders: any = {
            Name: "10%",
            Skill: "6%",
            Comps: "7%",
            Department: "8%",
            Shift: "5%",
            "Start Time": "7%",
            "End Time": "7%",
            Type: "5%",
            Hours: "5%",
            "RN Experience": "6%",
            "Hire Date": "8%",
            "Seniority Date": "8%",
            "Last Cancelled": "7%",
            "Last Floated": "7%",
            "Notes": "4%"
        };
        columnIcons: any = {
            Name: "desc",
            Skill: "desc",
            Comps: "desc",
            Department: "desc",
            Shift: "desc",
            "Start Time": "desc",
            "End Time": "desc",
            Type: "desc",
            Hours: "desc",
            "RN Experience": "desc",
            "Hire Date": "desc",
            "Seniority Date": "desc",
            "Last Cancelled": "desc",
            "Last Floated": "desc",
            "Notes": "none"
        };

        columnForSort: any = {
            "Name": "name",
            "Skill": "skillLevelDescription",
            "Comps": "competencyCodes",
            "Department": "departmentName",
            "Shift": "shiftCode",
            "Start Time": "actualStartTime",
            "End Time": "actualEndTime",
            "Type": "employeeType",
            "Hours": "hours",
            "RN Experience": "rnExperience",
            "Hire Date": "hireDate",
            "Seniority Date": "seniorityDate",
            "Last Cancelled": "lastCanceledDate",
            "Last Floated": "lastFloatedDate",
            "Notes": "none"
        }
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        HOME_TAB_ID = 0;
        isShowCareAssignmentSheetPopup: boolean = false;
        isShowExportToExcel: boolean = false;
        payloadCareAssignment!: any;
        payloadExportToExcel!: any;
        //Lifecycle hooks
        async mounted() {
            await this.loadHomeGridData();
            this.closeFilterBox();
        }

        created() {
            this.emitter.on("filterHomeGridDataBySkill", this.filterHomeGridDataBySkill);
            this.emitter.on("clearHomeGridDataBySkill", this.clearHomeGridDataBySkill);
            this.emitter.on("refreshHomeDepartmentTab", this.loadHomeGridData);
            this.emitter.on("loadCareAssignmentSheet", this.loadCareAssignmentSheet);
            this.emitter.on("homeTabExportToExcel", this.homeTabExportToExcel);
        }

        unmounted() {
            this.emitter.off("filterHomeGridDataBySkill", this.filterHomeGridDataBySkill);
            this.emitter.off("clearHomeGridDataBySkill", this.clearHomeGridDataBySkill);
            this.emitter.off("refreshHomeDepartmentTab", this.loadHomeGridData);
            this.emitter.off("loadCareAssignmentSheet", this.loadCareAssignmentSheet);
            this.emitter.off("homeTabExportToExcel", this.homeTabExportToExcel);
        }

        //Methods
        showToolTip(event: any){ 
            // eslint-disable-next-line          
            const tooltip : NodeListOf<HTMLSpanElement> | null = document.querySelectorAll(".tooltiptext");
            const mouseX = event.clientX;
            const mouseY = event.clientY;
            console.log(tooltip)
            if(tooltip != null){
                for(let i = 0; i < tooltip.length; i++){
                    tooltip[i].style.left = mouseX - 410 + 'px';
                    tooltip[i].style.top = mouseY - 40 + 'px';
                }
            }            
        }
        async loadHomeGridData() {
            if (this.homeTabInput != null) {
                this.showLoader();
                var inputData = {
                    deptId: this.homeTabInput.DataSet.DepartmentId,
                    startTime: this.homeTabInput.DataSet.StartDateTime,
                    endTime: this.homeTabInput.DataSet.EndDateTime
                }
                await this.$store
                    .dispatch("assignments/getAssignmentsByHomeGrid", inputData)
                    .then(() => {
                        if (this.assignmentHomeDept != null && this.assignmentHomeDept.length > 0) {
                            this.mapHomeOtherAssignments(this.assignmentHomeDept);

                            this.homeGridData = this.filterHomeAssignments(this.assignmentHomeDept);

                            if (this.homeGridData != null && this.homeGridData.length > 0) {
                                this.isRNExperienceFeatureEnabled = this.homeGridData[0].rnExperienceFeatureEnabled
                            }
                            this.isShowHomeMessage = false;
                            this.isShowHomeContent = true;
                            this.setCareAssignmentData();
                        }
                        else {
                            this.homeMessage = "No resources found";
                            this.isShowHomeMessage = true;
                            this.isShowHomeContent = false;
                        }
                        this.hideLoader();
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideLoader();
                            this.msgValue = "Error encountered while retrieving home department details.";
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                            console.error(err); // Handle errors any way you want
                        }
                    });
            }
        }
        mapHomeOtherAssignments(assignments: any) {
            var info: any = this.homeTabInput.DataSet;

            assignments.forEach((value: any) => {
                value.name = value.lastName + ', ' + value.firstName;
                value.shiftCode = value.shiftCode ? value.shiftCode.trim() : '???';
                value.staffVolume = this.deptStaffVolume(value.departmentId, value.departmentSummaryId, value.skillLevelId);
                value.allSkills = value.skillLevelDescription + (value.secondarySkills ? ', ' + value.secondarySkills : '');
                value.facilityId = info.FacilityId;
                value.facilityName = info.FacilityName;
                value.clusterId = info.ClusterId;
                value.clusterName = info.ClusterName;

                if (value.competencies && value.competencies.length > 0) {
                    value.competencyCodes = value.competencies.map(function (competency: any) {
                        return competency.code;
                    }).join(", ");
                }
            });
        }
        deptStaffVolume(deptId: any, summaryId: any, skillId: any) {
            var deptStats: any = this.homeTabInput.DeptStats;
            for (var i = 0; i < deptStats.length; i++) {
                var d = deptStats[i];
                if (d.DepartmentId === deptId && d.DeptSummaryId === summaryId && d.SkillLevelId === skillId) {
                    return Math.round((d.Scheduled - d.Target) * 100) / 100;
                }
            }
            return 0;
        }
        filterHomeAssignments(assignments: any) {
            return assignments.filter((item: any) => {
                var countType = item.countTypeId;
                var productive = item.productiveId;
                var needStatus = item.needStatusId;
                return (countType === CountType.COUNT || countType === CountType.DONT_COUNT_BUT_SHOW)
                    && (productive === ProductiveTypes.PRODUCTIVE || productive === ProductiveTypes.OTHER_PRODUCTIVE || productive === ProductiveTypes.NON_PRODUCTIVE || productive === ProductiveTypes.CALL)
                    && (needStatus === NeedStatus.CONFIRMED || needStatus === NeedStatus.CANCELED);

            });
        }
        setGridDateFormat(date: any) {
            if (date != null && date != '') {
                var formattedDate: any = moment(date).format("MM/DD/YYYY");
                return formattedDate;
            }
            else {
                return '';
            }
        }
        setGridTimeFormat(time: any) {
            if (time != null && time != '') {
                var formattedTime: any = moment(time).format("hh:mm A");
                return formattedTime;
            }
            else {
                return '';
            }
        }
        setCareAssignmentData(){
            this.$store.dispatch("actionable/setCareAssignmentSheetData", {
                                assignmentHomeDept : this.assignmentHomeDept,
                                homeGridData: this.homeGridData,
                                departmentId: this.homeTabInput.DataSet.DepartmentId,
                                skillLevelDescription: this.homeTabInput.DataSet.SkillLevelDescription
                            })
        }
        showLoader() {
            this.isShowLoader = true;
            this.isShowHomeContent = false;
            this.isShowHomeMessage = false;
        }

        hideLoader() {
            this.isShowLoader = false;
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }
        onRowSelect(dataItem: any) {
            if(this.homeTabInput.AppMode === AppMode.FullAccess){
                var currentDataItem = dataItem;
                var info = {
                    FacilityId: currentDataItem.facilityId,
                    FacilityName: currentDataItem.facilityName,
                    ClusterId: currentDataItem.clusterId,
                    ClusterName: currentDataItem.clusterName,
                    DepartmentId: currentDataItem.departmentId,
                    DepartmentName: currentDataItem.departmentName
                };

                currentDataItem.actionableDepartment = info;
                currentDataItem.config = this.homeTabInput.Config;

                this.emitter.emit('loadAssignment', currentDataItem);
            }
        }
        onFilterVisible(event: any, columnName: any) {
            event.stopPropagation();
            var filterPanel: any = document.getElementById('filterModel');
            filterPanel.style.left = event.clientX + 'px';
            if(this.homeGridData != null && this.homeGridData.length > 6){
                filterPanel.style.top = event.clientY - 58 + 'px';
            }
            else{
                filterPanel.style.top = event.clientY - 225 + 'px';
            }
            this.filterColumn = columnName;
            this.filterText = "";
            this.setFilterText();

            filterPanel.classList.remove('d-none');
        }
        onGridFilter() {
            var filterPanel: any = document.getElementById('filterModel');
            if ((this.filterColumn.toLowerCase() != 'Hours'.toLowerCase() && this.filterText != null && this.filterText != '') ||
                (this.filterColumn.toLowerCase() == 'Hours'.toLowerCase() && this.NumberfilterText != null && this.NumberfilterText != '')) {
                if (this.filteredData == null) {
                    this.filteredData = JSON.parse(JSON.stringify(this.homeGridData));
                }
                if (this.filterTextList != null && this.filterTextList.length > 0) {
                    var that = this;
                    //@ts-ignore
                    var isFilterExists = jslinq(this.filterTextList)
                        .where(function (data: any) {
                            if (data.filterColumn == that.filterColumn) {
                                return data;
                            }
                        })
                        .toList();

                    if (isFilterExists != null && isFilterExists.length > 0) {
                        this.filterTextList.forEach((data) => {
                            if (data.filterColumn == this.filterColumn) {
                                if (this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()) {
                                    data.filterText = this.NumberfilterText;
                                    data.filterSelectedValue = this.selectedNumberFilter;
                                }
                                else {
                                    data.filterText = this.filterText;
                                    data.filterSelectedValue = this.selectedTextFilter;
                                }
                            }
                        })
                    }
                    else {
                        this.AddFilterListItem();
                    }
                }
                else {
                    this.AddFilterListItem();
                }
                this.updateGridDataBySelectedFilter();
                var selectedFilterIcon: any = document.querySelector("[column='" + this.filterColumn + "']");
                selectedFilterIcon.classList.add('filterSelectedIcon');
                filterPanel.classList.add('d-none');
            }
            else {
                this.msgValue = "Please enter text to filter";
                this.classFlag = "error";
                this.isToasterVisible = true;
            }
        }
        onGridFilterClear() {
            if (this.filterTextList != null && this.filterTextList.length > 0) {
                for (var i = 0; i < this.filterTextList.length; i++) {
                    if (this.filterTextList[i].filterColumn == this.filterColumn) {
                        this.filterTextList.splice(i, 1);
                        var selectedFilterIcon: any = document.querySelector("[column='" + this.filterColumn + "']");
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    }
                }
                if (this.filterTextList != null && this.filterTextList.length > 0) {
                    var lastItemIndex = this.filterTextList.length - 1;
                    this.filterColumn = this.filterTextList[lastItemIndex].filterColumn;
                    if (this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()) {
                        this.NumberfilterText = this.filterTextList[lastItemIndex].filterText;
                        this.selectedNumberFilter = this.filterTextList[lastItemIndex].filterSelectedValue;
                    }
                    else {
                        this.filterText = this.filterTextList[lastItemIndex].filterText;
                        this.selectedTextFilter = this.filterTextList[lastItemIndex].filterSelectedValue;
                    }
                    this.homeGridData = this.filterTextList[lastItemIndex].filterData;
                }
                else {
                    this.homeGridData = JSON.parse(JSON.stringify(this.filteredData));
                    this.NumberfilterText = "";
                    this.filterText = "";
                    this.selectedTextFilter = 1;
                    this.selectedNumberFilter = 2;
                    this.isFilterDataFromHeader = false;
                }
            }
            var filterPanel: any = document.getElementById('filterModel');
            filterPanel.classList.add('d-none');
        }
        AddFilterListItem() {
            var fText;
            var fSelectedValue;
            if (this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()) {
                fText = this.NumberfilterText;
                fSelectedValue = this.selectedNumberFilter;
            }
            else {
                fText = this.filterText;
                fSelectedValue = this.selectedTextFilter;
            }
            var data: any = {
                filterColumn: this.filterColumn,
                filterText: fText,
                filterSelectedValue: fSelectedValue,
                filterData: null
            }
            this.filterTextList.push(data);
        }
        setFilterText() {
            if (this.filterTextList != null && this.filterTextList.length > 0) {
                var that = this;
                //@ts-ignore
                var isFilterExists = jslinq(this.filterTextList)
                    .where(function (data: any) {
                        if (data.filterColumn == that.filterColumn) {
                            return data;
                        }
                    })
                    .toList();

                if (isFilterExists != null && isFilterExists.length > 0) {
                    this.filterTextList.forEach((data) => {
                        if (data.filterColumn == this.filterColumn) {
                            if (this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()) {
                                this.NumberfilterText = data.filterText;
                                this.selectedNumberFilter = data.filterSelectedValue;
                            }
                            else {
                                this.filterText = data.filterText;
                                this.selectedTextFilter = data.filterSelectedValue;
                            }
                        }
                    })
                }
            }
        }
        updateGridDataBySelectedFilter() {
            if (this.filterColumn.toLowerCase() == "Name".toLowerCase()) {
                var data = this.homeGridData.filter((dt: any) => dt.name.toLowerCase().includes(this.filterText.toLowerCase()));
                this.homeGridData = data;
            }
            if (this.filterColumn.toLowerCase() == "Skill".toLowerCase()) {
                var data = this.homeGridData.filter((dt: any) => dt.skillLevelDescription.toLowerCase().includes(this.filterText.toLowerCase()));
                this.homeGridData = data;
            }
            if (this.filterColumn.toLowerCase() == "Comps".toLowerCase()) {
                var data = this.homeGridData.filter((dt: any) => dt.competencyCodes && dt.competencyCodes.toString().toLowerCase().includes(this.filterText.toLowerCase()));
                this.homeGridData = data;
            }
            if (this.filterColumn.toLowerCase() == "Shift".toLowerCase()) {
                var data = this.homeGridData.filter((dt: any) => dt.shiftCode.toLowerCase().includes(this.filterText.toLowerCase()));
                this.homeGridData = data;
            }
            if (this.filterColumn.toLowerCase() == "Type".toLowerCase()) {
                var data = this.homeGridData.filter((dt: any) => dt.employeeType.toLowerCase().includes(this.filterText.toLowerCase()));
                this.homeGridData = data;
            }
            if (this.filterColumn.toLowerCase() == "Hours".toLowerCase()) {
                var data;
                if (this.selectedNumberFilter == NumberFilterType.IS_EQUAL_TO) {
                    var data = this.homeGridData.filter((dt: any) => dt.hours == this.NumberfilterText);
                }
                else if (this.selectedNumberFilter == NumberFilterType.GREATER_THAN_OR_EQUAL_TO) {
                    var data = this.homeGridData.filter((dt: any) => dt.hours >= this.NumberfilterText);
                }
                else if (this.selectedNumberFilter == NumberFilterType.LESS_THAN_OR_EQUAL_TO) {
                    var data = this.homeGridData.filter((dt: any) => dt.hours <= this.NumberfilterText);
                }
                this.homeGridData = data;
            }

            this.filterTextList.forEach((data) => {
                if (data.filterColumn == this.filterColumn) {
                    data.filterData = JSON.parse(JSON.stringify(this.homeGridData));
                }
            });
        }

        onGridFilterClose() {
            var filterPanel: any = document.getElementById('filterModel');
            filterPanel.classList.add('d-none');
        }

        filterHomeGridDataBySkill(skillDesc: any) {
            if (this.isFilterDataFromHeader) {
                this.homeGridData = JSON.parse(JSON.stringify(this.filteredData));
            }
            else {
                this.filteredData = null;
                this.filteredData = JSON.parse(JSON.stringify(this.homeGridData));
                this.isFilterDataFromHeader = true;
            }

            if (this.filterTextList != null && this.filterTextList.length > 0) {
                this.filterTextList.forEach((data) => {
                    var selectedFilterIcon: any = document.querySelector("[column='" + data.filterColumn + "']");
                    if (selectedFilterIcon != null) {
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    }
                });
                this.filterTextList = [];
            }

            this.filterText = skillDesc;
            this.filterColumn = "Skill";

            this.AddFilterListItem();
            this.updateGridDataBySelectedFilter();

            var selectedFilterIcon: any = document.querySelector("[column='Skill']");
            if (selectedFilterIcon != null) {
                selectedFilterIcon.classList.add('filterSelectedIcon');
            }
            else {
                setTimeout(() => {
                    var selectedFilterIcon: any = document.querySelector("[column='Skill']");
                    selectedFilterIcon.classList.add('filterSelectedIcon');
                }, 1000);
            }
        }

        clearHomeGridDataBySkill() {
            if (this.isFilterDataFromHeader) {
                this.homeGridData = JSON.parse(JSON.stringify(this.filteredData));
                if (this.filterTextList != null && this.filterTextList.length > 0) {
                    this.filterTextList.forEach((data) => {
                        var selectedFilterIcon: any = document.querySelector("[column='" + data.filterColumn + "']");
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    });
                    this.filterTextList = [];
                }

                this.filterText = "";
                this.NumberfilterText = "";
                this.filterColumn = "";
                this.filteredData = null;
                this.isFilterDataFromHeader = false;
            }
        }

        closeFilterBox() {
            document.addEventListener('click', function handleClickOutsideFilterBox(event) {
                const box: any = document.getElementById('filterModel');

                if (box != null) {
                    if (!box.contains(event.target)) {
                        box.classList.add('d-none');
                    }
                }
            });

            var actionableDiv: any = document.getElementById("actionable");
            actionableDiv.addEventListener('scroll', function hideFilterBox() {
                const box: any = document.getElementById('filterModel');
                if (box != null) {
                    box.classList.add('d-none');
                }
            });

            var homeDataDiv: any = document.getElementById("home");
            homeDataDiv.addEventListener('scroll', function hideFilterBoxDiv() {
                const box: any = document.getElementById('filterModel');
                if (box != null) {
                    box.classList.add('d-none');
                }
            });
        }

        loadCareAssignmentSheet(headerInfo: any) {
            this.payloadCareAssignment = {
                headerInfo: headerInfo,
                assignment: this.assignmentHomeDept,
                dataSource: this.homeGridData,
                departmentId: this.homeTabInput.DataSet.DepartmentId
            };
            this.isShowCareAssignmentSheetPopup = true;
        }

        closeCareAssignmentSheetPopup() {
            this.isShowCareAssignmentSheetPopup = false;
        }

        homeTabExportToExcel(headerInfo: any) {
            this.payloadExportToExcel = {
                headerInfo: headerInfo,
                skillLevelDescription: this.homeTabInput.DataSet.SkillLevelDescription,
                dataSource: this.homeGridData,
                departmentId: this.homeTabInput.DataSet.DepartmentId,
                tabName : 'home'
            };
            this.isShowExportToExcel = true;
        }

        closeHomeTabExportToExcel() {
            this.isShowExportToExcel = false;
        }

        sortData(ev: any, columnName: any) {
            var column = this.columnForSort[columnName];

            this.homeGridData.sort((col1: any, col2: any) => {
                let modifier = 1;
                if (ev.detail.direction.toLowerCase() === 'desc') modifier = -1;

                if (col1[column] < col2[column]) return -1 * modifier;
                if (col1[column] > col2[column]) return 1 * modifier;
            });
        }
    }
</script>
<style scoped>
    .filterPanel {
        width: 200.797px;
        min-height: 162px;
        overflow: visible;
        top: 553.703px;
        z-index: 10013;
        left: 254.562px;
        box-sizing: content-box;
        display: block;
        position: absolute;
        text-align: left;
        border: 1px solid #939393;
        background-color: white;
        :deep(.neu-button) {
            background: var(--neu-color-primary);
        }
    }

    .filterSelectedIcon {
        background-color: #164D87;
        color: whitesmoke;
        padding: 2px;
    }

    .filterInput {
        width: 100% !important;
        min-height: 2rem !important;
    }

    .filterBtn {
        min-width: 30px !important;
        height: 30px !important;
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

        .filterBtn:hover {
            color: #fff !important;
        }

            .filterBtn:hover::after {
                background: none !important;
            }

    .neu-input__select-icon {
        display: block;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        top: 2.82rem;
        right: 0.938rem;
        background-color: transparent;
        transition: 0.5s ease-in-out;
        pointer-events: none;
    }

.tooltip {
  position: relative;
  display: contents !important;  
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #dbecf7 !important;
  color: #00435e;
  text-align: left;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem !important;
  font-weight: 600;
  overflow-wrap: break-word;
  word-break: break-word;
  position: fixed;
  z-index: 1;    
}

.tooltip .tooltiptext::after{    
    rotate: 90deg;
    content: "";
    position: absolute;
    left: 396px !important;
    transform: translateX(-110%);    
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent transparent #dbecf7 transparent !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>