<template>
    <div id="staffing-form" v-if="pcsStaffingViewModel.pcsEnabled && isShowStaffingContent">
        <div class="row">
            <div class="col-md-4">
                <div class="form-inline">
                    <div class="form-group ml-4" style="text-align: left;">
                        <label for="gridLevel">Grid Level</label>
                        <select class="neu-select filterSelect" v-model="selectedPCSRange" 
                            @change="pcsRangesOnChange" style="width: 300px"
                            data-role="dropdownlist"
                            data-option-label="Select a PCS Range">
                            <option v-for="pcs in pcsStaffingViewModel.pcsRanges" 
                                :value="pcs.fsDeptPatientClassificationRangeId" 
                                :key="pcs.fsDeptPatientClassificationRangeId">
                                {{pcs.name}}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    </div>
                </div>
            </div>
            <div class="col">
                <label for="pcsRangeLevel">PCS Range</label>
                <span class="font-weight-bold" style="padding-left: 5px !important;">{{pcsStaffingViewModel.pcsRangeLevel}}</span>
            </div>
        </div>
    </div>
    <div id="staffing-content-loading" class="text-center mt-3" v-show="isShowLoader">
        <div class="fa-2x">
           <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
        </div>
        <p><b>Loading...</b></p>
    </div>
    <div id="staffing-content" v-if="isShowStaffingContent">
        <div id="grdStaffing">
            <neu-card color="plain-0" class="p-0 m-0" v-if="staffingData != null && staffingData.length > 0">
                <neu-table>
                    <neu-table-row :columns="columnHeaders" header>
                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                    <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :slot="column"
                            :icon="columnIcons[column]" :title="column"  style="padding-top: 25px !important;">
                        {{ column }}
                    </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <neu-table-row :columns="columnHeaders" :slot="column" v-for="staffDt in staffingData" :key="staffDt"
                                        :class="[staffDt['Highlight'] ? 'staffing-grid-highlight': '']">          
                            <template v-for="dataItem in Object.keys(staffDt)" :key="dataItem">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label :slot="dataItem" v-if="dataItem != 'Highlight'">
                                    {{ staffDt[dataItem] }}
                                </neu-label>
                            </template>
                        </neu-table-row>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
    </div>
    <div id="staffing-message" class="text-center mt-3" v-if="isShowStaffingMessage">
        <p id="staffing-message-text">{{staffingMessage}}</p>
    </div>
    <div id="care-assignment-container-staffingmatrix"></div>
    <!-- Toaster -->
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import jslinq from "jslinq";

//imports models and enums

//Import Componenets
import ToasterPopup from "../../shared/ToasterPopup.vue";
import {
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard
} from "@neutron/vue";

class Props {
    staffingGridTabInput!: any 
}

@Options({
  computed: {
    ...mapState('actionable', ['staffingGrid', 'patientClassification']),
  },
  components: {
    ToasterPopup,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard
  }
}) 
export default class StaffingMatrix extends Vue.with(Props) {
    //Data properties
    staffingGrid!: any;
    patientClassification!: any;
    staffingData: any = [];
    isShowLoader: boolean = false;
    isShowStaffingContent: boolean = false;
    isShowStaffingMessage: boolean = false;
    staffingMessage: string = "";
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    selectedPCSRange: any = null;
    columnHeaders: any ={ 
        "Census Low": "10%", 
        "Census Peak": "10%", 
    };
    columnIcons: any = {    
        "Census Low": "none", 
        "Census Peak": "none"
    };
    pcsStaffingViewModel: any = {
        fsDeptPatientClassificationRangeId: null,
        name: null,
        pcsEnabled: false,
        pcsRangeLevel: null,
        pcsRanges: []
    };

    //Lifecycle hooks
    created(){
        this.emitter.on("refreshStaffingMatrixTab", this.loadStaffingMatrix);
    }

    async mounted(){
        await this.loadStaffingMatrix();
    }

    unmounted(){
        this.emitter.off("refreshStaffingMatrixTab", this.loadStaffingMatrix);
    }

    //Methods
    async loadStaffingMatrix(){
        this.staffingData = [];
        if(this.staffingGridTabInput != null){
            var data = this.staffingGridTabInput.DataSet;
            this.loadPatientClassificationRange(data.DepartmentId, data.StartDateTime, data.PCSRangeName, data.PCSEnabled);
            await this.loadStaffingGrid(data.DepartmentId,
                                data.StartDateTime,
                                null);
        }
    }

    async loadStaffingGrid(departmentId: any, startDateTime: any, deptPatientClassificationRangeId: any){
        var inputData = {
            departmentId: departmentId,
            startDateTime: startDateTime,
            deptPatientClassificationRangeId: deptPatientClassificationRangeId
        }
        this.showLoader();
        await this.$store
            .dispatch("actionable/getStaffingGrid", inputData)
            .then(() => {
                if(this.staffingGrid != null && this.staffingGrid.length > 0){
                    //Set Grid Column
                    var colwidth = "8";
                    if(this.staffingGrid[0].targets != null && this.staffingGrid[0].targets.length > 0){
                        colwidth = Number(80 / this.staffingGrid[0].targets.length).toFixed(2);
                    } 
                    this.staffingGrid[0].targets.forEach((value: any)=>{
                        this.columnHeaders[this.key(value.skill)] =  colwidth + "%";
                        this.columnIcons[this.key(value.skill)] = "none";
                    });

                    //Set Grid Data
                    this.staffingGrid.forEach((value: any) => {
                        var staffingItem: any = {
                            'Census Low': value.censusLow,
                            'Census Peak': value.censusPeak,
                            'Highlight': value.highlight
                        };
                        value.targets.forEach((data: any) => {
                            staffingItem[this.key(data.skill)] = data.target;
                        });
                        this.staffingData.push(staffingItem);
                    });

                    this.isShowStaffingMessage = false;
                    this.isShowStaffingContent = true;
                }
                else{
                    this.staffingMessage = "No resources found";
                    this.isShowStaffingMessage = true;
                    this.isShowStaffingContent = false;
                }
                this.hideLoader();
            })
            .catch((err: any) => {
                if (err) {
                    this.hideLoader();
                    this.msgValue = "Error encountered while retrieving staffing matrix details.";
                    this.classFlag = "error";
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadPatientClassificationRange(deptId: any, startDateTime: any, pcsRangeName: any, pcsEnabled: any){
        this.pcsStaffingViewModel.pcsEnabled = pcsEnabled;
        if (pcsEnabled) {
            var pcsRangeName = pcsRangeName;
            var inputData = {
                departmentId: deptId,
                startDateTime: startDateTime,
            }
            await this.$store
                .dispatch("actionable/getPatientClassificationRange", inputData)
                .then(() => {
                    if(this.patientClassification != null && this.patientClassification.length > 0){
                        this.pcsStaffingViewModel.pcsRanges = this.patientClassification;

                        if (pcsRangeName !== null) {
                            var ranges = JSON.parse(JSON.stringify(this.patientClassification));

                            //@ts-ignore
                            var selectedPCSRange = jslinq(ranges)
                                    .where(function (rng: any) {
                                        return rng.name == pcsRangeName;
                                    })
                                .toList();
                            
                            if(selectedPCSRange != null && selectedPCSRange.length > 0){
                                selectedPCSRange.forEach((range: any) => {
                                    var pcsRangeLevel = range.rangeStart + ' - ' + range.rangeEnd;
                                    var deptPatientClassificationRangeId = range.fsDeptPatientClassificationRangeId;
                                    this.pcsStaffingViewModel.pcsRangeLevel = pcsRangeLevel;
                                    this.pcsStaffingViewModel.fsDeptPatientClassificationRangeId = deptPatientClassificationRangeId;
                                    this.selectedPCSRange = deptPatientClassificationRangeId;
                                });
                            }
                        }
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        this.msgValue = "Error encountered while retrieving PCS classification details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
    }

    async pcsRangesOnChange() {
        var ranges = JSON.parse(JSON.stringify(this.patientClassification));

        var that=this;
        var patientClassificationRangeId = null;
        //@ts-ignore
        var selectedPCSRangeList = jslinq(ranges)
                .where(function (rng: any) {
                    return rng.fsDeptPatientClassificationRangeId == that.selectedPCSRange;
                })
            .toList();

        if(selectedPCSRangeList != null && selectedPCSRangeList.length > 0){
            selectedPCSRangeList.forEach((value: any) => {
                patientClassificationRangeId = value.fsDeptPatientClassificationRangeId;
                var range = value.rangeStart + ' - ' + value.rangeEnd;

                this.pcsStaffingViewModel.pcsRangeLevel = range;
            });
        }

        var data = this.staffingGridTabInput.DataSet;
        await this.loadStaffingGrid(data.DepartmentId,
                            data.StartDateTime,
                            patientClassificationRangeId);
    }

    key(name: any) {
        return name.replace(/[-/ ]/g, '_');
    }

    showLoader(){
        this.isShowLoader = true;
        this.isShowStaffingContent = false;
        this.isShowStaffingMessage = false;
    }

    hideLoader(){
        this.isShowLoader = false;
    }

    closeToasterPopup() {
      this.isToasterVisible = false;
    }
}
</script>
<style scoped>
.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2rem;
    left: 17rem;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}
</style>