<template>
<div class="ptoContainer-fluid" id="divPtoContainer-fluid">
    <div class="card ptoCard">
        <div id="ptobalance-content-loading" class="text-center mt-3" v-show="isShowLoader">
            <div id="divPTOBalanceHeader" >
                <h6 class="ptoHeader">PTO Balance</h6>
                <hr class="hrline" />
            </div>
            <div class="fa-2x">
                <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
            </div>
            <p><b>Loading PTO Balance...</b></p>
        </div>
        <div v-if="isShowPTOContent">
            <div id="divPTOBalanceLabels">
                <div>          
                    <label class="paddingCtrls" id="lblPtoBalance" style="padding-left:10px">
                        PTO Balance: <span id="ptoBalanceData" class="text-muted paddingCtrls">{{ptoBalance}}</span>
                    </label>
                </div>
                <div>
                    <label class="paddingCtrls" id="lblPayPeriodAccural" style="padding-left:10px">
                        Pay Period Accrual: <span id="payPeriodAccural" class="text-muted paddingCtrls">{{payPeriodAccural}}</span>
                    </label>
                </div>
            </div>
            <div id="divPTOBalanceGrid">
                <div id="ptobalance-content" class="mt-3">
                    <div id="ptoGrid" class="scrollBar">
                        <table class="ptoTable">
                            <tr class="th_HeaderRow">
                                <template v-for="(col, index) in PTOBalanceColumns" :key="col">
                                    <th :class="[index == 0? 'colTextWidth fixedCol': 'colDateWidth']">
                                        <neu-label >
                                            {{col}}
                                        </neu-label>
                                    </th>
                                </template>
                            </tr>
                            <tr class="neu-table__row--small"  
                                v-for="(pto, index) in PTOBalanceData" :key="PTOBalanceData.indexOf(pto)"
                                :class="[index == 2 ? 'bold': '', index == 1 ? 'highlight' : '']" >
                                <template v-for="(dataItem, index) in Object.keys(pto)" :key="dataItem">
                                    <td :class="[index == 0? 'fixedCol fontNormal': '', 
                                                (highlightedColumn!= null && highlightedColumn.length > 0 && highlightedColumn.includes(dataItem)) ? 'highlight' : '']">                                        
                                        <neu-label >
                                            {{ pto[dataItem] }}
                                        </neu-label>
                                    </td>
                                </template>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div id="divPTOBalanceFooter">
                <label class="text-muted" style="padding-left:10px"> PTO balances are shown as estimates only.</label>
            </div>
        </div>
        <div id="ptobalance-message" class="text-center mt-3" v-if="isShowPTOMessage">
            <p id="ptobalance-message-text">{{ptoMessage}}</p>
        </div>
    </div>
</div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//imports models and enums

//Import Componenets

class Props {
    StaffId!: any;
    ShiftDate!: any; 
}
@Options({
  computed: {
    ...mapState('actionable', ['PTOBalance']),
  }
}) 
export default class PTOBalance extends Vue.with(Props) {
    //Data properties
    PTOBalance!: any;

    PTOBalanceData: any = null;
    PTOBalanceColumns: any = null;
    highlightedColumn: any = null;
    isShowLoader: boolean = false;
    isShowPTOMessage: boolean = false;
    isShowPTOContent: boolean = false;
    ptoMessage: string = "";
    ptoBalance: string = ""; 
    payPeriodAccural: string = ""; 
    ptoBalanceDateFormat: string = 'MM/DD'

    //Lifecycle hooks
    async mounted(){
        await this.loadPTOBalance();
    }

    //Methods
    async loadPTOBalance(){
        var inputData = {
            staffId: this.StaffId,
            startDate: moment(this.ShiftDate).format('MM-DD-YYYY')
        }
        this.showLoader();
        await this.$store
                .dispatch("actionable/getPTOBalance", inputData)
                .then(() => {
                    if (this.PTOBalance != null) {
                        this.loadPtoGrid();
                        this.isShowPTOContent = true;                   
                        this.isShowPTOMessage = false;
                    }
                    else{
                         this.isShowPTOContent = false;
                        this.isShowPTOMessage = true;
                        this.ptoMessage = "Unable to find PTO Balance.";
                    }
                    this.hideLoader();
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        var errorData = {
                            message: "An error occured while retrieving PTO Balance.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    loadPtoGrid(){
        if(this.PTOBalance.ptoBalanceAccrual != null &&
            this.PTOBalance.ptoBalanceAccrual.length > 0){

            this.PTOBalanceData = [];
            this.PTOBalanceColumns = [];
            this.highlightedColumn = [];
            this.ptoBalance = this.PTOBalance.ptoBalance.toFixed(2) +' hours';
            this.payPeriodAccural = this.PTOBalance.payPeriodAccrual.toFixed(2) +' hours';

            this.PTOBalanceColumns.push('Pay Period End Date');

            var row1: any = { 'Pay Period End Date': 'PTO Balance'};
            var row2: any = { 'Pay Period End Date': 'Less FS Approved PTO' };
            var row3: any = { 'Pay Period End Date': 'Estimated PTO Hours' };
    
            this.PTOBalance.ptoBalanceAccrual.forEach((item: any, index: any) => {
                var colName = moment(item.payPeriodEndDate).format(this.ptoBalanceDateFormat);
                var PropName = colName;

                row1[PropName] = item.ptoBalance === 0 ? "--" : item.ptoBalance.toFixed(2).toString();

                row2[PropName] = item.lessFsApprovalPto === 0 ? "--" : item.lessFsApprovalPto.toFixed(2).toString();

                row3[PropName] = item.estPtoHours === 0 ? "--" : item.estPtoHours.toFixed(2).toString();

                this.PTOBalanceColumns.push(PropName);

                if(item.isHighlightedDate){
                    this.highlightedColumn.push(PropName);
                }
            })

            this.PTOBalanceData.push(row1);
            this.PTOBalanceData.push(row2);
            this.PTOBalanceData.push(row3);
        }
    }

    key(name: any) {
        return name.replace(/[-/ ]/g, '_');
    }
    showLoader(){
        this.isShowLoader = true;
        this.isShowPTOContent = false;
        this.isShowPTOMessage = false;
    }

    hideLoader(){
        this.isShowLoader = false;
    }
}
</script>
<style scoped>
.colDateWidth{
    width: 70px;
}

.colTextWidth{
    width: 175px;
}

.fixedCol{
    position: sticky !important;
    left: 0;
    z-index: 4 !important;
    background: #fff !important;
}
#ptoGrid{
    overflow-x: auto;
}

.ptoTable{
    table-layout: fixed; 
    width:100%;
}
.ptoTable th {
    text-align: left;
    border-bottom: 0.063rem solid #bcbcbc;
    padding: 0.313rem 0.938rem !important;
}
.ptoTable .neu-table__row--small td {
    padding: 0.313rem 0.938rem !important;
}

.ptoTable td {
    border-bottom: 0.063rem solid #bcbcbc;
    border-top: 0.063rem solid #bcbcbc;
    padding: 0.625rem 0.938rem;
}

.fontNormal{
    font-weight: normal !important;
}
</style>