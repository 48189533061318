import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { key: 4 }
const _hoisted_5 = { key: 5 }
const _hoisted_6 = { key: 6 }
const _hoisted_7 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Surge = _resolveComponent("Surge")!
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_DashboardMain = _resolveComponent("DashboardMain")!
  const _component_DashboardSevenDay = _resolveComponent("DashboardSevenDay")!
  const _component_ToasterPopup = _resolveComponent("ToasterPopup")!
  const _component_WorkforceRequest = _resolveComponent("WorkforceRequest")!
  const _component_ContractLaborManagement = _resolveComponent("ContractLaborManagement")!
  const _component_UserPreferences = _resolveComponent("UserPreferences")!
  const _component_CensusModal = _resolveComponent("CensusModal")!
  const _component_DailyRoster = _resolveComponent("DailyRoster")!
  const _component_SMSNotifications = _resolveComponent("SMSNotifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoadHomePage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.profileData.IsSurgeAlertFeatureEnabled)
            ? (_openBlock(), _createBlock(_component_Surge, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.isShowAggregatedSkillDashboard)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_FilterBar, {
                  dashboardConfig: _ctx.dashboardConfig,
                  filterPreferences: _ctx.filterPreferences
                }, null, 8, ["dashboardConfig", "filterPreferences"]),
                _createVNode(_component_DashboardMain, {
                  dashboardConfig: _ctx.dashboardConfig,
                  filterPreferences: _ctx.filterPreferences
                }, null, 8, ["dashboardConfig", "filterPreferences"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isShow7DayStaffingView)
            ? (_openBlock(), _createBlock(_component_DashboardSevenDay, { key: 2 }))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.isToasterVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ToasterPopup, {
            onCloseModal: _ctx.closeToasterPopup,
            msgValue: _ctx.msgValue,
            classFlag: _ctx.classFlag
          }, null, 8, ["onCloseModal", "msgValue", "classFlag"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowWorkforceRequestPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_WorkforceRequest, {
            workforceRequestInput: _ctx.workforceRequestInput,
            onCloseModal: _ctx.closeWorkforceRequesPopup
          }, null, 8, ["workforceRequestInput", "onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowContractLaborManagementPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ContractLaborManagement, {
            contractLaborInput: _ctx.contractLaborManagementInput,
            onCloseModal: _ctx.closeContractLaborPopup
          }, null, 8, ["contractLaborInput", "onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowMyPreference)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_UserPreferences, { onCloseModal: _ctx.closeUserPreferencesPopup }, null, 8, ["onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowManageCensusPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_CensusModal, {
            manageCensusInput: _ctx.manageCensusInput,
            onCloseModal: _ctx.closeManageCensusPopup
          }, null, 8, ["manageCensusInput", "onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowDailyRosterFilterReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_DailyRoster, {
            dailyRosterInput: _ctx.dailyRosterInput,
            onCloseModal: _ctx.closeDailyRosterFilterReportPopup
          }, null, 8, ["dailyRosterInput", "onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isSMSNotification)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_SMSNotifications, { onCloseModal: _ctx.closeSMSNotificationsPopup }, null, 8, ["onCloseModal"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}