import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";
import camelcaseKeys from "camelcase-keys";

const namespaced = true

interface State {
    needsAggregationMetrics: any
    deparmentMetrics: any
    plusMetrics: any
    departmentSkillList: any
    staffSkills: any
    facilityList: any
    departmentList: any
    skillSpecialization: any
    facilitiesByUser: any
    departmentsByUser: any
    skillsByDepartment: any
    contractLaborShiftStatus: any
    skillByCategory: any
}

//state
const state: State = {
    needsAggregationMetrics: null,
    deparmentMetrics: null,
    plusMetrics: null,
    departmentSkillList: null,
    staffSkills: null,
    facilityList: null,
    departmentList: null,
    skillSpecialization: null,
    facilitiesByUser: null,
    departmentsByUser: null,
    skillsByDepartment: null,
    contractLaborShiftStatus: null,
    skillByCategory: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getNeedsAggregationMetrics({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/needsaggregationmetrics/${inputs.deptId}/${inputs.startDate}/${inputs.endDate}/`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setNeedsAggregationMetrics', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDeparmentMetrics({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DepartmentMatrix/deptmatrix/${inputs.tenantId}/${inputs.departmentId}/${inputs.deptSummaryId}/${inputs.startDateTime}/`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setDeparmentMetrics', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getPlusMetrics({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/PlusMetrics/${inputs.tenantId}?departmentId=${inputs.deptId}&startDate=${inputs.newDate}&currentTimeStamp=${inputs.timestamp}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                        const data = camelcaseKeys(res.data.data.results, {deep: true});
                        commit('setPlusMetrics', data);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDepartmentSkills({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` +`/departmentskills/${inputs.tenantId}/${inputs.deptId}/${inputs.date}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setDepartmentSkills', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getStaffSkills({ commit, rootState }, inputs: any) {        
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` +`/skillLevel/${inputs.tenantId}/bystaff/${inputs.staffId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setStaffSkills', data);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getAllFacilities({ commit, rootState }, tenantId: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` +`/facilities/${tenantId}/GetAllFacilitiesKvp`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setAllFacilities', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDepartmentsByFacility({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` +`/departments/${inputs.tenantId}/GetDepartmentsByFacilityKvp/${inputs.facilityId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setDepartmentsByFacility', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSkillSpecializations({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlabor/skillspecialization/${inputData.FacilityCoid}/${inputData.DepartmentId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setSkillSpecializations', res.data.data.results);
                }
                else{
                    commit('setSkillSpecializations', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getFacilitiesByUser({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` +`/facilities/${inputData.TenantId}/GetByUser/${inputData.UserId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setFacilitiesByUser', res.data.data.results);
                }
                else{
                    commit('setFacilitiesByUser', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDepartmentsByUser({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/departments/byuser/${inputData.UserId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    const data = camelcaseKeys(res.data.data.results, {deep: true, pascalCase: true});
                    commit('setDepartmentsByUser', data);
                }
                else{
                    commit('setDepartmentsByUser', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSkills({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` +`/departmentskills/ByDepartments`;

        return http
            .post(apiUrl, inputData)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setSkillsByDepartments', res.data.data.results);
                }
                else{
                    commit('setSkillsByDepartments', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    }, 
    getContractLaborShiftStatuses({ commit, rootState }, tenantId: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts/status?tenantId=${tenantId}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setContractLaborShiftStatuses', res.data.data.results);
                }
                else{
                    commit('setContractLaborShiftStatuses', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSkillsBySkillCategory({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Dashboards/GetSkillGroups?dashboardId=${inputData}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.Data != null)){
                    commit('setSkillsBySkillCategory', res.data.Data.Results);
                }
                else{
                    commit('setSkillsBySkillCategory', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    }, 
}

// mutations
const mutations: MutationTree<State> = {
    setDeparmentMetrics(state, result: any){
        state.deparmentMetrics = result;
    },
    setNeedsAggregationMetrics(state, result: any){
        state.needsAggregationMetrics = result;
    },
    setPlusMetrics(state, result: any){
        state.plusMetrics = result;
    },
    setDepartmentSkills(state, result: any){
        state.departmentSkillList = result;
    },
    setStaffSkills(state, result: any){
        state.staffSkills = result;
    },
    setAllFacilities(state, result: any){
        state.facilityList = result;
    },
    setDepartmentsByFacility(state, result: any){
        state.departmentList = result;
    },
    setSkillSpecializations(state, result: any){
        state.skillSpecialization = result;
    },
    setFacilitiesByUser(state, result: any){
        state.facilitiesByUser = result;
    },
    setDepartmentsByUser(state, result: any){
        state.departmentsByUser = result;
    },
    setSkillsByDepartments(state, result: any){
        state.skillsByDepartment = result;
    },
    setContractLaborShiftStatuses(state, result: any){
        state.contractLaborShiftStatus = result;
    },
    setSkillsBySkillCategory(state, result: any){
        state.skillByCategory = result;
    },
}

export const shared: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

