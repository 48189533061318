export enum DashboardRelations {
    SKILLLEVEL = 1,
    CENSUS = 2,
    FORECASTCENSUS = 4,
    GROUPING = 102,
    SKILLGROUPHEADER = 103,
    CUSTOMSKILLGROUPHEADER = 104
}

export enum AppMode {
    ReadOnly = 0,
    ReadOnlyDetails = 1,
    FullAccess = 2,
    NoAccess = 4
}

export enum CountType {
    COUNT = 1,
    DONT_COUNT_BUT_SHOW = 2,
    DONT_COUNT_BUT_HIDE = 3
}

export enum ProductiveTypes {
    PRODUCTIVE = 1,
    OTHER_PRODUCTIVE = 2,
    NON_PRODUCTIVE = 3,
    CALL = 4,
    PLACEHOLDER = 5
}

export enum NeedStatus {
    NO_STATUS = 0,
    BOOKED = 1,
    CONFIRMED = 2,
    CANCELED = 3,
    NO_NEED = 4
}

export enum NumberFilterType {
    IS_EQUAL_TO = 2,
    GREATER_THAN_OR_EQUAL_TO = 3,
    LESS_THAN_OR_EQUAL_TO = 4
}

export enum NoteType {
    COMMENTS = 1,
    SMS = 2,
    EMAIL = 3,
}

export enum NoteMaxLength {
    ONEHUNDRED = 100,
    TWOHUNDRED = 200,
    THREEHUNDRED = 300,
    FOURHUNDRED = 400,
    FIVEHUNDRED = 500,
}

export enum NoteRelationType {

    FS_ASSIGN = 1,
    FS_DEPT = 2,
    FS_FACILITY = 3,
    FS_CENSUS = 4,
    FS_DEPTFORECASTCENSUS = 5,
    FS_NEEDNOTE = 6,
}

export enum DateFilterType {
    EQUAL = 2,
    AFTER_OR_EQUAL_TO = 3,
    BEFORE_OR_EQUAL_TO = 4
}

export enum NotificationActionTypes {
    approved = 1,
    denied = 2,
    received = 3
}
