import { createStore } from 'vuex'
import { dashboard } from './modules/dashboard'
import { userstaffpreferences } from './modules/userstaffpreferences'
import { surgealert } from './modules/surgealert';
import Oidc from './modules/oidc'
import { shared } from './modules/shared';
import { assignments } from './modules/assignments';
import { actionable } from './modules/actionable';
import { profile } from './modules/profile';
import { managecensus } from './modules/managecensus';
import { dailyroster } from './modules/dailyroster';
import ApprovalModule from './modules/approvals';
import ScheduleModule from './modules/schedule';
import SecurityModule from './modules/security';
import FilterModule from './modules/filters';

export default createStore({
  modules: {
    Oidc,
    dashboard,
    userstaffpreferences,
    surgealert,
    shared,
    assignments,
    actionable,
    profile,
    managecensus,
    dailyroster,
    approvalStore: ApprovalModule,
    scheduleStore: ScheduleModule,
    securityStore: SecurityModule,
    filterStore: FilterModule,
  }
})