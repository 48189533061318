import axios from 'axios'
import { ApprovalModel } from '@/models/scheduleManagement'
import {
  ApprovalOrRejectRequest,
  ApprovalSearchResults,
  ApprovalTableParams,
} from '@/models/scheduleManagement/approval.model'
import {
  TalentProfileResults,
  TalentProfileSearchRequests,
  TalentProflesRequestPayload,
} from '@/models/scheduleManagement/talentProfile.model'
import {
  TradeProfileRequestPayload,
  TradeProfileResults,
  TradeSearchProfileResults,
} from '@/models/scheduleManagement/tradeProfile.model'
export async function getAllAprovalsRequests(
  params: ApprovalTableParams
): Promise<ApprovalSearchResults> {
  const queryStrArr: Array<String> = []
  Object.keys(params).forEach((k) => {
    //queryStrArr.push(`${k}=${params[k]}`)
  })
  try {
    const response = await axios.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Requests/NonProductives/Search?${queryStrArr.join('&')}`
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function approveOrRejectRequest(
  payload: ApprovalOrRejectRequest,
  assignmentRequestId: number | undefined
): Promise<ApprovalModel> {
  try {
    const response = await axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/Requests/NonProductives/${assignmentRequestId}`,
      payload
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

// Talent profile requests

export async function getAllTalentedProfileRequests(
  params: ApprovalTableParams
): Promise<TalentProfileSearchRequests> {
  const queryStrArr: Array<String> = []
  Object.keys(params).forEach((k) => {
    //queryStrArr.push(`${k}=${params[k]}`)
  })
  try {
    const response = await axios.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Requests/TalentProfiles/Search?${queryStrArr.join('&')}`
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
// approve or modification talent profiles
export async function talentProfileRequest(
  payload: TalentProflesRequestPayload,
  talentProfileRequestId: number | undefined
): Promise<TalentProfileResults> {
  try {
    const response = await axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/Requests/TalentProfles/${talentProfileRequestId}`,
      payload
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
// Trade profile requests

export async function getAllTradeProfileRequests(
  params: ApprovalTableParams
): Promise<TradeSearchProfileResults> {
  const queryStrArr: Array<String> = []
  Object.keys(params).forEach((k) => {
    //queryStrArr.push(`${k}=${params[k]}`)
  })
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/Requests/Trades/Search?${queryStrArr.join(
        '&'
      )}`
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
// approve or reject trade profiles
export async function tradeProfileRequest(
  payload: TradeProfileRequestPayload,
  tradeRequestId: number | undefined
): Promise<TradeProfileResults> {
  try {
    const response = await axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/Requests/Trades/${tradeRequestId}`,
      payload
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
// Need profile requests

export async function getAllNeedProfileRequests(
  params: ApprovalTableParams
): Promise<ApprovalSearchResults> {
  const queryStrArr: Array<String> = []
  Object.keys(params).forEach((k) => {
    //queryStrArr.push(`${k}=${params[k]}`)
  })
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/Requests/Needs/Search?${queryStrArr.join(
        '&'
      )}`
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
// approve or modification Need profiles
export async function needProfileRequest(
  payload: ApprovalOrRejectRequest,
  assignmentRequestId: number | undefined
): Promise<ApprovalModel> {
  try {
    const response = await axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/Requests/Needs/${assignmentRequestId}`,
      payload
    )

    return response?.data
  } catch (error) {
    return Promise.reject(error)
  }
}
