import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";
import camelcaseKeys from "camelcase-keys";

const namespaced = true

interface State {
    forecastCensus: any,
    updateCensus: any,
    partOfDayTemplates: any,
    censusActivityLog: any
}

//state
const state: State = {
    forecastCensus: null,
    updateCensus: null,
    partOfDayTemplates: null ,
    censusActivityLog: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getForecastCensus({ commit, rootState }, inputs: any) {
                const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/getforecastcensus`;
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    const data = camelcaseKeys( res.data == '' ? null : res.data.data.results, { deep: true });
                    commit('getForecastCensus', data);
                }
            })
            .catch((err) => {
                throw err;
            });
    },

    updateManageCensus({ commit, rootState }, inputs: any) {       
        
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/updatecensusformanagecensus`;
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    const data = camelcaseKeys( res.data == '' ? null : res.data.data.results, { deep: true });
                    commit('updateManageCensus', data);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
 
    getPartOfDayTemplates({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/getpartofdaytemplates/${inputs.TenantId}`;
        return http
            .get(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    const data = camelcaseKeys( res.data.data.results, { deep: true });
                    commit('getPartOfDayTemplates', data);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDashboardCensusActivityLog({ commit, rootState }, inputs: any) {               
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/getdashboardcensusactivitylog`;
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null){
                    const data = camelcaseKeys( res.data == '' ? null : res.data.data.results, { deep: true });
                    commit('getDashboardCensusActivityLog', data);
                }
            })
            .catch((err) => {
                throw err;
            });
    }
}

// mutations
const mutations: MutationTree<State> = {   
    getForecastCensus(state, result: any){
        state.forecastCensus = result;
    },
    updateManageCensus(state, result: any){
        state.updateCensus = result;
    },
    getPartOfDayTemplates(state, result: any){
        state.partOfDayTemplates = result;
    },
    getDashboardCensusActivityLog(state, result: any){
        state.censusActivityLog = result;
    },
}

export const managecensus: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

