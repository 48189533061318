<template>
    <div class="">
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="filter-button ml-3" id="dashboard-filters">
                    <div id="filters-dashboard" class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" @click="showFilter" data-bs-toggle="dropdown" id="filters-dashboard-button" aria-haspopup="true" aria-expanded="false" role="button">Filters</button>
                        <div class="dropdown-menu" :class="[isShowFilterDropdown ? 'filterShow': '' , 'dropdown-menu']">
                        <FilterGrid :dashboardConfig="dashboardConfig" :skillData="skills" v-if="isShowFilterPanel" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                    <div class="left_arrow" @click="previousDateRange"> <font-awesome-icon :icon="['fas', 'angle-left']" size="lg" /></div>
                    <h4 class="dateTxt">{{gridDate}}</h4>
                    <div class="right_arrow" @click="nextDateRange"> <font-awesome-icon :icon="['fas', 'angle-right']" size="lg" /></div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="metricCountToggle">                    
                <DashboardNavbar />
                    <label class="bold cursorPointer">Show Actual</label>
                    <fieldset class="toggle">
                        <input
                        type="checkbox"
                        class="cmn-toggle cmn-toggle-round"
                        id="disableRefreshToggle-dashboard"
                        v-model="isShowActualCount"
                        @change="onMetricsCountChanges"
                        />
                        <label
                        for="disableRefreshToggle-dashboard" class="cursorPointer"
                        style="width: 40px">
                        <div class="toggle-button">
                            <div class="toggle-tab"></div>
                        </div>
                        </label>
                    </fieldset>                    
                </div>
            </div>
        </div>
        <div class="mt-3 scrollBar sevenDaydashboardGrid">
            <table id="sevenDaydashboardTable" class="table-hover dashboardTable sevenDaydashboardTable" style="height: auto;">
                <colgroup id="sevenDayDashboardColGroupTbl">
                </colgroup>
                <thead id="sevenDayDashboardHeaderTbl">
                </thead>
                <tbody id="sevenDayDashboardBodyTbl">
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="isToasterVisible">
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isPremiumLaborBreakdownVisible">
      <PremiumLaborBreakdown @closeModal="closePremiumLaborBreakdownPopup"
                    :premLaborBreakdownInput="premLaborBreakdownInput"
                    :isBreakdownfrom7Day="true"></PremiumLaborBreakdown>
    </div> 
    <div v-if="isDepartmentModelVisible">
      <DepartmentDetails @closeModal="closeDepartmentPopup"
                    :departmentList="departmentList"
                    :headerDetails="headerDetails"></DepartmentDetails>
    </div>    
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import jslinq from "jslinq";
import moment from 'moment';

//import model and enum
import { Profile } from '@/models/profile/index';
import { SevenDayInput, SevenDayResult, Facility, Cluster, Metric, KeyValuePair, PremiumLaborKeyValuePair, Department, MetricsTotalBreakup, Skill } from "@/models/dashboardSevenDay/index";
import {KeyPairModel} from '@/models/common/index';
import { StartEndDateTimeModel } from "@/models/dashboardmain/index";
import { useAppInsights } from '../../store/modules/AppInsights';

//import components
import FilterGrid from './FilterGrid.vue';
import ToasterPopup from '../shared/ToasterPopup.vue';
import PremiumLaborBreakdown from './PremiumLaborBreakdown.vue';
import DepartmentDetails from './DepartmentDetails.vue';
import DashboardNavbar   from '../common/DashboardNavbar.vue'

@Options({
    computed: {
    ...mapState('dashboard', ['dashboardConfiguration', 'sevenDayViewData', 'sevenDayViewFilter']),
    ...mapState('profile', ['profileData', 'appInsightEventData']),
    ...mapState('shared', ['skillByCategory'])
  },
  components: {
    FilterGrid,
    ToasterPopup,
    PremiumLaborBreakdown,
    DepartmentDetails,
    DashboardNavbar
  },
})
export default class DashboardSevenDay extends Vue {
    //Data properties
    dashboardConfiguration!: any;
    sevenDayViewData!: any;
    sevenDayViewFilter!: any;
    profileData!: Profile;
    appInsightEventData!: any;
    skillByCategory!: any;
    dashboardConfig: any = null;
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    shortDateFormat: string = 'YYYY-MM-DD';
    gridDateRangeFormat: string = 'M/D/YY';
    gridHeaderDateFormat: string = 'ddd MM/DD';
    shortTimeFormat: string = 'hh:mm A';
    currentNextShifts: any = [];
    apiInput!: SevenDayInput;
    metricsGridData!: SevenDayResult;

    gridDate: string = "";
    startDate: string = "";
    endDate: string = "";
    isShowFilterDropdown: boolean = false;
    isShowFilterPanel: boolean = false;

    gridFirstRowHeaders: any[] = [];
    gridSecondRowHeaders: any[] = [];
    weekEndDay1: string = "";
    weekEndDay2: string = "";
    MetricPIColList: any[] = ["DayLess95PI", "DayGreater115PI", "ProdIndex"]
    MetricPI95115ColList: any[] = ["DayLess95PI", "DayGreater115PI"];
    defaultSkilllList: any[] = ["RN", "LPN", "Chg RN"];
    isPremiumLaborBreakdownVisible: boolean = false;
    premLaborBreakdownInput: PremiumLaborKeyValuePair[] = [];
    skills: Skill[] = [];

    isDepartmentModelVisible: boolean = false;
    departmentList: Department[] = [];
    headerDetails: any = {};
    isShowActualCount: boolean = false;

    //Lifecycle hooks
    created(){
        this.emitter.on('loadDashboardGrid', this.loadDashboardGrid);
        this.emitter.on('close7DayGridFilterPanel', this.close7DayGridFilterPanel);
        this.emitter.on('showToasterNotification7DayView', this.showToasterNotification7DayView);
        
        useAppInsights().trackEvent({
            name: 'DASHBOARD_SEVENDAY_VIEW', properties:
                JSON.parse(JSON.stringify(this.appInsightEventData))
        });
        
    }
    async mounted() {
        await this.loadFilterInput();
        this.loadGridDateRange(moment(new Date()).add(1, 'days'));
        this.emitter.emit("setSelectedTopNavTab", "7DayView");
        
        await this.loadDashboardGrid();
    }
    
    unmounted(){
        this.emitter.off('loadDashboardGrid', this.loadDashboardGrid);
        this.emitter.off('close7DayGridFilterPanel', this.close7DayGridFilterPanel);
        this.emitter.off('showToasterNotification7DayView', this.showToasterNotification7DayView);
        this.emitter.emit("hideLastUpdatedTime");
    }

    //Methods
    getCurrentDateTime() {
      var date = moment(new Date()).format(this.longDateFormat);
      return date;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    closePremiumLaborBreakdownPopup(){
        this.isPremiumLaborBreakdownVisible = false;
    }

    closeDepartmentPopup(){
        this.isDepartmentModelVisible = false;
    }

    showFilter(){
        this.isShowFilterDropdown = !this.isShowFilterDropdown;
    }

    async loadFilterInput(){
        await this.getSkills()
        this.dashboardConfig = this.dashboardConfiguration;
        this.isShowFilterPanel = true;
    }

    async getSkills(){
        await this.$store
            .dispatch("shared/getSkillsBySkillCategory", this.profileData.DashboardId)
            .then(() => {
                if(this.skillByCategory != null && this.skillByCategory.length > 0){
                    this.skills = this.skillByCategory;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Skill.",
                            flag: "error"
                        }
                    this.emitter.emit('showToasterNotification7DayView', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    loadGridDateRange(date: any){
        this.startDate = moment(date).format(this.gridDateRangeFormat);
        this.endDate = moment(date).add(6, 'days').format(this.gridDateRangeFormat);
        this.gridDate = this.startDate + ' - ' + this.endDate;
    }

    async previousDateRange(){
        this.endDate = moment(this.startDate).subtract(1, 'days').format(this.gridDateRangeFormat);
        this.startDate = moment(this.startDate).subtract(7, 'days').format(this.gridDateRangeFormat);
        this.gridDate = this.startDate + ' - ' + this.endDate;

        if(this.sevenDayViewFilter != null){
            let savedFilter = this.sevenDayViewFilter;
            savedFilter.StartDate =  moment(this.startDate).format(this.shortDateFormat);
            savedFilter.EndDate=  moment(this.endDate).add(1, 'days').format(this.shortDateFormat);
            await this.saveAppliedFilterToState(savedFilter);
        }
        else{
            this.loadDashboardGrid();
        }
    }

    async nextDateRange(){
        this.startDate = moment(this.endDate).add(1, 'days').format(this.gridDateRangeFormat);
        this.endDate = moment(this.startDate).add(7, 'days').format(this.gridDateRangeFormat);
        this.gridDate = this.startDate + ' - ' + this.endDate;

        if(this.sevenDayViewFilter != null){
            let savedFilter = this.sevenDayViewFilter;
            savedFilter.StartDate =  moment(this.startDate).format(this.shortDateFormat);
            savedFilter.EndDate=  moment(this.endDate).format(this.shortDateFormat);
            await this.saveAppliedFilterToState(savedFilter);
        }
        else{
            this.loadDashboardGrid();
        }
    }

    async saveAppliedFilterToState(filter: any){
        await this.$store
            .dispatch("dashboard/saveSevenDayViewFilter", filter)
            .then(() => {
                this.emitter.emit("loadDashboardGrid");
            })
            .catch((err: any) => {
                
            });
    }

    loadDashboardGrid(){
        let filterToApply: any = null;
        if(this.sevenDayViewFilter != null){
            filterToApply = this.sevenDayViewFilter;
        }
            
        this.setStartEndTime();

        var defaultFacilityId = [];
        if (this.profileData.FacilityId != null && this.profileData.FacilityId != undefined && this.profileData.FacilityId != 0) {
            defaultFacilityId.push(this.profileData.FacilityId.toString());
        }
        else{
            defaultFacilityId.push(this.dashboardConfig.Filters.Facilities[0].Value)
        }

        var currentShift: any = this.currentNextShifts[0];
        var startTime = moment(currentShift.startDateTime).format("HH:mm");
        var endTime = moment(currentShift.endDateTime).format("HH:mm");
        var sDate = moment(currentShift.startDateTime).format(this.shortDateFormat);
        var eDate = moment(currentShift.endDateTime).format(this.shortDateFormat);
        var isSameDate = moment(sDate).isSame(eDate);


        if(filterToApply == null){
            var input: SevenDayInput = {
                DashboardId: this.profileData.DashboardId,
                StartDate: moment(this.startDate).format(this.shortDateFormat),
                EndDate: isSameDate ? moment(this.endDate).format(this.shortDateFormat) : moment(this.endDate).add(1, 'days').format(this.shortDateFormat),
                CurrentDateTime: this.getCurrentDateTime(),
                Facilities: defaultFacilityId,
                Clusters: null,
                Departments: null,
                SkillEntityIds: this.setDefaultSkills(),
                StartShiftSummary: startTime,
                EndShiftSummary: endTime,
                UserId: this.profileData.UserId,
                isShowPremiumLabor: false,
                isPI14Day: true,
                isPI7Day: true,
                SelectedSummary: ""
            }
            this.apiInput = input;
        }
        else{
            if(filterToApply.StartShiftSummary == "" && filterToApply.EndShiftSummary == "" && !isSameDate){
                filterToApply.EndDate = moment(filterToApply.EndDate).add(1, 'days').format(this.shortDateFormat);
            }

            if(filterToApply.StartShiftSummary == ""){
                filterToApply.StartShiftSummary = startTime;
            }
            if(filterToApply.EndShiftSummary == ""){
                filterToApply.EndShiftSummary = endTime;
            }

            this.apiInput = filterToApply;
            this.loadGridDateRange(this.apiInput.StartDate);
        }
        //Fetch Grid Data from API
        this.getSevenDayViewData();
    }

    async getSevenDayViewData(){
        this.emitter.emit('showLoader');
        await this.$store
              .dispatch("dashboard/getSevenDataViewData", this.apiInput)
              .then(() => {
                    this.metricsGridData = this.sevenDayViewData;
                    this.renderGridBody();

                    this.emitter.emit("setLastUpdateDateOfMetricsRefreshed");
                    this.emitter.emit('hideLoader');
              })
              .catch((err: any) => {
                  if (err) {
                      this.emitter.emit('hideLoader');
                      this.msgValue = "Error encountered while retrieving seven day metrics details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                      console.error(err); // Handle errors any way you want
                  }
              }).finally(() => {
                this.emitter.emit('showLastUpdatedTime');
              });
            if (this.isShowActualCount) {
                this.onMetricsCountChanges()
            }
    }

    renderGridBody(){
        this.createGridHeader();
        if(this.metricsGridData!= null && this.metricsGridData.Facilities != null && this.metricsGridData.Facilities.length > 0){
            this.createGridBody();
        }
    }

    createGridHeader(){
        this.loadGridHeaderData();
        this.loadGridHeaderHTML();
    }

    loadGridHeaderData(){
        this.gridFirstRowHeaders = [];
        this.gridSecondRowHeaders = [];

        //Load First row header data
        for(var i= 0; i < 7; i++){
            var dayOfWeek = moment(this.startDate).add(i, 'days').format('ddd');
            if(dayOfWeek.toUpperCase() == 'SAT'){
                this.weekEndDay1 = "Day" + (i+1);
            }
            if(dayOfWeek.toUpperCase() == 'SUN'){
                this.weekEndDay2 = "Day" + (i+1);
            }

            var date = moment(this.startDate).add(i, 'days').format(this.gridHeaderDateFormat);
            this.gridFirstRowHeaders.push(date);
        }
        this.gridFirstRowHeaders.push("Weekly Avg. Variance");
        this.gridFirstRowHeaders.push("Total Variance");

        //Load Second row header data
        this.gridSecondRowHeaders.push("Facility");
        this.gridSecondRowHeaders.push("Service Line");
        this.gridSecondRowHeaders.push("Shift Summary");
        this.gridSecondRowHeaders.push("% Days < 95% PI");
        this.gridSecondRowHeaders.push("% Days > 115% PI");
        
        if(this.sevenDayViewFilter == null || this.sevenDayViewFilter.isPI14Day) 
            this.gridSecondRowHeaders.push("14-Day Prod. Index");
        else 
            this.gridSecondRowHeaders.push("7-Day Prod. Index");

        for(var i= 0; i < 9; i++){
            this.gridSecondRowHeaders.push("Var.");
            if(this.apiInput.isShowPremiumLabor){
                this.gridSecondRowHeaders.push("Prem. Labor");
            }
        }
    }

    loadGridHeaderHTML(){
        var dashboardTableHead: any = document.getElementById('sevenDayDashboardHeaderTbl');
        var dashboardTableColGroup: any = document.getElementById('sevenDayDashboardColGroupTbl');

        dashboardTableHead.innerHTML = "";
        dashboardTableColGroup.innerHTML = "";

        var colGroup: any = this.prepareColGroup(this.gridSecondRowHeaders);
        var firstRowHeader: any = this.prepareFirstHeader(this.gridFirstRowHeaders);
        var secondRowHeader: any = this.prepareSecondHeader(this.gridSecondRowHeaders);

        colGroup.forEach((col: any) => {
            dashboardTableColGroup.appendChild(col);
        });
        dashboardTableHead.appendChild(firstRowHeader);
        dashboardTableHead.appendChild(secondRowHeader);
    }

    prepareColGroup(columns: any){
        var colGroupRow = [];
        for (var h = 0; h < columns.length; h++) {
            var config = columns[h];
            var col = document.createElement('col');

            if (h < 1) {
                col.style.width = "100px";
            }
            else if (h < 2) {
                col.style.width = "160px";
            } else if (h < 3) {
                col.style.width = "140px";
            } else if (h < 6) {
                col.style.width = "80px";
            } else {
                col.style.width = '70px';
            }
            colGroupRow.push(col);
        }
        return colGroupRow;
    }

    prepareSecondHeader(columns: any) {
       var headerRow = document.createElement('tr');
        for (var h = 0; h < columns.length; h++) {
            var column = columns[h];

            var th: any = document.createElement('th');
            th.style.padding = "3px 3px 3px 3px";
            th.classList = 'detailsHeader';

            if(column == 'Var.'){
                let spanVar: any = document.createElement('span');
                spanVar.innerHTML = 'Var.';
                spanVar.classList.add('metricsVarianceCount');

                let spanActual: any = document.createElement('span');
                spanActual.innerHTML = 'Actual';
                spanActual.classList.add('metricsActualCount');
                spanActual.classList.add('d-none');

                th.appendChild(spanVar);
                th.appendChild(spanActual);
            }
            else{
                th.innerHTML = column;
            }
            
            headerRow.appendChild(th);
        }
        return headerRow;
    }

    prepareFirstHeader(columns: any){
        var headerRow = document.createElement('tr');

        var thCommon: any = document.createElement('th');
        thCommon.style.padding = "3px 3px 3px 3px";
        thCommon.classList = 'detailsHeader';
        thCommon.colSpan = 6;
        headerRow.appendChild(thCommon);

        for (var h = 0; h < columns.length; h++) {
            var column = columns[h];

            var th: any = document.createElement('th');
            th.style.padding = "3px 3px 3px 3px";
            th.classList = 'detailsHeader';
            
            if(column == 'Weekly Avg. Variance'){
                let spanVar: any = document.createElement('span');
                spanVar.innerHTML = 'Weekly Avg. Variance';
                spanVar.classList.add('metricsVarianceCount');

                let spanActual: any = document.createElement('span');
                spanActual.innerHTML = 'Weekly Avg. Actual';
                spanActual.classList.add('metricsActualCount');
                spanActual.classList.add('d-none');

                th.appendChild(spanVar);
                th.appendChild(spanActual);
            }
            else if(column == 'Total Variance'){
                let spanVar: any = document.createElement('span');
                spanVar.innerHTML = 'Total Variance';
                spanVar.classList.add('metricsVarianceCount');

                let spanActual: any = document.createElement('span');
                spanActual.innerHTML = 'Total Actual';
                spanActual.classList.add('metricsActualCount');
                spanActual.classList.add('d-none');

                th.appendChild(spanVar);
                th.appendChild(spanActual);
            }
            else{
                th.innerHTML = column;
            }
            
            if(this.apiInput.isShowPremiumLabor){
                th.colSpan = 2;
            }
            headerRow.appendChild(th);
        }

        return headerRow;
    }

    createGridBody(){
        var tableBody: any = document.getElementById('sevenDayDashboardBodyTbl');
        tableBody.innerHTML = "";

        //create Total row.
        var rowTotal: any = document.createElement('tr');
        rowTotal.classList.add('totalrow');
        var colTotalTxt: any = document.createElement('td');
        colTotalTxt.innerHTML = "<span>Selected Facilities</span> <span class='headingTotalTxt'>Total</span>";
        colTotalTxt.classList.add('headingCol');
        colTotalTxt.colSpan = 3;
        rowTotal.appendChild(colTotalTxt);

        var grandTotal: Metric | null = this.metricsGridData.Total;
        if(grandTotal != null){
            this.loadGrandFacilityTotalMetrics(grandTotal, rowTotal);
        }

        tableBody.appendChild(rowTotal);

        var facilities: Facility[] | null = this.metricsGridData.Facilities;
        if(facilities != null){
            //loop through Facility
            for (var f = 0; f < facilities.length; f++) {
                var facility: Facility = facilities[f];
                var that= this;

                //create Facility Title row
                var rowFacilityName: any = document.createElement('tr');
                rowFacilityName.classList.add('facilitytitlerow');
                var colFacilityName: any = document.createElement('td');
                var icon: any = "<neu-icon class='material-icons clusterTitleIcon facilityTitleIcon_" + facility.FacilityId + "'>remove</neu-icon> <span>" + facility.FacilityName + "</span> <span class='headingTotalTxt'>Total</span>";                
                colFacilityName.innerHTML = icon;
                colFacilityName.classList.add('headingCol');
                colFacilityName.classList.add('cursorPointer');
                colFacilityName.classList.add("facilityTitle_" + facility.FacilityId);
                colFacilityName.colSpan = 3;
                colFacilityName.setAttribute('data-facility', facility.FacilityId);
                colFacilityName.setAttribute('data-isExpanded', true);
                //@ts-ignore
                colFacilityName.addEventListener('click', function () {  that.exapndClusterRows(this.getAttribute("data-facility"), this.getAttribute("data-isExpanded")); });
                rowFacilityName.appendChild(colFacilityName);

                var facilitytitleTotal: Metric | null = facility.Total;
                if(facilitytitleTotal != null){
                    this.loadGrandFacilityTotalMetrics(facilitytitleTotal, rowFacilityName);
                }

                tableBody.appendChild(rowFacilityName);

                if(facility.Clusters != null){
                    //create Facility Title row
                    var rowFacilitydetails: any = document.createElement('tr');
                    rowFacilitydetails.classList.add('Facility_'+ facility.FacilityId);
                    var totalRowSpan: number = 0;

                    //Facility Name
                    var colFacilityName: any = document.createElement('td');
                    colFacilityName.innerText = facility.FacilityName;
                    rowFacilitydetails.appendChild(colFacilityName);
                    tableBody.appendChild(rowFacilitydetails);

                    //create row by service line
                    for (var c = 0; c < facility.Clusters.length; c++) {
                        totalRowSpan++;
                        var cluster: Cluster = facility.Clusters[c];
                        var columnList: any[] = [];

                        //Service Line Name
                        var colServiceLineName: any = document.createElement('td');                        
                        var icon: any = "<neu-icon class='material-icons clusterExpandIcon clusterPlusIcon'>add</neu-icon> <span class='pl-2'>" + cluster.ClusterName + "</span>";                        
                        colServiceLineName.innerHTML = icon;
                        colServiceLineName.classList.add('posRelative');
                        colServiceLineName.classList.add('cursorPointer');
                        colServiceLineName.setAttribute('data-cluster', cluster.ClusterId);
                        colServiceLineName.setAttribute('data-facility', cluster.FacilityId);
                        //@ts-ignore
                        colServiceLineName.addEventListener('click', function () {  that.loadDepartmentPopup(this.getAttribute("data-cluster"), this.getAttribute("data-facility")); });

                        var clusterMetric: Metric | null = cluster.Metrics;
                        //Shift Summary time
                        var summaryStartTime: any = moment(cluster.StartDateTime).format(this.shortTimeFormat);
                        var summaryEndTime: any = moment(cluster.EndDateTime).format(this.shortTimeFormat);
                        var colSummaryTime: any = document.createElement('td');
                        colSummaryTime.innerText = summaryStartTime + ' - ' + summaryEndTime;

                        //Metrics Data
                        if(clusterMetric != null){
                            columnList = this.loadMetricsByCluster(clusterMetric, cluster.ClusterId, cluster.FacilityId);
                        }

                        if (c === 0) {
                            rowFacilitydetails.appendChild(colServiceLineName);
                            rowFacilitydetails.appendChild(colSummaryTime);
                            this.loadMetricsColumns(rowFacilitydetails, columnList)
                        } else {
                            var clRow = document.createElement('tr');
                            clRow.appendChild(colServiceLineName);
                            clRow.appendChild(colSummaryTime);
                            clRow.classList.add('Facility_'+ facility.FacilityId);
                            this.loadMetricsColumns(clRow, columnList);
                            tableBody.appendChild(clRow);
                        }
                    }
                    colFacilityName.rowSpan = totalRowSpan;
                }
            }
        }
    }

    loadMetricsColumns(row: any, metricCols: any[]){
        for(var i = 0; i< metricCols.length; i++){
            row.appendChild(metricCols[i]);
        }
    }

    loadGrandFacilityTotalMetrics(grandTotal: Metric, rowTotal: any){
        //"DayLess95PI", "DayGreater115PI", "ProdIndex"
        this.MetricPIColList.forEach((key: any) =>{
            this.createPIColumns(grandTotal, key, rowTotal);
        })

        //Day Columns
        for(var i=1; i<=7; i++){
            this.createDayColumns(grandTotal, "Day"+i, rowTotal);
        }

        //WeeklyAvg
        this.createDayColumns(grandTotal, "WeeklyAvg", rowTotal);

        //Total
        this.createDayColumns(grandTotal, "Total", rowTotal);
    }

    createPIColumns(grandTotal: Metric, key: any, rowTotal: any){
        var colTotalTxt: any = document.createElement('td');
        var colPISpn: any = document.createElement('span');
        
        var dayLess95PICss: string = "";
        if(this.MetricPI95115ColList.includes(key)){
            //@ts-ignore
            dayLess95PICss = this.getPICssClass(grandTotal[key]);
        }
        else{
            //@ts-ignore
            dayLess95PICss = this.getProdIndexCssClass(grandTotal[key]);
        }

        //@ts-ignore
        colPISpn.innerText = grandTotal[key] + '%';
        if(dayLess95PICss != ""){
            colPISpn.classList.add(dayLess95PICss);
        }
        colTotalTxt.appendChild(colPISpn);
        
        rowTotal.appendChild(colTotalTxt);
    }

    createDayColumns(grandTotal: Metric, key: any, rowTotal: any){
        //@ts-ignore
        var dayWiseBreakup: MetricsTotalBreakup | null = grandTotal[key];

        if(dayWiseBreakup != null){
            //day wise grand total for Variance
            var colTotalTxt: any = document.createElement('td');
            colTotalTxt.innerText = dayWiseBreakup.Metrics?.Variance;
            colTotalTxt.classList.add('metricsVarianceCount');

            //Add Special Background color for Weekend Cell
            if(key == this.weekEndDay1 || key == this.weekEndDay2){
                colTotalTxt.classList.add('weekendBgClr')
            }

            rowTotal.appendChild(colTotalTxt);

            //day wise grand total for Actual
            var colTotalActualTxt: any = document.createElement('td');
            colTotalActualTxt.innerText = dayWiseBreakup.Metrics?.Actual;
            colTotalActualTxt.classList.add('metricsActualCount');
            colTotalActualTxt.classList.add('d-none');

            //Add Special Background color for Weekend Cell
            if(key == this.weekEndDay1 || key == this.weekEndDay2){
                colTotalActualTxt.classList.add('weekendBgClr')
            }

            rowTotal.appendChild(colTotalActualTxt);

            //day wise grand total - Prem. Labor
            if(this.apiInput.isShowPremiumLabor){
                var colTotalTxt: any = document.createElement('td');
                colTotalTxt.innerText = dayWiseBreakup.PremiumLabor;
                //Add Special Background color for Weekend Cell
                if(key == this.weekEndDay1 || key == this.weekEndDay2){
                    colTotalTxt.classList.add('weekendBgClr')
                }
                rowTotal.appendChild(colTotalTxt);
            }
        }
    }

    createPIColumnsByCluster(grandTotal: Metric, key: any){
        var colPItd: any = document.createElement('td');
        var colPISpn: any = document.createElement('span');
        
        var dayLess95PICss: string = "";
        if(this.MetricPI95115ColList.includes(key)){
            //@ts-ignore
            dayLess95PICss = this.getPICssClass(grandTotal[key]);
        }
        else{
            //@ts-ignore
            dayLess95PICss = this.getProdIndexCssClass(grandTotal[key]);
        }

        //@ts-ignore
        colPISpn.innerText = grandTotal[key] + '%';
        if(dayLess95PICss != ""){
            colPISpn.classList.add(dayLess95PICss);
        }
        colPItd.appendChild(colPISpn);
        
        return colPItd;
    }

    createDayColumnsByCluster(grandTotal: Metric, key: any, clusterId: number| null, facilityId: number| null){
        var colList: any[]= [];
        //@ts-ignore
        var dayWiseBreakup: MetricsTotalBreakup | null = grandTotal[key];

        if(dayWiseBreakup != null){
            //day wise variance count
            var colTotalTxt: any = document.createElement('td');
            colTotalTxt.innerText = dayWiseBreakup.Metrics?.Variance;
            colTotalTxt.classList.add('metricsVarianceCount');

            //Add Special Background color for Weekend Cell
            if(key == this.weekEndDay1 || key == this.weekEndDay2){
                colTotalTxt.classList.add('weekendBgClr')
            }

            const metricNum = Number(dayWiseBreakup.Metrics?.Variance);
            //Add Style for Over and Under Staff
            if(metricNum < 0){
                colTotalTxt.classList.add('underStaff');
            }
            else if(metricNum > 0){
                colTotalTxt.classList.add('overStaff');
            }

            colList.push(colTotalTxt);

            //day wise actual count
            var colActualTxt: any = document.createElement('td');
            colActualTxt.innerText = dayWiseBreakup.Metrics?.Actual;
            colActualTxt.classList.add('metricsActualCount');
            colActualTxt.classList.add('d-none');

            //Add Special Background color for Weekend Cell
            if(key == this.weekEndDay1 || key == this.weekEndDay2){
                colActualTxt.classList.add('weekendBgClr')
            }

            colList.push(colActualTxt);


            //day wise grand total - Prem. Labor
            if(this.apiInput.isShowPremiumLabor){
                var colTotalTxt: any = document.createElement('td');
                colTotalTxt.innerText = dayWiseBreakup.PremiumLabor;

                const premLaborNum = Number(dayWiseBreakup.PremiumLabor);
                if(premLaborNum > 0){
                    colTotalTxt.classList.add('cursorPointer');
                    colTotalTxt.setAttribute('data-cluster', clusterId);
                    colTotalTxt.setAttribute('data-day', key);
                    colTotalTxt.setAttribute('data-value', dayWiseBreakup.PremiumLabor);
                    colTotalTxt.setAttribute('data-facility', facilityId);
                    var that= this;
                    //@ts-ignore
                    colTotalTxt.addEventListener('click', function () {  that.loadPremiumLaborBreakdownPopup(this.getAttribute("data-facility"), this.getAttribute("data-cluster"), this.getAttribute("data-day"), this.getAttribute("data-value")); });
                }
                //Add Special Background color for Weekend Cell
                if(key == this.weekEndDay1 || key == this.weekEndDay2){
                    colTotalTxt.classList.add('weekendBgClr')
                }
                colList.push(colTotalTxt);
            }
        }
        return colList;
    }

    loadMetricsByCluster(grandTotal: Metric, clusterId: number| null, facilityId: number| null){
        var colList: any[]= [];
        
        //"DayLess95PI", "DayGreater115PI", "ProdIndex"
        this.MetricPIColList.forEach((key: any) =>{
            colList.push(this.createPIColumnsByCluster(grandTotal, key));
        })

        //Day Columns
        for(var i=1; i<=7; i++){
            var dayRecords = this.createDayColumnsByCluster(grandTotal, "Day"+i, clusterId, facilityId);
            dayRecords.forEach((item: any) => {
                colList.push(item);
            });
        }

        //WeeklyAvg
        var weeklyAvg = this.createDayColumnsByCluster(grandTotal, "WeeklyAvg", clusterId, facilityId);
        weeklyAvg.forEach((item: any) => {
                colList.push(item);
            });

        //Total
        var totals = this.createDayColumnsByCluster(grandTotal, "Total", clusterId, facilityId);
        totals.forEach((item: any) => {
                colList.push(item);
            });

        return colList;
    }

    exapndClusterRows(facilityId: any, isExpanded: any){
        //@ts-ignore
        var elements: any = document.getElementsByClassName('Facility_'+ facilityId);
        if(elements != null && elements.length > 0){
            for(let i=0; i< elements.length; i++){
                if(isExpanded == 'true'){
                    document.getElementsByClassName('Facility_'+ facilityId)[i].classList.add('hideClusterRow')
                }
                else{
                    document.getElementsByClassName('Facility_'+ facilityId)[i].classList.remove('hideClusterRow');
                }
            }
        }

        if(isExpanded == 'true'){
            //@ts-ignore
            document.getElementsByClassName('facilityTitleIcon_'+ facilityId)[0].innerText = 'add';
        }
        else{
            //@ts-ignore
            document.getElementsByClassName('facilityTitleIcon_'+ facilityId)[0].innerText = 'remove';
        }

        //@ts-ignore
        document.getElementsByClassName('facilityTitle_'+ facilityId)[0].setAttribute('data-isExpanded', isExpanded == 'true'? 'false': 'true');
    }

    getPICssClass(index: string| null): string{
        var cssClass: string = "";
        if(index != null){
            const indexVal = Number(index);

            if(indexVal == 0){
                cssClass = "stablePI";
            }
            else if(indexVal > 5){
                cssClass = "underPI";
            }
            else if(indexVal <= 5){
                cssClass = "overPI";
            }
        }
        return cssClass;
    }

    getProdIndexCssClass(index: string| null): string{
        var cssClass: string = "";
        if(index != null){
            const indexVal = Number(index);

            if(indexVal >= 105 && indexVal < 106){
                cssClass = "stablePI";
            }
            else if(indexVal < 100 || indexVal >= 110){
                cssClass = "underPI";
            }
            else if((indexVal >= 100 && indexVal < 105) || (indexVal >= 106 && indexVal < 110)){
                cssClass = "overPI";
            }
        }
        return cssClass;
    }


    loadPremiumLaborBreakdownPopup(facilityId: any, clusterId: any, day: any, value: any){
        var premiumLaborBreakDown: PremiumLaborKeyValuePair[] = [];
        //@ts-ignore
        var facility: Facility = jslinq(this.metricsGridData.Facilities)
            .where((item: any) => {
                if (item.FacilityId == facilityId) {
                    return item;
                }
            }).firstOrDefault();

        if(facility != null){
            //@ts-ignore
            var cluster: Cluster = jslinq(facility.Clusters)
            .where((item: any) => {
                if (item.ClusterId == clusterId) {
                    return item;
                }
            }).firstOrDefault();

            if(cluster != null){
                //@ts-ignore
                var breakdownData: PremiumLaborKeyValuePair[] = cluster.PremiumLaborMetrics[day];

                if(breakdownData != null && breakdownData.length > 0){
                    breakdownData.forEach((x: KeyValuePair) => {
                        var keypair: PremiumLaborKeyValuePair = { Key: "", Value: 0, IsHeadingRow: false, Name: "", LastCancelled : null };
                        if(x.Key != null){
                            //Create Heading row
                            keypair.Key = x.Key;
                            keypair.Value = x.Value;
                            keypair.IsHeadingRow = true;
                            premiumLaborBreakDown.push(keypair);
                            
                            //Loop through Department to load details Premium Labor for selected day
                            var preLaborKey: string = x.Key;
                            if(cluster.Departments != null){
                                cluster.Departments.forEach((dept: Department) => {
                                    
                                    if(dept.PremiumLaborMetrics != null){
                                        //@ts-ignore
                                        var deptPremLaborByDay: KeyValuePair[] = dept.PremiumLaborMetrics[day].Breakdown;
                                        
                                        
                                        if(deptPremLaborByDay != null && deptPremLaborByDay.length > 0){
                                            //@ts-ignore
                                            var premLaborDeptByDayMetrics: KeyValuePair = jslinq(deptPremLaborByDay)
                                            .where((item: any) => {
                                                if(item.Key == preLaborKey){
                                                    return item;
                                                }
                                            }).firstOrDefault();

                                                
                                            if(premLaborDeptByDayMetrics != null){

                                                var keypairdept: PremiumLaborKeyValuePair = { Key: "", Value: 0, Name: "", LastCancelled: null, IsHeadingRow: false };
                                                keypairdept.Key = dept.DepartmentName + ' - ' + dept.DepartmentCode;
                                                keypairdept.Value = premLaborDeptByDayMetrics.Value;
                                                keypairdept.Name = premLaborDeptByDayMetrics.Name;
                                                keypairdept.LastCancelled = premLaborDeptByDayMetrics.LastCancelled;
                                                keypairdept.IsHeadingRow = false;
                                                premiumLaborBreakDown.push(keypairdept);
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });

                    var keypairTotal: PremiumLaborKeyValuePair = { Key: "", Value: 0, Name: "", LastCancelled: null, IsHeadingRow: false };
                    //load Net count Row
                    keypairTotal.Key = "Net Count";
                    keypairTotal.Value = value;
                    keypairTotal.IsHeadingRow = true;
                    premiumLaborBreakDown.push(keypairTotal);

                    this.premLaborBreakdownInput = premiumLaborBreakDown;
                    this.isPremiumLaborBreakdownVisible = true;
                }
            }
        }
    }

    loadDepartmentPopup(clusterId: any, facilityId: any){
        //@ts-ignore
        var facility: Facility = jslinq(this.metricsGridData.Facilities)
            .where((item: any) => {
                if (item.FacilityId == facilityId) {
                    return item;
                }
            }).firstOrDefault();

        if(facility != null){
            //@ts-ignore
            var cluster: Cluster = jslinq(facility.Clusters)
            .where((item: any) => {
                if (item.ClusterId == clusterId) {
                    return item;
                }
            }).firstOrDefault();

            if(cluster != null){
                //@ts-ignore
                var departments: Department[] = cluster.Departments;
                
                this.departmentList = departments;
                var summaryStartTime: any = moment(cluster.StartDateTime).format(this.shortTimeFormat);
                var summaryEndTime: any = moment(cluster.EndDateTime).format(this.shortTimeFormat);
                this.headerDetails = { 
                    FacilityName: facility.FacilityName,
                    ClusterName: cluster.ClusterName,
                    ShiftTime: summaryStartTime + ' - ' + summaryEndTime,
                    Skill: this.apiInput.SkillEntityIds,
                    StartDate: this.startDate,
                    WeekEndDay1: this.weekEndDay1,
                    WeekEndDay2: this.weekEndDay2,
                    IsShowPremiumLabor: this.apiInput.isShowPremiumLabor,
                    IsShowActualCount: this.isShowActualCount,
                    isPI14Day: this.sevenDayViewFilter == null || this.sevenDayViewFilter.isPI14Day == null  || this.sevenDayViewFilter.isPI14Day == undefined  ? false : this.sevenDayViewFilter.isPI14Day
                }
                this.isDepartmentModelVisible = true;
            }
        }
    }

    setStartEndTime() {
        var distinctSummary = this.getDistinct(this.dashboardConfig.Filters.Summaries);

        this.currentNextShifts = this.getCurrentNextShift(distinctSummary);

        return distinctSummary;

    }

    getCurrentNextShift(collection: KeyPairModel[]) {
        var currentDateTime: any =  null;
        var currentDT: any = null;

        currentDT = this.getCurrentDateTime();
        currentDateTime = new Date(currentDT);
        var currentNextShift = [];

        //@ts-ignore
        var results: any = jslinq(collection)
            .select(function (item) {
                var model:StartEndDateTimeModel = {
                    startDateTime: null,
                    endDateTime: null,
                    total: null
                };
                var datetimes = item.Value.split(' - ');
                var startDateTime = new Date(datetimes[0]);
                var endDateTime = new Date(datetimes[1]);

                model.startDateTime = datetimes[0];
                model.endDateTime = datetimes[1];
                model.total = endDateTime.getTime() - startDateTime.getTime();

                return model;
            }).toList();

        //@ts-ignore
        var current: any= jslinq(results)
            .where(function (item: any) {
                var startDateTime = new Date(item.startDateTime);
                var endDateTime = new Date(item.endDateTime);

                if ((currentDateTime.getTime() >= startDateTime.getTime()) && (currentDateTime.getTime() <= endDateTime.getTime())) {
                    return item;
                }
            }).toList();

        //@ts-ignore
        var minShift = jslinq(current)
            .min(function (el: any) {
                return el.total;
            });

        current = this.getCurrentModel(currentDateTime);

        if (current === null) {
            var startEndModel: StartEndDateTimeModel = {
                    startDateTime: null,
                    endDateTime: null,
                    total: null
                };
            startEndModel.startDateTime = this.getStartDate();
            startEndModel.endDateTime = this.getEndDate();
            current= startEndModel;
        }

        //@ts-ignore
        var next = jslinq(results)
            .where(function (item: any) {
                var thisShift = new Date(current.endDateTime);
                var startDateTime = new Date(item.startDateTime);
                var endDateTime = new Date(item.endDateTime);

                if ((startDateTime.getTime() >= thisShift.getTime()) && (endDateTime.getTime() >= thisShift.getTime())) {
                    return item;
                }
                return null;
            }).toList();

        //@ts-ignore
        minShift = jslinq(next)
            .min(function (el: any) {
                return el.total;
            });

        //@ts-ignore
        next = jslinq(next)
            .firstOrDefault(function (item: any) {
                return item.total === minShift;
            });

        //@ts-ignore
        var previous = jslinq(results)
            .where(function (item: any) {
                var thisShiftTime = new Date(current.startDateTime);
                var startDateTime = new Date(item.startDateTime);
                var endDateTime = new Date(item.endDateTime);

                if ((startDateTime.getTime() <= thisShiftTime.getTime()) && (endDateTime.getTime() <= thisShiftTime.getTime())) {
                    return item;
                }
                return null;
            }).toList();

        //@ts-ignore
        minShift = jslinq(previous)
            .min(function (el: any) {
                return el.total;
            });

        //@ts-ignore
        previous = jslinq(previous)
            .firstOrDefault(function (item: any) {
                return item.total === minShift;
            });


        currentNextShift.push(current);
        currentNextShift.push(next);
        currentNextShift.push(previous);
        return currentNextShift;
    }

    getDistinct(collection: KeyPairModel[]) {
        //@ts-ignore
        var results = jslinq(collection)
            .select(function (item: any) {
                return { Value: item.Value, Text: item.Text };
            })
            .distinct()
            .orderBy(function (item: any) {
                return item.Value;
            })
            .toList();

        return results;
    }
    
    getCurrentModel(currentDateTime: any){
        var startDatePeriod= moment(currentDateTime).format(this.shortDateFormat) + 'T07:00:00';   
        var endDatePeriod = moment(currentDateTime).format(this.shortDateFormat) + 'T19:00:00';
        
        if((currentDateTime.getTime() >= new Date(startDatePeriod).getTime()) && (currentDateTime.getTime() <= new Date(endDatePeriod).getTime()))
        {            
            endDatePeriod =  moment(startDatePeriod).add(1, 'days').format(this.shortDateFormat) + 'T07:00:00';
        }
        else
        {
            startDatePeriod = endDatePeriod ;
            endDatePeriod =  moment(endDatePeriod).add(1, 'days').format(this.shortDateFormat) + 'T19:00:00';
        }

        var modelCurrent:StartEndDateTimeModel = {
                    startDateTime: startDatePeriod,
                    endDateTime: endDatePeriod,
                    total: new Date(endDatePeriod).getTime() - new Date(startDatePeriod).getTime()
                };

        return modelCurrent;
    }

    getStartDate() {
        var date = moment(new Date()).format(this.shortDateFormat);
        var sdt = date + 'T07:00:00';

        return sdt;
    }

    getEndDate() {
        var date = moment(new Date()).format(this.shortDateFormat);
        var edt = date + 'T23:59:00';

        return edt;
    }

    close7DayGridFilterPanel(){
        this.isShowFilterDropdown = false;
    }

    showToasterNotification7DayView(data: any) {
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    setDefaultSkills() {
        var filterSkills: any[] = [];

        this.skills.forEach((item: any) => {
            if (this.defaultSkilllList.includes(item.EntityName)) {
                filterSkills.push(item.EntityId);
            }
        });
        return filterSkills;
    }

    onMetricsCountChanges(){
        var hideElementClass = '', showElementClass = '';

        if(this.isShowActualCount){
            showElementClass = 'metricsActualCount';
            hideElementClass = 'metricsVarianceCount';
        }
        else{
            showElementClass = 'metricsVarianceCount';
            hideElementClass = 'metricsActualCount';
        }

        //show element
        var showMetricsElements: any = [];
        showMetricsElements = document.getElementsByClassName(showElementClass);
        if(showMetricsElements != null && showMetricsElements.length > 0){
            for(var j=0; j<showMetricsElements.length; j++){
                showMetricsElements[j].classList.remove('d-none');
            }
        }

        //hide element
        var hideMetricsElements: any = [];
        hideMetricsElements = document.getElementsByClassName(hideElementClass);
        if(hideMetricsElements != null && hideMetricsElements.length > 0){
            for(var j=0; j<hideMetricsElements.length; j++){
                hideMetricsElements[j].classList.add('d-none');
            }
        }
    }
}
</script>
<style scoped>
.dropdown-menu{
  width: 640px;
  padding: 15px !important;
}

.filterShow{
  display: block !important;
}

.left_arrow{
    border: 1px solid #d6d7d9;
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 50%;
    margin-right: 20px;
    display: inline-block;
    background-color: #d6d7d9;
    cursor: pointer;
}

.right_arrow{
    border: 1px solid #d6d7d9;
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 50%;
    margin-left: 20px;
    display: inline-block;
    background-color: #d6d7d9;
    cursor: pointer;
}

.date_Nav{
    left: 26%;
    position: absolute;
}
.dateTxt{
    display: inline-block;
}

</style>
<style>
.sevenDaydashboardGrid{
    overflow: auto!important;
    max-height: calc(100vh - 290px);
}

.totalrow{
    background-color: #efefef !important;
    font-weight: bold !important;
    padding: 3px !important;
}
.totalrow td{
    font-size: 15px !important;

}

.facilitytitlerow{
    background-color: #164d87 !important;
    color: white !important;
    font-weight: bold;
    padding: 3px !important;
}

.facilitytitlerow td{
    font-size: 14px !important
}

.headingCol{
    padding-left: 15px !important;
    text-align: left !important;
    position: relative;
}

.headingTotalTxt{
    right: 8px;
    position: absolute;
}

.weekendBgClr{
    background-color: buttonface !important;
    color: #212529 !important;
}

.clusterExpandIcon{
    position: absolute;
    left: 0;
}

.underStaff{
    background-color: #E87070 !important;
    border: solid 2px #C94343 !important;
    font-weight: 600 !important;
}

.underStaff:hover{
    background-color: #C94343 !important;
}

.overStaff{
    background-color: #FFEE9A !important;
    border: solid 2px #F1B92A !important;
    font-weight: 600 !important;
}

.overStaff:hover{
    background-color: #F1B92A !important;
}

.underPI{
    background-color: #ee5253 !important;
    color: #fff;
    padding: 3px;
    font-weight: 600 !important;
    border-radius: 15%;
}

.stablePI{
    background-color: #84c54a !important;
    color: #212529;
    padding: 3px;
    font-weight: 600 !important;
    border-radius: 15%;
}

.overPI{
    background-color: #FFEE9A !important;
    color: #212529;
    padding: 3px;
    font-weight: 600 !important;
    border-radius: 15%;
}

.clusterPlusIcon{
    font-size: 1rem !important;    
}

.clusterTitleIcon{
    font-size: 0.875rem !important;
    padding-left: 6px;
}

.premiumLabortitle{
    background-color: #b6e1f1 !important;
    font-weight: bold;
    text-align: right !important;
    padding-right: 10px !important;
}

.hideClusterRow{
    display: none;
    transition: all 1s;
}

.sevenDaydashboardTable > tbody > tr:nth-of-type(odd) > * {
    background-color: inherit;
    color: inherit;
}

.sevenDaydashboardTable > tbody > tr:nth-of-type(even) > * {
    background-color: inherit;
    color: inherit;
}

.deptDateTitle{
    background-color: #efefef !important;
    color: #212529 !important;
    font-weight: bold;
    border-right: 1px solid white !important;
}

fieldSet {
    border: 0!important;
    display: inline-block!important;
    margin-left: 6px!important;
    top: 3px!important;
}

.metricCountToggle{
    float: right;
    padding-right: 10%;
}
</style>
