<template>

    <div id="careAssingnmentLayout" ref="careAssingnmentLayout" class="care-assingnment">
        <div style="text-align:left;">
            <h5 class="card-title care-assignment-header"> Care Assignment Sheet </h5>
            <div class="col-sm-12 div-filter">
                <div>
                    <label class="care-assignment-title">Facility:</label>
                    <span class="care-assignment-title-value">
                        {{
                            careAssignmentViewModel.facilityName
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Service Line:</label>
                    <span class="care-assignment-title-value">
                        {{
                            careAssignmentViewModel.serviceLineName
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Department:</label>
                    <span class="care-assignment-title-value">
                        {{
                            careAssignmentViewModel.departmentName
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Date:</label>
                    <span class="care-assignment-title-value">
                        {{
                            careAssignmentViewModel.startDate
                        }}
                    </span>
                </div>
                <div>
                    <label class="care-assignment-title">Summary Period:</label>
                    <span class="care-assignment-title-value">
                        {{
                            careAssignmentViewModel.startTime
                        }}
                    </span> - <span class="care-assignment-title-value">
                        {{
                            careAssignmentViewModel.endTime
                        }}
                    </span>
                </div>
                <div>
                    <div id="careAssignmentSkill" class="care-assignment-skill">
                        {{careAssignmentSkillDetails}}
                    </div>
                </div>
            </div>
        </div>

        <div class="padding">
        </div>
        <div id="grdCareAssignment" v-show="isShowCareAssignmentContent">
            <table border="1" class="tableClass">
                <thead>
                    <tr header class="tableRow" style="padding-top: 25px !important; padding-bottom: 10px !important;">
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <th class="tableRowHeading tableRowHeading_large"
                            style="width:15%;">
                            Name
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Skill
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:13%;">
                            Department
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:6%;">
                            Shift
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            Start Time
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            End Time
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:5%;">
                            Type
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:5%;">
                            Hours
                        </th>
                        <th v-if="isRNExperienceFeatureEnabled" class="tableRowHeading tableRowHeading_large" style="width:7%;">
                            RN Experience
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Room/Assignment 
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Additional Notes
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Competency
                        </th>
                        <th class="tableRowHeading tableRowHeading_large" style="width:10%;">
                            Credentials
                        </th>
                    </tr>
                </thead>
                <tbody align="left">
                    <template v-if="careAssignmentData != null && careAssignmentData.length > 0">
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <tr v-for="dataItem in careAssignmentData" :slot="column"
                            :key="dataItem" :uid="dataItem.uid" :staffId="dataItem.staffId"
                            :class="[dataItem.productiveId == 2 ? 'other-productive': '',
                            dataItem.productiveId==3 ? 'non-productive' : '' ,
                            dataItem.productiveId==4 ? 'call-assignment' : '' ,
                            dataItem.profileCharge && dataItem.charge ? 'chargeAssignment' : '' ,
                            (dataItem.cancelReasonId ||
                            (dataItem.cancelComment && dataItem.cancelComment.length>
                            0) || dataItem.isCancelled) ? 'cancelledAssignment': '',
                            dataItem.origDepartmentId !== dataItem.departmentId && !(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'floatedAssignment' : '','tableRow']">
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td class="tableRow_cell" slot="Name" :title="dataItem.name" style="width:15%;">
                                {{ dataItem.name }}
                                <span v-if="dataItem.charge">
                                    <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                </span>
                                <span v-if="dataItem.associationType === associationTypes.secondary">
                                    <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                </span>
                                <span v-if="dataItem.associationType === associationTypes.unassociated">
                                    <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                </span>
                                <span v-if="dataItem.isFloat">
                                    <img style="width: 24px;float: right" src="@/assets/float.png" alt="Float" />
                                </span>
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Skill" :title="dataItem.skillLevelDescription" style="width:6%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                        (dataItem.origDepartmentId !== dataItem.departmentId && payloadCareAssignment.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']">
                                {{ dataItem.skillLevelDescription }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Department" :title="dataItem.departmentName" style="width:13%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                            (dataItem.origDepartmentId !== dataItem.departmentId && payloadCareAssignment.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']">
                                <span v-if="dataItem.origDepartmentId !== dataItem.departmentId">
                                    {{ dataItem.departmentName }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentName}}  </span>
                                </span>
                                <span v-else> {{ dataItem.departmentName }}</span>
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Shift" style="width:6%;"
                                :class="[dataItem.overtimeShift ? 'gridOverTime': '',
                                (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                0) || dataItem.isCancelled) ? 'strikethrough': '',
                                (dataItem.origDepartmentId !== dataItem.departmentId && payloadCareAssignment.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']">
                                {{ dataItem.shiftCode }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Start Time" style="width:7%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                    (dataItem.origDepartmentId !== dataItem.departmentId && payloadCareAssignment.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']">
                                {{ setGridTimeFormat(dataItem.actualStartTime) }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="End Time" style="width:7%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                    (dataItem.origDepartmentId !== dataItem.departmentId && payloadCareAssignment.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']">
                                {{ setGridTimeFormat(dataItem.actualEndTime) }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Type" style="width:5%;"
                                :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '',
                                    (dataItem.origDepartmentId !== dataItem.departmentId && payloadCareAssignment.departmentId === dataItem.origDepartmentId) ? 'strikethrough' : '','tableRow_cell']">
                                {{ dataItem.employeeType }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Hours" style="width:5%;"
                                :class="[dataItem.isOvertime ? 'gridOverTime': '','tableRow_cell']">
                                {{ dataItem.hours }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="RN Experience" style="width:7%;" v-if="dataItem.rnExperienceFeatureEnabled"
                                :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '','tableRow_cell']">
                                {{ dataItem.rnExperience }}
                            </td>
                            <td slot="Room/Assignment" class="tableRowHeading tableRowHeading_large" style="width:10%;">                            
                            </td>
                            <td slot="Additional Notes" class="tableRowHeading tableRowHeading_large" style="width:10%;">                            
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Competency" style="width:10%;" class="tableRow_cell">
                                {{ dataItem.competencyCodes }}
                            </td>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <td slot="Credentials" style="width:10%;" class="tableRow_cell">
                                {{ dataItem.credentialCode }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div id="careAssignmentMessage" class="care-no-assingnments" v-if="isShowCareAssignmentMessage" style="padding-top: 25px !important; padding-bottom: 10px !important;">
            <p id="home-message-text"> {{careAssignmentMessage}}</p>
        </div>
        <div class="padding">
        </div>
        <div id="careAssignmentFooter" class="care-assignment-footer" v-if="careAssignmentFooterText">
            {{careAssignmentFooterText}}
        </div>
    </div>
</template>

<script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";

    //Import Componenets
    import {
        NeuTableRowCount,
        NeuTable,
        NeuTableRow,
        NeuLabel,
        NeuTableBody,
        NeuTableHeading,
        NeuCard
    } from "@neutron/vue";

    class Props {
        payloadCareAssignment!: any
    }

    @Options({
        components: {
            NeuTableRowCount,
            NeuTable,
            NeuTableRow,
            NeuLabel,
            NeuTableBody,
            NeuTableHeading,
            NeuCard
        }
    })
    export default class CareAssignment extends Vue.with(Props) {

        shortDateFormat: any = 'MM/DD/YYYY';
        shortTimeFormat: any = 'h:mm a';
        shortDateFormatPresentation: any = 'MM/DD/YYYY';
        shortDateTimeFormat: any = 'YYYY-MM-DD h:mm a';
        homeDepartmentId: any = 0;
        careAssignmentViewModel: any = {
            facilityName: null,
            serviceLineName: null,
            departmentName: false,
            startDate: null,
            startTime: null,
            endTime: null
        };
        isShowCareAssignmentContent: boolean = false;
        isShowCareAssignmentMessage: boolean = false;
        isRNExperienceFeatureEnabled: boolean = false;
        careAssignmentMessage: string = "";
        careAssignmentData!: any;
        careAssignmentSkillDetails: string = '';
        careAssignmentFooterText: string = '';
        associationTypes: any = {
            primary: 1,
            secondary: 2,
            unassociated: 3
        };

        mounted() {
            this.printCareAssignmentSheet();
        }

        printCareAssignmentSheet() {
            if (this.payloadCareAssignment != null) {
                this.careAssignmentViewModel.facilityName = this.payloadCareAssignment.headerInfo.fullFacilityName;
                this.careAssignmentViewModel.serviceLineName = this.payloadCareAssignment.headerInfo.serviceLineName;
                this.careAssignmentViewModel.departmentName = this.payloadCareAssignment.headerInfo.fullDepartmentName;
                this.careAssignmentViewModel.startDate = this.payloadCareAssignment.headerInfo.startDate;
                this.careAssignmentViewModel.startTime = this.payloadCareAssignment.headerInfo.startTime;
                this.careAssignmentViewModel.endTime = this.payloadCareAssignment.headerInfo.endTime;

                if (this.payloadCareAssignment.dataSource != null && this.payloadCareAssignment.dataSource.length > 0) {
                    this.isShowCareAssignmentContent = true;
                    this.isShowCareAssignmentMessage = false;

                    this.careAssignmentData = this.payloadCareAssignment.dataSource;
                    this.isRNExperienceFeatureEnabled = this.payloadCareAssignment.dataSource[0].rnExperienceFeatureEnabled;

                    var info = this.payloadCareAssignment.assignment;
                    var skillData: any[] = [];
                    var totalScheduled = 0;
                    this.payloadCareAssignment.dataSource.forEach((assignment: any) => {
                        var countType = assignment.countTypeId;
                        var productive = assignment.productiveId;
                        var isCancelled = (assignment.cancelReasonId || (assignment.cancelComment && assignment.cancelComment.length > 0) || assignment.isCancelled);
                        var departmentId = info[0].departmentId;

                        if ((departmentId === assignment.departmentId) &&
                            ((countType === 1 || countType === 2) && productive === 1 && !isCancelled) || !assignment.shiftCode && productive === 1 && !isCancelled) {
                            totalScheduled++;
                            var skill = { "SkillLevelId": assignment.skillLevelId, "SkillLevelDescription": assignment.skillLevelDescription, "Count": 1 };
                            var skillFound = skillData.filter(
                                function (item) {
                                    return item.SkillLevelId === assignment.skillLevelId;
                                })[0];

                            if (skillFound) {
                                skillFound.Count++;
                            }
                            else {
                                skillData.push(skill);
                            }
                        }

                    });

                    var skillText = 'Total Scheduled : ' + totalScheduled;
                    if (totalScheduled > 0) {
                        skillData.forEach((skill: any) => {
                            skillText = skillText + " | " + skill.SkillLevelDescription + " : " + skill.Count;
                        });
                    }

                    this.careAssignmentSkillDetails = skillText;

                    var today = moment();
                    var printedAt = "Printed : " + today.format('YYYY-MM-DD HH:mm:ss A');
                    this.careAssignmentFooterText = printedAt;
                }
                else {
                    this.isShowCareAssignmentMessage = true;
                    this.isShowCareAssignmentContent = false;
                    this.careAssignmentMessage = "No resources found";
                }

                var that = this;
                setTimeout(() => {
                    that.printHTML();
                }, 1000);
            }
        }

        printHTML() {
            var divCareAssignmentSheetPrint: any = document.createElement('div');
            divCareAssignmentSheetPrint.setAttribute('id', 'divCareAssignmentSheetPrint');
            //@ts-ignore
            divCareAssignmentSheetPrint.innerHTML = document.getElementById('careAssingnmentLayout').innerHTML;
            var mainPage: any = document.getElementById('app');
            mainPage.classList.add('d-none');
            document.body.appendChild(divCareAssignmentSheetPrint);
            window.print();
            document.body.removeChild(divCareAssignmentSheetPrint);
            mainPage.classList.remove('d-none');
            this.$emit('closePrintTemplate');
        }

        setGridTimeFormat(time: any) {
            if (time != null && time != '') {
                var formattedTime: any = moment(time).format("hh:mm A");
                return formattedTime;
            }
            else {
                return '';
            }
        }
        setGridDateFormat(date: any) {
            if (date != null && date != '') {
                var formattedDate: any = moment(date).format("MM/DD/YYYY");
                return formattedDate;
            }
            else {
                return '';
            }
        }
    }
</script>
<style scoped>
    .d-none {
        display: none;
    }

    .tableClass {
        width: 100%;
        overflow: hidden;
    }

    .tableRow {
        --selected-color: var(--neu-color-primary-20, #aecde5);
        width: 100%;
        border-bottom: 1px solid var(--neu-color-gray-40, #939393);
    }

    .tableRow_cell {
        font-size: small !important;
        text-align: center;
    }

    .tableRowHeading_large {
        height: 51px;
        max-height: 51px;
    }

    .tableRowHeading {
        justify-content: space-between;
        background: transparent;
        font-size: small !important;
        text-align: center;
    }

    @media print {
        .cancelledAssignment {
            color: #B33E3E !important;
            background-color: #F6DBDB !important;
        }

        .rn-experience {
            font-weight: bold;
            background-color: #FFC845;
            color: black;
        }
    }
</style>