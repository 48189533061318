<template>
    <div v-if="plusMetricsReportInput.plusMetricsPayPeriod != null && plusMetricsReportInput.plusMetricsPayPeriod.length > 0">
        <div class="row"> 
            <div class="col-md-3"> 
                <h5>PLUS(PPD)</h5>
                <table class="table table-bordered plusmetric-summary plus-metric-print">
                    <tbody> 
                        <tr>
                            <td colspan="2" scope="row" class="heading">PLUS PPD Summary</td>
                        </tr> 
                        <template v-for="(totalMetricRecord, index) in plusMetricsReportInput.plusMetricsPayPeriod" :key="totalMetricRecord">
                            <template v-if="index == (plusMetricsReportInput.plusMetricsPayPeriod.length - 1)">
                                <tr>
                                    <td>Target</td> 
                                    <td class="font-weight-bold">{{getFixedNumber(totalMetricRecord.targetHours)}}</td>
                                </tr>
                                <tr>
                                    <td>Actual</td> 
                                    <td class="font-weight-bold">{{getFixedNumber(totalMetricRecord.actualHours)}}</td>
                                </tr>
                                <tr>
                                    <td>Difference</td> 
                                    <td class="font-weight-bold">
                                        <template v-if="totalMetricRecord.varianceHours < 0">
                                            <font-awesome-icon :icon="['fas', 'arrow-circle-down']" size="1x" />
                                        </template>
                                        <template v-else>
                                            <font-awesome-icon :icon="['fas', 'arrow-circle-up']" size="1x" />
                                        </template>
                                        {{getFixedNumber(totalMetricRecord.varianceHours)}}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody> 
                </table>
            </div> 
            <div class="col-md-9"></div> 
        </div>
        <div class="row"> 
            <div class="col">{{ facilityName + ' > ' + departmentName }}</div> 
        </div>
        <div class="row"> 
            <div class="col-md-6"> 
                <div class="plusmetrics-payperiod-grid-container"> 
                    <h6>Pay Period</h6> 
                    <div class="plusmetrics-payperiod-grid"> 
                        <table class="table table-bordered plusmetric-summary plus-metric-print">
                            <colgroup>
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:15%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th class="heading">Date</th>
                                    <th class="heading">Target</th>
                                    <th class="heading">Actual</th>
                                    <th class="heading">Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(metricRecord, index) in plusMetricsReportInput.plusMetricsPayPeriod" :key="metricRecord">
                                    <td>
                                        <template v-if="index == (plusMetricsReportInput.plusMetricsPayPeriod.length - 1)">Total</template>
                                        <template v-else>
                                            {{getPayPeriodDate(metricRecord.date)}}
                                        </template>
                                    </td>
                                    <td class="target-hours-col">{{metricRecord.targetHours}}</td>
                                    <td class="actual-hours-col">{{metricRecord.actualHours}}</td>
                                    <td :class="['difference-hours-col', getNegativeNumberClass(metricRecord.varianceHours)]">
                                    {{formatNegativeNumbers(metricRecord.varianceHours)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 
            </div>
            <template v-if="plusMetricsReportInput.plusMetricsMonth != null && plusMetricsReportInput.plusMetricsMonth.length > 0">
                <div class="col-md-6"> 
                    <div class="plusmetric-monthly-grid-container"> 
                        <h6>Entire Month</h6> 
                        <div class="plusmetrics-payperiod-grid"> 
                            <table class="table table-bordered plusmetric-summary plus-metric-print">
                                <colgroup>
                                    <col style="width:15%">
                                    <col style="width:15%">
                                    <col style="width:15%">
                                    <col style="width:15%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th class="heading">Date</th>
                                        <th class="heading">Target</th>
                                        <th class="heading">Actual</th>
                                        <th class="heading">Difference</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(metricRecord, index) in plusMetricsReportInput.plusMetricsMonth" :key="metricRecord">
                                        <td>
                                            <template v-if="index == (plusMetricsReportInput.plusMetricsMonth.length - 1)">Total</template>
                                            <template v-else>
                                                {{getPayPeriodDate(metricRecord.date)}}
                                            </template>
                                        </td>
                                        <td class="target-hours-col">{{metricRecord.targetHours}}</td>
                                        <td class="actual-hours-col">{{metricRecord.actualHours}}</td>
                                        <td :class="['difference-hours-col', getNegativeNumberClass(metricRecord.varianceHours)]">
                                        {{formatNegativeNumbers(metricRecord.varianceHours)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </div>
            </template>
        </div>
    </div>
    
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets


class Props {
    plusMetricsReportInput!: any
    facilityName!: any
    departmentName!: any 
}
@Options({
    components: {

    }
})
export default class PlusMetricsReport extends Vue.with(Props) {  
    //Data properties

    //Lifecycle hooks
    mounted(){
        
    }
    //Methods
    getFixedNumber(num: any){
        return Number(num.toFixed(2)).toLocaleString();
    }

    getPayPeriodDate(dt: any){
        return moment(dt).format('M/D');
    }

    getNegativeNumberClass(value: any) {
        return typeof value === 'number' && value < 0 ? "red" : "";
    }
    
    formatNegativeNumbers(value: any) {
        return typeof value === 'number' && value < 0 ? "(" + Math.abs(value) + ")" : value;
    }
}
</script>
<style scoped>
.table-bordered {
    border: 1px solid #CED1D7;
}

.overtime {
    background-color: Red;
    padding: 5px;
}

.cancelledAssignment {
    color: #B33E3E !important;
    background-color: #F6DBDB !important;
}

.strikethrough {
    text-decoration: line-through !important;
}

.floatedAssignment {
    background-color: #D9EFFA !important;
}

.chargeAssignment {
    font-weight: bold !important;
}

.non-productive {
    color: #B33E3E !important;
    background-color: #DFE6E9 !important;
}

.other-productive {
    color: #B33E3E !important;
    background-color: #EFEFEF !important;
}

.call-assignment {
    color: #B33E3E !important;
    background-color: #D9EAD3 !important;
}

.neutable {
  border-collapse: collapse;
  color: #1f2532;
  table-layout: fixed;
  width: 100%;
  font-size: 0.875em;
}

.neutable th {
  text-align: left;
  font-family: "HCA-Mark-Medium", "Arial", sans-serif;
  color: #58595B;
  border-bottom: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
.neutable td {
  border-bottom: 0.063rem solid #bcbcbc;
  border-top: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}

#dailyRosterSearchModal table.plusmetric-summary td.heading {
        color: white;
        background-color: #004c8b;
    }

#dailyRosterSearchModal table.plusmetric-summary th.heading {
        color: white;
        background-color: #004c8b;
    }

    @media print {
    table.plus-metric-print td {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    table.plus-metric-print th {
        font-size: xx-small !important;
        padding: 0px !important;
    }
}
</style>