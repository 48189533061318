<template>
  <neu-header>
    <neu-toolbar v-if="oidcUser" nav fluid color="primary-100">
      <neu-drawer-button
        slot="start"
        text="Menu"
        class="d-md-none"
        drawer="first"
      >
      </neu-drawer-button>
 
      <neu-row noGutters slot="secondary">
 
          <neu-tablist class="d-none d-md-block" >
            <neu-tab
              v-for="page in pages"
              :key="page.path"
              :class="selectedPage === page.path ? 'neu-tab--navy-active' : ''"
              navy
              @click="routeToPage(page.path)"
            >
              {{ page.name }}
            </neu-tab>
          </neu-tablist>
      </neu-row>
      <neu-row slot="end">
        <div id="tList">
        <div v-if="isTrainingVideoListVisible" @mouseleave="isTrainingVideoListVisible=false;" class="modalTutorialList z-max"  id="trainingVideoList" style="border:solid; border-color:#3673AA;"> 
          <!-- <div style="color:red; text-align: left;">x&nbsp;&nbsp;</div> -->
          <div style="background-color: white;" >
          <neu-list  color="white" lines="none" style="background-color: white; " >
              <neu-item v-for="item in getTrainingVideoList" :key="item.name" class="training-video-list">
              <neu-label  @click="openVideo(item.path)">{{ item.name }}</neu-label>
              </neu-item>             
          </neu-list>
          </div></div>  
        
        <a v-if="profileData.TrainingEnabled"
              href="#"
              class="modaltoolbarIcon neu-tooltip"
              style="cursor: pointer; color:white; top:10px;" @click="displayTrainingList($event);">
              
              <span aria-hidden="true">
                <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Tutorials
              </span>
              <span class="neu-tooltip--text tooltipTxt">Click to view Help Videos</span>
          </a> 

         &nbsp;
        </div>
           
          &nbsp;
        <VueMultiselect
        style="width: 9.375rem; margin-right: 0.5rem; order: 1;" class="tenantslist" name="tenantsSelection" id="tenantsSelection"
          v-model="selectedTenant" 
          :options="tenants"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          @update:modelValue="setTenantDataToSession($event)"
          placeholder="Select Tenant"
          track-by="text"
          label="text"
          v-if="tenants.length > 1"
        >
        </VueMultiselect>
        <neu-user-controls
          style="order: 2"
          class="d-none d-md-flex"
          :user="
            oidcUser.firstName.toUpperCase() +
            ' ' +
            oidcUser.lastName.toUpperCase()
          "
        >
        
          <neu-link nav :href="appLogoutUrl">
            <neu-avatar class="icon-padding" color="plain-0">
              <neu-icon>logout</neu-icon>
            </neu-avatar>
          </neu-link>
        </neu-user-controls>

     </neu-row>
    </neu-toolbar>    
  </neu-header>

  <neu-drawer
    v-if="oidcUser"
    side="start"
    drawer-id="first"
    content-id="drawerContent"
  >
    <neu-content id="drawerContent" nav>
      <neu-list lines="full" nav>
        <neu-drawer-toggle auto-hide drawer="first"> </neu-drawer-toggle>
        <neu-item
          v-for="page in pages"
          :key="page.path"
          nav
          :active="selectedPage === page.path"
          @click="routeToPage(page.path)"
        >
          {{ page.name }}
        </neu-item>       

        <neu-item nav>
          <neu-icon>logout</neu-icon>
          <neu-link nav :href="appLogoutUrl" class="neu-tab-h">
            Logout
          </neu-link>
        </neu-item>
      </neu-list>
    </neu-content>
  </neu-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import ScheduleModule from '@/store/modules/schedule'
import {
  NeuContent,
  NeuList,
  NeuDrawer,
  NeuItem,
  NeuDrawerToggle,
  NeuHeader,
  NeuToolbar,
  NeuTab,
  NeuTablist,
  NeuLink,
  NeuIcon,
  NeuAvatar,
  NeuDrawerButton,
  NeuUserControls,
  NeuRow,
} from '@neutron/vue'
import { getModule } from 'vuex-module-decorators'
import SecurityModule from '@/store/modules/security'
import { sortAscByKey } from '@/utils/utils'
import VueMultiselect from 'vue-multiselect';
let securityModule: SecurityModule
export default defineComponent({
    name: 'NavComponent',
  components: {
    NeuContent,
    NeuList,
    NeuDrawer,
    NeuItem,
    NeuDrawerToggle,
    NeuHeader,
    NeuToolbar,
    NeuTab,
    NeuTablist,
    NeuLink,
    NeuIcon,
    NeuAvatar,
    NeuDrawerButton,
    NeuUserControls,
    NeuRow,
    VueMultiselect,
  },
  data(vm) {
    return {
      selectedPage: '',
      istrue: true,
      navigationList: [
        {
          path: 'schedule',
          name: 'Schedule Maintenance'
        },       
        {
          path: 'dashboard',
          name: 'Staffing Dashboard'
        },
        {
          path: 'staff',
          name: '7 Day Staffing View'
        }
      ],     
      pages: [      
        {
          path: 'dashboard',
          name: 'Staffing Dashboard'
        },
        {
          path: 'staff',
          name: '7 Day Staffing View'
        }
      ],
      pagesDefault: [      
        {
          path: 'dashboard',
          name: 'Staffing Dashboard'
        },
        {
          path: 'staff',
          name: '7 Day Staffing View'
        }
      ]  ,   
      
     
      appLogoutUrl: process.env.VUE_APP_LOGOUT,
      selectedTenant: '' as any,
      isTrainingVideoListVisible: false,
      selectedTraining: '' as any,   
     

    }
  },
  computed: {
    ...mapGetters(['oidcUser']),
    ...mapState('profile', ['profileData', 'userAccounts']),    
    ...mapState('dashboard', ['trainingVideos']),
    
    tenants() {
      const values = sortAscByKey(this.userAccounts, 'tenantName');
      return (values || []).map(v => ({
        ...v,
        text: v.tenantName,
        value: v.tenantId
      })) as any
    },
   
    defaultTeant() {
      var tenantId = '';
      let path = sessionStorage.getItem('vuex_oidc_active_route');
      const sessionValue = sessionStorage.getItem('tenant');
      if (path && path !== '/' && path?.split('/').length > 2) {
        tenantId = path.split('/')[2];
      }
      if((tenantId == undefined || tenantId == null || tenantId == '') && sessionValue){
        tenantId =sessionValue
      }
      if((tenantId == undefined || tenantId == null || tenantId == '') && this.userAccounts[0]?.tenantId){
        tenantId = this.userAccounts[0]?.tenantId;
      }
      return tenantId;
    },    
    getTrainingVideoList(){
      console.log(document.getElementById('tList')?.innerHTML);
      return this.trainingVideos.filter(function(v:any) { return v.Location == 'DashboardFullList'});
      },
    
  },
  created()
  {
    this.setDefaultTenant();
    if (this.profileData.OptInForScheduleHelper ) {
      this.pages = this.navigationList;
    }else{
      this.pages = this.pagesDefault;
    }
   
  },
  watch: {
    '$route.fullPath': {
      handler(val) {
        if (val === '/schedule' && !this.profileData?.OptInForScheduleHelper) {
          this.$router.push('/dashboard')
        } else {
          this.setSelectedPage()
        }
      },
      deep: true,
      immediate: true,
    },
    tenants(val, oldVal) {
      if (!oldVal?.length && val?.length) {
        this.setDefaultTenant();
        
      }
    }
  },
  methods: {

    setDefaultTenant() {
      let tenantId = sessionStorage["tenant"] ;
      let path = sessionStorage.getItem('vuex_oidc_active_route');
      if (path && path !== '/' && path?.split('/')?.length > 2 && tenantId == undefined || tenantId == null || tenantId == '') {
        tenantId = path?.split('/')[2];
      }     
      if(tenantId == undefined || tenantId == null || tenantId == ''){
        if (!this.tenants?.length) {
          return;
        }
      }
      securityModule = getModule(SecurityModule, this.$store)
      const filtersDataStr = sessionStorage.getItem('filters-data');
      const filtersData = {
        selectedTenantId: tenantId
      };
      if(tenantId == undefined || tenantId == null || tenantId == ''){
        if (filtersDataStr?.length) {
          const data = JSON.parse(filtersDataStr);
          filtersData.selectedTenantId = data.selectedTenantId;
          tenantId = data.selectedTenantId;
        }
      }
    
      const matchedTenant = this.tenants?.find((t: any) => t?.tenantId === (tenantId || this.defaultTeant))
      this.selectedTenant = matchedTenant || '';
      sessionStorage["tenant"] = this.selectedTenant?.tenantId;
      if (this.selectedTenant?.tenantId != '') {
        this.setProfileData(this.selectedTenant?.tenantId, 0)
        securityModule.setSelectedTenantId(this.selectedTenant?.tenantId)
      }
    },
    setSelectedPage() {
      let newSelectedPage = ''      
      if(this.profileData?.OptInForScheduleHelper)
      {
        newSelectedPage = 'schedule'
      } else {
        newSelectedPage = 'dashboard'
      }
      let path = window.location.pathname
      if (path !== '/' && path.split('/').length > 1) {
        let routePage = path.split('/')[1].split(' ')[0]
        this.pages.forEach((page) => {
          if (page.path.toLowerCase().replace(' ', '') === routePage.toLowerCase())
            newSelectedPage = page.path
        })
      } else {
        this.$router.push(`/${newSelectedPage}`)
      }
      this.selectedPage = newSelectedPage
    },
    async setProfileData(tenant: any, facility: any){
        var index: number = this.userAccounts?.findIndex((x: any) => x?.tenantId.toLowerCase() === tenant.toLowerCase());
        if(index >= 0){
            var input: any = {
                profileIndex: index,
                facilityId: facility
            }

            this.$store
            .dispatch("profile/setProfileByTenant", input)
            .then(() => {
                // this.getInitialDashboardConfig();
            })
            .catch((err: any) => {
                if (err) {
                    console.error(err); // Handle errors any way you want
                }
            });

          }
    },
    setTenantDataToSession(event:any){
      if (this.selectedTenant?.tenantId != null && this.selectedTenant?.tenantId != ""){
        sessionStorage["tenant"] = this.selectedTenant?.tenantId;
        this.emitter.emit('filter-data-reset') // reset grid
        sessionStorage.setItem('filters-data', JSON.stringify({
          selectedTenantId: this.selectedTenant?.tenantId,
          selectedFacilityId: '',
          selectedDepartmentId: '',
          selectedScheduleId: '',
          selectedFilters: []
        }));
        this.emitter.emit('tenant-id-change',  this.selectedTenant?.tenantId)
        this.setProfileData(this.selectedTenant?.tenantId, 0)
        securityModule.setSelectedTenantId(this.selectedTenant?.tenantId)
        securityModule.setSelectedFacilityId('')
        securityModule.setSelectedDepartmentId('')
        securityModule.setSelectedScheduleId('')
        securityModule.setFacilitiesList({})
        securityModule.setAllDepartmentsByFacilityKvp({})
        securityModule.setAllSchedulesByDepartmentId([])
        if (this.$route.path.startsWith('/schedule')) {
          this.$router.push({
            name: this.$route.params.name as any,
          })
        } else {
          this.$router.push({
            name: this.$route.params.name as any,
            params: { tenantId: this.selectedTenant?.tenantId, facilityId: 0 }
          });
        }
        if (this.profileData.OptInForScheduleHelper ) {
          this.pages = this.navigationList;
        }else{
          this.pages = this.pagesDefault;
        }
          }
    },
    routeToPage(path: string) {
      const scheduleModule = getModule(ScheduleModule, this.$store)
      if (!scheduleModule?.dirty) {
        this.$router.push('/' + path)
      }
    },
    openVideo(path: any){
      window.open(path, '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How' ); 
      this.isTrainingVideoListVisible = false;
      

    },
    displayTrainingList(e: any){
        this.isTrainingVideoListVisible = !this.isTrainingVideoListVisible;
    },
  },
})
</script>
<style lang="scss" scoped>
:deep(.neu-user-controls__text) {
padding-right: 0.625rem;
}
.neu-avatar {
  margin-right: 0.625rem;
}

.modalTutorialList {
  top:60px; position:absolute ; background-color: white;
}
.c4c-modal {
        width: auto;
        height: fit-content;
        overflow: hidden;
        font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
        background: white;
        box-shadow: 0px 16px 30px 0px;
    }

    .dropdown-menu{
  width: 660px;
  padding: 15px !important;
}

.filterShow{
  display: block !important;
}
.training-video-list {
  &:hover {
    background-color: rgb(54, 115, 170);
    color: #FFF;
  }
}
</style>
