import {
  ApprovalOrRejectRequest,
  ApprovalSearchResults,
  ApprovalTableParams,
} from '@/models/scheduleManagement/approval.model'
import {
  TalentProfileSearchRequests,
  TalentProflesRequestPayload,
} from '@/models/scheduleManagement/talentProfile.model'
import {
  TradeProfileRequestPayload,
  TradeSearchProfileResults,
} from '@/models/scheduleManagement/tradeProfile.model'
import {
  approveOrRejectRequest,
  getAllAprovalsRequests,
  getAllNeedProfileRequests,
  getAllTalentedProfileRequests,
  getAllTradeProfileRequests,
  needProfileRequest,
  talentProfileRequest,
  tradeProfileRequest,
} from '@/services/approval'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ name: 'approvalModule', stateFactory: true })
export default class ApprovalModule extends VuexModule {
  // State
  approvalList: ApprovalSearchResults = {
    results: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    rowCount: 1,
  }
  talentedProfilesList: TalentProfileSearchRequests = {
    results: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    rowCount: 1,
  }
  tradeProfilesList: TradeSearchProfileResults = {
    results: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    rowCount: 1,
  }
  needProfilesList: ApprovalSearchResults = {
    results: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    rowCount: 1,
  }

  // getters
  get needProfiles() {
    return this.needProfilesList
  }
  get tradeProfiles() {
    return this.tradeProfilesList
  }
  get talentedProfiles() {
    return this.talentedProfilesList
  }
  get approvals() {
    return this.approvalList
  }

  // Mutations
  @Mutation
  setApprovalsRequest(data: ApprovalSearchResults) {
    this.approvalList = data
  }

  @Mutation
  setTalentedProfileRequest(data: TalentProfileSearchRequests) {
    this.talentedProfilesList = data
  }
  @Mutation
  setTradeProfileRequest(data: TradeSearchProfileResults) {
    this.tradeProfilesList = data
  }
  @Mutation
  setNeedsProfileRequest(data: ApprovalSearchResults) {
    this.needProfilesList = data
  }

  // Approval profile requests

  @Action({ rawError: true })
  public async allApprovalsRequests(
    params: ApprovalTableParams
  ): Promise<void> {
    try {
      const response = await getAllAprovalsRequests(params)
      this.context.commit('setApprovalsRequest', response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  @Action({ rawError: true })
  public async getApproveOrRejectRequest(
    payload: ApprovalOrRejectRequest
  ): Promise<void> {
    await approveOrRejectRequest(payload, payload.id)
  }

  // Talented profile requests
  @Action({ rawError: true })
  public async allTalentedProfileRequests(
    params: ApprovalTableParams
  ): Promise<void> {
    try {
      const response = await getAllTalentedProfileRequests(params)
      this.context.commit('setTalentedProfileRequest', response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  @Action({ rawError: true })
  public async getTalentedProfileModificationRequest(
    payload: TalentProflesRequestPayload
  ): Promise<void> {
    await talentProfileRequest(payload, payload.id)
  }

  // Trade actions

  @Action({ rawError: true })
  public async allTradeProfileRequests(
    params: ApprovalTableParams
  ): Promise<void> {
    try {
      const response = await getAllTradeProfileRequests(params)
      this.context.commit('setTradeProfileRequest', response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  @Action({ rawError: true })
  public async getTradeProfileModificationRequest(
    payload: TradeProfileRequestPayload
  ): Promise<void> {
    await tradeProfileRequest(payload, payload.id)
  }

  // Approval profile requests

  @Action({ rawError: true })
  public async allNeedsProfileRequests(
    params: ApprovalTableParams
  ): Promise<void> {
    try {
      const response = await getAllNeedProfileRequests(params)
      this.context.commit('setNeedsProfileRequest', response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  @Action({ rawError: true })
  public async getNeedProfileRequest(
    payload: ApprovalOrRejectRequest
  ): Promise<void> {
    await needProfileRequest(payload, payload.id)
  }
}
