import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-572b9920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalBackdrop flex absolute z-max" }
const _hoisted_2 = {
  class: "save-modal absolute",
  id: "savePopUpModal",
  "aria-labelledby": "savePopUpModalLabel",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modalContent" }
const _hoisted_4 = { class: "material-icons neu-snackbar__feedback-icon" }
const _hoisted_5 = { class: "neu-snackbar__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_neu_icon = _resolveComponent("neu-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(['neu-snackbar', _ctx.className])
        }, [
          _createElementVNode("i", _hoisted_4, [
            _createVNode(_component_neu_icon, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.iconName), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.msgValue), 1)
        ], 2)
      ])
    ])
  ]))
}