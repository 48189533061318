import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";

const namespaced = true

interface State {
    filterPreferences: any,
    surgePreferences: any,
    surgeBarVisibility: any
}

//state
const state: State = {
    filterPreferences: null,
    surgePreferences: null,
    surgeBarVisibility: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getUserPreferences({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/userstaffpreferences/${inputs.sourceId}/${inputs.dashboardId}/${inputs.fsUserId}/${inputs.fsStaffId}/preferences/filters`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setUserPreferences', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    saveUserPreferences({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/userstaffpreferences/${inputs.sourceId}/${inputs.dashboardId}/${inputs.fsUserId}/${inputs.fsStaffId}/preferences/filters`;

        return http
            .post(apiUrl, inputs.filterPreferences)
            .then((res) => {
                if (res != null) {
                    commit('setUserPreferences', res.data.data.results);
                }
                })
            .catch((err) => {
                throw err;
            });
    },
    updateFilterPreferences({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/userstaffpreferences/${inputs.sourceId}/${inputs.dashboardId}/${inputs.fsUserId}/${inputs.fsStaffId}/preferences/filters/${inputs.filterId}`;

        return http
            .put(apiUrl, inputs.FilterData)
            .then((res) => {
                if(res != null){
                    commit('setUserPreferences', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteFilterPreferences({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/userstaffpreferences/${inputs.tenantId}/${inputs.sourceId}/${inputs.dashboardId}/${inputs.fsUserId}/${inputs.fsStaffId}/preferences/filters/${inputs.filterId}`;

        return http
            .delete(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setUserPreferences', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSurgePreferences({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/userstaffpreferences/${inputs.tenantId}/${inputs.sourceId}/${inputs.dashboardId}/${inputs.fsUserId}/${inputs.fsStaffId}/preferences/surge`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && (res.data != null && res.data != "") && res.data.data != null){
                    commit('setSurgePreferences', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    updateSurgePreference({ commit, rootState }, inputs: any){
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/userstaffpreferences/${inputs.tenantId}/${inputs.sourceId}/${inputs.dashboardId}/${inputs.fsUserId}/${inputs.fsStaffId}/preferences/surge`;

        return http
            .post(apiUrl, inputs.SurgePreference)
            .then((res) => {
                commit('setSurgeBarVisibility', inputs.SurgePreference.SurgeDisplay);
            })
            .catch((err) => {
                throw err;
            });
    }
}

// mutations
const mutations: MutationTree<State> = {
    setUserPreferences(state, result: any){
        state.filterPreferences = result;
    },
    setSurgePreferences(state, result: any){
        state.surgePreferences = result;
    },
    setSurgeBarVisibility(state, result: any){
        state.surgeBarVisibility = result;
    }
}

export const userstaffpreferences: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

