<template>
    <div class="tab-pane fade show active m-2 tab-content" id="StatusTabContent" role="tabpanel" aria-labelledby="home-tab">
        <div id="statusFormContainer" class="txtLeft">
            <div class="row mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">facility</label>
                    <select class="neu-select" name="facility" id="facilityDropdown"
                            data-role="dropdownlist"
                            v-model="selectedFacilities" 
                            @change="onFacilityChange"> 
                        <option v-for="fac in facilities" :value="fac.Value" :key="fac.Value">
                            {{fac.Text}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">department</label>
                     <VueMultiselect v-model="selectedDepartments"
                                    :options="departments"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="onDepartmentChange"
                                    placeholder="All Departments..."
                                    label="Text"
                                    track-by="Text"
                                    id="selDepartments">
                    </VueMultiselect>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">from date</label>
                    <Datepicker v-model="selectedStartDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                    :enableTimePicker="false" autoApply ></Datepicker>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">to date</label>
                    <Datepicker v-model="selectedEndDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                :enableTimePicker="false" autoApply ></Datepicker>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">shift</label>
                    <select class="neu-select" name="departmentShifts" id="departmentShifts"
                            data-role="dropdownlist"
                            v-model="selectedShift" :disabled="isDepartmentShiftDisable">
                        <option v-for="shift in shifts" :value="shift.deptShiftId" :key="shift.deptShiftId">
                            {{shift.deptShiftDescription}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-2">
                    <label class="text-uppercase default-font-size">skill</label>
                    <select class="neu-select" name="fsskills" id="fsskills"
                            data-role="dropdownlist"
                            v-model="selectedSkill" :disabled="isSkillDisable">
                        <option v-for="skl in skills" :value="skl.SkillLevelId" :key="skl.SkillLevelId">
                            {{skl.Description}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">specialization</label>
                    <select class="neu-select" name="skillSpecialization" id="skillSpecialization"
                            data-role="dropdownlist"
                            v-model="selectedSkillSpecialization" :disabled="isskillSpecializationDisable">
                        <option v-for="fac in skillSpecializations" :value="fac.wfFoldergroupID" :key="fac.wfFoldergroupID">
                            {{fac.wfFolderGroupName}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <div v-if="IsSkillSpecializationExists" id="divSkillsErrorMessage"
                            class="font-small text-start" style="display: block;">
                        <font-awesome-icon :icon="['fas', 'circle-exclamation']" size="sm" class="mr-1" />
                        <span>
                            No specialization available for this department.
                        </span>
                    </div>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">Status</label>
                    <select class="neu-select" name="statusFilter" id="statusFilter"
                            data-role="dropdownlist"
                            v-model="selectedStatusFilter">
                        <option v-for="sts in statusFilter" :value="sts.contractLaborShiftStatusId" :key="sts.contractLaborShiftStatusId">
                            {{sts.description}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">Result</label>
                    <select class="neu-select" name="resultFilter" id="resultFilter"
                            data-role="dropdownlist"
                            v-model="selectedResultFilter">
                        <option v-for="fac in resultFilter" :value="fac.Value" :key="fac.Value">
                            {{fac.Text}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">ContractLaborId</label>                    
                    <neu-input name="contractlaborid" v-model="contractlaborid"></neu-input>
                </div>
                <div class="col-2 search-wrapper" style="padding-top:20px">
                    <neu-button type="button" fill="raised" id="WFRequestStatusSearch" 
                        class="float-end me-1" @click="searchClick">
                        SEARCH
                    </neu-button>
                </div>
            </div>
        </div>

        <div class="activityLog-grid-container mt-2">
            <div id="status-content-loading" class="text-center mt-3" v-if="isShowWorkForceLoader" >
                <div class="fa-2x">
                   <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                </div>
                <p><b>Loading...</b></p>
            </div>
            <div id="statusGrid" v-if="isShowWorkForceGrid">
                <neu-card color="plain-0" class="p-0 m-0" >
                    <neu-table>
                        <neu-table-row :columns="columnHeaders" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column"
                                style="padding-top: 25px !important;">
                            {{ column }}
                            </neu-table-heading>
                        </neu-table-row>
                        <neu-table-body align="left">
                            <template v-if="workforceRequestGridData != null && workforceRequestGridData.length > 0">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row :columns="columnHeaders" v-for="wrkForce in workforceRequestGridDataList" :key="wrkForce" :slot="column">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Date" :title="buildDateTemplate(wrkForce.date)" >
                                        {{ buildDateTemplate(wrkForce.date) }}
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Facilities" :title="wrkForce.facility">{{ wrkForce.facility }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Department" :title="wrkForce.department">{{ wrkForce.department }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Shift" :title="wrkForce.shift">{{ wrkForce.shift }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Skill" :title="wrkForce.skill">
                                        {{ wrkForce.skill }}
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Req Sent">
                                        <template v-if="wrkForce.reqSent == null">
                                        
                                        </template>
                                        <template v-else-if="wrkForce.reqSent">
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'check-circle']" size="lg" class="colorCheckCircle" />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="colorTimesCircle" />
                                            </div>
                                        </template>
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Candidate Assigned">
                                        <template v-if="wrkForce.candidateAssigned == null">
                                        
                                        </template>
                                        <template v-else-if="wrkForce.candidateAssigned">
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'check-circle']" size="lg" class="colorCheckCircle" />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="colorTimesCircle" />
                                            </div>
                                        </template>
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Scheduled">
                                        <template v-if="wrkForce.scheduled == null">
                                        
                                        </template>
                                        <template v-else-if="wrkForce.scheduled">
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'check-circle']" size="lg" class="colorCheckCircle" />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="colorTimesCircle" />
                                            </div>
                                        </template>
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Cancelled">
                                        <template v-if="wrkForce.cancelled == null">
                                        
                                        </template>
                                        <template v-else-if="wrkForce.cancelled">
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'check-circle']" size="lg" class="colorCheckCircle" />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="colorTimesCircle" />
                                            </div>
                                        </template>
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Details" :title="wrkForce.details">
                                        {{ wrkForce.details }}
                                    </neu-label>
                                </neu-table-row>
                            </template>
                            <template v-else>
                                <p id="period-message-text">No data available on current page.</p>
                            </template>
                        </neu-table-body>
                    </neu-table>
                </neu-card>
                <neu-container fluid="" v-if="workforceRequestGridData != null && workforceRequestGridData.length > 0">
                    <neu-row>
                        <neu-col md="6" cols="12" class="neu-text-center neu-text-md-left">
                            <!-- eslint-disable-next-line vue/no-parsing-error  -->
                            <neu-paginator class="justify-content-start" @v-neu-change="(ev: CustomEvent) => this.pageNum = ev.detail.pageNumber"
                                :total="workforceRequestGridData != null && workforceRequestGridData.length"
                                :rowsPerPage="rowsPerPage"
                                >
                            </neu-paginator>
                        </neu-col>
                        <neu-col md="6" cols="12" class="neu-text-center neu-text-md-right">
                            <neu-table-row-count
                                class="mt-3"
                                section="number"
                                :total="workforceRequestGridData != null && workforceRequestGridData.length"
                                :page-number="pageNum"
                                @v-neu-change="rowCountChange"
                            >
                            </neu-table-row-count>
                            
                        </neu-col>
                    </neu-row>
                </neu-container>
            </div>
        </div>

    </div>
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ToasterPopup from '../shared/ToasterPopup.vue';
import {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox
} from "@neutron/vue";
//@ts-ignore
import VueMultiselect from 'vue-multiselect';

class Props {
    statusInput!: any 
}

@Options({
  computed: {
    ...mapState('shared', ['facilitiesByUser', 'departmentsByUser', 'skillsByDepartment', 'skillSpecialization', 'contractLaborShiftStatus']),
    ...mapState('actionable', ['departmentShifts']),
    ...mapState('dashboard', ['workforceRequestStatusResult']),
    workforceRequestGridDataList() {
      if (this.workforceRequestGridData && this.workforceRequestGridData.length === 0)
        return [];
      else return this.workforceRequestGridData.slice(this.first - 1, this.last);
    },
  },
  components: {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox,
    Datepicker,
    VueMultiselect,
    ToasterPopup
  }
}) 
export default class StatusTab extends Vue.with(Props) {
    //Data properties
    facilitiesByUser!: any;
    departmentsByUser!: any;
    departmentShifts!: any;
    skillsByDepartment!: any;
    skillSpecialization!: any;
    contractLaborShiftStatus!: any;
    workforceRequestStatusResult!: any;

    allDepartments: any = [];
    facilities: any = [];
    selectedFacilities: any = null;
    contractlaborid: any = null;
    departments: any = [];
    selectedDepartments: any = null;
    startDate: Date = new Date();
    endDate: Date = new Date();
    selectedStartDate: Date = new Date();
    selectedEndDate: Date = new Date();
    shifts: any = [];
    selectedShift: any = null;
    skills: any = [];
    selectedSkill: any = null;
    skillSpecializations: any = [];
    selectedSkillSpecialization: any = null;
    statusFilter: any = [];
    selectedStatusFilter: any = null;
    resultFilter: any = [];
    selectedResultFilter: any = null;
    IsSkillSpecializationExists: boolean = false;
    workforceRequestGridData: any = null;

    isDepartmentShiftDisable: boolean = true;
    isSkillDisable: boolean = true;
    isskillSpecializationDisable: boolean = true;
    isShowWorkForceLoader: boolean = false;
    isShowWorkForceGrid: boolean = true;

    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;

    columnHeaders: any ={ 
        Date: "9%", 
        Facilities: "10%", 
        Department: "15%", 
        Shift: "10%",
        Skill: "7%", 
        "Req Sent": "7%", 
        "Candidate Assigned": "8%", 
        Scheduled: "7%", 
        Cancelled: "7%", 
        Details: "20%"
    };
    columnIcons: any = {    
        Date: "none", 
        Facilities: "none",
        Department: "none",
        Shift: "none",
        Skill: "none",
        "Req Sent": "none",
        "Candidate Assigned": "none",
        Scheduled: "none", 
        Cancelled: "none",
        Details: "none"
    };

    pageNum: number = 1;
    rowsPerPage: number = 10;
    first: number = 1;
    last: number = 10;

    //Lifecycle hooks
    async mounted(){
       await this.loadStatusTab();
    }

    //Methods
    async loadStatusTab(){
        await this.loadFacilities();
        await this.loadDepartments();
        await this.loadContractLaborShiftStatuses();
        this.getResultFilters();  
    }

    async loadFacilities(){
        var inputData = {
            UserId: this.statusInput.fsUserId,
            TenantId: this.statusInput.tenantId
        }
        await this.$store
            .dispatch("shared/getFacilitiesByUser", inputData)
            .then(() => {
                if(this.facilitiesByUser != null && this.facilitiesByUser.length > 0){
                    var facilitiesByUser = this.facilitiesByUser;
                    var facilityIds = this.statusInput.facilities.map((facility: any) => { return +facility.Value; });
                    var facilities: any = [];
                    facilitiesByUser.sort(this.GetSortOrder("name"));
                    facilitiesByUser.forEach((facility: any) => {
                        if (facilityIds.indexOf(+facility.facilityId) >= 0) {
                            facilities.push({
                                FacilityId: facility.facilityId,
                                Text: facility.name + ' - ' + facility.coid,
                                Value: facility.facilityId,
                                COID: facility.coid
                            });
                        }
                    });
                    this.facilities = facilities;

                    if(this.statusInput.selectedFacilityId != null){
                        this.selectedFacilities = this.statusInput.selectedFacilityId;
                    }
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Facility.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadDepartments(){
        var inputData = {
            UserId: this.statusInput.fsUserId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                if(this.departmentsByUser != null && this.departmentsByUser.length > 0){
                    var finalDepartments: any = [];
                    var departmentIds = this.statusInput.departments.map((department: any) => { return +department.Value; });
                    var departmentsByUser = this.departmentsByUser;

                    //Sorting based on dept code
                    departmentsByUser.sort(this.GetSortOrder("DepartmentCode"));

                    departmentsByUser.forEach((department: any) => {
                        if (departmentIds.indexOf(+department.DepatmentId) >= 0 && department.IsContractLaborNeedAutomationEnabled == 1) {
                            finalDepartments.push({
                                FacilityId: department.FacilityId,
                                Text: department.DepartmentName + ' - ' + department.DepartmentCode,
                                Value: department.DepatmentId,
                                DepartmentCode: department.DepartmentCode
                            });
                        }
                    });

                    this.allDepartments = finalDepartments;
                    this.refreshDepartmentList();
                    
                    if(this.statusInput.selectedDepartments != null){
                        this.selectedDepartments = this.statusInput.selectedDepartments;
                    }
                }                
            })
            .catch((err: any) => {
                if (err) {
                     var errorData = {
                            message: "An error occured while retrieving Department.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    refreshDepartmentList() {
        var selectedFacilities = this.selectedFacilities;

        if (selectedFacilities) {
            //@ts-ignore
            var filteredDepartments = jslinq(this.allDepartments)
                  .where((item: any) => {
                      if(this.selectedFacilities == item.FacilityId){
                        return item;
                      }
                  })
              .toList();

            this.departments = filteredDepartments;
            if(filteredDepartments.length===0){
                    var errorData = {
                            message: "No departments found with automation",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
            }
        } else {
            this.departments = this.allDepartments;
        }
    }

    GetSortOrder(prop: any) {
        return function (a: any, b: any) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            } return 0;
        }
    }

    onFacilityChange(){
        this.refreshDepartmentList();
        this.selectedDepartments = null;
    } 
    
    async onDepartmentChange(){
        this.shifts = [];
        this.skills = [];
        this.skillSpecializations = [];
        this.emitter.emit('showLoader');

        var today = moment().format('DD-MMM-YYYY');

        var deptIds: any = [];
        if(this.selectedDepartments != null) {
            this.selectedDepartments.forEach((obj: any) => {
                deptIds.push(obj.Value);
            });
        }
        
        if (deptIds.length >= 1) {
            this.isSkillDisable = false;
            var data = { DepartmentIds: deptIds, EffectiveDate: moment().format("YYYY-MM-DD"), TenantId: this.statusInput.tenantId };
            await this.loadSkills(data);

        }
        else {
            this.selectedSkill = null;
            this.isSkillDisable = true;
        }

        if (deptIds.length == 1) {
            this.isDepartmentShiftDisable = false;               
            var selectDept = deptIds[0];
            await this.loadDepartmentShifts(selectDept, today);
                              
            this.isskillSpecializationDisable = false;
            //@ts-ignore
            var selectFacility: any = jslinq(this.facilities)
                    .where((fac: any) => {
                        if(fac.Value === this.selectedFacilities){
                            return fac;
                        }
                    })
              .toList();
            await this.loadSkillSpecializations(selectFacility[0].COID, this.selectedDepartments[0].Value)
        }        
        else {
            this.isDepartmentShiftDisable = true; 
            this.isskillSpecializationDisable = true;
            this.selectedShift = null;
            this.selectedSkillSpecialization = null;
        }

        this.emitter.emit('hideLoader');
    }
    
    async loadDepartmentShifts(deptId: any, date: any){
        var inputData = {
            departmentId: deptId,
            date: moment(date).format('YYYY-MM-DD')
        }
        await this.$store
                .dispatch("actionable/getDepartmentShifts", inputData)
                .then(() => {
                    if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                        var productiveShifts = this.filterShiftsByType(this.departmentShifts);
                        this.shifts = productiveShifts;                     
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async loadSkills(data: any){
        await this.$store
                .dispatch("shared/getSkills", data)
                .then(() => {
                    if (this.skillsByDepartment != null && this.skillsByDepartment.length > 0) {
                        var skills: any = [];
                        this.skillsByDepartment.forEach((value: any)=>{
                             var ind = skills.findIndex((item: any) => {
                                return item.SkillLevelId == value.skillLevelId;
                            });
                            if (ind == -1) {
                                skills.push({ SkillLevelId: value.skillLevelId, Description: value.description });
                            }
                        })
                        skills = this.sortBy(skills, 'description', 'asc');
                        this.skills = skills;                    
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Skill.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async loadSkillSpecializations(FacilityCoid: any, DepartmentId: any){
        var inputData = {
            FacilityCoid: FacilityCoid,
            DepartmentId: DepartmentId
        }
        await this.$store
                .dispatch("shared/getSkillSpecializations", inputData)
                .then(() => {
                    if (this.skillSpecialization != null && this.skillSpecialization.length > 0) {
                        var flags = [];
                        var distinctSkillSpecializations = [];
                        for (var index = 0; index < this.skillSpecialization.length; ++index) {
                            if (!flags[this.skillSpecialization[index].wfFoldergroupID]) {
                                flags[this.skillSpecialization[index].wfFoldergroupID] = true;
                                distinctSkillSpecializations.push(this.skillSpecialization[index]);
                            }
                        }
                        this.skillSpecializations = distinctSkillSpecializations; 
                    }
                    else{
                       var errorData = {
                            message: "No specialization available for this department.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData); 
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Skill Specialization.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async loadContractLaborShiftStatuses(){
       
        await this.$store
                .dispatch("shared/getContractLaborShiftStatuses", this.statusInput.tenantId)
                .then(() => {
                    if (this.contractLaborShiftStatus != null && this.contractLaborShiftStatus.length > 0) {
                        var filters = this.sortBy(this.contractLaborShiftStatus, 'description', 'asc');
                        this.statusFilter = filters;

                        this.statusFilter.unshift({
                            contractLaborShiftStatusId: 0,
                            description: 'All Status'
                        });

                        this.selectedStatusFilter = this.statusFilter[0].ContractLaborShiftStatusId;                    
                    }
                    else{
                        var errorData = {
                            message: "No Contract Labor Shift Status found.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Contract Labor Shift Status.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    getResultFilters() {
        var filter = [];
        var defaultValue = { Text: 'All', Value: 0 };
        filter.push(defaultValue);
        filter.push({ Text: 'Success', Value: 1 });
        filter.push({ Text: 'Failure', Value: 2 });
        this.resultFilter = filter;
        this.selectedResultFilter =  this.resultFilter[0].Value;
    }

    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    filterShiftsByType(allShifts: any) {
        //@ts-ignore
        var filteredShifts = jslinq(allShifts)
                  .where((shift: any) => {
                      if(shift.productiveId === 1){
                        return shift;
                      }
                  })
              .toList();

        var updatedShifts = filteredShifts.map((item: any) => {
            item.shiftText = item.code.trim() + ' ' + moment(item.startTime).format('h:mm A') + ' to ' + moment(item.endTime).format('h:mm A');
            return item;
        });

        return updatedShifts;
    }

    async loadWorkforceGrid(inputData: any){
        this.isShowWorkForceLoader = true;
        this.isShowWorkForceGrid = false;
        await this.$store
            .dispatch("dashboard/postWorkforceRequestStatus", inputData)
            .then(() => {
                if (this.workforceRequestStatusResult != null) {
                    this.workforceRequestGridData = this.sortBy(this.workforceRequestStatusResult,"date", 'asc')           
                }
                this.isShowWorkForceGrid = true;
                this.isShowWorkForceLoader = false;
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowWorkForceLoader = false;
                    this.isShowWorkForceGrid = true;
                    var errorData = {
                        message: "An error occured while retrieving Workforce request status data.",
                        flag: "error"
                    }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    rowCountChange(ev: CustomEvent) {
      if (ev.detail && ev.detail.first && ev.detail.last) {
        this.first = ev.detail.first;
        this.last = ev.detail.last;
      }
      if (ev.detail && ev.detail.value) this.rowsPerPage = ev.detail.value;
    }

    buildDateTemplate(Date: any) {
        var date = moment(Date).format('MM/DD/YYYY HH:mm A');
        return date;
    }

    async searchClick(){
        var model: any = {};
        if (this.contractlaborid == null) {
            if (this.selectedFacilities == null || this.selectedFacilities.length == 0) {
                var errorData = {
                    message: "Please select Facility.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (this.selectedDepartments == null || this.selectedDepartments.length == 0) {
                var errorData = {
                    message: "Please select Department.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (this.selectedStartDate == null) {
                var errorData = {
                    message: "Please enter valid From Date.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (this.selectedEndDate == null) {
                var errorData = {
                    message: "Please enter valid To Date.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
        }
        else if (moment(this.selectedStartDate ) > moment(this.selectedEndDate )) {
             var errorData = {
                message: "From Date should be less than To Date.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
        
        model.FacilityId = this.selectedFacilities == null ? null : this.selectedFacilities;
        var deptIds: any = [];
        if(this.selectedDepartments != null)
        {
            this.selectedDepartments.forEach((obj: any) => {
                deptIds.push(obj.Value);
            });
        }
        model.DepartmentIds = deptIds;
        model.FromDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
        model.ToDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
        if (this.selectedShift != null)
            model.ShiftId = this.selectedShift;
        if (this.selectedSkill != null)
            model.SkillId = this.selectedSkill;
        if (this.selectedSkillSpecialization != null)
            model.SpecializationId = this.selectedSkillSpecialization;
        if (this.selectedStatusFilter != null)
            model.StatusId = this.selectedStatusFilter;
        if (this.selectedResultFilter != null)
            model.Result = this.selectedResultFilter;
        model.contractlaborid = this.contractlaborid == null ? null : Number(this.contractlaborid);

        await this.loadWorkforceGrid(model);
    }

    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
#divSkillsErrorMessage{
    border-radius: 4px;
    border-color: #a3d0e4;
    color: #00435e;
    background-color: #d9ecf5;
    box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
}

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 1.8rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.colorCheckCircle{
    color: #0bae0b;
}

.colorTimesCircle{
    color:#f32424
}
.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.125rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}
</style>
