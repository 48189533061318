<template>
   
    <div class="mainViewFilter scrollableDiv-filter scrollBar">
        <!--dropdown-menu show-->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label" id="facilitiesFilter-dashboard-label">Facilities:</label>
                    <div class="close-parent multiselect-scroll"> 
                        <VueMultiselect v-model="selectedFacilities"
                                    :options="facilities"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="selectedFacilityChanged"
                                    placeholder="Select Facilities"
                                    label="Text"
                                    track-by="Text"
                                    id="selFacilities">
                        </VueMultiselect>
                    <label v-if="selectedFacilities != null && selectedFacilities.length > 0" class="close-child" @click="resetFacilityDropdown">X</label> 
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" id="clustersFilter-dashboard-label">Service Lines:</label>
                    <div class="close-parent multiselect-scroll"> 
                         <VueMultiselect v-model="selectedClusters"  
                                    :options="clusters"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="selectedClusterChanged"
                                    placeholder="Select Service Lines"
                                    label="Text"
                                    track-by="Text"
                                    id="selClusters">
                    </VueMultiselect>
                    <label v-if="selectedClusters != null && selectedClusters.length > 0" class="close-child" @click="resetServiceLineDropdown">X</label> 
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" id="departmentFilter-dashboard-label">Departments:</label>
                    <div class="close-parent multiselect-scroll"> 
                         <VueMultiselect v-model="selectedDepartments"
                                    :options="departments"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="selectedDeptChanged"
                                    placeholder="Select Departments"
                                    label="Text"
                                    track-by="Text"
                                    id="selDepartments">
                    </VueMultiselect>
                    <label v-if="selectedDepartments != null && selectedDepartments.length > 0" class="close-child" @click="resetDepartmentDropdown">X</label> 
                    </div>
                    <br />
                    <a v-if="profileData.TrainingEnabled"
                        href="#"
                        class="modaltoolbarIcon neu-tooltip"
                        style="cursor: pointer; color:#3673AA;" onclick="window.open('https://mediaconnect.app.medcity.net/embed?id=d2266b54-bcf6-47b2-92bd-6572385bcde3', '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How')">
                        <span aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Learn How
                        </span>
                        <span class="neu-tooltip--text tooltipTxt">Click to learn how<br/>to use Filters</span>              
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label" id="chkCurrent-label">
                        <neu-checkbox id="chkCurrent" name="chkCurrent" class="mr-10" v-model="isCurrentShiftChecked"></neu-checkbox>Current Shift
                    </label>
                </div>
                <div class="form-group">
                    <label class="control-label" id="chkNext-label">
                        <neu-checkbox id="chkNext" name="chkNext" class="mr-10" v-model="isNextShiftChecked"></neu-checkbox>Next Shift
                    </label>
                </div>
                <div class="form-group">
                    <label class="control-label" id="chkPrevious-label">
                        <neu-checkbox id="chkPrevious" name="chkPrevious" class="mr-10" v-model="isPreviousShiftChecked"></neu-checkbox>Previous Shift
                    </label>
                </div>
                <div class="form-group">
                    <label class="control-label" id="shiftTypesFilter-dashboard-label">Shift Summary:</label>
                    <br />
                    <select name="ddlShiftSummary" :class="['neu-select', !dateRangesEnabled ? 'neu-input--disabled':'']" v-model="selectedSummaries" @change="summaryChanged()" :disabled="!dateRangesEnabled">
                        <option :value="null" selected>All Shifts...</option>
                        <option v-for="smry in summaries" :value="smry.Value" :key="smry.Value">
                            {{smry.Text}}
                        </option>
                    </select>
                    <!-- <span class="material-icons neu-input__select-icon z-max">arrow_drop_down</span> -->
                </div>
                <div class="form-group">
                    <label class="control-label" id="dtStart-label">Start:</label>
                    <Datepicker v-model="startDateTime" weekStart="0" class="datePickerWidth" :disabled="!dateRangesEnabled" autoApply ></Datepicker>
                    <!--@change="startDateTimeChanged()" -->
                </div>
                <div class="form-group">
                    <label class="control-label" id="dtEnd-label">End:</label>
                    <Datepicker v-model="endDateTime" weekStart="0" class="datePickerWidth" :disabled="!dateRangesEnabled" autoApply ></Datepicker>
                    <!--@change="endDateTimeChanged()" disabled="!dateRangesEnabled"-->
                </div>
            </div>
        </div>
        <div class="row text-center" style="margin-top: 20px; margin-bottom: 10px;">
            <div class="btn-group" role="group" aria-label="...">
                <button class="btn btn-link col-md-2" @click="loadDailyRosterFilterReport" v-if="isShowPrintBtn">
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'print']" size="lg" />
                    </i> Print
                </button>
                <button @click="saveFilters" class="btn btn-link col-md-3">
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'save']" size="lg" />
                    </i> Save Filter
                </button>
                <button @click="preferenceSettings" class="btn btn-link col-md-3">
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'cog']" size="lg" />
                    </i> My Settings
                </button>
                <neu-button type="button" fill="raised" class="col-md-3 padding0" @click="applyFilters('btnclick', 0)">
                    <!--:disabled="!enableApplyClear"-->
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'filter']" size="lg" />
                    </i> Apply Filters
                </neu-button>
                <button @click="clearFilters" class="btn btn-link col-md-3">
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'undo']" size="lg" />
                    </i> Reset Filters
                </button>
            </div>
        </div>
        <div v-if="isToasterVisible">
            <ToasterPopup @closeModal="closeToasterPopup"
                          :msgValue="msgValue"
                          :classFlag="classFlag"></ToasterPopup>
        </div>
    </div>
</template>

<script lang="ts">

    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import jslinq from "jslinq";
    import moment from "moment";
    import { mapState } from "vuex";
    import { MetricsFilter, StartEndDateTimeModel, Filter } from "@/models/dashboardmain/index";
    import { KeyPairModel } from '@/models/common/index';
    import { Profile } from '@/models/profile/index';
    //@ts-ignore
    import VueMultiselect from 'vue-multiselect';
    //@ts-ignore
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import ToasterPopup from "../shared/ToasterPopup.vue";
    import {
        NeuCheckbox,
        NeuButton
    } from "@neutron/vue";

    class Props {
        dashboardConfig!: any;
    }

    @Options({
        computed: {
            ...mapState('profile', ['profileData']),
            ...mapState('dashboard', ['aggregatedDashbaordFilter']),
            ...mapState('userstaffpreferences', ['filterPreferences']),
        },
        components: {
            VueMultiselect: VueMultiselect,
            Datepicker: Datepicker,
            NeuCheckbox: NeuCheckbox,
            NeuButton: NeuButton,
            ToasterPopup: ToasterPopup
        },
        watch: {            
            isCurrentShiftChecked(newValue, oldValue) {
                this.onCheckboxChange(newValue, oldValue);
            },

            isNextShiftChecked(newValue, oldValue) {
                this.onCheckboxChange(newValue, oldValue);
            },

            isPreviousShiftChecked(newValue, oldValue) {
                this.onCheckboxChange(newValue, oldValue);
            }
        }
    })
    export default class MainViewFilter extends Vue.with(Props) {
        //Data properties
        aggregatedDashbaordFilter!: any;
        filterPreferences!: any;
        profileData!: Profile;
        filtersData!: Filter;
        facilities: KeyPairModel[] = [];
        selectedFacilities: any = null;
        selectedClusters: any = null;
        clusterEnabled: boolean = true;
        clusters: KeyPairModel[] = [];
        selectedDepartments: any = null;
        departmentEnabled: boolean = true;
        departments: KeyPairModel[] = [];
        selectedSummaries: any = null;
        summaryEnabled: boolean = true;
        summaries: KeyPairModel[] = [];
        isCurrentShiftChecked: boolean = true;
        isNextShiftChecked: boolean = false;
        isPreviousShiftChecked: boolean = false;
        startDateTime: any = null;
        endDateTime: any = null;
        defaultFacilityId: string[] = [];
        distinctSummary!: any;
        currentShift!: any;
        nextShift!: any;
        previousShift!: any;
        currentNextShifts: any = null;
        longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
        shortDateFormat: string = 'YYYY-MM-DD';
        longDateTimeFormatPresentation: string = 'MM/DD/YYYY hh:mm:ss';
        scrollableShellHeightAdjusted: boolean = false;
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        dateRangesEnabled: boolean = false;
        metricsFilter: MetricsFilter = {
            DashboardId: "",
            StartDateTime: "",
            EndDateTime: "",
            CurrentDateTime: "",
            Facilities: [],
            Clusters: null,
            Departments: null,
            SortFields: null,
            UserId: null,
            CurrentShiftChecked: null,
            NextShiftChecked: null,
            PreviousShiftChecked: null
        };
        isShowPrintBtn: boolean = false;

        mounted() {
            if (this.dashboardConfig != null) {
                if(this.aggregatedDashbaordFilter != null){
                    this.setFilterDataFromState();
                }
                else{
                    this.getDefaultfiltersData();
                }
            }
            this.isShowPrintBtn = this.profileData.DashboardDailyRosterPrint;
        }

        unmounted() {
            this.emitter.off('onChangeFilterPreference', this.onChangeFilterPreference);
            this.emitter.off('onChangeFilterPreferenceWithDataRefreshed', this.onChangeFilterPreferenceWithDataRefreshed);
        }

        created(){
            this.emitter.on('onChangeFilterPreference', this.onChangeFilterPreference);
            this.emitter.on('onChangeFilterPreferenceWithDataRefreshed', this.onChangeFilterPreferenceWithDataRefreshed);
        }

        //Methods
        setFilterDataFromState(){
            //Get Master data for all dropdown
            this.filtersData = this.dashboardConfig.Filters;
            this.distinctSummary = this.setStartEndDateTime();
            this.getDDLMasterData();
            
            //set Facility
            if(this.aggregatedDashbaordFilter.Facilities != null && this.aggregatedDashbaordFilter.Facilities.length > 0){
                let facilityList: any[] = [];
                this.aggregatedDashbaordFilter.Facilities.forEach((facility: any) =>{
                    //@ts-ignore
                    var selectFacility = jslinq(this.facilities)
                                        .where((item: any) => {
                                            return item.Value == facility;
                                        }).firstOrDefault();
                    facilityList.push(selectFacility);
                });
                this.selectedFacilities = facilityList;
            }

            //set Cluster
            //fetch cluster list by selected facility
            var clusterItems = this.getDistinct(
                this.getItems(this.selectedFacilities === null || this.selectedFacilities.length === 0
                    ? this.filtersData.Facilities
                    : this.selectedFacilities,
                    this.filtersData.Clusters));
            this.clusters = this.sortAscByText(this.getDistinct(clusterItems));

            //set selected cluster
            if(this.aggregatedDashbaordFilter.Clusters != null && this.aggregatedDashbaordFilter.Clusters.length > 0){
                let clusterList: any[] = [];
                this.aggregatedDashbaordFilter.Clusters.forEach((cluster: any) =>{
                    //@ts-ignore
                    var selectCluster = jslinq(this.clusters)
                                        .where((item: any) => {
                                            return item.Value == cluster;
                                        }).firstOrDefault();
                    clusterList.push(selectCluster);
                });
                this.selectedClusters = clusterList;
            }

            //set Department
            //fetch department list by selected cluster and facility
            //@ts-ignore
            var facilityIds: string[] = this.getIntCollection(this.selectedFacilities, this.filtersData.Facilities);

            var departmentItems: KeyPairModel[] =
                this.getDepartmentItems(this.selectedClusters === null || this.selectedClusters.length === 0
                    ? clusterItems
                    : this.selectedClusters,
                    this.filtersData.Departments,
                    facilityIds);
            this.departments = this.sortAscByText(departmentItems);

            //set selected department
            if(this.aggregatedDashbaordFilter.Departments != null && this.aggregatedDashbaordFilter.Departments.length > 0){
                let deptList: any[] = [];
                this.aggregatedDashbaordFilter.Departments.forEach((dept: any) =>{
                    //@ts-ignore
                    var selectdept = jslinq(this.departments)
                                        .where((item: any) => {
                                            return item.Value == dept;
                                        }).firstOrDefault();
                    deptList.push(selectdept);
                });
                this.selectedDepartments = deptList;
            }

            //set Summary Time
            var summaryItems = this.getDistinct(this.getItems(
                this.selectedDepartments === null || this.selectedDepartments.length === 0
                    ? departmentItems
                    : this.selectedDepartments,
                this.filtersData.Summaries));
            this.summaries = summaryItems;

            //set StartDate, EndDate and other flags
            this.isCurrentShiftChecked = this.aggregatedDashbaordFilter.CurrentShiftChecked;
            this.isNextShiftChecked = this.aggregatedDashbaordFilter.NextShiftChecked;
            this.isPreviousShiftChecked = this.aggregatedDashbaordFilter.PreviousShiftChecked;
            
            //set selected summaries time
            if(this.isCurrentShiftChecked || this.isNextShiftChecked || this.isPreviousShiftChecked){
                this.selectedSummaries = null;
            }
            else{
                setTimeout(() => {
                    this.startDateTime = this.aggregatedDashbaordFilter.StartDateTime;
                    this.endDateTime = this.aggregatedDashbaordFilter.EndDateTime;
                    this.selectedSummaries = this.startDateTime + ' - ' + this.endDateTime;
                }, 1000);               
            }

            //Update Save filter dropdown
            if(this.aggregatedDashbaordFilter.FilterPreferenceId != undefined || this.aggregatedDashbaordFilter.FilterPreferenceId != 0){
                this.emitter.emit('setSelectedFilterPreference', this.aggregatedDashbaordFilter.FilterPreferenceId);
            }
        }

        onChangeFilterPreference(selectedFilterPreference: any){
            if(this.aggregatedDashbaordFilter != null){
                this.setFilterDataFromState();
            }
            else{
                this.selectedFacilities = [];
                this.selectedClusters = [];
                this.selectedDepartments = [];
                this.selectedSummaries = null;

                //@ts-ignore
                var selectedfilter: any = jslinq(this.filterPreferences)
                    .where(function (filter: any) {
                        return filter.id == selectedFilterPreference;
                    })
                    .toList();
                    
                this.setFilterPreferences(selectedfilter);
            }
        }

        

        onChangeFilterPreferenceWithDataRefreshed(selectedFilterPreference: any){
            this.selectedFacilities = [];
                this.selectedClusters = [];
                this.selectedDepartments = [];
                this.selectedSummaries = null;

                //@ts-ignore
                var selectedfilter: any = jslinq(this.filterPreferences)
                    .where(function (filter: any) {
                        return filter.id == selectedFilterPreference;
                    })
                    .toList();
                    
                this.setFilterPreferences(selectedfilter);
                this.applyFilters('', selectedFilterPreference);
        }

        onCheckboxChange(newValue: boolean, oldValue: boolean) {
            if (oldValue != null) {
                if (newValue != oldValue) {
                    this.shiftSelectionHandler();
                }
            }
        }

        setFilterPreferences(filterPref: any) {
            if (filterPref != null) {
                // this.getDDLMasterData();
                if (filterPref[0].facilities.length != 0 && filterPref[0].facilities.length > 0) {
                    this.selectedFacilities = this.facilities.filter((value: KeyPairModel) => {
                        for (var i = 0; i < filterPref[0].facilities.length; i++) {
                            if(filterPref[0].facilities[i] == value.Value) {
                                return value;
                            }
                        }
                    })
                }
          
                //set Cluster
                //fetch cluster list by selected facility
                var clusterItems = this.getDistinct(
                    this.getItems(this.selectedFacilities === null || this.selectedFacilities.length === 0
                        ? this.filtersData.Facilities
                        : this.selectedFacilities,
                        this.filtersData.Clusters));
                this.clusters = this.sortAscByText(this.getDistinct(clusterItems));
                //set selected cluster
                if (filterPref[0].serviceLines.length != 0 && filterPref[0].serviceLines.length > 0) {
                    let clusterList: any[] = [];
                    filterPref[0].serviceLines.forEach((cluster: any) =>{
                        //@ts-ignore
                        var selectCluster = jslinq(this.clusters)
                                            .where((item: any) => {
                                                return item.Value == cluster;
                                            }).firstOrDefault();
                        clusterList.push(selectCluster);
                    });
                    this.selectedClusters = clusterList;
                }
                //@ts-ignore
                var facilityIds: string[] = this.getIntCollection(this.selectedFacilities, this.filtersData.Facilities);
                var departmentItems: KeyPairModel[] =
                    this.getDepartmentItems(this.selectedClusters === null || this.selectedClusters.length === 0
                        ? clusterItems
                        : this.selectedClusters,
                        this.filtersData.Departments,
                        facilityIds);
                this.departments = this.sortAscByText(departmentItems);
                //set selected department
                if(filterPref[0].departments != null && filterPref[0].departments.length > 0){
                    let deptList: any[] = [];
                    filterPref[0].departments.forEach((dept: any) =>{
                        //@ts-ignore
                        var selectdept = jslinq(this.departments)
                                            .where((item: any) => {
                                                return item.Value == dept;
                                            }).firstOrDefault();
                        deptList.push(selectdept);
                    });
                    this.selectedDepartments = deptList;
                }  
            }
        }

        getDefaultfiltersData() {
            this.filtersData = this.dashboardConfig.Filters;

            this.defaultFacilityId = []; 

            this.isCurrentShiftChecked = true;
            this.isNextShiftChecked = false;
            this.isPreviousShiftChecked = false;

            this.getDDLMasterData();

            if (this.profileData.FacilityId != null && this.profileData.FacilityId != undefined && this.profileData.FacilityId != 0) {
                this.defaultFacilityId.push(this.profileData.FacilityId.toString());
            }
            else {
                if(this.facilities != null && this.facilities.length > 0){
                    this.defaultFacilityId.push(this.facilities[0].Value);
                }
            }

            var thisVar = this;
            this.selectedFacilities = this.filtersData.Facilities.filter(function (value: KeyPairModel) {
                return value.Value == thisVar.defaultFacilityId[0];
            });
            this.setSelectedFilters();

            this.selectedClusters = [];
            this.selectedDepartments = [];
            this.selectedSummaries = null;
            this.distinctSummary = this.setStartEndDateTime();
        }

        getDDLMasterData() {
            this.facilities = this.sortAscByText(this.filtersData.Facilities);
            this.clusters = this.sortAscByText(this.getDistinct(this.filtersData.Clusters));
            this.departments = this.getDepartments();
            this.summaries = this.getSummaries();
        }

        getDepartments() {
            if (this.filtersData.Departments != null) {
                var thisVar = this;
                var selectedDepartments = this.filtersData.Departments.filter(function (value: KeyPairModel) {
                    return value.FacilityId == thisVar.defaultFacilityId[0];
                });
                return this.sortAscByText(selectedDepartments);
            }
            else {
                return [];
            }
        }

        getSummaries() {
            if (this.filtersData.Departments != null) {
                var thisVar = this;
                var selectedDepartments = this.filtersData.Departments.filter(function (value: KeyPairModel) {
                    return value.FacilityId == thisVar.defaultFacilityId[0];
                });
                if (selectedDepartments !== null && selectedDepartments.length > 0) {
                    var deptIds: any = this.getIntCollection(selectedDepartments, this.filtersData.Departments, false);
                    if (this.filtersData.Summaries != null) {
                        var selectedSummaries: KeyPairModel[] = this.filtersData.Summaries.filter(function (value: KeyPairModel) {
                            return deptIds.indexOf(value.ParentValue) >= 0;
                        });

                        return this.getDistinct(selectedSummaries);
                    }
                    else
                        return [];
                }
                else
                    return [];
            }
            else
                return [];
        }

        onDateTimeChanges() {
            this.isCurrentShiftChecked = false;
            this.isNextShiftChecked = false;
            this.isPreviousShiftChecked = false;
        }

        startDateTimeChanged() { this.onDateTimeChanges() }

        endDateTimeChanged() { this.onDateTimeChanges() }

        selectedFacilityChanged(){
            if(this.selectedFacilities != null && this.selectedFacilities.length > 0){
                var selectedFacilities = this.selectedFacilities;
            
                //@ts-ignore
                var facilityIds: string[] = this.getIntCollection(selectedFacilities, this.filtersData.Facilities);
                
                var clusterItems = this.getDistinct(
                    this.getItems(selectedFacilities,
                        this.filtersData.Clusters));

                var departmentItems: KeyPairModel[] = [];
                if(this.selectedClusters != null && this.selectedClusters.length > 0){
                    departmentItems = this.getDepartmentItems(this.selectedClusters,
                            this.filtersData.Departments,
                            facilityIds);
                }
                else{
                    departmentItems = this.getDepartmentItems(clusterItems,
                            this.filtersData.Departments,
                            facilityIds);
                }
                var summaryItems = this.getDistinct(this.getItems(
                    departmentItems,
                    this.filtersData.Summaries));

                this.clusters = this.sortAscByText(this.getDistinct(clusterItems));
                this.departments = this.sortAscByText(departmentItems);
                this.summaries = summaryItems;

                if(this.selectedClusters != null && this.selectedClusters.length > 0){
                    //@ts-ignore
                    var clusterIds: any[] = jslinq(this.clusters).select(function (item) { return item.Value; }).toList();

                    this.selectedClusters = this.selectedClusters.filter(function (value: KeyPairModel) {
                        return clusterIds.indexOf(value.Value) >= 0;
                    });
                }

                if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
                    //@ts-ignore
                    var departmentIds: any[] = jslinq(this.departments).select(function (item) { return item.Value; }).toList();

                    this.selectedDepartments = this.selectedDepartments.filter(function (value: KeyPairModel) {
                        return departmentIds.indexOf(value.Value) >= 0;
                    });
                }
            }
            else{
                this.selectedClusters = [];
                this.selectedDepartments = [];
                this.getDDLMasterData();
            }
        }

        selectedClusterChanged(){
            if(this.selectedClusters != null && this.selectedClusters.length > 0){
                var selectedFacilities = this.selectedFacilities;
                var selectedClusters = this.selectedClusters;

                //@ts-ignore
                var facilityIds: string[] = this.getIntCollection(selectedFacilities, this.filtersData.Facilities);

                var departmentItems: KeyPairModel[] =
                    this.getDepartmentItems(selectedClusters,
                        this.filtersData.Departments,
                        facilityIds);

                var summaryItems = this.getDistinct(this.getItems(
                    departmentItems,
                    this.filtersData.Summaries));

                this.departments = this.sortAscByText(departmentItems);
                this.summaries = summaryItems;

                
                if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
                    //@ts-ignore
                    var departmentIds: any[] = jslinq(this.departments).select(function (item) { return item.Value; }).toList();

                    this.selectedDepartments = this.selectedDepartments.filter(function (value: KeyPairModel) {
                        return departmentIds.indexOf(value.Value) >= 0;
                    });
                }
            }
            else{
                this.selectedDepartments = [];
                this.clusters = this.sortAscByText(this.getDistinct(this.filtersData.Clusters));
                this.departments = this.getDepartments();
                this.summaries = this.getSummaries();
            }
        }

        selectedDeptChanged(){
            if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
                var selectedDepartments = this.selectedDepartments;

                var summaryItems = this.getDistinct(this.getItems(
                    selectedDepartments === null || selectedDepartments.length === 0
                        ? this.departments
                        : selectedDepartments,
                    this.filtersData.Summaries));

                this.summaries = summaryItems;
            }
            else{
                this.departments = this.getDepartments();
                this.summaries = this.getSummaries();
            }
        }

        setSelectedFilters() {
            var selectedFacilities = this.selectedFacilities;
            var selectedClusters = this.selectedClusters;
            var selectedDepartments = this.selectedDepartments;

            //@ts-ignore
            var facilityIds: string[] = this.getIntCollection(selectedFacilities, this.filtersData.Facilities);
            //@ts-ignore
            var clusterIds: string[] = this.getIntCollection(selectedClusters, this.filtersData.Clusters);

            var clusterItems = this.getDistinct(
                this.getItems(selectedFacilities === null || selectedFacilities.length === 0
                    ? this.filtersData.Facilities
                    : selectedFacilities,
                    this.filtersData.Clusters));
            var departmentItems: KeyPairModel[] =
                this.getDepartmentItems(selectedClusters === null || selectedClusters.length === 0
                    ? clusterItems
                    : selectedClusters,
                    this.filtersData.Departments,
                    facilityIds);
            var summaryItems = this.getDistinct(this.getItems(
                selectedDepartments === null || selectedDepartments.length === 0
                    ? departmentItems
                    : selectedDepartments,
                this.filtersData.Summaries));

            if (selectedClusters !== null && selectedClusters.length > 0
                && selectedDepartments !== null && selectedDepartments.length > 0) {
                selectedDepartments = selectedDepartments.filter(function (value: KeyPairModel) {
                    return clusterIds.indexOf(value.ParentValue) >= 0;
                });

                this.selectedDepartments = selectedDepartments;
            }

            if (selectedDepartments !== null && selectedDepartments.length > 0) {
                selectedDepartments = selectedDepartments.filter(function (value: KeyPairModel) {
                    return facilityIds.indexOf(value.FacilityId) >= 0;
                });

                this.selectedDepartments = selectedDepartments;
            }

            this.clusters = this.sortAscByText(this.getDistinct(clusterItems));
            this.departments = this.sortAscByText(departmentItems);
            this.summaries = summaryItems;
        }

        summaryChanged() {
            var selectedSummaries = this.selectedSummaries;
            if (selectedSummaries !== null && selectedSummaries !== undefined) {
                var value = selectedSummaries.split(' - ');

                this.startDateTime = value[0];
                this.endDateTime = value[1];
                this.isCurrentShiftChecked = false;
                this.isNextShiftChecked = false;
            }
        }

        clearFilters() {
            this.startDateTime = this.currentShift.startDateTime;
            this.endDateTime = this.currentShift.endDateTime;
            this.isCurrentShiftChecked = true;
            this.isNextShiftChecked = false;
            this.isPreviousShiftChecked = false;
            this.summaries = this.distinctSummary;

            if(this.filterPreferences != null && this.filterPreferences.length > 0){
                //@ts-ignore
                var defaultfilterPreference: any = jslinq(this.filterPreferences)
                        .where(function (filter: any) {
                        if(filter.isDefault){
                            return filter;
                        }
                        })
                    .toList();
                this.setFilterPreferences(defaultfilterPreference);
                this.emitter.emit('setSelectedFilterPreference', defaultfilterPreference[0].id);
            }
            else{
                this.selectedSummaries = null;
                this.departments = this.sortAscByText(this.filtersData.Departments);
                this.selectedDepartments = [];
                this.clusters = this.sortAscByText(this.getDistinct(this.filtersData.Clusters));
                this.selectedClusters = [];
                this.facilities = this.filtersData.Facilities;
                this.selectedFacilities = [];
                this.selectedFacilities.push(this.facilities[0]);
            }

            this.setMetricsFilter();
            this.msgValue = "Your filters were removed successfully, now you will get all facilities"
            this.classFlag = "success";
            this.isToasterVisible = true;
            this.emitter.emit('showLoader');
            this.saveAppliedFilterToState(this.metricsFilter);
            this.emitter.emit("closeFilterPopup");
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }

        transformDateTime(datetime: any) {
            if (datetime instanceof Date) {
                var date = moment(new Date(datetime)).format(this.longDateFormat);
                return date;
            } else {
                return datetime;
            }
        }

        setMetricsFilter() {
            var facilityIds: any[] = this.getIntCollection(this.selectedFacilities, this.filtersData.Facilities, false) as any[];
            var clusterIds = this.getIntCollection(this.selectedClusters, this.filtersData.Clusters, true);
            var departmentIds = this.getIntCollection(this.selectedDepartments, this.filtersData.Departments, true);

            this.metricsFilter.StartDateTime = this.transformDateTime(this.startDateTime);
            this.metricsFilter.EndDateTime = this.transformDateTime(this.endDateTime);
            this.metricsFilter.CurrentDateTime = this.getCurrentDateTime();
            this.metricsFilter.Facilities = facilityIds;
            this.metricsFilter.Clusters = clusterIds;
            this.metricsFilter.Departments = departmentIds;
            this.metricsFilter.SortFields = null;
            this.metricsFilter.CurrentShiftChecked = this.isCurrentShiftChecked;
            this.metricsFilter.NextShiftChecked = this.isNextShiftChecked;
            this.metricsFilter.PreviousShiftChecked = this.isPreviousShiftChecked;
        }

        async applyFilters(flag: string, selectedFilterPreferenceId: any) {
            var selectedFacilities = this.selectedFacilities;
            var selectedClusters = this.selectedClusters;
            var selectedDepartments = this.selectedDepartments;
            var selectedSummaries = this.selectedSummaries;
            if (selectedSummaries !== null
                && (selectedFacilities === null || (selectedFacilities !== null && selectedFacilities.length === 0))
                && (selectedClusters === null || (selectedClusters !== null && selectedClusters.length === 0))
                && (selectedDepartments === null || (selectedDepartments !== null && selectedDepartments.length === 0))) {

                var summaries = this.filtersData.Summaries.filter(function (value: any) {
                    return value.Value === selectedSummaries.Value;
                });

                var summaryParentIds: any[] = this.getParentIntCollection(summaries, this.filtersData.Summaries, false) as any[];

                var departments = this.filtersData.Departments.filter(function (value: any) {
                    return summaryParentIds.indexOf(value.Value) >= 0;
                });

                this.selectedDepartments = departments;
            }

            // If user press the apply filter without a selection option, load their default facility.
            // This will prevent loading all facilities which will cause a timeout
            if ((selectedFacilities === null || (selectedFacilities !== null && selectedFacilities.length === 0))
                && (selectedClusters === null || (selectedClusters !== null && selectedClusters.length === 0))
                && (selectedDepartments === null || (selectedDepartments !== null && selectedDepartments.length === 0))
                && selectedSummaries === null) {

                var thisVar = this;
                var selectedDefaultFacilityId = this.filtersData.Facilities.filter(function (value: any) {
                    return value.Value === thisVar.defaultFacilityId[0];
                });

                this.selectedFacilities = selectedDefaultFacilityId;
            }

            this.setMetricsFilter();
            if (flag == "btnclick") {
                this.msgValue = "Your filters were applied successfully"
                this.classFlag = "success";
                this.isToasterVisible = true;
                this.emitter.emit("closeFilterPopup");
            }
            this.emitter.emit('showLoader');
            //Set Filter data in state
            if(selectedFilterPreferenceId != 0){
                //@ts-ignore
                this.metricsFilter.FilterPreferenceId = selectedFilterPreferenceId;
            }
            this.saveAppliedFilterToState(this.metricsFilter);
            return false;
        }

        async saveAppliedFilterToState(metricsFilter: any){
            await this.$store
                .dispatch("dashboard/saveAggregatedDashbaordFilter", metricsFilter)
                .then(() => {
                    this.emitter.emit("refreshDashboard");
                })
                .catch((err: any) => {
                    
                });
        }

        getSelectedDDLIds(items: any[]) {
            //@ts-ignore
            var selectedIds: any[] = jslinq(items).select(function (item) { return item.Value; }).toList();
            return selectedIds;
        }

        async saveFilters() {
            var preferences: { PreferenceDetails: string } = { PreferenceDetails: "" };
            var preferenceDetails: { FilterPreferences: any[] } = { FilterPreferences: [] };

            var filterPreferences: {
                Facilities: any[],
                ServiceLines: any[],
                Departments: any[]
            } = {
                Facilities: this.getSelectedDDLIds(this.selectedFacilities),
                ServiceLines: this.getSelectedDDLIds(this.selectedClusters),
                Departments: this.getSelectedDDLIds(this.selectedDepartments)
            };
            if (filterPreferences.Facilities.length == 0 && filterPreferences.ServiceLines.length == 0 && filterPreferences.Departments.length == 0) {
                this.msgValue = "Please select filters to save."
                this.classFlag = "error";
                this.isToasterVisible = true;
                return false;
            }
            preferenceDetails.FilterPreferences.push(filterPreferences);
            preferences.PreferenceDetails = JSON.stringify(preferenceDetails);
            var inputData = {
                sourceId: 'Dashboard',
                dashboardId: this.profileData.DashboardId,
                fsUserId: this.profileData.UserId,
                fsStaffId: 0,
                filterPreferences: filterPreferences
            }
           
            this.emitter.emit('showLoader');
            await this.$store
                .dispatch("userstaffpreferences/saveUserPreferences", inputData)
                .then(() => {
                    this.emitter.emit("updateFilterPreferenceDropdown", this.filterPreferences);
                    this.msgValue = "Filter data saved successfully."
                    this.classFlag = "success";
                    this.isToasterVisible = true;
                    this.setMetricsFilter();
                    this.emitter.emit('refreshFilterGrid');
                    this.emitter.emit('showLoader');
                    this.saveAppliedFilterToState(this.metricsFilter);
                    this.emitter.emit('setSelectedFilterPreference', this.filterPreferences[this.filterPreferences.length - 1].id);
                    this.emitter.emit('hideLoader');
                })
                .catch((err: any) => {
                    this.msgValue = "Could not load the User Preferences. This could be due to your session timing out. Please refresh the page to reload this session.";
                    this.classFlag = "error";
                    this.isToasterVisible = true;
                    this.emitter.emit('hideLoader');
                });


        }

        shiftSelectionHandler() {
            var current = this.isCurrentShiftChecked === false ? false : true;
            var next = this.isNextShiftChecked === false ? false : true;
            var previous = this.isPreviousShiftChecked === false ? false : true;
            var shifts = this.getShifts(current, next, previous);
            var enabled: boolean = false;

            if (!current && !next && !previous) {
                enabled = true;
            }

            this.startDateTime = shifts.startDateTime;
            this.endDateTime = shifts.endDateTime;
            this.dateRangesEnabled = enabled;
            this.selectedSummaries = null;
        }

        getShifts(current: any, next: any, previous: any) {
            var startDateTime = this.currentShift.startDateTime;
            var endDateTime = this.currentShift.endDateTime;
            var result: StartEndDateTimeModel = {
                startDateTime: null,
                endDateTime: null,
                total: null
            };

            if (this.nextShift == null && this.previousShift == null) {
                startDateTime = this.currentShift.startDateTime;
                endDateTime = this.currentShift.endDateTime;
            }

            else if (this.nextShift == null) {
                if (current && !previous) {
                    startDateTime = this.currentShift.startDateTime;
                    endDateTime = this.currentShift.endDateTime;
                }
                if (!current && previous) {
                    startDateTime = this.previousShift.startDateTime;
                    endDateTime = this.previousShift.endDateTime;
                }
                if (current && previous) {
                    startDateTime = this.previousShift.startDateTime;
                    endDateTime = this.currentShift.endDateTime;
                }
            }

            else if (this.previousShift == null) {
                if (current && !next) {
                    startDateTime = this.currentShift.startDateTime;
                    endDateTime = this.currentShift.endDateTime;
                }
                if (!current && next) {
                    startDateTime = this.nextShift.startDateTime;
                    endDateTime = this.nextShift.endDateTime;
                }
                if (current && next) {
                    startDateTime = this.currentShift.startDateTime;
                    endDateTime = this.nextShift.endDateTime;
                }
            }

            else if (current && next && previous) {
                startDateTime = this.previousShift.startDateTime;
                endDateTime = this.nextShift.endDateTime;
            }

            else if (current && !next && !previous) {
                startDateTime = this.currentShift.startDateTime;
                endDateTime = this.currentShift.endDateTime;
            }

            else if (!current && next && !previous) {
                startDateTime = this.nextShift.startDateTime;
                endDateTime = this.nextShift.endDateTime;
            }

            else if (!current && !next && previous) {
                startDateTime = this.previousShift.startDateTime;
                endDateTime = this.previousShift.endDateTime;
            }

            else if (current && next && !previous) {
                startDateTime = this.currentShift.startDateTime;
                endDateTime = this.nextShift.endDateTime;
            }

            else if (!current && next && previous) {
                startDateTime = this.previousShift.startDateTime;
                endDateTime = this.nextShift.endDateTime;
            }

            else if (current && !next && previous) {
                startDateTime = this.previousShift.startDateTime;
                endDateTime = this.currentShift.endDateTime;
            }

            result.startDateTime = startDateTime;
            result.endDateTime = endDateTime;

            return result;
        }

        getDepartmentItems(filters: any, collection: any, facilityIds: string[]) {
            var result: KeyPairModel[] = [];

            if (filters !== undefined && filters !== null && filters.length > 0) {
                for (var i = 0; i < filters.length; i++) {
                    var filter = filters[i].Value;
                    //@ts-ignore
                    var items = jslinq(collection.filter(function (item: any) {
                        return item.ParentValue === filter;
                    })).orderBy(function (item: any) {
                        return item.Text;
                    }).distinct().toList();

                    items.forEach(function (value: any) {
                        result.push(value);
                    });
                }

                result = result.filter(function (value: any) {
                    return facilityIds.indexOf(value.FacilityId) >= 0;
                });

                return result;
            }
            else
                return [];
        }

        getIntCollection(filters: any, collection: any, allowNull: boolean) {
            var items = [];
            if (filters === undefined || filters === null || filters.length === 0) {
                if (allowNull) {
                    return null;
                }
                items = collection;
            } else {
                for (var i = 0; i < filters.length; i++) {
                    var filter = filters[i];
                    var it = { ParentValue: filter.ParentValue, Value: filter.Value, Text: filter.Text };
                    items.push(it);
                }
            }

            //@ts-ignore
            var result: any[] = jslinq(items).select(function (item) { return item.Value; }).toList();

            return result;
        }

        getParentIntCollection(filters: any, collection: any, allowNull: boolean) {
            var items = [];
            if (filters === undefined || filters === null || filters.length === 0) {
                if (allowNull) {
                    return null;
                }
                items = collection;
            } else {
                for (var i = 0; i < filters.length; i++) {
                    var filter = filters[i];
                    var it = { ParentValue: filter.ParentValue, Value: filter.Value, Text: filter.Text };
                    items.push(it);
                }
            }

            //@ts-ignore
            var result: any[] = jslinq(items).select(function (item) { return item.ParentValue; }).toList();

            return result;
        }

        getItems(filters: any, collection: KeyPairModel[] | null) {
            var result: any[] = [];

            if (filters !== undefined && filters !== null && filters.length > 0) {
                for (var i = 0; i < filters.length; i++) {
                    var filter = filters[i].Value;

                    //@ts-ignore
                    var items = jslinq(collection).where(function (item) { return item.ParentValue === filter; }).distinct().toList();

                    items.forEach((value) => { result.push(value); });
                }
            }

            result = this.sortAscByText(result);

            return result;
        }

        sortAscByText(collection: KeyPairModel[]) {
            //@ts-ignore
            var result = jslinq(collection).distinct().orderBy(function (item: any) {
                return item.Text;
            }).toList();

            return result;
        }

        getDistinct(collection: KeyPairModel[] | null) {
            if (collection != null) {
                //@ts-ignore
                var results = jslinq(collection)
                    .select(function (item: any) {
                        return { Value: item.Value, Text: item.Text };
                    })
                    .distinct()
                    .orderBy(function (item: any) {
                        return item.Value;
                    })
                    .toList();

                return results;
            }
            else {
                return [];
            }
        }

        setStartEndDateTime() {
            var distinctSummary = this.getDistinct(this.filtersData.Summaries);
            this.currentNextShifts = this.getCurrentNextShift(distinctSummary);
            this.currentShift = this.currentNextShifts[0];
            this.nextShift = this.currentNextShifts[1];
            this.previousShift = this.currentNextShifts[2];
            this.startDateTime = this.currentShift.startDateTime;
            this.endDateTime = this.currentShift.endDateTime;
            return distinctSummary;
        }

        getCurrentDateTime() {
            var date = moment(new Date()).format(this.longDateFormat);
            return date;
        }

        getStartDate() {
            var date = moment(new Date()).format(this.shortDateFormat);
            var sdt = date + 'T07:00:00';

            return sdt;
        }

        getEndDate() {
            var date = moment(new Date()).format(this.shortDateFormat);
            var edt = date + 'T23:59:00';

            return edt;
        }

        getCurrentNextShift(collection: KeyPairModel[]) {
            var currentDateTime = new Date(this.getCurrentDateTime());
            var currentNextShift = [];

            //@ts-ignore
            var results: any = jslinq(collection)
                .select(function (item) {
                    var model: StartEndDateTimeModel = {
                        startDateTime: null,
                        endDateTime: null,
                        total: null
                    };
                    var datetimes = item.Value.split(' - ');
                    var startDateTime = new Date(datetimes[0]);
                    var endDateTime = new Date(datetimes[1]);

                    model.startDateTime = datetimes[0];
                    model.endDateTime = datetimes[1];
                    model.total = endDateTime.getTime() - startDateTime.getTime();

                    return model;
                }).toList();

            //@ts-ignore
            var current: any = jslinq(results)
                .where(function (item: any) {
                    var startDateTime = new Date(item.startDateTime);
                    var endDateTime = new Date(item.endDateTime);

                    if ((currentDateTime.getTime() >= startDateTime.getTime()) && (currentDateTime.getTime() <= endDateTime.getTime())) {
                        return item;
                    }
                }).toList();

            //@ts-ignore
            var minShift = jslinq(current)
                .min(function (el: any) {
                    return el.total;
                });

            //@ts-ignore
            current = jslinq(current)
                .firstOrDefault(function (item: any) {
                    return item.total === minShift;
                });

            if (current === null) {
                var startEndModel: StartEndDateTimeModel = {
                    startDateTime: null,
                    endDateTime: null,
                    total: null
                };
                startEndModel.startDateTime = this.getStartDate();
                startEndModel.endDateTime = this.getEndDate();
                current = startEndModel;
            }

            if (this.filtersData !== null && current !== undefined) {
                this.filtersData.StartDateTime = current.startDateTime;
                this.filtersData.EndDateTime = current.endDateTime;
            }

            //@ts-ignore
            var next = jslinq(results)
                .where(function (item: any) {
                    var thisShift = new Date(current.endDateTime);
                    var startDateTime = new Date(item.startDateTime);
                    var endDateTime = new Date(item.endDateTime);

                    if ((startDateTime.getTime() >= thisShift.getTime()) && (endDateTime.getTime() >= thisShift.getTime())) {
                        return item;
                    }
                    return null;
                }).toList();

            //@ts-ignore
            minShift = jslinq(next)
                .min(function (el: any) {
                    return el.total;
                });

            //@ts-ignore
            next = jslinq(next)
                .firstOrDefault(function (item: any) {
                    return item.total === minShift;
                });

            //@ts-ignore
            var previous = jslinq(results)
                .where(function (item: any) {
                    var thisShiftTime = new Date(current.startDateTime);
                    var startDateTime = new Date(item.startDateTime);
                    var endDateTime = new Date(item.endDateTime);

                    if ((startDateTime.getTime() <= thisShiftTime.getTime()) && (endDateTime.getTime() <= thisShiftTime.getTime())) {
                        return item;
                    }
                    return null;
                }).toList();

            //@ts-ignore
            minShift = jslinq(previous)
                .min(function (el: any) {
                    return el.total;
                });

            //@ts-ignore
            previous = jslinq(previous)
                .firstOrDefault(function (item: any) {
                    return item.total === minShift;
                });


            currentNextShift.push(current);
            currentNextShift.push(next);
            currentNextShift.push(previous);
            return currentNextShift;
        }

        preferenceSettings() {
            this.emitter.emit("loadUserPreferencesPopup");
        }

        calDateTimeFormated(inputDateTime: any) {
            return moment(new Date(inputDateTime)).format(this.longDateTimeFormatPresentation);
        }

        loadDailyRosterFilterReport() {
            if(this.profileData.DashboardDailyRosterPrint){
                    var input: any = {
                    startDate: this.startDateTime,
                    endDate: this.endDateTime,
                    selectedFacilities: this.selectedFacilities,
                    clusterDepartments: (this.selectedClusters != null ? this.departments : null),
                    selectedDepartments: (this.selectedDepartments != null && this.selectedDepartments.length > 0
                        ? this.selectedDepartments : this.selectedClusters != null && this.selectedClusters.length > 0
                            ? this.departments : null),
                    isDefaultReport: false
                }
                this.emitter.emit("loadDailyRosterFilterReportPopup", input);
            }
        }
        resetFacilityDropdown() {
            this.selectedFacilities = null;
            this.selectedFacilityChanged();
        }
        resetServiceLineDropdown() {
            this.selectedClusters = null;
            this.selectedClusterChanged();
        }
        resetDepartmentDropdown() {
            this.selectedDepartments = null;
            this.selectedDeptChanged();
        }

    }
</script>

<style scoped>
    .btn {
        white-space: nowrap !important;
    }

    .neu-select {
        width: 225px !important;
        font-size: 14px !important;
        border: 1px solid #CED1D7 !important;
    }

    .datePickerWidth {
        width: 225px !important;
    }

    /* Select Icon */
    .neu-input__select-icon {
        display: block;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        left: 18.5rem;
        background-color: initial;
        transition: .5s ease-in-out;
        pointer-events: none;
        top: 0.4rem;
    }
    .close-child {
       position: absolute;
       float: right;
       right: 40px;
       top: 13px;
       cursor: pointer;
       font-size: 11px;
       z-index: 50;
    }

    .close-parent {
        position: relative;
    }

    .scrollableDiv-filter {
    overflow-y: auto!important;
    overflow-x: hidden;
    max-height: calc(100vh - 270px);
  }


</style>

<style>
 .multiselect-scroll > div > .multiselect__tags {
    max-height: 108px!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
   
    
  }

  .multiselect-scroll > div > .multiselect__select {
    margin-right: 7px!important;
   
    
  }
</style>