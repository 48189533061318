<template>
    <div id="modal-header" class="card shadow-sm mb-2 bg-white rounded">
        <div id="header-loading" class="text-center mt-3" v-show="isShowLoader">
            <div class="fa-2x">
                <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
            </div>
            <p><b>Loading header...</b></p>
        </div>
        <div id="header-content" class="card-body position-relative" v-if="isShowHeaderContent">
            <div class="row ">
                <div id="divSummary" :class="[headerViewModel.pcsEnabled ? 'col-sm-6' : 'col-sm-7']">
                    <div id="dept-summary" v-if="headerViewModel.showDeptSummary">
                        <h5><span class="card-title">{{fullFacilityName()}}</span></h5>
                        <h6><span class="text-muted">{{headerViewModel.serviceLineName}}</span> > <span class="card-title">{{fullDepartmentName()}}</span></h6>
                        <div><i class="far fa-calendar"></i> <span class="font-weight-bold">{{startDate()}}</span></div>
                        <div><i class="far fa-clock"></i> <span class="font-weight-bold">{{startTime()}}</span> - <span class="font-weight-bold">{{endTime()}}</span></div>
                    </div>
                    <div id="skillGapDetails" class="skillgroup-container" v-if="headerViewModel.showSkillAggregation">

                    </div>
                </div>
                <div class="col text-end">
                    <div v-if="headerViewModel.pmEnabled">
                        <div id="plusMetrics">
                            <PlusMetrics :plusMetricsData="plusMetricsData" />
                        </div>
                    </div>
                </div>
                <div id="divPcs" class="col text-start " v-if="headerViewModel.pcsEnabled">
                    <div class="white-background-color padding-left-15 line-height-25">
                        <div class="row white-background-color ">
                            <div class="col-sm-12 text-center"><b>PCS</b></div>

                        </div>
                        <div class="row white-background-color ">
                            <div class="col-sm-6" id="count-container"><span class="census-data">Count</span>:  </div>
                            <div class="col-sm-6 text-end text-padding ">
                                <span class="digits">{{headerViewModel.pcsTotalCensus != null ? headerViewModel.pcsTotalCensus: '00'}}</span>
                            </div>
                        </div>
                        <div class="row white-background-color ">
                            <div class="col-sm-6" id="rating-container"><span class="census-data">Rating</span>: </div>
                            <div class="col-sm-6 text-end text-padding ">
                                <span class="digits">{{headerViewModel.pcsRating != null ? headerViewModel.pcsRating: '00'}}</span>
                            </div>
                        </div>
                        <div class="row white-background-color ">

                            <div class="col-sm-6" id="level-container"><span class="census-data">Level</span>:  </div>
                            <div class="col-sm-6 text-end text-padding "><span>{{headerViewModel.pcsRangeName}}</span></div>
                        </div>
                        <div class="row white-background-color ">
                            <div class="col-sm-6" id="rating-container">&nbsp;</div>
                            <div class="col-sm-6 text-end">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col text-start">
                    <div class="line-height-25 padding-right-15 ">

                        <neu-row class="row text-center white-background-color">
                            <neu-col cols="12"><b><span>{{statDescription}}</span></b></neu-col>
                        </neu-row>
                        <neu-row class="row white-background-color">
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" id="staffTo-container" :class="[isAddCensusClsStaffTo ? 'census': '']"><span class="census-data">Staff-to</span>: </neu-col>
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-end text-padding "><span class="metric ">{{headerViewModel.staffTo}}</span></neu-col>
                        </neu-row>
                        <neu-row class="row white-background-color">
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-7" id="actual-container"><span class="census-data">Actual</span>:</neu-col>
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-5 text-end text-padding ">
                                <span class="metric " id="spanActual" :class="[isAddCensusClsActual ? 'census': '']">{{headerViewModel.actualCensus}}</span>
                            </neu-col>
                        </neu-row>
                        <neu-row class="row white-background-color">
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-7" id="forecast-container"><span class="census-data">Forecast</span>: </neu-col>
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-5 text-end text-padding ">
                                <span id="spanforecast" class="metric " :class="[isAddCensusClsForecast ? 'census': '']">{{headerViewModel.forecastCensus}}</span>
                            </neu-col>
                        </neu-row>
                        <neu-row class="row white-background-color">
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-7" id="volumeLevel-container">
                                <span class="census-data">Volume Level</span>:
                            </neu-col>
                            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-5  text-end text-padding ">
                                <span class="digits">{{censusLow}}</span>-<span class="digits">{{censusPeak}}</span>
                            </neu-col>
                        </neu-row>

                    </div>
                </div>
                <div class="padding-right-15">

                </div>

            </div>
            <hr v-if="headerViewModel.pcsEnabled" style="margin-top: 0 !important; margin-bottom: 12px !important;" />
            <div class="row">
                <div class="col text-end">
                    <div v-if="headerViewModel.pcsEnabled">
                        <div>Last Census Update: <span>{{lastCensusUpdateStartDateTime()}}</span></div>
                    </div>
                </div>
            </div>
            <hr style="margin-top: 0 !important; margin-bottom: 12px !important;" v-if="headerViewModel.showSkillGaps" />
            <div class="row" v-if="headerViewModel.showSkillGaps">
                <div class="col-auto skill-gaps-container">
                    <div id="skill-gaps"></div>
                </div>
            </div>
            <hr style="margin-top: 0 !important; margin-bottom: 12px !important;" />
            <div class="row">
                <div class="col-sm-2 text-start">
                    <span id="selected-skill"></span>
                </div>
                <div class="row col">
                    <div class="col text-end row-padding">
                        <span v-if="headerViewModel.showCareAssignmentPrint" id="care-assignment-sheet-print">
                            <i aria-hidden="true">
                                <font-awesome-icon :icon="['fas', 'file-pdf']"
                                                   size="sm" />
                            </i>
                            <a class="actionable-menu-link" href="#" @click="loadCareAssignmentSheet('PDF')">Care Assignment Sheet</a>
                        </span>
                        <span v-if="headerViewModel.showActionableRefresh" class="actionable-refresh" id="actionable-refresh">
                            &nbsp;|&nbsp;
                            <i aria-hidden="true">
                                <font-awesome-icon :icon="['fas', 'sync-alt']"
                                                   size="sm" />
                            </i>
                            <a class="actionable-menu-link" href="#" @click="refreshActionablePopup">Refresh</a>
                        </span>
                        <span v-if="headerViewModel.showClockInStatues" class="clockin-status" id="btnClockInStatus">
                            &nbsp;|&nbsp;
                            <i aria-hidden="true">
                                <font-awesome-icon :icon="['far', 'clock']"
                                                   size="sm" />
                            </i>
                            <a class="actionable-menu-link" href="#" @click="loadClockInStatus">Clock-In-Details</a>
                        </span>
                        <span v-if="headerViewModel.showNotes" class="notes" id="actionable-note-id">
                            &nbsp;|&nbsp;
                            <i aria-hidden="true">
                                <font-awesome-icon :icon="['far', 'sticky-note']"
                                                   size="sm" />
                            </i>
                            <a class="actionable-menu-link" href="#" @click="loadDepartmentSummaryNote">Notes</a>
                        </span>
                        <span v-if="headerViewModel.showSaveAsExcel" class="save-as-excel" id="saveAsExcel">
                            &nbsp;|&nbsp;
                            <i aria-hidden="true">
                                <font-awesome-icon :icon="['far', 'file-excel']"
                                                   size="sm" />
                            </i>
                            <a class="actionable-menu-link" href="#" @click="loadCareAssignmentSheet('Excel')">Export to Excel</a>
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div id="header-message" class="text-center mt-3" v-if="isShowHeaderMessage">
            <p id="header-message-text">{{headerMessageText}}</p>
        </div>
        <!-- Toaster -->
        <div v-if="isToasterVisible">
            <ToasterPopup @closeModal="closeToasterPopup"
                          :msgValue="msgValue"
                          :classFlag="classFlag"></ToasterPopup>
        </div>
    </div>
</template>
<script lang="ts">
    //import plugins and modules
    import { Vue, Options } from 'vue-class-component';
    import { mapState } from "vuex";
    import moment from "moment";

    //import models and enum
    import { DashboardRelations } from "@/enums/enum";

    //import components
    import ToasterPopup from "../../shared/ToasterPopup.vue";
    import PlusMetrics from './PlusMetrics.vue';

    class Props {
        deptSummaryHeaderData!: any
    }

    @Options({
        computed: {
            ...mapState('shared', ['needsAggregationMetrics', 'deparmentMetrics']),
        },
        components: {
            ToasterPopup,
            PlusMetrics
        }
    })
    export default class DepartmentSummaryHeader extends Vue.with(Props) {
        //Data properties
        deparmentMetrics!: any;
        needsAggregationMetrics!: any;
        isShowLoader: boolean = false;
        isShowHeaderContent: boolean = true;
        isShowHeaderMessage: boolean = false;
        headerMessageText: string = "";
        longDateTimeFormatPresentation: string = 'MM/DD/YYYY  hh:mm A';
        shortDateFormatPresentation: string = 'MM/DD/YYYY';
        shortTimeFormat: string = 'hh:mm A';
        longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
        source: string = 'Dashboard';
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        statDescription: string = '';
        censusLow: string = '';
        censusPeak: string = '';
        isAddCensusClsStaffTo: boolean = false;
        isAddCensusClsActual: boolean = false;
        isAddCensusClsForecast: boolean = false;
        currentSkillGap: any = null;
        tabChanged = 0; //Home Department Tab by Default
        headerViewModel: any = {
            facilityName: null,
            facilityCoid: null,
            facilityId: null,
            departmentName: 'N/A',
            departmentCode: 'N/A',
            startDateTime: null,
            endDateTime: null,
            staffTo: null,
            actualCensus: null,
            forecastCensus: null,
            pcsCensus: null,
            censusLevel: "Low",
            pcsTotalCensus: null,
            pcsRating: null,
            pcsRangeName: null,
            pcsEnabled: false,
            pmEnabled: false,
            dateChangedUTC: null,
            serviceLineName: null,
            showSkillGaps: true,
            showCareAssignmentPrint: false,
            showActionableRefresh: false,
            showClockInStatues: false,
            showNotes: false,
            showSaveAsExcel: false,
            dashboardConfig: null,
            showDeptSummary: false,
            showSkillAggregation: true
        };
        plusMetricsData: any = null;
        isAssignmentSectionVisible: boolean = false;

        //Lifecycle hooks
        created() {
            this.emitter.on("showPlusMetricsError", this.showPlusMetricsError);
            this.emitter.on("setAssignmentSectionVisibleFlag", this.setAssignmentSectionVisibleFlag);
        }
        async mounted() {
            await this.refresh();
        }
        unmounted() {
            this.emitter.off("showPlusMetricsError", this.showPlusMetricsError);
            this.emitter.on("setAssignmentSectionVisibleFlag", this.setAssignmentSectionVisibleFlag);
        }
        //Methods
        async refresh() {
            this.showLoader();
            await this.getDeparmentMetrics();
        }
        async getDeparmentMetrics() {
            var inputData = {
                deptId: this.deptSummaryHeaderData.departmentId,
                startDate: moment(this.deptSummaryHeaderData.startTime).format(this.longDateFormat),
                endDate: moment(this.deptSummaryHeaderData.endTime).format(this.longDateFormat),
            }
            await this.$store
                .dispatch("shared/getNeedsAggregationMetrics", inputData)
                .then(() => {
                    this.hideLoader();
                    if (this.needsAggregationMetrics != null) {
                        var firstMetric = this.needsAggregationMetrics[0];
                        var censusValue = firstMetric.forecastCensus;
                        if (firstMetric.staffTo) {
                            censusValue = firstMetric.staffTo;
                        }
                        else if (firstMetric.actualCensus) {
                            censusValue = firstMetric.actualCensus;
                        }

                        this.isShowHeaderContent = true;
                    }
                    else {
                        this.isShowHeaderMessage = true;
                        this.headerMessageText = 'No Content';
                    }
                    this.loadPlusMetrics();
                    this.getDeptMatris_load(this.deptSummaryHeaderData.departmentId,
                        firstMetric.deptSummaryId,
                        this.deptSummaryHeaderData.startTime,
                        censusValue,
                        firstMetric);
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        this.msgValue = "Error encountered while retrieving department header details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
        setAssignmentSectionVisibleFlag(flag: boolean){
            this.isAssignmentSectionVisible = flag;
        }
        async getDeptMatris_load(departmentId: any, deptSummaryId: any, effectiveDate: any, forecastCensus: any, firstMetric: any) {
            var inputData = {
                tenantId: this.deptSummaryHeaderData.tenantId,
                departmentId: departmentId,
                deptSummaryId: deptSummaryId,
                startDateTime: effectiveDate
            }
            await this.$store
                .dispatch("shared/getDeparmentMetrics", inputData)
                .then(() => {
                    if (this.deparmentMetrics != null) {
                        var filterData = this.deparmentMetrics.filter(
                            function (value: any) {
                                return (forecastCensus >= value.volumeLow && forecastCensus <= value.volumePeak);
                            });
                        if (filterData.length > 0) {
                            this.statDescription = filterData[0].statDescription;
                            this.censusLow = filterData[0].volumeLow;
                            this.censusPeak = filterData[0].volumePeak;
                        }
                        else {
                            filterData = this.deparmentMetrics.filter(
                                function (value: any) {
                                    return (forecastCensus < value.volumeLow);
                                });

                            if (filterData.length > 0) {
                                this.statDescription = filterData[0].statDescription;
                                this.censusLow = filterData[0].volumeLow;
                                this.censusPeak = filterData[0].volumePeak;
                            }
                            else {
                                filterData = this.deparmentMetrics.filter(
                                    function (value: any) {
                                        return (forecastCensus > value.volumePeak);
                                    });

                                if (filterData.length > 0) {
                                    this.statDescription = filterData[filterData.length - 1].statDescription;
                                    this.censusLow = filterData[filterData.length - 1].volumeLow;
                                    this.censusPeak = filterData[filterData.length - 1].volumePeak;
                                }
                            }
                        }
                    }
                    this.map(firstMetric);
                    this.loadHeader();
                    if (this.deptSummaryHeaderData.showSkillGaps) {
                        this.loadSkillGaps();
                    }
                    this.hideLoader();
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        this.msgValue = "Error encountered while retrieving department Metrics details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        map(metric: any) {
            this.headerViewModel.facilityName = metric.facilityName;
            this.headerViewModel.facilityCoid = metric.facilityCoId;
            this.headerViewModel.facilityId = metric.facilityId;
            this.headerViewModel.departmentName = metric.departmentName;
            this.headerViewModel.departmentCode = metric.deptCode;
            this.headerViewModel.staffTo = metric.staffTo || '-';
            this.headerViewModel.actualCensus = metric.actualCensus || '-';
            this.headerViewModel.forecastCensus = metric.forecastCensus;
            this.headerViewModel.startDateTime = this.deptSummaryHeaderData.startTime;
            this.headerViewModel.endDateTime = this.deptSummaryHeaderData.endTime;
            this.headerViewModel.pcsRating = metric.pcsRating;
            this.headerViewModel.pcsRangeName = metric.pcsRangeName;
            this.headerViewModel.pcsTotalCensus = metric.pcsTotalCensus;
            this.headerViewModel.pcsEnabled = metric.pcsEnabled;
            this.headerViewModel.dateChangedUTC = metric.dateChangedUTC;
            this.headerViewModel.serviceLineName = this.deptSummaryHeaderData.serviceLineName;
            this.headerViewModel.showSkillGaps = this.deptSummaryHeaderData.showSkillGaps;
            this.headerViewModel.showCareAssignmentPrint = this.deptSummaryHeaderData.showCareAssignmentPrint;
            this.headerViewModel.showActionableRefresh = this.deptSummaryHeaderData.showActionableRefresh;
            this.headerViewModel.showClockInStatues = this.deptSummaryHeaderData.showClockInStatues;
            this.headerViewModel.showNotes = this.deptSummaryHeaderData.showNotes;
            this.headerViewModel.showSaveAsExcel = this.deptSummaryHeaderData.showSaveAsExcel;
        }
        loadHeader() {
            this.isAddCensusClsStaffTo = false;
            this.isAddCensusClsActual = false;
            this.isAddCensusClsForecast = false;

            if (this.headerViewModel.staffTo >= 0) {
                this.isAddCensusClsStaffTo = true;
                return;
            }

            if (this.headerViewModel.actualCensus >= 0) {
                this.isAddCensusClsActual = true;
                return;
            }

            if (this.headerViewModel.forecastCensus > 0) {
                this.isAddCensusClsForecast = true;
                return;
            }
        }

        loadSkillGaps() {
            var container: any = document.getElementById('skill-gaps');
            if (container != null) {
                container.innerHTML = "";
            }

            var skillGapDetails: any = document.getElementById('skillGapDetails');
            skillGapDetails.classList = 'skillgroup-container skill-gap-label scrollBar';
            skillGapDetails.innerHTML = "";

            this.needsAggregationMetrics = this.needsAggregationMetrics.sort(this.sortByColumns("skillLevelCategoryId", "fsSkillLevelID"));
            var dashboardSkillCategories = null;
            if (this.deptSummaryHeaderData.dashboardConfig) {

                var dashboardConfig = this.deptSummaryHeaderData.dashboardConfig;
                dashboardSkillCategories = dashboardConfig.EntityModelsHeaders.filter(function (value: any) {
                    return value.RelationId == DashboardRelations.SKILLGROUPHEADER;
                });
                if (dashboardSkillCategories.length > 0) {
                    this.headerViewModel.showDeptSummary = false;
                    this.headerViewModel.showSkillAggregation = true;
                    this.headerViewModel.showSkillGaps = false;
                }
                else {
                    this.headerViewModel.showDeptSummary = true;
                    this.headerViewModel.showSkillAggregation = false;
                    this.headerViewModel.showSkillGaps = true;
                }
            }
            if (this.headerViewModel.showSkillAggregation) {
                var deptSkills = this.needsAggregationMetrics;
                var deptSkillCategories: any = [];
                deptSkills.forEach(function (value: any) {
                    var filterData = deptSkillCategories.filter(
                        function (item: any) {
                            return (item.SkillLevelCategoryId === value.skillLevelCategoryId);
                        })[0];

                    if (!filterData) {
                        deptSkillCategories.push({
                            SkillLevelCategoryId: value.skillLevelCategoryId
                        });
                    }
                });


                var skillCategoriesList: any = [];
                dashboardSkillCategories.forEach(function (value: any) {
                    var filterData = deptSkillCategories.filter(
                        function (item: any) {
                            return (item.SkillLevelCategoryId === value.RelationValue);
                        })[0];

                    if (filterData) {
                        skillCategoriesList.push(value);
                    }
                });


                var skillGapsByCategory: any = document.createElement('table');
                skillGapsByCategory.classList = 'table-border table-striped table-hover dashboardTable';
                skillGapsByCategory.style.width = 'initial';
                var selectedSkill: any = document.getElementById('selected-skill');
                selectedSkill.innerHTML = "";


                for (var i = 0; i < 5; i++) {

                    if (i == 0) {

                        var row: any = document.createElement('tr');
                        var col: any = document.createElement('td');
                        col.rowSpan = 2;

                        var allSkills: any = document.createElement('td');
                        allSkills.classList = 'no-border all-skills-min-width';
                        var span: any = document.createElement('span');
                        span.innerText = 'Show All Skills';
                        span.classList = 'metric all';
                        allSkills.append(span);

                        col.append(allSkills);

                        var that = this;
                        col.addEventListener('click', function () {
                            var selectedSkill: any = document.getElementById('selected-skill');
                            if (selectedSkill.innerHTML != "") {
                                that.emitter.emit('clearHomeGridDataBySkill');
                                that.emitter.emit('clearOtherGridDataBySkill');
                                that.emitter.emit('clearUnscheduleStaffGridDataBySkill');
                            }
                            selectedSkill.innerHTML = "";


                        });

                        row.append(col);

                        for (var column = 0; column < skillCategoriesList.length; column++) {
                            var currentCategorySkills = deptSkills.filter(function (value: any) {
                                return value.skillLevelCategoryId == skillCategoriesList[column].RelationValue;
                            });

                            var skillGroupScheduled = 0;
                            var skillGroupTarget = 0;
                            var skillGroupDifference = 0;

                            currentCategorySkills.forEach(function (value: any) {
                                skillGroupScheduled = skillGroupScheduled + value.scheduled;
                                skillGroupTarget = skillGroupTarget + value.target;
                                skillGroupDifference = skillGroupDifference + value.difference;
                            });

                            var colSpan = 1;
                            if (currentCategorySkills.length > 0) {
                                colSpan = currentCategorySkills.length;
                            }

                            var span: any = document.createElement('div');
                            span.innerHTML = Number(skillGroupDifference).toFixed(2);
                            span.classList = 'metric-group';

                            if (skillGroupDifference > 0) {
                                span.classList = 'metric-group diff-up';
                            }
                            else if (skillGroupDifference < 0) {
                                span.classList = 'metric-group diff-down';
                            }
                            else {
                                span.classList = 'metric-group diff-good';
                            }

                            var col: any = document.createElement('th');
                            col.colSpan = colSpan;
                            col.classList = 'detailsHeader';
                            col.style.minWidth = '150px';

                            var skillDiv = document.createElement('div');
                            skillDiv.id = 'skill-gaps';
                            skillDiv.append(skillCategoriesList[column].EntityName)
                            skillDiv.append(span);

                            col.append(skillDiv);
                            row.append(col);
                        }
                        skillGapsByCategory.append(row);
                    }

                    if (i == 1) {
                        var row: any = document.createElement('tr');

                        for (var column = 0; column < skillCategoriesList.length; column++) {
                            var currentCategorySkills = deptSkills.filter(function (value: any) {
                                return value.skillLevelCategoryId == skillCategoriesList[column].RelationValue;
                            });

                            for (var columnSkill = 0; columnSkill < currentCategorySkills.length; columnSkill++) {
                                var tdSkill: any = document.createElement('td');
                                tdSkill.innerHTML = currentCategorySkills[columnSkill].skillLevelDesc;
                                tdSkill.classList = 'detailsHeader';
                                row.append(tdSkill);
                            }
                        }
                        skillGapsByCategory.append(row);
                    }

                    if (i == 2) {
                        var row: any = document.createElement('tr');
                        row.classList = 'white-background-color';

                        var col: any = document.createElement('td');
                        col.innerText = "Scheduled";
                        col.classList = 'text-start';
                        col.style.width = '120px';

                        row.append(col);

                        for (var column = 0; column < skillCategoriesList.length; column++) {

                            var currentCategorySkills = deptSkills.filter(function (value: any) {
                                return value.skillLevelCategoryId == skillCategoriesList[column].RelationValue;
                            });

                            for (var columnSkill = 0; columnSkill < currentCategorySkills.length; columnSkill++) {
                                var col: any = document.createElement('td');
                                col.innerText = Number(currentCategorySkills[columnSkill].scheduled).toFixed(2);
                                col.classList = 'skill-min-width';
                                row.append(col);
                            }

                        }
                        skillGapsByCategory.append(row);
                    }

                    if (i == 3) {
                        var row: any = document.createElement('tr');
                        row.classList = 'white-background-color';

                        var col: any = document.createElement('td');
                        col.innerText = "Target";
                        col.classList = 'text-start';
                        col.style.width = '120px';

                        row.append(col);

                        for (var column = 0; column < skillCategoriesList.length; column++) {

                            var currentCategorySkills = deptSkills.filter(function (value: any) {
                                return value.skillLevelCategoryId == skillCategoriesList[column].RelationValue;
                            });

                            for (var columnSkill = 0; columnSkill < currentCategorySkills.length; columnSkill++) {
                                var col: any = document.createElement('td');
                                col.innerText = Number(currentCategorySkills[columnSkill].target).toFixed(2);
                                row.append(col);
                            }
                        }
                        skillGapsByCategory.append(row);
                    }

                    if (i == 4) {
                        var row: any = document.createElement('tr');
                        row.classList = 'white-background-color';

                        var col: any = document.createElement('td');
                        col.innerText = "Difference";
                        col.classList = 'text-start';
                        col.style.width = '100px';

                        row.append(col);

                        for (var column = 0; column < skillCategoriesList.length; column++) {

                            var currentCategorySkills = deptSkills.filter(function (value: any) {
                                return value.skillLevelCategoryId == skillCategoriesList[column].RelationValue;
                            });

                            for (var columnSkill = 0; columnSkill < currentCategorySkills.length; columnSkill++) {

                                var skill: any = document.createElement('div');
                                var diff: any = (Number(currentCategorySkills[columnSkill].scheduled) - Number(currentCategorySkills[columnSkill].target)).toFixed(2);

                                var span: any = document.createElement('div');
                                span.innerText = diff;
                                span.classList = 'metric';

                                if (diff > 0) {
                                    span.classList = 'metric diff-up';
                                }
                                else if (diff < 0) {
                                    span.classList = 'metric diff-down';
                                }
                                else {
                                    span.classList = 'metric diff-good';
                                }

                                skill.style.display = 'inline-block';
                                skill.append(span);

                                var col: any = document.createElement('td');
                                col.setAttribute('data-skillDesc', currentCategorySkills[columnSkill].skillLevelDesc);
                                col.append(skill);

                                var that = this;
                                col.addEventListener('click', function () {
                                    var selectedSkill: any = document.getElementById('selected-skill');
                                    selectedSkill.innerHTML = "";

                                    //@ts-ignore
                                    var skillDesc = this.getAttribute("data-skillDesc");

                                    var skillDescBold: any = document.createElement('b');
                                    skillDescBold.innerText = skillDesc;

                                    selectedSkill.append("Assignments are filtered by ");
                                    selectedSkill.append(skillDescBold);

                                    that.emitter.emit('filterHomeGridDataBySkill', skillDesc);
                                    that.emitter.emit('filterOtherGridDataBySkill', skillDesc);
                                    that.emitter.emit('filterUnscheduleStaffGridDataBySkill', skillDesc);

                                });

                                row.append(col);
                            }
                        }
                        skillGapsByCategory.append(row);
                    }
                }

                skillGapDetails.append(skillGapsByCategory);

            }
            if (this.headerViewModel.showDeptSummary) {
                var label: any = document.createElement('div');
                label.classList = 'skill-gap-label';
                label.innerHTML = 'Skill Gaps: ';
                container.append(label);

                var all: any = document.createElement('div');
                var allSpan: any = document.createElement('span');
                allSpan.classList = 'metric all';
                allSpan.innerHTML = 'ALL';

                var that = this;
                all.addEventListener('click', function (e: any) {
                    var target = e.target;
                    if (that.currentSkillGap) {
                        that.currentSkillGap.classList.remove('selected-skill');
                    }
                    that.currentSkillGap = target;
                    target.classList = 'selected-skill';
                    //To-Do
                    //Send skill to homedepartment.js
                    //PubSub.emit(PubSubEventType.properties[FILTER_HOME_ASSIGNMENT_GRID].eventname, null);
                    //Send skill to otherdepartment.js
                    //PubSub.emit(PubSubEventType.properties[FILTER_OTHER_ASSIGNMENT_GRID].eventname, null);
                    //Send skill to unschedule.js
                    //PubSub.emit(PubSubEventType.properties[FILTER_UNSCHEDULESTAFF_ASSIGNMENT_GRID].eventname, null);

                });
                all.classList = 'skill-gap selected-skill';
                all.append(allSpan);
                container.append(all);

                this.currentSkillGap = all;

                this.needsAggregationMetrics.forEach(function (metric: any) {
                    var skill: any = document.createElement('div');
                    var diff: any = (Number(metric.scheduled) - Number(metric.target)).toFixed(2);
                    var span: any = document.createElement('span');
                    span.innerHTML = metric.skillLevelDesc + '&nbsp;&nbsp;' + diff;
                    span.classList = 'metric';

                    if (diff > 0) {
                        span.classList = 'diff-up';
                    }
                    else if (diff < 0) {
                        span.classList = 'diff-down';
                    }
                    else {
                        span.classList = 'diff-good';
                    }
                    skill.setAttribute('data-skillId', metric.skillLevelDesc); //SkillDescription
                    skill.addEventListener('click', function (e: any) {
                        var target = e.target;
                        //var data = target.data();

                        if (that.currentSkillGap) {
                            that.currentSkillGap.classList.remove('selected-skill');
                        }
                        that.currentSkillGap = target;
                        target.classList = 'selected-skill';

                        //To-Do
                        // events.log('Sending  data to Home and Other Grid ' + data.skillid);

                        //Send skill to homedepartment.js
                        //PubSub.emit(PubSubEventType.properties[FILTER_HOME_ASSIGNMENT_GRID].eventname, data.skillid);
                        //Send skill to otherdepartment.js
                        //PubSub.emit(PubSubEventType.properties[FILTER_OTHER_ASSIGNMENT_GRID].eventname, data.skillid);
                        //Send skill to unschedule.js
                        //PubSub.emit(PubSubEventType.properties[FILTER_UNSCHEDULESTAFF_ASSIGNMENT_GRID].eventname, data.skillid);

                    });
                    skill.classList = 'skill-gap';
                    skill.append(span);
                    container.append(skill);
                });
            }
        }

        sortByColumns(Column1: any, Column2: any) {
            return function (a: any, b: any) {
                if (a[Column1] < b[Column1]) {
                    return -1;
                } else if (a[Column1] > b[Column1]) {
                    return 1;
                }
                else {
                    if (a[Column2] > b[Column2]) {
                        return 1;
                    } else if (a[Column2] < b[Column2]) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            }
        }

        loadPlusMetrics() {
            var startDate = moment(this.deptSummaryHeaderData.startTime).format('YYYY-MM-DD');
            this.plusMetricsData = {
                startTime: startDate,
                departmentId: this.deptSummaryHeaderData.departmentId
            }
            this.headerViewModel.pmEnabled = this.deptSummaryHeaderData.pmEnabled;
        }
        startDate() {
            return moment(new Date(this.headerViewModel.startDateTime)).format(this.shortDateFormatPresentation);
        }

        endDate() {
            return moment(new Date(this.headerViewModel.endDateTime)).format(this.shortDateFormatPresentation);
        }

        startTime() {
            return moment(new Date(this.headerViewModel.startDateTime)).format(this.shortTimeFormat);
        }

        endTime() {
            return moment(new Date(this.headerViewModel.endDateTime)).format(this.shortTimeFormat);
        }

        fullFacilityName() {
            return '(' + this.headerViewModel.facilityCoid + ') ' + this.headerViewModel.facilityName;
        }

        fullDepartmentName() {
            return '(' + this.headerViewModel.departmentCode + ') ' + this.headerViewModel.departmentName;
        }

        census() {
            var census = this.headerViewModel.forecastCensus;
            if (this.headerViewModel.staffTo) {
                census = this.headerViewModel.staffTo;
            }
            else if (this.headerViewModel.actualCensus) {
                census = this.headerViewModel.actualCensus;
            }
            return census;
        }
        censusLabel() {
            var label = 'Forecast Census';
            if (this.headerViewModel.staffTo) {
                label = 'Staff To';
            }
            else if (this.headerViewModel.actualCensus) {
                label = 'Actual Census';
            }
            return label;
        }

        lastCensusUpdateStartDateTime() {
            var lastCensusUpdateStartDateTime = moment.utc(this.headerViewModel.dateChangedUTC).toDate();

            return moment(lastCensusUpdateStartDateTime).local().format(this.longDateTimeFormatPresentation);
        }

        showLoader() {
            this.isShowLoader = true;
            this.isShowHeaderContent = false;
            this.isShowHeaderMessage = false;
        }

        hideLoader() {
            this.isShowLoader = false;
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }

        showPlusMetricsError() {
            this.msgValue = 'Plus Metrics Service encountered an error. Please try again later.';
            this.classFlag = "error";
            this.isToasterVisible = true;
        }

        async refreshActionablePopup() {
            await this.refresh();
            this.emitter.emit("refreshActionableTabContent");
        }

        loadDepartmentSummaryNote() {
            this.emitter.emit("showDepartmentSummaryNote");
        }

        loadClockInStatus() {
            this.emitter.emit("showClockInStatusPopup");
        }

        loadCareAssignmentSheet(type: any) {
            
            if (type.toLowerCase() == 'PDF'.toLowerCase()) {
                var data = {
                    fullFacilityName: this.fullFacilityName(),
                    fullDepartmentName: this.fullDepartmentName(),
                    startDate: this.startDate(),
                    startTime: this.startTime(),
                    endTime: this.endTime(),
                    serviceLineName: this.headerViewModel.serviceLineName,
                   
                    staffTo: this.headerViewModel.staffTo,
                    actualCensus: this.headerViewModel.actualCensus,
                    forecastCensus: this.headerViewModel.forecastCensus
                };
                if(this.isAssignmentSectionVisible){
                    this.emitter.emit("loadCareAssignmentSheetFromAssignment", data);
                }
                else{
                    this.emitter.emit("loadCareAssignmentSheet", data);
                }
            }
            else //(type == 'Excel') 
            {
                var data = {
                    fullFacilityName: this.fullFacilityName(),
                    fullDepartmentName: this.fullDepartmentName(),
                    startDate: this.startDate(),
                    startTime: this.startTime(),
                    endTime: this.endTime(),
                    serviceLineName: this.headerViewModel.serviceLineName,
                    staffTo: this.headerViewModel.staffTo,
                    actualCensus: this.headerViewModel.actualCensus,
                    forecastCensus: this.headerViewModel.forecastCensus
                };

                if(this.isAssignmentSectionVisible){
                    this.emitter.emit("homeTabExportToExcelFromAssignment", data)
                }
                else{
                    this.emitter.emit("checkActiveTabinActionablePanel", data);
                }
            }
            
            
        }

    }
</script>
<style>
    .census-container {
        padding: 1px 4px;
    }

    .census-data {
        font-weight: 700;
    }

    .census {
        background-color: lightgreen;
    }

    .skill-gap {
        float: left;
        margin: 0 10px;
    }

    .skill-gap-label {
        font-weight: bold;
        float: left;
    }

    .skill-gaps-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 98% !important;
    }

    .metric {
        padding: 4px 6px;
        font-weight: bold;
        border-radius: 4px;
        cursor: pointer;
    }

    .table-border {
        border: 1px solid #CED1D7;
    }

        .table-border td {
            border-left: 1px solid #CED1D7;
            border-bottom: 1px solid #CED1D7;
        }


    .diff-up {
        background-color: #FFEE9A !important;
        border: solid 1px transparent;
    }

    .diff-down {
        background-color: #E87070 !important;
        border: solid 1px transparent;
    }

    .diff-good {
        border: solid 1px;
    }

    .selected-skill {
        border-bottom: solid 4px lightblue;
        padding-bottom: 10px;
    }

    .all {
        background-color: lightgray;
        border: solid 1px transparent;
    }

    .toast-success {
        background-color: #51a351 !important;
    }

    .toast-error {
        background-color: #bd362f !important;
    }

    /* ****************************** */

    #modal-header {
        background-color: #F7F7F8 !important;
        font-size: 13px;
        box-shadow: none !important;
        margin-top: 5px;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

        #modal-header span.font-weight-bold {
            font-weight: 700 !important;
            font-size: 12px;
        }

    #header-content {
        padding-bottom: 5px !important;
    }


    .metric-group {
        padding: 4px 6px;
        font-weight: bold;
        border-radius: 4px;
        color: black;
        float: right;
        background-color: white;
    }


    .skillgroup-container {
        overflow: auto;
        width: 100%
    }

    .skill-details {
        width: 50%;
        float: left;
        padding: 20px;
        border: 2px solid red;
    }

    .skill-min-width {
        min-width: 70px;
    }

    .skill-category-min-width {
        min-width: 150px;
    }

    .white-background-color {
        background-color: white;
    }


    .variance-low {
        color: red;
    }

    .variance-high {
        color: yellow;
    }

    .no-border {
        border: 0px !important;
    }

    .selected-skill-left-rigth {
        border-left: solid 4px lightblue;
        border-right: solid 4px lightblue;
    }

    .padding-left-15 {
        padding-left: 15px !important;
    }


    .line-height-2 {
        line-height: 2;
    }

    .line-height-25 {
        line-height: 2.5;
    }

    .digits {
        font-weight: 700;
    }

    .padding-right-15 {
        padding-left: 15px !important;
    }

    .all-skills-min-width {
        min-width: 120px;
    }

    .text-padding {
        padding-left: 0px !important;
        padding-right: 15px !important;
    }    
</style>
<style scoped>
.text-start{
    text-align: left;
    right: 2px;
}

.text-end{
    text-align: right;
    right: 2px;
}
.row-padding {
    padding-left : 575px;
}
</style>