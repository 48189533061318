<template>
  <div class="filterbar " data-role="droptarget">
    <div class="row" style="padding-right: 17px;">
      <div class="col-sm-4">
        <div class="filter-button" id="dashboard-filters">
          <div id="filters-dashboard" class="dropdown">
            <button class="btn btn-primary dropdown-toggle" @click="showFilter" data-bs-toggle="dropdown" id="filters-dashboard-button" aria-haspopup="true" aria-expanded="false" role="button">Filters</button>
            <div class="dropdown-menu" :class="[isShowFilterDropdown ? 'filterShow': '' , 'dropdown-menu']">
              <MainViewFilter :dashboardConfig="dashboardConfig" 
                              :filterPreferences="filterPreferences"/>
            </div>
            <a v-if="profileData.TrainingEnabled"
              href="#"
              class="modaltoolbarIcon neu-tooltip"
              style="cursor: pointer; color:#3673AA;" onclick="window.open('https://mediaconnect.app.medcity.net/embed?id=7c99a26f-7a09-4672-aa72-e7c6c50911f6', '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How')">
              <span aria-hidden="true">
                <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Learn How
              </span>
              <span class="neu-tooltip--text tooltipTxt">Click to learn how<br/>to print Daily Roster</span>
          </a>
         
          </div>
        </div>

        <select name="ddlfilterPreference" data-role="dropdownlist" class="neu-select filterSelect" v-model="selectedFilterPreference" v-if="filterPreferenceDetails != null" @change="onChangeFilterPreference">
              <option v-for="fpd in filterPreferenceDetails" :value="fpd.id" :key="fpd.id">
                  {{fpd.name}}
              </option>
        </select>
        <neu-icon class="material-icons neu-input__select-icon z-max" v-if="filterPreferenceDetails != null">arrow_drop_down</neu-icon>
        <div class="clPendingConfirmationWrapper" v-if="isCLPendingConfirmationBtnVisible">
          <a class="btn btn-light ms-3 clPendingConfirmationBtn" :href="clPendingConfirmationBtnURL" target="_blank">
            CL Pending Confirmations <span class="badge bg-danger" id="clPendingConfirmationCount">{{CLPendingCount}}</span> 
          </a>
        </div>
      </div>
      <div class="col-sm-2 p-0">
        <div class="row" id="labelsform-dashboard-container">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" id="currentShiftLabel-dashboard-label">From:</label>
              <span id="currentShiftLabel-dashboard" name="currentShiftLabel-dashboard">{{startDateTimeTxt}}</span>
            </div>
            <div class="form-group mt-2">
              <label class="control-label" id="nextShiftLabel-dashboard-label">To:</label>
              <span id="nextShiftLabel-dashboard" name="nextShiftLabel-dashboard" >{{endDateTimeTxt}}</span>
            </div>
          </div>
      </div> 
      </div>
      <div id="divCalloutPopup" class="col-sm-6 text-start pl-0 pr-0">
        <button type="button" class="btn btn-light me-2 calloutBtn" @click="showContractLaborPopup()"> Contract Labor <span class="badge bg-success" id="divContractLoborCount">{{contractLoborCountTxt}}</span> </button>
        <button type="button" class="btn btn-light calloutBtn" @click="showCalloutPopup()"> Call Out <span class="badge bg-warning" id="divCalloutCount">{{calloutCountTxt}}</span> </button>
        <div id="actionsBtn" class="pt-2 actionBtn">
          <DashboardNavbar />
          <a
              href="#"
              class="modaltoolbarIcon neu-tooltip"
              style="cursor: pointer" @click="printDashbaordGrid">
              <i aria-hidden="true">
                <font-awesome-icon :icon="['fas', 'file-pdf']" size="lg" />
              </i>
              <span class="neu-tooltip--text tooltipTxt">Export to PDF</span>
          </a>
          <a
            href="#"
            class="modaltoolbarIcon neu-tooltip"
            style="cursor: pointer" @click="printDashbaordGridToExcel">
            <i aria-hidden="true">
              <font-awesome-icon :icon="['fas', 'file-excel']" size="lg" />
            </i>
            <span class="neu-tooltip--text tooltipTxt">Export to Excel</span>
          </a>
          <a
            href="#"
            class="modaltoolbarIcon neu-tooltip"
            style="cursor: pointer"
            @click="refreshDashboard()">
            <i aria-hidden="true">
              <font-awesome-icon :icon="['fas', 'sync']" size="lg" />
            </i>
            <span class="neu-tooltip--text tooltipTxt">Refresh Screen</span>
          </a>
          <a href="#">
            <fieldset class="toggle">
              <input
                type="checkbox"
                class="cmn-toggle cmn-toggle-round"
                id="disableRefreshToggle-dashboard"
                v-model="isEnableAutoRefresh"
                @change="onChangesAutoRefresh"
                />
              <label
                for="disableRefreshToggle-dashboard"
                class="neu-tooltip"
                style="width: 40px">
                <div class="toggle-button">
                  <div class="toggle-tab"></div>
                </div>
                <span class="neu-tooltip--text tooltipTxt">Click here to disable auto refresh</span>
              </label>
            </fieldset>
          </a>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import jslinq from "jslinq";
import { mapState } from "vuex";
import moment from "moment";

//import components
import MainViewFilter from './MainViewFilter.vue';
import  DashboardNavbar   from '../common/DashboardNavbar.vue'
import {
  NeuSelect,
  NeuOption,
} from "@neutron/vue";

class Props {
    dashboardConfig!: any
    filterPreferences!: any
}

@Options({
  computed: {
    ...mapState('dashboard', ['pendingContractLaborResult']),
    ...mapState('profile', ['profileData', 'userAccounts']), 
    
  },
  components: {
    MainViewFilter,
    NeuSelect,
    NeuOption,
    DashboardNavbar
  }
})
export default class FilterBar extends Vue.with(Props) {
  //Data properties
  pendingContractLaborResult!: any;
  startDateTimeTxt: string= "";
  endDateTimeTxt: string="";
  isCLPendingConfirmationBtnVisible: boolean = false;
  clPendingConfirmationBtnURL: string = "";
  contractLoborCountTxt: string = "";
  calloutCountTxt: string = "";
  isShowFilterDropdown: boolean = false;
  filterPreferenceDetails: any = null;
  isfilterPreferenceDrpVisible: boolean = false;
  selectedFilterPreference: any = null;
  contractLaborProviderConfirmStatus = 106;
  CLPendingCount: any = '';
  isEnableAutoRefresh: boolean = true;
  trainingVideos: any;

  //Lifecycle hooks
  created() {
      this.emitter.on("setFilterBarData", this.setFilterBarDetails);
      this.emitter.on("closeFilterPopup", this.showFilter);
      this.emitter.on("updateFilterPreferenceswithDataRefresh", this.updateFilterPreferenceswithDataRefresh);
      this.emitter.on("updateFilterPreferenceDropdown", this.updateFilterPreferenceDropdown);
      this.emitter.on("setSelectedFilterPreference", this.setSelectedFilterPreference);
      this.emitter.on("loadCLPendingCount", this.loadCLPendingCount);
  }

  async mounted(){
    if(this.filterPreferences != null && this.filterPreferences.length > 0){
      this.setFilterPreferences(this.filterPreferences);
    }
  }

  unmounted() {
      this.emitter.off("setFilterBarData", this.setFilterBarDetails);
      this.emitter.off("closeFilterPopup", this.showFilter);
      this.emitter.off("updateFilterPreferenceswithDataRefresh", this.updateFilterPreferenceswithDataRefresh);
      this.emitter.off("updateFilterPreferenceDropdown", this.updateFilterPreferenceDropdown);
      this.emitter.off("setSelectedFilterPreference", this.setSelectedFilterPreference);
      this.emitter.off("loadCLPendingCount", this.loadCLPendingCount);
  }

  //Methods
  async loadCLPendingCount() {
    await this.getCLPendingConfirmation();
  }

  setFilterBarDetails(data: any){
      this.startDateTimeTxt = data.StartDateTime;
      this.endDateTimeTxt = data.EndDateTime;
      this.isCLPendingConfirmationBtnVisible = data.ContractLaborEnabled,
      this.clPendingConfirmationBtnURL = data.ContractLaborRedirectURL;
      this.contractLoborCountTxt = data.ContractLaborCount;
      this.calloutCountTxt = data.CalloutCount;
  }
  showContractLaborPopup(){
      this.emitter.emit("displayContractLaborCalloutPopup",{
          isContractLaborVisible: true,
          isCalloutVisible: false
      });
  }
  showCalloutPopup(){
      this.emitter.emit("displayContractLaborCalloutPopup",{
          isContractLaborVisible: false,
          isCalloutVisible: true
      });
  }
  showFilter(){
    this.isShowFilterDropdown = !this.isShowFilterDropdown;
  }
  setFilterPreferences(filterPref: any){
    if(filterPref != null && filterPref.length > 0){
      this.filterPreferenceDetails = filterPref;
      //@ts-ignore
      var defaultfilterPreference: any = jslinq(this.filterPreferenceDetails)
              .where(function (filter: any) {
                if(filter.isDefault){
                  return filter;
                }
              })
          .toList();
      this.selectedFilterPreference = defaultfilterPreference[0].id;
      this.emitter.emit('onChangeFilterPreference', this.selectedFilterPreference);
    }
    else{
      this.filterPreferenceDetails = null;
    }
  }

  updateFilterPreferenceswithDataRefresh(filterPref: any){
    if(filterPref != null && filterPref.length > 0){
      this.filterPreferenceDetails = filterPref;
      //@ts-ignore
      var defaultfilterPreference: any = jslinq(this.filterPreferenceDetails)
              .where(function (filter: any) {
                if(filter.isDefault){
                  return filter;
                }
              })
          .toList();
      this.selectedFilterPreference = defaultfilterPreference[0].id;
      this.emitter.emit('onChangeFilterPreferenceWithDataRefreshed', this.selectedFilterPreference);
    }
    else{
      this.filterPreferenceDetails = null;
    }
  }
  
  onChangeFilterPreference(){
      this.emitter.emit('onChangeFilterPreferenceWithDataRefreshed', this.selectedFilterPreference);
  }

  updateFilterPreferenceDropdown(filterPref: any){
    this.filterPreferenceDetails = filterPref;
    //@ts-ignore
    var defaultfilterPreference: any = jslinq(this.filterPreferenceDetails)
            .where(function (filter: any) {
              if(filter.isDefault){
                return filter;
              }
            })
        .toList();
    this.selectedFilterPreference = defaultfilterPreference[0].id;
  }

  setSelectedFilterPreference(id: any){
    this.selectedFilterPreference = id;
  }

  async getCLPendingConfirmation(){
    var inputData = {
        statusIds: this.contractLaborProviderConfirmStatus,
        requestDate: moment().format('MM-DD-YYYY')
    }
    await this.$store
        .dispatch("dashboard/getCLPendingConfirmation", inputData)
        .then(() => {
            if(this.pendingContractLaborResult != null){
              this.CLPendingCount = this.pendingContractLaborResult.length > 0 ? this.pendingContractLaborResult.length : '';
            }
        })
        .catch((err: any) => {
            if (err) {
                console.error(err); // Handle errors any way you want
            }
        });
  }

  refreshDashboard(){
    this.emitter.emit('refreshDashboard');
  }

  onChangesAutoRefresh(){
    this.emitter.emit('autoRefreshChanged', this.isEnableAutoRefresh);
  }


  printDashbaordGrid(){
    this.emitter.emit("printAggregatedSkillDashboard");
  }

  printDashbaordGridToExcel(){
    this.emitter.emit("exportAggregatedSkillDashboardToExcel");
  }
}
</script>
<style scoped>
.dropdown-menu{
  width: 660px;
  padding: 15px !important;
}

.filterShow{
  display: block !important;
}

.filterSelect{
    width: 150px !important;
    font-size: 14px !important;
    border: 1px solid #CED1D7 !important;
    float: left;
    min-height: 2.2rem !important;
    margin-left: 1rem !important;
    padding-right: 1rem !important;
}

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 5px;
    left: 18.5rem;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.modaltoolbarIcon {
    color: #15202E;
    font-size: 16px;
    margin-left: 8px;
}

.modaltoolbarIcon:hover {
    color: #15202E;
}

.tooltipTxt{
  font-size: 0.75rem !important;
}

.actionBtn{
  display: inline-block;
}

fieldSet{
  border: 0 !important;
  display: inline-block !important;
  margin-left: 6px !important;
  top: 3px !important;
}

</style>