<template>
    <div class="txtLeft">
        <legend :class="[!assignmentReviewInput.showTitle ? 'd-none' : '']">{{assignmentReviewInput.title}} &nbsp;<span :class="[!assignmentReviewInput.showStatus ? 'd-none' : '', 'text-danger font-weight-bold']">{{assignmentReviewInput.status}}</span></legend>

        <div class="row">
            <div class="col">
                <h6 class="card-subtitle mb-2 text-muted">
                    <font-awesome-icon :icon="['far', 'calendar']" size="sm" class="mr-1" /> <span class="font-weight-bold">{{DisplayDate}}</span>&nbsp;
                    <font-awesome-icon :icon="['far', 'clock']" size="sm" class="mr-1" /> <span class="font-weight-bold">{{DisplayStartTime}}</span> - <span class="font-weight-bold">{{DisplayEndTime}}</span>&nbsp;
                    <small class="d-inline-block">{{ShiftDuration}}</small>
                </h6>
            </div>
            <div class="col text-end">
                <div></div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl">

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Facility</label>
                            <input class="form-control" readonly :value="assignmentReviewInput.facilityName" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Service Line</label>
                            <input class="form-control" readonly :value="assignmentReviewInput.clusterName" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Department</label>
                            <input class="form-control" readonly :value="assignmentReviewInput.departmentName" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Status</label>
                            <input class="form-control" readonly :value="assignmentReviewInput.productiveDescription" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Shift Code</label>
                            <input class="form-control" name="shiftCode" readonly 
                                :value="assignmentReviewInput.shiftCode" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Skill</label>
                            <input class="form-control" readonly :value="assignmentReviewInput.skillLevelDescription" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Date</label>
                            <input class="form-control" readonly :value="DisplayDate" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label class="font-weight-bold">Start Time</label>
                                    <input class="form-control" readonly :value="DisplayStartTime" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="font-weight-bold">End Time</label>
                                    <input class="form-control" readonly :value="DisplayEndTime" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label class="font-weight-bold">Charge</label>
                                    <div class="">{{ChargeStatus}}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="font-weight-bold">Float</label>
                                    <div class="">{{FloatStatus}}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="font-weight-bold">Cancelled</label>
                                    <div class="">{{CancelStatus}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" :class="[!assignmentReviewInput.showCancelOptions ? 'd-none' : '']" >
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Cancellation Reason</label>
                            <input class="form-control" readonly :value="CancelReason" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="font-weight-bold">Unscheduled Absence</label>
                            <input class="form-control" readonly :value="assignmentReviewInput.unscheduledAbsenceText" />
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-xl-4" :class="[!assignmentReviewInput.showNote ? 'd-none' : '']" >
                <div class="form-group">
                    <label class="font-weight-bold">Note</label>
                    <textarea class="form-control k-input" rows="4" v-model="Note" readonly></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//imports models and enums

//Import Componenets

class Props {
    assignmentReviewInput!: any;
}
 
export default class AssignmentEditReview extends Vue.with(Props) {
    //Data Properties
    DisplayDate: string = "";
    DisplayStartTime: string = "";
    DisplayEndTime: string = "";
    ShiftDuration: string = "";
    ChargeStatus: string = "";
    FloatStatus: string = "";
    CancelStatus: string = "";
    Note: string = "";
    CancelReason: string = "";
    shortTimeFormat: string = 'hh:mm A';
    shortDateFormat: string = 'MM/DD/YYYY';

    //Lifecycle hooks
    mounted(){
        this.loadAssignmentData();
    }

    //Methods
    loadAssignmentData(){
        this.DisplayDate = moment(this.assignmentReviewInput.date).format(this.shortDateFormat);
        this.DisplayStartTime = moment(this.assignmentReviewInput.startTime).format(this.shortTimeFormat);
        this.DisplayEndTime = moment(this.assignmentReviewInput.endTime).format(this.shortTimeFormat);
        
        var shiftDuration = moment(this.assignmentReviewInput.endTime).diff(moment(this.assignmentReviewInput.startTime), 'hours', true);
        this.ShiftDuration = '(' + shiftDuration.toFixed(2) + ' hours)';
        this.ChargeStatus = (this.assignmentReviewInput.charge) ? 'Yes' : 'No';
        this.FloatStatus = (this.assignmentReviewInput.isFloat == "true" || this.assignmentReviewInput.isFloat == true) ? 'Yes' : 'No';
        this.CancelStatus = (this.assignmentReviewInput.isCancelled) ? 'Yes' : 'No';
        this.Note = (this.assignmentReviewInput.note === null) ? '' : this.assignmentReviewInput.note;
        this.CancelReason = (this.assignmentReviewInput.cancelReasonId === 0 && this.assignmentReviewInput.cancelReason === null) ? 'Other - please specify' : this.assignmentReviewInput.cancelReason;
    }
}
</script>
<style scoped>

</style>