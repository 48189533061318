<template>
  <neu-app id="scheduleMaintenance" :style="isSpinnerLoading ? 'loading-area' : ''">
    <div id="loadingDiv" class="loadingDiv" style="padding: 10px;" v-show="isLoading"></div>
    <nav-component v-if="showNav"> </nav-component>
    <toasts-component></toasts-component>
    <neu-content  class="scrollbar">
      <!-- ROUTER OUTPUT -->
      <router-view/>
    </neu-content>
    <Footer />
  </neu-app>
  <div class="loader-container" v-show="isSpinnerLoading">
    <neu-spinner class="spinner" color="primary"> </neu-spinner><br/>
    <neu-label>{{ spinnerText }}</neu-label>
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
//import components
import {
  NeuApp,
  NeuContent,
} from "@neutron/vue";

//import css
import "@/styles/default.css";
import "@/styles/override-dashboard.css";
import '@neutron/core/css/neutron.min.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import '@vueform/toggle/themes/default.css';
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import { defineAsyncComponent } from 'vue';
import NavComponent from './components/scheduleManagement/NavComponent.vue'
import { mapGetters, mapState } from 'vuex';
const Footer = defineAsyncComponent(
  () =>
    import(
      './components/common/Footer.vue' /* webpackChunkName: "FooterComponent" */
    )
)
const ToastsComponent = defineAsyncComponent(
  () =>
    import(
      './components/scheduleManagement/ToastsComponent.vue' /* webpackChunkName: "ToastsComponent" */
    )
)
export default defineComponent({
  name: 'App',
  components: {
    // TopNavbar,
    Footer,
    NeuApp,
    NeuContent,
    NavComponent,
    ToastsComponent
  },
  data() {
    return {
      isAuthenticated: false,
      showNav: false,
      isLoading: false,
      spinnerText: 'Loading...',
      isSpinnerLoading: false
    }
  },
  computed: {
    ...mapGetters(['oidcUser', 'oidcIsAuthenticated', 'getGenerateScheduleRequestLoading']),
    ...mapState('profile', ['profileData', 'userAccounts']),
  },
  watch: {
    oidcIsAuthenticated: function (newVal, oldVal) {
      if (this.oidcUser && newVal && newVal != oldVal) {
        this.loadUserProfile();
      } else {
        this.isAuthenticated = true
        this.$router.go(0);
      } 
    },
  },
  async created() {  
   this.emitter.on("showLoader", this.showLoader);
    this.emitter.on("hideLoader", this.hideLoader);
    this.emitter.on("showSpinner", (data: any) => {
      this.spinnerText = data?.text
      this.showSpinner()
    });
    this.emitter.on("hideSpinner", this.hideSpinner);
  },
  //Methods
  methods: {
    showLoader(){
      this.isLoading = true;
    },
    hideLoader(){
      this.isLoading = false;
    },
    showSpinner(){
      this.isSpinnerLoading = true;
    },
    hideSpinner(){
      this.isSpinnerLoading = false;
    },
    async loadUserProfile() {
      await this.$store
            .dispatch("profile/getUserAccounts")
            .then(() => {
              this.showNav = true
              if (this.userAccounts != null && this.userAccounts.length > 0) {
                  // sessionStorage.setItem('tenant', this?.userAccounts[0]?.tenantId)
              }
              else {
                this.$router.push('/invalidaccount')
              }
            })
            .catch((err: any) => {
              if (err) {
                  this.$router.push('/error')
                  console.error(err); // Handle errors any way you want
              }
      });

      await this.$store
            .dispatch("dashboard/getTrainingVideos", this.profileData.TenantId);
  
    },
  }
})

</script>
<style lang="scss">
// @import '@/scss/scrollbar';
@use "@/scss/scrollbar" as m;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.neu-content__inner-scroll {
  @include m.scroll;
}
.loading-area {
  opacity: 0.3;
  pointer-events: none;
}
.loader-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba($color: lightgray, $alpha: 0.3);
}
</style>
