<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <div class="headerTitleContainer">
                    <span class="headerTitle">Clock-In Details &nbsp;</span>
                    <div class="headerActions">
                        <a v-if="isShowTrainingLink && profileData.TrainingEnabled"

                            class="modaltoolbarIcon neu-tooltip"
                            style="cursor: pointer; color:#3673AA;" @click="fnOpenVideo">
                                <span aria-hidden="true">
                                    <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Learn How
                                </span>
                            <span class="neu-tooltip--text tooltipTxt">{{ trainingLinkToolTip }}</span>
                        </a> 
                    </div>
                </div>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                            <i aria-hidden="true">
                            <font-awesome-icon
                                :icon="['fas', 'close']"
                                size="lg" />
                            </i>
                            <span class="neu-tooltip--text">Close</span>
                        </a>
            </header>
            <div class="panleBody">
                <div id="divClockInStatusDetails" style="height: 100%">
                    <div class="header-clockPop txtLeft">        
                        <hr id="hrClockInStatusLine" />
                        <div id="spnDeptFacilityName">{{facilityName}}</div>
                        <span id="spnClockInStatusSecondHeader">{{lastUpdateDate}}</span>
                    </div>
                    <div style="height:20px;"></div>
                    <div class="col-emsu-width-12" style="height: 85%">
                        <div id="clockIn-content-loading" class="text-center mt-3" v-show="isShowLoader" style="text-align: center">
                            <div class="fa-2x">
                                <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                            </div>
                            <p><b>Loading...</b></p>
                        </div>
                        <div id="gridClockInDetails" class="scrollBar" v-if="isShowClockInContent">
                            <neu-card color="plain-0" class="p-0 m-0">
                                <neu-table>
                                    <neu-table-row :columns="columnHeaders" header>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column" style="padding-top: 25px !important;">
                                            <template v-if="column == 'ClockInGroup'">
                                                <span></span>
                                            </template>
                                            <template v-else>
                                                {{ column }}
                                            </template>
                                        </neu-table-heading>
                                    </neu-table-row>
                                    <neu-table-body align="left">
                                        <template v-if="clockInDataList != null && clockInDataList.length > 0">
                                            <template v-for="clock in clockInDataList" :key="clock">
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-table-row class="clockInStatusGroupRow" :columns="columnHeaders" :slot="column">
                                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                    <neu-label slot="ClockInGroup">
                                                        {{ clock.ClockedInGroupText }}
                                                    </neu-label>
                                                </neu-table-row>
                                                <template v-for="dataItem in clock.ClockInList" :key="dataItem">
                                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                    <neu-table-row :columns="columnHeaders" :slot="column">            
                                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                        <neu-label slot="Name" :title="dataItem.Name">
                                                            {{ dataItem.Name }}
                                                        </neu-label>
                                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                        <neu-label slot="Shift Start Time">
                                                            {{ dataItem.beginningShift }}
                                                        </neu-label>
                                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                        <neu-label slot="Clock-In Time">
                                                            {{ dataItem.actualTimeIn }}
                                                        </neu-label>
                                                    </neu-table-row>
                                                </template>
                                            </template>
                                        </template>
                                    </neu-table-body>
                                </neu-table>
                            </neu-card>
                        </div>
                        <div id="home-message" class="text-center mt-3" v-if="isShowClockInMessage">
                            <p id="home-message-text">No results found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";
import { Profile } from '@/models/profile/index';
//Import Componenets
import {
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard
} from "@neutron/vue";

class Props {
    clockInInput!: any
}

@Options({
  computed: {
    ...mapState('actionable', ['clockInData']),
    ...mapState('profile', ['profileData']),
  },
  components: {
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard
  }
})
export default class ClockInStatus extends Vue.with(Props) {
    //Data properties
    clockInData!:any;
    clockInDetails: any = null;
    clockInDataList: any = null;
    facilityName: string = "";
    lastUpdateDate: string = "";
    isShowClockInContent: boolean = false;
    isShowClockInMessage: boolean = false;
    isShowLoader: boolean = false;
    groupingTextOrder: any[] = ['NOT CLOCKED IN', 'CLOCKED IN & UNSCHEDULED', 'CLOCKED IN'];
    profileData!: Profile;
    columnHeaders: any ={ 
        ClockInGroup: "1%",
        Name: "50%", 
        "Shift Start Time": "25%", 
        "Clock-In Time": "24%" 
    };

    columnIcons: any = {
        ClockInGroup: "none",
        Name: "none", 
        "Shift Start Time": "none", 
        "Clock-In Time": "none"
    }

    //Lifecycle hooks
    async mounted(){
        await this.loadClockInData();
    }

    //Methods
    async loadClockInData(){
        this.facilityName = this.clockInInput.facilityName + " > " + this.clockInInput.departmentName
        await this.getClockInData();
    }

    fnOpenVideo(){
        window.open(this.trainingCurrentLink, '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How');
    } 

    trainingLinkToolTip: string = 'Click here to learn how to use the Clock-In Details information';
    trainingCurrentLink: string = 'https://mediaconnect.app.medcity.net/embed?id=546ad6f4-ab88-4007-b6ec-e04fd99c0825';
    isShowTrainingLink: boolean = true;

    async getClockInData(){
        this.showLoader();
        await this.$store
                .dispatch("actionable/getClockInData", this.clockInInput)
                .then(() => {
                    if(this.clockInData != null){
                        this.loadClockInGrid();
                    }
                    else{
                        this.isShowClockInContent = false;
                        this.isShowClockInMessage = true;
                    }
                    this.hideLoader();
                })
                .catch((err: any) => {
                    if (err) {
                        this.isShowClockInContent = false;
                        this.isShowClockInMessage = false;
                        this.hideLoader();
                        var response = JSON.parse(err.request.response);
                        var errorData = {
                            message: response.Message,
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationActionable', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    loadClockInGrid(){
        this.lastUpdateDate = 'Last Updated: ' + moment(this.clockInData.kronosExtracted).format('MM/DD/YYYY') + 
                                " at " + moment(this.getformattedTime(this.clockInData.kronosExtracted)).format('hh:mm A');
        
        var timeDetails: any = this.clockInData.shiftClockIns;

        if(timeDetails != null && timeDetails.length > 0){
                this.clockInDetails = [];
                timeDetails.forEach((item: any) => {
                var empData: any = {};
                empData.Name = item.employeeName;
                empData.beginningShift = (item.beginShiftDate === null || item.beginShiftDate === '') ? '-' : moment(item.beginShiftDate).format('hh:mm A');
                empData.actualTimeIn = (item.clockIn === null || item.clockIn === '') ? '-' : moment(item.clockIn).format('hh:mm A');
                empData.ClockedIn = (item.clockInStatus.toLowerCase() === 'Clocked In'.toLowerCase()) ? 'Yes' : 'No';
                empData.ClockedIn = (item.clockInStatus.toLowerCase() === 'Clocked In And Unscheduled'.toLowerCase()) ? 'unscheduled' : empData.ClockedIn;
                empData.ClockedInText = this.getClockInGroupText(empData.ClockedIn); 
                this.clockInDetails.push(empData);
            });
            this.generateGridData();
            this.isShowClockInContent = true;
            this.isShowClockInMessage = false;
            var that = this;
            
            setTimeout(() =>{
                that.setGroupingColumnStyles();
            }, 500);
        }
        else{
            this.isShowClockInContent = false;
            this.isShowClockInMessage = true;
        }
    }

    getClockInGroupText(clockInStatus: any){
        var groupingtext: string = ''
            switch (clockInStatus) {
                case "No":
                    groupingtext = 'NOT CLOCKED IN';
                    break;
                case "unscheduled":
                    groupingtext = 'CLOCKED IN & UNSCHEDULED';
                    break;
                case "Yes":
                    groupingtext = 'CLOCKED IN'
                    break;
            }
        return groupingtext;
    }

    getformattedTime(kronosExtracted: any) {
        if (kronosExtracted !== null && kronosExtracted !== '' && kronosExtracted !== undefined) {
            if (kronosExtracted.length >= 16) {
                if (Date.parse(kronosExtracted.substring(0, 16))) {
                    return kronosExtracted.substring(0, 16);
                }
            }
        }
        return kronosExtracted;
    }

    generateGridData(){
        if(this.clockInDetails != null && this.clockInDetails.length > 0){
            this.clockInDataList = [];

            //@ts-ignore
            var distinctClockInStatus: any = jslinq(this.clockInDetails)
                    .select((x: any)=> x.ClockedInText)
                    .distinct()
                    .toList();

            if(distinctClockInStatus != null && distinctClockInStatus.length > 0){
                distinctClockInStatus.sort((a: any, b: any) => {
                    return this.groupingTextOrder.indexOf(a) - this.groupingTextOrder.indexOf(b)
                });
                
                distinctClockInStatus.forEach((item: any)=>{
                    var clockData: any = {};

                    clockData.ClockedInGroupText = item;

                    //@ts-ignore
                    var clockInList: any = jslinq(this.clockInDetails)
                    .where((data: any) => { 
                        if(data.ClockedInText == item){
                            return data;
                        }})
                    .toList();

                    if(clockInList != null && clockInList.length > 0){
                        clockData.ClockInList = clockInList;
                    }

                    this.clockInDataList.push(clockData);
                });
            }
        }
    }

    setGroupingColumnStyles(){
        if(this.clockInDetails != null && this.clockInDetails.length > 0){
            var deptColLength: number = document.getElementsByClassName('clockInStatusGroupRow').length;
            
            if(deptColLength > 0){
                    for(let i=0; i<deptColLength; i++){
                        //@ts-ignore
                        document.getElementsByClassName('clockInStatusGroupRow')[i].firstElementChild.style.width = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('clockInStatusGroupRow')[i].firstElementChild.style.minWidth = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('clockInStatusGroupRow')[i].firstElementChild.style.maxWidth = '100%';
                    }
            }
        }
    }

    showLoader(){
        this.isShowLoader = true;
        this.isShowClockInContent = false;
        this.isShowClockInMessage = false;
    }

    hideLoader(){
        this.isShowLoader = false;
    }

    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
#hrClockInStatusLine {
    border-top: 1px solid black;
    margin-top: 5px;
    margin-bottom: 6px;
}
#spnDeptFacilityName {
    font-weight: bold;
    color: #15202E;
    font-size: 1rem;
}
#spnClockInStatusSecondHeader {
    font-family: MarkforHCA-Book;
    font-size: 0.80rem;
    color: #15202E;
}
.c4c-modal {
    width: 50%;
    height: 75%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}

.c4c-modal{
    line-height: 1.5;
}
.panleBody{
    padding: 5px 12px;
    height:85%;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}

#gridClockInDetails{
    height: 100%;
    overflow: auto;
}

.clockInStatusGroupRow {
    font-family: MarkforHCA-Bold;
    color: #15202E;
    font-size: 13.334px;
    background-color: #D3D3D3;
    border: 0.7px solid gray !important;
    padding-bottom: 0;
}

.headerTitleContainer{
    float: left;
    display: inline-flex;
    margin-right: 10px;
}

.headerActions{
    display: inline-flex;
    align-items: center;
}
</style>