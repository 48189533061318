


require('popper.js');
require("bootstrap");
import { createApp } from "vue";
import App from './App.vue';
import store from "./store";
import router from "./router";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { NeutronVue } from '@neutron/vue';
import mitt from 'mitt';
// import BootstrapVue from "bootstrap-vue";

//Add all icons to the library so you can use it in your pag
library.add(fas, far, fab);
const emitter = mitt();

const app = createApp(App).use(NeutronVue);
app.component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.$store = store;
app.config.globalProperties.emitter = emitter;
app.use(router).mount('#app');