<template>
    <div class="modalBackdrop flex absolute z-max">
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Compose Message</span>
                <a href="#" class="closeBtn neu-tooltip" @click="close">
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'close']"
                                           size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody">
                <div id="composeSMS-details" class="panelTabData scrollBar txtLeft" role="tabpanel">
                    <div id="sms-dialog" style="font-size:13px">
                        <div class="row rowSms mb-3">
                            <div class="col">
                                <neu-label for="sms-template" class="text-uppercase">sms template</neu-label>
                                <select name="ddlSmsTemplate" :class="['neu-select']" v-model="selectedTemplate" @change="refreshTemplate()" placeholder="Select Notification">
                                    <option v-for="smsTemp in smsTemplates" :value="smsTemp.notificationTemplateId" :key="smsTemp.notificationTemplateId">
                                        {{smsTemp.name}}
                                    </option>
                                </select>
                                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                            </div>
                            <div class="col">
                                <neu-label for="sms-skill-deficit" class="text-uppercase">skill needed</neu-label>
                                <select name="ddlSkillNeeded" :class="['neu-select']" v-model="selectedSkill" @change="onSkillChange()" placeholder="Select skill">
                                    <option v-for="skill in skills" :value="skill.skillLevelId" :key="skill.skillLevelId">
                                        {{skill.description}}
                                    </option>
                                </select>
                                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                            </div>
                            <div class="col">
                                <neu-label for="sms-shift" class="text-uppercase">shift</neu-label>
                                <select name="ddlShift" :class="['neu-select']" v-model="selectedShift" @change="refreshTemplate()" placeholder="Select Shift">
                                    <option v-for="shift in shifts" :value="shift.deptShiftId" :key="shift.deptShiftId">
                                        {{shift.shiftText}}
                                    </option>
                                </select>
                                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                            </div>
                        </div>
                        <div class="row rowSms mb-3">
                            <div class="col">
                                <neu-label for="sms-recipients" class="text-uppercase">RECIPIENTS</neu-label>
                                <VueMultiselect v-model="selectedEnrolledRecipients"
                                                :options="enrolledRecipients"
                                                :multiple="true"
                                                :searchable="true"
                                                :close-on-select="true"
                                                @update:modelValue="enableSmsButton"
                                                placeholder="Select Recipients"
                                                label="name"
                                                track-by="name"
                                                id="selEnrolledRecipients">
                                </VueMultiselect>
                            </div>
                        </div>
                        <div class="row rowSms mb-3">
                            <div class="col">
                                <neu-label for="sms-free-text" class="text-uppercase">ADD COMMENTS</neu-label>
                                <span id="sms-free-text-remaining" style="font-size: x-small">{{smsFreeTextRemaining}} </span>
                                <input class="neu-input__field" id="sms-free-text" v-model="freeText" maxlength="100" type="text" autocomplete="off" style="min-height: 2.5rem !important;"
                                       @change="onFreeTextChange" :disabled="isSMSFreeTextDisabled" />
                            </div>
                        </div>
                        <div class="row rowSms mb-3">
                            <div class="col sms-non-enrolled-recipient">
                                <neu-label for="sms-non-enrolled-recipient" class="text-uppercase">NOT ENROLLED/UNSUBSCRIBED</neu-label>
                                <!--<select class="form-control" id="sms-non-enrolled-recipient" multiple="multiple" placeholder="" disabled
                                        data-text-field="Name" data-value-field="StaffId"
                                        data-bind="source:unenrolledRecipients, value:selectedUnenrolledRecipients">
                                </select>-->

                                <VueMultiselect v-model="selectedUnenrolledRecipients"
                                                :options="unenrolledRecipients"
                                                :multiple="true"
                                                :searchable="true"
                                                :close-on-select="true"
                                                placeholder=""
                                                label="name"
                                                track-by="name"
                                                id="selUnenrolledRecipients"
                                                disabled>
                                </VueMultiselect>
                            </div>
                        </div>
                        <div class="row rowSms mb-3">
                            <div class="col">
                                <label for="sms-preview-msg" class="text-uppercase">SMS PREVIEW</label>
                                <textarea class="form-control" v-model="parsedSmsTemplate" id="sms-preview-msg" rows="3" disabled></textarea>
                            </div>
                        </div>
                        <div class="row rowSms mb-3">
                            <div class="col">
                                <!--<button id="sendSmsMessage" type="button" class="btn btn-primary float-right" @click="onSendSms">SEND SMS</button>-->
                                <neu-button type="button" fill="raised" class="float-right" @click="isSendSMSDisabled ? null : onSendSms()"
                                 :disabled="isSendSMSDisabled">
                                    SEND SMS
                                </neu-button>
                            </div>
                        </div>
                        <div id="modalLoadingDiv" class="loadingDiv1" style="padding: 5px; display: none;">
                            <div id="actionable-content-loading" style="text-align: center">
                                <div class="fa-2x">
                                    <i class="fas fa-circle-notch fa-spin"></i>
                                    <i aria-hidden="true">
                                        <font-awesome-icon :icon="['fas', 'filter']" size="lg" />
                                    </i>
                                </div>
                                <p><b>Loading...</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isToasterVisible">
        <ToasterPopup @closeModal="closeToasterPopup"
                      :msgValue="msgValue"
                      :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
    import { Options, Vue } from 'vue-class-component';
    //@ts-ignore
    import VueMultiselect from 'vue-multiselect';
    import jslinq from "jslinq";
    import moment from "moment";
    import { mapState } from "vuex";

    //Import Componenets
    import {
        NeuLabel,
        NeuButton
    } from "@neutron/vue";
    import { Profile } from '@/models/profile/index';
    import { useAppInsights } from '../../../store/modules/AppInsights';
    import ToasterPopup from "../../shared/ToasterPopup.vue";

    class Props {
        smsInput!: any
    }

    @Options({
        computed: {
            ...mapState('assignments', ['smsNotificationTemplates', 'shiftByDepartments', 'skillsByDepartment', 'smsNotificationStatus']),
            ...mapState('profile', ['profileData', 'appInsightEventData']),
        },
        components: {
            VueMultiselect: VueMultiselect,
            NeuButton,
            NeuLabel,
            ToasterPopup
        }
    })
    export default class SendSMSPopup extends Vue.with(Props) {
        smsNotificationTemplates!: any;
        shiftByDepartments!: any;
        skillsByDepartment!: any;
        smsNotificationStatus!: any;
        smsTemplates: any = [];
        selectedTemplate: any = null;
        skills: any = [];
        selectedSkill: any = null;
        shifts: any = [];
        selectedShift: any = null;
        parsedSmsTemplate: any = null;
        enrolledRecipients: any = [];
        selectedEnrolledRecipients: any = [];
        unenrolledRecipients: any = [];
        selectedUnenrolledRecipients: any = [];
        freeText: any = '';
        isSMSFreeTextDisabled: boolean = true;
        isSendSMSDisabled: boolean = true;
        smsFreeTextRemaining: any = '';
        sendSmsSucessMessage: any = 'Your SMS has been sent.';
        sendSmsFailedMessage: any = 'An error was found in attempting to send SMS notifications.';
        serverSideDateFormat: any = 'YYYY-MM-DD h:mm A';
        transportMediaId: any = 1;
        notificationEventIds: any = [4, 5];
        productiveShift: any = 1;
        freeTextMaxLength: any = 100;
        remainingCharacters: any = 'Remaining characters: ';

        departmentId: any = null;
        startDateTime: any = null;
        endDateTime: any = null;
        skillDeficitIds: any = null;
        unscheduledStaffs: any = [];
        facilityName: any = null;
        departmentName: any = null;
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        appInsightEventData!:any ;
        profileData!: Profile;
        facilityCoid :any = null;
        departmentCode :any = null;
        
        created()
        {
            this.facilityCoid = this.appInsightEventData.COID != undefined ?this.appInsightEventData.COID : ''  ; 
            this.departmentCode=this.appInsightEventData.DeptCode != undefined ?this.appInsightEventData.DeptCode : ''  ;  
        }

        refreshTemplate() {
            this.resetFreeText();
            this.renderSmsTemplate();
            this.refreshEnrolledRecipients();
            this.refreshUnenrolledRecipients();
            this.enableSmsButton();
        }

        onShiftChange() {
            this.resetFreeText();
            this.renderSmsTemplate();
            this.enableSmsButton();
        }
        onSkillChange() {
            this.resetFreeText();
            this.renderSmsTemplate();
            this.refreshEnrolledRecipients();
            this.refreshUnenrolledRecipients();
            this.enableSmsButton();
        }

        showRemainingCharacters() {
            this.smsFreeTextRemaining = (this.remainingCharacters + (this.freeTextMaxLength - this.freeText.length));
            this.buildTemplate();
        }

        async onSendSms() {
            if (this.isFormValid()) {
                var staffIds = this.selectedEnrolledRecipients.map(function (staff: any) {
                    return staff.staffId;
                });

                //@ts-ignore
                var selectedTemplate: any = jslinq(this.smsTemplates)
                    .where((sms: any) => {
                        if (sms.notificationTemplateId == this.selectedTemplate) {
                            return sms;
                        }
                    })
                    .toList();

                //@ts-ignore
                var shift: any = jslinq(this.shifts)
                    .where((shift: any) => {
                        if (shift.deptShiftId == this.selectedShift) {
                            return shift;
                        }
                    })
                    .toList();

                var shiftDates = this.buildShiftDates(this.smsInput.startDateTime, shift[0].startTime, shift[0].endTime);

                var smsMessage = {
                    DepartmentId: this.smsInput.departmentId,
                    DepartmentShiftId: this.selectedShift,
                    NotificationTemplateId: selectedTemplate[0].notificationTemplateId,
                    SkillLevelId: this.selectedSkill,
                    StartDateTime: shiftDates.shiftStartTime.format(this.serverSideDateFormat),
                    EndDateTime: shiftDates.shiftEndTime.format(this.serverSideDateFormat),
                    NotificationCode: selectedTemplate[0].notificationCode,
                    NotificationDetails: {
                        NotificationEventId: selectedTemplate[0].notificationEventId,
                        Message: this.parsedSmsTemplate,
                        StaffIds: staffIds
                    },
                    TenantId: this.profileData.TenantId
                };

                await this.$store
                    .dispatch("assignments/sendSmsNotifications", smsMessage)
                    .then(() => {
                        if (this.smsNotificationStatus) {
                            this.msgValue = this.sendSmsSucessMessage;
                            this.classFlag = "success";
                            this.isToasterVisible = true;
                            let vm = this;
                            setTimeout(function () { vm.close()}, 3000);

                            var eventName = "";
                            if (smsMessage.NotificationDetails.NotificationEventId == "5"){
                            
                                eventName = "DASHBOARD_SMS_STAFFING_NEED_CRITICAL_PAY";
                            }
                            else if (smsMessage.NotificationDetails.NotificationEventId == "4"){
                            
                                eventName = "DASHBOARD_SMS_STAFFING_NEED_IMMEDIATE";
                            }

                            useAppInsights().trackEvent({
                                name: eventName, properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                            });
                        }
                    })
                    .catch((err: any) => {
                        if (err) {

                            this.msgValue = this.sendSmsFailedMessage;
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                            this.close();
                        }
                    });
            }
        }

        isFormValid() {
            var skill = this.selectedSkill,
                template = this.selectedTemplate,
                shift = this.selectedShift,
                enrolledRecipients = this.selectedEnrolledRecipients;

            var regex = /{\w|\w}/;
            var invalidTempate = regex.test(this.parsedSmsTemplate);

            if (!invalidTempate && skill && shift && template && enrolledRecipients.length > 0) {
                return true;
            } else {
                return false;
            }
        }

        onFreeTextChange() {
            this.showRemainingCharacters();
            this.renderSmsTemplate();
            this.enableSmsButton();
        }

        resetFreeText() {
            this.smsFreeTextRemaining = this.remainingCharacters + this.freeTextMaxLength;
            this.freeText = '';
        }

        buildTemplate() {
            var templateReplacementOptions = [];

            if (this.smsInput.facilityName !== '') {
                templateReplacementOptions.push({ name: '{FACILITY}', value: this.smsInput.facilityName });
            }

            if (this.smsInput.departmentName !== '') {
                templateReplacementOptions.push({ name: '{DEPARTMENT}', value: this.smsInput.departmentName });
            }

            if (this.smsInput.startDateTime !== '') {
                templateReplacementOptions.push({ name: '{DATE}', value: moment(this.smsInput.startDateTime).format('MM/DD/YYYY') });
            }

            //@ts-ignore
            var skill: any = jslinq(this.skills)
                .where((skl: any) => {
                    if (skl.skillLevelId == this.selectedSkill) {
                        return skl;
                    }
                })
                .toList();

            if (skill != null && skill.length > 0) {
                if (skill[0].Description !== '') {
                    templateReplacementOptions.push({ name: '{SKILL}', value: skill[0].description });
                }
            }

            //@ts-ignore
            var shift: any = jslinq(this.shifts)
                .where((shift: any) => {
                    if (shift.deptShiftId == this.selectedShift) {
                        return shift;
                    }
                })
                .toList();

            if (shift != null && shift.length > 0) {
                if (shift[0].shiftText !== '') {
                    templateReplacementOptions.push({ name: '{SHIFT}', value: shift[0].shiftText });
                }
            }

            var freeText = this.freeText;
            if (freeText && freeText.length > 0) {
                templateReplacementOptions.push({ name: '{FREETEXT}', value: freeText });
            }

            return templateReplacementOptions;
        }

        renderSmsTemplate() {
            //@ts-ignore
            var selectedTemplate: any = jslinq(this.smsTemplates)
                .where((sms: any) => {
                    if (sms.notificationTemplateId == this.selectedTemplate) {
                        return sms;
                    }
                })
                .toList();

            if (selectedTemplate != null && selectedTemplate.length > 0) {
                var templateReplacementOptions = this.buildTemplate();
                var parsedTemplate = this.parseSmsTemplate(selectedTemplate[0].template, templateReplacementOptions);
                this.parsedSmsTemplate = parsedTemplate;
                this.isSMSFreeTextDisabled = false;
            } else {
                this.resetFreeText();
                this.isSMSFreeTextDisabled = true;
            }
        }

        parseSmsTemplate(templateText: any, optionsArray: any) {
            var result = templateText;
            var freeTextData = '';

            optionsArray.forEach(function (option: any) {
                var re = new RegExp(option.name, "gi");
                result = result.replace(re, option.value);
                if (option.name === '{FREETEXT}') {
                    freeTextData = option.value;
                }
            });

            if (freeTextData.length > 0) {
                var freeTextInsertPos = result.indexOf("STOP");
                var resultPart1 = result.substring(0, freeTextInsertPos);
                var resultPart3 = result.substring(freeTextInsertPos);
                result = resultPart1 + freeTextData + ' ' + resultPart3;
            }
            return result;
        }

        enableSmsButton() {
            if (this.isFormValid()) {
                this.isSendSMSDisabled = false;
            } else {
                this.isSendSMSDisabled = true;
            }
        }

        isStaffEnrolled(staff: any) {
            var isValid = staff.staffAlertSubscriptions
                && staff.staffAlertSubscriptions.isMobileValidated
                && staff.staffAlertSubscriptions.isUserOptedIn;
            return isValid;
        }

        refreshEnrolledRecipients() {
            var unscheduledStaffs = Object.assign([], this.unscheduledStaffs),
                enrolledStaffs: any[] = [];

            //@ts-ignore
            var selectedTemplate: any = jslinq(this.smsTemplates)
                .where((sms: any) => {
                    if (sms.notificationTemplateId == this.selectedTemplate) {
                        return sms;
                    }
                })
                .toList();

            if (unscheduledStaffs && unscheduledStaffs.length > 0 && this.selectedSkill && this.selectedTemplate) {
                var thisVar = this;
                unscheduledStaffs.forEach(function (staff: any) {
                    var isValid = thisVar.isStaffEnrolled(staff);
                    var matchedSkill = staff.skillLevelIds.indexOf(thisVar.selectedSkill);
                    if (isValid && matchedSkill >= 0) {
                        var isRegisteredForEvent = [];
                        if (staff.staffAlertSubscriptions.staffSubscriptions) {
                            isRegisteredForEvent = staff.staffAlertSubscriptions.staffSubscriptions.filter(
                                function (x: any) {
                                    return x.notificationEventId === selectedTemplate[0].notificationEventId;
                                });
                        }
                    }
                    if (matchedSkill >= 0 && isValid && isRegisteredForEvent.length > 0) {
                        enrolledStaffs.push(staff);
                    }
                });
            }
            this.enrolledRecipients = enrolledStaffs;
            this.selectedEnrolledRecipients = Object.assign([], enrolledStaffs);
            this.removeCloseButtonInMultiSelect(this.unenrolledRecipients);
            return enrolledStaffs;
        }

        refreshUnenrolledRecipients() {
            var unscheduledStaffs = Object.assign([], this.unscheduledStaffs),
                unenrolledStaffs: any[] = [];

            //@ts-ignore
            var selectedTemplate: any = jslinq(this.smsTemplates)
                .where((sms: any) => {
                    if (sms.notificationTemplateId == this.selectedTemplate) {
                        return sms;
                    }
                })
                .toList();

            if (unscheduledStaffs && unscheduledStaffs.length > 0 && this.selectedSkill && this.selectedTemplate) {
                var thisVar = this;
                unscheduledStaffs.forEach(function (staff: any) {
                    var isValid = thisVar.isStaffEnrolled(staff);
                    var matchedSkill = staff.skillLevelIds.indexOf(thisVar.selectedSkill);
                    if (matchedSkill >= 0) {
                        var isRegisteredForEvent = [];
                        if (staff.staffAlertSubscriptions.staffSubscriptions) {
                            isRegisteredForEvent = staff.staffAlertSubscriptions.staffSubscriptions.filter(function (subscription: any) {
                                return subscription.notificationEventId === selectedTemplate[0].notificationEventId;
                            });
                        }
                        if (matchedSkill >= 0 && isRegisteredForEvent.length === 0) {
                            unenrolledStaffs.push(staff);
                        }
                    }
                });
            }
            this.unenrolledRecipients = unenrolledStaffs;
            this.selectedUnenrolledRecipients = unenrolledStaffs;
            this.removeCloseButtonInMultiSelect(this.unenrolledRecipients);
            return unenrolledStaffs;
        }

        resetDialog() {
            this.smsTemplates = [];
            this.selectedTemplate = null;
            this.shifts = [];
            this.selectedShift = null;
            this.skills = [];
            this.selectedSkill = null;
            this.parsedSmsTemplate = null;
            this.enrolledRecipients = [];
            this.selectedEnrolledRecipients = [];
            this.selectedUnenrolledRecipients = [];
            this.enrolledRecipients = [];
            this.unenrolledRecipients = [];
            this.isSendSMSDisabled = true;
            this.resetFreeText();
        }

        filterShiftsBySummary(allShifts: any) {
            var shiftSummaryStart = moment(this.smsInput.startDateTime);
            var shiftSummaryEnd = moment(this.smsInput.endDateTime);
            var summaryStartDate = moment(this.smsInput.startDateTime).format('YYYY-MM-DD');
            var thisVar = this;
            var filteredShifts = allShifts.filter(function (shift: any) {

                if (shift.productiveId === thisVar.productiveShift) {
                    var shiftStartTime = moment(shift.startTime).format('h:mm a');
                    var formattedStartTime = moment(summaryStartDate + ' ' + shiftStartTime, 'YYYY-MM-DD hh:mm A');
                    var duration = moment(shift.endTime).diff(moment(shift.startTime), 'minutes');
                    var formattedEndTime = moment(summaryStartDate + ' ' + shiftStartTime, 'YYYY-MM-DD hh:mm A');
                    formattedEndTime.add(duration, 'minutes');

                    if (formattedStartTime.isSameOrAfter(shiftSummaryStart)
                        && formattedStartTime.isBefore(shiftSummaryEnd)
                        && formattedEndTime.isAfter(moment())) {
                        return true;
                    }
                }
            });

            var updatedShifts = filteredShifts.filter(function (item: any, index: any) {
                item.shiftText = item.code.trim() + ' ' + moment(item.startTime).format('h:mm A') + ' to ' + moment(item.endTime).format('h:mm A');
                return item;
            });

            return updatedShifts;
        }

        filterUnscheduledStaffs(unscheduledStaffs: any, selectedSkillId: any) {
            return unscheduledStaffs.filter(function (staff: any) {
                return staff.SkillLevelId === selectedSkillId;
            });
        }

        buildShiftDates(summaryDate: any, shiftStartDateTime: any, shiftEndDateTime: any) {
            var summaryStartDate = moment(summaryDate).format('YYYY-MM-DD');
            var shiftStartTime = moment(shiftStartDateTime).format('h:mm a');
            var formattedStartTime = moment(summaryStartDate + ' ' + shiftStartTime, this.serverSideDateFormat);
            var duration = moment(shiftEndDateTime).diff(moment(shiftStartDateTime), 'minutes');
            var formattedEndTime = moment(summaryStartDate + ' ' + shiftStartTime, this.serverSideDateFormat);
            formattedEndTime.add(duration, 'minutes');
            return {
                shiftStartTime: formattedStartTime,
                shiftEndTime: formattedEndTime
            };
        }

        filterDepartmentSkills(allShifts: any) {
            var thisVar = this;
            var filteredSkills = allShifts.filter(function (skill: any) {
                var result = thisVar.smsInput.skillDeficitIds.filter(function (item: any) {
                    return skill.skillLevelId === item;
                });
                if (result && result.length > 0) {
                    return true;
                }
            });
            return filteredSkills;
        }

        removeCloseButtonInMultiSelect(elementId: any) {
            //$('.' + elementId + ' .k-multiselect-wrap span.k-select').hide();
        }

        //Lifecycle hooks
        mounted() {
            this.loadComposeMessageDetails();
            this.updateAppInsights();
        }

        async updateAppInsights(){
            var inputData =  {
                    Username:  this.profileData.UserName,
                    TenantName:  this.profileData.TenantName,
                    FacilityName: this.smsInput.facilityName,
                    COID: this.facilityCoid,            
                    DeptCode: this.departmentCode ,            
                    DeptName: this.smsInput.departmentName 
                };
                await this.$store
                    .dispatch("profile/updateAppInsights", inputData)
                    .then(() => {
                        
                        })
                    .catch((err: any) => {
                        if (err) { 
                            console.error(err); // Handle errors any way you want
                        }
                    });
            }

        loadComposeMessageDetails() {
            this.resetDialog();
            this.getSMSNotificationTemplate();
            this.getShiftByDepartmentsAndShiftSummaries();
            this.getSkillsByDepartment();

            var unscheduledStaffs = Object.assign([], this.smsInput.unscheduledStaffs);
            this.unscheduledStaffs = unscheduledStaffs;

            this.smsFreeTextRemaining = this.remainingCharacters + this.freeTextMaxLength;
            //var dialog = $('#' + controls.dialogId);
            //dialog.data("kendoWindow").center().open();

            //kendo.bind(dialog, viewModel);

            this.removeCloseButtonInMultiSelect(this.unenrolledRecipients);
        }

        async getSMSNotificationTemplate() {
            var inputData: any = {
                transportMediaId: this.transportMediaId,
                eventIds: this.notificationEventIds
            };
            await this.$store
                .dispatch("assignments/getSMSNotificationTemplates", inputData)
                .then(() => {
                    if (this.smsNotificationTemplates != null) {
                        this.smsTemplates = this.smsNotificationTemplates;
                        //hideLoadingModal();
                    }
                })
                .catch((err: any) => {
                    if (err) {

                        //  hideLoadingModal();
                        // toastr.error('Error retrieving SMS Templates');
                    }
                });
        }

        async getShiftByDepartmentsAndShiftSummaries() {
            var dateVal = moment(this.smsInput.startDateTime).format('YYYY-MM-DD');
            var inputData: any = {
                departmentId: this.smsInput.departmentId,
                dateVal: dateVal
            };
            await this.$store
                .dispatch("assignments/getShiftByDepartmentsAndShiftSummaries", inputData)
                .then(() => {
                    if (this.shiftByDepartments != null) {
                        var filteredShifts = this.filterShiftsBySummary(this.shiftByDepartments);
                        this.shifts = filteredShifts;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        // toastr.error('Error retrieving departments shifts');
                    }
                });
        }

        async getSkillsByDepartment() {
            var dateVal = moment(this.smsInput.startDateTime).format('YYYY-MM-DD');
            var inputData: any = {
                departmentId: this.smsInput.departmentId,
                dateVal: dateVal
            };
            await this.$store
                .dispatch("assignments/getSkillsByDepartment", inputData)
                .then(() => {
                    if (this.skillsByDepartment != null) {
                        var filteredSkills = this.filterDepartmentSkills(this.skillsByDepartment);
                        this.skills = filteredSkills;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        // toastr.error('Error retrieving skill deficits');
                    }
                });
        }

        close() {
            this.$emit("close-modal");
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }
    }
</script>
<style scoped>
    .c4c-modal {
        width: 60%;
        height: 78%;
        overflow: hidden;
        font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
        background: white;
        box-shadow: 0px 16px 30px 0px;
    }

    .panleBody {
        padding: 5px 12px;
        height: 90%;
    }

    .panelTabData {
        height: 100%;
        overflow: auto;
        word-break: break-all;
    }

    .red {
        color: red;
    }

    .paddingBottom-10 {
        padding-bottom: 10px;
    }

    #facilityAndDept {
        text-align: left;
    }

    .neu-input__select-icon {
        display: block;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        top: 1.4rem;
        right: 20px;
        background-color: transparent;
        transition: 0.5s ease-in-out;
        pointer-events: none;
    }

    .neu-input__select-icon_sms-recipients {
        display: block;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        top: 1.4rem;
        right: 1.5rem;
        background-color: transparent;
        transition: 0.5s ease-in-out;
        pointer-events: none;
    }

    .rowSms {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
</style>