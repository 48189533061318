import axios from 'axios'
import { UserModel } from '@/models/scheduleManagement'

export async function getUser(): Promise<UserModel> {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BASE_API_URL}/Users`)
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getSecurityAccount(): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/Security/Account`
    )
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getFacilities(tenantId: String): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/Facilities/${tenantId}/GetAllFacilitiesKvp`
    )
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getDepartmentsByFacilityKvp(
  tenantId: String,
  facilityId: string
): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/Departments/${tenantId}/GetDepartmentsByFacilityKvp/${facilityId}`
    )
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getSchedulesByDepartmentId(
  departmentId: String,
  tenantId: string
): Promise<any> {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/Helper/${tenantId}/Department/${departmentId}/Schedules`
    )
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}
