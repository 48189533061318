<template>
    <div class="modalBackdrop flex absolute popUpZIndex">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Contract Labor Management</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="contractLaborManagementModal" class="panleBody scrollBar border">
                <div v-if="isShowTabList">
                    <neu-tablist :value="selectedTab" class="mx-4 tabList">
                        <neu-tab
                            white
                            tab="SingleDay" 
                            @click="setSelectedTab('SingleDay')">
                            <div>Single Day </div>
                        </neu-tab>
                        <neu-tab
                            white
                            tab="MultiDay"
                            @click="setSelectedTab('MultiDay')">
                            <div>Multi Day </div>
                        </neu-tab>
                    </neu-tablist> 
                    <div id="contractLaborRequestSingleDayWrapper" class="panelTabData scrollBar" v-if="isSingleDayVisible" role="tabpanel">
                        <SingleDay :singleDayInput="tabInput"/>
                    </div>
                    <div id="contractlaborManagementRequestMultiDayWrapper" class="panelTabData scrollBar" v-if="isMultiDayVisible" role="tabpanel">
                        <MultiDay :multiDayInput="tabInput"/>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isToasterVisible"> 
            <ToasterPopup @closeModal="closeToasterPopup"
                            :msgValue="msgValue"
                            :classFlag="classFlag"></ToasterPopup>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import {
  NeuTab,
  NeuTablist
} from "@neutron/vue";
import SingleDay from './SingleDay.vue';
import MultiDay from './MultiDay.vue';
import ToasterPopup from '../shared/ToasterPopup.vue';

class Props {
    contractLaborInput!: any 
}

@Options({
  computed: {
    ...mapState('shared', ['facilitiesByUser', 'departmentsByUser']),    
  },
  components: {
    NeuTab,
    NeuTablist,
    SingleDay,
    MultiDay,
    ToasterPopup
  }
}) 
export default class ContractLaborManagement extends Vue.with(Props) {
    //Data properties
    facilitiesByUser!: any;
    departmentsByUser!: any;

    facilities: any = [];
    allDepartments: any = [];
    tabInput: any = null;
    isShowTabList: boolean = false;
    selectedTab: string = 'SingleDay';
    isSingleDayVisible: boolean = true; 
    isMultiDayVisible: boolean = false;
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;

    //Lifecycle hooks
    async mounted(){
        await this.loadContractLaborTabInput()
    }
    //Methods
    async loadContractLaborTabInput(){
        await this.loadFacilities();
        await this.loadDepartments();
        this.tabInput = {
            userId: this.contractLaborInput.userId,
            facilities: this.facilities,
            departments: this.allDepartments,
            contractLaborUsingKafkaEnabled: this.contractLaborInput.contractLaborUsingKafkaEnabled
        }
        this.isShowTabList = true;
    }
    setSelectedTab(tab: string){
        this.selectedTab = tab;
        this.isSingleDayVisible = false; 
        this.isMultiDayVisible = false;
        
        switch (this.selectedTab) {
            case 'SingleDay':  
                this.isSingleDayVisible = true;
                break;
            case 'MultiDay':
                this.isMultiDayVisible = true;
                break;
        }
    }

    async loadFacilities(){
        var inputData = {
            UserId: this.contractLaborInput.userId,
            TenantId: this.contractLaborInput.tenantId
        }
        await this.$store
            .dispatch("shared/getFacilitiesByUser", inputData)
            .then(() => {
                if(this.facilitiesByUser != null && this.facilitiesByUser.length > 0){
                    var facilitiesByUser = this.facilitiesByUser;
                    var facilityIds = this.contractLaborInput.facilities.map((facility: any) => { return +facility.Value; });
                    var facilities: any = [];
                    facilitiesByUser = this.sortBy(this.facilitiesByUser, "name", "asc");
                    facilitiesByUser.forEach((facility: any) => {
                        if (facilityIds.indexOf(+facility.facilityId) >= 0) {
                            facilities.push({
                                FacilityId: facility.facilityId,
                                Text: facility.name + ' - ' + facility.coid,
                                Value: facility.facilityId,
                                Coid: facility.coid
                            });
                        }
                    });
                    this.facilities = facilities;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Facility.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadDepartments(){
        var inputData = {
            UserId: this.contractLaborInput.userId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                if(this.departmentsByUser != null && this.departmentsByUser.length > 0){
                    var finalDepartments: any = [];
                    var allDepartmentsLookup: any = {};

                    var departmentIds = this.contractLaborInput.departments.map((department: any) => { return +department.Value; });
                    var departmentsByUser = this.departmentsByUser;

                    //Sorting based on dept code
                    this.contractLaborInput.departments.forEach((department: any) => {
                        allDepartmentsLookup[department.Value] = {
                            clusterId: department.ParentValue
                        };
                    });

                    departmentsByUser.forEach((department: any) => {
                        var departmentFound = allDepartmentsLookup[+department.DepatmentId];
                        if (departmentFound) {
                            finalDepartments.push({
                                FacilityId: department.FacilityId,
                                Text: department.DepartmentCode + ' - ' + department.DepartmentName,
                                Value: department.DepatmentId,
                                DepartmentCode: department.DepartmentCode,
                                ClusterId: departmentFound.clusterId,
                                AllowManualClRequests: department.AllowManualContractLaborRequests
                            });
                        }
                    });

                    this.allDepartments = finalDepartments;
                }
            })
            .catch((err: any) => {
                if (err) {
                     var errorData = {
                            message: "An error occured while retrieving Department.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 90%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    margin: 5px;
    padding: 5px 12px;
    height:90%;
    overflow: auto;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}
.popUpZIndex{
    z-index: 2147483639;
}
</style>