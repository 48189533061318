
<template>
                  <a href="#" class="modaltoolbar neu-tooltip" v-if="isManageCensusEnabled" @click="loadManageCensusPopup">
                        <i aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'users']" size="lg" />
                        </i>
                        <span class="neu-tooltip--text tooltipTxt">Manage Census</span>
                    </a>
                    <a href="#"  @click="showUserSMSNotificationsPopup"
                       class="modaltoolbar neu-tooltip">
                        <i aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'bell']" size="lg" />
                        </i>
                        <span id="sms-notifications-count" class="badge notification-count" v-show="isShowNotifictionBadge == true && notificationCount > 0">{{notificationCount}}</span>
                        <span class="neu-tooltip--text tooltipTxt">SMS Notifications</span>
                    </a>
                    <a class="modaltoolbar neu-tooltip"
                       :href="fsRedirectionLink" target="_blank"
                       style="cursor: pointer">
                        <i aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'home']" size="lg" />
                        </i>
                        <span class="neu-tooltip--text tooltipTxt">Go to Facility Scheduler</span>
                    </a>
               
                    <a href="#" class="modaltoolbar neu-tooltip"  v-if="isShowContractLaborManagementModalIcon" @click="showContractLaborPopup">
                        <i aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'address-card']"
                                               size="lg" />
                        </i>
                        <span class="neu-tooltip--text tooltipTxt">Contract Labor Management</span>
                    </a>
               
                    <a href="#"  @click="showUserPreferencesPopup"
                       class="modaltoolbar neu-tooltip"
                       style="cursor: pointer">
                        <i aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'user-circle']" size="lg" />
                        </i>
                        <span class="neu-tooltip--text tooltipTxt">Preferences</span>
                    </a>
                    <a href="#"  v-if="isShowSimpleLifecyleReportingModalIcon" @click="showWorkforceRequestStatusPopup"
                       class="modaltoolbar neu-tooltip"
                       style="cursor: pointer">
                        <i aria-hidden="true">
                            <font-awesome-icon :icon="['fas', 'cogs']" size="lg" />
                        </i>
                        <span class="neu-tooltip--text tooltipTxt">Workforce Request Status</span>
                    </a>
                                   
</template>


<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";

//import model and enum
import { Profile } from '@/models/profile/index';
    import moment from "moment";
    import { NotificationActionTypes } from "@/enums/enum";

//Import Componenets
import {
  NeuTab,
  NeuTablist
} from "@neutron/vue";
import SMSNotifications from '../smsnotifications/SMSNotifications.vue'
import ToasterPopup from "../shared/ToasterPopup.vue";

@Options({
  computed: {
        ...mapState('profile', ['profileData']),
        ...mapState('shared', ['departmentsByUser']),
        ...mapState('assignments', ['notificationData']),
  },
  components: {
    NeuTab,
    NeuTablist,
      SMSNotifications,
      ToasterPopup
  }
})
//@ts-ignore
export default class DashboardNavbar extends Vue {
  //Data properties
    profileData!: Profile;
    departmentsByUser!: any;
    notificationData!: any;
  isShowContractLaborManagementModalIcon: boolean = false;
  isShowSimpleLifecyleReportingModalIcon: boolean = false;
  isManageCensusEnabled: boolean = false;
  isSMSNotification: boolean = false;
  fsRedirectionLink: string = "";
    shortDateTimeFormat: any = 'YYYY-MM-DDTHH:mm:ss';
    isShowNotifictionBadge: any = false;
    notificationCount: any = 0;
    colors: any = {
        red: 'red',
        green: 'green',
        yellow: 'yellow',
        black: 'black',
        white: 'white'
    };

 
  
 

  async refreshNotificationCount() {
        await this.loadDepartments();
    }

    async loadDepartments() {
        var inputData = {
            UserId: this.profileData.UserId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                var departmentIds = [];
                if (this.departmentsByUser != null && this.departmentsByUser.length > 0) {
                    departmentIds = this.departmentsByUser.map(function (dept: any) { return +dept.DepatmentId; });
                }
                var notificationRequest = {
                    departmentIds: departmentIds,
                    ResponseReceivedStartDateTimeUtc: moment().utc().subtract(2, 'days'),
                    ResponseReceivedEndDateTimeUtc: moment().utc().add(30, 'days'),
                    TenantId: this.profileData.TenantId
                };

                this.getSmsNotifications(notificationRequest);
            })
            .catch((err: any) => {
               
            });
    }

    async getSmsNotifications(notificationRequest: any) {
        await this.$store
            .dispatch("assignments/getSmsNotificationResponses", notificationRequest)
            .then(() => {
                if (this.notificationData != null && this.notificationData.length > 0) {
                    var modifiedData = [];
                    modifiedData = this.buildNotificationsData(this.notificationData);
                    this.displayNotificationBadge(modifiedData);
                }
            })
            .catch((err: any) => {
               
            });
    }

    buildNotificationsData(result: any) {
        var results: any = [];
        var counter = 0;
        var that = this;
        result.forEach(function (item: any) {
            item.details.forEach(function (detail: any) {
                detail.staffResponses.forEach(function (response: any) {
                    var clone = Object.assign({},
                        that.removeCollectionProps(item),
                        that.removeCollectionProps(detail),
                        response);
                    clone.responseDateTime = moment.utc(clone.receivedTimeUtc).local().format(that.shortDateTimeFormat);
                    //clone.responseText = clone.responseText;
                    clone.rowUniqueId = counter++;
                    //clone.group = that.buildTemplateSkillShiftGroupingHeader(clone);
                    clone.staffName = detail.firstName + ' ' + detail.lastName;
                    clone.staffId = detail.staffId;
                    clone.phoneNumber = detail.phoneNumber;
                    clone.department = item.departmentName + ' ' + '(' + item.facilityName + ')';
                    results.push(clone);
                });
            });
        });
        return results;
    }

    removeCollectionProps(obj: any) {
        var resultObj: any = {};
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop) && !Array.isArray(obj[prop])) {
                resultObj[prop] = obj[prop];
            }
        }
        return resultObj;
    }

    displayNotificationBadge(smsNotifications: any) {
        var smsPendingNotifications = this.getPendingSmsNotifications(smsNotifications);
        if (smsPendingNotifications.length > 0) {
            this.isShowNotifictionBadge = true;
            this.notificationCount = smsPendingNotifications.length;
            var lastestNotificationReceived = smsPendingNotifications.reduce(function (prev: any, curr: any) {
                if (prev) {
                    return prev.responseDateTime < curr.responseDateTime ? prev.responseDateTime : curr.responseDateTime;
                } else {
                    return curr.responseDateTime;
                }
            }, null);

            var duration = moment().diff(moment(lastestNotificationReceived), 'seconds');
            var backgroundColor: any = this.colors.green;
            var color: any;            
            
            var minutesSinceLastNotification = duration / 60;
            if (minutesSinceLastNotification > 5 && minutesSinceLastNotification <= 10) {
                backgroundColor = this.colors.yellow;
                color = this.colors.black;
            } else if (minutesSinceLastNotification > 10) {
                backgroundColor = this.colors.red;
                color = this.colors.white;
            }
            
            var badgeElement: any = document.getElementById('sms-notifications-count');
            if (badgeElement != null) {
                badgeElement.style.backgroundColor = backgroundColor;
                badgeElement.style.color = color;
            }
        }
        else {
            this.isShowNotifictionBadge = false;
        }
    }

    getPendingSmsNotifications(smsNotifications: any) {
        var that = this;
        return smsNotifications.filter(function (notification: any) {
            var hasShiftEnded = that.hasShiftEnded(notification.shiftStartDateTime, notification.shiftEndDateTime);
            return notification.action === NotificationActionTypes.received && !hasShiftEnded;
        });
    }

    hasShiftEnded(shiftStartDate: any, shiftEndDate: any) {
        var shiftEnd = moment(shiftEndDate);
        var effectiveDate = moment();
        return effectiveDate.isSameOrAfter(shiftEnd);
    }

  showWorkforceRequestStatusPopup(){
    this.emitter.emit('loadWorkforceRequestStatusPopup');
  }
  showContractLaborPopup(){
    this.emitter.emit('loadContractLaborPopup');
  }
  showUserPreferencesPopup(){
    this.emitter.emit('loadUserPreferencesPopup');
  }
  showAggregatedSkillDashboard(){
    this.emitter.emit('loadAggregatedSkillDashboard');
  }
  show7DayView(){
    this.emitter.emit('load7DayStaffingView');
  }
  scheduleManagementView(){
    this.emitter.emit('loadScheduleManagementView');
  }

  showUserSMSNotificationsPopup(){
    this.emitter.emit('loadUserSMSNotificationsPopup');
  }
  
  loadManageCensusPopup(){
    this.emitter.emit('loadManageCensusPopup');
  }
 
  updateNavBarData(){
    this.isShowContractLaborManagementModalIcon = (this.profileData.ContractLaborEnabled == false || this.profileData.ContractLaborBulkCreateEnabled == false) ? false : true;
    this.isShowSimpleLifecyleReportingModalIcon = this.profileData.ContractLaborUsingKafkaEnabled || this.profileData.ContractLaborNeedAutomation;
    this.isManageCensusEnabled = this.profileData.ManageCensusEnabled;
    this.fsRedirectionLink = this.profileData.AppUrl;
  }
  created(): void {
      this.updateNavBarData();
  }
}

</script>
<style scoped>
.tooltipTxt{
  font-size: 0.75rem !important;
}
.navbar-brand{
  background-color: rgba(248, 249, 250, 1) !important;
  height: 3rem;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

.navbar{
  background: #03173e !important;
  color: #fff;
  vertical-align: middle;
  height: 3.75rem;
}

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 1.1rem;
    left: 7rem;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
    color: black;
}

.tenantslist{
  min-height: 1.7rem !important;
  font-size: 14px !important;
}

.tenantSelection{
  position: relative;
}

.tenantLabel{
  float:left;
  font-weight: 600;
}

.notification-count {
    position: absolute;
    background-color: green;
    top: -5px;
    left: 5px;
    color: white;
}
.modaltoolbar {
    color: #15202E;
    font-size: 16px;
    margin-left: 8px;
}
</style>