import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modalBackdrop flex absolute z-max" }
const _hoisted_2 = {
  class: "save-modal absolute",
  id: "saveAlertPopUpModal",
  "aria-labelledby": "savePopUpModalLabel",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modalContent" }
const _hoisted_4 = { class: "material-icons mr0 neu-snackbar__feedback-icon iconPadding" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_neu_icon = _resolveComponent("neu-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(['neu-snackbar', _ctx.calssName])
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("i", _hoisted_4, [
              _createVNode(_component_neu_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.iconName), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(['neu-snackbar__text', _ctx.notificationBody]),
            innerHTML: _ctx.msgValue
          }, null, 10, _hoisted_5),
          _createElementVNode("button", {
            class: _normalizeClass([_ctx.btnClassName, 'neu-button neu-button--small neu-margin--left-auto']),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeButtonClicked && _ctx.closeButtonClicked(...args)))
          }, " Ok ", 2)
        ], 2)
      ])
    ])
  ]))
}