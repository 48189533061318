<template>
   <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">SMS Notifications</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody">
                <neu-tablist :value="activeTab" class="mx-4 tabList">
                    <neu-tab
                        white
                        tab="current"
                        @click="showCurrentTab">
                        <div>Current </div>
                    </neu-tab>
                    <neu-tab
                        white
                        tab="activityLog"
                        @click="showActivityLogTab">
                        <div>Activity Log </div>
                    </neu-tab>
                </neu-tablist>
                <div id="filters-details" class="panelTabData scrollBar" v-if="isCurrentNotification" role="tabpanel">
                    <CurrentNotifications :notificationTab="activeTab"/>
                </div>
                <div id="ED-surge-details" class="panelTabData scrollBar" v-if="isActivityLog" role="tabpanel">
                    <CurrentNotifications :notificationTab="activeTab"/>
                </div>
            </div>  
        </div>
    </div>
</template>

<script lang="ts">

    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import CurrentNotifications from './CurrentNotifications.vue';

    import {
        NeuTab,
        NeuTablist
    } from "@neutron/vue";
    
    @Options({
        components: {
            CurrentNotifications,
            NeuTab,
            NeuTablist
        },
    })
    export default class SMSNotifications extends Vue  {       
     isCurrentNotification:boolean = true; 
     isActivityLog:boolean = false;
     activeTab: string = 'current';

     showCurrentTab(){
        this.isCurrentNotification = true;
        this.isActivityLog = false;
        this.activeTab = 'current'
     } 
     showActivityLogTab(){
        this.isActivityLog = true;
         this.isCurrentNotification = false;
          this.activeTab = 'activityLog'
     } 
    close() {
        this.$emit("close-modal");
    }
       

    }
</script>

<style scoped>
.c4c-modal {
    width: 90%;
    height: 70%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    padding: 5px 12px;
    height:92%;
    overflow: auto;
}
.panelTabData{
    height: 92%;
    overflow: auto;
    padding-top: 1rem;
}

</style>