<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Workforce Request Status</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="workforceRequestModal" class="panleBody scrollBar border">
                <div class="tab-content" id="workforceRequestModalTabContent">  
                    <StatusTab :statusInput="workforceRequestInput" />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import StatusTab from '../lifecyclereporting/StatusTab.vue';

class Props {
    workforceRequestInput!: any 
}

@Options({
  computed: {
    
  },
  components: {
    StatusTab
  }
}) 
export default class WorkforceRequest extends Vue.with(Props) {
    //Data properties

    //Lifecycle hooks

    //Methods
    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 90%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    margin: 5px;
    padding: 5px 12px;
    height:90%;
    overflow: auto;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}
</style>