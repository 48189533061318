<template>
    <div id="filterContainer">
        <div id="filteractivityLoggrid-content-loading" class="text-center mt-3" v-show="isShowLoader">
            <div class="fa-2x">
                <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
            </div>
            <p><b>Loading...</b></p>
        </div>
        <neu-card color="plain-0" class="p-0 m-0" >
            <neu-table>
                <neu-table-row :columns="columnHeaders" header>
                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column">
                {{ column }}
                </neu-table-heading>
                </neu-table-row>
                <neu-table-body align="left">
                    <template v-if="filterPreferenceDetails != null && filterPreferenceDetails.length > 0">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <neu-table-row :columns="columnHeaders" v-for="filter in filterPreferenceDetails" :key="filter" :slot="column">
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-label slot="FilterName">
                                <span v-if="filter.isVisibleNameLabel">{{ filter.name }}</span>
                                <neu-input v-if="filter.isVisibleNameText"
                                    maxlength="50" v-model="filter.nameText"
                                ></neu-input>
                            </neu-label>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-label slot="Facilities">{{ buildFacilitiesServiceLineDeptsTemplate(filter.facilityNames) }}</neu-label>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-label slot="Service Lines">{{ buildFacilitiesServiceLineDeptsTemplate(filter.serviceLineNames) }}</neu-label>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-label slot="Department">{{ buildFacilitiesServiceLineDeptsTemplate(filter.departmentNames) }}</neu-label>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-label slot="Default">
                                <neu-checkbox id="chkDefault" name="chkDefault" class="mr-10" v-model="filter.IsDefaultChecked" :checked="filter.IsDefaultChecked" :disabled="filter.isDefaultCheckDisable"></neu-checkbox>
                            </neu-label>
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-label slot="Action">
                                <a href="#" class="modaltoolbarIcon edit-button" 
                                            title="Edit" 
                                            v-if="filter.isEditIconVisible"
                                            @click="onFilterEdit(filter.id)">
                                    <i aria-hidden="true">
                                        <font-awesome-icon :icon="['fas', 'edit']" size="lg" />
                                    </i>
                                </a>
                                <a href="#" class="modaltoolbarIcon delete-button" 
                                            title="Delete" 
                                            v-if="filter.isDeleteIconVisible"
                                            @click="onFilterDelete(filter.id)"
                                            >
                                    <i aria-hidden="true">
                                        <font-awesome-icon :icon="['fas', 'trash']" size="lg" />
                                    </i>
                                </a>
                                <a href="#" class="modaltoolbarIcon save-button" 
                                            title="Save" 
                                            v-if="filter.isSaveIconVisible"
                                            @click="onFilterSave(filter.id)">
                                    <i aria-hidden="true">
                                        <font-awesome-icon :icon="['fas', 'save']" size="lg" />
                                    </i>
                                </a>
                                <a href="#" class="modaltoolbarIcon cancel-button" 
                                            title="Cancel" 
                                            v-if="filter.isSaveIconVisible"
                                            @click="onFilterCancel(filter.id)">
                                    <i aria-hidden="true">
                                        <font-awesome-icon :icon="['fas', 'window-close']" size="lg" />
                                    </i>
                                </a>
                            </neu-label>
                        </neu-table-row>
                    </template>
                    <template v-else>
                        <p id="period-message-text">No data available on current page.</p>
                    </template>
                </neu-table-body>
            </neu-table>
        </neu-card>

        <neu-container fluid="" v-if="filterPreferenceDetails != null && filterPreferenceDetails.length > 0">
            <neu-row>
                <neu-col md="6" cols="12" class="neu-text-center neu-text-md-left">
                    <!-- eslint-disable-next-line vue/no-parsing-error  -->
                    <neu-paginator class="justify-content-start" @v-neu-change="(ev: CustomEvent) => this.pageNum = ev.detail.pageNumber"
                        :total="filterPreferenceDetails != null && filterPreferenceDetails.length"
                        :rowsPerPage="rowsPerPage"
                        >
                    </neu-paginator>
                </neu-col>
                <neu-col md="6" cols="12" class="neu-text-center neu-text-md-right">
                    <neu-table-row-count
                        class="mt-3"
                        section="number"
                        :total="filterPreferenceDetails != null && filterPreferenceDetails.length"
                        :page-number="pageNum"
                        @v-neu-change="rowCountChange"
                    >
                    </neu-table-row-count>
                    
                </neu-col>
            </neu-row>
        </neu-container>
        <div v-if="isToasterVisible"> 
        <ToasterPopup @closeModal="closeToasterPopup"
                        :msgValue="msgValue"
                        :classFlag="classFlag"></ToasterPopup>
        </div>
        <div v-if="isConfirmModalVisible">
        <ConfirmationPopup @cancelled="isConfirmModalVisible = false"
                            @confirmed="confirmedClicked"
                            :msgValue="confirmMsgValue"></ConfirmationPopup>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";

//import model and enum
import { Profile } from '@/models/profile/index';

//import components
import {
  NeuContainer,
  NeuInput,
  NeuRow,
  NeuTableRowCount,
  NeuTable,
  NeuTableRow,
  NeuLabel,
  NeuTableBody,
  NeuTableHeading,
  NeuCard,
  NeuPaginator,
  NeuCol,
  NeuCheckbox
} from "@neutron/vue";
//@ts-ignore
import ToasterPopup from "../shared/ToasterPopup.vue";
import ConfirmationPopup from "../shared/ConfirmationPopup.vue";

@Options({
  computed: {
    ...mapState('userstaffpreferences', ['filterPreferences']),
    ...mapState('profile', ['profileData'])
  },
  components: {
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox,
    ToasterPopup,
    ConfirmationPopup
  },
})

export default class Filter extends Vue {
  //Data properties
  filterPreferences!: any;
  profileData!: Profile;
  filterPreferenceDetails: any = null;
  columnHeaders: any = { "FilterName": "20%", Facilities: "20%", "Service Lines": "20%", Department: "20%", Default: "10%", Action: "10%" };
  columnIcons: any = {"FilterName": "none", Facilities: "none", "Service Lines": "none", Department: "none", Default: "none", Action: "none"};
  pageNum: number = 1;
  rowsPerPage: number = 10;
  first: number = 1;
  last: number = 10;
  msgValue!: string;
  classFlag!: string;
  isToasterVisible:boolean = false;
  isConfirmModalVisible:boolean = false;
  confirmMsgValue!: string;
  filterIdTodelete: number = 0;
  isShowLoader: boolean = false;
  isNoRecordFound: boolean = false;
  //Lifecycle hooks
   mounted() {
    this.getFilterPreferences();
  }

  //Methods
  async getFilterPreferences(){
    var inputData = {
      sourceId: 'Dashboard',
      dashboardId: this.profileData.DashboardId,
      fsUserId: this.profileData.UserId,
      fsStaffId: 0
    }
    this.showLoader();
    await this.$store
            .dispatch("userstaffpreferences/getUserPreferences", inputData)
            .then(() => {
                this.setFilterPreference();
                this.hideLoader();
            })
            .catch((err: any) => {
                if (err) {
                    this.hideLoader();
                    console.error(err); // Handle errors any way you want
                }
            }); 
    }
    rowCountChange(ev: CustomEvent) {
      if (ev.detail && ev.detail.first && ev.detail.last) {
        this.first = ev.detail.first;
        this.last = ev.detail.last;
      }
      if (ev.detail && ev.detail.value) this.rowsPerPage = ev.detail.value;
    }
    buildFacilitiesServiceLineDeptsTemplate(dataItems: any){
        var Items = '';
        dataItems.forEach((value: any) => {
            Items += value + ', ';
        });
        if (Items.length > 0) {
            Items = Items.substring(0, Items.length - 2);
        }
        return Items;
    }
    onFilterEdit(itemId: any){
        this.toggleEditMode(itemId, true);
    }

    onFilterCancel(itemId: any){
        this.toggleEditMode(itemId, false);
    }

    async onFilterSave(itemId: any){
        var itemIndex = this.filterPreferenceDetails.findIndex((obj: any) => obj.id == itemId);
        if (this.filterPreferenceDetails[itemIndex].nameText.trim() == '') {
            this.msgValue = "Please enter filter name.";
            this.classFlag = "error";
            this.isToasterVisible = true;
            return;
        }

        this.showLoader();
        var objFilterData: any = {};
        objFilterData.Id = this.filterPreferenceDetails[itemIndex].id;
        objFilterData.Name = this.escapeHTML(this.filterPreferenceDetails[itemIndex].nameText.trim());
        objFilterData.IsDefault = this.filterPreferenceDetails[itemIndex].IsDefaultChecked;
         
        var inputData = {
            sourceId: 'Dashboard',
            dashboardId: this.profileData.DashboardId,
            fsUserId: this.profileData.UserId,
            fsStaffId: 0,
            filterId: this.filterPreferenceDetails[itemIndex].id,
            FilterData: objFilterData
        } 

        await this.$store
            .dispatch("userstaffpreferences/updateFilterPreferences", inputData)
            .then(() => {
                this.setFilterPreference();
                this.toggleEditMode(itemId, false);
                this.emitter.emit('updateFilterPreferenceswithDataRefresh', this.filterPreferences);
                this.hideLoader();
                this.msgValue = "Data updated successfully."
                this.classFlag = "success";
                this.isToasterVisible = true;
                //To Do Update Filter dropdown and Filter popup data
            })
            .catch((err: any) => {
                if (err) {
                    this.hideLoader();
                    this.msgValue = "Error encountered while saving filter.";
                    this.classFlag = "error";
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
            }); 
        
    }

    onFilterDelete(itemId: any){
       this.confirmMsgValue = "Are you sure you want to delete this filter?";
       this.isConfirmModalVisible = true;
       this.filterIdTodelete = itemId;
    }

    confirmedClicked(){
        this.isConfirmModalVisible = false;
        this.deleteFilter();
    }

    async deleteFilter(){
        var inputData = {
            tenantId: this.profileData.TenantId,
            sourceId: 'Dashboard',
            dashboardId: this.profileData.DashboardId,
            fsUserId: this.profileData.UserId,
            fsStaffId: 0,
            filterId: this.filterIdTodelete,
        }
        this.showLoader();

        await this.$store
            .dispatch("userstaffpreferences/deleteFilterPreferences", inputData)
            .then(() => {
                this.setFilterPreference();
                this.emitter.emit('updateFilterPreferenceswithDataRefresh', this.filterPreferences);
                this.hideLoader();
                this.msgValue = "Data deleted successfully."
                this.classFlag = "success";
                this.isToasterVisible = true;
                //To Do Update Filter dropdown and Filter popup data
            })
            .catch((err: any) => {
                if (err) {
                    this.hideLoader();
                    this.msgValue = "Error encountered while saving filter.";
                    this.classFlag = "error";
                    this.isToasterVisible = true;
                    console.error(err); // Handle errors any way you want
                }
            }); 
    }
    setFilterPreference(){
        if(this.filterPreferences != null && this.filterPreferences.length > 0){
            this.filterPreferenceDetails = this.filterPreferences; 
            for (var i = 0; i < this.filterPreferenceDetails.length; i++) {
                this.filterPreferenceDetails[i] = Object.assign(this.filterPreferenceDetails[i], {
                    nameText: this.filterPreferenceDetails[i].name,
                    IsDefaultChecked: this.filterPreferenceDetails[i].isDefault,
                    isVisibleNameText: false,
                    isVisibleNameLabel: true,
                    isDefaultCheckDisable: true,
                    isEditIconVisible: true,
                    isDeleteIconVisible: true,
                    isSaveIconVisible: false, 
                    isCancelIconVisible: false
                });
            }
        }
        else{
            this.filterPreferenceDetails = [];
            this.isNoRecordFound = true;
        }
    }

    escapeHTML(str: any) {
        var entityMap: any = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt',
            '"': '&quot;',
            "'": '&#39;'
        }
        return String(str).replace(/[&<>"']/g, function (s) {
            return entityMap[s];
        });
    }

    toggleEditMode(itemId: any, isEditible: boolean){
        var itemIndex = this.filterPreferenceDetails.findIndex((obj: any) => obj.id == itemId);
        if(isEditible){
            this.filterPreferenceDetails[itemIndex].isVisibleNameText= true;
            this.filterPreferenceDetails[itemIndex].isVisibleNameLabel= false;
            this.filterPreferenceDetails[itemIndex].isDefaultCheckDisable= false;
            this.filterPreferenceDetails[itemIndex].isEditIconVisible= false;
            this.filterPreferenceDetails[itemIndex].isDeleteIconVisible= false;
            this.filterPreferenceDetails[itemIndex].isSaveIconVisible= true; 
            this.filterPreferenceDetails[itemIndex].isCancelIconVisible= true;
        }
        else{
            this.filterPreferenceDetails[itemIndex].nameText= this.filterPreferenceDetails[itemIndex].name;
            this.filterPreferenceDetails[itemIndex].IsDefaultChecked = this.filterPreferenceDetails[itemIndex].isDefault;
            this.filterPreferenceDetails[itemIndex].isVisibleNameText= false;
            this.filterPreferenceDetails[itemIndex].isVisibleNameLabel= true;
            this.filterPreferenceDetails[itemIndex].isDefaultCheckDisable= true;
            this.filterPreferenceDetails[itemIndex].isEditIconVisible= true;
            this.filterPreferenceDetails[itemIndex].isDeleteIconVisible= true;
            this.filterPreferenceDetails[itemIndex].isSaveIconVisible= false; 
            this.filterPreferenceDetails[itemIndex].isCancelIconVisible= false;
        }
    }

    closeToasterPopup() {
      this.isToasterVisible = false;
    }

    showLoader(){
        this.isShowLoader = true;
    }

    hideLoader(){
        this.isShowLoader = false;
    }
}
</script>
<style scoped>
.neu-table-row--ellipsis {
    white-space: inherit !important;
}

.modaltoolbarIcon {
    margin-left: 5px;
    margin-right: 5px;
    color: #15202E;
}
.modaltoolbarIcon:hover {
    color: #15202E;
}
</style>