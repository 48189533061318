<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">{{headerDetails.FacilityName}} - {{headerDetails.ClusterName}} - ({{headerDetails.ShiftTime}})</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="departmentModal" class="panleBody scrollBar border" v-if="departmentList != null && departmentList.length > 0">
                <div>
                    <h6 class="float-right mb-2">Skills:  {{deptSkills.join(', ')}}</h6>
                </div>
                <table id="departmentTable" class="table-hover dashboardTable sevenDaydashboardTable" style="height: auto;">
                    <colgroup id="departmentColGroupTbl"> 
                    </colgroup>
                    <thead id="departmentHeaderTbl">
                    </thead>
                    <tbody id="departmentBodyTbl">
                    </tbody>
                </table>
            </div>
            <div class="txtCenter" v-else>No data available.</div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import jslinq from "jslinq";
import moment from 'moment';

//import model and enum
import { Department, DepartmentMetric, DepartmentSummary, DeptPremiumLaborMetricBreakDown } from "@/models/dashboardSevenDay/index";

//Import Componenets

class Props {
    departmentList!: Department[] 
    headerDetails!: any
}

@Options({
    
}) 
export default class DepartmentDetails extends Vue.with(Props) {
    //Data properties
    gridFirstRowHeaders: any[] = [];
    gridSecondRowHeaders: any[] = [];
    longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    shortDateFormat: string = 'YYYY-MM-DD';
    gridDateRangeFormat: string = 'M/D/YY';
    gridHeaderDateFormat: string = 'ddd MM/DD';
    shortTimeFormat: string = 'hh:mm A';
    totalColumns: number = 0;
    deptSkills: string[] = [];
    skillCount: number = 0;
    noOfDays: number = 7;
    MetricPI95115ColList: any[] = ["DayLess95PI", "DayGreater115PI"];

    //Lifecycle hooks
    mounted(){
        if(this.departmentList != null && this.departmentList.length > 0){
            this.renderDepartmentGrid();
        }
    }
    //Methods
    renderDepartmentGrid(){
        this.createGridHeader();
        this.createGridBody();
    }

    createGridHeader(){
        this.loadGridHeaderData();
        this.loadGridHeaderHTML();
    }

    loadGridHeaderData(){
        //Get distinct skills from all department
        //@ts-ignore
        var skills: DepartmentMetric[] | null = this.departmentList[0].Summaries[0].Metrics;
        if(skills != null && skills.length > 0){
            skills.forEach((x: DepartmentMetric) =>{
                //@ts-ignore
                this.deptSkills.push(x.SkillLevelDesc);
            })
        }
        this.skillCount = this.deptSkills.length;

        //Load First row header data
        for(var i= 0; i < this.noOfDays; i++){
            var date = moment(this.headerDetails.StartDate).add(i, 'days').format(this.gridHeaderDateFormat);
            this.gridFirstRowHeaders.push(date);
        }
        var dateRange = moment(this.headerDetails.StartDate).format(this.gridHeaderDateFormat) + ' - ' + moment(this.headerDetails.StartDate).add(this.noOfDays - 1, 'days').format(this.gridHeaderDateFormat);
        this.gridFirstRowHeaders.push(dateRange);

        //Load Second row header data
        this.gridSecondRowHeaders.push("Department");
        this.gridSecondRowHeaders.push("Shift Summary");
        this.gridSecondRowHeaders.push("% Days < 95% PI");
        this.gridSecondRowHeaders.push("% Days > 115% PI");
        if(this.headerDetails.isPI14Day == null || this.headerDetails.isPI14Day) 
            this.gridSecondRowHeaders.push("14-Day Prod. Index");
        else 
            this.gridSecondRowHeaders.push("7-Day Prod. Index");
       
        for(var i= 0; i < this.noOfDays; i++){
            if(skills != null && skills.length > 0){
                skills.forEach((x: DepartmentMetric) =>{

                    this.gridSecondRowHeaders.push(x.SkillLevelDesc)
                })
            }
            if(this.headerDetails.IsShowPremiumLabor){
                this.gridSecondRowHeaders.push("Prem. Labor")
            }
        }
        this.gridSecondRowHeaders.push("Weekly Avg. Variance");
        this.gridSecondRowHeaders.push("Total Variance");

        //Total number of columns in Dept Grid
        this.totalColumns = this.gridSecondRowHeaders.length;
    }

    loadGridHeaderHTML(){  
        var dashboardTableHead: any = document.getElementById('departmentHeaderTbl');
        var dashboardTableColGroup: any = document.getElementById('departmentColGroupTbl');

        dashboardTableHead.innerHTML = "";
        dashboardTableColGroup.innerHTML = "";

        var colGroup: any = this.prepareColGroup();
        var firstRowHeader: any = this.prepareFirstHeader(this.gridFirstRowHeaders);
        var secondRowHeader: any = this.prepareSecondHeader(this.gridSecondRowHeaders);

        colGroup.forEach((col: any) => {
            dashboardTableColGroup.appendChild(col);
        });
        dashboardTableHead.appendChild(firstRowHeader);
        dashboardTableHead.appendChild(secondRowHeader);
    }

    prepareColGroup(){
        var colGroupRow = [];
        for (var h = 0; h < this.totalColumns; h++) {
            var col = document.createElement('col');

            if (h < 1) {
                col.style.width = "160px";
            }
            else if (h == 1) {
                col.style.width = "140px";
            }
            
             else if (h < 5) {
                col.style.width = "80px";
            } else if (h == (this.totalColumns - 2)) {
                col.style.width = "100px";
            } else {
                col.style.width = '70px';
            }
            colGroupRow.push(col);
        }
        return colGroupRow;
    }

    prepareSecondHeader(columns: any){
        var headerRow = document.createElement('tr');

        //staring 5 columns (Department, 3 Prod Index columns, shift summary)
        for (var h = 0; h < 5; h++) {
            var column = columns[h];
            var thCommon: any = document.createElement('th');
            thCommon.style.padding = "3px 3px 3px 3px";
            if(h==0)
            {
                thCommon.classList ='detailsHeader departmentcol departmentoutline' 
            }
            else if(h==1)
            {
                thCommon.classList ='detailsHeader shiftSummarycol departmentoutline' 
            }
            else
            {
                thCommon.classList ='detailsHeader';
            }
            
            thCommon.innerHTML = column;
            thCommon.rowSpan = 2;
             headerRow.appendChild(thCommon);
        }

        //Day wise Skills column for total 7 day + Weekly avg + total columns
        for (var h = 5; h < columns.length; h++) {
            var column = columns[h];

            var th: any = document.createElement('th');
            th.style.padding = "3px 3px 3px 3px";
            th.classList = 'detailsHeader';

            if(column == 'Weekly Avg. Variance'){
                th.innerHTML = this.headerDetails.IsShowActualCount ? 'Weekly Avg. Actual' : column;
            }
            else if(column == 'Total Variance'){
                th.innerHTML = this.headerDetails.IsShowActualCount ? 'Total Actual' : column;
            }
            else{
                th.innerHTML = column;
            }

            headerRow.appendChild(th);
        }

        return headerRow;
    }

    prepareFirstHeader(columns: any) {
       var headerRow = document.createElement('tr');
        var thCommon: any = document.createElement('th');
        thCommon.style.padding = "3px 3px 3px 3px";
        thCommon.classList = 'detailsHeader departmentcol departmentoutline';
        thCommon.colSpan = 2; 
        headerRow.appendChild(thCommon);
        
        var thheader: any = document.createElement('th');
        thheader.style.padding = "3px 3px 3px 3px";
        thheader.classList = 'detailsHeader';
        thheader.colSpan = 3;
        headerRow.appendChild(thheader);

        for (var h = 0; h < columns.length ; h++) {
            var column = columns[h];

            var th: any = document.createElement('th');
            th.style.padding = "3px 3px 3px 3px";
            th.classList = 'detailsHeader';
            th.colSpan = this.skillCount;
            if(this.headerDetails.IsShowPremiumLabor){
                th.colSpan = this.skillCount + 1;
            }

            //if last column the set colspan of 2(WeeklyAvg + total column)
            if(h == columns.length - 1){
                th.colSpan = 2;
            }

            th.innerHTML = column;
            headerRow.appendChild(th);
        }

        return headerRow;
    }

    createGridBody(){
        var tableBody: any = document.getElementById('departmentBodyTbl');
        tableBody.innerHTML = "";

        //Display Department Name and Code column data
        for (var d = 0; d < this.departmentList.length; d++) {
            var department: Department = this.departmentList[d];
            var dRow: any = document.createElement('tr');

            //Create Department Name and Code column 
            var dCol: any = document.createElement('td');
            dCol.innerHTML = department.DepartmentName + " - " + department.DepartmentCode;
            dCol.rowSpan = department.Summaries?.length;
            dCol.classList='departmentcol backgroundwhite'
            dRow.appendChild(dCol);
           
            if(department.Summaries != null){
              
                    var summary: DepartmentSummary = department.Summaries[0];
                   // Create first row Shift Summary column
                    var summaryStartTime: any = moment(summary.StartDateTime).format(this.shortTimeFormat);
                    var summaryEndTime: any = moment(summary.EndDateTime).format(this.shortTimeFormat);
                    var dCol: any = document.createElement('td');
                    dCol.innerHTML = summaryStartTime + ' - ' + summaryEndTime;
                    dCol.classList='shiftSummarycol backgroundwhite'
                    dRow.appendChild(dCol);
                   
            }


            //Create %Day less than 95 PI column
            var dCol: any = this.createPIColums(department.DayLess95PI, "DayLess95PI");
            dCol.rowSpan = department.Summaries?.length;
            dRow.appendChild(dCol);

            //Create %Day greater than 115 PI column
            var dCol: any = this.createPIColums(department.DayGreater115PI, "DayGreater115PI");
            dCol.rowSpan = department.Summaries?.length;
            dRow.appendChild(dCol);

            //Create PI column
            var dCol: any = this.createPIColums(department.ProdIndex, "ProdIndex");
            dCol.rowSpan = department.Summaries?.length;
            dRow.appendChild(dCol);
            
            //Loop through summaries data
            if(department.Summaries != null){
                for (var s = 0; s < department.Summaries.length; s++) {
                    var summary: DepartmentSummary = department.Summaries[s];
                    var summaryColList: any[] = [];
                    var sRow: any = document.createElement('tr');

                    //Create Shift Summary column
                    if(s!=0)
                    {
                    var summaryStartTime: any = moment(summary.StartDateTime).format(this.shortTimeFormat);
                    var summaryEndTime: any = moment(summary.EndDateTime).format(this.shortTimeFormat);

                    var dCol: any = document.createElement('td');
                    dCol.innerHTML = summaryStartTime + ' - ' + summaryEndTime;
                    dCol.classList='shiftSummarycol backgroundwhite'
                    summaryColList.push(dCol);
                    }
                    //create Metrics data column for Day 1 to Day 7
                    for(var i = 1; i <= this.noOfDays; i++){
                        var dayKey: string = "Day" + i;
                        for(var j = 0; j < this.deptSkills.length; j++){
                            var skill: string = this.deptSkills[j];

                            //Get department metrics by skill from Metrics array
                            //@ts-ignore
                            var metrics: DepartmentMetric = jslinq(summary.Metrics)
                                .where((item: any) => {
                                    if (item.SkillLevelDesc == skill) {
                                        return item;
                                    }
                                }).firstOrDefault();

                            if(metrics != null){
                                if(this.headerDetails.IsShowActualCount){
                                    //@ts-ignore
                                    var metricBrekupByDay: string = metrics[dayKey].Actual;
                                    summaryColList.push(this.createActualMetricsColumnbyDay(metricBrekupByDay, dayKey));
                                }
                                else{
                                    //@ts-ignore
                                    var metricBrekupByDay: string = metrics[dayKey].Variance;
                                    summaryColList.push(this.createMetricsColumnbyDay(metricBrekupByDay, dayKey));
                                }
                            }
                        }

                        if(this.headerDetails.IsShowPremiumLabor){
                            //@ts-ignore
                            var premLaborByDay: DeptPremiumLaborMetricBreakDown = department.PremiumLaborMetrics[dayKey];
                            summaryColList.push(this.createPremLaborColumnbyDay(premLaborByDay.Total, dayKey))
                        }
                    }

                    if(this.headerDetails.IsShowActualCount){
                        //create Weekly avg column - Actual
                        if(summary.WeeklyAvg != null){
                            summaryColList.push(this.createActualMetricsColumnbyDay(summary.WeeklyAvg.Actual, "WeeklyAvg"));
                        }
                    }
                    else{
                        //create Weekly avg column - Variance
                        if(summary.WeeklyAvg != null){
                            summaryColList.push(this.createMetricsColumnbyDay(summary.WeeklyAvg.Variance, "WeeklyAvg"));
                        }
                    }

                    if(this.headerDetails.IsShowActualCount){
                        //create Total column - Actual
                        if(summary.Total != null){
                            summaryColList.push(this.createActualMetricsColumnbyDay(summary.Total.Actual, "Total"));
                        }
                    }
                    else{
                        //create Total column - Variance
                        if(summary.Total != null){
                            summaryColList.push(this.createMetricsColumnbyDay(summary.Total.Variance, "Total"));
                        }
                    }

                    if (s === 0) {
                        summaryColList.forEach((col) => {
                            dRow.appendChild(col);
                        });
                        tableBody.appendChild(dRow);
                    } else {
                        summaryColList.forEach((col) => {
                            sRow.appendChild(col);
                       });
                     tableBody.appendChild(sRow);
                    }
                }
            }
        }
    }

    createMetricsColumnbyDay(metricBrekupByDay: any | null, key: string){
        var colTotalTxt: any;
        if(metricBrekupByDay != null){
            //day wise grand total
            colTotalTxt = document.createElement('td');
            colTotalTxt.innerText = metricBrekupByDay;

            //Add Special Background color for Weekend Cell
            if(key == this.headerDetails.WeekEndDay1 || key == this.headerDetails.WeekEndDay2){
                colTotalTxt.classList.add('weekendBgClr')
            }

            const metricNum = Number(metricBrekupByDay);
            //Add Style for Over and Under Staff
            if(metricNum < 0){
                colTotalTxt.classList.add('underStaff');
            }
            else if(metricNum > 0){
                colTotalTxt.classList.add('overStaff');
            }
        }

        return colTotalTxt;
    }

    createActualMetricsColumnbyDay(metricBrekupByDay: any | null, key: string){
        var colTotalTxt: any;
        if(metricBrekupByDay != null){
            //day wise grand total
            colTotalTxt = document.createElement('td');
            colTotalTxt.innerText = metricBrekupByDay;

            //Add Special Background color for Weekend Cell
            if(key == this.headerDetails.WeekEndDay1 || key == this.headerDetails.WeekEndDay2){
                colTotalTxt.classList.add('weekendBgClr')
            }
        }

        return colTotalTxt;
    }

    createPremLaborColumnbyDay(metricBrekupByDay: any | null, key: string){
        var colTotalTxt: any;
        if(metricBrekupByDay != null){
            colTotalTxt = document.createElement('td');
            colTotalTxt.innerText = metricBrekupByDay;

            //Add Special Background color for Weekend Cell
            if(key == this.headerDetails.WeekEndDay1 || key == this.headerDetails.WeekEndDay2){
                colTotalTxt.classList.add('weekendBgClr')
            }
        }
        return colTotalTxt;
    }

    createPIColums(piVal: any, key: string){
        var colPItd: any = document.createElement('td');
        var colPISpn: any = document.createElement('span');

        var dayLess95PICss: string = "";
        if(this.MetricPI95115ColList.includes(key)){
            //@ts-ignore
            dayLess95PICss = this.getPICssClass(piVal);
        }
        else{
            //@ts-ignore
            dayLess95PICss = this.getProdIndexCssClass(piVal);
        }

        //@ts-ignore
        colPISpn.innerText = piVal + '%';
        if(dayLess95PICss != ""){
            colPISpn.classList.add(dayLess95PICss);
        }
        colPItd.appendChild(colPISpn);
        
        return colPItd;
    }

    getPICssClass(index: string| null): string{
        var cssClass: string = "";
        if(index != null){
            const indexVal = Number(index);

            if(indexVal == 0){
                cssClass = "stablePI";
            }
            else if(indexVal > 5){
                cssClass = "underPI";
            }
            else if(indexVal <= 5){
                cssClass = "overPI";
            }
        }
        return cssClass;
    }

    getProdIndexCssClass(index: string| null): string{
        var cssClass: string = "";
        if(index != null){
            const indexVal = Number(index);

            if(indexVal >= 105 && indexVal < 106){
                cssClass = "stablePI";
            }
            else if(indexVal < 100 || indexVal >= 110){
                cssClass = "underPI";
            }
            else if((indexVal >= 100 && indexVal < 105) || (indexVal >= 106 && indexVal < 110)){
                cssClass = "overPI";
            }
        }
        return cssClass;
    }

    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 90%;
    height: 85%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
    padding: 12px 8px;
}

.panleBody{
    margin: 5px;
    height: 88%;
    overflow: auto;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}

</style>
<style>
.departmentcol {
    position: sticky!important;
    left:0!important;
    z-index: 5!important;
    
}

.shiftSummarycol {
    position: sticky!important;
    left:160px!important;
    z-index: 5!important;
    
}

.backgroundwhite {
    background-color: white!important;
    outline:1px solid #CED1D7!important;
    border: none!important;
   
}
.departmentoutline {
outline: 1px solid #CED1D7!important;
border-left: none!important;
}

</style>