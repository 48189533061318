<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal" :class="[isBreakdownfrom7Day? 'seven-day': '']">
            <header class="panelheader">
                <span class="headerTitle">Breakdown</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="breakdowntModal" class="panleBody scrollBar border">
                <neu-card color="plain-0" class="p-0 m-0" >
                    <neu-table>
                        <neu-table-row v-if="isBreakdownfrom7Day" :columns="columnHeaders" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column"
                                style="padding-top: 25px !important;">
                            {{ columnDisplayNames[column] }}
                            </neu-table-heading>
                        </neu-table-row>
                        <neu-table-body align="left">  
                            <template v-if="premLaborBreakdownInput != null && premLaborBreakdownInput.length > 0">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row :columns="columnHeaders" v-for="(premLbr, index) in premLaborBreakdownInput" :key="premLbr"
                                    :class="[premLbr.IsHeadingRow && isBreakdownfrom7Day ? 'headingRow': '']">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Key" 
                                            :class="[index == (premLaborBreakdownInput.length - 1) && isBreakdownfrom7Day ? 'totalValue': '',
                                                    (premLbr.IsHeadingRow && index != (premLaborBreakdownInput.length - 1) && isBreakdownfrom7Day ) ? 'headingRowTitle': '',
                                                    !premLbr.IsHeadingRow && isBreakdownfrom7Day  ? 'deptTitle': '']">
                                        {{ premLbr.Key }}
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Value" :class="[index == (premLaborBreakdownInput.length - 1) && isBreakdownfrom7Day  ? 'totalValue': '']">{{ premLbr.Value }}</neu-label>
                                    <neu-label v-if="isBreakdownfrom7Day" slot="Name" :class="[index == (premLaborBreakdownInput.length - 1) && isBreakdownfrom7Day  ? 'totalValue': '']">{{ premLbr.Name }}</neu-label>
                                    <neu-label v-if="isBreakdownfrom7Day" slot="LastCancelled" :class="[index == (premLaborBreakdownInput.length - 1) && isBreakdownfrom7Day  ? 'totalValue': '']">{{ displayDate(premLbr.LastCancelled) }}</neu-label>
                                </neu-table-row>
                            </template>
                            <template v-else>
                                <p id="period-message-text">No data available.</p>
                            </template>
                        </neu-table-body>
                    </neu-table>
                </neu-card>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";

//import model and enum
import { PremiumLaborKeyValuePair } from "@/models/dashboardSevenDay/index";

//Import Componenets
import {
    NeuContainer,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard
} from "@neutron/vue";

class Props {
    premLaborBreakdownInput!: PremiumLaborKeyValuePair[]
    isBreakdownfrom7Day!: boolean 
}

@Options({
    components: {
        NeuContainer,
        NeuRow,
        NeuTableRowCount,
        NeuTable,
        NeuTableRow,
        NeuLabel,
        NeuTableBody,
        NeuTableHeading,
        NeuCard
    }
}) 
export default class PremiumLaborBreakdown extends Vue.with(Props) {
    //Data properties
    columnHeaders: any = this.isBreakdownfrom7Day? { 
       Key: "35%", 
       Value: "7%",
       Name: "33%", 
       LastCancelled: "25%",
    } : { 
       Key: "70%", 
       Value: "30%"
    };
    columnIcons: any = {    
        Key: "none", 
        Value: "none",
        Name: "none",
        LastCancelled: "none",
    };
    columnDisplayNames: any = {    
        Key: "Employee Type", 
        Value: "Count",
        Name: "Name",
        LastCancelled: "Last Cancelled At",
    };

    //Lifecycle hooks

    //Methods
    close(){
        this.$emit("close-modal");
    }

    displayDate(date?: Date){
        if (!date){
            return '';
        }
        return moment(date).format("MM/DD/YYYY");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 40%;
    height: 65%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}

.c4c-modal.seven-day {
    width: 70%;
}

.panleBody{
    margin: 5px;
    padding: 5px 12px;
    height: 85%;
    overflow: auto;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}

.totalValue {
    font-weight: bold;
}

.headingRow{ 
    font-size: 17px;
    font-weight: bold;
}

.deptTitle{
    padding-left: 3rem !important;
}

.headingRowTitle{
    color: #164D87 !important;
}
</style>