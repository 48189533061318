<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Edit Assignment</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="assignmentModal" class="panleBody scrollBar border" style="overflow:scroll;">                                
                <div>                                   
                    <Assignment :assignmentInput="assignmentInput"
                                :assignmentType="assignmentType" />
                </div>        
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import {

} from "@neutron/vue";


import Assignment from '../actionable/assignment/Assignment.vue';


class Props {
    assignmentInput!: any ;
    assignmentType!: any ;
}

@Options({
  computed: {
   
  },
  components: {
    Assignment
  }
}) 
export default class EditAssignment extends Vue.with(Props) {
    //Data properties

    //Lifecycle hooks
    async mounted(){
        
    }

    //Methods
    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 90%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    margin: 5px;
    padding: 5px 12px;
    height:90%;
}
.panelTabData{
    word-break: break-all;
    padding-bottom: 23px;
}
</style>