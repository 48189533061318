<template>
    <div id="unsch-content-loading" class="text-center mt-3" v-show="isShowLoader">
        <div class="fa-2x">
            <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
        </div>
        <p><b>Loading...</b></p>
    </div>
    <div id="unsch-content" v-if="isShowAvailStaffContent">
        <div>
            <button id="sms-notification-modal-btn" class="btn btn-primary btn-sm float-end me-2" @click="showSendSMSPopup">SEND SMS</button>

        </div>
        <p class="p-2 txtLeft"> <b>AVAILABLE STAFF - Employees who worked in the last 12 hours are not displayed</b></p>
        <div id="grdUnscheduled">
            <neu-card color="plain-0" class="p-0 m-0">   
                <neu-table>
                    <neu-table-row :columns="columnHeadersAvail" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <neu-table-heading v-for="column in Object.keys(columnHeadersAvail)" :icon="columnIcons[column]"
                                           :key="column" :slot="column" style="padding-top: 25px !important;" @v-neu-change="sortDataAvailable($event,column)">
                            <template v-if="(column != 'RN Experience' || (column == 'RN Experience' && isRNExperienceFeatureEnabled))">
                                <span>  {{ column }} </span>
                                <font-awesome-icon :icon="['fas', 'filter']" size="lg"
                                                   id="filterIcon" :column="column"
                                                   @click="onFilterVisible($event, column,'avail')"
                                                   v-if="filterColumnList.includes(column)"
                                                   style="margin-left: 5px;" />

                                <!-- <i class="material-icons neu-table__sort neu-table__sort--active" v-if="getOrder(column,'avail') == 'asc'">arrow_upward</i>
                                <i class="material-icons neu-table__sort" v-if="getOrder(column,'avail') == 'desc'">arrow_downward</i> -->
                            </template>
                        </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                        <template v-if="availGridData != null && availGridData.length > 0">
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-row :columns="columnHeadersAvail" v-for="dataItem in availGridData" :slot="column"
                                           :key="dataItem" @click="onRowSelect(dataItem)"
                                           :class="[dataItem.charge ? 'chargeAssignment': '']"
                                           :data-staffId="dataItem.staffId">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Name" :title="getName(dataItem)">
                                    {{getName(dataItem)}}
                                    <span v-if="dataItem.charge">
                                        <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                    </span>
                                    <span v-if="dataItem.associationType === associationTypes.secondary">
                                        <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                    </span>
                                    <span v-if="dataItem.associationType === associationTypes.unassociated">
                                        <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                    </span>
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Skill" :title="dataItem.skillDescription">
                                    {{ dataItem.skillDescription }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Comps" style="white-space:nowrap;" class="col-comps">
                                    {{ dataItem.competencies.length > 0 ? dataItem.competencyCodes : ''  }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Type">
                                    {{ dataItem.employeeType }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Worked Hrs" :class="[dataItem.hoursWorked > dataItem.hours ? 'gridOverTime': '']">
                                    {{ dataItem.hoursWorked }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Sched. Hrs">
                                    {{ dataItem.hours }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="RN Experience" v-if="dataItem.rnExperienceFeatureEnabled"
                                           :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '']" :title="dataItem.rnExperience">
                                    {{ dataItem.rnExperience }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Hire Date">
                                    {{ setGridDateFormat(dataItem.hireDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Seniority Date">
                                    {{ setGridDateFormat(dataItem.seniorityDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Worked Date">
                                    {{ setGridDateFormat(dataItem.lastWorked) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Worked Time">
                                    {{ setGridTimeFormat(dataItem.lastWorked) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Next Assignment Date">
                                    {{ setGridDateFormat(dataItem.nextAssignmentDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Next Assignment Time">
                                    {{ setGridTimeFormat(dataItem.nextAssignmentStart) }}
                                </neu-label>
                            </neu-table-row>
                        </template>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
    </div>
    <div id="unsch-message" class="text-center mt-3" v-if="isShowAvailMessage">
        <p id="unsch-message-text">{{unschMessage}}</p>
    </div>
    <div id="unavailableStaff-content" class="mt-3" v-if="isShowUnavailStaffContent">
        <p class="p-2 txtLeft"> <b>UNAVAILABLE STAFF</b></p>
        <div id="grdUnavailableStaff">
            <neu-card color="plain-0" class="p-0 m-0">
                <neu-table>
                    <neu-table-row :columns="columnHeadersUnavail" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <neu-table-heading v-for="column in Object.keys(columnHeadersUnavail)" :icon="columnIcons[column]"
                                           :key="column" :slot="column" style="padding-top: 25px !important;" @v-neu-change="sortDataUnavailable($event,column)">

                            <span>  {{ column }} </span>
                            <font-awesome-icon :icon="['fas', 'filter']" size="lg"
                                               id="filterIcon" :column="column"
                                               @click="onFilterVisible($event, column,'unavail')"
                                               v-if="filterColumnList.includes(column)"
                                               style="margin-left: 5px;" />

                            <!-- <i class="material-icons neu-table__sort neu-table__sort--active" v-if="getOrder(column,'unavail') == 'asc'">arrow_upward</i>
                            <i class="material-icons neu-table__sort" v-if="getOrder(column,'unavail') == 'desc'">arrow_downward</i> -->

                        </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                        <template v-if="unavailGridData != null && unavailGridData.length > 0">
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-row :columns="columnHeadersUnavail" v-for="dataItem in unavailGridData" :slot="column"
                                           :key="dataItem" @click="onRowSelect(dataItem)"
                                           :class="[dataItem.charge ? 'chargeAssignment': '']"
                                           :data-staffId="dataItem.staffId">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Name" :title="getName(dataItem)">
                                    {{getName(dataItem)}}
                                    <span v-if="dataItem.charge">
                                        <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                    </span>
                                    <span v-if="dataItem.associationType === associationTypes.secondary">
                                        <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                    </span>
                                    <span v-if="dataItem.associationType === associationTypes.unassociated">
                                        <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                    </span>
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Skill" :title="dataItem.skillDescription">
                                    {{ dataItem.skillDescription }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Comps" style="white-space:nowrap;" class="col-comps">
                                    {{ dataItem.competencies.length > 0 ? dataItem.competencyCodes : ''  }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Type">
                                    {{ dataItem.employeeType }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Worked Hrs" :class="[dataItem.hoursWorked > dataItem.hours ? 'gridOverTime': '']">
                                    {{ dataItem.hoursWorked }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Sched. Hrs">
                                    {{ dataItem.hours }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Hire Date">
                                    {{ setGridDateFormat(dataItem.hireDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Seniority Date">
                                    {{ setGridDateFormat(dataItem.seniorityDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Worked Date">
                                    {{ setGridDateFormat(dataItem.lastWorked) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Worked Time">
                                    {{ setGridTimeFormat(dataItem.lastWorked) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Next Assignment Date">
                                    {{ setGridDateFormat(dataItem.nextAssignmentDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Next Assignment Time">
                                    {{ setGridTimeFormat(dataItem.nextAssignmentStart) }}
                                </neu-label>
                            </neu-table-row>
                        </template>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
    </div>
    <div id="unavailable-message" class="text-center mt-3" v-if="isShowUnavailMessage">
        <p id="unavailable-message-text">{{unavailMessage}}</p>
    </div>

    <div id="filterModel_avail" class="filterPanel d-none" v-show="filterVisibleOnGrid == 'avail'">
        <div class="p-2">
            <div class="pb-2">Filter by: </div>
            <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedNumberFilter_avail" v-if="filterColumn_avail == 'Worked Hrs' || filterColumn_avail == 'Sched. Hrs'">
                <option v-for="numFtr in numberFilter" :value="numFtr.Id" :key="numFtr.Id">{{numFtr.Value}}</option>
            </select>
            <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedDateFilter_avail" v-else-if="filterColumn_avail == 'Last Worked Date' || filterColumn_avail == 'Next Assignment Date'">
                <option v-for="dateFtr in dateFilter" :value="dateFtr.Id" :key="dateFtr.Id">{{dateFtr.Value}}</option>
            </select>
            <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedTextFilter_avail" v-else-if="filterColumn_avail != 'Type' && filterColumn_avail != 'Last Worked Time' && filterColumn_avail != 'Next Assignment Time'">
                <option v-for="txtFtr in textFilter" :value="txtFtr.Id" :key="txtFtr.Id">{{txtFtr.Value}}</option>
            </select>
            <neu-icon class="material-icons neu-input__select-icon z-max" v-if="filterColumn_avail != 'Type' && filterColumn_avail != 'Last Worked Time' && filterColumn_avail != 'Next Assignment Time'">arrow_drop_down</neu-icon>
            <input title="Value" v-model="NumberfilterText_avail" v-if="filterColumn_avail == 'Worked Hrs' || filterColumn_avail == 'Sched. Hrs'" class="neu-input mb-2 filterInput" type="number">
            <Datepicker v-model="DateFilterText_avail" weekStart="0" :enableTimePicker="false" v-else-if="filterColumn_avail == 'Last Worked Date' || filterColumn_avail == 'Next Assignment Date'" autoApply ></Datepicker>
            <div v-else-if="filterColumn_avail == 'Type' || filterColumn_avail == 'Last Worked Time' || filterColumn_avail == 'Next Assignment Time'">
                <template v-if="filterColumn_avail == 'Type'">
                    <input type="checkbox" @click='checkAllTypeFilter()' v-model='isCheckAllTypeFilter_avail'>
                    <span class="checkbox-label"> All </span>
                    <br>
                    <span v-for="(itemT, index) in lstType_avail" :key="index">
                        <input type="checkbox" :value="itemT" v-model="selectedTypeFilter_avail" @change='updateCheckallTypeFilter()'> <span class="checkbox-label"> {{itemT}} </span> <br>
                    </span>
                    <br />
                    <span>{{ selectedTypeFilter_avail.length }} items selected</span>
                </template>
                <template v-if="filterColumn_avail == 'Last Worked Time'">
                    <input type="checkbox" @click='checkAllLastWorkTimeFilter()' v-model='isCheckAllWorkTimeFilter_avail'>
                    <span class="checkbox-label"> All </span>
                    <br>
                    <span v-for="(itemL, index) in lstLastWorkedTime_avail" :key="index">
                        <input type="checkbox" :value="itemL" v-model="selectedLastWorkTimeFilter_avail" @change='updateCheckAllLastWorkTimeFilter()'> <span class="checkbox-label"> {{itemL}} </span> <br>
                    </span>
                    <br />
                    <span>{{ selectedLastWorkTimeFilter_avail.length }} items selected</span>
                </template>
                <template v-if="filterColumn_avail == 'Next Assignment Time'">
                    <input type="checkbox" @click='checkAllNextAssigmentTimeFilter()' v-model='isCheckAllNextAssignTimeFilter_avail'>
                    <span class="checkbox-label"> All </span>
                    <br>
                    <span v-for="(itemN, index) in lstNextAssignmentTime_avail" :key="index">
                        <input type="checkbox" :value="itemN" v-model="selectedNextAssignementTimeFilter_avail" @change='updateCheckAllNextAssignTimeFilter()'> <span class="checkbox-label"> {{itemN}} </span> <br>
                    </span>
                    <br />
                    <span>{{ selectedNextAssignementTimeFilter_avail.length }} items selected</span>
                </template>
            </div>
            <input title="Value" v-model="filterText_avail" class="neu-input mb-2 filterInput" type="text" v-else>
            <div>
                <button type="submit" title="Filter" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilter">Filter</button>
                <button type="reset" title="Clear" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClear">Clear</button>
                <button type="button" title="Close" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClose">Close</button>
            </div>
        </div>
    </div>
    <div id="filterModel_unavail" class="filterPanel d-none" v-show="filterVisibleOnGrid == 'unavail'">
        <div class="p-2">
            <div class="pb-2">Filter by: </div>
            <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedNumberFilter_unavail" v-if="filterColumn_unavail == 'Worked Hrs' || filterColumn_unavail == 'Sched. Hrs'">
                <option v-for="numFtr in numberFilter" :value="numFtr.Id" :key="numFtr.Id">{{numFtr.Value}}</option>
            </select>
            <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedDateFilter_unavail" v-else-if="filterColumn_unavail == 'Last Worked Date' || filterColumn_unavail == 'Next Assignment Date'">
                <option v-for="dateFtr in dateFilter" :value="dateFtr.Id" :key="dateFtr.Id">{{dateFtr.Value}}</option>
            </select>
            <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedTextFilter_unavail" v-else-if="filterColumn_unavail != 'Type' && filterColumn_unavail != 'Last Worked Time' && filterColumn_unavail != 'Next Assignment Time'">
                <option v-for="txtFtr in textFilter" :value="txtFtr.Id" :key="txtFtr.Id">{{txtFtr.Value}}</option>
            </select>
            <neu-icon class="material-icons neu-input__select-icon z-max" v-if="filterColumn_unavail != 'Type' && filterColumn_unavail != 'Last Worked Time' && filterColumn_unavail != 'Next Assignment Time'">arrow_drop_down</neu-icon>
            <input title="Value" v-model="NumberfilterText_unavail" v-if="filterColumn_unavail == 'Worked Hrs' || filterColumn_unavail == 'Sched. Hrs'" class="neu-input mb-2 filterInput" type="number">
            <Datepicker v-model="DateFilterText_unavail" weekStart="0" :enableTimePicker="false" v-else-if="filterColumn_unavail == 'Last Worked Date' || filterColumn_unavail == 'Next Assignment Date'" autoApply ></Datepicker>
            <div v-else-if="filterColumn_unavail == 'Type' || filterColumn_unavail == 'Last Worked Time' || filterColumn_unavail == 'Next Assignment Time'">
                <template v-if="filterColumn_unavail == 'Type'">
                    <input type="checkbox" @click='checkAllTypeFilter()' v-model='isCheckAllTypeFilter_unavail'>
                    <span class="checkbox-label"> All </span>
                    <br>
                    <span v-for="(itemT, index) in lstType_unavail" :key="index">
                        <input type="checkbox" :value="itemT" v-model="selectedTypeFilter_unavail" @change='updateCheckallTypeFilter()'> <span class="checkbox-label"> {{itemT}} </span> <br>
                    </span>
                    <br />
                    <span>{{ selectedTypeFilter_unavail.length }} items selected</span>
                </template>
                <template v-if="filterColumn_unavail == 'Last Worked Time'">
                    <input type="checkbox" @click='checkAllLastWorkTimeFilter()' v-model='isCheckAllWorkTimeFilter_unavail'>
                    <span class="checkbox-label"> All </span>
                    <br>
                    <span v-for="(itemL, index) in lstLastWorkedTime_unavail" :key="index">
                        <input type="checkbox" :value="itemL" v-model="selectedLastWorkTimeFilter_unavail" @change='updateCheckAllLastWorkTimeFilter()'> <span class="checkbox-label"> {{itemL}} </span> <br>
                    </span>
                    <br />
                    <span>{{ selectedLastWorkTimeFilter_unavail.length }} items selected</span>
                </template>
                <template v-if="filterColumn_unavail == 'Next Assignment Time'">
                    <input type="checkbox" @click='checkAllNextAssigmentTimeFilter()' v-model='isCheckAllNextAssignTimeFilter_unavail'>
                    <span class="checkbox-label"> All </span>
                    <br>
                    <span v-for="(itemN, index) in lstNextAssignmentTime_unavail" :key="index">
                        <input type="checkbox" :value="itemN" v-model="selectedNextAssignementTimeFilter_unavail" @change='updateCheckAllNextAssignTimeFilter()'> <span class="checkbox-label"> {{itemN}} </span> <br>
                    </span>
                    <br />
                    <span>{{ selectedNextAssignementTimeFilter_unavail.length }} items selected</span>
                </template>
            </div>
            <input title="Value" v-model="filterText_unavail" class="neu-input mb-2 filterInput" type="text" v-else>
            <div>
                <button type="submit" title="Filter" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilter">Filter</button>
                <button type="reset" title="Clear" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClear">Clear</button>
                <button type="button" title="Close" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClose">Close</button>
            </div>
        </div>
    </div>

    <div v-if="isToasterVisible">
        <ToasterPopup @closeModal="closeToasterPopup"
                      :msgValue="msgValue"
                      :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isShowExportToExcel">
        <ExportToExcelForTabs @closeExcelTemplate="closeUnscheduledTabExportToExcel" :payloadExportToExcel="payloadExportToExcel">
        </ExportToExcelForTabs>
    </div>
</template>
<script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";
    import { mapState } from "vuex";
    import jslinq from "jslinq";
    //@ts-ignore
    import Datepicker from '@vuepic/vue-datepicker';
    //imports models and enums
    import { CountType, ProductiveTypes, NeedStatus, NumberFilterType, DateFilterType } from "@/enums/enum";
    import { AppMode} from "@/enums/enum";

    //Import Componenets
    import ToasterPopup from "../../shared/ToasterPopup.vue";
    import ExportToExcelForTabs from '../header/ExportToExcelForTabs.vue';

    import {
        NeuTableRowCount,
        NeuTable,
        NeuTableRow,
        NeuLabel,
        NeuTableBody,
        NeuTableHeading,
        NeuCard
    } from "@neutron/vue";

    class Props {
        unschStaffTabInput!: any
    }

    @Options({
        computed: {
            ...mapState('assignments', ['availableStaff', 'availableStaffSMSSubscription']),
        },
        components: {
            ToasterPopup,
            NeuTableRowCount,
            NeuTable,
            NeuTableRow,
            NeuLabel,
            NeuTableBody,
            NeuTableHeading,
            NeuCard,
            Datepicker,
            ExportToExcelForTabs
        }
    })
    export default class UnscheduledStaff extends Vue.with(Props) {
        //Data properties
        availableStaff!: any;
        unavailableStaff!: any;
        availableStaffSMSSubscription!: any;
        isShowLoader: boolean = false;
        isShowAvailStaffContent: boolean = false;
        isShowUnavailStaffContent: boolean = false;
        isShowAvailMessage: boolean = false;
        isShowUnavailMessage: boolean = false;
        isFilterDataFromHeader: boolean = false;
        isRNExperienceFeatureEnabled: boolean = false;
        unschMessage: string = "";
        unavailMessage: string = "";
        availGridData: any = null;
        availGridDataForFilter: any = null;
        unavailGridData: any = null;
        unavailGridDataForFilter: any = null;
        filterVisibleOnGrid: string = "";
        filterColumn_avail: string = "";
        filterColumn_unavail: string = "";
        filterText_avail: string = "";
        NumberfilterText_avail: string = "";
        DateFilterText_avail: string = "";
        filterText_unavail: string = "";
        NumberfilterText_unavail: string = "";
        DateFilterText_unavail: string = "";
        //filterDateFormat: string = "";
        availFilterTextList: any[] = [];
        unavailFilterTextList: any[] = [];
        filterColumnList: any[] = ["Name", "Skill", "Comps", "Type", "Worked Hrs", "Sched. Hrs", "Last Worked Date", "Last Worked Time", "Next Assignment Date", "Next Assignment Time"];
        sortingColumnOnAST: string = "";
        availSortColumnList: any[] = [
            {
                column: "Name",
                sortOrder: ""
            },
            {
                column: "Skill",
                sortOrder: ""
            },
            {
                column: "Comps",
                sortOrder: ""
            },
            {
                column: "Type",
                sortOrder: ""
            },
            {
                column: "Worked Hrs",
                sortOrder: ""
            },
            {
                column: "Sched. Hrs",
                sortOrder: ""
            },
            {
                column: "RN Experience",
                sortOrder: ""
            },
            {
                column: "Hire Date",
                sortOrder: ""
            },
            {
                column: "Seniority Date",
                sortOrder: ""
            },
            {
                column: "Last Worked Date",
                sortOrder: ""
            },
            {
                column: "Last Worked Time",
                sortOrder: ""
            },
            {
                column: "Next Assignment Date",
                sortOrder: ""
            },
            {
                column: "Next Assignment Time",
                sortOrder: ""
            }
        ];
        unavailSortColumnList: any[] = [
            {
                column: "Name",
                sortOrder: ""
            },
            {
                column: "Skill",
                sortOrder: ""
            },
            {
                column: "Comps",
                sortOrder: ""
            },
            {
                column: "Type",
                sortOrder: ""
            },
            {
                column: "Worked Hrs",
                sortOrder: ""
            },
            {
                column: "Sched. Hrs",
                sortOrder: ""
            },
            {
                column: "Hire Date",
                sortOrder: ""
            },
            {
                column: "Seniority Date",
                sortOrder: ""
            },
            {
                column: "Last Worked Date",
                sortOrder: ""
            },
            {
                column: "Last Worked Time",
                sortOrder: ""
            },
            {
                column: "Next Assignment Date",
                sortOrder: ""
            },
            {
                column: "Next Assignment Time",
                sortOrder: ""
            }
        ];

        textFilter: any[] = [{
            Id: 1,
            Value: "Contains"
        }];
        numberFilter: any[] = [
            {
                Id: 2,
                Value: "Is equal to"
            },
            {
                Id: 3,
                Value: "Greater than or equal to"
            },
            {
                Id: 4,
                Value: "Less than or equal to"
            }
        ];
        dateFilter: any[] = [
            {
                Id: 2,
                Value: "Equal"
            },
            {
                Id: 3,
                Value: "After or equal to"
            },
            {
                Id: 4,
                Value: "Before or equal to"
            }];
        selectedNumberFilter_avail: any = 2;
        selectedDateFilter_avail: any = 2;
        selectedTextFilter_avail: any = 1;
        selectedNumberFilter_unavail: any = 2;
        selectedDateFilter_unavail: any = 2;
        selectedTextFilter_unavail: any = 1;
        availFilteredData: any = null;
        unavailFilteredData: any = null;
        lstType_avail: any = [];
        lstLastWorkedTime_avail: any = [];
        lstNextAssignmentTime_avail: any = [];
        lstType_unavail: any = [];
        lstLastWorkedTime_unavail: any = [];
        lstNextAssignmentTime_unavail: any = [];
        selectedTypeFilter_avail: any = [];
        selectedLastWorkTimeFilter_avail: any = [];
        selectedNextAssignementTimeFilter_avail: any = [];
        selectedTypeFilter_unavail: any = [];
        selectedLastWorkTimeFilter_unavail: any = [];
        selectedNextAssignementTimeFilter_unavail: any = [];
        isCheckAllTypeFilter_avail: boolean = false;
        isCheckAllWorkTimeFilter_avail: boolean = false;
        isCheckAllNextAssignTimeFilter_avail: boolean = false;
        isCheckAllTypeFilter_unavail: boolean = false;
        isCheckAllWorkTimeFilter_unavail: boolean = false;
        isCheckAllNextAssignTimeFilter_unavail: boolean = false;

        associationTypes: any = {
            primary: 1,
            secondary: 2,
            unassociated: 3

        };
        columnHeadersAvail: any = {
            "Name": "10%",
            "Skill": "6%",
            "Comps": "7%",
            "Type": "5%",
            "Worked Hrs": "6%",
            "Sched. Hrs": "6%",
            "RN Experience": "7%",
            "Hire Date": "8%",
            "Seniority Date": "8%",
            "Last Worked Date": "8%",
            "Last Worked Time": "7%",
            "Next Assignment Date": "8%",
            "Next Assignment Time": "7%"
        };

        columnHeadersUnavail: any = {
            "Name": "10%",
            "Skill": "6%",
            "Comps": "7%",
            "Type": "6%",
            "Worked Hrs": "9%",
            "Sched. Hrs": "9%",
            "Hire Date": "9%",
            "Seniority Date": "9%",
            "Last Worked Date": "7%",
            "Last Worked Time": "7%",
            "Next Assignment Date": "7%",
            "Next Assignment Time": "7%"
        };
        columnIcons: any = {
            "Name": "desc",
            "Skill": "desc",
            "Comps": "desc",
            "Type": "desc",
            "Worked Hrs": "desc",
            "Sched. Hrs": "desc",
            "RN Experience": "desc",
            "Hire Date": "desc",
            "Seniority Date": "desc",
            "Last Worked Date": "desc",
            "Last Worked Time": "desc",
            "Next Assignment Date": "desc",
            "Next Assignment Time": "desc"
        };
        columnForSort: any = {
            "Name": "lastName",
            "Skill": "skillDescription",
            "Comps": "competencies",
            "Type": "employeeType",
            "Worked Hrs": "hoursWorked",
            "Sched. Hrs": "hours",
            "Hire Date": "hireDate",
            "Seniority Date": "seniorityDate",
            "Last Worked Date": "lastWorked",
            "Last Worked Time": "lastWorked",
            "Next Assignment Date": "nextAssignmentDate",
            "Next Assignment Time": "nextAssignmentStart"
        };
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        isShowExportToExcel: boolean = false;
        payloadExportToExcel!: any;

        //Lifecycle hooks
        async mounted() {
            await this.loadUnscheduleStaffingDetails();
            this.closeFilterBox();
        }

        created() {
            this.emitter.on("filterUnscheduleStaffGridDataBySkill", this.filterUnscheduleStaffGridDataBySkill);
            this.emitter.on("clearUnscheduleStaffGridDataBySkill", this.clearUnscheduleStaffGridDataBySkill);
            this.emitter.on("refreshUnscheduleStaffingDetails", this.loadUnscheduleStaffingDetails);
            this.emitter.on("unscheduledTabExportToExcel", this.unscheduledTabExportToExcel);
        }

        unmounted() {
            this.emitter.off("filterUnscheduleStaffGridDataBySkill", this.filterUnscheduleStaffGridDataBySkill);
            this.emitter.off("clearUnscheduleStaffGridDataBySkill", this.clearUnscheduleStaffGridDataBySkill);
            this.emitter.off("refreshUnscheduleStaffingDetails", this.loadUnscheduleStaffingDetails);
            this.emitter.off("unscheduledTabExportToExcel", this.unscheduledTabExportToExcel);
        }

        //Methods
        getName(dataItem: any) {
            return dataItem.lastName + ", " + dataItem.firstName;
        }

        async loadUnscheduleStaffingDetails() {
            if (this.unschStaffTabInput != null) {
                this.showLoader();
                var inputData = {
                    DepartmentId: this.unschStaffTabInput.DataSet.DepartmentId,
                    date: this.unschStaffTabInput.DataSet.StartDateTime
                }
                await this.$store
                    .dispatch("assignments/getAvailableStaff", inputData)
                    .then(() => {
                        if (this.availableStaff != null && this.availableStaff.length > 0) {
                            this.availGridData = this.processStaffData(false);
                            this.unavailGridData = this.processStaffData(true);
                            this.availGridDataForFilter = JSON.parse(JSON.stringify(this.availGridData));
                            this.unavailGridDataForFilter = JSON.parse(JSON.stringify(this.unavailGridData));
                            this.lstType_avail = this.availGridData.map((item: any) => { return item.employeeType; })
                                .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
                            this.lstLastWorkedTime_avail = this.availGridData.map((item: any) => { return this.setGridTimeFormat(item.lastWorked); })
                                .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
                            this.lstNextAssignmentTime_avail = this.availGridData.map((item: any) => { return this.setGridTimeFormat(item.nextAssignmentStart); })
                                .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

                            if (this.availGridData != null && this.availGridData.length > 0) {
                                this.isRNExperienceFeatureEnabled = this.availGridData[0].rnExperienceFeatureEnabled
                            }
                            this.isShowAvailMessage = false;
                            this.isShowAvailStaffContent = true;

                            this.lstType_unavail = this.unavailGridData.map((item: any) => { return item.employeeType; })
                                .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
                            this.lstLastWorkedTime_unavail = this.unavailGridData.map((item: any) => { return this.setGridTimeFormat(item.lastWorked); })
                                .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
                            this.lstNextAssignmentTime_unavail = this.unavailGridData.map((item: any) => { return this.setGridTimeFormat(item.nextAssignmentStart); })
                                .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

                            this.isShowUnavailMessage = false;
                            this.isShowUnavailStaffContent = true;
                        }
                        else {
                            this.unschMessage = "No resources found";
                            this.isShowAvailMessage = true;
                            this.isShowAvailStaffContent = false;

                            this.unavailMessage = "No resources found";
                            this.isShowUnavailMessage = true;
                            this.isShowUnavailStaffContent = false;
                        }
                        this.hideLoader();
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideLoader();
                            this.msgValue = "Error encountered while retrieving available staff details.";
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                            console.error(err); // Handle errors any way you want
                        }
                    });
            }
        }

        processStaffData(unAvailableStaffList: boolean) {
            var staff: any = [];
            var found = false;
            var temp = JSON.parse(JSON.stringify(this.availableStaff));
            var info: any = this.unschStaffTabInput;

            temp.sort(function (a: any, b: any) {
                var isPrimaryA = Number(a.isPrimarySkill);
                var isPrimaryB = Number(b.isPrimarySkill);

                if (isPrimaryA > isPrimaryB) {
                    return -1;
                }
                if (isPrimaryA < isPrimaryB) {
                    return 1;
                }
                return 0;
            });

            temp.forEach(function (item: any, index: any) {
                if (unAvailableStaffList === item.isStaffUnavailable) {
                    if (index === 0) {
                        staff.push(item);
                    }
                    else {
                        found = false;
                        for (var i = 0; i < staff.length; i++) {
                            if (item.staffId === staff[i].staffId) {
                                if (staff[i].skillDescription.indexOf(item.skillDescription) === -1) {
                                    staff[i].skillDescription += ', ' + item.skillDescription;
                                }
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            staff.push(item);
                        }
                    }
                }
            });

            var that = this;
            staff.forEach(function (value: any, indx: any) {

                value.facilityId = info.DataSet.FacilityId;
                value.facilityName = info.DataSet.FacilityName;
                value.clusterId = info.DataSet.ClusterId;
                value.clusterName = info.DataSet.ClusterName;

                if (value.competencies && value.competencies.length > 0) {
                    value.competencyCodes = value.competencies.map(function (competency: any) {
                        return competency.code;
                    }).join(", ");
                }

                var date: any = '';
                var nextAssignmentDate: any = '';
                var nextAssignmentTime: any = '';

                if (value.nextAssignmentStart !== null) {
                    date = new Date(value.nextAssignmentStart);
                    nextAssignmentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    nextAssignmentTime = new Date(1, 1, 1970, date.getHours(), date.getMinutes(), 0, 0);
                }

                value.name = value.lastName + ', ' + value.firstName;
                value.nextAssignmentDate = nextAssignmentDate;
                value.nextAssignmentTime = nextAssignmentTime;

                date = new Date(value.lastWorked);
                value.lastWorkedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                value.lastWorkedTime = new Date(1, 1, 1970, date.getHours(), date.getMinutes(), 0, 0);

                value.availabilityDeclaredOn = value.availabilityDeclaredOn ? value.availabilityDeclaredOn.split(',') : '';
                value.timeAvailable = value.timeAvailable ? value.timeAvailable.split(',') : '';
                value.preference = value.preference ? value.preference.split(',') : '';
                value.detailSort = that.detailSortValue(value.details, value.preference[1]);
            });
            return staff;
        }

        detailSortValue(availability: any, preference: any) {
            var sort = 3;
            if (availability) {
                sort = 1;
            } else
                if (preference) {
                    sort = Number(preference) === 1 ? 2 : 4;
                }
            return sort;
        }

        setGridDateFormat(date: any) {
            if (date != null && date != '') {
                var formattedDate: any = moment(date).format("MM/DD/YYYY");
                return formattedDate;
            }
            else {
                return '';
            }
        }
        setGridTimeFormat(time: any) {
            if (time != null && time != '') {
                var formattedTime: any = moment(time).format("hh:mm A");
                return formattedTime;
            }
            else {
                return '';
            }
        }
        showLoader() {
            this.isShowLoader = true;
            this.isShowAvailStaffContent = false;
            this.isShowAvailMessage = false;
        }

        hideLoader() {
            this.isShowLoader = false;
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }

        onRowSelect(dataItem: any) {
            if(this.unschStaffTabInput.AppMode === AppMode.FullAccess){
                var currentDataItem = dataItem;
                var info = {
                    AssignId: '00000000-0000-0000-0000-000000000000',
                    FacilityId: currentDataItem.facilityId,
                    FacilityName: currentDataItem.facilityName,
                    ClusterId: currentDataItem.clusterId,
                    ClusterName: currentDataItem.clusterName,
                    DepartmentId: currentDataItem.departmentId,
                    DepartmentName: currentDataItem.departmentName
                };

                currentDataItem.actionableDepartment = info;
                currentDataItem.config = this.unschStaffTabInput.Config;
                currentDataItem.tabName = "UnSchedule Staff";
                this.emitter.emit('createAssignment', currentDataItem);
            }
        }

        sortAvailUnavailStaffTable(columnName: any, flag: any) {
            var item: any;
            if (flag.toLowerCase() == 'avail') {
                var anyExistingSortColumn: any = this.availSortColumnList.filter(x => x.column != columnName && x.sortOrder != "");
                if (anyExistingSortColumn != null && anyExistingSortColumn.length > 0) {
                    return;
                }
                item = this.availSortColumnList.filter(x => x.column == columnName);
            }
            else {
                var anyExistingSortColumn: any = this.unavailSortColumnList.filter(x => x.column != columnName && x.sortOrder != "");
                if (anyExistingSortColumn != null && anyExistingSortColumn.length > 0) {
                    return;
                }
                item = this.unavailSortColumnList.filter(x => x.column == columnName);
            }

            if (item[0].column.toLowerCase() != "Comps".toLowerCase()) {
                if ((flag.toLowerCase() == 'avail' && this.availGridData != null && this.availGridData.length > 0) || (flag.toLowerCase() === 'unavail' && this.unavailGridData != null && this.unavailGridData.length > 0)) {
                    item[0].sortOrder = (item[0].sortOrder == "" ? "asc" : (item[0].sortOrder.toLowerCase() == "asc" ? "desc" : ""));
                    if (item[0].sortOrder == "") {
                        if (flag.toLowerCase() == 'avail') {
                            this.availGridData = JSON.parse(JSON.stringify(this.availGridDataForFilter));
                            return this.availGridData;
                        }
                        else {
                            this.unavailGridData = JSON.parse(JSON.stringify(this.unavailGridDataForFilter));
                            return this.unavailGridData;
                        }
                    }
                }
            }           

            if (flag.toLowerCase() == 'avail') {
                return this.availGridData.sort((p1: any, p2: any) => {
                    let modifier = 1;
                    if (item[0].sortOrder.toLowerCase() == 'desc') modifier = -1;
                    if (item[0].column.toLowerCase() == "Name".toLowerCase()) {
                        if (p1["LastName"] < p2["LastName"]) return -1 * modifier; if (p1["LastName"] > p2["LastName"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Skill".toLowerCase()) {
                        if (p1["SkillDescription"] < p2["SkillDescription"]) return -1 * modifier; if (p1["SkillDescription"] > p2["SkillDescription"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Comps".toLowerCase()) {
                        if (p1["Competencies"].length > 0 && this.availGridData != null && this.availGridData.length > 0) {
                            item[0].sortOrder = (item[0].sortOrder == "" ? "asc" : (item[0].sortOrder.toLowerCase() == "asc" ? "desc" : ""));
                            if (item[0].sortOrder == "") {
                                this.availGridData = JSON.parse(JSON.stringify(this.availGridDataForFilter));
                                return this.availGridData;
                            }
                            if (p1["competencyCodes"] < p2["competencyCodes"]) return -1 * modifier; if (p1["competencyCodes"] > p2["competencyCodes"]) return 1 * modifier;
                        }
                    }
                    else if (item[0].column.toLowerCase() == "Type".toLowerCase()) {
                        if (p1["EmployeeType"] < p2["EmployeeType"]) return -1 * modifier; if (p1["EmployeeType"] > p2["EmployeeType"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Worked Hrs".toLowerCase()) {
                        if (p1["HoursWorked"] < p2["HoursWorked"]) return -1 * modifier; if (p1["HoursWorked"] > p2["HoursWorked"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Sched. Hrs".toLowerCase()) {
                        if (p1["Hours"] < p2["Hours"]) return -1 * modifier; if (p1["Hours"] > p2["Hours"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "RN Experience".toLowerCase()) {
                        if (p1["RNExperience"] < p2["RNExperience"]) return -1 * modifier; if (p1["RNExperience"] > p2["RNExperience"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Hire Date".toLowerCase()) {
                        if (p1["HireDate"] < p2["HireDate"]) return -1 * modifier; if (p1["HireDate"] > p2["HireDate"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Seniority Date".toLowerCase()) {
                        if (p1["SeniorityDate"] < p2["SeniorityDate"]) return -1 * modifier; if (p1["SeniorityDate"] > p2["SeniorityDate"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Last Worked Date".toLowerCase()) {
                        if (p1["LastWorked"] < p2["LastWorked"]) return -1 * modifier; if (p1["LastWorked"] > p2["LastWorked"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Last Worked Time".toLowerCase()) {
                        if (p1["LastWorkedTime"] < p2["LastWorkedTime"]) return -1 * modifier; if (p1["LastWorkedTime"] > p2["LastWorkedTime"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Next Assignment Date".toLowerCase()) {
                        if (p1["NextAssignmentDate"] < p2["NextAssignmentDate"]) return -1 * modifier; if (p1["NextAssignmentDate"] > p2["NextAssignmentDate"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Next Assignment Time".toLowerCase()) {
                        if (p1["NextAssignmentTime"] < p2["NextAssignmentTime"]) return -1 * modifier; if (p1["NextAssignmentTime"] > p2["NextAssignmentTime"]) return 1 * modifier;
                    }
                    else {
                        if (p1[item[0].column] < p2[item[0].column]) return -1 * modifier; if (p1[item[0].column] > p2[item[0].column]) return 1 * modifier;
                    }
                    return 0;
                });
            }
            else {
                return this.unavailGridData.sort((p1: any, p2: any) => {
                    let modifier = 1;
                    if (item[0].sortOrder.toLowerCase() == 'desc') modifier = -1;
                    if (item[0].column.toLowerCase() == "Name".toLowerCase()) {
                        if (p1["LastName"] < p2["LastName"]) return -1 * modifier; if (p1["LastName"] > p2["LastName"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Skill".toLowerCase()) {
                        if (p1["SkillDescription"] < p2["SkillDescription"]) return -1 * modifier; if (p1["SkillDescription"] > p2["SkillDescription"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Comps".toLowerCase()) {
                        if (p1["Competencies"].length > 0 && this.unavailGridData != null && this.unavailGridData.length > 0) {
                            item[0].sortOrder = (item[0].sortOrder == "" ? "asc" : (item[0].sortOrder.toLowerCase() == "asc" ? "desc" : ""));
                            if (item[0].sortOrder == "") {
                                this.unavailGridData = JSON.parse(JSON.stringify(this.unavailGridDataForFilter));
                                return this.unavailGridData;
                            }
                            if (p1["competencyCodes"] < p2["competencyCodes"]) return -1 * modifier; if (p1["competencyCodes"] > p2["competencyCodes"]) return 1 * modifier;
                        }
                    }
                    else if (item[0].column.toLowerCase() == "Type".toLowerCase()) {
                        if (p1["EmployeeType"] < p2["EmployeeType"]) return -1 * modifier; if (p1["EmployeeType"] > p2["EmployeeType"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Worked Hrs".toLowerCase()) {
                        if (p1["HoursWorked"] < p2["HoursWorked"]) return -1 * modifier; if (p1["HoursWorked"] > p2["HoursWorked"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Sched. Hrs".toLowerCase()) {
                        if (p1["Hours"] < p2["Hours"]) return -1 * modifier; if (p1["Hours"] > p2["Hours"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Hire Date".toLowerCase()) {
                        if (p1["HireDate"] < p2["HireDate"]) return -1 * modifier; if (p1["HireDate"] > p2["HireDate"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Seniority Date".toLowerCase()) {
                        if (p1["SeniorityDate"] < p2["SeniorityDate"]) return -1 * modifier; if (p1["SeniorityDate"] > p2["SeniorityDate"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Last Worked Date".toLowerCase()) {
                        if (p1["LastWorked"] < p2["LastWorked"]) return -1 * modifier; if (p1["LastWorked"] > p2["LastWorked"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Last Worked Time".toLowerCase()) {
                        if (p1["LastWorkedTime"] < p2["LastWorkedTime"]) return -1 * modifier; if (p1["LastWorkedTime"] > p2["LastWorkedTime"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Next Assignment Date".toLowerCase()) {
                        if (p1["NextAssignmentDate"] < p2["NextAssignmentDate"]) return -1 * modifier; if (p1["NextAssignmentDate"] > p2["NextAssignmentDate"]) return 1 * modifier;
                    }
                    else if (item[0].column.toLowerCase() == "Next Assignment Time".toLowerCase()) {
                        if (p1["NextAssignmentTime"] < p2["NextAssignmentTime"]) return -1 * modifier; if (p1["NextAssignmentTime"] > p2["NextAssignmentTime"]) return 1 * modifier;
                    }
                    else {
                        if (p1[item[0].column] < p2[item[0].column]) return -1 * modifier; if (p1[item[0].column] > p2[item[0].column]) return 1 * modifier;
                    }
                    return 0;
                });
            }
        }

        getOrder(columnName: any, flag: any) {
            var item: any;
            if (flag.toLowerCase() == 'avail') {
                item = this.availSortColumnList.filter(x => x.column == columnName);
            }
            else {
                item = this.unavailSortColumnList.filter(x => x.column == columnName);
            }
            return item[0].sortOrder;
        }

        onFilterVisible(event: any, columnName: any, flag: any) {
            event.stopPropagation();
            this.filterVisibleOnGrid = flag;
            var filterPanel: any;
            let panelTopPosition: any;
            if (flag.toLowerCase() == 'avail') {
                if ((this.availGridData == null || this.availGridData.length == 0) && (this.availGridDataForFilter == null || this.availGridDataForFilter.length == 0)) {
                    return;
                }
                this.filterText_avail = "";
                this.NumberfilterText_avail = "";
                this.DateFilterText_avail = "";
                filterPanel = document.getElementById('filterModel_avail');
                this.filterColumn_avail = columnName;
                panelTopPosition = this.availGridData != null && this.availGridData.length > 4 ? event.clientY - 58 : event.clientY - 225;
            }
            else {
                if ((this.unavailGridData == null || this.unavailGridData.length == 0) && (this.unavailGridDataForFilter == null || this.unavailGridDataForFilter.length == 0)) {
                    return;
                }
                this.filterText_unavail = "";
                this.NumberfilterText_unavail = "";
                this.DateFilterText_unavail = "";
                filterPanel = document.getElementById('filterModel_unavail');
                this.filterColumn_unavail = columnName;
                panelTopPosition = this.unavailGridData != null && this.unavailGridData.length > 6 ? event.clientY - 58 : event.clientY - 225;
            }

            filterPanel.style.left = event.clientX + 'px';
            filterPanel.style.top = panelTopPosition + 'px';
            this.setFilterText(flag);
            filterPanel.classList.remove('d-none');
        }

        onGridFilter() {
            var filterPanel: any;
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                filterPanel = document.getElementById('filterModel_avail');
                if ((this.filterColumn_avail.toLowerCase() != 'Worked Hrs'.toLowerCase() && this.filterText_avail != null && this.filterText_avail != '') ||
                    (this.filterColumn_avail.toLowerCase() != "Sched. Hrs".toLowerCase() && this.filterText_avail != null && this.filterText_avail != '') ||
                    (this.filterColumn_avail.toLowerCase() != 'Last Worked Date'.toLowerCase() && this.filterText_avail != null && this.filterText_avail != '') ||
                    (this.filterColumn_avail.toLowerCase() != 'Next Assignment Date'.toLowerCase() && this.filterText_avail != null && this.filterText_avail != '') ||
                    (this.filterColumn_avail.toLowerCase() == 'Worked Hrs'.toLowerCase() && this.NumberfilterText_avail != null && Number(this.NumberfilterText_avail) >= 0) ||
                    (this.filterColumn_avail.toLowerCase() == 'Sched. Hrs'.toLowerCase() && this.NumberfilterText_avail != null && Number(this.NumberfilterText_avail) >= 0) ||
                    (this.filterColumn_avail.toLowerCase() == 'Last Worked Date'.toLowerCase() && this.DateFilterText_avail != null && this.DateFilterText_avail != '') ||
                    (this.filterColumn_avail.toLowerCase() == 'Next Assignment Date'.toLowerCase() && this.DateFilterText_avail != null && this.DateFilterText_avail != '') ||
                    (this.filterColumn_avail.toLowerCase() == 'Type'.toLowerCase() && this.selectedTypeFilter_avail != null && this.selectedTypeFilter_avail.length != 0) ||
                    (this.filterColumn_avail.toLowerCase() == 'Last Worked Time'.toLowerCase() && this.selectedLastWorkTimeFilter_avail != null && this.selectedLastWorkTimeFilter_avail.length != 0) ||
                    (this.filterColumn_avail.toLowerCase() == 'Next Assignment Time'.toLowerCase() && this.selectedNextAssignementTimeFilter_avail != null && this.selectedNextAssignementTimeFilter_avail.length != 0)
                ) {
                    if (this.availFilteredData == null) {
                        this.availFilteredData = JSON.parse(JSON.stringify(this.availGridData));
                    }
                    if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                        var that = this;
                        //@ts-ignore
                        var isFilterExists = jslinq(this.availFilterTextList)
                            .where(function (data: any) {
                                if (data.filterColumn == that.filterColumn_avail) {
                                    return data;
                                }
                            })
                            .toList();

                        if (isFilterExists != null && isFilterExists.length > 0) {
                            this.availFilterTextList.forEach((data) => {
                                if (data.filterColumn == this.filterColumn_avail) {
                                    if (this.filterColumn_avail.toLowerCase() == 'Worked Hrs'.toLowerCase() || this.filterColumn_avail.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                                        data.filterText = this.NumberfilterText_avail;
                                        data.filterSelectedValue = this.selectedNumberFilter_avail;
                                    }
                                    else if (this.filterColumn_avail.toLowerCase() == 'Last Worked Date'.toLowerCase() || this.filterColumn_avail.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                                        data.filterText = this.DateFilterText_avail;
                                        data.filterSelectedValue = this.selectedDateFilter_avail;
                                    }
                                    else {
                                        data.filterText = this.filterText_avail;
                                        data.filterSelectedValue = this.selectedTextFilter_avail;
                                    }
                                }
                            })
                        }
                        else {
                            this.AddFilterListItem();
                        }
                    }
                    else {
                        this.AddFilterListItem();
                    }
                    this.updateGridDataBySelectedFilter();
                    var selectedFilterIcon: any = document.querySelector("[column='" + this.filterColumn_avail + "']");
                    selectedFilterIcon.classList.add('filterSelectedIcon');
                    filterPanel.classList.add('d-none');
                }
                else {
                    this.msgValue = "Please enter text to filter";
                    this.classFlag = "error";
                    this.isToasterVisible = true;
                }
            }
            else {
                filterPanel = document.getElementById('filterModel_unavail');
                if ((this.filterColumn_unavail.toLowerCase() != 'Worked Hrs'.toLowerCase() && this.filterText_unavail != null && this.filterText_unavail != '') ||
                    (this.filterColumn_unavail.toLowerCase() != "Sched. Hrs".toLowerCase() && this.filterText_unavail != null && this.filterText_unavail != '') ||
                    (this.filterColumn_unavail.toLowerCase() != 'Last Worked Date'.toLowerCase() && this.filterText_unavail != null && this.filterText_unavail != '') ||
                    (this.filterColumn_unavail.toLowerCase() != 'Next Assignment Date'.toLowerCase() && this.filterText_unavail != null && this.filterText_unavail != '') ||
                    (this.filterColumn_unavail.toLowerCase() == 'Worked Hrs'.toLowerCase() && this.NumberfilterText_unavail != null && Number(this.NumberfilterText_unavail) >= 0) ||
                    (this.filterColumn_unavail.toLowerCase() == 'Sched. Hrs'.toLowerCase() && this.NumberfilterText_unavail != null && Number(this.NumberfilterText_unavail) >= 0) ||
                    (this.filterColumn_unavail.toLowerCase() == 'Last Worked Date'.toLowerCase() && this.DateFilterText_unavail != null && this.DateFilterText_unavail != '') ||
                    (this.filterColumn_unavail.toLowerCase() == 'Next Assignment Date'.toLowerCase() && this.DateFilterText_unavail != null && this.DateFilterText_unavail != '') ||
                    (this.filterColumn_unavail.toLowerCase() == 'Type'.toLowerCase() && this.selectedTypeFilter_unavail != null && this.selectedTypeFilter_unavail.length != 0) ||
                    (this.filterColumn_unavail.toLowerCase() == 'Last Worked Time'.toLowerCase() && this.selectedLastWorkTimeFilter_unavail != null && this.selectedLastWorkTimeFilter_unavail.length != 0) ||
                    (this.filterColumn_unavail.toLowerCase() == 'Next Assignment Time'.toLowerCase() && this.selectedNextAssignementTimeFilter_unavail != null && this.selectedNextAssignementTimeFilter_unavail.length != 0)
                ) {
                    if (this.unavailFilteredData == null) {
                        this.unavailFilteredData = JSON.parse(JSON.stringify(this.unavailGridData));
                    }
                    if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                        var that = this;
                        //@ts-ignore
                        var isFilterExists = jslinq(this.unavailFilterTextList)
                            .where(function (data: any) {
                                if (data.filterColumn == that.filterColumn_unavail) {
                                    return data;
                                }
                            })
                            .toList();

                        if (isFilterExists != null && isFilterExists.length > 0) {
                            this.unavailFilterTextList.forEach((data) => {
                                if (data.filterColumn == this.filterColumn_unavail) {
                                    if (this.filterColumn_unavail.toLowerCase() == 'Worked Hrs'.toLowerCase() || this.filterColumn_unavail.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                                        data.filterText = this.NumberfilterText_unavail;
                                        data.filterSelectedValue = this.selectedNumberFilter_unavail;
                                    }
                                    else if (this.filterColumn_unavail.toLowerCase() == 'Last Worked Date'.toLowerCase() || this.filterColumn_unavail.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                                        data.filterText = this.DateFilterText_unavail;
                                        data.filterSelectedValue = this.selectedDateFilter_unavail;
                                    }
                                    else {
                                        data.filterText = this.filterText_unavail;
                                        data.filterSelectedValue = this.selectedTextFilter_unavail;
                                    }
                                }
                            })
                        }
                        else {
                            this.AddFilterListItem();
                        }
                    }
                    else {
                        this.AddFilterListItem();
                    }
                    this.updateGridDataBySelectedFilter();
                    var selectedFilterIcon: any = document.querySelector("[column='" + this.filterColumn_unavail + "']");
                    selectedFilterIcon.classList.add('filterSelectedIcon');
                    filterPanel.classList.add('d-none');
                }
                else {
                    this.msgValue = "Please enter text to filter";
                    this.classFlag = "error";
                    this.isToasterVisible = true;
                }
            }
        }

        onGridFilterClear() {
            var filterPanel: any;
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                filterPanel = document.getElementById('filterModel_avail');
                if (this.filterColumn_avail.toLowerCase() == 'Type'.toLowerCase()) {
                    this.selectedTypeFilter_avail = [];
                    this.isCheckAllTypeFilter_avail = false;
                }
                else if (this.filterColumn_avail.toLowerCase() == 'Last Worked Time'.toLowerCase()) {
                    this.selectedLastWorkTimeFilter_avail = [];
                    this.isCheckAllWorkTimeFilter_avail = false;
                }
                else if (this.filterColumn_avail.toLowerCase() == 'Next Assignment Time'.toLowerCase()) {
                    this.selectedNextAssignementTimeFilter_avail = [];
                    this.isCheckAllNextAssignTimeFilter_avail = false;
                }

                if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                    for (var i = 0; i < this.availFilterTextList.length; i++) {
                        if (this.availFilterTextList[i].filterColumn == this.filterColumn_avail) {
                            this.availFilterTextList.splice(i, 1);
                            var selectedFilterIcon: any = document.querySelector("[column='" + this.filterColumn_avail + "']");
                            selectedFilterIcon.classList.remove('filterSelectedIcon');
                        }
                    }
                    if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                        var lastItemIndex = this.availFilterTextList.length - 1;
                        this.filterColumn_avail = this.availFilterTextList[lastItemIndex].filterColumn;
                        if (this.filterColumn_avail.toLowerCase() == 'Worked Hrs'.toLowerCase() || this.filterColumn_avail.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                            this.NumberfilterText_avail = this.availFilterTextList[lastItemIndex].filterText;
                            this.selectedNumberFilter_avail = this.availFilterTextList[lastItemIndex].filterSelectedValue;
                        }
                        else if (this.filterColumn_avail.toLowerCase() == 'Last Worked Date'.toLowerCase() || this.filterColumn_avail.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                            this.DateFilterText_avail = this.availFilterTextList[lastItemIndex].filterText;
                            this.selectedDateFilter_avail = this.availFilterTextList[lastItemIndex].filterSelectedValue;
                        }
                        else {
                            this.filterText_avail = this.availFilterTextList[lastItemIndex].filterText;
                            this.selectedTextFilter_avail = this.availFilterTextList[lastItemIndex].filterSelectedValue;
                        }
                        this.updateGridDataBySelectedFilter();
                        //this.availGridData = this.availFilterTextList[lastItemIndex].filterData;
                    }
                    else {
                        this.availGridData = JSON.parse(JSON.stringify(this.availFilteredData));
                        this.NumberfilterText_avail = "";
                        this.filterText_avail = "";
                        this.DateFilterText_avail = "";
                        this.selectedTextFilter_avail = 1;
                        this.selectedNumberFilter_avail = 2;
                        this.selectedDateFilter_avail = 2;

                        this.isFilterDataFromHeader = false;
                    }
                }
            }
            else {
                filterPanel = document.getElementById('filterModel_unavail');
                if (this.filterColumn_unavail.toLowerCase() == 'Type'.toLowerCase()) {
                    this.selectedTypeFilter_unavail = [];
                    this.isCheckAllTypeFilter_unavail = false;
                }
                else if (this.filterColumn_unavail.toLowerCase() == 'Last Worked Time'.toLowerCase()) {
                    this.selectedLastWorkTimeFilter_unavail = [];
                    this.isCheckAllWorkTimeFilter_unavail = false;
                }
                else if (this.filterColumn_unavail.toLowerCase() == 'Next Assignment Time'.toLowerCase()) {
                    this.selectedNextAssignementTimeFilter_unavail = [];
                    this.isCheckAllNextAssignTimeFilter_unavail = false;
                }

                if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                    for (var i = 0; i < this.unavailFilterTextList.length; i++) {
                        if (this.unavailFilterTextList[i].filterColumn == this.filterColumn_unavail) {
                            this.unavailFilterTextList.splice(i, 1);
                            var selectedFilterIcon: any = document.querySelector("[column='" + this.filterColumn_unavail + "']");
                            selectedFilterIcon.classList.remove('filterSelectedIcon');
                        }
                    }
                    if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                        var lastItemIndex = this.unavailFilterTextList.length - 1;
                        this.filterColumn_unavail = this.unavailFilterTextList[lastItemIndex].filterColumn;
                        if (this.filterColumn_unavail.toLowerCase() == 'Worked Hrs'.toLowerCase() || this.filterColumn_unavail.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                            this.NumberfilterText_unavail = this.unavailFilterTextList[lastItemIndex].filterText;
                            this.selectedNumberFilter_unavail = this.unavailFilterTextList[lastItemIndex].filterSelectedValue;
                        }
                        else if (this.filterColumn_unavail.toLowerCase() == 'Last Worked Date'.toLowerCase() || this.filterColumn_unavail.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                            this.DateFilterText_unavail = this.unavailFilterTextList[lastItemIndex].filterText;
                            this.selectedDateFilter_unavail = this.unavailFilterTextList[lastItemIndex].filterSelectedValue;
                        }
                        else {
                            this.filterText_unavail = this.unavailFilterTextList[lastItemIndex].filterText;
                            this.selectedTextFilter_unavail = this.unavailFilterTextList[lastItemIndex].filterSelectedValue;
                        }
                        this.updateGridDataBySelectedFilter();
                        //this.unavailGridData = this.unavailFilterTextList[lastItemIndex].filterData;
                    }
                    else {
                        this.unavailGridData = JSON.parse(JSON.stringify(this.unavailFilteredData));
                        this.NumberfilterText_unavail = "";
                        this.filterText_unavail = "";
                        this.DateFilterText_unavail = "";
                        this.selectedTextFilter_unavail = 1;
                        this.selectedNumberFilter_unavail = 2;
                        this.selectedDateFilter_unavail = 2;

                        this.isFilterDataFromHeader = false;
                    }
                }
            }
            filterPanel.classList.add('d-none');
        }

        AddFilterListItem() {
            var fText;
            var fSelectedValue;
            var filterColumn = this.filterVisibleOnGrid.toLowerCase() == 'avail' ? this.filterColumn_avail : this.filterColumn_unavail;
            if (filterColumn.toLowerCase() == 'Worked Hrs'.toLowerCase() || filterColumn.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                fText = this.filterVisibleOnGrid.toLowerCase() == 'avail' ? this.NumberfilterText_avail : this.NumberfilterText_unavail;
                fSelectedValue = this.filterVisibleOnGrid == 'avail' ? this.selectedNumberFilter_avail : this.selectedNumberFilter_unavail;
            }
            else if (filterColumn.toLowerCase() == 'Last Worked Date'.toLowerCase() || filterColumn.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                fText = this.filterVisibleOnGrid.toLowerCase() == 'avail' ? this.DateFilterText_avail : this.DateFilterText_unavail;
                fSelectedValue = this.filterVisibleOnGrid.toLowerCase() == 'avail' ? this.selectedDateFilter_avail : this.selectedDateFilter_unavail;
            }
            else {
                fText = this.filterVisibleOnGrid.toLowerCase() == 'avail' ? this.filterText_avail : this.filterText_unavail;
                fSelectedValue = this.filterVisibleOnGrid.toLowerCase() == 'avail' ? this.selectedTextFilter_avail : this.selectedTextFilter_unavail;
            }
            var data: any = {
                filterColumn: filterColumn,
                filterText: fText,
                filterSelectedValue: fSelectedValue,
                filterData: null
            }
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                var filterColumnExistsItem: any = this.availFilterTextList.filter(x => x.filterColumn == filterColumn);
                if (filterColumnExistsItem.length != 0) {
                    filterColumnExistsItem[0].filterText = fText;
                    filterColumnExistsItem[0].filterSelectedValue = fSelectedValue;
                }
                else {
                    this.availFilterTextList.push(data);
                }
            }
            else {
                var filterColumnExistsItem: any = this.unavailFilterTextList.filter(x => x.filterColumn == filterColumn);
                if (filterColumnExistsItem.length != 0) {
                    filterColumnExistsItem[0].filterText = fText;
                    filterColumnExistsItem[0].filterSelectedValue = fSelectedValue;
                }
                else {
                    this.unavailFilterTextList.push(data);
                }
            }
        }

        setFilterText(flag: any) {
            if (flag.toLowerCase() == 'avail') {
                if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                    var that = this;
                    //@ts-ignore
                    var isFilterExists = jslinq(this.availFilterTextList)
                        .where(function (data: any) {
                            if (data.filterColumn == that.filterColumn_avail) {
                                return data;
                            }
                        })
                        .toList();

                    if (isFilterExists != null && isFilterExists.length > 0) {
                        this.availFilterTextList.forEach((data: any) => {
                            if (data.filterColumn == this.filterColumn_avail) {
                                if (this.filterColumn_avail.toLowerCase() == 'Worked Hrs'.toLowerCase() || this.filterColumn_avail.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                                    this.NumberfilterText_avail = data.filterText;
                                    this.selectedNumberFilter_avail = data.filterSelectedValue;
                                }
                                else if (this.filterColumn_avail == 'Last Worked Date'.toLowerCase() || this.filterColumn_avail == 'Next Assignment Date'.toLowerCase()) {
                                    this.DateFilterText_avail = data.filterText;
                                    this.selectedDateFilter_avail = data.filterSelectedValue;
                                }
                                else {
                                    this.filterText_avail = data.filterText;
                                    this.selectedTextFilter_avail = data.filterSelectedValue;
                                }
                            }
                        })
                    }
                }
            }
            else {
                if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                    var that = this;
                    //@ts-ignore
                    var isFilterExists = jslinq(this.unavailFilterTextList)
                        .where(function (data: any) {
                            if (data.filterColumn == that.filterColumn_unavail) {
                                return data;
                            }
                        })
                        .toList();

                    if (isFilterExists != null && isFilterExists.length > 0) {
                        this.unavailFilterTextList.forEach((data) => {
                            if (data.filterColumn == this.filterColumn_unavail) {
                                if (this.filterColumn_unavail.toLowerCase() == 'Worked Hrs'.toLowerCase() || this.filterColumn_unavail.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                                    this.NumberfilterText_unavail = data.filterText;
                                    this.selectedNumberFilter_unavail = data.filterSelectedValue;
                                }
                                else if (this.filterColumn_unavail.toLowerCase() == 'Last Worked Date'.toLowerCase() || this.filterColumn_unavail.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                                    this.DateFilterText_unavail = data.filterText;
                                    this.selectedDateFilter_unavail = data.filterSelectedValue;
                                }
                                else {
                                    this.filterText_unavail = data.filterText;
                                    this.selectedTextFilter_unavail = data.filterSelectedValue;
                                }
                            }
                        })
                    }
                }
            }
        }

        updateGridDataBySelectedFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                    var data = JSON.parse(JSON.stringify(this.availGridDataForFilter));
                    this.availFilterTextList.forEach((dataRow: any) => {
                        var filterTextValue = '';
                        if (dataRow.filterColumn.toLowerCase() == "Name".toLowerCase() || dataRow.filterColumn.toLowerCase() == "Skill".toLowerCase() || dataRow.filterColumn.toLowerCase() == "Comps".toLowerCase() || dataRow.filterColumn.toLowerCase() == "Shift".toLowerCase()) {
                            filterTextValue = dataRow.filterText.toLowerCase().trim();
                        }

                        if (dataRow.filterColumn.toLowerCase() == "Name".toLowerCase()) {
                            data = data.filter((dt: any) => (dt.firstName.toLowerCase().trim().includes(filterTextValue) || dt.lastName.toLowerCase().trim().includes(filterTextValue)));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == "Skill".toLowerCase()) {
                            data = data.filter((dt: any) => dt.skillDescription.toLowerCase().trim().includes(filterTextValue));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == "Comps".toLowerCase()) {//Code
                            data = data.filter((dt: any) => dt.competencies.length > 0 && dt.competencies.filter((x: any) => x.code.toLowerCase().trim().includes(filterTextValue)));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == "Shift".toLowerCase()) {
                            data = data.filter((dt: any) => dt.shiftCode.toLowerCase().trim().includes(filterTextValue));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                            if (this.selectedNumberFilter_avail == NumberFilterType.IS_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hours) == Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_avail == NumberFilterType.GREATER_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hours) >= Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_avail == NumberFilterType.LESS_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hours) <= Number(dataRow.filterText));
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Worked Hrs'.toLowerCase()) {
                            if (this.selectedNumberFilter_avail == NumberFilterType.IS_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hoursWorked) == Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_avail == NumberFilterType.GREATER_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hoursWorked) >= Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_avail == NumberFilterType.LESS_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hoursWorked) <= Number(dataRow.filterText));
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Last Worked Date'.toLowerCase()) {
                            if (this.selectedDateFilter_avail == DateFilterType.EQUAL) {
                                data = data.filter((dt: any) => {
                                    return moment(dt.lastWorked).format("YYYY-MM-DD") == moment(dataRow.filterText).format("YYYY-MM-DD");
                                });
                            }
                            else if (this.selectedDateFilter_avail == DateFilterType.BEFORE_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.lastWorked).format("YYYY-MM-DD") <= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                            else if (this.selectedDateFilter_avail == DateFilterType.AFTER_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.lastWorked).format("YYYY-MM-DD") >= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                            if (this.selectedDateFilter_avail == DateFilterType.EQUAL) {
                                data = data.filter((dt: any) => { return moment(dt.nextAssignmentDate).format("YYYY-MM-DD") == moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                            else if (this.selectedDateFilter_avail == DateFilterType.BEFORE_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.nextAssignmentDate).format("YYYY-MM-DD") <= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                            else if (this.selectedDateFilter_avail == DateFilterType.AFTER_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.nextAssignmentDate).format("YYYY-MM-DD") >= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Type'.toLowerCase()) {
                            data = data.filter((dt: any) => this.selectedTypeFilter_avail.includes(dt.employeeType));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Last Worked Time'.toLowerCase()) {
                            data = data.filter((dt: any) => this.selectedLastWorkTimeFilter_avail.includes(this.setGridTimeFormat(dt.lastWorked)));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Next Assignment Time'.toLowerCase()) {
                            data = data.filter((dt: any) => this.selectedNextAssignementTimeFilter_avail.includes(this.setGridTimeFormat(dt.nextAssignmentStart)));
                        }

                        if (dataRow.filterColumn == this.filterColumn_avail) {
                            dataRow.filterData = JSON.parse(JSON.stringify(data));
                        }
                    });
                    this.availGridData = JSON.parse(JSON.stringify(data));
                }
            }
            else {
                if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                    var data = JSON.parse(JSON.stringify(this.unavailGridDataForFilter));
                    this.unavailFilterTextList.forEach((dataRow: any) => {
                        var filterTextValue = '';
                        if (dataRow.filterColumn.toLowerCase() == "Name" || dataRow.filterColumn.toLowerCase() == "Skill" || dataRow.filterColumn.toLowerCase() == "Comps" || dataRow.filterColumn.toLowerCase() == "Shift") {
                            filterTextValue = dataRow.filterText.toLowerCase().trim();
                        }

                        if (dataRow.filterColumn.toLowerCase() == "Name".toLowerCase()) {
                            data = data.filter((dt: any) => (dt.firstName.toLowerCase().trim().includes(filterTextValue) || dt.lastName.toLowerCase().trim().includes(filterTextValue)));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == "Skill".toLowerCase()) {
                            data = data.filter((dt: any) => dt.skillDescription.toLowerCase().trim().includes(filterTextValue));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == "Comps".toLowerCase()) {
                            data = data.filter((dt: any) => dt.competencies.length > 0 && dt.competencies.filter((x: any) => x.code.toLowerCase().trim().includes(filterTextValue)));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == "Shift".toLowerCase()) {
                            data = data.filter((dt: any) => dt.shiftCode.toLowerCase().trim().includes(filterTextValue));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Sched. Hrs'.toLowerCase()) {
                            if (this.selectedNumberFilter_unavail == NumberFilterType.IS_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hours) == Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_unavail == NumberFilterType.GREATER_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hours) >= Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_unavail == NumberFilterType.LESS_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hours) <= Number(dataRow.filterText));
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Worked Hrs'.toLowerCase()) {
                            if (this.selectedNumberFilter_unavail == NumberFilterType.IS_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hoursWorked) == Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_unavail == NumberFilterType.GREATER_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hoursWorked) >= Number(dataRow.filterText));
                            }
                            else if (this.selectedNumberFilter_unavail == NumberFilterType.LESS_THAN_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => Number(dt.hoursWorked) <= Number(dataRow.filterText));
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Last Worked Date'.toLowerCase()) {
                            if (this.selectedDateFilter_unavail == DateFilterType.EQUAL) {
                                data = data.filter((dt: any) => {
                                    return moment(dt.lastWorked).format("YYYY-MM-DD") == moment(dataRow.filterText).format("YYYY-MM-DD");
                                });
                            }
                            else if (this.selectedDateFilter_unavail == DateFilterType.BEFORE_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.lastWorked).format("YYYY-MM-DD") <= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                            else if (this.selectedDateFilter_unavail == DateFilterType.AFTER_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.lastWorked).format("YYYY-MM-DD") >= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Next Assignment Date'.toLowerCase()) {
                            if (this.selectedDateFilter_unavail == DateFilterType.EQUAL) {
                                data = data.filter((dt: any) => { return moment(dt.nextAssignmentDate).format("YYYY-MM-DD") == moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                            else if (this.selectedDateFilter_unavail == DateFilterType.BEFORE_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.nextAssignmentDate).format("YYYY-MM-DD") <= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                            else if (this.selectedDateFilter_unavail == DateFilterType.AFTER_OR_EQUAL_TO) {
                                data = data.filter((dt: any) => { return moment(dt.nextAssignmentDate).format("YYYY-MM-DD") >= moment(dataRow.filterText).format("YYYY-MM-DD"); });
                            }
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Type'.toLowerCase()) {
                            data = data.filter((dt: any) => this.selectedTypeFilter_unavail.includes(dt.employeeType));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Last Worked Time'.toLowerCase()) {
                            data = data.filter((dt: any) => this.selectedLastWorkTimeFilter_unavail.includes(this.setGridTimeFormat(dt.lastWorked)));
                        }
                        else if (dataRow.filterColumn.toLowerCase() == 'Next Assignment Time'.toLowerCase()) {
                            data = data.filter((dt: any) => this.selectedNextAssignementTimeFilter_unavail.includes(this.setGridTimeFormat(dt.nextAssignmentStart)));
                        }

                        if (dataRow.filterColumn == this.filterColumn_unavail) {
                            dataRow.filterData = JSON.parse(JSON.stringify(data));
                        }
                    });
                    this.unavailGridData = JSON.parse(JSON.stringify(data));
                }
            }
        }

        onGridFilterClose() {
            var filterPanel: any;
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                filterPanel = document.getElementById('filterModel_avail');
            }
            else {
                filterPanel = document.getElementById('filterModel_unavail');
            }
            filterPanel.classList.add('d-none');
        }

        checkAllTypeFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                this.isCheckAllTypeFilter_avail = !this.isCheckAllTypeFilter_avail;
                this.selectedTypeFilter_avail = [];
                if (this.isCheckAllTypeFilter_avail) { // Check all
                    for (var key in this.lstType_avail) {
                        this.selectedTypeFilter_avail.push(this.lstType_avail[key]);
                    }
                }
            }
            else {
                this.isCheckAllTypeFilter_unavail = !this.isCheckAllTypeFilter_unavail;
                this.selectedTypeFilter_unavail = [];
                if (this.isCheckAllTypeFilter_unavail) { // Check all
                    for (var key in this.lstType_unavail) {
                        this.selectedTypeFilter_unavail.push(this.lstType_unavail[key]);
                    }
                }
            }
        }

        updateCheckallTypeFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                if (this.selectedTypeFilter_avail.length == this.lstType_avail.length) {
                    this.isCheckAllTypeFilter_avail = true;
                } else {
                    this.isCheckAllTypeFilter_avail = false;
                }
            }
            else {
                if (this.selectedTypeFilter_unavail.length == this.lstType_unavail.length) {
                    this.isCheckAllTypeFilter_unavail = true;
                } else {
                    this.isCheckAllTypeFilter_unavail = false;
                }
            }
        }

        checkAllLastWorkTimeFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                this.isCheckAllWorkTimeFilter_avail = !this.isCheckAllWorkTimeFilter_avail;
                this.selectedLastWorkTimeFilter_avail = [];
                if (this.isCheckAllWorkTimeFilter_avail) { // Check all
                    for (var key in this.lstLastWorkedTime_avail) {
                        this.selectedLastWorkTimeFilter_avail.push(this.lstLastWorkedTime_avail[key]);
                    }
                }
            }
            else {
                this.isCheckAllWorkTimeFilter_unavail = !this.isCheckAllWorkTimeFilter_unavail;
                this.selectedLastWorkTimeFilter_unavail = [];
                if (this.isCheckAllWorkTimeFilter_unavail) { // Check all
                    for (var key in this.lstLastWorkedTime_unavail) {
                        this.selectedLastWorkTimeFilter_unavail.push(this.lstLastWorkedTime_unavail[key]);
                    }
                }
            }
        }

        updateCheckAllLastWorkTimeFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                if (this.selectedLastWorkTimeFilter_avail.length == this.lstLastWorkedTime_avail.length) {
                    this.isCheckAllWorkTimeFilter_avail = true;
                } else {
                    this.isCheckAllWorkTimeFilter_avail = false;
                }
            }
            else {
                if (this.selectedLastWorkTimeFilter_unavail.length == this.lstLastWorkedTime_unavail.length) {
                    this.isCheckAllWorkTimeFilter_unavail = true;
                } else {
                    this.isCheckAllWorkTimeFilter_unavail = false;
                }
            }
        }

        checkAllNextAssigmentTimeFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                this.isCheckAllNextAssignTimeFilter_avail = !this.isCheckAllNextAssignTimeFilter_avail;
                this.selectedNextAssignementTimeFilter_avail = [];
                if (this.isCheckAllNextAssignTimeFilter_avail) { // Check all
                    for (var key in this.lstNextAssignmentTime_avail) {
                        this.selectedNextAssignementTimeFilter_avail.push(this.lstNextAssignmentTime_avail[key]);
                    }
                }
            }
            else {
                this.isCheckAllNextAssignTimeFilter_unavail = !this.isCheckAllNextAssignTimeFilter_unavail;
                this.selectedNextAssignementTimeFilter_unavail = [];
                if (this.isCheckAllNextAssignTimeFilter_unavail) { // Check all
                    for (var key in this.lstNextAssignmentTime_unavail) {
                        this.selectedNextAssignementTimeFilter_unavail.push(this.lstNextAssignmentTime_unavail[key]);
                    }
                }
            }
        }

        updateCheckAllNextAssignTimeFilter() {
            if (this.filterVisibleOnGrid.toLowerCase() == 'avail') {
                if (this.selectedNextAssignementTimeFilter_avail.length == this.lstNextAssignmentTime_avail.length) {
                    this.isCheckAllNextAssignTimeFilter_avail = true;
                } else {
                    this.isCheckAllNextAssignTimeFilter_avail = false;
                }
            }
            else {
                if (this.selectedNextAssignementTimeFilter_unavail.length == this.lstNextAssignmentTime_unavail.length) {
                    this.isCheckAllNextAssignTimeFilter_unavail = true;
                } else {
                    this.isCheckAllNextAssignTimeFilter_unavail = false;
                }
            }
        }

        filterUnscheduleStaffGridDataBySkill(skillDesc: any) {
            if (this.isFilterDataFromHeader) {
                this.availGridData = JSON.parse(JSON.stringify(this.availFilteredData));
                this.unavailGridData = JSON.parse(JSON.stringify(this.unavailFilteredData));
            }
            else {
                this.availFilteredData = null;
                this.availFilteredData = JSON.parse(JSON.stringify(this.availGridData));
                this.unavailFilteredData = null;
                this.unavailFilteredData = JSON.parse(JSON.stringify(this.unavailGridData));
                this.isFilterDataFromHeader = true;
            }

            if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                this.availFilterTextList.forEach((data) => {
                    var selectedFilterIcon: any = document.querySelector("[column='" + data.filterColumn + "']");
                    if (selectedFilterIcon != null) {
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    }
                });
                this.availFilterTextList = [];
            }

            if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                this.unavailFilterTextList.forEach((data) => {
                    var selectedFilterIcon: any = document.querySelector("[column='" + data.filterColumn + "']");
                    if (selectedFilterIcon != null) {
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    }
                });
                this.unavailFilterTextList = [];
            }

            this.filterText_avail = skillDesc;
            this.filterText_unavail = skillDesc;
            this.filterColumn_avail = "Skill";
            this.filterColumn_unavail = "Skill";

            this.filterVisibleOnGrid = 'avail';
            this.AddFilterListItem();
            this.updateGridDataBySelectedFilter();

            this.filterVisibleOnGrid = 'unavail';
            this.AddFilterListItem();
            this.updateGridDataBySelectedFilter();

            var selectedFilterIcon: any = document.querySelector("[column='Skill']");
            if (selectedFilterIcon != null) {
                selectedFilterIcon.classList.add('filterSelectedIcon');
            }
            else {
                setTimeout(() => {
                    var selectedFilterIcon: any = document.querySelector("[column='Skill']");
                    selectedFilterIcon.classList.add('filterSelectedIcon');
                }, 1000);
            }
        }

        clearUnscheduleStaffGridDataBySkill() {
            if (this.isFilterDataFromHeader) {
                this.availGridData = JSON.parse(JSON.stringify(this.availFilteredData));
                this.unavailGridData = JSON.parse(JSON.stringify(this.unavailFilteredData));
                if (this.availFilterTextList != null && this.availFilterTextList.length > 0) {
                    this.availFilterTextList.forEach((data) => {
                        var selectedFilterIcon: any = document.querySelector("[column='" + data.filterColumn + "']");
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    });
                    this.availFilterTextList = [];
                }

                if (this.unavailFilterTextList != null && this.unavailFilterTextList.length > 0) {
                    this.unavailFilterTextList.forEach((data) => {
                        var selectedFilterIcon: any = document.querySelector("[column='" + data.filterColumn + "']");
                        selectedFilterIcon.classList.remove('filterSelectedIcon');
                    });
                    this.unavailFilterTextList = [];
                }

                this.filterText_avail = "";
                this.NumberfilterText_avail = "";
                this.filterColumn_avail = "";
                this.availFilteredData = null;
                this.filterText_unavail = "";
                this.NumberfilterText_unavail = "";
                this.filterColumn_unavail = "";
                this.unavailFilteredData = null;
                this.isFilterDataFromHeader = false;
            }
        }

        closeFilterBox() {
            document.addEventListener('click', function handleClickOutsideFilterBox(event) {
                const box: any = document.getElementById('filterModel_avail');
                const box1: any = document.getElementById('filterModel_unavail');

                if (box != null) {
                    if (!box.contains(event.target)) {
                        box.classList.add('d-none');
                    }
                }

                if (box1 != null) {
                    if (!box1.contains(event.target)) {
                        box1.classList.add('d-none');
                    }
                }
            });

            var actionableDiv: any = document.getElementById("actionable");
            actionableDiv.addEventListener('scroll', function hideFilterBox() {
                const box: any = document.getElementById('filterModel_avail');
                if (box != null) {
                    box.classList.add('d-none');
                }

                const box1: any = document.getElementById('filterModel_unavail');
                if (box1 != null) {
                    box1.classList.add('d-none');
                }
            });

            var homeDataDiv: any = document.getElementById("unscheduled");
            homeDataDiv.addEventListener('scroll', function hideFilterBoxDiv() {
                const box: any = document.getElementById('filterModel_avail');
                if (box != null) {
                    box.classList.add('d-none');
                }

                const box1: any = document.getElementById('filterModel_unavail');
                if (box1 != null) {
                    box1.classList.add('d-none');
                }
            });
        }

        async showSendSMSPopup() {
            var inputData = {
                DepartmentId: this.unschStaffTabInput.DataSet.DepartmentId,
                date: this.unschStaffTabInput.DataSet.StartDateTime,
                IncludeSmsSubscriptionInfo: true
            }
            await this.$store
                .dispatch("assignments/getAvailableStaffSMSSubscription", inputData)
                .then(() => {
                    var unscheduledStaffs: any = [];
                    if (this.availableStaffSMSSubscription != null && this.availableStaffSMSSubscription.length > 0) {
                        unscheduledStaffs = this.processAvailableStaffs(this.availableStaffSMSSubscription, this.availableStaff);
                    }
                    var staffSmsNotificationInput: any = {
                        departmentId: this.unschStaffTabInput.DataSet.DepartmentId,
                        startDateTime: this.unschStaffTabInput.DataSet.StartDateTime,
                        endDateTime: this.unschStaffTabInput.DataSet.EndDateTime,
                        skillDeficitIds: this.unschStaffTabInput.SkillDeficitIds,
                        unscheduledStaffs: unscheduledStaffs,
                        facilityName: this.unschStaffTabInput.DataSet.FacilityName,
                        departmentName: this.unschStaffTabInput.DataSet.DepartmentName
                    };
                    this.emitter.emit("showSendSMSPopup", staffSmsNotificationInput);
                    this.hideLoader();
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        this.msgValue = "Error encountered while retrieving available staff details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        processAvailableStaffs(result: any, filteredStaffs: any) {
            var unscheduledStaffs = [];

            var filteredStaffIds = filteredStaffs.map((filteredStaff: any) => {
                return filteredStaff.staffId;
            });

            //@ts-ignore
            var filteredExistingStaffs = jslinq(result)
                .where(function (staff: any) {
                    if (filteredStaffIds.indexOf(staff.staffId) >= 0) {
                        return staff;
                    }
                })
                .toList();

            var staffs: any = {};

            filteredExistingStaffs.forEach((staff: any) => {
                staff.skillLevelIds = [staff.skillLevelId];
                staff.name = staff.firstName + ' ' + staff.lastName;
                if (staffs[staff.staffId]) {
                    var existing = staffs[staff.staffId];
                    if (existing.skillDescription.indexOf(staff.skillDescription) === -1) {
                        existing.skillDescription += ', ' + staff.skillDescription;
                    }
                    if (existing.skillLevelIds.indexOf(staff.skillLevelId) === -1) {
                        existing.skillLevelIds.push(staff.skillLevelId);
                    }
                } else {
                    staffs[staff.staffId] = staff;
                }
            });

            for (var prop in staffs) {
                if (Object.prototype.hasOwnProperty.call(staffs, prop)) {
                    unscheduledStaffs.push(staffs[prop]);
                }
            }

            return unscheduledStaffs;
        }

        sortDataAvailable(ev: any, columnName: any){ 
            var column = this.columnForSort[columnName];
            
            this.availGridData.sort((col1: any,col2: any) => {
                let modifier = 1;
                if (ev.detail.direction.toLowerCase() === 'desc') modifier = -1;

                if (col1[column] < col2[column]) return -1 * modifier; 
                if (col1[column] > col2[column]) return 1 * modifier;
            });
        }

        sortDataUnavailable(ev: any, columnName: any){
            var column = this.columnForSort[columnName];
            
            this.unavailGridData.sort((col1: any,col2: any) => {
                let modifier = 1;
                if (ev.detail.direction.toLowerCase() === 'desc') modifier = -1;

                if (col1[column] < col2[column]) return -1 * modifier; 
                if (col1[column] > col2[column]) return 1 * modifier;
            });
        }

        unscheduledTabExportToExcel(headerInfo: any) {
            this.payloadExportToExcel = {
                headerInfo: headerInfo,
                skillLevelDescription: this.unschStaffTabInput.DataSet.SkillLevelDescription,
                dataSource: this.availGridData,
                departmentId: '',//this.otherTabInput.DataSet.DepartmentId,
                tabName: 'unscheduled'
            };
            this.isShowExportToExcel = true;
        }

        closeUnscheduledTabExportToExcel() {
            this.isShowExportToExcel = false;
        }
    }
</script>
<style scoped>
    .filterPanel {
        width: 200.797px;
        min-height: 162px;
        overflow: visible;
        top: 553.703px;
        z-index: 10013;
        left: 254.562px;
        box-sizing: content-box;
        display: block;
        position: absolute;
        text-align: left;
        border: 1px solid #939393;
        background-color: white;
        :deep(.neu-button) {
            background: var(--neu-color-primary);
        }
    }

    .filterSelectedIcon {
        background-color: #164D87;
        color: whitesmoke;
        padding: 2px;
    }

    .filterInput {
        width: 100% !important;
        min-height: 2rem !important;
    }

    .filterBtn {
        min-width: 30px !important;
        height: 30px !important;
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

        .filterBtn:hover {
            color: #fff !important;
        }

            .filterBtn:hover::after {
                background: none !important;
            }

    .neu-input__select-icon {
        display: block;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        top: 2.82rem;
        right: 0.938rem;
        background-color: transparent;
        transition: 0.5s ease-in-out;
        pointer-events: none;
    }

    .me-2 {
        margin-right: 0.5rem!important;
    }
    .float-end {
        float: right!important;
    }
</style>
