<template>
    <div v-for="(data, index) in careAssignmentSheetReportInput" :key="data">
        <div class="dailyRosterDetail-card p-2 mb-2" style="background-color: white" :data-departmentid="data.departmentId" :data-businessday="GetBusinessDay(data.businessDay)">        
            <div :class="['heading ', index != 0 ? 'dailyRoasterPrintPageBreak': '']"> 
                <div class="row">
                    <div class="col-md-6">
                        <h5>{{'(' + data.facilityCoId + ') ' + data.facilityName}}</h5> 
                    </div>
                    <div class="col">
                        <h5 class="float-end">{{ '(' + data.departmentCode + ') ' + data.departmentName}}</h5> 
                    </div> 
                </div>
            </div> 
            <hr> 
            <div class="row"> 
                <div class="col-md-6"> 
                    <h5>Care Assignment Sheet</h5>
                    <table class="table table-bordered careAssignmentsheet-summary care-assignment-print">
                        <tbody>
                            <tr> 
                                <td colspan="4" scope="row" class="">{{'(' + data.facilityCoId + ') ' + data.facilityName}}</td> 
                            </tr>
                            <tr> 
                                <td colspan="4" scope="row" class="">{{'(' + data.departmentCode + ') ' + data.departmentName}}</td> 
                            </tr>
                            <tr>
                                <td scope="row" class="font-weight-bold">Date</td>
                                <td scope="row" class="">{{GetSummarySectionDate(data.businessDay)}}</td>
                                <td scope="row" class="font-weight-bold">Total Scheduled : {{GetTotalSchedule(data.skills)}}</td>
                                <td scope="row" class="font-weight-bold">{{GetSkillText(data.skills)}}</td>
                            </tr>
                            <tr> 
                                <td colspan="2" scope="row" class=""> Part of Day </td>
                                <td colspan="2" scope="row" class="">{{data.partOfDay}}</td> 
                            </tr>
                        </tbody> 
                    </table> 
                </div> 
                <div class="col"></div> 
            </div>
            <div class="row" v-if="data.assignments != null && data.assignments.length > 0">
                <div class="col">
                    <div class="care-assignment-sheet-grid">
                        <div color="plain-0" class="p-0 m-0">
                            <table class="neutable care-assignment-print">
                                <colgroup>
                                    <template v-for="column in Object.keys(columnHeaders)" :key="column">
                                        <col :style="{'width': columnHeaders[column] }" >
                                    </template>
                                </colgroup>
                                <thead>
                                    <tr :columns="columnHeaders" header>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <th v-for="column in Object.keys(columnHeaders)" :key="column">
                                            {{ column }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody align="left">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <tr :columns="columnHeaders" v-for="dataItem in data.assignments" :slot="column"
                                                :key="dataItem" :class="getAssignmentClassForRow(dataItem)">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.departmentCode" class="department-col">
                                            {{ dataItem.departmentCode }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.lastAndFirstName" class="employee-col">
                                            {{ dataItem.lastAndFirstName }}                                           
                                            <span v-if="dataItem.charge">
                                                <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                            </span>
                                            <span v-if="dataItem.associationType === defaults.secondaryStaff" >                                                                                        
                                               <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                            </span> 
                                            <span v-if="dataItem.associationType === defaults.unassociatedStaff">
                                                <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                            </span>
                                            <span v-if="dataItem.float">
                                                <img style="width: 16px;float: right" src="@/assets/float.png" alt="Float" />
                                            </span>                                        
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.credentialsExpiration">
                                            {{ dataItem.credentialsExpiration }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.skillLevelDescription">
                                            {{ dataItem.skillLevelDescription }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.employeeType">
                                            {{ dataItem.employeeType }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.hours"
                                        :class="[(dataItem.overtimeShift) ? 'overtime': '']">
                                            {{ dataItem.hours }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="dataItem.shiftCode" 
                                         :class="[(dataItem.overtimeShift && !dataItem.cancel) ? 'shift-col overtime': 'shift-col']">
                                            {{ dataItem.shiftCode }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="AssignmentStartTime(dataItem.startTime)">  
                                            {{ AssignmentStartTime(dataItem.startTime) }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  :title="AssignmentEndTime(dataItem.endTime)">
                                            {{ AssignmentEndTime(dataItem.endTime) }}
                                        </td>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td >
                                            
                                        </td>
                                         <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td>
                                           
                                        </td>                                      
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <td  class="notes-col">
                                            <span v-if="dataItem.hasNotes">
                                                <font-awesome-icon :icon="['fa', 'sticky-note']" size="sm"
                                                                   @click="onEditingShift(dataItem)" />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <template v-if="data.plusMetrics != null">
                <PlusMetricsReport :plusMetricsReportInput="data.plusMetrics"
                                    :facilityName="data.facilityName"
                                    :departmentName="data.departmentName"/>
            </template>
            <template v-if="data.clockIns != null && data.clockIns.shiftClockIns != null && data.clockIns.shiftClockIns.length > 0">
                <ClockInsReport :clockInsInput="data.clockIns" />
            </template>
        </div>
    </div>
    <template v-if="isEditAssignment">
        <EditAssignment :assignmentInput="assignmentInput" :assignmentType="assignmentType" 
                @close-modal="closeEditAssignment"/>
    </template>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import PlusMetricsReport from './PlusMetricsReport.vue';
import ClockInsReport from './ClockInsReport.vue';
import EditAssignment from './EditAssignment.vue';

class Props {
    careAssignmentSheetReportInput!: any 
}
@Options({
    components: {
        PlusMetricsReport,
        ClockInsReport,
        EditAssignment
    }
})
export default class CareAssignmentSheetReport extends Vue.with(Props) {  
    //Data properties
    
    defaults: any= {
        config: {},
        numberOfSummariesToDisplayPerRow: 6,
        otherProductiveAssignment: 2,
        nonProductiveAssignment: 3,
        callAssignment:4,
        unassociatedStaff: 3,
        secondaryStaff: 2,
        timeOnlyFormat: 'hh:mm A'
    };
    columnHeaders: any = {
        Dept: "6%",
        Employee: "14%",
        Credentials: "10%",
        Skills: "8%",
        Type: "7%",
        Hours: "5%",
        Shift: "5%",
        "Start Time": "9%",
        "End Time": "9%",
        "Room / Assignment": "10%",
        "Additional Comment": "10%",
        "Notes": "7%"
    };
    columnIcons: any = {
        Department: "none",
        Employee: "none",
        Credentials: "none",
        Skills: "none",
        Type: "none",
        Hours: "none",
        Shift: "none",
        "Start Time": "none",
        "End Time": "none",
        "Room / Assignment": "none",
        "Additional Comment": "none",
        "Notes": "none"
    };
    timeOnlyFormat: string = 'hh:mm A';
    viewModel: any = {
        reportData: [],
        lastEditedAssignmentId:null
    };
    isEditAssignment: boolean = false;
    assignmentInput: any = '';
    assignmentType: any = 'View'

    //Lifecycle hooks
    mounted(){
        this.careAssignmentSheetReportInput.forEach((data: any) => {              
            this.viewModel.reportData.push(data);
        });
    }
    
    created(){
        this.emitter.on("unloadAssignment", this.unloadAssignment);
    }

    unmounted(){
        this.emitter.off("unloadAssignment", this.unloadAssignment);
    }

    //Methods
    GetBusinessDay(businessDay: any){
       return moment(businessDay).format('MM-DD-YYYY');
    }

    GetSummarySectionDate(businessDay: any){
        return moment(businessDay).format('MM/DD/YYYY');
    }

    GetSkillText(skills: any){
        var skillsText = '';
        if (skills != null && skills.length > 0) {
            skills.forEach((skill: any) => {
                skillsText += skill.skillLevelDesc + ' : ' + skill.count + ' | ';
            });
        }
        return skillsText.slice(0, -3);
    }

    GetTotalSchedule(skills: any){
        var totalScheduled = 0;
        if (skills != null && skills.length > 0) {
            skills.forEach((skill: any) => {
                totalScheduled += Number(skill.count);
            });
        }
        return totalScheduled;
    }

    AssignmentStartTime(startTime: any){ 
        return moment(startTime).format(this.timeOnlyFormat);
    }

    AssignmentEndTime(endTime: any){
        return moment(endTime).format(this.timeOnlyFormat);
    }

    getAssignmentClassForRow(assignment: any) {
        var className = '';

        if (!assignment) {
            return className;
        }

        className = assignment.cancel ? " cancelledAssignment" : "";
        className += assignment.profileCharge && assignment.charge ? " chargeAssignment" : "";
        className += assignment.float ? " floatedAssignment" : "";
        className += assignment.cancel ? " strikethrough" : "";

        switch (+assignment.productiveId) {
            case this.defaults.nonProductiveAssignment:
                className += " non-productive";
                break;
            case this.defaults.otherProductiveAssignment:
                className += " other-productive";
                break;
            case this.defaults.callAssignment:
                className += " call-assignment";
                break;
            default:
                break;
        }
        return className.trim();
    }

    
    closeEditAssignment(){
        this.unloadAssignment();
    }
    
    unloadAssignment() {
        this.isEditAssignment = false;
        this.emitter.emit('refreshDailyRoster');
    }

    onEditingShift(dataItem: any) {
        var assigmentId = dataItem.assignmentId;
        var departmentId = dataItem.departmentId;

        var assigmentDetails = this.findAssignmentInDepartment(departmentId, assigmentId);
        var assigmentData = this.mapAssignmentToBeUpdated(assigmentDetails);

        this.viewModel.lastEditedAssignmentId = assigmentId;            
        this.assignmentInput = assigmentData;
        this.isEditAssignment = true;
    }

    findAssignmentInDepartment (departmentId: any, assignmentId: any) {
        var reportData = this.viewModel.reportData, i;

        if (reportData ){
            for (i = 0; i < reportData.length; i++) {
                if (reportData[i].departmentId === departmentId) {
                    var assignmentInfo = this.findAssignment(reportData[i].assignments, assignmentId);
                    if(assignmentInfo != undefined)
                    {
                        assignmentInfo.facilityId = reportData[i].facilityId;
                        assignmentInfo.facilityName = reportData[i].facilityName;
                        assignmentInfo.clusterId = reportData[i].clusterId;
                        assignmentInfo.clusterName = reportData[i].clusterName;
                        assignmentInfo.facilityPriorDatesEditable = reportData[i].facilityPriorDatesEditable;
                        return assignmentInfo;
                    }
                }
            }
        }
    }

    findAssignment (assignmentList: any, assignmentId: any) {
        var i;
        if (assignmentList) {
            for (i = 0; i < assignmentList.length; i++) {
                if (assignmentList[i].assignmentId === assignmentId) {
                    return assignmentList[i];
                }
            }
        }
    }

    mapAssignmentToBeUpdated (a: any) {
        return {
            actualEndTime: a.actualEndTime,
            actualStartTime: a.actualStartTime,
            allSkills: "",                                                  
            assignId: a.assignmentId,
            assignmentDateChanged: null,                    
            assignmentDateCreated: null,                    
            associationType: a.associationType,
            cancelComment: a.comment,
            cancelReason: null,
            cancelReasonId: a.cancelReasonId,
            charge: a.charge,
            clusterId: a.clusterId,                           
            clusterName: a.clusterName,       
            competencies: [],
            countType: a.countTypeDescription,
            countTypeId: a.countTypeId,
            credentialCode: null,                   
            credentialNumber: null,                 
            credentialRenewalDate: null,
            credentialTrackingStatusId: 0,
            date: a.date,
            day: null,
            departmentCode: a.departmentCode,
            departmentId: a.departmentId,
            departmentName: a.departmentDescription,
            departmentShiftId: a.departmentShiftId,
            departmentSummaryId: 0,
            employeeType: a.employeeType,
            endTime: a.endTime, 
            fte: a.fte,
            facilityId: a.facilityId,
            facilityName: a.facilityName,
            firstName: a.staffFirstName,
            hasNotes: false,
            hireDate: null,                      
            hours: a.hours,
            hoursWorked: a.hoursWorked,
            isCancelled: a.cancel,
            isFloat: a.float,
            isOvertime: a.isOvertime,
            lastCanceledDate: a.lastCanceled, 
            lastFloatedDate: a.lastFloated,  
            lastName: a.staffLastName,
            name: a.employeeFullName,
            needId: a.needId,
            needStatusId: a.needStatusId,
            origDepartmentCode: a.origDepartmentCode,
            origDepartmentId: a.origDeptId,
            origDepartmentName: a.origDepartmentDescription,
            overTimeOccurence: 0,
            overtimeShift: a.overtimeShift,
            phoneNumber: a.phoneNumber,
            productiveDescription: a.productiveDescription,
            productiveId: a.productiveId,
            profileCharge: a.profileCharge,
            secondarySkills: a.secondarySkills,
            seniorityDate: a.seniorityDate,   
            shiftCode: a.shiftCode.trim(),
            skillLevelDescription: a.skillLevelDescription,
            skillLevelId: a.skillLevelId,
            skillSort: a.skillSortOrder,
            staffId: a.staffId,
            staffOrigDepartmentId: a.staffOrigDepartmentId,
            staffShift: null,
            staffVolume: 0,
            startTime: a.startTime, 
            unscheduledAbsence: a.unscheduledAbsence,
            unscheduledAbsenceOption: 0,
            actionableDepartment: {
                FacilityId: a.facilityId,
                FacilityName: a.facilityName,
                ClusterId: a.clusterId,                           
                ClusterName: a.clusterName,       
                DepartmentId: a.departmentId            
            },
            competencyCodes: null,
            config: [],
            dirty: false,
            dirtyFields: {},
            facilityConfig: { FacilityPriorDatesEditable: a.facilityPriorDatesEditable },      
            loadedFromDailyRoster: true
        };
    }
}
</script>
<style scoped>
.table-bordered {
    border: 1px solid #CED1D7;
}

.overtime {
    background-color: Red;
    padding: 5px;
}

.cancelledAssignment {
    color: #B33E3E !important;
    background-color: #F6DBDB !important;
}

.strikethrough {
    text-decoration: line-through !important;
}

.floatedAssignment {
    background-color: #D9EFFA !important;
}

.chargeAssignment {
    font-weight: bold !important;
}

.non-productive {
    color: #B33E3E !important;
    background-color: #DFE6E9 !important;
}

.other-productive {
    color: #B33E3E !important;
    background-color: #EFEFEF !important;
}

.call-assignment {
    color: #B33E3E !important;
    background-color: #D9EAD3 !important;
}

.neutable {
  border-collapse: collapse;
  color: #1f2532;
  table-layout: fixed;
  width: 100%;
  font-size: 0.875em;
}

.neutable th {
  text-align: left;
  font-family: "HCA-Mark-Medium", "Arial", sans-serif;
  color: #58595B;
  border-bottom: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
.neutable td {
  border-bottom: 0.063rem solid #bcbcbc;
  border-top: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
@media print {
    table.care-assignment-print td {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    table.care-assignment-print th {
        font-size: xx-small !important;
        padding: 0px !important;
    }
}
</style>