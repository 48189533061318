<template>
  <div id="edSurgeContainer">
       <div id="filteractivityLoggrid-content-loading" class="text-center mt-3" v-show="isShowLoader">
           <div class="fa-2x">
               <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
           </div>
           <p><b>Loading...</b></p>
       </div>
       <neu-container>
         <neu-row class="mb-3">
           <neu-col md="3" cols="12" class="pt-1 textLeft">
             <span>Show/Hide Surge Banner : </span>
           </neu-col>
           <neu-col md="9" cols="12" class="textLeft">
             <Toggle v-model="surgeDisplayValue"
                     on-label="On"
                     off-label="Off"
                     @change="onSurgeDisplayChange()" />
           </neu-col>
         </neu-row>
         <neu-row>
           <neu-col md="3" cols="12" class="pt-1 textLeft">
             <span class="bold">Emergency Department Displayed : </span>
           </neu-col>
         </neu-row>
         <neu-row class="pt-3">
           <neu-col md="1" cols="12" class="textLeft addNewLabel">
             <span>Add new : </span>
           </neu-col>
           <neu-col md="9" cols="12">
               <input class="neu-input typeahead searchTxt" 
                     id="txtSearchDepartment" 
                     type="text" 
                     placeholder="Search by department..." 
                     onfocus="this.placeholder = ''" 
                     onblur="this.placeholder = 'Search by department...'"
                     @keyup="searchDepartment"
                     v-model="searchTerm">
               <a @click="clearSearchTerm"
                   class="neu-tooltip searchClose"
                   style="cursor: pointer">
                   <i aria-hidden="true">
                     <font-awesome-icon :icon="['fas', 'close']" size="lg" style="opacity: 0.5;" />
                   </i>
                   <span class="neu-tooltip--text tooltipTxt">Clear</span>
               </a>
             <ul id="autoCompleteResult" v-if="isSearchDepartmentDrpVisible"
               class="ulSearch space-y-1">
               <li
                   v-for="dept in searchDepartmentList"
                   :key="dept.coId"
                   @click="setSelectedDepartment(dept)"
                   class="liSearch bg-gray-100">
                 {{ dept.departmentName }}
               </li>
             </ul>
             <div id="autoCompleteNoResult" v-if="isSearchNoReseultVisible" class="ulSearch searchNodata space-y-1">No Data Found</div>
           </neu-col>
           <neu-col md="2" cols="12">
             <neu-button type="button" fill="raised" id="btnAddDepartment" :full="true" @click="addDepartment">
                 ADD
             </neu-button>
           </neu-col>
         </neu-row>
         <neu-row class="mt-3 checkbox-list">
           <template v-for="(item, index) in departmentList" :key="index">
             <div class="my-div">             
                 <neu-checkbox :id="item.forText" 
                       :class="[item.isNew ? ' newDepartment' : '','mr-10']"
                       :value="item.coId"
                       v-model="item.isCheckedChange"
                       :checked="item.isCheckedChange"></neu-checkbox>
               <label :class="[item.isNew ? 'boldText' : '', 'control-label']">
                     <!-- :for="item.forText"> -->
                   {{item.facilityName + ' - ' + item.departmentName}}       
               </label>
             </div>
           </template>
         </neu-row>
         <neu-row class="pt-3">
           <neu-col md="9" cols="12">
           </neu-col>
           <neu-col md="2" cols="12">
             <neu-button type="button" id="btnSaveChanges" fill="raised" @click="onSaveChanges()">
                 SAVE CHANGES
             </neu-button>
           </neu-col>
           <neu-col md="1" cols="12">
           </neu-col>
         </neu-row>
       </neu-container>
       <div v-if="isToasterVisible"> 
         <ToasterPopup @closeModal="closeToasterPopup"
                         :msgValue="msgValue"
                         :classFlag="classFlag"></ToasterPopup>
       </div>
  </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import Toggle from '@vueform/toggle';
import jslinq from "jslinq";

//import model and enum
import { Profile } from '@/models/profile/index';

//import components
import {
 NeuContainer,
 NeuInput,
 NeuRow,
 NeuTableRowCount,
 NeuTable,
 NeuTableRow,
 NeuLabel,
 NeuTableBody,
 NeuTableHeading,
 NeuCard,
 NeuPaginator,
 NeuCol,
 NeuCheckbox,
 NeuButton
} from "@neutron/vue";
import ToasterPopup from "../shared/ToasterPopup.vue";
import { faTruckField } from '@fortawesome/free-solid-svg-icons';

@Options({
 computed: {
   ...mapState('userstaffpreferences', ['surgePreferences', 'surgeBarVisibility']),
   ...mapState('surgealert', ['surgeSelectedDepartments', 'searchDepartmentResult']),
   ...mapState('profile', ['profileData'])
 },
 components: {
   NeuContainer,
   NeuInput,
   NeuRow,
   NeuTableRowCount,
   NeuTable,
   NeuTableRow,
   NeuLabel,
   NeuTableBody,
   NeuTableHeading,
   NeuCard,
   NeuPaginator,
   NeuCol,
   NeuCheckbox,
   NeuButton,
   Toggle,
   ToasterPopup
 },
})
export default class EDSurge extends Vue {
 //Data properties
 surgePreferences!: any;
 surgeBarVisibility!: any;
 surgeSelectedDepartments!: any;
 searchDepartmentResult!: any;
 profileData!: Profile;
 departmentList: any = null;
 surgeDisplayValue: boolean = true;
 isShowLoader: boolean = false;
 msgValue!: string;
 classFlag!: string;
 isToasterVisible:boolean = false;
 searchTerm: string = "";
 searchDepartmentList: any = null;
 searchSelectedDepartment: any = null;
 isSearchDepartmentDrpVisible: boolean = false;
 isSearchNoReseultVisible: boolean = false;
 //Lifecycle hooks
 mounted() {
   this.getSurgePreference();
   this.getSelectedDepartments();
   this.closeAutoCompleteResult();
 }

 //Methods
 async getSurgePreference(){
   var inputData = {
     tenantId: this.profileData.TenantId,
     sourceId: 'Dashboard',
     dashboardId: this.profileData.DashboardId,
     fsUserId: this.profileData.UserId,
     fsStaffId: 0
   }
   this.showLoader();
   await this.$store
           .dispatch("userstaffpreferences/getSurgePreferences", inputData)
           .then(() => {
               if (this.surgePreferences != null) {
                   this.surgeDisplayValue = this.surgePreferences.surgeDisplay;
               }
               if(this.surgeBarVisibility != null){
                   this.surgeDisplayValue = this.surgeBarVisibility;
               }
               this.hideLoader();
           })
           .catch((err: any) => {
               if (err) {
                   this.hideLoader();
                   this.msgValue = "Error encountered while retrieving surge preferences.";
                   this.classFlag = "error";
                   this.isToasterVisible = true;
                   console.error(err); // Handle errors any way you want
               }
           }); 
 }

 async getSelectedDepartments(){
   var inputData = {
     fsUserId: this.profileData.UserId,
     tenantId: this.profileData.TenantId
   }
   this.emitter.emit('showLoader');
   await this.$store
           .dispatch("surgealert/getSelectedDepartments", inputData)
           .then(() => {
               this.setSelectedDepartments();
               this.emitter.emit('hideLoader');
           })
           .catch((err: any) => {
               if (err) {
                   this.emitter.emit('hideLoader');
                   this.msgValue = "Error encountered while retrieving surge preferences.";
                   this.classFlag = "error";
                   this.isToasterVisible = true;
                   console.error(err); // Handle errors any way you want
               }
           }); 
 }

 setSelectedDepartments(){
     this.departmentList = this.surgeSelectedDepartments; 
     //@ts-ignore
     this.departmentList = jslinq(this.departmentList).orderBy(function (item) { return item.facilityName; }).toList();
     for (var i = 0; i < this.departmentList.length; i++) {
         this.departmentList[i] = Object.assign(this.departmentList[i], {
             isChecked: !(this.departmentList[i].isMyMarket && this.departmentList[i].preferenceId > 0),
             isCheckedChange: !(this.departmentList[i].isMyMarket && this.departmentList[i].preferenceId > 0),
             isNew: false,
             forText: 'chkgrpDepartment_chk'+ i
         });
     }
 }

 async onSurgeDisplayChange(){
   var inputData = {
     tenantId: this.profileData.TenantId,
     sourceId: 'Dashboard',
     dashboardId: this.profileData.DashboardId,
     fsUserId: this.profileData.UserId,
     fsStaffId: 0,
     SurgePreference: {
       SurgeDisplay: this.surgeDisplayValue
     }
   }
   
   this.showLoader();
   await this.$store
           .dispatch("userstaffpreferences/updateSurgePreference", inputData)
           .then(() => {
               this.emitter.emit('toggleSurgeBar', this.surgeDisplayValue);
               this.msgValue = "Surge display preference updated successfully.";
               this.classFlag = "success";
               this.isToasterVisible = true;
               this.hideLoader();
           })
           .catch((err: any) => {
               if (err) {
                   this.hideLoader();
                   this.msgValue = "Error encountered while updating surge preferences";
                   this.classFlag = "error";
                   this.isToasterVisible = true;
                   console.error(err); // Handle errors any way you want
               }
           }); 
 }

 async onSaveChanges(){
    var requestmodel: any[] = [];
    this.emitter.emit('showLoader');

    //@ts-ignore
     var modifiedPreferences= jslinq(this.departmentList)
             .where(function (item: any) {
                 return (item.isCheckedChange != item.isChecked || item.isNew == true);
             })
         .toList();
     
     modifiedPreferences.forEach((item: any) => {
       var data = {
                       "SurgeAlertPreferenceId": item.preferenceId,
                       "UserId": this.profileData.UserId,
                       "TenantId": this.profileData.TenantId,
                       "FacilityCoId": item.coId,
                       "DepartmentName": item.departmentName,
                       "IsMyMarket": item.isMyMarket
                   };
       requestmodel.push(data)
     });

     if (requestmodel.length > 0) {
       var inputData = {
         fsUserId: this.profileData.UserId,
         tenantId: this.profileData.TenantId,
         requestmodel: requestmodel
       }
       await this.$store
           .dispatch("surgealert/saveSelectedDepartment", inputData)
           .then(() => {
               this.setSelectedDepartments();
               this.msgValue = "Data saved successfully.";
               this.classFlag = "success";
               this.isToasterVisible = true;
               this.emitter.emit('hideLoader');
           })
           .catch((err: any) => {
               if (err) {
                 this.emitter.emit('hideLoader');
                   this.msgValue = "Error encountered while saving departments";
                   this.classFlag = "error";
                   this.isToasterVisible = true;
                   console.error(err); // Handle errors any way you want
               }
           }); 
     }
     else {
       this.emitter.emit('hideLoader');
       this.msgValue = "No data to save.";
       this.classFlag = "error";
       this.isToasterVisible = true;
     }
 }

 addDepartment(){
   var departmentExist = false;
   var dept = this.searchSelectedDepartment;

   if(dept == null){
     this.msgValue = "Please select department.";
     this.classFlag = "error";
     this.isToasterVisible = true;
     return;
   }
   //@ts-ignore
   var duplicatePreferences= jslinq(this.departmentList)
           .where(function (item: any) {
               return (item.coId == dept.coId && 
                     item.departmentName.toLocaleLowerCase() == dept.departmentName.toLocaleLowerCase()) ;
           })
       .toList();
   
   if(duplicatePreferences.length > 0){
       this.msgValue = "Department already added.";
       this.classFlag = "error";
       this.isToasterVisible = true;
       departmentExist = true;
       return;
   }

   if(!departmentExist){
     var obj = {
         coId: dept.coId,
         facilityName: dept.facilityName,
         departmentName: dept.departmentName,
         isMyMarket: dept.isMyMarket,
         preferenceId: 0,
         isChecked: true,
         isCheckedChange: true,
         isNew: true,
     };

     this.departmentList.push(obj);
   }
 }

 async searchDepartment() {
     if (this.searchTerm == '') {
       return []
     }
     
     var inputData = {
         fsUserId: this.profileData.UserId,
         tenantId: this.profileData.TenantId,
         searchText: this.searchTerm
     }
     this.emitter.emit('showLoader');
     await this.$store
           .dispatch("surgealert/searchDepartments", inputData)
           .then(() => {
               this.emitter.emit('hideLoader');
               this.searchDepartmentList = this.searchDepartmentResult;

               if(this.searchDepartmentList != null && this.searchDepartmentList.length > 0){
                 this.isSearchDepartmentDrpVisible = true;
                 this.isSearchNoReseultVisible = false;
               }
               else{
                  this.isSearchDepartmentDrpVisible = false;
                  this.isSearchNoReseultVisible = true;
               }
           })
           .catch((err: any) => {
               if (err) {
                   this.emitter.emit('hideLoader');
                   this.msgValue = "Error encountered while retriving departments";
                   this.classFlag = "error";
                   this.isToasterVisible = true;
                   console.error(err); // Handle errors any way you want
               }
           });
   }
 clearSearchTerm(){
   this.searchTerm = "";
   this.searchSelectedDepartment = null;
 }

 setSelectedDepartment(dept: any){
   this.searchSelectedDepartment = dept;
   this.searchTerm = dept.departmentName;
   this.isSearchDepartmentDrpVisible = false;
   this.isSearchNoReseultVisible = false;
 }

 closeAutoCompleteResult(){
   var that = this;
   document.addEventListener('click', function(e){ 
     //@ts-ignore  
     if (e.target.id != 'autoCompleteResult'){
       that.isSearchDepartmentDrpVisible = false;
     } 

     //@ts-ignore  
     if (e.target.id != 'autoCompleteNoResult'){
       that.isSearchNoReseultVisible = false;
     }
   });
 }

 closeToasterPopup() {
   this.isToasterVisible = false;
 }

 showLoader(){
     this.isShowLoader = true;
 }

 hideLoader(){
     this.isShowLoader = false;
 }
}
</script>
<style scoped>
#edSurgeContainer{
 font-size:13px; 
 padding: 15px 15px 1px;
}

.addNewLabel{
 margin: 12px 0px !important;
}

.searchTxt{
 margin: 4px 0 !important;
}

.checkbox-list {
   width: 100%;
   max-height: 250px;
   height: 250px;
   overflow-y: auto;
   margin: 0;
   padding: 0;
   text-align: left;
   border: 1px solid #CBCBD1;
   padding-top: 6px;
}

.my-div {
min-width: 100%;
}

label {
 margin-bottom: 0rem;
}
</style>