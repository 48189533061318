<template>
    <div class="assignment">
        <div id="assignmentLoadingDiv" class="loadingDiv1" style="padding: 5px;" v-if="isShowSavingLoader">
            <div id="actionable-content-loading" style="text-align: center">
                <div class="fa-2x">
                    <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                </div>
                <p><b>Saving...</b></p>
            </div>
        </div>
        <div class="row">
            <div class="col txtLeft" v-show="showHeader">
                <h5 class="card-title"><span>{{EmployeeName}}</span></h5>
                <h6 class="card-subtitle mb-3 text-muted" v-show="showPhoneNumber" >
                    Phone:<font-awesome-icon :icon="['fa', 'phone']" size="sm" class="ml-1" />&nbsp;
                    <span class="font-weight-bold">{{PhoneNumber}}</span>&nbsp;
                </h6>
                <h6 class="card-subtitle mb-3 text-muted" v-show="showShiftHeader">
                    Shift: <span class="font-weight-bold">{{ShiftCode}}</span>&nbsp;
                    <font-awesome-icon :icon="['far', 'calendar']" size="sm" /> <span class="font-weight-bold">{{shiftDate}}</span>&nbsp;
                    <font-awesome-icon :icon="['far', 'clock']" size="sm" /> <span class="font-weight-bold">{{StartTime}}</span> - <span class="font-weight-bold">{{EndTime}}</span>
                </h6>
            </div>
            <div class="row-padding">
                <a v-if="isShowTrainingLink && profileData.TrainingEnabled"
                href="#"
                class="modaltoolbarIcon neu-tooltip"
                style="cursor: pointer; color:#3673AA;" @click="fnOpenVideo">
                <span aria-hidden="true">
                    <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Learn How
                </span>
                <span class="neu-tooltip--text tooltipTxt">{{ trainingLinkToolTip }}</span>
                </a> 
                &nbsp;
                <neu-button type="button" fill="raised" id="assignment_edit_btn"
                    v-show="showEditButton" @click="formOnEdit" 
                    class="" >
                    <font-awesome-icon :icon="['fas', 'edit']" size="sm" class="mr-2" /> Edit
                </neu-button>
                <neu-button type="button" fill="raised" id="assignment_cancel_btn"
                    v-show="showCancelButton" :disabled="!enabledCancelButton" @click="!enabledCancelButton ? null : formOnCancel()"
                    class="" >
                    <font-awesome-icon :icon="['fas', 'minus-circle']" size="sm" class="mr-2" /> Cancel
                </neu-button>
                <neu-button type="button" fill="raised" id="assignment_split_btn"
                    v-show="showSplitButton" :disabled="!enabledSplitButton" @click="!enabledSplitButton ? null : formOnSplit()"
                    class="" >
                    <font-awesome-icon :icon="['fas', 'clone']" size="sm" class="mr-2" /> Split
                </neu-button>

                <neu-button type="button" fill="raised" id="assignment_back_btn"
                    @click="formOnClose"
                    class="" >
                    <font-awesome-icon :icon="['fas', 'arrow-circle-left']" size="sm" class="mr-2" /> Back to Assignments
                </neu-button>
            </div>
        </div>
        <!-- View Assignment -->
        <div v-if="showView" >
            <div class="assignment-view">
                <AssignmentView :assignmentViewInput="assignmentInput" />
            </div>
        </div>
    
        <!-- Cancel Assignment -->
        <div v-if="showCancel">
            <AssignmentCancel :assignmentCancelInput="assignmentInput" />
        </div>
    
        <!-- Edit Assignment -->
        <div v-if="showEdit">
            <div class="mx-5">
                <ul class="progress-indicator">
                    <li :class="[isEditStep ? 'active' : '', isEditReviewStep ? 'completed' : '']" >
                        <span class="bubble"></span> Edit Assignment
                    </li>
                    <li :class="[isEditReviewStep ? 'active' : '']" >
                        <span class="bubble"></span> View Summary
                    </li>
                </ul>
            </div>
    
            <div class="edit-step-1" v-show="isEditStep" >
                <div class="assignment-edit txtLeft">
                    <AssignmentEdit :assignmentEditInput="assignmentInput" containerType="editAssignment"/>
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="editFormOnExit">
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="editFormOnReview">
                                <font-awesome-icon :icon="['fas', 'angle-right']" size="sm" class="mr-2" /> Next
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="edit-step-2" v-show="isEditReviewStep">
                <div class="assignment-edit-original" v-if="showAssignmentEditOriginal"> 
                    <AssignmentEditReview :assignmentReviewInput="assignmentOriginalData"/>
                </div>
                <hr />
                <div class="assignment-edit-review" v-if="showAssignmentEditReview">
                    <AssignmentEditReview :assignmentReviewInput="modifiedAssignment"/>
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="editFormOnEdit" v-show="showEditFormPrevious"> 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="editFormOnSave" v-show="showEditFormOnSave">
                                <font-awesome-icon :icon="['fas', 'save']" size="sm" class="mr-2" /> Save &amp; Finish
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Split Assignment -->
        <div id="assignment-split" v-if="showSplit" >
            <div class="mx-5">
                <ul class="progress-indicator">
                    <li :class="[isSplitTimeStep ? 'active' : '', isSplitTimeStepComplete ? 'completed' : '']">
                        <span class="bubble"></span> Split Time
                    </li>
                    <li :class="[isSplitStep1 ? 'active' : '', isSplitStep1Complete ? 'completed' : '']">
                        <span class="bubble"></span> Assignment A
                    </li>
                    <li :class="[isSplitStep2 ? 'active' : '', isSplitStep2Complete ? 'completed' : '']">
                        <span class="bubble"></span> Assignment B
                    </li>
                    <li :class="[isSplitReviewStep ? 'active' : '', isSplitReviewStepComplete ? 'completed' : '']">
                        <span class="bubble"></span> View Summary
                    </li>
                </ul>
            </div>
    
            <div class="split-step-1" v-show="isSplitTimeStep">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="form-group row">
                            <label class="col-5 col-form-label text-end font-weight-bold">Split Time</label>
                            <div class="col txtLeft">
                                <vue-timepicker :hour-range="hourRange" format="h:mm A"  
                                    v-model="SplitTime" :key="counterSplitTime" @close="splitTimeValidation"
                                    auto-scroll manual-input hide-disabled-items close-on-complete>
                                </vue-timepicker>
                                <neu-icon v-if="isShowSplitTimerRequired" class="timerRequired material-icons" aria-hidden="true">error</neu-icon>
                                <span v-if="isShowSplitTimerRequired" class="requiredTimerTxt">{{splitTimerErrorTxt}}</span> 
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="splitFormOnExit" > 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="splitFormOnSplitTimeComplete" >
                                <font-awesome-icon :icon="['fas', 'angle-right']" size="sm" class="mr-2" /> Next
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="split-step-2" v-show="isSplitStep1">
                <div class="assignment-splitA txtLeft">
                    <AssignmentEdit :assignmentEditInput="assignmentsplitAInput" 
                                    containerType="splitAAssignment"
                                    :key="refershSplitAAssignmentCompCounter" />
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="splitFormOnPrevious" > 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="splitFormOnSplitAComplete" >
                                <font-awesome-icon :icon="['fas', 'angle-right']" size="sm" class="mr-2" /> Next
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="split-step-3" v-show="isSplitStep2">
                <div class="assignment-splitB txtLeft">
                    <AssignmentEdit :assignmentEditInput="assignmentsplitBInput" 
                        containerType="splitBAssignment"
                        :key="refershSplitBAssignmentCompCounter" />
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="splitFormOnPrevious" > 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="splitFormOnSplitBComplete" >
                                <font-awesome-icon :icon="['fas', 'angle-right']" size="sm" class="mr-2" /> Next
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="split-step-4" v-show="isSplitReviewStep">
                <div class="assignment-split-original" v-if="showAssignmentSplitOriginal"> 
                    <AssignmentEditReview :assignmentReviewInput="assignmentOriginalData"/>
                </div>
                <hr />
                <div class="assignment-splitA-review" v-if="showAssignmentSplitAssignA">
                    <AssignmentEditReview :assignmentReviewInput="modifiedassignmentsplitAInput"/>
                </div>
                <hr/>
                <div class="assignment-splitB-review" v-if="showAssignmentSplitAssignB">
                    <AssignmentEditReview :assignmentReviewInput="modifiedassignmentsplitBInput"/>
                </div>
                <hr/>
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="splitFormOnPrevious"  v-show="showSplitFormPrevious"> 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="splitFormOnSave"  v-show="showSplitFormOnSave"> 
                                <font-awesome-icon :icon="['fas', 'save']" size="sm" class="mr-2" /> Save &amp; Finish
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    
        <!-- Create Assignment -->
        <div v-if="showCreate">
            <div class="mx-5">
                <ul class="progress-indicator">
                    <li :class="[isCreateStep ? 'active' : '', isCreateReviewStep ? 'completed' : '']" >
                        <span class="bubble"></span> Create Assignment
                    </li>
                    <li :class="[isCreateReviewStep ? 'active' : '']">
                        <span class="bubble"></span> View Summary
                    </li>
                </ul>
            </div>
    
            <div class="create-step-1" v-show="isCreateStep">
                <div class="assignment-create txtLeft">
                    <AssignmentEdit :assignmentEditInput="assignmentInput" containerType="createAssignment" />
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="createFormOnExit"> 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="createFormOnReview">
                                <font-awesome-icon :icon="['fas', 'angle-right']" size="sm" class="mr-2" /> Next
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="edit-step-2" v-show="isCreateReviewStep">
                <div class="assignment-create-review"  v-if="showAssignmentCreateOriginal">
                    <AssignmentEditReview :assignmentReviewInput="modifiedCreateAssignment"/>
                </div>
                <hr />
                <div class="actions">
                    <div class="row">
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="prev_btn"
                                @click="createFormOnEdit" v-show="showCreateFormPrevious"> 
                                <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" class="mr-2" /> Previous
                            </neu-button>
                        </div>
                        <div class="col-sm">
                            <neu-button type="button" fill="raised" name="next_btn"
                                @click="createFormOnSave" v-show="showCreateFormOnSave">
                                <font-awesome-icon :icon="['fas', 'save']" size="sm" class="mr-2" /> Save &amp; Finish
                            </neu-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Toaster -->
        <div v-if="isToasterVisible"> 
          <ToasterPopup @closeModal="closeToasterPopup"
                        :msgValue="msgValue"
                        :classFlag="classFlag"></ToasterPopup>
        </div>
        <!-- Feedback Snackbar with Action -->
        <div v-if="isAlertVisible"> 
          <FeedbackSnackbar @closeModal="closeAlertPopup"
                        :msgValue="msgValueAlert"
                        :classFlag="classFlagAlert"></FeedbackSnackbar>
        </div>
    
        <div v-if="isShowCareAssignmentSheetPopup">
            <CareAssignment @closePrintTemplate="closeCareAssignmentSheetPopup" :payloadCareAssignment="payloadCareAssignment">
            </CareAssignment>
        </div>
        <div v-if="isShowExportToExcel">
            <ExportToExcelForTabs @closeExcelTemplate="closeHomeTabExportToExcel" :payloadExportToExcel="payloadExportToExcel">
            </ExportToExcelForTabs>
        </div>
    </div>
    </template>
    <script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";
    import { mapState } from "vuex";
    import * as Moment from 'moment';
    import { extendMoment } from 'moment-range';
    import { Profile } from '@/models/profile/index';
    //imports models and enums
    
    //Import Componenets
    import ToasterPopup from "../../shared/ToasterPopup.vue";
    import FeedbackSnackbar from "../../shared/FeedbackSnackbar.vue";
    import {
        NeuButton
    } from "@neutron/vue";
    import AssignmentView from './AssignmentView.vue';
    import AssignmentCancel from './AssignmentCancel.vue';
    import AssignmentEdit from './AssignmentEdit.vue';
    import AssignmentEditReview from './AssignmentEditReview.vue';
    import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue';
    import { useAppInsights } from '../../../store/modules/AppInsights';
    import CareAssignment from '../header/CareAssignment.vue';
    import ExportToExcelForTabs from '../header/ExportToExcelForTabs.vue';
    
    
    class Props {
        assignmentInput!: any
        assignmentType!: any 
    }
    
    @Options({
      computed: {
        ...mapState('actionable', ['priorDaySettings', 'careAssignmentData']),
        ...mapState('assignments', ['editAssignmentResult', 'cancelAssignmentResult', 'splitAssignmentResult', 'createAssignmentResult']),
        ...mapState('profile', ['profileData', 'appInsightEventData']),
      },
      components: {
        NeuButton,
        ToasterPopup,
        FeedbackSnackbar,
        AssignmentView,
        AssignmentEdit,
        AssignmentCancel,
        AssignmentEditReview,
        VueTimepicker,
        CareAssignment,
        ExportToExcelForTabs
      }
    }) 
    export default class Assignment extends Vue.with(Props) {
        //Data properties
        msgValue!: string;
        classFlag!: string;
        isToasterVisible:boolean = false;
    
        msgValueAlert!: string;
        classFlagAlert!: string;
        careAssignmentData!: any;
        isAlertVisible:boolean = false;
        facilityCoid :any = null;
        departmentCode :any = null;
        priorDaySettings!: any;
        editAssignmentResult!: any;
        cancelAssignmentResult!: any;
        splitAssignmentResult!: any;
        createAssignmentResult!: any;
        shortTimeFormat: string = 'hh:mm a';
        shortDateFormatPresentation: string = 'MM/DD/YYYY';
        shortDateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ss';
        appInsightEventData!:any ;
        profileData!: Profile;
        EmployeeName: string = "";
        PhoneNumber: any = null;
        ShiftCode: string = "";
        shiftDate: any = null;
        StartTime: any = null;
        EndTime: any = null;
        isChanged: boolean = false;
        SplitTime: any = {
            "h": "", 
            "mm": "",
            "A": ""
        }; 
        SplitTimeMin: string = '12:00 AM';
        SplitTimeMax: string = '12:00 PM';
    
        // Action states
        showHeader: boolean = false;                
        showView: boolean = false;
        showEdit: boolean = false;
        showCancel: boolean = false;
        showSplit: boolean = false;
        showCreate: boolean = false;
        showShiftHeader: boolean = false;
        showPhoneNumber: boolean = false;
    
        // Button Action states
        showEditButton: boolean = false;
        showCancelButton: boolean = false;
        enabledCancelButton: boolean = true;
        enabledSplitButton: boolean = false;
        showSplitButton: boolean = true;
        showEditFormOnSave: boolean = true;
        showEditFormPrevious: boolean = true;
        showCreateFormPrevious: boolean = true;
        showCreateFormOnSave: boolean = true;
        showSplitFormPrevious: boolean = true;
        showSplitFormOnSave: boolean = true;
    
        // Edit progress indicators
        isEditStep: boolean = false;
        isEditReviewStep: boolean = false;
    
         // Create progress indicators
        isCreateStep: boolean = false;
        isCreateReviewStep: boolean = false;
    
        // Split progress indicators
        isSplitTimeStep: boolean = false;
        isSplitTimeStepComplete: boolean = false;
        isSplitStep1: boolean = false;
        isSplitStep1Complete: boolean = false;
        isSplitStep2: boolean = false;
        isSplitStep2Complete: boolean = false;
        isSplitReviewStep: boolean = false;
        isSplitReviewStepComplete: boolean = false;
    
        //Validation Flag
        isShowSplitTimerRequired: boolean = false;
        splitTimerErrorTxt: string = "";
    
        //Edit Assignment Input Data
        modifiedAssignment: any = null;
        assignmentOriginalData: any = null;
    
        //Split Assignment Input Data
        assignmentsplitAInput: any = null;
        assignmentsplitBInput: any = null;
        modifiedassignmentsplitAInput: any = null;
        modifiedassignmentsplitBInput: any = null;
    
        modifiedCreateAssignment: any = null;
    
        //Edit Assignment Data Validation Flag
        isEditValidationSuccess: boolean = true; 
        isSplitAssignAValidationSuccess: boolean = true; 
        isSplitAssignBValidationSuccess: boolean = true; 
        isCreateValidationSuccess: boolean = true; 
    
        showAssignmentCreateOriginal: boolean = false; 
        showAssignmentEditOriginal: boolean = false; 
        showAssignmentEditReview: boolean = false;
        showAssignmentSplitOriginal: boolean = false;  
        showAssignmentSplitAssignA: boolean = false;  
        showAssignmentSplitAssignB: boolean = false; 
        isShowSavingLoader: boolean = false;
        counterSplitTime: number = 0;
        hourRange: any = null;
        minutesRange: any = null;
        refershSplitAAssignmentCompCounter: number = 0;
        refershSplitBAssignmentCompCounter: number = 0;
    
        isShowCareAssignmentSheetPopup: boolean = false;
        isShowExportToExcel: boolean = false;
        payloadCareAssignment!: any;
        payloadExportToExcel!: any;       
        trainingLinkToolTip: string = '';
        trainingCurrentLink: string = '';
        isShowTrainingLink: boolean = false;
    
        //Lifecycle hooks
        created(){
            this.emitter.on('showToasterNotificationAssignment', this.showToasterNotificationAssignment);
            this.emitter.on('showAlertPopup', this.showAlertPopup);
            this.emitter.on('updateModifiedAssignmentData', this.updateModifiedAssignmentData);
            this.emitter.on('updateModifiedSplitAAssignmentData', this.updateModifiedSplitAAssignmentData);
            this.emitter.on('updateModifiedSplitBAssignmentData', this.updateModifiedSplitBAssignmentData);
            this.emitter.on('updateModifiedCreateAssignmentData', this.updateModifiedCreateAssignmentData);
            this.emitter.on('updateValidationFlag', this.updateValidationFlag);
            this.emitter.on('loadCareAssignmentSheetFromAssignment', this.loadCareAssignmentSheetFromAssignment);
            this.emitter.on('homeTabExportToExcelFromAssignment', this.homeTabExportToExcelFromAssignment);
    
            this.facilityCoid = this.appInsightEventData.COID == undefined ? '' : this.appInsightEventData.COID; 
        } 
    
        unmounted(){
            this.emitter.off('showToasterNotificationAssignment', this.showToasterNotificationAssignment);
            this.emitter.off('showAlertPopup', this.showAlertPopup);
            this.emitter.off('updateModifiedAssignmentData', this.updateModifiedAssignmentData);
            this.emitter.off('updateModifiedSplitAAssignmentData', this.updateModifiedSplitAAssignmentData);
            this.emitter.off('updateModifiedSplitBAssignmentData', this.updateModifiedSplitBAssignmentData);
            this.emitter.off('updateModifiedCreateAssignmentData', this.updateModifiedCreateAssignmentData);
            this.emitter.off('updateValidationFlag', this.updateValidationFlag);  
            this.emitter.off('loadCareAssignmentSheetFromAssignment', this.loadCareAssignmentSheetFromAssignment);
            this.emitter.off('homeTabExportToExcelFromAssignment', this.homeTabExportToExcelFromAssignment);
        } 
    
        async mounted(){
            console.log(this.assignmentInput);
            if (this.assignmentType.toLowerCase() == "View".toLowerCase()){
                await this.loadAssignment();
            }
            if (this.assignmentType.toLowerCase() == "Create".toLowerCase()){
                this.loadCreateAssignment();
            }
            this.updateAppInsights();
        }    
    
        async updateAppInsights(){
              var inputData =  {
                    Username:  this.profileData.UserName,
                    TenantName:  this.profileData.TenantName,
                    FacilityName: this.assignmentInput.facilityName,
                    COID: this.facilityCoid,            
                    DeptCode: this.assignmentInput.departmentCode ,            
                    DeptName: this.assignmentInput.departmentName,
                    Skill : this.assignmentInput.skillLevelDescription
                
                 };
                 await this.$store
                    .dispatch("profile/updateAppInsights", inputData)
                    .then(() => {
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_ASSIGNMENT_VIEW', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                        })
                    .catch((err: any) => {
                        if (err) {
                            console.error(err); // Handle errors any way you want
                        }
                    });
            }
    
        //Methods
        async loadAssignment(){
            var now = moment(new Date()); //todays date
            var end = moment(this.assignmentInput.startTime); // another date
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            var isCancelled = this.assignmentInput.isCancelled;
    
            var facilityPriorDatesEditable = this.assignmentInput.facilityConfig
                && this.assignmentInput.facilityConfig.FacilityPriorDatesEditable
                ? this.assignmentInput.facilityConfig.FacilityPriorDatesEditable
                : 1;
            
            this.map();
    
            // set display flag
            this.showHeader = true;
            this.showShiftHeader = true;
            this.showPhoneNumber = true;
            this.showView = true;
            this.showEditButton =  true;
            this.showCancelButton = true;
            this.enabledCancelButton = !isCancelled;
            this.showSplitButton = this.assignmentInput.productiveId === 5 ? false : true;
    
            if (days > facilityPriorDatesEditable) {
                // date is future
                this.showEditButton = false;
                this.showCancelButton = false;
                this.showSplitButton = false;
            }
    
            var inputData = {
                facilityId: this.assignmentInput.facilityId
            }
            await this.$store
                    .dispatch("actionable/getPriorDaySetting", inputData)
                    .then(() => {
                        var enabledSplitButton = true;
                        if (this.priorDaySettings != null) {
                            if (days > this.priorDaySettings.priorDaysEditable) {
                                enabledSplitButton = false;
                            }
                        }
                        else {
                            if (days > 1) {
                                enabledSplitButton = false;
                            }
                        }
                        if (isCancelled) {
                            enabledSplitButton = false;
                        }
                        this.enabledSplitButton = this.assignmentInput.productiveId === 5 ? false : enabledSplitButton;
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.msgValue = "Error encountered while retrieving Prior Day Setting.";
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                            console.error(err); // Handle errors any way you want
                        }
                    });
            this.assignmentsplitAInput = JSON.parse(JSON.stringify(this.assignmentInput));
            this.assignmentsplitBInput = JSON.parse(JSON.stringify(this.assignmentInput));
        }
    
        loadCreateAssignment(){
            this.EmployeeName = this.assignmentInput.lastName + ', ' + this.assignmentInput.firstName;
            this.showSplitButton = false;
            this.showCreate = true;
            this.isCreateStep =  true;
            this.showHeader = true;
            this.showShiftHeader = false;
            this.showPhoneNumber = true;
            this.PhoneNumber = this.getValue(this.assignmentInput.phoneNumber);
        }
    
        map(){
            var assignDate = moment(this.getValue(this.assignmentInput.date));
            var assignStartTime = moment(this.assignmentInput.startTime);
            var assignEndTime = moment(this.assignmentInput.endTime);
    
            this.SplitTimeMin = moment(assignStartTime).format(this.shortTimeFormat);
            this.SplitTimeMax = moment(assignEndTime).format(this.shortTimeFormat);
            this.EmployeeName = this.getValue(this.assignmentInput.lastName) + ', ' + this.getValue(this.assignmentInput.firstName);
            this.PhoneNumber = this.getValue(this.assignmentInput.phoneNumber);
            this.ShiftCode = this.getValue(this.assignmentInput.shiftCode);
            this.shiftDate = moment(assignDate).format(this.shortDateFormatPresentation);
            this.StartTime = moment(assignStartTime).format(this.shortTimeFormat);
            this.EndTime = moment(assignEndTime).format(this.shortTimeFormat);
        }
    
        getValue(obj: any) {
            if (typeof obj === undefined) {
                return null;
            }
            return obj;
        }
    
        formOnEdit(){
            this.showView = false;
            this.showEditButton =  false;
            this.showCancelButton = false;
            this.showSplitButton = false;
            this.showEdit = true;
            this.showSplit = false;
            this.isEditStep = true;
            this.trainingCurrentLink = 'https://mediaconnect.app.medcity.net/embed?id=c8536fa0-b549-4a2b-8e99-49e55158dcde';
            this.trainingLinkToolTip='Click to learn how to edit and float an assignment';
            this.isShowTrainingLink = true && this.profileData.TrainingEnabled;
        } 
    
        formOnCancel(){
            if(this.enabledCancelButton){
                this.showView = false;
                this.showEditButton = false;
                this.showCancelButton =  false;
                this.showSplitButton =  false;
                this.showEdit = false;
                this.showCancel =  true;
                this.showSplit =  false;
                this.trainingCurrentLink = 'https://mediaconnect.app.medcity.net/embed?id=71135567-f7a3-4650-8faf-d61415c7dd79';
                this.trainingLinkToolTip='Click to learn how to cancel an assignment';
                this.isShowTrainingLink = true && this.profileData.TrainingEnabled;
            }
            else{
                return;
            }
        } 
    
        formOnSplit(){
            // get start and end times of current assignment
            var splitMin = moment(this.getValue(this.assignmentInput.startTime));
            var splitMax = moment(this.getValue(this.assignmentInput.endTime));
    
            // set limits for split time control
            if (splitMin.isValid() && splitMax.isValid()) {
                const moment = extendMoment(Moment);
                const range = moment.range(splitMin, splitMax);
    
                const hours = Array.from(range.by('hour'));
                this.hourRange = hours.map(m => m.format('ha').slice(0, -1));
    
                const minutes = Array.from(range.by('minutes', { step: 1 }));
                this.minutesRange = minutes.map(m => m.format('h:mm A'));
    
                var min = splitMin.format('h:mm A');
                var max = splitMax.format('h:mm A');
    
                this.SplitTimeMin = min;
                this.SplitTimeMax = max;
            }
    
            this.counterSplitTime = this.counterSplitTime + 1;
            
            this.showView = false;
            this.showEditButton = false;
            this.showCancelButton = false;
            this.showSplitButton = false;
            this.showEdit = false;
            this.showCancel = false;
            this.showSplit = true;
            // this.showSelectSplitTime = true;
            this.isSplitTimeStep = true;
            this.trainingCurrentLink = 'https://mediaconnect.app.medcity.net/embed?id=0916bc45-e695-4952-a075-4d438c163a17';
            this.trainingLinkToolTip='Click to learn how to split an assignment';
            this.isShowTrainingLink = true  && this.profileData.TrainingEnabled;
        }  
    
        formOnClose(){
            this.emitter.emit('unloadAssignment');
        } 
    
        closeToasterPopup() {
          this.isToasterVisible = false;
        }
    
        closeAlertPopup(){
            this.isAlertVisible = false;
        }
    
        showAlertPopup(input: any){
            this.msgValueAlert = input.msg;
            this.classFlagAlert = input.flag;
            this.isAlertVisible = true;
        }
    
        showToasterNotificationAssignment(data: any){
            this.msgValue = data.message;
            this.classFlag = data.flag;
            this.isToasterVisible = true;
        }
    
        editFormOnExit(){
            this.showEdit = false;
            this.loadAssignment();
        }
    
        editFormOnReview(){
            if(this.isEditValidationSuccess){
                //fetch Edited Data
                this.assignmentOriginalData = JSON.parse(JSON.stringify(this.assignmentInput));
                this.modifiedAssignment.showNote = true;
                
                // Assignment was cancel
                if (this.modifiedAssignment.isProductiveChange === false && this.modifiedAssignment.isCancelled === true) {
                    this.assignmentOriginalData.showStatus = false;// true;
                    this.assignmentOriginalData.isCancelled = true;
                    this.assignmentOriginalData.status = '';//'Cancel';
                }
    
                if (this.modifiedAssignment.isProductiveChange) {
                    // Set the flag so that the original assignment is set to cancel
                    this.assignmentOriginalData.isProductiveChange = true;
                    this.assignmentOriginalData.isCancelled = true;
                    this.assignmentOriginalData.status = '';//'Cancel';
                    this.assignmentOriginalData.showStatus = false;// true;
                }
    
                // Clear flags new assignments
                this.modifiedAssignment.isProductiveChange = false;
                this.modifiedAssignment.showStatus = false; 
                this.modifiedAssignment.showTitle = true;
                this.assignmentOriginalData.showTitle = true;
                this.modifiedAssignment.title = "New Assignment";
                this.assignmentOriginalData.title = "Original Assignment"; 
    
                this.showAssignmentEditOriginal = true;
                this.showAssignmentEditReview = true;
                this.isEditStep = false;
                this.isEditReviewStep = true;
            }
        }
    
        updateModifiedAssignmentData(assignmentData: any){
            this.modifiedAssignment = JSON.parse(JSON.stringify(assignmentData));
        }

        fnOpenVideo(){
        window.open(this.trainingCurrentLink, '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How');
    } 
    
        editFormOnEdit(){
            this.isEditStep = true;
            this.isEditReviewStep = false;
            this.showAssignmentEditOriginal = false;
            this.showAssignmentEditReview = false;
            this.trainingCurrentLink = 'https://mediaconnect.app.medcity.net/embed?id=c8536fa0-b549-4a2b-8e99-49e55158dcde';
            this.trainingLinkToolTip='Click to learn how to edit and float an assignment';
            this.isShowTrainingLink = true;
            // this.emitter.emit('mapEditedAssignmentData', this.modifiedAssignment);
        } 
        
        async editFormOnSave(){
            if (this.modifiedAssignment.isCancelled){
                await this.cancelAssignment();
            }
            else{
                await this.editAssignment();
            }
        }
    
        async editAssignment(){
            var data = this.modifiedAssignment;
            var inputData: any = {
                AssignmentId: data.assignId,
                DepartmentId: data.departmentId,
                SkillLevelId: data.skillLevelId,
                Date: moment(data.date).format(this.shortDateTimeFormat),
                DepartmentShiftId: data.departmentShiftId === 0 ? null : data.departmentShiftId,
                Charge: data.charge,
                Float: data.isFloat,
                Comment: data.note,
                StartTime: moment(data.startTime).format(this.shortDateTimeFormat),
                EndTime: moment(data.endTime).format(this.shortDateTimeFormat),
                Hours: Math.ceil(data.durationHours),
                Minutes: Math.ceil(data.durationMinutes),
                StaffId: data.staffId,
                ProductiveId: data.productiveId,
                CountTypeId: data.countTypeId,
                Cancel: data.isCancelled,
                CancelReasonId: data.cancelReasonId === -1 ? null : data.cancelReasonId,
                UnscheduledAbsence: data.unscheduledAbsence,
                UnscheduledAbsenceOption: data.unscheduledAbsenceOption,
                FloatUserModified: data.floatChangedByUser == 1 ? true : false,
                AssociationType: data.associationType,
                OrigDeptId: data.origDepartmentId
            };
    
            this.showSavingLoader();
            await this.$store
                    .dispatch("assignments/editAssignment", inputData)
                    .then(() => {
                        this.hideSavingLoader();
                        this.editCancelOnSuccess(this.editAssignmentResult, "Assignment edited successfully.", "Edit");
    
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_ASSIGNMENT_EDIT', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideSavingLoader();
                            var response = err.response.data;
                            var errorData = {
                                message: response,
                                flag: "error"
                            }
                            this.emitter.emit('showToasterNotificationActionable', errorData);
                            console.error(err); // Handle errors any way you want
                        }
                    });
        }
    
        editCancelOnSuccess(resultData: any, successMsg: string, successType: string){
            var successData = {
                message: successMsg,
                flag: "success"
            }
            this.emitter.emit('showToasterNotificationActionable', successData);
    
            if (successType.toLowerCase() == "Split".toLowerCase()){
                if (resultData != null) {
                    if (resultData[0].isNotificationSuccess) {
                        var successData = {
                            message: "Confirmation SMS sent.",
                            flag: "success"
                        }
                        this.emitter.emit('showToasterDelayNotificationActionable', successData);
                        this.appInsightEventData.SMSSuccess = 'True';
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_SMS_ASSIGNMENT_SPLIT', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    }
                    else {
                        var errorData = {
                            message: resultData[0].notificationResponseMessage,
                            flag: "error"
                        }
                        this.emitter.emit('showToasterDelayNotificationActionable', errorData);
                        this.appInsightEventData.SMSSuccess = 'False';
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_SMS_ASSIGNMENT_SPLIT', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    }
                }
            }
            else{
    
                var eventName = "DASHBOARD_SMS_ASSIGNMENT_EDIT";
                if (successType.toLowerCase() == "Edit".toLowerCase()){
                
                    eventName = "DASHBOARD_SMS_ASSIGNMENT_EDIT";
                }
                else if (successType.toLowerCase() == "Cancel".toLowerCase()){
                
                    eventName = "DASHBOARD_SMS_ASSIGNMENT_CANCEL";
                }
                else if (successType.toLowerCase() == "Create".toLowerCase()){
                
                    eventName = "DASHBOARD_SMS_ASSIGNMENT_CREATE";
                }
                if (resultData != null) {
                    if (resultData.isNotificationSuccess) {
                        var successData = {
                            message: "Confirmation SMS sent.",
                            flag: "success"
                        }
                        this.emitter.emit('showToasterDelayNotificationActionable', successData);
                        this.appInsightEventData.SMSSuccess = 'True';
                        useAppInsights().trackEvent({
                            name: eventName, properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    }
                    else {
                        var errorData = {
                            message: resultData.notificationResponseMessage,
                            flag: "error"
                        }
                        this.emitter.emit('showToasterDelayNotificationActionable', errorData);
                        this.appInsightEventData.SMSSuccess = 'False';
                        useAppInsights().trackEvent({
                            name: eventName, properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    }
                }
            }
            
            this.emitter.emit('unloadAssignment');
        }
        async cancelAssignment(){
            var data = this.modifiedAssignment;
    
            var assignmentCancelModel: any = {
                AssignId: data.assignId,
                Note: data.Note == null ? "": data.note,
                CancelReasonId: data.cancelReasonId,
                UnscheduledAbsence: data.unscheduledAbsence,
                UnscheduledAbsenceOptionId: data.unscheduledAbsenceOption
            };
    
            this.showSavingLoader();
            await this.$store
                    .dispatch("assignments/cancelAssignment", assignmentCancelModel)
                    .then(() => {
                        this.hideSavingLoader();
                        this.editCancelOnSuccess(this.cancelAssignmentResult, "Assignment edited successfully.", "Cancel");
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_ASSIGNMENT_CANCEL', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideSavingLoader();
                            var response = err.response.data;
                            var errorData = {
                                message: response,
                                flag: "error"
                            }
                            this.emitter.emit('showToasterNotificationActionable', errorData);
                            console.error(err); // Handle errors any way you want
                        }
                    });
        }
    
        showSavingLoader(){
            this.isShowSavingLoader = true;
        }
    
        hideSavingLoader(){
            this.isShowSavingLoader = false;
        }
    
        updateModifiedSplitAAssignmentData(assignmentData: any){
            this.modifiedassignmentsplitAInput = JSON.parse(JSON.stringify(assignmentData));
        } 
    
        updateModifiedSplitBAssignmentData(assignmentData: any){
            this.modifiedassignmentsplitBInput = JSON.parse(JSON.stringify(assignmentData));
        }
    
        updateModifiedCreateAssignmentData(assignmentData: any){
            this.modifiedCreateAssignment = JSON.parse(JSON.stringify(assignmentData));
        }
    
        splitFormOnExit(){
            this.showSplit = false;
            this.loadAssignment();
        }
    
        splitFormOnPrevious(){
            var currentStep: string = "";
    
            if (this.isSplitTimeStep) { currentStep = 'isSplitTimeStep'; }
            if (this.isSplitStep1) { currentStep = 'isSplitStep1'; }
            if (this.isSplitStep2) { currentStep = 'isSplitStep2'; }
            if (this.isSplitReviewStep) { currentStep = 'isSplitReviewStep'; }
    
            // Reset all progress indicators
            this.isSplitTimeStep = false;
            this.isSplitTimeStepComplete = false;
            this.isSplitStep1 = false;
            this.isSplitStep1Complete = false;
            this.isSplitStep2 = false;
            this.isSplitStep2Complete = false;
            this.isSplitReviewStep = false;
            this.isSplitReviewStepComplete = false;
    
            switch (currentStep) {
            case 'isSplitTimeStep':
                break;
            case 'isSplitStep1':
                this.isSplitTimeStep = true;
                this.isSplitTimeStepComplete = true;
                break;
            case 'isSplitStep2':
                this.isSplitTimeStepComplete = true;
                this.isSplitStep1 = true;
                this.isSplitStep1Complete = true;
                break;
            case 'isSplitReviewStep':
                this.isSplitTimeStepComplete = true;
                this.isSplitStep1Complete = true;
                this.isSplitStep2 = true;
                this.isSplitStep2Complete = true;
                this.showAssignmentSplitOriginal = false;
                this.showAssignmentSplitAssignA = false;
                this.showAssignmentSplitAssignB = false;
                break;
            }
        }
    
        splitFormOnSplitTimeComplete(){
            if(this.splitTimeValidation()){
                var splitTimeTxt = this.SplitTime.h + ':'+ this.SplitTime.mm + ' ' + this.SplitTime.A;
                var splitTime: any = moment(splitTimeTxt, ["h:mm A"]).format("HH:mm");
                this.assignmentsplitAInput = JSON.parse(JSON.stringify(this.assignmentInput));
    
                var shiftStartTime = moment(this.assignmentsplitAInput.startTime).format('h:mm A');
                var shiftTimeAbrv = shiftStartTime.split(" ")[1];
                if(shiftTimeAbrv == this.SplitTime.A){
                    var startDate = moment(this.assignmentsplitAInput.startTime).format('YYYY-MM-DD');
                    splitTime = moment(startDate + ' ' + splitTime, this.shortDateTimeFormat);
                }
                else{
                    var startDate = moment(this.assignmentsplitAInput.endTime).format('YYYY-MM-DD');
                    splitTime = moment(startDate + ' ' + splitTime, this.shortDateTimeFormat);
                }
            
                this.assignmentsplitAInput.isAssignmentA = true;
                this.assignmentsplitAInput.isSplit = true;
                this.assignmentsplitAInput.startTime = moment(this.assignmentsplitAInput.startTime).toDate();
                this.assignmentsplitAInput.endTime = splitTime.toDate();
                this.assignmentsplitAInput.shiftCode = null;
                this.assignmentsplitAInput.departmentShiftId = null;
    
                // load edit control
                this.assignmentsplitAInput.title = "Assignment A";
                this.assignmentsplitAInput.showTitle = true;
                
                this.refershSplitAAssignmentCompCounter = this.refershSplitAAssignmentCompCounter + 1;
                // Split progress indicators
                this.isSplitTimeStep = false;
                this.isSplitTimeStepComplete = true;
                this.isSplitStep1 = true;
                this.isSplitStep1Complete = false;
                this.isSplitStep2 = false;
                this.isSplitStep2Complete = false;
                this.isSplitReviewStep = false;
                this.isSplitReviewStepComplete = false;
            }
        }
    
        splitTimeValidation(): boolean{
            var splitTimeTxt = this.SplitTime.h + ':'+ this.SplitTime.mm + ' ' + this.SplitTime.A;
            if(this.SplitTime.h == "" || this.SplitTime.mm == "" || this.SplitTime.A == ""){
                //@ts-ignore
                document.getElementsByClassName('vue__time-picker-input')[0].style.setProperty('border', '0.125rem solid #DF2500', 'important');
                this.splitTimerErrorTxt = 'Required';
                this.isShowSplitTimerRequired = true;
                return false;
            }
            else if(!this.minutesRange.includes(splitTimeTxt)){
                //@ts-ignore
                document.getElementsByClassName('vue__time-picker-input')[0].style.setProperty('border', '0.125rem solid #DF2500', 'important');
                this.splitTimerErrorTxt = 'Selected time must be within assignment start and end times.';
                this.isShowSplitTimerRequired = true;
                return false;
            }
            else{
                this.isShowSplitTimerRequired = false; 
                //@ts-ignore
                document.getElementsByClassName('vue__time-picker-input')[0].style.removeProperty('border');
                return true;
            }
        }
    
        splitFormOnSplitAComplete(){
            if(this.isSplitAssignAValidationSuccess){
                this.assignmentsplitBInput = JSON.parse(JSON.stringify(this.assignmentInput));
    
                this.assignmentsplitBInput.isAssignmentB = true;
                this.assignmentsplitBInput.isSplit = true;
                this.assignmentsplitBInput.date = moment(this.assignmentsplitAInput.endTime).format('YYYY-MM-DD');
                this.assignmentsplitBInput.startTime = this.assignmentsplitAInput.endTime;
                this.assignmentsplitBInput.endTime = moment(this.assignmentsplitBInput.endTime).toDate();
                this.assignmentsplitBInput.shiftCode = null;
                this.assignmentsplitBInput.departmentShiftId = null;
    
                // load edit control
                this.assignmentsplitBInput.title = "Assignment B";
                this.assignmentsplitBInput.showTitle = true;
                this.refershSplitBAssignmentCompCounter = this.refershSplitBAssignmentCompCounter + 1;
    
                // Split progress indicators
                this.isSplitTimeStep = false;
                this.isSplitTimeStepComplete = true;
                this.isSplitStep1 = false;
                this.isSplitStep1Complete = true;
                this.isSplitStep2 = true;
                this.isSplitStep2Complete = false;
                this.isSplitReviewStep = false;
                this.isSplitReviewStepComplete = false; 
            }
        }
    
        splitFormOnSplitBComplete(){
            if(this.isSplitAssignBValidationSuccess){
                //fetch Edited Data
                this.assignmentOriginalData = JSON.parse(JSON.stringify(this.assignmentInput));
                this.modifiedassignmentsplitAInput.showNote = true;
                this.modifiedassignmentsplitBInput.showNote = true;
    
                if (this.modifiedassignmentsplitAInput.isProductiveChange || 
                    this.modifiedassignmentsplitBInput.isProductiveChange || 
                    this.modifiedassignmentsplitAInput.isCancelled || 
                    this.modifiedassignmentsplitBInput.isCancelled) {
    
                    // Set the flag so that the original assignment is set to cancel
                    this.assignmentOriginalData.isProductiveChange = true;
                    this.assignmentOriginalData.isCancelled = true;                        
                    this.assignmentOriginalData.status = '';// 'Cancel';
                    this.assignmentOriginalData.showStatus = false;// true;
    
                    // Clear flags new assignments
                    this.modifiedassignmentsplitAInput.isProductiveChange = false;
                    this.modifiedassignmentsplitAInput.showStatus = false;
                    this.modifiedassignmentsplitBInput.isProductiveChange = false;
                    this.modifiedassignmentsplitBInput.showStatus = false;
                }
    
                if (this.modifiedassignmentsplitAInput.isCancelled || 
                    this.modifiedassignmentsplitBInput.isCancelled) {
    
                    // Set flags new assignments
                    this.modifiedassignmentsplitAInput.isProductiveChange = false;
                    this.modifiedassignmentsplitAInput.showStatus = false;// splitAData.IsCancelled === true ? true : false;
                    this.modifiedassignmentsplitAInput.status = ''; //splitAData.IsCancelled === true ? 'Cancel' : '';
    
                    this.modifiedassignmentsplitBInput.isProductiveChange = false;
                    this.modifiedassignmentsplitBInput.showStatus = false;// splitBData.IsCancelled === true ? true : false;
                    this.modifiedassignmentsplitBInput.status = ''; //splitBData.IsCancelled === true ? 'Cancel' : '';
                }
    
                this.assignmentOriginalData.title ='Original Assignment';
                this.assignmentOriginalData.showTitle = true;
    
                this.modifiedassignmentsplitBInput.title = "Assignment B";
                this.modifiedassignmentsplitBInput.showTitle = true;
    
                this.modifiedassignmentsplitAInput.title = "Assignment A";
                this.modifiedassignmentsplitAInput.showTitle = true;
    
                this.showAssignmentSplitOriginal = true;
                this.showAssignmentSplitAssignA = true;
                this.showAssignmentSplitAssignB = true;
                // Split progress indicators
                this.isSplitTimeStep = false;
                this.isSplitTimeStepComplete = true;
                this.isSplitStep1 = false;
                this.isSplitStep1Complete = true;
                this.isSplitStep2 = false;
                this.isSplitStep2Complete = true;
                this.isSplitReviewStep = true;
                this.isSplitReviewStepComplete = false;  
            }
        }
    
        async splitFormOnSave(){
            var assignmentA: any = JSON.parse(JSON.stringify(this.modifiedassignmentsplitAInput));
            var assignmentB: any = JSON.parse(JSON.stringify(this.modifiedassignmentsplitBInput));
    
            var assignments: any = {
                assignmentId: this.assignmentOriginalData.assignId,
                assignmentsData: {
                    Assignments: [
                        {
                            DepartmentId: assignmentA.departmentId,
                            SkillLevelId: assignmentA.skillLevelId,
                            Date: moment(assignmentA.date).format(this.shortDateTimeFormat),
                            DepartmentShiftId: assignmentA.departmentShiftId === 0 ? null : assignmentA.departmentShiftId,
                            Charge: assignmentA.charge,
                            Float: assignmentA.isFloat,
                            Comment: assignmentA.note == null ? "": assignmentA.note,
                            StartTime: moment(assignmentA.startTime).format(this.shortDateTimeFormat),
                            EndTime: moment(assignmentA.endTime).format(this.shortDateTimeFormat),
                            Hours: Math.ceil(assignmentA.durationHours),
                            Minutes: Math.ceil(assignmentA.durationMinutes),
                            StaffId: assignmentA.staffId,
                            ProductiveId: assignmentA.productiveId,
                            CountTypeId: assignmentA.countTypeId,
                            ShiftCode: assignmentA.shiftCode === '???' ? null : assignmentA.shiftCode,
                            Cancel: assignmentA.isCancelled,
                            CancelReasonId: assignmentA.cancelReasonId === -1 ? null : assignmentA.cancelReasonId,
                            CancelCommentText: assignmentA.cancelReason,
                            UnscheduledAbsence: assignmentA.unscheduledAbsence,
                            UnscheduledAbsenceOption: assignmentA.unscheduledAbsenceOption,
                            FloatUserModified: assignmentA.defaultIsFloat !== assignmentA.isFloat ? true : false,
                            AssociationType: assignmentA.associationType,
                            OrigDeptId: assignmentA.origDepartmentId
                        },
                        {
                            DepartmentId: assignmentB.departmentId,
                            SkillLevelId: assignmentB.skillLevelId,
                            Date: moment(assignmentB.date).format(this.shortDateTimeFormat),
                            DepartmentShiftId: assignmentB.departmentShiftId === 0 ? null : assignmentB.departmentShiftId,
                            Charge: assignmentB.charge,
                            Float: assignmentB.isFloat,
                            Comment: assignmentB.note == null ? "": assignmentB.note,
                            StartTime: moment(assignmentB.startTime).format(this.shortDateTimeFormat),
                            EndTime: moment(assignmentB.endTime).format(this.shortDateTimeFormat),
                            Hours: Math.ceil(assignmentB.durationHours),
                            Minutes: Math.ceil(assignmentB.durationMinutes),
                            StaffId: assignmentB.staffId,
                            ProductiveId: assignmentB.productiveId,
                            CountTypeId: assignmentB.countTypeId,
                            ShiftCode: assignmentB.shiftCode === '???' ? null : assignmentB.shiftCode,
                            Cancel: assignmentB.isCancelled,
                            CancelReasonId: assignmentB.cancelReasonId === -1 ? null : assignmentB.cancelReasonId,
                            CancelCommentText: assignmentB.cancelReason,
                            UnscheduledAbsence: assignmentB.unscheduledAbsence,
                            UnscheduledAbsenceOption: assignmentB.unscheduledAbsenceOption,
                            FloatUserModified: assignmentB.defaultIsFloat !== assignmentB.isFloat ? true : false,
                            AssociationType: assignmentB.associationType,
                            OrigDeptId: assignmentB.origDepartmentId
                        }
                    ]
                }
            };
    
            this.showSavingLoader();
            await this.$store
                    .dispatch("assignments/splitAssignment", assignments)
                    .then(() => {
                        this.hideSavingLoader();
                        this.editCancelOnSuccess(this.splitAssignmentResult, "Assignment split successfully.", "Split");
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_ASSIGNMENT_SPLIT', properties:
                            JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideSavingLoader();
                            var response = err.response.data;
                            var errorData = {
                                message: response,
                                flag: "error"
                            }
                            this.emitter.emit('showToasterNotificationActionable', errorData);
                            console.error(err); // Handle errors any way you want
                        }
                    });
        }
    
        createFormOnExit(){
            this.emitter.emit('unloadAssignment');
        } 
    
        createFormOnReview(){
            if(this.isCreateValidationSuccess){
                this.modifiedCreateAssignment.title = 'Create Assignment';
                this.modifiedCreateAssignment.showTitle = true;
                this.modifiedCreateAssignment.showNote = true;
    
                this.showAssignmentCreateOriginal = true;
                this.isCreateStep = false;
                this.isCreateReviewStep = true;
                }
        } 
        
        createFormOnEdit(){
            this.isCreateStep = true;
            this.isCreateReviewStep = false;
            this.showCreate = true;
            this.showAssignmentCreateOriginal = false;
        } 
        
        async createFormOnSave(){
            var data: any = JSON.parse(JSON.stringify(this.modifiedCreateAssignment));
    
            var assignmentData: any = {
                AssignId: data.assignId,
                DepartmentId: data.departmentId,
                SkillLevelID: data.skillLevelId,
                Date: data.date,
                DepartmentShiftId: data.departmentShiftId,
                Charge: data.charge,
                IsFloat: data.isFloat,
                Note: data.note,
                StartTime: data.startTime,
                EndTime: data.endTime,
                DurationHours: data.durationHours,
                DurationMinutes: data.durationMinutes,
                StaffId: data.staffId,
                floatChangedByUser: data.floatChangedByUser,
                AssociationType: data.associationType,
                ProductiveId: data.productiveId
            };
            this.appInsightEventData.Skill = data.skillLevelDescription;
            this.showSavingLoader();
            await this.$store
                    .dispatch("assignments/createAssignment", assignmentData)
                    .then(() => {
                        this.hideSavingLoader();
                        this.editCancelOnSuccess(this.createAssignmentResult, "Assignment created successfully.", "Create");
                        
                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_ASSIGNMENT_NEW', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });
                        
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideSavingLoader();
                            var response = err.response.data;
                            var errorData = {
                                message: response,
                                flag: "error"
                            }
                            this.emitter.emit('showToasterNotificationActionable', errorData);
                            console.error(err); // Handle errors any way you want
                        }
                    });           
        }
    
        updateValidationFlag(data: any){
            if (data.eventType.toLowerCase() == "editAssignment".toLowerCase()){
                this.isEditValidationSuccess = data.flag;
            }
            else if (data.eventType.toLowerCase() == "splitAAssignment".toLowerCase()){ 
                this.isSplitAssignAValidationSuccess = data.flag;
            }
            else if (data.eventType.toLowerCase() == "splitBAssignment".toLowerCase()){
                this.isSplitAssignBValidationSuccess = data.flag;
            }
            else if (data.eventType.toLowerCase() == "createAssignment".toLowerCase()){ 
                this.isCreateValidationSuccess = data.flag;
            }
        }
    
        loadCareAssignmentSheetFromAssignment(headerInfo: any) {
            this.payloadCareAssignment = {
                headerInfo: headerInfo,
                assignment: this.careAssignmentData.assignmentHomeDept,
                dataSource: this.careAssignmentData.homeGridData,
                departmentId: this.careAssignmentData.departmentId
            };
            this.isShowCareAssignmentSheetPopup = true;
        }
    
        closeCareAssignmentSheetPopup() {
            this.isShowCareAssignmentSheetPopup = false;
        }
    
        homeTabExportToExcelFromAssignment(headerInfo: any) {
            this.payloadExportToExcel = {
                headerInfo: headerInfo,
                skillLevelDescription: this.careAssignmentData.skillLevelDescription,
                dataSource: this.careAssignmentData.homeGridData,
                departmentId: this.careAssignmentData.departmentId,
                tabName : 'home'
            };
            this.isShowExportToExcel = true;
        }
    
        closeHomeTabExportToExcel() {
            this.isShowExportToExcel = false;
        }
    }
    
    </script>
    <style scoped>
    
    #Actionable .content {
        height: calc(100vh - 300px) !important;
    }
    #grdHome{
        height: calc(100vh - 380px) !important;
    }
    .assignment-notes-container {
        overflow: auto;
        max-height: 300px;
    }
    .card-title {
        margin-bottom: 0.5rem !important;
    }
    
    .timerRequired{
        color: #DF2500;
        display: inline-block;
        position: absolute;
        top: 0.1875rem;
        left: 8.5rem;
    }
    
    .requiredTimerTxt{
        color: #DF2500 !important;
        display: block;
    }
    
    .row-padding{
        padding-right: 18px;
    }
    
    </style>