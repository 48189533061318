<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">PLUS Metrics</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody">
                <div id="facilityAndDept" class="paddingBottom-10 mx-4">{{facilityName}} > {{departmentName}}</div>
                <div class="mb-2">
                    <neu-tablist :value="selectedTab" class="mx-4 tabList">
                        <neu-tab
                            white
                            tab="payperiod-tab"
                                @click="showPayPeriodTab">
                            <div>Pay Period </div>
                        </neu-tab>
                        <neu-tab
                            white
                            tab="entiremonth-tab" payperiod-tab entiremonth-tab
                                @click="showEntireMonthTab">
                            <div>Entire Month </div>
                        </neu-tab>
                    </neu-tablist>
                </div>
                <div id="payperiod-details" class="panelTabData scrollBar" v-if="isPayPeriodVisible" role="tabpanel" aria-labelledby="contract-labor-home-tab">
                    <div id="period-content" class="mt-3">
                        <neu-card color="plain-0" class="p-0 m-0">
                            <neu-table v-if="PlusMetricsPayPeriod != null && PlusMetricsPayPeriod.length > 0">
                                <neu-table-row :columns="columnHeaders" header>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column">
                                {{ column }}
                                </neu-table-heading>
                                </neu-table-row>
                                <neu-table-body align="left">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row :columns="columnHeaders" v-for="(payPeriod, index) in PlusMetricsPayPeriod" :key="payPeriod" :slot="column">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Date" :class="[index == (PlusMetricsPayPeriod.length - 1) ? 'digits': '']">{{ payPeriod.date != 'Total' ? getFormattedDate(payPeriod.date) : payPeriod.date }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Target" :class="[index == (PlusMetricsPayPeriod.length - 1) ? 'digits': '']">{{ payPeriod.targetHours }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Actual" :class="[index == (PlusMetricsPayPeriod.length - 1) ? 'digits': '']">{{ payPeriod.actualHours }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Difference" :class="[payPeriod.varianceHours < 0 ? 'red': '',
                                                                         index == (PlusMetricsPayPeriod.length - 1) ? 'digits': '']">
                                        {{getVarianceHours(payPeriod.varianceHours)}}
                                    </neu-label>
                                </neu-table-row>
                                </neu-table-body>
                            </neu-table>
                        </neu-card>
                    </div>
                    <div id="period-message" class="text-center mt-3" v-if="isPeriodMessageVisible">
                        <p id="period-message-text">{{periodMessage}}</p>
                    </div>
                </div>
                <div id="entiremonth-details" class="panelTabData scrollBar" v-if="isEntireMonthVisible" role="tabpanel" aria-labelledby="contract-labor-profile-tab">
                    <div id="month-content" class="mt-3">
                        <neu-card color="plain-0" class="p-0 m-0">
                            <neu-table v-if="PlusMetricsMonth != null && PlusMetricsMonth.length > 0">
                                <neu-table-row :columns="columnHeaders" header>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column">
                                {{ column }}
                                </neu-table-heading>
                                </neu-table-row>
                                <neu-table-body align="left">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row :columns="columnHeaders" v-for="(payPeriod, index) in PlusMetricsMonth" :key="payPeriod" :slot="column">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Date" :class="[index == (PlusMetricsMonth.length - 1) ? 'digits': '']">{{ payPeriod.date != 'Total' ? getFormattedDate(payPeriod.date) : payPeriod.date }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Target" :class="[index == (PlusMetricsMonth.length - 1) ? 'digits': '']">{{ payPeriod.targetHours }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Actual" :class="[index == (PlusMetricsMonth.length - 1) ? 'digits': '']">{{ payPeriod.actualHours }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Difference" :class="[payPeriod.varianceHours < 0 ? 'red': '',
                                                                         index == (PlusMetricsMonth.length - 1) ? 'digits': '']">
                                        {{getVarianceHours(payPeriod.varianceHours)}}
                                    </neu-label>
                                </neu-table-row>
                                </neu-table-body>
                            </neu-table>
                        </neu-card>
                    </div>
                    <div id="month-message" class="text-center mt-3" v-if="isMonthMessageVisible">
                        <p id="month-message-text">{{monthMessage}}</p>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import moment from "moment";

//Import Componenets
import {
    NeuTab,
    NeuTablist,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard 
} from "@neutron/vue";

class Props {
    plusMetrics!: any 
}

@Options({
  components: {
    NeuTab,
    NeuTablist,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard 
  }
})  
export default class PlusMetricsListPopup extends Vue.with(Props) {

    //Data properties
    facilityName: string = '';
    departmentName: string = '';
    selectedTab: string = 'payperiod-tab';
    isPayPeriodVisible: boolean = true;
    isEntireMonthVisible: boolean = false;
    columnHeaders: any = { Date: "25%", Target: "25%", Actual: "25%", Difference: "25%"};
    columnIcons: any = {Date: "none", Target: "none", Actual: "none", Difference: "none"};
    PlusMetricsMonth: any = null;
    PlusMetricsPayPeriod: any = null;
    isPeriodMessageVisible: boolean = false;
    isMonthMessageVisible: boolean = false;
    periodMessage: string = '';
    monthMessage: string = '';

    //Lifecycle hooks
    mounted(){
        this.loadPlusMetricsList();
    }

    //Methods
    loadPlusMetricsList(){
        if(this.plusMetrics != null){
            if (this.plusMetrics.plusMetricsPayPeriod.length > 0) {
                this.PlusMetricsPayPeriod = this.plusMetrics.plusMetricsPayPeriod;
                var lastIndex= this.PlusMetricsPayPeriod.length - 1;
                this.PlusMetricsPayPeriod[lastIndex].date = 'Total';
            }
            else{
                this.isPeriodMessageVisible = true;
                this.periodMessage = 'No Pay Period PLUS data is available for these dates.';
            }

            if (this.plusMetrics.plusMetricsMonth.length > 0){
                this.PlusMetricsMonth = this.plusMetrics.plusMetricsMonth;
                var lastIndex= this.PlusMetricsMonth.length - 1;
                this.PlusMetricsMonth[lastIndex].date = 'Total';
            }
            else{
                this.isMonthMessageVisible = true;
                this.monthMessage = 'No Entire Month PLUS data is available for these dates.';
            }

            this.facilityName = this.plusMetrics.facilityName;
            this.departmentName = this.plusMetrics.departmentName;
        }
    }
    close(){
        this.$emit("close-modal");
    }
    showPayPeriodTab(){
        this.setSelectedTab('payperiod-tab');
    }
    showEntireMonthTab(){
        this.setSelectedTab('entiremonth-tab');
    }
    setSelectedTab(tab: string){
        this.selectedTab = tab;
        this.isPayPeriodVisible = false;
        this.isEntireMonthVisible = false;

        switch (this.selectedTab) {
            case 'payperiod-tab':
                this.isPayPeriodVisible = true;
                break;
            case 'entiremonth-tab':
                this.isEntireMonthVisible = true;
                break;
        }
    }

    getVarianceHours(varianceHours: any){
        var hoursTxt: String = '';
        if(varianceHours < 0){
            hoursTxt = '(' + (-1 * varianceHours).toFixed(2) + ')';
        }else{
            hoursTxt = varianceHours;
        }

        return hoursTxt;
    }

    getFormattedDate(date: any){
        var formattedDate: string = '';
        formattedDate = moment(date).format('MM/DD');
        return formattedDate;
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 50%;
    height: 65%;
    max-width: 50%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    padding: 5px 12px;
    height:85%;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}

.red {
    color: red;
}

.paddingBottom-10{
    padding-bottom: 10px;
}

#facilityAndDept{
    text-align: left;
}
</style>