<template>
    <div id="AddContractLabor" class="ms-2 mt-2 txtLeft" style="overflow:hidden;">
        <div class="row">
            <div><span id="spnMessage"></span></div>
        </div>
        <div class="row">
            <div class="col-12">
                <form id="contractlaborrequest-form">
                    <div id="contract-labor-heading" class="row">
                        <div class="form-group col-2">
                            <label>DATE</label>
                        </div>
                        <div class="form-group col-2">
                            <label class="text-uppercase">shift</label>
                        </div>
                        <div class="form-group col-2">
                            <label class="text-uppercase">skill</label>
                        </div>
                        <div class="form-group col-2">
                            <label class="text-uppercase">skill & Specialization</label>
                        
                        </div>
                        <div class="form-group col-3">
                            <label>NOTE(OPTIONAL)</label>
                        </div>
                    </div>
                    <div id="view" v-if="contractRequestControls != null && contractRequestControls.length > 0">
                        <div class="row" v-for="dataItem in contractRequestControls" :key="dataItem.Id">
                            <div class="form-group col-2">
                                <Datepicker v-model="dataItem.RequestDate" inputClassName="dp-custom-input" weekStart="0" class="form-control requestDate"
                                    :enableTimePicker="false" autoApply ></Datepicker>
                            </div>
                            <div class="form-group col-2">
                                <select class="neu-select departmentShifts" 
                                    data-role="dropdownlist"
                                    :class="[dataItem.IsShiftRequired || dataItem.IsShowShiftValidationErrorMsg ? 'hasError': '']"
                                    v-model="dataItem.SelectedShift" >
                                <option v-for="shift in departmentShiftsList" :value="shift.deptShiftId" :key="shift.deptShiftId">
                                    {{shift.deptShiftDescription}}
                                </option>    
                                </select>
                                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                <span v-if="dataItem.IsShiftRequired || dataItem.IsShowShiftValidationErrorMsg" class="requiredControl material-icons" aria-hidden="true">error</span>
                                <span v-if="dataItem.IsShiftRequired" class="requiredTxt">Required</span> 
                                <span v-if="dataItem.IsShowShiftValidationErrorMsg" class="shift-validation-error-messages validation-error requiredTxt">{{dataItem.ShiftValidationErrorMsg}}</span>
                            </div>
                            <div class="form-group col-2">
                                <select class="neu-select fsskills"  
                                    data-role="dropdownlist"
                                    :class="[dataItem.IsSkillRequired ? 'hasError': '']"
                                    v-model="dataItem.SelectedSkill" >
                                <option v-for="skill in skills" :value="skill.skillLevelId" :key="skill.skillLevelId">
                                    {{skill.description}}
                                </option>    
                                </select>
                                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                <span v-if="dataItem.IsSkillRequired" class="requiredControl material-icons" aria-hidden="true">error</span>
                                <span v-if="dataItem.IsSkillRequired" class="requiredTxt">Required</span> 
                            </div>
                            <div class="form-group col-2">
                                <select class="neu-select fsskills"  
                                    data-role="dropdownlist"
                                    :class="[dataItem.IsSkillSpecializationRequired ? 'hasError': '']"
                                    v-model="dataItem.SelectedSkillSpecialization" >
                                <option v-for="skillSpec in skillSpecializations" :value="skillSpec.wfFoldergroupID" :key="skillSpec.wfFoldergroupID">
                                    {{skillSpec.wfFolderGroupName}}
                                </option>    
                                </select>
                                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                                <span v-if="dataItem.IsSkillSpecializationRequired" class="requiredControl material-icons" aria-hidden="true">error</span>
                                <span v-if="dataItem.IsSkillSpecializationRequired" class="requiredTxt">Required</span> 
                                <div  v-if="dataItem.IsSkillSpecializationExists" id="divSkillsErrorMessage"
                                     class="font-small text-start" style="display: block;">
                                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" size="sm" class="mr-1" />
                                    <span>
                                        No specialization available for this department.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group col-3">
                                <neu-input name="note" v-model="dataItem.note" placeholder="Request specific contractor by name here..."></neu-input>
                            </div>
                            <div class="form-group col-1 pl-0">
                                <button type="button" class="btn btn-link copy-request-btn p-0" 
                                    :disabled="dataItem.IsDisableCopyBtn"
                                    @click="copyContractLaborRequestControls(dataItem.Id)">
                                    <font-awesome-icon :icon="['far', 'copy']" size="sm" class="mr-2" /> Copy
                                </button>
                                <button type="button" class="btn btn-link remove-request-btn p-0" 
                                    :disabled="dataItem.IsDisableRemoveBtn"
                                    @click="removeContractLaborRequestControls(dataItem.Id)">
                                    <font-awesome-icon :icon="['far', 'trash-alt']" size="sm" class="mr-2" /> Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row actionable-new-create-controls-container">
            <div class="col">
                <button id="btnAddNew" class="btn btn-link iconStyle" 
                    :disabled="isDisableAddButton" style="text-decoration: none;" href="#"
                    @click="onAddNewRequest">
                    <font-awesome-icon :icon="['fas', 'plus']" size="sm" class="mr-2" />NEW
                </button>  
                <neu-button type="button" fill="raised" id="createrequest" class="float-end me-1"
                        :disabled="isDisableCreateRequest" @click="isDisableCreateRequest ? null : onCreateContractLaborRequests()">
                    REQUEST
                </neu-button>
            </div>
        </div>
        <hr class="lineStyle" />
        <div v-if="isContractLaborNeedAutomationEnabled">
            <div class="row mb-4">
                <div id="noContentMsg" v-if="isShowNoContentMsg" class="col-md-9 contract-text display">
                    
                </div>
                <div class="col"><a id="manage-contract-labor-lnk" class="float-end me-1" :href="contractLaborLink" target="_blank">Manage Contract Labor</a></div>
            </div>
            <div class="vertical-center">
                <div class="row">
                    <div class="col-md-3 fa-xl" style="text-align: right ">
                        <font-awesome-icon :icon="['fas', 'warning']" size="2xl" style="color:red;" />
                    </div>
                    <div class="col-md-9" style="text-align: left">
                        <span>Automation has been enabled for this Department. Manual submissions are not allowed at this time.</span>
                        <br/>
                        <span>Use the WF Automation Status Tool by <span class="clickSpan" @click="showWorkforceRequestStatusPopup">Clicking here</span> to see Automation activity for this department.</span>  
                    </div>       
                </div>
            </div>
        </div>
        <div v-else>
            <div>
                <div id="contract-labor-grid-content-loading" v-if="isShowContractLaborGridLoading" style="text-align: center; display:none">
                    <div class="fa-2x">
                        <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                    </div>
                    <p><b>Loading...</b></p>
                </div>
            </div> 
            <div class="row mb-4">
                <div id="noContentMsg" v-if="isShowNoContentMsg" class="col-md-9 contract-text display">
                    <span class="newIcon">There are no pending contract labor requests for your department at this time.</span>
                </div>
                <div class="col"><a id="manage-contract-labor-lnk" class="float-end me-1" :href="contractLaborLink" target="_blank">Manage Contract Labor</a></div>
            </div>
            <div id="gridContent" class="display" v-if="isShowContractLaborGrid">
                <div class="row">
                    <div class="col-md-10 mb-2 contract-header"><span class="newIcon">Pending Requests</span></div>
                </div>
                <div class="row gridSpace">
                    <div class="active col-md-12 grid-table" id="pendingReqGrid">
                        <neu-card color="plain-0" class="p-0 m-0" v-if="dsContractReqGrid != null && dsContractReqGrid.length > 0">
                            <neu-table>
                                <neu-table-row :columns="columnHeaders" size="small" header>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column" style="padding-top: 25px !important;">
                                    {{ column }}
                                </neu-table-heading>
                                </neu-table-row>
                                <neu-table-body align="left">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-table-row :columns="columnHeaders" v-for="dataItem in dsContractReqGridList" :slot="column" 
                                                        :key="dataItem" size="small" >            
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Date">
                                                {{ dataItem.Date }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Shift" :title="dataItem.Shift">
                                                {{ dataItem.Shift }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="FS Skill" :title="dataItem.FsSkillLevelDescription">
                                                {{ dataItem.FsSkillLevelDescription }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="WF Specialization" :title="dataItem.WfSkill">
                                                {{ dataItem.WfSkill }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Requested" :title="dataItem.Requested">
                                                {{ dataItem.Requested }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Status" :title="dataItem.Status">
                                                {{ dataItem.Status }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Name" :title="dataItem.ResourceName">
                                                {{ dataItem.ResourceName }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Notes" :title="dataItem.Notes">
                                                {{ dataItem.Notes }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Actions">
                                                <neu-button type="button" fill="raised" 
                                                        @click="cancelContractLaborRequests(dataItem)">
                                                    CANCEL
                                                </neu-button>
                                            </neu-label>
                                        </neu-table-row>
                                </neu-table-body>
                            </neu-table>
                        </neu-card>
                        <neu-container fluid="" v-if="dsContractReqGrid != null && dsContractReqGrid.length > 0">
                        <neu-row>
                            <neu-col md="6" cols="12" class="neu-text-center neu-text-md-left">
                                <!-- eslint-disable-next-line vue/no-parsing-error  -->
                                <neu-paginator class="justify-content-start" @v-neu-change="(ev: CustomEvent) => this.pageNum = ev.detail.pageNumber"
                                    :total="dsContractReqGrid != null && dsContractReqGrid.length"
                                    :rowsPerPage="rowsPerPage"
                                    >
                                </neu-paginator>
                            </neu-col>
                            <neu-col md="6" cols="12" class="neu-text-center neu-text-md-right">
                                <neu-table-row-count
                                    class="mt-3"
                                    section="number"
                                    :total="dsContractReqGrid != null && dsContractReqGrid.length"
                                    :page-number="pageNum"
                                    @v-neu-change="rowCountChange"
                                >
                                </neu-table-row-count>
                                
                            </neu-col>
                        </neu-row>
                    </neu-container>
                    </div>
                </div>
            </div>
            <div id="contract-labor-content-loading" v-if="isShowContractLaborContentLoading" class="loadingDiv1" style="padding: 5px;">
                <div style="text-align: center">
                    <div class="fa-2x">
                        <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                    </div>
                    <p><b>Processing...</b></p>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import models and enum
import {ContractLaborRequest} from '@/models/actionable/index';
import { Profile } from '@/models/profile/index';

//Import Componenets
//@ts-ignore
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {
    NeuButton,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuInput,
    NeuContainer,
    NeuRow,
    NeuCol,
    NeuPaginator
} from "@neutron/vue";

class Props {
    contractLaborTabInput!: any 
}

@Options({
  computed: {
    ...mapState('actionable', ['departmentShifts', 'contractLaborRequests', 'cancelledRequest', 'createdcontractLaborRequest', 'deptCLNeedAutomationStatus']),
    ...mapState('shared', ['departmentSkillList', 'skillSpecialization']),
    ...mapState('profile', ['profileData']),
    dsContractReqGridList() {
      if (this.dsContractReqGrid && this.dsContractReqGrid.length === 0)
        return [];
      else return this.dsContractReqGrid.slice(this.first - 1, this.last);
    },
  },
  components: {
    NeuButton,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    Datepicker,
    NeuInput,
    NeuContainer,
    NeuRow,
    NeuCol,
    NeuPaginator
  }
}) 
export default class ContractLabor extends Vue.with(Props) {
    //Data properties
    departmentShifts!: any;
    departmentSkillList!: any;
    skillSpecialization!: any;
    contractLaborRequests!: any;
    cancelledRequest!: any;
    createdcontractLaborRequest!: any;
    profileData!: Profile;

    dsContractReqGrid: any = [];
    contractRequestControls: ContractLaborRequest[] = [];
    departmentShiftsList: any = [];
    skills: any = [];
    skillSpecializations: any = [];
    contractLaborRequest: any = [];
    contractLaborLink: string = ""

    isShowContractLaborGridLoading: boolean = false; 
    isShowNoContentMsg: boolean = false;  
    isShowContractLaborGrid: boolean = false; 
    isShowContractLaborContentLoading: boolean = false;
    contractLaborCtrlUId: number = 0;
    isDisableAddButton: boolean = false; 
    isDisableCreateRequest: boolean = false;
    isContractLaborNeedAutomationEnabled: boolean = false;
    deptCLNeedAutomationStatus!: any;
    workforceRequestInput: any;

    pageNum: number = 1;
    rowsPerPage: number = 10;
    first: number = 1;
    last: number = 10;

    columnHeaders: any ={ 
        Date: "10%",
        Shift: "17%",
        "FS Skill": "7%",
        "WF Specialization": "15%",
        Requested: "15%",
        Status: "6%",
        Name: "9%",
        Notes: "9%",
        Actions: "12%",
    };
    columnIcons: any = {  
        Date: "none",
        Shift: "none",
        "FS Skill": "none",
        "WF Specialization": "none",
        Requested: "none",
        Status: "none",
        Name: "none",
        Notes: "none",
        Actions: "none"
    };
    model: any = {
        container: "",
        htmlFilePath: " /App/Dashboard/ContractLabor/contractLaborTab.html",
        errorMessage: "An error has occurred while loading the page.We apologize for any inconvenience this has caused.Please try refreshing the page again, and if the error persists, please contact Facility Scheduler support.",
        successRequestMessage: "Your request has been submitted.",
        cancelRequestMessage: "Your cancel request has been submitted.",
        cancelRequestFailMessage: "Unable to process your cancellation request. ",
        cancelRequestSuccessMessage: "Your cancellation request has been submitted.",
        createRequestsFailMessage: "Unable to create contract labor request(s).",
        creatRequestTaskCancelledMessage: "Your request has timed out. Please try refreshing the page again, and if the error persists, please contact Facility Scheduler support. ",
        fetchingContractLaborErrorMessage: 'Unable to retrieve contract labor requests. Please try refreshing the page again, and if the error persists, please contact Facility Scheduler support.',
        pastShiftsValidationErrorMessage: 'Shift has already started. Please a select a valid future shift',
        errorSelection: 'No content',
        createRequestsSuccessMessage: "",
        productiveShift: 1,
        successResponseCode: 200,
        createdResponseCode: 201,
        administrativeCancel: 5,
        pendingdStatus: 101,
        failedStatus: 5,
        maxNumberOfRequest: 10,
        shortDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
        submittedStatus: 4,
        dateValidationFormat:'MM-DD-YYYY',
        badRequestResponseCode:400
    };

    //Lifecycle hooks
    created(){
        this.emitter.on("refreshContractLabor", this.loadContractLabor);
    }

    async mounted(){
        await this.loadContractLabor();
        await this.loadDeptCLNeedAutomationStatus(this.contractLaborTabInput.DataSet.DepartmentId);
    }

    unmounted(){
        this.emitter.off("refreshContractLabor", this.loadContractLabor);
    }
    //Methods
    async loadContractLabor(){
        this.contractLaborLink = this.profileData.AppUrl + 'Requests/ContractLaborRequest.aspx';
        await this.loadInitialState();
        this.loadInitialContractLaborControl();
        await this.loadContractLaborRequests();
    }

    async loadInitialState(){
        this.contractRequestControls = [];
        this.departmentShiftsList = [];
        this.skills = [];
        this.skillSpecializations = [];

        var today: any = moment().format('DD-MMM-YYYY');

        await this.loadDepartmentShifts(this.contractLaborTabInput.DataSet.DepartmentId, today);
        await this.loadDepartmentSkills(this.contractLaborTabInput.DataSet.DepartmentId, today);
        await this.loadSkillSpecializations(this.contractLaborTabInput.DataSet.FacilityCoid, this.contractLaborTabInput.DataSet.DepartmentId);
    }

    loadInitialContractLaborControl(){
        var isDisbaleBtn: boolean = true;

        if(this.skillSpecialization != null && this.skillSpecialization.length > 0){
            isDisbaleBtn = false;
        }

        this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;
        var controlLbrCtrl: ContractLaborRequest = {
            Id: this.contractLaborCtrlUId,
            RequestDate: moment(this.contractLaborTabInput.DataSet.StartDateTime).format('MM/DD/YYYY'),
            SelectedShift: -1,
            SelectedSkill: -1,
            SelectedSkillSpecialization: 0,
            IsRequestedDateRequired: false,
            IsShiftRequired: false,
            IsSkillRequired: false,
            IsSkillSpecializationRequired: false,
            IsShowShiftValidationErrorMsg: false,
            IsSkillSpecializationExists: isDisbaleBtn,
            ShiftValidationErrorMsg: null,
            IsDisableCopyBtn: isDisbaleBtn,
            IsDisableRemoveBtn: isDisbaleBtn,
            note: null
        };

        this.contractRequestControls.push(controlLbrCtrl);
    }


    async loadDeptCLNeedAutomationStatus(deptId: any){
        var inputData = {
            departmentId: deptId
        }
        await this.$store
                .dispatch("actionable/getDepartmentContractLaborNeedAutomationStatus", inputData)
                .then(() => {
                    if (this.deptCLNeedAutomationStatus != null) {
                         this.isContractLaborNeedAutomationEnabled = this.deptCLNeedAutomationStatus;   
                         if(this.deptCLNeedAutomationStatus) {
                            this.disableAddCreateBtn(true);
                            this.disableAllCopyRemoveBtns(true);
                         }
                    }                    
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Contract Labor Automation Status.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationActionable', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async loadDepartmentShifts(deptId: any, date: any){
        var inputData = {
            departmentId: deptId,
            date: moment(date).format('YYYY-MM-DD')
        }
        await this.$store
                .dispatch("actionable/getDepartmentShifts", inputData)
                .then(() => {
                    if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                        var productiveShifts = this.filterShiftsByType();
                        this.departmentShiftsList = productiveShifts;                   
                    }

                    this.departmentShiftsList.unshift({
                        deptShiftId: -1,
                        deptShiftDescription: 'Select Shift'
                    });
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationActionable', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    filterShiftsByType() {
        //@ts-ignore
        var filteredShifts: any = jslinq(this.departmentShifts)
                            .where((shift: any) => { 
                                if(shift.productiveId === this.model.productiveShift){ 
                                    return shift;
                                }})
                            .toList();

        filteredShifts.forEach((item: any) =>{
            item.shiftText = item.code.trim() + ' ' + moment(item.startTime).format('h:mm A') + ' to ' + moment(item.endTime).format('h:mm A');
        });
        
        return filteredShifts;
    }

    async loadDepartmentSkills(departmentId: any, date: any) {
        var input = {
            deptId: departmentId,
            date: date,
            tenantId: this.profileData.TenantId
        }

        await this.$store
            .dispatch("shared/getDepartmentSkills", input)
            .then(() => {
                if (this.departmentSkillList != null && this.departmentSkillList.length > 0) {
                    var skills: any = this.sortBy(this.departmentSkillList, 'description', 'asc');
                    this.skills = skills;         
                }

                this.skills.unshift({
                        skillLevelId: -1,
                        description: 'Select Skill'
                    });
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving Department Skills.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });       
    }

    async loadSkillSpecializations(FacilityCoid: any, DepartmentId: any){
        var inputData = {
            FacilityCoid: FacilityCoid,
            DepartmentId: DepartmentId
        }
        await this.$store
                .dispatch("shared/getSkillSpecializations", inputData)
                .then(() => {
                    if (this.skillSpecialization != null && this.skillSpecialization.length > 0) {
                        var flags = [];
                        var distinctSkillSpecializations = [];
                        for (var index = 0; index < this.skillSpecialization.length; ++index) {
                            if (!flags[this.skillSpecialization[index].wfFoldergroupID]) {
                                flags[this.skillSpecialization[index].wfFoldergroupID] = true;
                                distinctSkillSpecializations.push(this.skillSpecialization[index]);
                            }
                        }
                        this.skillSpecializations = distinctSkillSpecializations; 
                        this.disableAllCopyRemoveBtns(false);
                        this.disableAddCreateBtn(false);                       
                    }
                    else{
                        this.disableAllCopyRemoveBtns(true);
                        this.disableAddCreateBtn(true);
                    }

                    this.skillSpecializations.unshift({
                        wfFoldergroupID: 0,
                        wfFolderGroupName: 'Select Skill Specialization'
                    });
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationActionable', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }
    sortBy(array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
           if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    disableAllCopyRemoveBtns(isdisable: boolean){
        if(this.contractRequestControls != null &&  this.contractRequestControls.length > 0){
            this.contractRequestControls.forEach((item: any) => {
                item.IsDisableCopyBtn = isdisable;
                item.IsDisableRemoveBtn = isdisable;
                item.IsSkillSpecializationExists = isdisable;
            })
        }
    }

    disableAddCreateBtn(isdisable: boolean){
        this.isDisableAddButton = isdisable;
        this.isDisableCreateRequest = isdisable;
    }

    async loadContractLaborRequests(){
        this.isShowContractLaborGridLoading = true;
        this.isShowNoContentMsg = false;
        this.isShowContractLaborGrid = false;

        var newRequestdate = new Date(this.contractLaborTabInput.DataSet.StartDateTime);
        newRequestdate.setHours(0, 0, 0, 0);

        var inputData: any = {
            FacilityId: this.contractLaborTabInput.DataSet.FacilityId,
            DepartmentId: this.contractLaborTabInput.DataSet.DepartmentId,
            StatusIds: this.model.pendingdStatus + "," + this.model.submittedStatus + "," + this.model.failedStatus,
            StartDate: moment(newRequestdate).format('YYYY-MM-DDTHH:mm:ss'),
            EndDate: moment(newRequestdate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
        }

        await this.$store
            .dispatch("actionable/getContractLaborRequests", inputData)
            .then(() => {
                if (this.contractLaborRequests != null && this.contractLaborRequests.length > 0) {
                    this.dsContractReqGrid = [];
                    for (var i = 0, len = this.contractLaborRequests.length; i < len; i++) {
                            var selectedItem = this.contractLaborRequests[i];
                            var shiftStartTime = moment(selectedItem.shiftStartTime).format('hh:mm A');
                            var shiftEndTime = moment(selectedItem.shiftEndTime).format('hh:mm A');
                            this.dsContractReqGrid.push({
                                "Date": moment(selectedItem.date).format('MM/DD/YYYY'),
                                "Shift": selectedItem.shiftCode + ' ' + shiftStartTime + ' ' + shiftEndTime,
                                "FsSkillLevelDescription": selectedItem.fsSkillLevelDescription,
                                "WfSkill": selectedItem.wfFolderGroupName,
                                "Status": selectedItem.wfStatusDescription,
                                "Requested": moment.utc(selectedItem.createDateUtc).local().format('MM/DD/YYYY h:mm A'),
                                "ResourceName": selectedItem.wfCandidateName,
                                "Notes": selectedItem.notes,
                                "Id": selectedItem.contractLaborShiftId
                            });
                        }
                    this.isShowNoContentMsg = false;
                    this.isShowContractLaborGrid = true;           
                }
                else{
                    this.isShowNoContentMsg = true;
                    this.isShowContractLaborGrid = false;
                }
                this.isShowContractLaborGridLoading = false;
                this.scrollToTop();
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborGridLoading = false;
                    var errorData = {
                        message: "An error occured while retrieving contract labor requests.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    console.error(err); // Handle errors any way you want
                }
            }); 
    }

    scrollToTop(){
        //@ts-ignore
        var actionableDiv: any = document.getElementById("actionable");
        actionableDiv.scrollTop = 250;
    }

    async cancelContractLaborRequests(contractLaborRequest: any){
        this.isShowContractLaborContentLoading = true;
        var input = {
            contractLaborRequestId: contractLaborRequest.Id,
            defaultCancelReasonId: { CancellationReasonId: this.model.administrativeCancel, Notes: '' }
        }

        await this.$store
            .dispatch("actionable/cancelContractLaborRequests", input)
            .then(() => {
                if (this.cancelledRequest != null) {
                    var successData = {
                        message: this.model.cancelRequestSuccessMessage,
                        flag: "success"
                    }
                    this.emitter.emit('showToasterNotificationActionable', successData); 
                    this.loadContractLaborRequests();       
                }
                else{
                    var errorData = {
                        message: this.model.cancelRequestFailMessage,
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                }
                this.isShowContractLaborContentLoading = false;
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborContentLoading = false;
                    var response = JSON.parse(err.request.response);
                    var errorData = {
                        message: this.model.cancelRequestFailMessage + response.Message,
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    copyContractLaborRequestControls(id: number){
        for (var i = 0, len = this.contractRequestControls.length; i < len; i++) {
            if (this.contractRequestControls[i].Id === id) {   
                this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;             
                var currentItem = this.contractRequestControls[i];
                var itemToCopy: ContractLaborRequest = {
                    Id: this.contractLaborCtrlUId,
                    RequestDate: currentItem.RequestDate,
                    SelectedShift: currentItem.SelectedShift,
                    SelectedSkill: currentItem.SelectedSkill,
                    SelectedSkillSpecialization: currentItem.SelectedSkillSpecialization,
                    IsRequestedDateRequired: false,
                    IsShiftRequired: false,
                    IsSkillRequired: false,
                    IsSkillSpecializationRequired: false,
                    IsShowShiftValidationErrorMsg: false,
                    IsSkillSpecializationExists: false,
                    ShiftValidationErrorMsg: null,
                    IsDisableCopyBtn: false,
                    IsDisableRemoveBtn: false,
                    note: null
                };

                this.contractRequestControls.splice(i + 1, 0, itemToCopy)
                if (this.contractRequestControls.length >= this.model.maxNumberOfRequest) {
                    this.disableAddCreateBtn(true);
                    this.disableAllCopyRemoveBtns(true);
                }

                break;
            }
        }
    }

    removeContractLaborRequestControls(id: number){
        this.disableAddCreateBtn(false);
        this.disableAllCopyRemoveBtns(false);

        if (this.contractRequestControls.length === 1) {
            return;
        }

        for (var i = 0, len = this.contractRequestControls.length; i < len; i++) {
            if (this.contractRequestControls[i].Id === id) {
                this.contractRequestControls.splice(i, 1);
                break;
            }
        }
    }

    onAddNewRequest(){
        this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;
        var controlLbrCtrl: ContractLaborRequest = {
            Id: this.contractLaborCtrlUId,
            RequestDate: moment(this.contractLaborTabInput.DataSet.StartDateTime).format('MM/DD/YYYY'),
            SelectedShift: -1,
            SelectedSkill: -1,
            SelectedSkillSpecialization: -1,
            IsRequestedDateRequired: false,
            IsShiftRequired: false,
            IsSkillRequired: false,
            IsSkillSpecializationRequired: false,
            IsShowShiftValidationErrorMsg: false,
            IsSkillSpecializationExists: false,
            ShiftValidationErrorMsg: null,
            IsDisableCopyBtn: false,
            IsDisableRemoveBtn: false,
            note: null
        };

        this.contractRequestControls.push(controlLbrCtrl);

        if (this.contractRequestControls.length >= this.model.maxNumberOfRequest) {
            this.disableAddCreateBtn(true);
            this.disableAllCopyRemoveBtns(true);
        }
    }

    async onCreateContractLaborRequests(){
        var requestContractModel = [];
        var formValid = true;

        for (var i = 0, len = this.contractRequestControls.length; i < len; i++) {
            var request = this.contractRequestControls[i];
            var requestDate = moment(request.RequestDate).format('MM/DD/YYYY');
            var isValid = this.validateNewRequestInfo(request);

            if (!isValid) {
                formValid = false;
            } else {
                requestContractModel.push({
                    "Date": requestDate,
                    "FolderGroupId": request.SelectedSkillSpecialization,
                    "Note": request.note,
                    "DepartmentShiftId": request.SelectedShift,
                    "fsSkillId": request.SelectedSkill
                });
            }
        }

        if (!formValid) {
            var errorData = {
                message: 'Please select missing information',
                flag: "error"
            }
            this.emitter.emit('showToasterNotificationActionable', errorData);
            return;
        }

        await this.createContractLaborRequests(requestContractModel);
    }

    validateNewRequestInfo(request: ContractLaborRequest): boolean{
        var isValid = true;

        if (request.SelectedShift == null || request.SelectedShift == -1) {
            isValid = false;
            request.IsShiftRequired = true;
        } else if (this.isShiftInvalid(request)) {
            request.ShiftValidationErrorMsg = this.model.pastShiftsValidationErrorMessage;
            isValid = false;
            request.IsShowShiftValidationErrorMsg = true;
        }
        else{
            request.IsShiftRequired = false;
            request.IsShowShiftValidationErrorMsg = false;
            request.ShiftValidationErrorMsg = null;
        }

        if (request.SelectedSkill == null || request.SelectedSkill == -1) {
            isValid = false;
            request.IsSkillRequired = true;
        }
        else{
            request.IsSkillRequired = false;
        }

        if (request.SelectedSkillSpecialization == null || request.SelectedSkillSpecialization == -1) {
            isValid = false;
            request.IsSkillSpecializationRequired = true;
        }
        else{
            request.IsSkillSpecializationRequired = false;
        }

        return isValid;
    }

    isShiftInvalid(request: ContractLaborRequest) {
        if (request != null) {
            var selectedShiftTime;
            //@ts-ignore
            var selectedShift: any = jslinq(this.departmentShiftsList)
                    .where((item: any) => { 
                        if(item.deptShiftId == request.SelectedShift){ 
                            return item;
                        }})
                    .toList();
            if(selectedShift != null && selectedShift.length > 0){
                selectedShiftTime = selectedShift[0].startTime;
            }
            var now = moment();
            var requestDate = moment(request.RequestDate).format('YYYY-MM-DD');
            var shiftStartTime = moment(selectedShiftTime).format('h:mm a');
            var formattedStartTime = moment(requestDate + ' ' + shiftStartTime, 'YYYY-MM-DD hh:mm A');
            return formattedStartTime.isSameOrBefore(now);
        }
    }

    async createContractLaborRequests(requestContractModel: any){
        this.isShowContractLaborContentLoading = true;
        var input = {
            FacilityCoId: this.contractLaborTabInput.DataSet.FacilityCoid,
            DepartmentId: this.contractLaborTabInput.DataSet.DepartmentId,
            ShiftRequestDetails: requestContractModel
        }

        await this.$store
            .dispatch("actionable/createContractLaborRequests", input)
            .then(() => {
                if (this.createdcontractLaborRequest != null &&
                    typeof this.createdcontractLaborRequest === "string" &&
                    this.createdcontractLaborRequest.indexOf("task was canceled") >= 0) {
                    this.contractRequestControls = [];
                    this.onAddNewRequest();
                    var errorData = {
                        message: this.model.creatRequestTaskCancelledMessage,
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    return;
                }
                else{
                    if(this.createdcontractLaborRequest != null){
                        //@ts-ignore
                        var errors: any = jslinq(this.createdcontractLaborRequest)
                            .where((request: any) => {
                                if(request.responseStatusCode !== this.model.createdResponseCode){
                                    return request;
                                }
                            })
                            .toList();

                        if (errors.length > 0) {
                            var errData:any;
                            if (errors[0].responseStatusCode == this.model.badRequestResponseCode) {
                                var message: string = errors[0].responseMessage;
                                  errData = {
                                    message: message,
                                    flag: "error"
                                }
                            }
                            else {
                                errData = {
                                    message: 'Error encountered processing your request',
                                    flag: "error"
                                }
                            }
                            this.emitter.emit('showToasterNotificationActionable', errData);
                        } else {
                            var successrData = {
                                message: 'Your request has been sent.',
                                flag: "success"
                            }
                            this.emitter.emit('showToasterNotificationActionable', successrData);
                        }
                    }
                }
                this.removeContractLaborControls();
                this.loadContractLaborRequests();
                this.isShowContractLaborContentLoading = false;
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborContentLoading = false;
                    var errorData = {
                        message: 'An error occured while creating contract labor request.',
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    removeContractLaborControls(){
        var failedRequestControls: any = [];

        if(this.createdcontractLaborRequest != null){
            //@ts-ignore
            var successRequests: any = jslinq(this.createdcontractLaborRequest)
                .where((request: any) => { 
                    if(request.ResponseStatusCode == this.model.createdResponseCode){ 
                        return request;
                    }})
                .toList();

            this.contractRequestControls.forEach((control: ContractLaborRequest) =>{
                var processedSuccessfully = false;
                for (var i = 0, len = successRequests.length; i < len; i++) {
                    var request = successRequests[i];
                    var requestDate = moment(request.Date);
                    var foundMatch = moment(control.RequestDate).isSame(requestDate, 'day')
                        && control.SelectedShift === request.FsDeptShiftID
                        && control.SelectedSkill === request.FsSkillLevelId
                        && control.SelectedSkillSpecialization === request.WfFolderGroupId;
                    if (foundMatch) {
                        successRequests.splice(i, 1);
                        processedSuccessfully = true;
                        break;
                    }
                }
                if (!processedSuccessfully) {
                    failedRequestControls.push(control);
                }
            });
        }
        
        if (failedRequestControls.length <= 0) {
            this.contractRequestControls = [];
            this.onAddNewRequest();
        } else {
            this.contractRequestControls = [];
            failedRequestControls.forEach((requestControl: any) => {
                this.contractRequestControls.push(requestControl);

                if (this.contractRequestControls.length >= this.model.maxNumberOfRequest) {
                    this.disableAddCreateBtn(true);
                    this.disableAllCopyRemoveBtns(true);
                }
            });
        }
    }

    rowCountChange(ev: CustomEvent) {
      if (ev.detail && ev.detail.first && ev.detail.last) {
        this.first = ev.detail.first;
        this.last = ev.detail.last;
      }
      if (ev.detail && ev.detail.value) this.rowsPerPage = ev.detail.value;
    }
    
    showWorkforceRequestStatusPopup(){      
        this.emitter.emit('loadWorkforceRequestStatusPopupWithSelectedFacAndDept', 
            this.contractLaborTabInput.DataSet);      
    }
}
</script>
<style scoped>
#contract-labor-heading .form-group {
    margin-bottom: 0 !important;
}

#contractlaborrequest-form .k-dropdown-wrap.is-invalid {
    border-color:red !important;
}

#contractlaborrequest-form div.validation-error {
    color: red;
    font-size: small
}

.font-small {
    font-size: 12px;
}

.requiredControl{
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 0.7rem;
    right: 37px;
    z-index: 2147483647;
}

.requiredTxt{
    color: #DF2500 !important;
    display: block;
}
.hasError{
    border: 2px solid #ce3637 !important;
}

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 0.5rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

#divSkillsErrorMessage{
    border-radius: 4px;
    border-color: #a3d0e4;
    color: #00435e;
    background-color: #d9ecf5;
    box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
}

.vertical-center {
    margin-top: 25px;
    font-size: 14px;
}

.clickSpan{
    text-decoration: underline;
    color:blue;
    cursor: pointer;
}

.me-1 {
    margin-right: 0.25rem!important;
}
.float-end {
    float: right!important;
}

.hydrated {
    visibility: inherit;
}

</style>