<template>
    <div class="modalBackdrop flex absolute z-max">
        <div class="c4c-modal">
            <header class="">
                <neu-row class="resourceAssignHeading">
                    <neu-col class="neu-text-left" md="9" cols="12"> 
                        <h5 class="pb-1">Resource Assignments</h5>
                    </neu-col>
                    <neu-col class="pr-6" md="3" cols="12">
                        <a href="#" class="closeBtn neu-tooltip" @click="close">
                            <i aria-hidden="true">
                                <font-awesome-icon :icon="['fas', 'close']"
                                                   size="lg" />
                            </i>
                            <span class="neu-tooltip--text">Close</span>
                        </a>
                    </neu-col>
                </neu-row>
                <div class="resourceAssignHeaderDetails shadow-sm rounded">
                    <neu-row>
                        <neu-col md="6" cols="12">
                            <h5 class="headerLH">
                                <span class="card-title"> ({{FacilityCoid}}){{FacilityName}}</span>
                            </h5>
                            <h6 class="headerLH">
                                <span class="text-muted">{{ClusterName}}</span> &gt; <span class="card-title txtWrap"> ({{DepartmentCode}}){{DepartmentName}} </span>
                            </h6>
                        </neu-col>
                        <neu-col md="6" cols="12">
                            <h6 class="headerLH">
                                <i aria-hidden="true">
                                    <font-awesome-icon :icon="['far', 'calendar']"
                                                       size="sm" />
                                </i>
                                <span class="font-weight-bold">{{StartDate}}</span>
                            </h6>
                            <h6 class="headerLH">
                                <i aria-hidden="true">
                                    <font-awesome-icon :icon="['far', 'clock']"
                                                       size="sm" />
                                </i>
                                <span class="font-weight-bold">{{StartTime}}</span> -<span class="font-weight-bold">{{EndTime}}</span>
                            </h6>
                        </neu-col>
                    </neu-row>
                </div>
            </header>
            <div id="actionable" class="panleBody scrollBar">
                <div id="actionable-assignment-header" v-if="isdeptSummaryHeaderVisible">
                    <DepartmentSummaryHeader :deptSummaryHeaderData="deptSummaryHeaderData" />
                </div>
                <div id="tList"  v-if="!isAssignmentSectionVisible">
                        <neu-row noGutters slot="beginning">

                            <a v-if="profileData.TrainingEnabled"
                            href="#"
                            class="modaltoolbarIcon neu-tooltip"
                            style="cursor: pointer; color:#3673AA; left:50px;  text-align: left; " @click="displayTrainingList($event)">
                            <span aria-hidden="true">
                                <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Learn How
                            </span>
                            <span class="neu-tooltip--text tooltipTxt">Click to view Help Videos</span>
                        </a>
                    </neu-row>
            
                    <div v-if="isTrainingVideoListVisible" @mouseleave="isTrainingVideoListVisible=false;" class="modalTutorialList z-max" ref="trainingActionableVideoList" id="trainingActionableVideoList" 
                    style="{ top: mousePosY+'px' }" :style="{ left: mousePosX+'px' , border:'solid', 'border-color':'#3673AA' }" >  
                                    <div style="background-color: rgb(202, 202, 202);">
                                        <neu-list  color="white" lines="none" style="background-color: white;" >
                                            <neu-item class="training-video-list">
                                            <neu-label  @click="openVideo('https://mediaconnect.app.medcity.net/embed?id=7b5ec633-e6ee-476c-a41f-6e2c1f24227f')">Add an Assignment</neu-label>
                                            </neu-item>    
                                            <neu-item class="training-video-list">
                                            <neu-label  @click="openVideo('https://mediaconnect.app.medcity.net/embed?id=625c5df2-5938-4e3d-924c-c5f0e402c834')">Tabs in the Resource Assignment Screen</neu-label>
                                            </neu-item>           
                                        </neu-list>
                                    </div></div> 
                </div>
                <div id="actionable-assignment-tabs" class="mt-3 border rounded tabHeight" v-if="!isAssignmentSectionVisible">
                    <neu-tablist :value="selectedTab" class="mx-4 tabList neu-text-left">
                        <neu-tab white
                                 tab="home-tab"
                                 @click="setSelectedTab('home-tab')">
                            <div>Home Department </div>
                        </neu-tab>
                        <neu-tab white
                                 tab="other-tab"
                                 @click="setSelectedTab('other-tab')">
                            <div>Other Department</div>
                        </neu-tab>
                        <neu-tab white
                                 tab="unscheduled-tab"
                                 @click="setSelectedTab('unscheduled-tab')">
                            <div>Unscheduled Staff</div>
                        </neu-tab>
                        <neu-tab white
                                 tab="search-tab"
                                 @click="setSelectedTab('search-tab')">
                            <div>Staff Search</div>
                        </neu-tab>
                        <neu-tab white
                                 tab="staffing-tab"
                                 @click="setSelectedTab('staffing-tab')">
                            <div>Staffing Matrix</div>
                        </neu-tab>
                        <neu-tab white
                                 tab="sms-notification-tab"
                                 @click="setSelectedTab('sms-notification-tab')"
                                 tooltip="tooltip"
                                 >
                            <div>
                                Notifications
                                <span id="sms-notification-tab-count" class="badge bg-danger ms-1" v-if="isShowNotifictionBadge == true && notificationCount > 0">{{notificationCount}}</span>
                            </div>
                        </neu-tab>
                        <neu-tab white
                                 tab="contractlabor-tab"
                                 v-if="allowManualClRequests" 
                                 @click="setSelectedTab('contractlabor-tab')">
                            <div>Contract Labor</div>
                        </neu-tab>

               
                    </neu-tablist>



                    <div class="panelTabData scrollBar" id="home" role="tabpanel" v-show="isHomeTabVisible" aria-labelledby="home-tab">
                        <HomeDepartment :homeTabInput="actionableConfig" />
                    </div>
                    <div class="panelTabData scrollBar" id="other" role="tabpanel" v-if="isOtherTabVisible" aria-labelledby="other-tab">
                        <OtherDepartment :otherTabInput="actionableConfig" />
                    </div>
                    <div class="panelTabData scrollBar" id="unscheduled" role="tabpanel" v-if="isUnscheduledTabVisible" aria-labelledby="unscheduled-tab">
                        <UnscheduledStaff :unschStaffTabInput="actionableConfig" />
                    </div>
                    <div class="panelTabData scrollBar" id="search" role="tabpanel" v-if="isSearchTabVisible" aria-labelledby="search-tab">
                        <StaffSearch :staffingSerachTabInput="actionableConfig" />
                    </div>
                    <div class="panelTabData scrollBar" id="staffing" role="tabpanel" v-if="isStaffingTabVisible" aria-labelledby="staffing-tab">
                        <StaffingMatrix :staffingGridTabInput="actionableConfig" />
                    </div>
                    <div class="panelTabData scrollBar" id="smsNotifications" role="tabpanel" v-if="isNotificationsTabVisible" aria-labelledby="sms-notification-tab">
                        <Notifications :notificationTabInput="actionableConfig" />
                    </div>
                    <div class="panelTabData scrollBar" id="contractlabor" role="tabpanel" v-if="isContractLaborTabVisible" aria-labelledby="contractlabor-tab">
                        <ContractLabor :contractLaborTabInput="actionableConfig" />
                    </div>
                    
 


                        <neu-row slot="end"></neu-row>
                </div>
                <div id="assignment-container" v-if="isAssignmentSectionVisible">
                    <Assignment :assignmentInput="assignmentInput"
                                :assignmentType="assignmentType" />
                </div>
            </div>
        </div>
        <div v-if="isMetricsViewListVisible">
            <PlusMetricsListPopup :plusMetrics="plusMetrics"
                                  @close-modal="closePopup" />
        </div>
        <div v-if="isShowDepartmentSummaryNote">
            <DepartmentSummaryNote @close-modal="closeNotePopup"
                                   :noteInput="actionableConfig"></DepartmentSummaryNote>
        </div>
        <div v-if="isShowClockInDetails">
            <ClockInStatus @close-modal="closeClockInStatusPopup"
                           :clockInInput="clockStatusModel"></ClockInStatus>
        </div>
        <!-- Toaster 1-->
        <div v-if="isToasterVisible">
            <ToasterPopup @closeModal="closeToasterPopup"
                          :msgValue="msgValue"
                          :classFlag="classFlag"></ToasterPopup>
        </div>
        <div v-if="isSendSMSPopupVisible">
            <SendSMSPopup :smsInput="smsInput"
                          @close-modal="closeSendSMSPopup" />
        </div>
    </div>
</template>
<script lang="ts">
    import { mapState } from "vuex";
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";

    //Import Componenets
    import {
        NeuContainer,
        NeuRow,
        NeuCol,
        NeuTab,
        NeuTablist
    } from "@neutron/vue";
    import ToasterPopup from "../shared/ToasterPopup.vue";
    import DepartmentSummaryHeader from './header/DepartmentSummaryHeader.vue';
    import PlusMetricsListPopup from './header/PlusMetricsListPopup.vue';
    import HomeDepartment from './tabs/HomeDepartment.vue';
    import OtherDepartment from './tabs/OtherDepartment.vue';
    import StaffingMatrix from './tabs/StaffingMatrix.vue';
    import StaffSearch from './tabs/StaffSearch.vue';
    import UnscheduledStaff from './tabs/UnscheduledStaff.vue';
    import Notifications from './tabs/Notifications.vue';
    import ContractLabor from './tabs/ContractLabor.vue';
    import Assignment from './assignment/Assignment.vue';
    import DepartmentSummaryNote from './header/DepartmentSummaryNote.vue';
    import ClockInStatus from './header/ClockInStatus.vue';
    import SendSMSPopup from './tabs/SendSMSPopup.vue';
    import { Profile } from '@/models/profile/index';
    import { useAppInsights } from '../../store/modules/AppInsights';
    import { AppMode} from "@/enums/enum";

    class Props {
        actionableConfig!: any

    }

    @Options({
        computed: {
            ...mapState('profile', ['profileData', 'appInsightEventData']),
        },

        components: {
            NeuContainer,
            NeuRow,
            NeuCol,
            NeuTab,
            NeuTablist,
            ToasterPopup,
            DepartmentSummaryHeader,
            PlusMetricsListPopup,
            HomeDepartment,
            OtherDepartment,
            StaffingMatrix,
            StaffSearch,
            UnscheduledStaff,
            Notifications,
            ContractLabor,
            Assignment,
            DepartmentSummaryNote,
            ClockInStatus,
            SendSMSPopup
        }
    })
    //@ts-ignore
    export default class Actionable extends Vue.with(Props) {
        //Data properties
        profileData!: Profile;
        appInsightEventData!:any ;
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        selectedTab: string = 'home-tab';
        isHomeTabVisible: boolean = true;
        isOtherTabVisible: boolean = false;
        isUnscheduledTabVisible: boolean = false;
        isSearchTabVisible: boolean = false;
        isStaffingTabVisible: boolean = false;
        isNotificationsTabVisible: boolean = false;
        isContractLaborTabVisible: boolean = false;
        deptSummaryHeaderData: any = null;
        isdeptSummaryHeaderVisible: boolean = false;
        shortDateFormatPresentation: string = 'MM/DD/YYYY';
        shortTimeFormat: string = 'hh:mm A'
        FacilityCoid: string = "";
        FacilityName: string = "";
        ClusterName: string = "";
        DepartmentCode: string = "";
        DepartmentName: string = "";
        StartDate: string = "";
        StartTime: string = "";
        EndTime: string = "";
        isMetricsViewListVisible: boolean = false;
        plusMetrics: any = null;
        isAssignmentSectionVisible: boolean = false;
        assignmentInput: any = null;
        assignmentType: string = "";
        isShowDepartmentSummaryNote: boolean = false;
        isShowClockInDetails: boolean = false;
        clockStatusModel: any = null;
        isSendSMSPopupVisible: boolean = false;
        smsInput: any = null;
        isShowNotifictionBadge: any = true;
        notificationCount: any = 0;
        isTrainingVideoListVisible: boolean =false;
        mousePosX: any;
        mousePosY: any;
        allowManualClRequests: boolean = false;


        //Lifecycle hooks
        created() {
            this.emitter.on('showToasterNotificationActionable', this.showToasterNotificationActionable);
            this.emitter.on('showToasterDelayNotificationActionable', this.showToasterDelayNotificationActionable);
            this.emitter.on("showMetricsList", this.showMetricsViewList);
            this.emitter.on("showDepartmentSummaryNote", this.showDepartmentSummaryNote);
            this.emitter.on("showClockInStatusPopup", this.showClockInStatusPopup);
            this.emitter.on("loadAssignment", this.loadAssignment);
            this.emitter.on("createAssignment", this.createAssignment);
            this.emitter.on("unloadAssignment", this.unloadAssignment);
            this.emitter.on("refreshActionableTabContent", this.refreshActionableTabContent);
            this.emitter.on("showSendSMSPopup", this.showSendSMSPopup);
            this.emitter.on("refreshNotificationBadge", this.refreshNotificationBadge);
            this.emitter.on("checkActiveTabinActionablePanel", this.checkActiveTabinActionablePanel);
        }
        unmounted(){
            this.emitter.off('showToasterNotificationActionable', this.showToasterNotificationActionable);
            this.emitter.off('showToasterDelayNotificationActionable', this.showToasterDelayNotificationActionable);
            this.emitter.off("showMetricsList", this.showMetricsViewList);
            this.emitter.off("showDepartmentSummaryNote", this.showDepartmentSummaryNote);
            this.emitter.off("showClockInStatusPopup", this.showClockInStatusPopup);
            this.emitter.off("loadAssignment", this.loadAssignment);
            this.emitter.off("createAssignment", this.createAssignment);
            this.emitter.off("unloadAssignment", this.unloadAssignment);
            this.emitter.off("refreshActionableTabContent", this.refreshActionableTabContent);
            this.emitter.off("showSendSMSPopup", this.showSendSMSPopup);
            this.emitter.off("refreshNotificationBadge", this.refreshNotificationBadge);
            this.emitter.off("checkActiveTabinActionablePanel", this.checkActiveTabinActionablePanel);
        }
        async mounted() {
            if (this.actionableConfig != null) {
                this.FacilityCoid = this.actionableConfig.DataSet.FacilityCoid;
                this.FacilityName = this.actionableConfig.DataSet.FacilityName;
                this.ClusterName = this.actionableConfig.DataSet.ClusterName;
                this.DepartmentCode = this.actionableConfig.DataSet.DepartmentCode;
                this.DepartmentName = this.actionableConfig.DataSet.DepartmentName;
                this.StartDate = moment(this.actionableConfig.DataSet.StartDateTime).format(this.shortDateFormatPresentation);
                this.StartTime = moment(this.actionableConfig.DataSet.StartDateTime).format(this.shortTimeFormat);
                this.EndTime = moment(this.actionableConfig.DataSet.EndDateTime).format(this.shortTimeFormat);
                this.allowManualClRequests = this.actionableConfig.AllowManualClRequests;
            }
            this.deptSummaryHeaderData = {
                shortDateFormat: this.actionableConfig.ShortDateFormat,
                shortTimeFormat: this.actionableConfig.ShortTimeFormat,
                tenantId: this.actionableConfig.TenantId,
                userId: this.actionableConfig.UserId,
                shortDateFormatPresentation: this.actionableConfig.ShortDateFormatPresentation,
                headerContainer: 'actionable-assignment-header',
                startTime: this.actionableConfig.DataSet.StartDateTime,
                endTime: this.actionableConfig.DataSet.EndDateTime,
                departmentId: this.actionableConfig.DataSet.DepartmentId,
                appMode: this.actionableConfig.AppMode,
                serviceLineName: this.actionableConfig.DataSet.ClusterName,
                showSkillGaps: true,
                showCareAssignmentPrint: true,
                showActionableRefresh: true,
                showClockInStatues: this.actionableConfig.AppMode === AppMode.FullAccess ? true : false,
                showNotes: this.actionableConfig.AppMode === AppMode.FullAccess ? true : false,
                showSaveAsExcel: true,
                pmEnabled: this.actionableConfig.DataSet.PMEnabled,
                dashboardConfig: this.actionableConfig.DashboardConfig
            }
            this.isdeptSummaryHeaderVisible = true;
            this.updateAppInsights();
            document.addEventListener("mousemove", (event) => {
                    this.mousePosX = event.clientX + 5;
                    this.mousePosY = event.clientY + 60;
                });
        }

        async updateAppInsights(){
          var inputData =  {
                Username:  this.profileData.UserName,
                TenantName:  this.profileData.TenantName,
                FacilityName: this.FacilityName,
                COID: this.FacilityCoid,            
                DeptCode: this.DepartmentCode ,            
                DeptName: this.DepartmentName,
            
             };
             await this.$store
                .dispatch("profile/updateAppInsights", inputData)
                .then(() => {
                    useAppInsights().trackEvent({
                        name: 'DASHBOARD_ACTIONABLE_VIEW', properties:
                            JSON.parse(JSON.stringify(this.appInsightEventData))
                    });
                    })
                .catch((err: any) => {
                    if (err) {
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        //Methods
        close() {
            this.$emit("close-modal");
        }

        setSelectedTab(tab: string) {
            this.selectedTab = tab;
            this.showTabContent();
        }
        showTabContent() {
            this.isHomeTabVisible = false;
            this.isOtherTabVisible = false;
            this.isUnscheduledTabVisible = false;
            this.isSearchTabVisible = false;
            this.isStaffingTabVisible = false;
            this.isNotificationsTabVisible = false;
            this.isContractLaborTabVisible = false;

            switch (this.selectedTab) {
                case 'home-tab':
                    this.isHomeTabVisible = true;
                    break;
                case 'other-tab':
                    this.isOtherTabVisible = true;
                    break;
                case 'unscheduled-tab':
                    this.isUnscheduledTabVisible = true;
                    break;
                case 'search-tab':
                    this.isSearchTabVisible = true;
                    break;
                case 'staffing-tab':
                    this.isStaffingTabVisible = true;
                    break;
                case 'sms-notification-tab':
                    this.isNotificationsTabVisible = true;
                    break;
                case 'contractlabor-tab':
                    this.isContractLaborTabVisible = true;
                    break;
            }
        }

        refreshActionableTabContent() {
            switch (this.selectedTab) {
                case 'home-tab':
                    this.emitter.emit("refreshHomeDepartmentTab");
                    break;
                case 'other-tab':
                    this.emitter.emit("refreshOtherDepartmentTab");
                    break;
                case 'unscheduled-tab':
                    this.emitter.emit("refreshUnscheduleStaffingDetails");
                    break;
                case 'search-tab':
                    this.emitter.emit("refreshStaffSearch");
                    break;
                case 'staffing-tab':
                    this.emitter.emit("refreshStaffingMatrixTab");
                    break;
                case 'sms-notification-tab':
                    this.emitter.emit("refreshSmsNotificationGrid");
                    break;
                case 'contractlabor-tab':
                    this.emitter.emit("refreshContractLabor");
                    break;
            }
        }
        displayTrainingList(e: any){
            
            console.log(this.mousePosY);
            this.isTrainingVideoListVisible = !this.isTrainingVideoListVisible;
            }

        openVideo(path: any){
                window.open(path, '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How' ); 
                this.isTrainingVideoListVisible = false;
                

                }            
        refreshNotificationBadge(notificationBadgeData: any) {
            if (notificationBadgeData.notificationCount > 0) {
                this.isShowNotifictionBadge = true;
                this.notificationCount = notificationBadgeData.notificationCount;

                var badgeElement: any = document.getElementById('sms-notification-tab-count');
                if (badgeElement != null) {
                    badgeElement.setAttribute('style', 'background-color:' + notificationBadgeData.backgroundColor);
                    badgeElement.setAttribute('style', 'color:' + notificationBadgeData.color);
                }
            }
            else {
                this.isShowNotifictionBadge = false;
            }
        }

        showMetricsViewList(metricsData: any) {
            this.plusMetrics = metricsData;
            this.isMetricsViewListVisible = true;
        }

        closePopup() {
            this.isMetricsViewListVisible = false;
        }

        showDepartmentSummaryNote() {
            this.isShowDepartmentSummaryNote = true;
        }

        closeNotePopup() {
            this.isShowDepartmentSummaryNote = false;
        }

        loadAssignment(parameter: any) {
            parameter.facilityConfig = this.actionableConfig.FacilityConfig || {};
            this.assignmentInput = parameter;
            this.assignmentType = "View";
            this.isAssignmentSectionVisible = true;
            this.emitter.emit('setAssignmentSectionVisibleFlag', this.isAssignmentSectionVisible);
        }

        createAssignment(parameter: any) {
            parameter.facilityConfig = this.actionableConfig.FacilityConfig || {};

            //DepartmentId is available when staff memeber is selected from unscheduled tab
            //DeptId is available when staff memeber is selected from staff search tab
            if (parameter.DepartmentId) {
                parameter.StaffOrigDepartmentId = parameter.DepartmentId;
            }
            else if (parameter.DeptId) {
                parameter.StaffOrigDepartmentId = parameter.DeptId;
            }

            parameter.Title = 'Create Assignment';
            parameter.showTitle = true;

            this.assignmentInput = parameter;
            this.assignmentType = "Create";
            this.isAssignmentSectionVisible = true;
            this.emitter.emit('setAssignmentSectionVisibleFlag', this.isAssignmentSectionVisible);
        }

        unloadAssignment() {
            this.isAssignmentSectionVisible = false;
            this.emitter.emit('setAssignmentSectionVisibleFlag', this.isAssignmentSectionVisible);
        }

        showToasterNotificationActionable(data: any) {
            this.msgValue = data.message;
            this.classFlag = data.flag;
            this.isToasterVisible = true;
        }

        showToasterDelayNotificationActionable(data: any) {
            setTimeout(() => {
                this.msgValue = data.message;
                this.classFlag = data.flag;
                this.isToasterVisible = true;
            }, 4000);
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }

        showClockInStatusPopup() {
            var info: any = this.actionableConfig.DataSet;
            var facilityName: string = info.FacilityName + ' (' + info.FacilityCoid + ')';
            var departmentName: string = info.DepartmentName + ' (' + info.DepartmentCode + ')';
            this.clockStatusModel = {
                facilityId: info.FacilityId,
                facilityName: facilityName,
                departmentId: info.DepartmentId,
                departmentName: departmentName,
                startDateTime: moment(new Date()).format('YYYY-MM-DD'),
                source: 'Dashboard'
            };
            this.isShowClockInDetails = true;
        }

        closeClockInStatusPopup() {
            this.isShowClockInDetails = false;
        }

        showSendSMSPopup(data: any) {
            this.smsInput = data;
            this.isSendSMSPopupVisible = true;
        }

        closeSendSMSPopup() {
            this.isSendSMSPopupVisible = false;
        }

        checkActiveTabinActionablePanel(headerInfo: any) {
            if (this.selectedTab.toLowerCase() === 'home-tab') {
                this.emitter.emit("homeTabExportToExcel", headerInfo);
            }
            else if (this.selectedTab.toLowerCase() === 'other-tab') {
                this.emitter.emit("otherTabExportToExcel", headerInfo);
            }
            else if (this.selectedTab.toLowerCase() === 'unscheduled-tab') {
                this.emitter.emit("unscheduledTabExportToExcel", headerInfo);
            }
        }
    }
</script>
<style scoped>
    .c4c-modal {
        width: 95%;
        height: 95%;
        font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
        background: white;
        box-shadow: 0px 16px 30px 0px;
    }

    .panleBody {
        padding: 12px;
        height: 73%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .panelTabData {
        padding-top: 1rem;
        overflow-x: auto;
        /* overflow: auto;
    height: calc(100% - 50px) !important; */
    }

    .resourceAssignHeaderDetails {
        text-align: left;
        background-color: #F7F7F8 !important;
        border: 1px solid rgba(0, 0, 0, 0.125);
        margin: 12px;
        margin-right: 23px;
        color: black;
        padding: 8px 12px;
        white-space: nowrap;
    }

    .headerLH {
        line-height: 1.5 !important;
    }

    .modelHeader {
        background-color: #F7F7F8 !important;
        font-size: 13px;
        box-shadow: none !important;
        margin-top: 5px;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .tabHeight {
        min-height: calc(100% - 250px) !important;
    }

    .txtWrap {
        white-space: break-spaces;
    }

    .resourceAssignHeading{
        padding: 0px 12px !important;
        padding-top: 12px !important;
    }
    .modalTutorialList {
          left:50px; position:absolute ; border-color: black; background-color: rgb(141, 140, 140);
}
.training-video-list {
  &:hover {
    background-color: rgb(54, 115, 170);
    color: #FFF;
  }
}
</style>
