<template>
  <div class="smsNotificationFilter">
    <!--dropdown-menu show-->
    <div class="row filterrow txtLeft">
      <div class="col-md-2">
        <div class="form-group">
         <label class="control-label" id="facilitiesFilter-dashboard-label">FACILITY</label>
                    <VueMultiselect v-model="selectedFacilities"
                                    :options="facilities"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="setSelectedFilters"
                                    placeholder="Select Facilities"
                                    label="Text"
                                    track-by="Text"
                                    id="selFacilities">
                    </VueMultiselect>
                    </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label" id="departmentFilter-dashboard-label"
            >DEPARTMENT</label
          >
          <VueMultiselect
            v-model="selectedDepartments"
            :options="departments"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"
            placeholder="Select Departments"
            label="Text"
            track-by="Text"
            id="selDepartments"
          >
          </VueMultiselect>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label" id="dtStart-label">RECEIVED FROM</label>
          <Datepicker
            v-model="startDateTime" weekStart="0" inputClassName="dp-input-size"
            class="datePickerWidth" autoApply 
          ></Datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label" id="dtEnd-label">RECEIVED TO</label>
          <Datepicker
            v-model="endDateTime" weekStart="0" inputClassName="dp-input-size"
            class="datePickerWidth" autoApply 
          ></Datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label" id="facilitiesFilter-dashboard-label">SMS TEMPLATE</label>
          <VueMultiselect
            v-model="selectedSMStemplates"
            :options="smsTemplates"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"  
            class="min-width-200"          
            placeholder="Select SMS Template"
            label="name"
            track-by="name"
            id="selSMStemplates"
          >
          </VueMultiselect>
        </div>
      </div>
      <div class="col-md-1 txtCenter">
        <div class="form-group">
          <label
            class="control-label"
            id="facilitiesFilter-dashboard-label"
          ></label>

          <neu-button
            type="button"
            fill="raised"            
            @click="searchData()"
          >
            <!--:disabled="!enableApplyClear"-->
            <i aria-hidden="true"> </i> Search
          </neu-button>
        </div>
      </div>
      
    </div>

    <div v-if="isToasterVisible">
      <ToasterPopup
        @closeModal="closeToasterPopup"
        :msgValue="msgValue"
        :classFlag="classFlag"
      ></ToasterPopup>
    </div>
   <div class="row filterrow">
     <Notification />
   </div>
  </div>
</template>

<script lang="ts">
//import plugins and modules
import { Options, Vue } from "vue-class-component";
import jslinq from "jslinq";
import moment from "moment";
import { mapState } from "vuex";
import {
  MetricsFilter,
  StartEndDateTimeModel,
  Filter,
} from "@/models/dashboardmain/index";
import { KeyPairModel } from "@/models/common/index";
import { Profile } from "@/models/profile/index";
//@ts-ignore
import VueMultiselect from "vue-multiselect";
//@ts-ignore
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ToasterPopup from "../shared/ToasterPopup.vue";
import { NeuCheckbox, NeuButton } from "@neutron/vue";
import Notification from './Notification.vue';
class Props {   
  notificationTab!: any;
}

@Options({
  computed: {
    ...mapState("profile", ["profileData"]),   
    ...mapState('dashboard', ['dashboardConfiguration']),
   ...mapState('assignments', ['smsNotificationTemplates', 'shiftByDepartments', 'skillsByDepartment', 'smsNotificationStatus']),
      
  },
  components: {
    VueMultiselect: VueMultiselect,
    Datepicker: Datepicker,
    NeuCheckbox: NeuCheckbox,
    NeuButton: NeuButton,
    ToasterPopup: ToasterPopup,
    Notification
  }
})
export default class CurrentNotifications extends Vue.with(Props) {
  //Data properties  
  profileData!: Profile;
  dashboardConfiguration!: any;
  transportMediaId: any = 1;
  notificationEventIds: any = [4, 5];
  smsNotificationTemplates!: any;
  smsTemplates: any = [];
  serchInput: any={departmentIdsToLoad: [],
                   receivedFrom: '',
                   receivedTo:  '',
                   smsTemplates: '', 
                   activeTab: ''};
  showGrid: boolean = false;
  filtersData!: Filter;
  facilities: KeyPairModel[] = [];
  selectedFacilities: any = null;
  selectedSMStemplates: any = null;
  selectedDepartments: any = null;
  departmentEnabled: boolean = true;
  departments: KeyPairModel[] = [];
  
  startDateTime: any = null;
  endDateTime: any = null;
  defaultFacilityId: string[] = [];
  
  longDateFormat: string = "YYYY-MM-DDTHH:mm:ss";
  shortDateFormat: string = "YYYY-MM-DD";
  longDateTimeFormatPresentation: string = "MM/DD/YYYY hh:mm:ss";
  scrollableShellHeightAdjusted: boolean = false;
  msgValue!: string;
  classFlag!: string;
  isToasterVisible: boolean = false;
  dateRangesEnabled: boolean = false;
  maxDaysInSearch:number = 60;
  dateErrorMessage:string = "Please filter within a 60 day range.";
  invalidEndDateErrorMessage:string = "End date must not be greater than current date time.";
  invalidDateRangeMessage:string = "Please make sure end date is greater than start date.";
  invalidDateFormat:string = "Please make sure a valid date has been selected.";
  invalidStartDateMessage:string = "Start date should be within 48 hours from today's date";

  async mounted() {
      await this.getDefaultfiltersData();  
      await this.getSMSNotificationTemplate();
      this.setStartEndDate();
      if(this.notificationTab.toLowerCase() == 'current'){
        this.maxDaysInSearch= 30;  
        this.dateErrorMessage = "Please filter within a 30 day range.";
      }else{
        this.maxDaysInSearch= 60;
        this.dateErrorMessage = "Please filter within a 60 day range.";
      }
      
  }

  //Methods

 GetSortOrder(prop: any) {
      return function (a: any, b: any) {
          if (a[prop] > b[prop]) {
              return 1;
          } else if (a[prop] < b[prop]) {
              return -1;
          } return 0;
      }
  }
  showToasterPopup(data: any){
      this.msgValue = data.message;
      this.classFlag = data.flag;
      this.isToasterVisible = true;
  }

   searchData()
   {
    //set SMS Template
    let smsTemplate = [];
    if(this.selectedSMStemplates != null && this.selectedSMStemplates.length > 0){
      smsTemplate = this.selectedSMStemplates;
    }
    else{
      smsTemplate = this.smsTemplates;
    }

    //set Department
    let departmentList = [];
    if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
      departmentList = this.selectedDepartments;
    }
    else{
      departmentList = this.departments;
    }
    
    if (!this.isDateValid(this.startDateTime) || !this.isDateValid(this.endDateTime)) {
          let data={message:this.invalidDateFormat, flag: "error"}
          this.showToasterPopup(data);
          return;
      }

      if(this.notificationTab.toLowerCase() == 'activitylog'){
        if (!this.isEndDateValid(this.endDateTime)) {
          let data={message:this.invalidEndDateErrorMessage,  flag: "error"}
          this.showToasterPopup(data);
          return;
        }
      }

      if(this.notificationTab.toLowerCase() == 'current'){
        if (!this.isStartDateValid(this.startDateTime) || !this.isStartDateValid(this.endDateTime)) {
          let data={message:this.invalidStartDateMessage,  flag: "error"}
          this.showToasterPopup(data);
          return;
        }
      }

      if (!this.areStartEndDatesValid(this.startDateTime, this.endDateTime)) {
          let data={message:this.invalidDateRangeMessage, flag: "error"}
          this.showToasterPopup(data);
          return;
      }
                  
      if (!this.isWithinThirtyDays(this.startDateTime, this.endDateTime, this.maxDaysInSearch)) {
          let data={message:this.dateErrorMessage, flag: "error"}
          this.showToasterPopup(data);
          return;
      }
      
      this.serchInput = {
          departmentIdsToLoad: departmentList,
          receivedFrom: this.startDateTime,
          receivedTo:  this.endDateTime,
          smsTemplates: smsTemplate, 
          activeTab: this.notificationTab.toLowerCase(),
          tenantId: this.profileData.TenantId
      };
      
      this.emitter.emit("serchInput", this.serchInput);
      this.showGrid = true;   
   }

  isStartDateValid(startDate: any) {
      var twoDaysAgo = moment()
          .subtract(48, 'hours')
          .subtract(1, 'minutes');
      return moment(twoDaysAgo).isBefore(startDate);
  }

  getDefaultfiltersData() {
    this.filtersData = this.dashboardConfiguration.Filters;
    this.facilities = this.filtersData.Facilities;
    this.departments = this.filtersData.Departments;
    this.selectedDepartments = [];
  }

  async getSMSNotificationTemplate() {
      var inputData: any = {
          transportMediaId: this.transportMediaId,
          eventIds: this.notificationEventIds
      };
      await this.$store
          .dispatch("assignments/getSMSNotificationTemplates", inputData)
          .then(() => {
              if (this.smsNotificationTemplates != null) {
                  this.smsTemplates = this.smsNotificationTemplates;
                  //hideLoadingModal();
              }
          })
          .catch((err: any) => {
              if (err) {

                  //  hideLoadingModal();
                  // toastr.error('Error retrieving SMS Templates');
              }
          });
  }
  
  setSelectedFilters() {
    var selectedFacilities = this.selectedFacilities;
    
    var selectedDepartments = this.selectedDepartments;

    //@ts-ignore
    var facilityIds: string[] = this.getIntCollection(
      selectedFacilities,
      this.filtersData.Facilities
    );
   

    
    var departmentItems: KeyPairModel[] = this.getDepartmentItems(      
      this.filtersData.Departments,
      facilityIds
    );
   

    if (selectedDepartments !== null && selectedDepartments.length > 0) {
        if(facilityIds != null && facilityIds.length > 0){
          selectedDepartments = selectedDepartments.filter((value: KeyPairModel) => {
            return facilityIds.indexOf(value.FacilityId) >= 0;
          });

          this.selectedDepartments = selectedDepartments;
        }
        else{
          this.selectedDepartments = [];
        }
    }

    if(departmentItems != null && departmentItems.length > 0){
      this.departments = departmentItems;
    }
    else{
      this.departments = this.filtersData.Departments;
    }
    this.showGrid = false;
    //this.summaries = summaryItems;
  }

  closeToasterPopup() {
    this.isToasterVisible = false;
  }

  getSelectedDDLIds(items: any[]) {
    //@ts-ignore
    var selectedIds: any[] = jslinq(items)
      .select(function (item) {
        return item.Value;
      })
      .toList();
    return selectedIds;
  }
  getDepartmentItems(collection: any, facilityIds: string[]) {
    var result: KeyPairModel[] = [];
    if(facilityIds === undefined || facilityIds === null || facilityIds.length === 0)
    return [];
    else
    {
          result = collection.filter(function (value: any) {
            return facilityIds.indexOf(value.FacilityId) >= 0;
          });
          return result;
    }
     
  }
  

  getIntCollection(filters: any, collection: any, allowNull: boolean) {
    var items = [];
    if (filters === undefined || filters === null || filters.length === 0) {
        return null;
    } else {
      for (var i = 0; i < filters.length; i++) {
        var filter = filters[i];
        var it = {
          ParentValue: filter.ParentValue,
          Value: filter.Value,
          Text: filter.Text,
        };
        items.push(it);
      }
    }

    //@ts-ignore
    var result: any[] = jslinq(items).select(function (item) {  return item.Value; }).toList();

    return result;
  }

 
  sortAscByText(collection: KeyPairModel[]) {
    //@ts-ignore
    var result = jslinq(collection)
      .distinct()
      .orderBy(function (item: any) {
        return item.Text;
      })
      .toList();

    return result;
  }

  getDistinct(collection: KeyPairModel[] | null) {
    if (collection != null) {
      //@ts-ignore
      var results = jslinq(collection)
        .select(function (item: any) {
          return { Value: item.Value, Text: item.Text };
        })
        .distinct()
        .orderBy(function (item: any) {
          return item.Value;
        })
        .toList();

      return results;
    } else {
      return [];
    }
  }

  getCurrentDateTime() {
    var date = moment(new Date()).format(this.longDateFormat);
    return date;
  }

  getStartDate() {
    var date = moment(new Date()).format(this.shortDateFormat);
    var sdt = date + "T07:00:00";

    return sdt;
  }

  getEndDate() {
    var date = moment(new Date()).format(this.shortDateFormat);
    var edt = date + "T23:59:00";

    return edt;
  }

  calDateTimeFormated(inputDateTime: any) {
    return moment(new Date(inputDateTime)).format(
      this.longDateTimeFormatPresentation
    );
  }
  
    isWithinThirtyDays = function (startDateTime:Date, endDateTime:Date, maxNumberOfDates:number) {
        var diff = moment(startDateTime).diff(moment(endDateTime), 'day');
        return Math.abs(diff) <= maxNumberOfDates;
    };

    isEndDateValid = function (endDate:Date) {
        var now = moment();
        return moment(endDate).isSameOrBefore(now);
    };

    areStartEndDatesValid = function (startDate:Date, endDate:Date) {
        return moment(startDate).isSameOrBefore(endDate);
    };

    isDateValid = function (date:Date) {
        return moment(date).isValid();
    };

    setStartEndDate(){
      var currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - 48);
      this.startDateTime = currentDate;
      this.endDateTime = new Date();
    }
       
}
</script>

<style scoped>
.btn {
  white-space: nowrap !important;
}

.neu-select {
  width: 225px !important;
  font-size: 14px !important;
  border: 1px solid #ced1d7 !important;
}

.datePickerWidth {
  width: 185px !important;
}

.filterrow{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.min-width-200{
  min-width: 200px;
}
/* Select Icon */

</style>
