<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Contract Labor and Call Out</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody">
                <neu-tablist :value="selectedTab" class="mx-4 tabList">
                    <neu-tab
                        white
                        tab="ContractLabor"
                        @click="showContractLaborDetails">
                        <div>Contract Labor <span id="contractlabordetailcount" class="badge bg-success">{{contractLaborCount}}</span></div>
                    </neu-tab>
                    <neu-tab
                        white
                        tab="CallOut"
                        @click="showCalloutDetails">
                        <div>Call Out <span id="calloutstaffdetailcount" class="badge bg-warning">{{callOutCount}}</span></div>
                    </neu-tab>
                </neu-tablist>
                <div id="contract-labor-home" class="panelTabData scrollBar" v-if="isContractLaborVisible" role="tabpanel" aria-labelledby="contract-labor-home-tab">
                    <p id="contractlaborstaffdetail" class="p-2 default-font-size">{{contractLabors}}</p>
                </div>
                <div id="contract-labor-profile" class="panelTabData scrollBar" v-if="isCalloutVisible" role="tabpanel" aria-labelledby="contract-labor-profile-tab">
                    <p id="calloutstaffdetail" class="p-2 default-font-size">{{callOuts}}</p>
                </div>
            </div>  
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import {
  NeuTab,
  NeuTablist
} from "@neutron/vue";

@Options({
  components:{
    NeuTab,
    NeuTablist,
  },
  props: {
      contractLaborCalloutDetails: {} as any,
      contractLaborCalloutFlags: {} as any
  }, 
})
export default class ContractLaborCalloutPopup extends Vue {
    //Data properties
    contractLaborCalloutDetails: any;
    contractLaborCalloutFlags: any;
    isContractLaborVisible: boolean = true;
    isCalloutVisible: boolean = false;
    selectedTab: string = "";
    contractLaborCount: number = 0;
    callOutCount: number = 0;
    contractLabors: string = "";
    callOuts: string= "";

    //Lifecycle hooks
    mounted() {
        this.setContractLaborCalloutsData();
    }
    created(){
        this.emitter.on("setContractLaborCalloutsData", this.setContractLaborCalloutsData);
    }
    unmounted(){
        this.emitter.off("setContractLaborCalloutsData", this.setContractLaborCalloutsData);
    }
    //Methods
    close() {
        this.$emit("close-modal");
    }
    setContractLaborCalloutsData(){
        this.contractLabors =  this.contractLaborCalloutDetails.contractLaborStaff;
        this.callOuts =  this.contractLaborCalloutDetails.calloutStaff;

        this.contractLaborCount = this.contractLaborCalloutDetails.contractLaborStaff && this.contractLaborCalloutDetails.contractLaborStaff.trim()
            ? this.contractLaborCalloutDetails.contractLaborStaff.split(';').length
            : 0;

        this.callOutCount = this.contractLaborCalloutDetails.calloutStaff && this.contractLaborCalloutDetails.calloutStaff.trim()
            ? this.contractLaborCalloutDetails.calloutStaff.split(';').length
            : 0;

        if(this.contractLaborCalloutFlags.isContractLaborVisible){
            this.isContractLaborVisible = true;
            this.isCalloutVisible = false;
            this.selectedTab = "ContractLabor"
        }

        if(this.contractLaborCalloutFlags.isCalloutVisible){
            this.isContractLaborVisible = false;
            this.isCalloutVisible = true;
            this.selectedTab = "CallOut"
        }
    }
    showContractLaborDetails(){
        this.isContractLaborVisible = true;
        this.isCalloutVisible = false;
    }
    showCalloutDetails(){
        this.isContractLaborVisible = false;
        this.isCalloutVisible = true;
    }
}
</script>
<style scoped>

.c4c-modal {
    width: 50%;
    height: 65%;
    max-width: 50%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    padding: 5px 12px;
    height:90%;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
}

</style>