import ScheduleModule from '@/store/modules/schedule'
import SecurityModule from '@/store/modules/security'
import { defineComponent } from 'vue'
import { getModule } from 'vuex-module-decorators'
export default defineComponent({
  data() {
    return {
      isSidebarOpen: false, // shift panels (add/cancel)
      showSideBarPanel: false, // staff panels
      scheduleData: {},
      cancelCodes: [] as any[],
      scheduleSummaryCell: null as any,
      selectedCell: null as any,
    }
  },
  computed: {
    // store for schedule module
    scheduleStore() {
      return getModule(ScheduleModule, this.$store)
    },

    // store for security module
    securityStore() {
      return getModule(SecurityModule, this.$store)
    },
    underStaffModalPanel() {
      //@ts-ignore
      return this.scheduleStore?.underStaffModal ?? false
    },
    overStaffModalPanel() {
      //@ts-ignore
      return this.scheduleStore?.overStaffModal ?? false
    },
    openAddShift() {
      //@ts-ignore
      return this.scheduleStore?.addShiftModal ?? false
    },
    openCancelShift() {
      //@ts-ignore
      return this.scheduleStore?.cancelShiftModal ?? false
    },
  },
  watch: {
    isSidebarOpen() {
      setTimeout(() => {
        this.gridTransition()
      }, 100)
    },
    underStaffModalPanel(val) {
      if (val) {
        this.scheduleStore?.setOverstaffShift(false)
        this.closeShiftSidePanel()
      }
    },
    overStaffModalPanel(val) {
      if (val) {
        this.scheduleStore?.setUnderStafShift(false)
        this.closeShiftSidePanel()
      }
    },
    showSideBarPanel() {
      setTimeout(() => {
        this.gridTransition()
      }, 100)
    },
    openAddShift(val) {
      if (val) {
        this.closeStaffSidePanel()
      }
    },
    openCancelShift(val) {
      if (val) {
        this.closeStaffSidePanel()
      }
    },
  },
  created() {
    this.gridTransition()
  },
  methods: {
    /**
     * Closing add/cancel shift panels
     */
    closeShiftSidePanel() {
      this.isSidebarOpen = false
      this.selectedCell?.classList?.remove('cell_selected')
      this.scheduleStore.setOpenAddShift(false)
      this.scheduleStore.setOpenCancelShift(false)
    },
    /**
     * Closing over staff/understaff panels
     */
    closeStaffSidePanel() {
      this.showSideBarPanel = false
      this.scheduleSummaryCell?.classList?.remove('cell_selected')
      this.scheduleStore?.setOverstaffShift(false)
      this.scheduleStore?.setUnderStafShift(false)
    },
    /**
     * style changes for transition panels
     */
    gridTransition() {
      const grid = document.querySelector('.grid-container') as HTMLElement
      const sideBar = document.querySelector(
        '.sidebar-container'
      ) as HTMLElement
      if (sideBar?.clientWidth) {
        return (grid.style.width = `calc(100% - ${sideBar?.clientWidth}px)`)
      } else {
        if (grid) {
          return (grid.style.width = '100%')
        }
      }
    },
  },
})
