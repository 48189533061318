import { UserFacility, UserModel } from '@/models/scheduleManagement'
import {
  getUser,
  getSecurityAccount,
  getFacilities,
  getDepartmentsByFacilityKvp,
  getSchedulesByDepartmentId,
} from '@/services/security'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ name: 'securityModule', stateFactory: true })
export default class SecurityModule extends VuexModule {
  userObject: UserModel = {} as any as UserModel
  selectedTenant: string = ''
  facilityData: any
  allDepartmentsByFacilityKvp: any
  selectedFacility: string = ''
  selectedDepartment: string = ''
  allSchedulesByDepartmentId: any
  selectedScheduleId: string = ''
  statusText: string = ''

  get user() {
    return this.userObject
  }


  get getSelectedTenant() {
    return this.selectedTenant
  }
  get getSelectedFacility() {
    return this.selectedFacility
  }
  get getSelectedDepartment() {
    return this.selectedDepartment
  }
  get getSelectedScheduleId() {
    return this.selectedScheduleId
  }
  get getAllFacilitiesData() {
    return this.facilityData
  }
  get getAllDepartments() {
    return this.allDepartmentsByFacilityKvp
  }
  get getAllSchedulesRanges() {
    return this.allSchedulesByDepartmentId
  }
  get getStatusText() {
    return this.statusText
  }

  @Mutation
  setUser(data: UserModel) {
    const facilities: UserFacility[] = []
    if (data.departments) {
      for (const department of data.departments) {
        const facility = {
          facilityId: department.facilityId,
          coid: department.coid,
          facilityName: department.facilityName,
        }
        if (!facilities.includes(facility)) {
          facilities.push(facility)
        }
      }
    }
    data.facilities = facilities
    this.userObject = data
  }



  @Mutation
  setSelectedTenantId( data: any) {
    this.selectedTenant = data
  }
  @Mutation
  setSelectedFacilityId(data: any) {
    this.selectedFacility = data
  }
  @Mutation
  setSelectedDepartmentId(data: any) {
    this.selectedDepartment = data
  }
  @Mutation
  setSelectedScheduleId(data: any) {
    this.selectedScheduleId = data
  }
  @Mutation
  setStatusText(data: any) {
    this.statusText = data
  }
  @Mutation
  setFacilitiesList(data: any) {
    this.facilityData = data
  }
  @Mutation
  setAllDepartmentsByFacilityKvp(data: any) {
    this.allDepartmentsByFacilityKvp = data
  }
  @Mutation
  setAllSchedulesByDepartmentId(data: any) {
    this.allSchedulesByDepartmentId = data
  }

  @Action
  async getAllFacilities(tenantId: string) {
    try {
      const response = await getFacilities(tenantId)
      this.context.commit('setFacilitiesList', response)
    } catch (error) {
      return error
    }
  }
  @Action
  async getAllDepartmentsByFacilityKvp(params: any) {
    try {
      const response = await getDepartmentsByFacilityKvp(
        params.tenantId,
        params.facilityId
      )
      this.context.commit('setAllDepartmentsByFacilityKvp', response)
    } catch (error) {
      return error
    }
  }
  @Action
  async getAllSchedulesByDepartmentId(params: any) {
    try {
      const response = await getSchedulesByDepartmentId(
        params.departmentId,
        params.tenantId
      )
      this.context.commit('setAllSchedulesByDepartmentId', response)
    } catch (error) {
      return error
    }
  }
}
