import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";
import camelcaseKeys from "camelcase-keys";

const namespaced = true

interface State {
    staffingGrid: any,
    patientClassification: any,
    staffSearch: any,
    priorDaySettings: any,
    deptCancelReasons: any,
    notesByAssignment: any,
    notesByDept: any,
    notesByEntity: any,
    costCenter: any,
    departmentShifts: any,
    deptCancelReasonsByCategoryId: any,
    PTOBalance: any,
    clockInData: any,
    contractLaborRequests: any,
    cancelledRequest: any,
    createdcontractLaborRequest: any,
    createdcontractLaborBulkRequest: any,
    cancelledcontractLaborRequest: any,
    deptCLNeedAutomationStatus: any,
    careAssignmentData: any
}

//state
const state: State = {
    staffingGrid: null,
    patientClassification: null,
    staffSearch: null,
    priorDaySettings: null,
    deptCancelReasons: null,
    notesByAssignment: null,
    notesByDept: null,
    notesByEntity: null,
    costCenter: null,
    departmentShifts: null,
    deptCancelReasonsByCategoryId: null,
    PTOBalance: null,
    clockInData: null,
    contractLaborRequests: null,
    cancelledRequest: null,
    createdcontractLaborRequest: null,
    createdcontractLaborBulkRequest: null,
    cancelledcontractLaborRequest: null,
    deptCLNeedAutomationStatus: null,
    careAssignmentData: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getStaffingGrid({ commit, rootState }, inputs: any) {
        
        let apiUrl = `${process.env.VUE_APP_BASE_API_URL}/departmentmatrix/bysummaryperiod/${inputs.departmentId}/${inputs.startDateTime}`;
        if (inputs.deptPatientClassificationRangeID != undefined && inputs.deptPatientClassificationRangeID != null) {
            apiUrl += `/${inputs.deptPatientClassificationRangeID}`;
        }
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setStaffingGrid', res.data.data.results);
                }
                else{
                    commit('setStaffingGrid', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getPatientClassificationRange({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/patientclassificationrange/${inputs.departmentId}/${inputs.startDateTime}/${inputs.tenantId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    
                    const data = camelcaseKeys(res.data.data.results, { deep: true });
                        commit('setPatientClassificationRange', data);
                }
                else{
                    commit('setPatientClassificationRange', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getStaffSearch({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/staffs/StaffSearch`+inputs;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setStaffSearch', data);
                }
                else{
                    commit('setStaffSearch', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getPriorDaySetting({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/facilityconfigs/${inputs.facilityId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setPriorDaySetting', res.data.data.results);
                }
                else{
                    commit('setPriorDaySetting', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDepartmentCancelReasons({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/cancelreasons/department/${inputs.deptId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setDepartmentCancelReasons', data);
                }
                else{
                    commit('setDepartmentCancelReasons', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDepartmentCancelReasonsByCategoryId({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/cancelreasons/department/${inputs.departmentId}/category/${inputs.categoryId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setDepartmentCancelReasonsByCategoryId', data);
                }
                else{
                    commit('setDepartmentCancelReasonsByCategoryId', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getNotesByAssignment({ commit, rootState }, params: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` + '/notes/NotesByAssignment?noteTypeId=' +
                        params.NoteTypeId + '&relationId=' + params.RelationId + '&relationIdValue=' +
                        params.RelationIdValue + '&departmentId=' + params.DepartmentId + 
                        '&tenantId=' + params.TenantId + '&costCenterId=' + params.CostCenterId + 
                        '&startDateTime=' + params.StartDateTime + '&endDateTime=' + params.EndDateTime;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setNotesByAssignment', res.data.data.results);
                }
                else{
                    commit('setNotesByAssignment', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getNotesByDepartment({ commit, rootState }, params: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` + '/notes/NotesByDepartment?noteTypeId=' +
                        params.NoteTypeId + '&departmentId=' + params.DepartmentId + 
                        '&tenantId=' + params.TenantId + '&FacilityId=' + params.CostCenterId + 
                        '&startDateTime=' + params.StartDateTime + '&endDateTime=' + params.EndDateTime;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setNotesByDepartment', res.data.data.results);
                }
                else{
                    commit('setNotesByDepartment', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getNotesByEntity({ commit, rootState }, params: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` + '/notes/NotesByEntity?noteTypeId=' +
                        params.NoteTypeId + '&relationId=' + params.RelationId +'&departmentId=' + params.DepartmentId + 
                        '&tenantId=' + params.TenantId + '&FacilityId=' + params.CostCenterId + 
                        '&relationIdValue=' + params.RelationIdValue;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setNotesByEntity', res.data.data.results);
                }
                else{
                    commit('setNotesByEntity', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getCostCenter({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}` + '/notes/CostCentersByFacilityId?tenantId=' + inputs.TenantId + '&facilityId=' + inputs.CostCenterId;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setCostCenter', res.data.data.results);
                }
                else{
                    commit('setCostCenter', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDepartmentShifts({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DepartmentShifts/active/${inputs.departmentId}/${inputs.date}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setDepartmentShifts', res.data.data.results);
                }
                else{
                    commit('setDepartmentShifts', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getPTOBalance({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/ptotransactions/${inputs.staffId}/${inputs.startDate}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setPTOBalance', data);
                }
                else{
                    commit('setPTOBalance', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getClockInData({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/scheduleclockinstatus/${inputs.departmentId}/${inputs.startDateTime}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    const clockIndata = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setClockInData', clockIndata);
                }
                else{
                    commit('setClockInData', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getContractLaborRequests({ commit, rootState }, inputs: any) {        
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts?FacilityId=${inputs.FacilityId}&DepartmentId=${inputs.DepartmentId}&StatusIds=${inputs.StatusIds}&StartDate=${inputs.StartDate}&EndDate=${inputs.EndDate}`;
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setContractLaborRequests', res.data.data.results);
                }
                else{
                    commit('setContractLaborRequests', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    cancelContractLaborRequests({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts/${inputs.contractLaborRequestId}/cancel`;

        return http
            .put(apiUrl, inputs.defaultCancelReasonId)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setCancelledRequest', res.data.data.results);
                }
                else{
                    commit('setCancelledRequest', null);
                }
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    createContractLaborRequests({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setCreatedContractLaborRequest', res.data.data.results);
                }
                else{
                    commit('setCreatedContractLaborRequest', null);
                }
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    createContactLaborBulkRequest({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts/bulk`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setCreatedContactLaborBulkRequest', res.data.data.results);
                }
                else{
                    commit('setCreatedContactLaborBulkRequest', null);
                }
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    cancelContractLbrRequests({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts/cancel`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setCancelContractLaborRequest', res.data.data.results);
                }
                else{
                    commit('setCancelContractLaborRequest', null);
                }
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    postNeedNote({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/NeedNotes`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                //return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    postNote({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/notes`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                //return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    getDepartmentContractLaborNeedAutomationStatus({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Departments/GetDepartmentCLAutomationStatus?deptId=${inputs.departmentId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '')){
                    commit('setDeptCLNeedAutomationStatus', res.data);
                }
                else{
                    commit('setDeptCLNeedAutomationStatus', false);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    setCareAssignmentSheetData({ commit, rootState }, inputs: any){
        if(inputs != null){
            commit('setCareAssignmentData', inputs);
        }
        else{
            commit('setCareAssignmentData', null);
        }      
    }
}

// mutations
const mutations: MutationTree<State> = {
    setStaffingGrid(state, result: any){
        state.staffingGrid = result;
    },
    setPatientClassificationRange(state, result: any){
        state.patientClassification = result;
    },
    setStaffSearch(state, result: any){
        state.staffSearch = result;
    },
    setPriorDaySetting(state, result: any){
        state.priorDaySettings = result;
    },
    setDepartmentCancelReasons(state, result: any){
        state.deptCancelReasons = result;
    },
    setNotesByAssignment(state, result: any){
        state.notesByAssignment = result;
    },
    setNotesByDepartment(state, result: any){
        state.notesByDept = result;
    },
    setNotesByEntity(state, result: any){
        state.notesByEntity = result;
    },
    setCostCenter(state, result: any){
        state.costCenter = result;
    },
    setDepartmentShifts(state, result: any){
        state.departmentShifts = result;
    },
    setDepartmentCancelReasonsByCategoryId(state, result: any){
        state.deptCancelReasonsByCategoryId = result;
    },
    setPTOBalance(state, result: any){
        state.PTOBalance = result;
    },
    setClockInData(state, result: any){
        state.clockInData = result;
    },
    setContractLaborRequests(state, result: any){
        state.contractLaborRequests = result;
    },
    setCancelledRequest(state, result: any){
        state.cancelledRequest = result;
    },
    setCreatedContractLaborRequest(state, result: any){
        state.createdcontractLaborRequest = result;
    },
    setCancelContractLaborRequest(state, result: any){
        state.cancelledcontractLaborRequest = result;
    },
    setCreatedContactLaborBulkRequest(state, result: any){
        state.createdcontractLaborBulkRequest = result;
    },
    setDeptCLNeedAutomationStatus(state, result: any){
        state.deptCLNeedAutomationStatus = result;
    },
    setCareAssignmentData(state, result: any){
        state.careAssignmentData = result;
    },
}

export const actionable: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

