<template>
    <div id="contractLaborRequestMultiDayContent">
        <div id="multiday-content-loading" class="loadingDiv1" 
            style="padding: 5px;" v-if="isShowContractLaborLoader">
            <div style="text-align: center">
                <div class="fa-2x">
                    <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                </div>
                <p><b>Processing...</b></p>
            </div>
        </div>
        <div id="contractLaborRequestSingleDayView" class="txtLeft">
            <div class="row rowMultiDay mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">facility</label>
                    <select class="neu-select" name="facility" id="multiDaySearchFacility"
                            data-role="dropdownlist"
                            v-model="selectedFacility" 
                            :class="[isFacilityError ? 'hasError': '']"
                            @change="onFacilityChange"> 
                        <option v-for="fac in facilities" :value="fac.Value" :key="fac.Value">
                            {{fac.Text}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isFacilityError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">department</label>
                     <select class="neu-select" name="department" id="multiDaySearchDepartment"
                            data-role="dropdownlist"
                            v-model="selectedDepartment" 
                            :class="[isDepartmentError ? 'hasError': '']"
                            @change="onDepartmentChange"> 
                        <option v-for="dept in departments" :value="dept.Value" :key="dept.Value">
                            {{dept.Text}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isDepartmentError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">date</label>
                    <Datepicker v-model="requestDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                    :enableTimePicker="false" :class="[isRequestDateError ? 'hasError': '']" autoApply ></Datepicker>
                    <neu-icon v-if="isRequestDateError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">shift</label>
                    <select class="neu-select" name="shift" id="multiDaySearchShift"
                            data-role="dropdownlist"
                            :class="[isShiftError ? 'hasError': '']"
                            v-model="selectedShift" >
                        <option v-for="shft in shifts" :value="shft.deptShiftId" :key="shft.deptShiftId">
                            {{shft.deptShiftDescription}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isShiftError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-2">
                    <label class="text-uppercase default-font-size">skill</label>
                    <select class="neu-select" name="skill" id="multiDaySearchSkill"
                            data-role="dropdownlist"
                            :class="[isSkillError ? 'hasError': '']"
                            v-model="selectedSkill">
                        <option v-for="skl in skills" :value="skl.skillLevelId" :key="skl.skillLevelId">
                            {{skl.description}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isSkillError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
            </div>
            <div class="row rowMultiDay mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">specialization</label>
                    <select class="neu-select" name="skillSpecialization" id="multiDaySearchSpecialization"
                            data-role="dropdownlist" :class="[isSpecializationError ? 'hasError': '']"
                            v-model="selectedSkillSpecialization" >
                        <option v-for="fac in skillSpecializations" :value="fac.wfFoldergroupID" :key="fac.wfFoldergroupID">
                            {{fac.wfFolderGroupName}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    <neu-icon v-if="isSpecializationError" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">number of resources</label>
                    <neu-input id="resource-count" v-model="resourceCount" :class="[isResourcesCountError ? 'hasError': '']" ></neu-input>
                    <span v-if="isResourcesCountError" class="requiredControl material-icons" aria-hidden="true">error</span>
                </div>
                <div class="col-4">
                    <label class="text-uppercase default-font-size">notes</label>
                    <neu-input id="notes" v-model="notes" ></neu-input>
                </div>
                <div class="col-2 search-wrapper" style="padding-top:20px">
                    <neu-button type="button" fill="raised" id="WFRequestStatusSearch" :disabled="isDisableCreateRequest"
                        class="float-end me-1" @click="isDisableCreateRequest ? null : onAddRequestControls()">
                        ADD REQUEST
                    </neu-button>
                </div>
            </div>
        </div>

        <div class="row rowMultiDay">
            <div class="col-12">
                <div class="assignment-list ms-1 me-1" > <!-- v-show="showList" -->
                    <neu-tablist :value="selectedTab" class="mx-4 tabList txtLeft">
                        <neu-tab
                            white
                            tab="RequestConfirmationTab"  
                            @click="setSelectedTab('RequestConfirmationTab')">
                            <div>Request </div>
                        </neu-tab>
                        <neu-tab
                            white
                            tab="ActivityLogTab"
                            @click="setSelectedTab('ActivityLogTab')">
                            <div>Activity Log </div>
                        </neu-tab>
                         <neu-tab
                            white
                            tab="ManageContractTab"
                            @click="setSelectedTab('ManageContractTab')">
                            <div>Manage Contract </div>
                        </neu-tab> 
                    </neu-tablist> 
                    <div id="multiDayRequestConfirmationTabContent" class="panelTabData scrollBar" v-if="isRequestConfirmationVisible" role="tabpanel">
                        <div class="row multiDayNoRequestHeading" v-show="showNoRequestHeading">
                            <div class="col d-flex justify-content-center mt-5">
                                <h4>No requests submitted. Please request one above.</h4>
                            </div>
                        </div>

                        <div class="row multiDayRequestConfirmationSection" v-show="showRequestConfirmation">
                            <neu-card color="plain-0" class="p-0 m-0" >
                                <neu-table>
                                    <neu-table-row :columns="createdRequestsColumnHeaders" header>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-table-heading v-for="column in Object.keys(createdRequestsColumnHeaders)" :key="column" :icon="createdRequestsColumnIcons[column]" :slot="column"
                                            style="padding-top: 25px !important;">
                                        {{ column }}
                                        </neu-table-heading>
                                    </neu-table-row>
                                    <neu-table-body align="left">
                                        <template v-if="createdContractLaborShiftRequest != null && createdContractLaborShiftRequest.length > 0">
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-table-row :columns="createdRequestsColumnHeaders" v-for="createdContractLbr in createdContractLaborShiftRequest" :key="createdContractLbr" :slot="column">
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Facility" :title="createdContractLbr.requests[0].facilityName">{{ createdContractLbr.requests[0].facilityName }}</neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Dept" :title="createdContractLbr.requests[0].fsDeptDescription">{{ createdContractLbr.requests[0].fsDeptDescription }}</neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Date">{{ setCreatedContractLbrGridDate(createdContractLbr.requests[0].date) }}</neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Shift" :title="setCreatedContractLbrGridShiftDescription(createdContractLbr.requests[0])" >
                                                    {{ setCreatedContractLbrGridShiftDescription(createdContractLbr.requests[0]) }}
                                                </neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Skill" :title="createdContractLbr.requests[0].fsSkillLevelDescription">
                                                    {{ createdContractLbr.requests[0].fsSkillLevelDescription }}
                                                </neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Specialization" :title="createdContractLbr.requests[0].wfFolderGroupName">
                                                    {{ createdContractLbr.requests[0].wfFolderGroupName }}
                                                </neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Resource" :title="createdContractLbr.requests.length">
                                                    {{ createdContractLbr.requests.length }}
                                                </neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Notes" :title="createdContractLbr.requests[0].notes">
                                                    {{ createdContractLbr.requests[0].notes }}
                                                </neu-label>
                                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                                <neu-label slot="Action">
                                                    <neu-button type="button" fill="raised" id="multiDayCancelRequestbutton" 
                                                        @click="cancelRequests(createdContractLbr.requestId)">
                                                        CANCEL
                                                    </neu-button>
                                                </neu-label>
                                            </neu-table-row>
                                        </template>
                                        <template v-else>
                                            <p id="period-message-text">No data available on current page.</p>
                                        </template>
                                    </neu-table-body>
                                </neu-table>
                            </neu-card>
                        </div>

                        <div class="multiday-request-controls-form mt-2" v-show="showRequestForm">

                            <div class="row">
                                <div class="form-group col-3">FACILITY</div>
                                <div class="form-group col-3">DEPT.</div>
                                <div class="form-group col-2">DATE</div>
                                <div class="form-group col-2">SHIFT</div>
                                <div class="form-group col-2">SKILL</div>
                                <!-- <div class="form-group col-3">SKILL & SPECIALIZATION</div>
                                <div class="form-group col-1">RESOURCES</div> -->
                            </div>

                            <div id="view" data-role="list-view" data-bind="source: contractRequestControls">
                                <div class="multiday-request-controls" v-for="controls in contractRequestControls" :key="controls.Id" >
                                    <div class="row">
                                        <div class="form-group col-3">
                                            <select class="neu-select" name="facility" id="multiDayControls-facility"
                                                    data-role="dropdownlist"
                                                    v-model="controls.SelectedFacility" 
                                                    :class="[controls.isFacilityError ? 'hasError': '']"
                                                    @change="onControlsFacilityChange(controls.Id)"> 
                                                <option v-for="fac in controls.Facilities" :value="fac.Value" :key="fac.Value">
                                                    {{fac.Text}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max" style="top: 0.4rem !important;">arrow_drop_down</neu-icon>
                                            <neu-icon v-if="controls.isFacilityError" class="requiredControl material-icons" style="top: 0.5rem !important;" aria-hidden="true">error</neu-icon>
                                        </div>
                                        <div class="form-group col-3">
                                            <select class="neu-select" name="department" id="multiDayControls-department"
                                                    data-role="dropdownlist"
                                                    v-model="controls.SelectedDepartment" 
                                                    :class="[controls.isDepartmentError ? 'hasError': '']"
                                                    @change="onControlsDepartmentChange(controls.Id)"> 
                                                <option v-for="dept in controls.Departments" :value="dept.Value" :key="dept.Value">
                                                    {{dept.Text}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max" style="top: 0.4rem !important;">arrow_drop_down</neu-icon>
                                            <neu-icon v-if="controls.isDepartmentError" class="requiredControl material-icons" style="top: 0.5rem !important;" aria-hidden="true">error</neu-icon>
                                        </div>
                                        <div class="form-group col-2">
                                            <Datepicker v-model="controls.RequestDate" weekStart="0" inputClassName="dp-custom-input" class="form-control multiDayControls-requestDate" style="width: 100%;"
                                                :enableTimePicker="false" :class="[controls.isRequestDateError ? 'hasError': '']"
                                                @update:modelValue="onControlsRequestDateChange(modelData, controls.Id)" autoApply ></Datepicker>
                                            <neu-icon v-if="controls.isRequestDateError" class="requiredControl material-icons" aria-hidden="true" style="top: 0.5rem !important;">error</neu-icon>
                                        </div>
                                        <div class="form-group col-2" data-bind="attr:{data-id:uid}">
                                            <select class="neu-select" name="shift" id="multiDayControls-departmentShifts"
                                                    data-role="dropdownlist" :class="[controls.isShiftError ? 'hasError': '']"
                                                    v-model="controls.SelectedShift" >
                                                <option v-for="shift in controls.Shifts" :value="shift.deptShiftId" :key="shift.deptShiftId">
                                                    {{shift.deptShiftDescription}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max" style="top: 0.4rem !important;">arrow_drop_down</neu-icon>
                                            <neu-icon v-if="controls.isShiftError" class="requiredControl material-icons" aria-hidden="true" style="top: 0.5rem !important;">error</neu-icon>
                                        </div>
                                        <div class="form-group col-2" data-bind="attr:{data-id:uid}">
                                            <select class="neu-select" name="skill" id="multiDayControls-skills"
                                                    data-role="dropdownlist" :class="[controls.isSkillError ? 'hasError': '']"
                                                    v-model="controls.SelectedSkill">
                                                <option v-for="skill in controls.Skills" :value="skill.skillLevelId" :key="skill.skillLevelId">
                                                    {{skill.description}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max" style="top: 0.4rem !important;">arrow_drop_down</neu-icon>
                                            <neu-icon v-if="controls.isSkillError" class="requiredControl material-icons" aria-hidden="true" style="top: 0.5rem !important;">error</neu-icon>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="form-group col-3" data-bind="attr:{data-id:uid}">
                                            <select class="neu-select" name="skillSpecialization" id="multiDayControls-skillSpecialization"
                                                    data-role="dropdownlist" :class="[controls.isSpecializationError ? 'hasError': '']"
                                                    v-model="controls.SelectedSkillSpecialization" >
                                                <option v-for="fac in controls.SkillSpecializations" :value="fac.wfFoldergroupID" :key="fac.wfFoldergroupID">
                                                    {{fac.wfFolderGroupName}}
                                                </option>    
                                            </select>
                                            <neu-icon class="material-icons neu-input__select-icon z-max" style="top: 0.4rem !important;">arrow_drop_down</neu-icon>
                                            <neu-icon v-if="controls.isSpecializationError" class="requiredControl material-icons" style="top: 0.5rem !important;" aria-hidden="true">error</neu-icon>
                                        </div>
                                        <div class="form-group col-2">
                                            <neu-input id="multiDayControls-ResourceCount" v-model="controls.ResourceCount" :class="[controls.isResourcesCountError ? 'hasError': '']" ></neu-input>
                                            <neu-icon v-if="controls.isResourcesCountError" class="requiredControl material-icons" style="left: 8.2rem !important; top: 0.5rem !important;" aria-hidden="true">error</neu-icon>
                                        </div>
                                        <div class="form-group col-5">
                                            <neu-input id="multiDayControls-notes" v-model="controls.note" ></neu-input>
                                        </div>
                                        <div class="form-group col-2">
                                            <button type="button" class="btn btn-link multiday-remove-request-btn float-right tmptRemoveBtn" style="padding-top:10px" @click="onRemoveRequestlnk(controls.Id)">
                                                <font-awesome-icon :icon="['far', 'trash-alt']" size="sm" class="mr-1" /> Remove
                                            </button>
                                            <button type="button" class="btn btn-link multiday-copy-request-btn float-right tmptCopyBtn" style="padding-top:10px" @click="onCopyRequestlnk(controls.Id)">
                                                <font-awesome-icon :icon="['far', 'copy']" size="sm" class="mr-1" /> Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col txtLeft">
                                    &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex justify-content-center mt-5">
                                    <button type="button" class="btn btn-primary text-uppercase multi-day-submit-request-btn" @click="createContractLaborRequest">Submit Request</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="multiDayMyRecentActivityTabContent" class="panelTabData scrollBar" v-if="isActivityLogVisible" role="tabpanel">
                        <neu-card color="plain-0" class="p-0 m-0" >
                            <neu-table>
                                <neu-table-row :columns="columnHeaders" header>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column"
                                        style="padding-top: 25px !important;">
                                    {{ column }}
                                    </neu-table-heading>
                                </neu-table-row>
                                <neu-table-body align="left">
                                    <template v-if="activityLogGridData != null && activityLogGridData.length > 0">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-table-row :columns="columnHeaders" v-for="activityLog in activityLogGridData" :key="activityLog" :slot="column">
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Requested At" :title="activityLog.createdDate">{{ activityLog.createdDate }}</neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Action" :title="activityLog.transactionName">{{ activityLog.transactionName }}</neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Facility" :title="activityLog.facilityName">{{ activityLog.facilityName }}</neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Department" :title="activityLog.departmentName">
                                                {{ activityLog.departmentName }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Skill" :title="activityLog.skill">
                                                 {{ activityLog.skill }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Requested For" :title="activityLog.requestedForDate">
                                                 {{ activityLog.requestedForDate }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="User" :title="activityLog.userInfo">
                                                 {{ activityLog.userInfo }}
                                            </neu-label>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <neu-label slot="Details" :title="activityLog.details">
                                                {{ activityLog.details }}
                                            </neu-label>
                                        </neu-table-row>
                                    </template>
                                    <template v-else>
                                        <p id="period-message-text">No data available on current page.</p>
                                    </template>
                                </neu-table-body>
                            </neu-table>
                        </neu-card>
                        <neu-container fluid="" v-if="activityLogGridData != null && activityLogGridData.length > 0">
                            <neu-row>
                                <neu-col md="6" cols="12" class="neu-text-center neu-text-md-left">
                                    <!-- eslint-disable-next-line vue/no-parsing-error  -->
                                    <neu-paginator class="justify-content-start" @v-neu-change="(ev: CustomEvent) => this.pageNum = ev.detail.pageNumber"
                                        :total="activityLogGridData != null && activityLogGridData.length"
                                        :rowsPerPage="rowsPerPage"
                                        >
                                    </neu-paginator>
                                </neu-col>
                                <neu-col md="6" cols="12" class="neu-text-center neu-text-md-right">
                                    <neu-table-row-count
                                        class="mt-3"
                                        section="number"
                                        :total="activityLogGridData != null && activityLogGridData.length"
                                        :page-number="pageNum"
                                        @v-neu-change="rowCountChange"
                                    >
                                    </neu-table-row-count>
                                    
                                </neu-col>
                            </neu-row>
                        </neu-container>
                    </div>
                    <div id="multiDayManageContractTabContent" class="panelTabData scrollBar" v-if="isManageContractVisible" role="tabpanel">
                        <br />
                        <div class="col">
                            <a id="manage-contract-labor-lnk" class="mr-1" :href="contractLaborLink" target="_blank">
                                Manage Contract Labor</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum
import { ContractLaborModel } from '@/models/contractLaborManagementModal/index';

//Import Componenets
import {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuTab,
    NeuTablist
} from "@neutron/vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ToasterPopup from '../shared/ToasterPopup.vue';

class Props {
    multiDayInput!: any 
}

@Options({
  computed: {
    ...mapState('actionable', ['departmentShifts', 'createdcontractLaborBulkRequest', 'cancelledcontractLaborRequest', 'deptCLNeedAutomationStatus']),
    ...mapState('shared', ['departmentSkillList', 'skillSpecialization']),
    ...mapState('dashboard', ['recentActivityLogs']),
    ...mapState('profile', ['profileData'])
  },
  components: {
    NeuTab,
    NeuTablist,
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    Datepicker,
    ToasterPopup
  }
}) 
export default class MultiDay extends Vue.with(Props) {
    //Data properties
    departmentSkillList!: any;
    skillSpecialization!: any;
    departmentShifts!: any;
    recentActivityLogs!: any;
    profileData!: any;
    createdcontractLaborBulkRequest!: any;
    cancelledcontractLaborRequest!: any;
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    
    selectedTab: string = 'RequestConfirmationTab';
    isRequestConfirmationVisible: boolean = true; 
    isActivityLogVisible: boolean = false;
    isManageContractVisible: boolean = false;

    facilities: any = [];
    selectedFacility: any = null;
    allDepartments: any = [];
    departments: any = [];
    selectedDepartment: any = null;
    selectedDate: any = null;
    requestDate: Date = new Date();
    shifts: any = [];
    selectedShift: any = null;
    skills: any = [];
    selectedSkill: any = null;
    skillSpecializations: any = [];
    selectedSkillSpecialization: any = null;
    resourceCount: number = 1;
    notes: string = '';
    createdRequests: any = [];
    requestLogs: any = [];
    isShowContractLaborLoader: boolean = false;
    contractLaborLink: string = '';
    isShowSingleDayNoRequestHeading: boolean = false;
    isShowSingleDayRequestConfirmarion: boolean = false;
    activityLogGridData: any = null;
    showNoRequestHeading: boolean = true;
    showRequestConfirmation: boolean = false;
    showRequestForm: boolean = false;
    isDisableCreateRequest: boolean = false;
    deptCLNeedAutomationStatus!: any;
    isContractLaborNeedAutomationEnabled: boolean = false;

    contractLaborCtrlUId: number = 0;
    contractRequestControls: ContractLaborModel[] = [];
    pageNum: number = 1;
    rowsPerPage: number = 10;
    first: number = 1;
    last: number = 10;
    createdContractLaborShiftRequest: any = [];

    defaults: any = {
        productiveShift: 1,
        administrativeCancel: 5,
        errorMessages: {
            createRequestsFailMessage: "Unable to process your contract labor request.",
            createRequestSuccessMessage: "Your request has been submitted.",
            validationShiftAlreadyStartedMessage: "Shift has already started. Please select a valid future shift",
            validationMissingInfomationMessage: "Please select missing information",
            loadActivityLogFailMessage: "Unable to load activity logs.",
            cancelRequestFailMessage: "Unable to process your cancellation request. ",
            cancelRequestSuccessMessage: "Your cancellation request has been submitted."
        },
        serverSideDateFormat: 'YYYY-MM-DDTHH:mm:ss',
        maxNumberOfResources: 999,
        maxNumberOfResourcesWhenKafkaIsOff: 10,
        maxNumberOfTransactionsAllowed:10
    };

    columnHeaders: any ={ 
        "Requested At": "17%", 
        Action: "8%", 
        Facility: "15%",
        Department: "15%", 
        Skill: "8%", 
        "Requested For": "13%", 
        User: "12%", 
        Details: "12%", 
    };
    columnIcons: any = {    
        "Requested At": "none", 
        Action: "none", 
        Facility: "none",
        Department: "none", 
        Skill: "none", 
        "Requested For": "none", 
        User: "none", 
        Details: "none",
    };

    createdRequestsColumnHeaders: any ={ 
        Facility: "13%", 
        Dept: "13%", 
        Date: "9%",
        Shift: "13%", 
        Skill: "8%", 
        Specialization: "12%", 
        Resource: "10%", 
        Notes: "10%", 
        Action: "12%"
    };

    createdRequestsColumnIcons: any = {    
        Facility: "none", 
        Dept: "none",  
        Date: "none", 
        Shift: "none",  
        Skill: "none", 
        Specialization: "none", 
        Resource: "none",  
        Notes: "none",  
        Action: "none" 
    };

    //Validation Flag
    isFacilityError: boolean = false;
    isDepartmentError: boolean = false;
    isShiftError: boolean = false;
    isSkillError: boolean = false;
    isSpecializationError: boolean = false;
    isResourcesCountError: boolean = false;
    isRequestDateError: boolean = false;

    //Lifecycle hooks
    async mounted(){
        await this.loadSingleDay();
    }

    //Methods
    async loadSingleDay(){
        await this.loadFacilitiesAndDepartments();
        this.contractLaborLink = this.profileData.AppUrl + 'Requests/ContractLaborRequest.aspx';
        this.refreshMyRecentActityGrid();
    }

    async onFacilityChange(){
        await this.loadDepartmentByFacility();
    }

    async onDepartmentChange(){
        //check for department atomation enabled
        await this.loadDeptCLNeedAutomationStatus();
        await this.loadShiftAndSkillAndSpecializations();
    }

    async loadDeptCLNeedAutomationStatus() {
        if (this.selectedDepartment != null) {
            var inputData = {
                departmentId: this.selectedDepartment
            }
            await this.$store
                .dispatch("actionable/getDepartmentContractLaborNeedAutomationStatus", inputData)
                .then(() => {
                    if (this.deptCLNeedAutomationStatus != null) {
                        this.isContractLaborNeedAutomationEnabled = this.deptCLNeedAutomationStatus;
                        this.isDisableCreateRequest = this.deptCLNeedAutomationStatus;
                        if (this.deptCLNeedAutomationStatus) {
                            var errorData = {
                                message: "Automation has been enabled for this Department. Manual submissions are not allowed at this time.",
                                flag: "error"
                            }
                            this.showToasterPopup(errorData);
                        }
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Contract Labor Automation Status.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

    }

    async loadFacilitiesAndDepartments(){

        this.facilities = this.multiDayInput.facilities;
        this.selectedFacility = this.multiDayInput.facilities[0].Value;
        this.allDepartments = this.multiDayInput.departments;

        await this.loadDepartmentByFacility();

    }

    async loadDepartmentByFacility() {
        
        var facility = this.selectedFacility;
        var allDepartments = this.allDepartments;

        //@ts-ignore
        var facilityDepartments: any = jslinq(allDepartments)
                .where((item: any) => {
                    if (item.FacilityId === +facility && item.AllowManualClRequests === true) {
                    return item;
                    }
                })
            .toList();

        if (facilityDepartments.length > 0) {
            this.departments = facilityDepartments;
            this.selectedDepartment = facilityDepartments[0].Value;

        }
        else {
            this.departments = [];
            this.selectedDepartment = null;
              var errorData = {
                message: "No departments are available in this facility to send requests.",
                flag: "warning"
            }
            this.showToasterPopup(errorData); 
        }

        await this.loadDeptCLNeedAutomationStatus();
        await this.loadShiftAndSkillAndSpecializations();

    }

    async loadShiftAndSkillAndSpecializations(){
        var selectedDepartment = this.selectedDepartment;

        if(selectedDepartment != null){
            this.isShowContractLaborLoader = true;
            var today = moment().format('DD-MMM-YYYY');

            //@ts-ignore
            var facilityCoid: any = jslinq(this.facilities)
                .where((item: any) => {
                    if(item.FacilityId === this.selectedFacility){
                    return item;
                    }
                })
                .toList();

            //@ts-ignore
            var deptCodes: any = jslinq(this.departments)
                .where((item: any) => {
                    if(item.Value === this.selectedDepartment){
                    return item;
                    }
                })
                .toList();

            await this.loadDepartmentShifts(selectedDepartment, today);
            await this.loadSkills(selectedDepartment, today);
            await this.loadSkillSpecializations(facilityCoid[0].Coid, deptCodes[0].Value);
            this.isShowContractLaborLoader = false;
        }
    }

    async loadDepartmentShifts(deptId: any, date: any){
        var inputData = {
            departmentId: deptId,
            date: moment(date).format('YYYY-MM-DD')
        }
        await this.$store
                .dispatch("actionable/getDepartmentShifts", inputData)
                .then(() => {
                    if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                        var productiveShifts = this.filterShiftsByType(JSON.parse(JSON.stringify(this.departmentShifts)));
                        this.shifts = productiveShifts; 
                        this.selectedShift = productiveShifts[0].deptShiftId                   
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        this.isShowContractLaborLoader = false;
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    filterShiftsByType(allShifts: any) {
        //@ts-ignore
        var filteredShifts = jslinq(allShifts)
                  .where((shift: any) => {
                      if(shift.productiveId === 1){
                        return shift;
                      }
                  })
              .toList();

        var updatedShifts = filteredShifts.map((item: any) => {
            item.shiftText = item.code.trim() + ' ' + moment(item.startTime).format('h:mm A') + ' to ' + moment(item.endTime).format('h:mm A');
            return item;
        });

        return updatedShifts;
    }

    async loadSkills(deptId: any, date: any){
        var input = {
            deptId: deptId,
            date: date,
            tenantId: this.profileData.TenantId
        }

        await this.$store
            .dispatch("shared/getDepartmentSkills", input)
            .then(() => {
                if (this.departmentSkillList != null && this.departmentSkillList.length > 0) {
                    var skills: any = this.sortBy(JSON.parse(JSON.stringify(this.departmentSkillList)), 'description', 'asc');
                    this.skills = skills; 
                    this.selectedSkill = skills[0].skillLevelId       
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving Department Skills.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationActionable', errorData);
                    this.isShowContractLaborLoader = false;
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    async loadSkillSpecializations(FacilityCoid: any, DepartmentId: any){
        var inputData = {
            FacilityCoid: FacilityCoid,
            DepartmentId: DepartmentId
        }
        await this.$store
                .dispatch("shared/getSkillSpecializations", inputData)
                .then(() => {
                    if (this.skillSpecialization != null && this.skillSpecialization.length > 0) {
                        var flags = [];
                        var distinctSkillSpecializations = [];
                        for (var index = 0; index < this.skillSpecialization.length; ++index) {
                            if (!flags[this.skillSpecialization[index].wfFoldergroupID]) {
                                flags[this.skillSpecialization[index].wfFoldergroupID] = true;
                                distinctSkillSpecializations.push(this.skillSpecialization[index]);
                            }
                        }
                        this.skillSpecializations = distinctSkillSpecializations; 
                        this.selectedSkillSpecialization =  this.skillSpecialization[0].wfFoldergroupID;                  
                    }
                    else {                               
                       var errorData = {
                            message: "No specialization available for this department.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData); 
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Skill Specialization.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        this.isShowContractLaborLoader = false;
                        console.error(err); // Handle errors any way you want
                    }
                });
    }
    setSelectedTab(tab: string){
        this.selectedTab = tab;
        this.isRequestConfirmationVisible = false; 
        this.isActivityLogVisible = false;
        this.isManageContractVisible = false; 
        
        switch (this.selectedTab) {
            case 'RequestConfirmationTab':    
                this.isRequestConfirmationVisible = true;
                break;
            case 'ActivityLogTab':
                this.isActivityLogVisible = true;
                break;
            case 'ManageContractTab':
                this.isManageContractVisible = true;
                break;
        }
    }
    
    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }
    
    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    async refreshMyRecentActityGrid() {
        var createDateFrom = moment().subtract(24, 'hours').utc().format(this.defaults.serverSideDateFormat);
        var createDateTo = moment().add(5,'minutes').utc().format(this.defaults.serverSideDateFormat);

        var request = {
            FsUserId: this.multiDayInput.userId,
            FromCreatedOnDateUtc: createDateFrom,
            ToCreatedOnDateUtc: createDateTo,
            TenantId: this.profileData.TenantId
        };

        await this.loadRecentActivityLogGrid(request);     

    }

    async loadRecentActivityLogGrid(request: any){
        await this.$store
                .dispatch("dashboard/getRecentActivityLogs", request)
                .then(() => {
                    if(this.recentActivityLogs != null && this.recentActivityLogs.length > 0){
                        this.activityLogGridData = this.recentActivityLogs;
                        this.activityLogGridData = this.activityLogGridData.map((activity: any) => {
                            activity.userInfo = activity.fsUserName + ', ' + activity.fsLoginName;
                            activity.createdDate =
                                moment.utc(activity.createdOnUtc).local().format('MM/DD/YYYY hh:mm A');
                            activity.skill = activity.skillDescription;
                            activity.requestedForDate = moment(activity.shiftRequestedForDate).format('MM/DD/YYYY');
                            return activity;
                        });
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: this.defaults.errorMessages.loadActivityLogFailMessage,
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    rowCountChange(ev: CustomEvent) {
      if (ev.detail && ev.detail.first && ev.detail.last) {
        this.first = ev.detail.first;
        this.last = ev.detail.last;
      }
      if (ev.detail && ev.detail.value) this.rowsPerPage = ev.detail.value;
    }

    onAddRequestControls(){
        this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;
        var controlLbrCtrl: ContractLaborModel = {
            Id: this.contractLaborCtrlUId,
            RequestDate: new Date(this.requestDate),
            Facilities: this.facilities,
            SelectedFacility: this.selectedFacility,
            AllDepartments: this.allDepartments,
            Departments: this.departments,
            SelectedDepartment: this.selectedDepartment,
            Shifts: this.shifts,
            SelectedShift: this.selectedShift,
            Skills: this.skills,
            SelectedSkill: this.selectedSkill,
            SkillSpecializations: this.skillSpecializations,
            SelectedSkillSpecialization: this.selectedSkillSpecialization,
            note: this.notes,
            ResourceCount: this.resourceCount,
            isFacilityError: false,
            isDepartmentError: false,
            isShiftError: false,
            isSkillError: false,
            isSpecializationError: false,
            isResourcesCountError: false,
            isRequestDateError: false
        };

        if (!this.validateSearchControls()) {
            return;
        }

        this.contractRequestControls.push(controlLbrCtrl);

        this.showRequestConfirmation = false;
        this.showNoRequestHeading = false;
        this.showRequestForm = true;
    }

    onRemoveRequestlnk(removeBtnId: number) {
        for (var i = 0, len = this.contractRequestControls.length; i < len; i++) {
            if (this.contractRequestControls[i].Id === removeBtnId) {
                this.contractRequestControls.splice(i, 1);
                break;
            }
        }

        if (this.contractRequestControls.length <= 0) {
            this.showRequestForm = false
            this.showNoRequestHeading = true;
        }
    }

    onCopyRequestlnk(copyBtnId: number) {

        var indexToCopy = -1;

        if (this.maxNumberOfRequestReached()) {
            return;
        }
        
        for (var i = 0, len = this.contractRequestControls.length; i < len; i++) {
            if (this.contractRequestControls[i].Id === copyBtnId) {
                indexToCopy = i;
                var currentItem = this.contractRequestControls[i];

                this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;
                var itemToCopy = {
                    Id: this.contractLaborCtrlUId,
                    RequestDate: currentItem.RequestDate,
                    Facilities: currentItem.Facilities,
                    SelectedFacility: currentItem.SelectedFacility,
                    AllDepartments: currentItem.AllDepartments,
                    Departments: currentItem.Departments,
                    SelectedDepartment: currentItem.SelectedDepartment,
                    Shifts: currentItem.Shifts,
                    SelectedShift: currentItem.SelectedShift,
                    Skills: currentItem.Skills,
                    SelectedSkill: currentItem.SelectedSkill,
                    SkillSpecializations: currentItem.SkillSpecializations,
                    SelectedSkillSpecialization: currentItem.SelectedSkillSpecialization,
                    note: currentItem.note,
                    ResourceCount: currentItem.ResourceCount,
                    isFacilityError: false,
                    isDepartmentError: false,
                    isShiftError: false,
                    isSkillError: false,
                    isSpecializationError: false,
                    isResourcesCountError: false,
                    isRequestDateError: false
                };

                this.contractRequestControls.splice(indexToCopy + 1, 0, itemToCopy)
                break;
            }
        }
    }

    maxNumberOfRequestReached() {
        if (this.contractRequestControls.length >= 10) {
            var errorData = {
                message: "Maximum 10 rows of request data allowed",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            
            return true
        }
        return false;
    }

    async createContractLaborRequest(){
        this.removeRequestControlsValidationErrors();

        if (!this.validateForRequiredFields()) {
            var errorData = {
                message: this.defaults.errorMessages.validationMissingInfomationMessage,
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }

        if (!this.validateForInvalidShifts()) {
            var errorData = {
                message: this.defaults.errorMessages.validationShiftAlreadyStartedMessage,
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }

        if (!this.validateResourceCount()) {
            return;
        }

        var requestsToBeCreated = this.prepareRequest();

        if (requestsToBeCreated.length <= 0) {
            return;
        }

        this.isShowContractLaborLoader = true;

        await this.$store
            .dispatch("actionable/createContactLaborBulkRequest", requestsToBeCreated)
            .then(() => {
                if (this.createdcontractLaborBulkRequest != null){
                    var successData = {
                        message: this.defaults.errorMessages.createRequestSuccessMessage,
                        flag: "success"
                    }
                    this.showToasterPopup(successData);

                    this.displayRequestConfirmation(this.createdcontractLaborBulkRequest);
                    this.refreshMyRecentActityGrid();
                    this.isShowContractLaborLoader = false;
                }
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborLoader = false;
                    var errorData = {
                        message: this.defaults.errorMessages.createRequestsFailMessage,
                        flag: "error"
                    }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    prepareRequest() {
        var requestsToBeCreated: any = [];

        if (this.contractRequestControls.length > 0) {

            for (var i = 0; i < this.contractRequestControls.length; i++) {
                var currentRequest = this.contractRequestControls[i];

                //@ts-ignore
                var facility: any = jslinq(this.facilities)
                    .where((item: any) => {
                        if(item.FacilityId === currentRequest.SelectedFacility){
                        return item;
                        }
                    })
                    .toList();

                var contractLaborRequest = {
                    FacilityCoId: facility[0].Coid,
                    DepartmentId: currentRequest.SelectedDepartment,
                    TenantId: this.profileData.TenantId,
                    ShiftRequestDetails: [{
                        Date: moment(currentRequest.RequestDate).format('MM/DD/YYYY'),
                        FolderGroupId: currentRequest.SelectedSkillSpecialization,
                        Note: currentRequest.note,
                        DepartmentShiftId: currentRequest.SelectedShift,
                        fsSkillId: currentRequest.SelectedSkill,
                        requestCount: currentRequest.ResourceCount
                    }]
                };

                requestsToBeCreated.push(contractLaborRequest);
            }
        }
        return requestsToBeCreated;
    }

    displayRequestConfirmation(requests: any) {
        var createdRequests: any = [];

        requests.forEach((requestList: any, indx: any) => {
            createdRequests.push({
                requestId: indx,
                requests: requestList.contractLaborShiftRequests
            })
        });

        this.createdContractLaborShiftRequest = createdRequests;
        this.renderRequestConfirmation();
    }

    renderRequestConfirmation(){
        this.contractRequestControls = [];
        this.showRequestForm = false;

        if(this.createdContractLaborShiftRequest != null && this.createdContractLaborShiftRequest.length >= 1){
            this.showRequestConfirmation = true;
            this.showNoRequestHeading = false;
        }
        else{
            this.showRequestConfirmation = false;
            this.showNoRequestHeading = true;
        }
    }

    setCreatedContractLbrGridDate(date: any){
        return moment(date).format('MM/DD/YYYY');
    }

    setCreatedContractLbrGridShiftDescription(singleRequest: any){
        return singleRequest.shiftCode + ' ' + moment(singleRequest.shiftStartTime).format("h:mm A") + ' to ' + moment(singleRequest.shiftEndTime).format("h:mm A");
    }

    async cancelRequests(requestid: any){
        //@ts-ignore
        var requestsToBeCancelled: any = jslinq(this.createdContractLaborShiftRequest)
                            .where((request: any) => { 
                                if(request.requestId === +requestid){ 
                                    return request;
                                }})
                            .firstOrDefault();
        
        if (requestsToBeCancelled == null || requestsToBeCancelled.requests == null ||
            requestsToBeCancelled.requests.length < 1) {
            return;
        }

        var cancelRequest = {
            CancellationReasonId: this.defaults.administrativeCancel,
            Notes: '',
            ContractLaborShiftIds: requestsToBeCancelled.requests.map((request: any) => {
                return request.contractLaborShiftId;
            })
        };

        this.isShowContractLaborLoader = true;

        await this.$store
            .dispatch("actionable/cancelContractLbrRequests", cancelRequest)
            .then(() => {
                this.removeCancelledRequest(requestid);
                this.refreshMyRecentActityGrid();
                this.isShowContractLaborLoader = false;
            })
            .catch((err: any) => {
                if (err) {
                    this.isShowContractLaborLoader = false;
                    var response = JSON.parse(err.request.response);
                    var erroMsg: any = this.defaults.errorMessages.cancelRequestFailMessage + response.Message;
                    var errorData = {
                        message: erroMsg,
                        flag: "error"
                    }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    removeCancelledRequest(requestId: any) {
        for (var i = 0; i < this.createdContractLaborShiftRequest.length; i++) {
            if (this.createdContractLaborShiftRequest[i].requestId === requestId) {
                this.createdContractLaborShiftRequest.splice(i, 1);
                break;
            }
        }
        this.renderRequestConfirmation();
    }

    addNewRequestLnk(){
        if (this.maxNumberOfRequestReached()) {
            return;
        }

        var facilities = this.multiDayInput.facilities;
        var facility = facilities[0].Value;
        var allDepartments = this.multiDayInput.departments;
        //@ts-ignore
        var facilityDepartments: any = jslinq(allDepartments)
                            .where((department: any) => { 
                                if(department.FacilityId === +facility){ 
                                    return department;
                                }}).toList();
        
        var department = facilityDepartments[0].Value;

        this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;
        var itemToCopy: ContractLaborModel = {
            Id: this.contractLaborCtrlUId,
            Facilities: facilities,
            SelectedFacility: facility,
            AllDepartments: allDepartments,
            Departments: facilityDepartments,
            SelectedDepartment: department,
            RequestDate: new Date(),
            Shifts: null,
            SelectedShift: null,
            Skills: null,
            SelectedSkill: null,
            SkillSpecializations: null,
            SelectedSkillSpecialization: null,
            note: "",
            ResourceCount: 1,
            isFacilityError: false,
            isDepartmentError: false,
            isShiftError: false,
            isSkillError: false,
            isSpecializationError: false,
            isResourcesCountError: false,
            isRequestDateError: false
        };

        this.getSkillShiftAndSpecializationData(itemToCopy, "Add", 0);
    }

    onControlsFacilityChange(Id: number){
        var currentItem: any = null,
            index: number = -1;

        for (var i = 0; i < this.contractRequestControls.length; i++) {
            currentItem = this.contractRequestControls[i];
            index = i;
            if (currentItem.Id === Id) {
                break;
            }
        }

        if (currentItem != null) {            
            //@ts-ignore
            var departments: any = jslinq(currentItem.AllDepartments)
                            .where((department: any) => { 
                                if(department.FacilityId === +currentItem.SelectedFacility){ 
                                    return department;
                                }})
                            .toList();

            this.contractLaborCtrlUId = this.contractLaborCtrlUId + 1;
            var itemToCopy: ContractLaborModel = {
                    Id: this.contractLaborCtrlUId,
                    Facilities: currentItem.Facilities,
                    SelectedFacility: currentItem.SelectedFacility,
                    AllDepartments: currentItem.AllDepartments,
                    Departments: departments,
                    SelectedDepartment: departments[0].Value,
                    RequestDate: currentItem.RequestDate,
                    Shifts: currentItem.Shifts,
                    SelectedShift: currentItem.SelectedShift,
                    Skills: currentItem.Skills,
                    SelectedSkill: currentItem.SelectedSkill,
                    SkillSpecializations: currentItem.SkillSpecializations,
                    SelectedSkillSpecialization: currentItem.SelectedSkillSpecialization,
                    note: currentItem.note,
                    ResourceCount: currentItem.ResourceCount,
                    isFacilityError: false,
                    isDepartmentError: false,
                    isShiftError: false,
                    isSkillError: false,
                    isSpecializationError: false,
                    isResourcesCountError: false,
                    isRequestDateError: false
                };
            
            this.getSkillShiftAndSpecializationData(itemToCopy, "Facility Change", index);
        }
    }

    onControlsDepartmentChange(Id: number){
        var currentItem: any = null,
            index: number = -1;

        for (var i = 0; i < this.contractRequestControls.length; i++) {
            currentItem = this.contractRequestControls[i];
            index = i;
            if (currentItem.Id === Id) {
                break;
            }
        }

        if (currentItem) {
            var itemToCopy: ContractLaborModel = {
                    Id: this.contractLaborCtrlUId,
                    Facilities: currentItem.Facilities,
                    SelectedFacility: currentItem.SelectedFacility,
                    AllDepartments: currentItem.AllDepartments,
                    Departments: currentItem.Departments,
                    SelectedDepartment: currentItem.SelectedDepartment,
                    RequestDate: currentItem.RequestDate,
                    Shifts: null,
                    SelectedShift: null,
                    Skills: null,
                    SelectedSkill: null,
                    SkillSpecializations: null,
                    SelectedSkillSpecialization: null,
                    note: currentItem.note,
                    ResourceCount: currentItem.ResourceCount,
                    isFacilityError: false,
                    isDepartmentError: false,
                    isShiftError: false,
                    isSkillError: false,
                    isSpecializationError: false,
                    isResourcesCountError: false,
                    isRequestDateError: false
                };
            
            this.getSkillShiftAndSpecializationData(itemToCopy, "Department Change", index);
        }
    }

    async onControlsRequestDateChange(modelData: any, Id: number){
        var currentItem: any = null,
            index: number = -1;

        for (var i = 0; i < this.contractRequestControls.length; i++) {
            currentItem = this.contractRequestControls[i];
            index = i;
            if (currentItem.Id === Id) {
                break;
            }
        }

         var requestDate: any = moment(currentItem.RequestDate, 'MM-DD-YYYY');

        if (currentItem) {
            var itemToCopy: ContractLaborModel = {
                    Id: this.contractLaborCtrlUId,
                    Facilities: currentItem.Facilities,
                    SelectedFacility: currentItem.SelectedFacility,
                    AllDepartments: currentItem.AllDepartments,
                    Departments: currentItem.Departments,
                    SelectedDepartment: currentItem.SelectedDepartment,
                    RequestDate: new Date(requestDate),
                    Shifts: null,
                    SelectedShift: null,
                    Skills: currentItem.Skills,
                    SelectedSkill: currentItem.SelectedSkill,
                    SkillSpecializations: currentItem.SkillSpecializations,
                    SelectedSkillSpecialization: currentItem.SelectedSkillSpecialization,
                    note: currentItem.note,
                    ResourceCount: currentItem.ResourceCount,
                    isFacilityError: false,
                    isDepartmentError: false,
                    isShiftError: false,
                    isSkillError: false,
                    isSpecializationError: false,
                    isResourcesCountError: false,
                    isRequestDateError: false
                };

            var inputDeptShift = {
                departmentId: itemToCopy.SelectedDepartment,
                date: moment(requestDate).format('DD-MMM-YYYY')
            }

            this.isShowContractLaborLoader = true;
            await this.$store
                .dispatch("actionable/getDepartmentShifts", inputDeptShift)
                .then(() => {
                    if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                        var productiveShifts = this.filterShiftsByType(JSON.parse(JSON.stringify(this.departmentShifts)));
                        itemToCopy.Shifts = productiveShifts; 
                        itemToCopy.SelectedShift = productiveShifts[0].deptShiftId;
                        
                        this.contractRequestControls.splice(index, 1, itemToCopy);
                        this.isShowContractLaborLoader = false;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        this.isShowContractLaborLoader = false;
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
    }

    getSkillShiftAndSpecializationData(itemToCopy: ContractLaborModel, event: string, index: number){
        var today = moment(itemToCopy.RequestDate).format('DD-MMM-YYYY');
        //@ts-ignore
        var facilityCoid: any = jslinq(itemToCopy.Facilities)
            .where((item: any) => {
                if(item.FacilityId === itemToCopy.SelectedFacility){
                return item;
                }
            })
            .toList();

        //@ts-ignore
        var deptCodes: any = jslinq(itemToCopy.Departments)
            .where((item: any) => {
                if(item.Value === itemToCopy.SelectedDepartment){
                return item;
                }
            })
            .toList();

        var inputDeptShift = {
            departmentId: itemToCopy.SelectedDepartment,
            date: moment(today).format('DD-MMM-YYYY')
        }

        var inputDeptSkill = {
            deptId: itemToCopy.SelectedDepartment,
            date: today
        }

        var inputSkillSpec = {
            FacilityCoid: facilityCoid[0].Coid,
            DepartmentCode: deptCodes[0].DepartmentCode
        }

        this.isShowContractLaborLoader = true;
        Promise.all([
            this.$store.dispatch('actionable/getDepartmentShifts', inputDeptShift),
            this.$store.dispatch('shared/getDepartmentSkills', inputDeptSkill),
            this.$store.dispatch('shared/getSkillSpecializations', inputSkillSpec)
        ])
        .then(() => {
            if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                var productiveShifts = this.filterShiftsByType(JSON.parse(JSON.stringify(this.departmentShifts)));
                itemToCopy.Shifts = productiveShifts; 
                itemToCopy.SelectedShift = productiveShifts[0].deptShiftId                   
            }
						
            if (this.departmentSkillList != null && this.departmentSkillList.length > 0) {
                var skills: any = this.sortBy(JSON.parse(JSON.stringify(this.departmentSkillList)), 'Description', 'asc');
                itemToCopy.Skills = skills; 
                itemToCopy.SelectedSkill = skills[0].skillLevelId       
            }
                            
            if (this.skillSpecialization != null && this.skillSpecialization.length > 0) {
                itemToCopy.SkillSpecializations = JSON.parse(JSON.stringify(this.skillSpecialization)); 
                itemToCopy.SelectedSkillSpecialization =  this.skillSpecialization[0].wfFoldergroupID;                  
            }

            if (event.toLowerCase() == "Add".toLowerCase()){
                this.contractRequestControls.push(itemToCopy);
            }
            else if (event.toLowerCase() == "Facility Change".toLowerCase() || event.toLowerCase() == "Department Change".toLowerCase()){
                this.contractRequestControls.splice(index, 1, itemToCopy);
            }
            this.isShowContractLaborLoader = false;
        }).
        finally(() => {
            this.isShowContractLaborLoader = false;
        });
    }
    
    validateSearchControls(){
        var isFormValid = true;
        
        this.removeValidation();

        if (this.maxNumberOfRequestReached()) {
            isFormValid = false;
            return isFormValid;
        }

        if (!this.selectedFacility == null) {
            this.isFacilityError = true;
            isFormValid = false;
        }

        if (this.selectedDepartment == null) {
            this.isDepartmentError = true;
            isFormValid = false;
        }

        if (this.selectedShift == null) {
            this.isShiftError = true;
            isFormValid = false;
        }

        if (this.selectedSkill == null) {
            this.isSkillError = true;
            isFormValid = false;
        }

        if (this.selectedSkillSpecialization == null) {
            this.isSpecializationError = true;
            isFormValid = false;
        }

        if (this.requestDate == null) {
            this.isRequestDateError = true;
            isFormValid = false;
        }

        if (this.resourceCount == null || this.resourceCount <= 0 || isNaN(this.resourceCount)) {
            this.isRequestDateError = true;
            isFormValid = false;
        }


        if (!isFormValid) {
            var errorData = {
                message: this.defaults.errorMessages.validationMissingInfomationMessage,
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return isFormValid;
        }

        if (isFormValid) {
            //@ts-ignore
            var selectedShiftData: any = jslinq(this.shifts)
                .where((item: any) => {
                    if(item.deptShiftId === this.selectedShift){
                    return item;
                    }
                }).firstOrDefault();

            if (this.isShiftInvalid(this.requestDate, selectedShiftData.startTime)) {
                this.isShiftError = true;
                var errorData = {
                    message: this.defaults.errorMessages.validationShiftAlreadyStartedMessage,
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                isFormValid = false;
                return isFormValid;
            }
        }

        var numberOfRequestToBeCreated = + this.resourceCount + this.getRequestCount();
        var isKafkaDisabled = this.multiDayInput.contractLaborUsingKafkaEnabled === undefined
                || this.multiDayInput.contractLaborUsingKafkaEnabled === false;

        if (isKafkaDisabled && numberOfRequestToBeCreated > this.defaults.maxNumberOfResourcesWhenKafkaIsOff) {
            this.isResourcesCountError = true;
            var errData = {
                message: 'Total number of resources must be between 1 and ' + this.defaults.maxNumberOfResourcesWhenKafkaIsOff,
                flag: "error"
            }
            this.showToasterPopup(errData);
            isFormValid = false;
            return isFormValid;
        }
        
        if (numberOfRequestToBeCreated > this.defaults.maxNumberOfResources) {
            this.isResourcesCountError = true;
            var errData = {
                message: 'Total number of resources must be between 1 and ' + this.defaults.maxNumberOfResources,
                flag: "error"
            }
            this.showToasterPopup(errData);
            isFormValid = false;
            return isFormValid;
        }
                    
        return isFormValid;
    }

    removeValidation(){
        this.isFacilityError = false;
        this.isDepartmentError = false;
        this.isShiftError = false;
        this.isSkillError = false;
        this.isSpecializationError = false;
        this.isResourcesCountError = false;
        this.isRequestDateError = false;
    }

    getRequestCount() {
        var count = 0;
        var requests = this.contractRequestControls;
        for (var i = 0; i < requests.length; i++) {
            if(requests[i].ResourceCount != null){
                //@ts-ignore
                count += +requests[i].ResourceCount;
            }
        }
        return count;
    }

    isShiftInvalid(requestDate: any, shiftStartTime: any) {
        if (requestDate != null && shiftStartTime != null) {
            var now = moment();
            var requestDate: any = moment(requestDate).format('YYYY-MM-DD');
            var startTime: any = moment(shiftStartTime).format('h:mm a');
            var formattedStartTime: any = moment(requestDate + ' ' + startTime, 'YYYY-MM-DD hh:mm A');
            return formattedStartTime.isSameOrBefore(now);
        }
    }

    removeRequestControlsValidationErrors(){
        this.contractRequestControls.forEach((item: ContractLaborModel) => {
            item.isFacilityError = false;
            item.isDepartmentError = false;
            item.isShiftError = false;
            item.isSkillError = false;
            item.isSpecializationError = false;
            item.isResourcesCountError = false;
            item.isRequestDateError = false;
        });
    }

    validateForRequiredFields(): boolean {
        var isValid = true;
        this.contractRequestControls.forEach((request: ContractLaborModel) => {
            var isRequestValid: boolean = this.validateControlRequest(request);
            if (!isRequestValid) {
                isValid = false;
            }
        });
        return isValid;
    }

    validateControlRequest(request: ContractLaborModel): boolean {
        var isRequestValid = true;
            
        if (request.SelectedFacility == null) {
            request.isFacilityError = true;
            isRequestValid = false;
        }

        if (request.SelectedDepartment == null) {
            request.isDepartmentError = true;
            isRequestValid = false;
        }

        if (request.RequestDate == null) {
            request.isRequestDateError = true;
            isRequestValid = false;
        }

        if (request.SelectedShift == null) {
            request.isShiftError = true;
            isRequestValid = false;
        }

        if (request.SelectedSkill == null) {
            request.isSkillError = true;
            isRequestValid = false;
        }

        if (!request.SelectedSkillSpecialization == null) {
            request.isSpecializationError = true;
            isRequestValid = false;
        }

        if (request.ResourceCount == null || request.ResourceCount <= 0 || isNaN(request.ResourceCount)) {
            request.isResourcesCountError = true;
            isRequestValid = false;
        }

        return isRequestValid;
    }

    validateForInvalidShifts(): boolean {
        var isValid = true;
        for (var i = 0; i < this.contractRequestControls.length; i++) {
            var request = this.contractRequestControls[i];

            //@ts-ignore
            var selectedShiftData: any = jslinq(request.Shifts)
                .where((item: any) => {
                    if(item.deptShiftId === request.SelectedShift){
                        return item;
                    }
                }).firstOrDefault();

            if (this.isShiftInvalid(request.RequestDate, selectedShiftData.startTime)) {
                request.isShiftError = true;
                isValid = false;
            }
        }
        return isValid;
    }

    validateResourceCount(): boolean {
        var isValid = true,
            isKafkaDisabled = this.multiDayInput.contractLaborUsingKafkaEnabled === undefined || this.multiDayInput.contractLaborUsingKafkaEnabled === false,
            resourseCountTotal = this.getRequestCount(),
            errorMessage = 'Number of resources must be between 1 and ';

        isKafkaDisabled = false;

        if (!isKafkaDisabled && resourseCountTotal > this.defaults.maxNumberOfResources) {
            var errData = {
                message: errorMessage + this.defaults.maxNumberOfResources,
                flag: "error"
            }
            this.showToasterPopup(errData);

            isValid = false;
            return isValid
        }

        if (isKafkaDisabled && resourseCountTotal > this.defaults.maxNumberOfResourcesWhenKafkaIsOff) {
            isValid = false;

            var errData = {
                message: errorMessage + this.defaults.maxNumberOfResourcesWhenKafkaIsOff,
                flag: "error"
            }
            this.showToasterPopup(errData);
        }

        return isValid;
    }
}
</script>
<style scoped>
.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.125rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.rowMultiDay{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.tmptRemoveBtn{
    padding-top: 10px !important;
    padding: 0;
}

.tmptCopyBtn{
    padding-top: 10px !important;
    padding: 0;
    padding-right: 20px !important;
}

.requiredControl{
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 37px;
    z-index: 2147483647;
}

.hasError{
    border: 2px solid #ce3637 !important;
}
</style>