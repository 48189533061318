export const alphaNumSort = (
  array: Array<Record<string, any>>
): Array<Record<string, any>> => {
  if (!array) return []
  const resultArray: Array<Record<string, any>> = []

  for (let index = 0; index < 10; index++) {
    resultArray.push(
      ...array
        .filter((row) => row?.text?.startsWith(index))
        .sort(
          (a, b) => Number(a.text.split('-')[0]) - Number(b.text.split('-')[0])
        )
    )
  }

  resultArray.push(
    ...array
      .filter(({ text }) => !resultArray.map(({ text }) => text).includes(text))
      .sort()
  )

  return resultArray
}

export const sortAscByKey = (
  array: Array<Record<string, any>>,
  key: string
): Array<Record<string, any>> => {
  if (!array) return [];

  return array.sort((a, b) => {
    if (a[key] < b[key]) { return -1 }
    if (a[key] > b[key]) { return 1 }
    return 0;
  })
}