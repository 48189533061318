<template>
    <div id="plusMetrics-header" class="padding-left-15 line-height-25 ">
        <!-- placeholder for Target/Scheduled display -->
        <neu-row class="row white-background-color">
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-start-padding">
                <span class="Plus"><neu-icon color="primary" 
                        @click="openTrainingDocument"
                        style="cursor: pointer; color:#3673AA;"
                    >info</neu-icon> <b>PLUS(PPD)</b></span>
            </neu-col>
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-end-padding">
                <span class="actionable-plusmetrics" id="actionable-plusmetrics"><a class="actionable-menu-link viewMore" @click="showMetricsView">View More</a></span>
            </neu-col>
        </neu-row>
        <neu-row class="row white-background-color ">
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-start">
                <span class="target census-data" id="pmTarget">Target: </span>
            </neu-col>
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-end">
                <span class="digits plusMetrics text-end">{{TargetHours}}</span>
            </neu-col>
        </neu-row>
        <neu-row class="row white-background-color ">
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-start">
                <span class="actual census-data" id="pmActual">Actual: </span>
            </neu-col>
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-end">
                <span class="digits plusMetrics text-end">{{ActualHours}}</span>
            </neu-col>
        </neu-row>
        <neu-row class="row white-background-color ">
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-start">
                <span class="variance census-data" id="pmVariance">Difference: </span>
            </neu-col>
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-end pl-0" id="variance-details">
                <span v-if="VarianceNegative" class="mr-1">
                    <font-awesome-icon
                                :icon="['fas', 'arrow-circle-down']"
                                size="sm" />
                </span>
                <span v-if="VariancePositive" class="mr-1">
                    <font-awesome-icon
                                :icon="['fas', 'arrow-circle-up']"
                                size="sm" />
                </span>
                <span class="digits plusMetrics text-end">{{VarianceHours}}</span>
            </neu-col>
        </neu-row>
        <neu-row class="row white-background-color ">
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-start">
                <span class="actual census-data" id="pmProdIndex">Prod. Index: </span>
            </neu-col>
            <neu-col xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="text-end">
                <span class="digits plusMetrics text-end">{{ProdIndex}}</span>
            </neu-col>
        </neu-row>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import moment from "moment";
import { Profile } from '@/models/profile/index';
class Props {
    plusMetricsData!: any 
}

@Options({
  computed: {
    ...mapState('shared', ['plusMetrics']),
    ...mapState('profile', ['profileData']),
  }
})  
export default class PlusMetrics extends Vue.with(Props) {
    //Data properties
    plusMetrics!: any;
    longDateTimeFormatPresentation: string = 'MM/DD/YYYY  hh:mm A';
    shortDateFormatPresentation: string = 'MM/DD/YYYY';
    shortTimeFormat: string = 'hh:mm A';
    longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    ActualHours: number = 0;
    TargetHours: number = 0;
    VarianceHours: number = 0;
    VarianceNegative: boolean = false;
    VariancePositive: boolean = false;
    ProdIndex: any = null;
    trainingLinkUrl: string ="";
    profileData!: Profile;
    //Lifecycle hooks
    mounted(){
        this.loadPlusMetricsData();
    }

    //Methods
    async loadPlusMetricsData(){
        var timestamp = moment(new Date()).format(this.longDateFormat);
        var newDate = moment(this.plusMetricsData.startTime).format(this.longDateFormat);
        var inputData = {
            deptId: this.plusMetricsData.departmentId,
            newDate: newDate,
            timestamp: timestamp,
            tenantId: this.profileData.TenantId
        }
        await this.$store
            .dispatch("shared/getPlusMetrics", inputData)
            .then(() => {
                if(this.plusMetrics != null){
                    if (this.plusMetrics.plusMetricsPayPeriod.length > 0) {
                        var payPeriod = this.plusMetrics.plusMetricsPayPeriod;
                        var total = payPeriod[payPeriod.length - 1];
                        this.ActualHours = total.actualHours;
                        this.TargetHours = total.targetHours;
                        this.VarianceHours = total.varianceHours;

                        this.VarianceNegative = this.VarianceHours < 0;
                        this.VariancePositive = this.VarianceHours > 0; 
                        this.ProdIndex = this.ActualHours == null || this.ActualHours == undefined ? '' : this.ActualHours == 0 ? '0%' : parseFloat((this.TargetHours * 100.0 / this.ActualHours).toFixed(2)) + '%';     
                    }
                }
            })
            .catch((err: any) => {
                if (err) {
                    this.emitter.emit('showPlusMetricsError');
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    showMetricsView(){
        this.emitter.emit("showMetricsList", this.plusMetrics);
    }
    openTrainingDocument(){
        let url:string =  this.profileData.AppUrl.trim() + "WBT/index.htm#t=PLUS_Metrics%2fDashboard_-_View_PLUS_Metrics.htm";
        window.open(url);
        
    }

}

</script>
<style scoped>

span.digits.plusMetrics {   
    display: inline-block !important;
    font-weight: 700;
}

</style>
<style scoped>
.text-start-padding{
    right: 35px !important;
}

.text-end-padding{
    left: 0px !important;
}

.text-start{
    text-align: left;
    right: 2px;
}

.text-end{
    text-align: right;
    right: 2px;
}
</style>