
<template>
    <div class="tab-pane fade show active m-2 tab-content" id="CurrentCensusTabContent" role="tabpanel" aria-labelledby="home-tab">
        <div id="censusTrendGraphContainer" class="txtLeft">
            <div class="row mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">facility</label>
                    <VueMultiselect v-model="selectedFacilities"
                                    :options="facilities"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="onFacilityChange"
                                    label="Text"
                                    track-by="Text"
                                    placeholder="All Facilities..."
                                    id="selFacilities">
                    </VueMultiselect>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">department</label>
                    <VueMultiselect v-model="selectedDepartments"
                                    :options="departments"
                                    :searchable="true"
                                    :close-on-select="true"
                                    placeholder="All Departments..."
                                    label="Text"
                                    track-by="Text"
                                    id="selDepartments">
                    </VueMultiselect>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">FROM</label>
                    <Datepicker v-model="selectedStartDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                :enableTimePicker="true" autoApply ></Datepicker>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">TO</label>
                    <Datepicker v-model="selectedEndDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                :enableTimePicker="true" autoApply ></Datepicker>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">PART OF DAY</label>
                    <select class="neu-select" name="facility" id="partOfDayDropdown"
                            v-model="selectedPartOfDay">
                        <option :value="-1" selected>All Templates...</option>
                        <option v-for="partOfDay in partOfDays" :value="partOfDay.partOfDayId" :key="partOfDay.partOfDayId">
                            {{partOfDay.partOfDayName}}
                        </option>
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">CHART TYPE</label>
                    <select class="neu-select" name="chartType" id="chartTypeDropdown"
                            v-model="selectedChartType">
                        <option v-for="objChartType in chartTypeArray" :value="objChartType.ChartValue" :key="objChartType.ChartValue">
                            {{objChartType.ChartText}}
                        </option>
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-3">
                    <input type="checkbox" v-model="chkForecastValue" id="chkForecast" name="a11y-issues"><span class="uppercase default-font-size">FORECAST</span><br />
                    <input type="checkbox" v-model="chkActualValue" id="chkActual" name="a11y-issues"><span class="uppercase default-font-size">ACTUAL</span><br />
                    <input type="checkbox" v-model="chkStaffToValue" id="chkStaffTo" name="a11y-issues"><span class="uppercase default-font-size">STAFF TO</span>
                </div>
                <div class="col-2 search-wrapper" style="padding-top:20px">
                    <neu-button type="button" fill="raised" id="censusTrendSearch"
                                class="float-end me-1" @click="searchClick">
                        SEARCH
                    </neu-button>
                </div>
            </div>
        </div>
        <div class="activityLog-grid-container mt-2">
            <div id="census-trend-graph-loading" class="text-center mt-3" v-show="isShowLoader">
                <div class="fa-2x">
                    <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                </div>
                <p><b>Loading...</b></p>
            </div>
            <div style="margin-top:20px;" v-if="censusGridData != null">
                <span style="font-size:16px; color:gray; font-family:HCA-Mark, Arial, sans-serif; font-weight:bold;">
                    Census Trend <br />
                    Department ({{CensusDataDepartmentName()}})<br v-if="!censusGridData.length"/>
                    {{censusGridData.length? '' : 'No Data Found' }}
                </span>
            </div>
            <div id="divLineChart" v-show="isShowLineChart">
            </div>
            <div id="divColumnChart" v-show="isShowColumnChart">
            </div>
            <div id="divAreaChart" v-show="isShowAreaChart">
            </div>
        </div>
    </div>
    <div v-if="isToasterVisible">
        <ToasterPopup @closeModal="closeToasterPopup"
                      :msgValue="msgValue"
                      :classFlag="classFlag"></ToasterPopup>
    </div>
</template>

<script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";
    import { mapState } from "vuex";
    import { KeyPairModel } from '@/models/common/index';
    import jslinq from "jslinq";

    //import model and enum
    //import model and enum
    import { Profile } from '@/models/profile/index';

    //Import Componenets
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import ToasterPopup from '../shared/ToasterPopup.vue';
    import {
        NeuButton,
        NeuContainer,
        NeuInput,
        NeuLabel,
        NeuCard,
        NeuCol,
        NeuCheckbox
    } from "@neutron/vue";
    //@ts-ignore
    import VueMultiselect from 'vue-multiselect';
    import ApexCharts from 'apexcharts';

    class Props {
        censusTrendTabInput!: any
    }

    @Options({
        computed: {
            ...mapState('shared', ['facilitiesByUser', 'departmentsByUser']),
            ...mapState('managecensus', ['forecastCensus', 'partOfDayTemplates']),
        },
        components: {
            NeuButton,
            NeuContainer,
            NeuInput,
            NeuLabel,
            NeuCard,
            NeuCol,
            NeuCheckbox,
            Datepicker,
            VueMultiselect,
            ToasterPopup,
            ApexCharts
        },
        watch: {
            chkForecastValue(newValue, oldValue) {
                this.onCheckboxChange(newValue, oldValue, 'Forecast');
            },

            chkActualValue(newValue, oldValue) {
                this.onCheckboxChange(newValue, oldValue, 'Actual');
            },

            chkStaffToValue(newValue, oldValue) {
                this.onCheckboxChange(newValue, oldValue, 'StaffTo');
            }
        }
    })
    export default class CensusTrendTab extends Vue.with(Props) {
        //Data properties
        facilitiesByUser!: any;
        departmentsByUser!: any;
        forecastCensus: any;
        partOfDayTemplates: any;

        allDepartments: any = [];
        facilities: any = [];
        selectedFacilities: any = null;
        departments: any = [];
        selectedDepartments: any = null;
        partOfDays: any = [];
        selectedPartOfDay: any = [];
        startDate: Date = new Date();
        endDate: Date = new Date();
        selectedStartDate: Date = new Date();
        selectedEndDate: Date = new Date();

        censusGridData: any = null;
        isShowLoader: boolean = false;
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        series_Actual: any[] = [];
        series_Forecast: any[] = [];
        series_StaffTo: any[] = [];
        chartCategories: any[] = [];
        chkForecastValue: boolean = true;
        chkActualValue: boolean = true;
        chkStaffToValue: boolean = true;
        selectedChartType: any = "1";
        chartTypeArray: any = [{ ChartText: "Line", ChartValue: "1" },
        { ChartText: "Column", ChartValue: "2" },
        { ChartText: "Area", ChartValue: "3" }];

        optionsLine: any = {
            chart: {
                height: 380,
                width: "100%",
                type: "line",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                }
            },
            series: [
                {
                    name: "Forecast",
                    data: this.series_Forecast
                },
                {
                    name: "Actual",
                    data: this.series_Actual
                },
                {
                    name: "StaffTo",
                    data: this.series_StaffTo
                },
            ],
            xaxis: {
                categories: this.chartCategories,
                labels: {
                    maxHeight: 200,
                    rotate: -90
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            markers: {
                size: [2, 2, 2],
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: undefined,
                    sizeOffset: 3
                }
            }
        };

        optionsColumn: any = {
            series: [
                {
                    name: "Forecast",
                    data: this.series_Forecast
                },
                {
                    name: "Actual",
                    data: this.series_Actual
                },
                {
                    name: "StaffTo",
                    data: this.series_StaffTo
                }],
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            chart: {
                type: 'bar',
                height: 380,
                width: "100%",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: this.chartCategories,
                labels: {
                    maxHeight: 200,
                    rotate: -90
                },
            },
            fill: {
                opacity: 1
            }
        };

        optionsArea: any = {
            series: [
                {
                    name: "Forecast",
                    data: this.series_Forecast
                },
                {
                    name: "Actual",
                    data: this.series_Actual
                },
                {
                    name: "StaffTo",
                    data: this.series_StaffTo
                }],
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            chart: {
                height: 380,
                type: 'area',
                width: "100%",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                },
                stacked: true,
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: this.chartCategories,
                labels: {
                    maxHeight: 200,
                    rotate: -90
                },
            }
        };
        lineChart: any;
        columnChart: any;
        areaChart: any;
        isShowLineChart: boolean = false;
        isShowColumnChart: boolean = false;
        isShowAreaChart: boolean = false;

        //Lifecycle hooks
        async mounted() {
            var currentDate = new Date();
            currentDate.setHours(currentDate.getHours());
            this.selectedStartDate = currentDate;

            currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 24);
            this.selectedEndDate = currentDate;

            await this.loadCurrentCensusTab();
            this.selectedPartOfDay = -1;
            this.loadChartsWithInitialData();
        }

        //Methods
        loadChartsWithInitialData() {
            this.lineChart = new ApexCharts(document.querySelector('#divLineChart'), this.optionsLine);
            this.lineChart.render();

            this.columnChart = new ApexCharts(document.querySelector('#divColumnChart'), this.optionsColumn);
            this.columnChart.render();

            this.areaChart = new ApexCharts(document.querySelector('#divAreaChart'), this.optionsArea);
            this.areaChart.render();
        }
        async loadCurrentCensusTab() {
            await this.loadFacilities();
            await this.loadDepartments();
            await this.loadPartOfDay();
        }

        async loadFacilities() {
            var inputData = {
                UserId: this.censusTrendTabInput.userId,
                TenantId: this.censusTrendTabInput.tenantId
            }
            await this.$store
                .dispatch("shared/getFacilitiesByUser", inputData)
                .then(() => {
                    if (this.facilitiesByUser != null && this.facilitiesByUser.length > 0) {
                        var facilitiesByUser = this.facilitiesByUser;
                        var facilityIds = this.censusTrendTabInput.facilities.map((facility: any) => { return +facility.Value; });
                        var facilities: any = [];
                        facilitiesByUser.sort(this.GetSortOrder("name"));
                        facilitiesByUser.forEach((facility: any) => {
                            if (facilityIds.indexOf(+facility.facilityId) >= 0) {
                                facilities.push({
                                    FacilityId: facility.facilityId,
                                    Text: facility.name + ' - ' + facility.coid,
                                    Value: facility.facilityId,
                                    COID: facility.coid
                                });
                            }
                        });
                        this.facilities = facilities;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Facility.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        async loadDepartments() {
            var inputData = {
                UserId: this.censusTrendTabInput.userId
            }
            await this.$store
                .dispatch("shared/getDepartmentsByUser", inputData)
                .then(() => {
                    if (this.departmentsByUser != null && this.departmentsByUser.length > 0) {
                        var finalDepartments: any = [];
                        var departmentIds = this.censusTrendTabInput.departments.map((department: any) => { return +department.Value; });
                        var departmentsByUser = this.departmentsByUser;

                        //Sorting based on dept code
                        departmentsByUser.sort(this.GetSortOrder("DepartmentCode"));

                        departmentsByUser.forEach((department: any) => {
                            if (departmentIds.indexOf(+department.DepatmentId) >= 0) {
                                finalDepartments.push({
                                    FacilityId: department.FacilityId,
                                    Text: department.DepartmentName + ' - ' + department.DepartmentCode,
                                    Value: department.DepatmentId,
                                    DepartmentCode: department.DepartmentCode
                                });
                            }
                        });

                        this.allDepartments = finalDepartments;
                        this.refreshDepartmentList();
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        async loadPartOfDay() {
            var inputData = {
         
                TenantId: this.censusTrendTabInput.tenantId
            }
            await this.$store
                .dispatch("managecensus/getPartOfDayTemplates", inputData)
                .then(() => {
                    if (this.partOfDayTemplates != null && this.partOfDayTemplates.length > 0) {
                        this.partOfDays = this.partOfDayTemplates;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Part of Day.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        onFacilityChange() {
            this.refreshDepartmentList();
            this.selectedDepartments = null;
        }

        refreshDepartmentList() {
            var selectedFacilities = this.selectedFacilities;

            if (selectedFacilities) {
                //@ts-ignore
                var filteredDepartments = this.sortAscByText(this.getDistinct(
                    this.getDepartments(selectedFacilities, this.allDepartments)));

                this.departments = filteredDepartments;

            } else {
                this.departments = this.allDepartments;
            }
        }

        getDepartments(selectedFaciities: any, collection: KeyPairModel[] | null) {
            var result: any[] = [];
            var facilityId = selectedFaciities.FacilityId;

            //@ts-ignore
            var items = jslinq(collection).where(function (item) { return item.FacilityId === facilityId; }).distinct().toList();
            items.forEach((value) => { result.push(value); });
            result = this.sortAscByText(result);
            return result;
        }

        getDistinct(collection: KeyPairModel[] | null) {
            if (collection != null) {
                //@ts-ignore
                var results = jslinq(collection)
                    .select(function (item: any) {
                        return { Value: item.Value, Text: item.Text };
                    })
                    .distinct()
                    .orderBy(function (item: any) {
                        return item.Value;
                    })
                    .toList();

                return results;
            }
            else {
                return [];
            }
        }

        sortAscByText(collection: KeyPairModel[]) {
            //@ts-ignore
            var result = jslinq(collection).distinct().orderBy(function (item: any) {
                return item.Text;
            }).toList();

            return result;
        }

        GetSortOrder(prop: any) {
            return function (a: any, b: any) {
                if (a[prop] > b[prop]) {
                    return 1;
                } else if (a[prop] < b[prop]) {
                    return -1;
                } return 0;
            }
        }

        async searchClick() {
            var model: any = {};
            if (this.selectedFacilities == null) {
                var errorData = {
                    message: "Please select Facility.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (this.selectedDepartments == null) {
                var errorData = {
                    message: "Please select Department.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (this.selectedStartDate == null) {
                var errorData = {
                    message: "Please enter valid From Date.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (this.selectedEndDate == null) {
                var errorData = {
                    message: "Please enter valid To Date.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (moment(this.selectedStartDate) > moment(this.selectedEndDate)) {
                var errorData = {
                    message: "From Date should be less than To Date.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }
            else if (Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'day')) > 30) {
                var errorData = {
                    message: "Please filter within a 30 day range.",
                    flag: "error"
                }
                this.showToasterPopup(errorData);
                return;
            }

            var deptIds: any = [];
            deptIds.push(this.selectedDepartments.Value);

            model.DepartmentIds = deptIds;
            model.StartDateTime = moment(this.selectedStartDate).utc();
            model.EndDateTime = moment(this.selectedEndDate).utc();
            model.PartOfDayIds = this.selectedPartOfDay == -1 ? [1, 2, 4, 3] : [this.selectedPartOfDay];
            await this.loadCensusTrendGraphData(model);
        }

        async loadCensusTrendGraphData(inputData: any) {
            this.showLoader();
            this.censusGridData = null;
            await this.$store
                .dispatch("managecensus/getForecastCensus", inputData)
                .then(() => {
                    this.hideLoader();
                    if (this.forecastCensus != null) {
                        this.censusGridData = this.sortBy(this.forecastCensus, "Date", 'asc');
                        this.loadCharts();
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving manage census data.",
                            flag: "error"
                        }
                        this.showToasterPopup(errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        loadCharts() {
            if (this.censusGridData != null) {
                this.series_Actual = [];
                this.series_Forecast = [];
                this.series_StaffTo = [];
                this.chartCategories = [];

                for (let x = 0; x < this.censusGridData.length; x++) {
                    this.series_Actual.push(
                        this.censusGridData[x].actual != null ? Number(this.censusGridData[x].actual) : null
                    );

                    this.series_Forecast.push(
                        this.censusGridData[x].forecast != null ? Number(this.censusGridData[x].forecast) : null
                    );

                    this.series_StaffTo.push(
                        this.censusGridData[x].staffTo != null ? Number(this.censusGridData[x].staffTo) : null
                    );
                    this.chartCategories.push(this.censusGridData[x].startDateTimeString);
                }

                if (this.selectedChartType == "1")// Line Chart
                {
                    this.toggleChartVisibility('Line');

                    this.lineChart.updateOptions({
                        series: [
                            {
                                name: "Forecast",
                                data: this.series_Forecast
                            },
                            {
                                name: "Actual",
                                data: this.series_Actual
                            },
                            {
                                name: "StaffTo",
                                data: this.series_StaffTo
                            }],
                        xaxis: {
                            categories: this.chartCategories
                        },
                    }, true, true, true);

                }
                else if (this.selectedChartType == "2")// Column Chart
                {
                    this.toggleChartVisibility('Column');

                    this.columnChart.updateOptions({
                        series: [
                            {
                                name: "Forecast",
                                data: this.series_Forecast
                            },
                            {
                                name: "Actual",
                                data: this.series_Actual
                            },
                            {
                                name: "StaffTo",
                                data: this.series_StaffTo
                            }],
                        xaxis: {
                            categories: this.chartCategories
                        },
                    });

                }
                else // Area Chart
                {
                    this.toggleChartVisibility('Area');

                    this.areaChart.updateOptions({
                        series: [
                            {
                                name: "Forecast",
                                data: this.series_Forecast
                            },
                            {
                                name: "Actual",
                                data: this.series_Actual
                            },
                            {
                                name: "StaffTo",
                                data: this.series_StaffTo
                            }],
                        xaxis: {
                            categories: this.chartCategories
                        },
                    }, true, true, true);
                }

                setTimeout(() => {
                    this.checkforCheckboxesUnchecked();
                }, 1000);
            }
        }

        CensusDataDepartmentName(){
            var selectedDepartmentId = Number(this.selectedDepartments.Value);
            var selectedDepartments = this.allDepartments.filter(function(v:any){ 
                return v.Value == selectedDepartmentId;
                });
            var departmentName = '';
            if (this.censusGridData != null && this.censusGridData.length){
                departmentName = this.censusGridData[0].deptName;
            }else if (selectedDepartments != null && selectedDepartments.length){
                departmentName = selectedDepartments[0].Text.replace(' - ' + selectedDepartments[0].DepartmentCode, '')
            }

            return departmentName;
        }

        checkforCheckboxesUnchecked() {
            if (this.selectedChartType == '1' && this.lineChart != null) {
                if (this.chkForecastValue == false) {
                    this.lineChart.hideSeries('Forecast');
                }
                else {
                    this.lineChart.showSeries('Forecast');
                }

                if (this.chkActualValue == false) {
                    this.lineChart.hideSeries('Actual');
                }
                else {
                    this.lineChart.showSeries('Actual');
                }

                if (this.chkStaffToValue == false) {
                    this.lineChart.hideSeries('StaffTo');
                }
                else {
                    this.lineChart.showSeries('StaffTo');
                }
            }
            else if (this.selectedChartType == '2' && this.columnChart != null) {

                if (this.chkForecastValue == false) {
                    this.columnChart.hideSeries('Forecast');
                }
                else {
                    this.columnChart.showSeries('Forecast');
                }

                if (this.chkActualValue == false) {
                    this.columnChart.hideSeries('Actual');
                }
                else {
                    this.columnChart.showSeries('Actual');
                }

                if (this.chkStaffToValue == false) {
                    this.columnChart.hideSeries('StaffTo');
                }
                else {
                    this.columnChart.showSeries('StaffTo');
                }
            }
            else if (this.selectedChartType == '3' && this.areaChart != null) {
                if (this.chkForecastValue == false) {
                    this.areaChart.hideSeries('Forecast');
                }
                else {
                    this.areaChart.showSeries('Forecast');
                }

                if (this.chkActualValue == false) {
                    this.areaChart.hideSeries('Actual');
                }
                else {
                    this.areaChart.showSeries('Actual');
                }

                if (this.chkStaffToValue == false) {
                    this.areaChart.hideSeries('StaffTo');
                }
                else {
                    this.areaChart.showSeries('StaffTo');
                }
            }
        }

        toggleChartVisibility(chartType: string) {
            this.isShowLineChart = this.censusGridData != null && this.censusGridData.length && chartType.toLowerCase() == 'Line'.toLowerCase() ? true : false;
            this.isShowColumnChart = this.censusGridData != null && this.censusGridData.length && chartType.toLowerCase() == 'Column'.toLowerCase() ? true : false;
            this.isShowAreaChart = this.censusGridData != null && this.censusGridData.length && chartType.toLowerCase() == 'Area'.toLowerCase() ? true : false;
        }
        sortBy(array: any, key: any, direction: any) {
            var sortDirection = direction || 'asc';
            return array.sort(function (a: any, b: any) {
                if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                    return 0;
                }
                var x = a[key].toUpperCase();
                var y = b[key].toUpperCase();
                if (sortDirection.toLowerCase() === 'asc') {
                    return (x < y) ? -1 : (x > y) ? 1 : 0;
                } else if (sortDirection.toLowerCase() === 'desc') {
                    return (x > y) ? -1 : (x < y) ? 1 : 0;
                }
            });
        }

        onCheckboxChange(newValue: boolean, oldValue: boolean, flag: string) {
            if (oldValue != null) {
                if (newValue != oldValue) {
                    if (this.selectedChartType == '1' && this.lineChart != null) {
                        if (flag.toLowerCase() == 'Forecast'.toLowerCase()) {
                            if (this.chkForecastValue == false) {
                                this.lineChart.hideSeries('Forecast');
                            }
                            else {
                                this.lineChart.showSeries('Forecast');
                            }
                        }
                        else if (flag.toLowerCase() == 'Actual'.toLowerCase()) {
                            if (this.chkActualValue == false) {
                                this.lineChart.hideSeries('Actual');
                            }
                            else {
                                this.lineChart.showSeries('Actual');
                            }
                        }
                        else if (flag.toLowerCase() == 'StaffTo'.toLowerCase()) {
                            if (this.chkStaffToValue == false) {
                                this.lineChart.hideSeries('StaffTo');
                            }
                            else {
                                this.lineChart.showSeries('StaffTo');
                            }
                        }
                    }
                    else if (this.selectedChartType == '2' && this.columnChart != null) {
                        if (flag.toLowerCase() == 'Forecast'.toLowerCase()) {
                            if (this.chkForecastValue == false) {
                                this.columnChart.hideSeries('Forecast');
                            }
                            else {
                                this.columnChart.showSeries('Forecast');
                            }
                        }
                        else if (flag.toLowerCase() == 'Actual'.toLowerCase()) {
                            if (this.chkActualValue == false) {
                                this.columnChart.hideSeries('Actual');
                            }
                            else {
                                this.columnChart.showSeries('Actual');
                            }
                        }
                        else if (flag.toLowerCase() == 'StaffTo'.toLowerCase()) {
                            if (this.chkStaffToValue == false) {
                                this.columnChart.hideSeries('StaffTo');
                            }
                            else {
                                this.columnChart.showSeries('StaffTo');
                            }
                        }
                    }
                    else if (this.selectedChartType == '3' && this.areaChart != null) {
                        if (flag.toLowerCase() == 'Forecast'.toLowerCase()) {
                            if (this.chkForecastValue == false) {
                                this.areaChart.hideSeries('Forecast');
                            }
                            else {
                                this.areaChart.showSeries('Forecast');
                            }
                        }
                        else if (flag.toLowerCase() == 'Actual'.toLowerCase()) {
                            if (this.chkActualValue == false) {
                                this.areaChart.hideSeries('Actual');
                            }
                            else {
                                this.areaChart.showSeries('Actual');
                            }
                        }
                        else if (flag.toLowerCase() == 'StaffTo'.toLowerCase()) {
                            if (this.chkStaffToValue == false) {
                                this.areaChart.hideSeries('StaffTo');
                            }
                            else {
                                this.areaChart.showSeries('StaffTo');
                            }
                        }
                    }
                }
            }
        }

        showLoader() {
            this.isShowLoader = true;
        }

        hideLoader() {
            this.isShowLoader = false;
        }

        showToasterPopup(data: any) {
            this.msgValue = data.message;
            this.classFlag = data.flag;
            this.isToasterVisible = true;
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }
    }
</script>

<style scoped>
    #divLineChart, #divColumnChart, #divAreaChart {
        max-width: 1225px;
        margin: 35px 50px;
    }
    .neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.125rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}
</style>