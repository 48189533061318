<template>
    <div id="divSurgeDisplay" v-if="surgeDisplayValue">
        <div class="container-fluid surgeStrip dashboardSurgeHeight" id="dvMainSurgeUL">
            <ul id="SurgeUL">
                <li class="navSurge" id="surgeDiv">
                    <label id="lblSurge">SURGE</label>
                    <font-awesome-icon id="warningIco"  :icon="['fas', 'exclamation-triangle']" size="lg" />
                </li>
                <li class="navSurge" id="firstLI">
                    <font-awesome-icon title="Previous"  :icon="['fas', 'angle-left']" size="1x" style="cursor: pointer; padding-top: 4px;" id="btnsurgeprv" v-show="isShowSurgePrev" @click="surgePrev" />
                </li> 
                <li class="navSurge" id="lastLI"> 
                    <table>
                        <tbody>
                            <tr>
                                <td rowspan="2" style="font-size:1rem !important;padding-right:10px">
                                    <font-awesome-icon title="Next" class="navSurge" :icon="['fas', 'angle-right']" size="1x" style="cursor: pointer;" id="btnsurgenext" v-show="isShowSurgeNext" @click="surgeNext" />
                                </td>
                                <td>
                                    <label id="lblLastUpdated"> Last Updated: {{getCurrentDate()}}</label>
                                </td>
                                <td rowspan="2" style="font-size:1rem !important;padding-right:12px"></td>
                                <td @click="showUserPreferencesPopup"><a href="javascript:void(0)" id="linkSurgeDetailsDisplay">Settings</a></td>
                            </tr> 
                        </tbody>
                    </table>
                </li>              
                <template v-if="isShowSurgeAlertData"> 
                    <li :class="['navSurge']">
                    <neu-tooltip class="tooltipRight" v-for="item in surgeAlertDisplayDataList" :key="item">                                          
                        <neu-container slot="content"
                      :id="item.DepteId" :data-facilityCode="item.FacilityShortName" 
                            :data-DeptName="item.DepartmentName" :class="['spnIconPopover dashboardSurgeitemHeight',
                                item.EdwinScoreColor == 'Yellow' ? 'bgClrYellow' : '',
                                item.EdwinScoreColor == 'Red' ? 'bgClrRed' : '']" ><div class="liSurge">{{item.FacilityShortName.substr(0, 3) + ' ' + item.TotalSecondsInSurge}}</div></neu-container>
                      <neu-container slot="tooltip">
                         <neu-row class="headlabel" leftAlig xxl="3" xl="6" lg="12" md="3" sm="6" cols="12"> {{item.SurgePopOverDataByDept.facilityName}} </neu-row>
                         <neu-row class="popoverSubHeadtext" leftAlig xxl="3" xl="6" lg="12" md="3" sm="6" cols="12">{{ item.SurgePopOverDataByDept.departmentName }}</neu-row>                         
                         <hr class="popoverline">
                            <neu-row xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="row leftAlign">
                                <neu-col leftAlig  xxl="3" xl="6" lg="12" md="3" sm="6" cols="12"  class="col-sm-9 popovertext colRight">Current EDWIN Score:</neu-col>
                                <neu-col rightAlig xxl="3" xl="6" lg="12" md="3" sm="6" cols="12" class="col-sm-3 valuesText popovertext">
                                    <div :class="item.SurgePopOverDataByDept.boxclass"></div>   {{item.SurgePopOverDataByDept.currentEdwinScore}}
                                </neu-col>
                            </neu-row>
                            <neu-row class="row leftAlign">
                                <neu-col class="col-sm-9 popovertext colRight">Time in Current State:</neu-col>
                                <neu-col class="col-sm-3  valuesText popovertext">{{item.SurgePopOverDataByDept.timeInCurrentState}}</neu-col>
                            </neu-row>
                            <neu-row class="row leftAlign">
                                <neu-col class="col-sm-9 popovertext colRight">Total Time in Surge:</neu-col>
                                <neu-col class="col-sm-3 valuesText popovertext">{{item.SurgePopOverDataByDept.totalTimeInSurge}}</neu-col>
                            </neu-row>
                            <hr class="popoverline">
                            <neu-row class="row leftAlign">
                                <neu-col class="col-sm-9 popovertext colRight">Volume Since Midnight:</neu-col>
                                <neu-col class="col-sm-3 valuesText popovertext">{{item.SurgePopOverDataByDept.volumeSinceMidnight}}</neu-col>
                            </neu-row>
                            <neu-row class="row leftAlign">
                                <neu-col class="col-sm-9 popovertext colRight"> Actual Census:</neu-col>
                                <neu-col class="col-sm-3 valuesText popovertext">{{item.SurgePopOverDataByDept.actualCensus}}</neu-col>
                            </neu-row>
                     </neu-container>                      
                    </neu-tooltip>
                </li>                                 
                </template>
            </ul>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import moment from "moment";
import jslinq from "jslinq";

//import model and enum
import { Profile } from '@/models/profile/index';

//import components

@Options({
  computed: {
    ...mapState('userstaffpreferences', ['surgePreferences']),
    ...mapState('surgealert', ['surgeAlert']),
    ...mapState('profile', ['profileData']),
    surgeAlertDisplayDataList() {
      if (this.surgeAlertDisplayData && this.surgeAlertDisplayData.length === 0){
        return [];
      } 
      else{
        if (this.displayMarketsFromIndex !== -1 && this.displayMarketsToIndex !== -1) {
            return this.surgeAlertDisplayData.slice(this.displayMarketsFromIndex, this.displayMarketsToIndex + 1);
        }
      }
    },
  },
})
export default class Surge extends Vue {
    //Data properties
    surgePreferences!: any;
    profileData!: Profile;
    surgeAlert!: any;
    surgeInterval!: any;
    surgeAlertData: any[] = [];
    surgeAlertDisplayData: any[] = [];
    surgeDisplayValue: boolean = true;
    isShowSurgePrev: boolean = false;
    isShowSurgeNext: boolean = false;
    displayMarketsFromIndex: number = 0;
    displayMarketsToIndex: number = 0;
    displayMarketsCount: number = 0; 
    isShowSurgeAlertData: boolean = false;
    
    //Lifecycle hooks
    async mounted(){ 
        this.clearsurgeStrip();
    }

    created(){
        this.emitter.on('toggleSurgeBar', this.toggleSurgeBar);
        this.emitter.on('loadSurgeBarData', this.loadSurgeBarData);
    }
    unmounted(){
        clearInterval(this.surgeInterval);
        this.emitter.off('toggleSurgeBar', this.toggleSurgeBar);
        this.emitter.off('loadSurgeBarData', this.loadSurgeBarData);
    }

    //Methods
    async loadSurgeBarData(){
        await this.getSurgePreference();
        this.setAutoRefreshSurge();
    }

    showUserPreferencesPopup(){
        this.emitter.emit('loadUserPreferencesPopup');
    }

    toggleSurgeBar(isSurgeDisplay: boolean){
        this.surgeDisplayValue = isSurgeDisplay;
    }
    async getSurgePreference(){
        var inputData = {
            tenantId: this.profileData.TenantId,
            sourceId: 'Dashboard',
            dashboardId: this.profileData.DashboardId,
            fsUserId: this.profileData.UserId,
            fsStaffId: 0
        }
        
        await this.$store
                .dispatch("userstaffpreferences/getSurgePreferences", inputData)
                .then(() => {
                    if (this.surgePreferences != null) {
                        this.surgeDisplayValue = this.surgePreferences.surgeDisplay;
                    }
                    this.initializeScreen();
                })
                .catch((err: any) => {
                    if (err) {
                    
                        console.error(err); // Handle errors any way you want
                    }
                }); 
    }

    async initializeScreen(){
        await this.getSurgeAlerts();
    }

    setAutoRefreshSurge(){
        this.surgeInterval = setInterval(() => {
            this.getSurgeAlerts();
        }, 300000);
    }

    async getSurgeAlerts(){
        var inputData = {
            fsUserId: this.profileData.UserId,
            tenantId: this.profileData.TenantId
        }
        
        await this.$store
                .dispatch("surgealert/getSurgeAlerts", inputData)
                .then(() => {
                    if (this.surgeAlert != null && this.surgeAlert.length > 0) {
                        this.surgeAlertData = this.sortBy(this.surgeAlert,"facilityShortName", 'asc');
                        this.setSurgeStrip();
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        console.error(err); // Handle errors any way you want
                    }
                }); 
    }

    setSurgeStrip(){
        var totalSurgeULWidth, firstSurgeLIWidth, lastSurgeLIWidth, screenWidth;
        //@ts-ignore
        totalSurgeULWidth = document.getElementById('SurgeUL').offsetWidth;
        //@ts-ignore
        firstSurgeLIWidth = document.getElementById('surgeDiv').offsetWidth;
        //@ts-ignore
        lastSurgeLIWidth = document.getElementById('lastLI').offsetWidth;

        screenWidth = totalSurgeULWidth - (firstSurgeLIWidth + lastSurgeLIWidth);
        
        this.surgeAlertDisplayData = [];
        if (this.surgeAlertData.length > 0) {
            this.displayMarketsFromIndex = 0;
            switch (true) {
                case (screenWidth <= 200):
                    this.displayMarketsFromIndex = -1;
                    this.displayMarketsCount = -1;
                    break;
                case (screenWidth > 200 && screenWidth <= 270):
                    this.displayMarketsCount = 0;
                    break;
                case (screenWidth > 270 && screenWidth <= 370):
                    this.displayMarketsCount = 1;
                    break;
                case (screenWidth > 370 && screenWidth <= 470):
                    this.displayMarketsCount = 2;
                    break;
                case (screenWidth > 470 && screenWidth <= 570):
                    this.displayMarketsCount = 3;
                    break;
                case (screenWidth > 570 && screenWidth <= 670):
                    this.displayMarketsCount = 4;
                    break;
                case (screenWidth > 670 && screenWidth <= 770):
                    this.displayMarketsCount = 5;
                    break;
                case (screenWidth > 670 && screenWidth <= 870):
                    this.displayMarketsCount = 6;
                    break;
                case (screenWidth > 870 && screenWidth <= 970):
                    this.displayMarketsCount = 7;
                    break;
                case (screenWidth > 970 && screenWidth <= 1100):
                    this.displayMarketsCount = 8;
                    break;
                case (screenWidth > 1100):
                    this.displayMarketsCount = 9;
                    break;
            }

            this.displayMarketsCount = ((this.surgeAlertData.length - 1) < this.displayMarketsCount) ? (this.surgeAlertData.length - 1) : this.displayMarketsCount;
            
            this.displayMarketsToIndex = this.displayMarketsCount; 

            let deptName = '';
            this.surgeAlertData.forEach((element: any) => {
                deptName = element.departmentName.replace(/[^A-Za-z0-9]/g, '');

                var surgePopOverDataByDept: any = {   
                    facilityName: element.facilityName,
                    departmentName: element.departmentName,
                    currentEdwinScore: element.edwinScoreValue,
                    timeInCurrentState: this.SecondsTohhmm(element.totalSecondsInCurrentState),
                    totalTimeInSurge: this.SecondsTohhmm(element.totalSecondsInSurge),
                    currentEdwinColor: element.edwinScoreColor,
                    volumeSinceMidnight: element.volumeSinceMidnight,
                    actualCensus: element.actualCensus,
                    boxclass: 'grid-box inline-block-child ' + this.getClassByEdwinColor(element.edwinScoreColor) 
                }
                
                this.surgeAlertDisplayData.push({
                    DepteId: deptName,
                    DepartmentName: element.departmentName,
                    FacilityShortName: element.facilityShortName,
                    TotalSecondsInCurrentState: this.SecondsTohhmm(element.totalSecondsInCurrentState),
                    TotalSecondsInSurge: this.SecondsTohhmm(element.totalSecondsInSurge),
                    EdwinScoreColor: element.edwinScoreColor,
                    SurgePopOverDataByDept: surgePopOverDataByDept
                });
            });

            this.loadDisplayMarkets();
        }
    }

    getClassByEdwinColor(currentEdwinColor: any){
        let styles = "";
        switch (currentEdwinColor) {
            case "Yellow":
                styles = "yellowClass";
                break;
            case "Red":
                styles = "redClass";
                break;
        }
        return styles;
    }

    loadDisplayMarkets(){
        if (this.surgeAlertDisplayData.length > 0) {
            if (this.displayMarketsFromIndex !== -1 && this.displayMarketsToIndex !== -1) {
                this.isShowSurgeAlertData = true;
            }
        }
        this.setSurgeScrolling();
    }

    setSurgeScrolling(){
        if ((this.surgeAlertDisplayData.length - 1) > this.displayMarketsCount && this.displayMarketsCount > -1) {
            this.isShowSurgePrev = true;
            this.isShowSurgeNext = true;
        }
        if (this.displayMarketsFromIndex === 0) {
            this.isShowSurgePrev = false;
        }
        if (this.displayMarketsToIndex === (this.surgeAlertDisplayData.length - 1)) {
            this.isShowSurgeNext = false;
        }
    }

    sortBy(array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
           if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    SecondsTohhmm(totalSeconds: any) {
        var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
        var seconds = totalSeconds - (hours * 3600) - (minutes * 60);
        seconds = Math.round(seconds * 100) / 100
        var result = (hours < 10 ? "0" + hours : hours);
        result += ":" + (minutes < 10 ? "0" + minutes : minutes);
        return result;
    }

    getCurrentDate(){
        return moment(new Date()).format("hh:mm A");
    }

    clearsurgeStrip(){
        this.isShowSurgeAlertData = false;
        this.isShowSurgeNext = false;
        this.isShowSurgePrev = false;
    }

    surgeNext(){
        if (this.displayMarketsToIndex !== (this.surgeAlertDisplayData.length - 1)) {
            this.displayMarketsFromIndex += 1;
            this.displayMarketsToIndex += 1;
        }
        this.loadDisplayMarkets();
    }

    surgePrev(){
        if (this.displayMarketsFromIndex !== 0) {
            this.displayMarketsFromIndex -= 1;
            this.displayMarketsToIndex -= 1;
        }
        this.loadDisplayMarkets();
    }
}

</script>

<style>
@font-face {
    font-family: 'MarkforHCA-Bold';
    src: url('../../styles/fonts/HCA/MarkforHCA-Bold.woff2') format('woff2'), 
        url('../../styles/fonts/HCA/MarkforHCA-Bold.woff') format('woff'), 
        url('../../styles/fonts/HCA/MarkforHCA-Bold.ttf') format('truetype')
}

@font-face {
    font-family: 'MarkforHCA-Medium';
    src: url('../../styles/fonts/HCA/MarkforHCA-Medium.woff2') format('woff2'), 
        url('../../styles/fonts/HCA/MarkforHCA-Medium.woff') format('woff'), 
        url('../../styles/fonts/HCA/MarkforHCA-Medium.ttf') format('truetype')
}

@font-face {
    font-family: 'MarkforHCA-Book';
    src: url('../../styles/fonts/HCA/MarkforHCA-Book.woff2') format('woff2'), 
        url('../../styles/fonts/HCA/MarkforHCA-Book.woff') format('woff'), 
        url('../../styles/fonts/HCA/MarkforHCA-Book.ttf') format('truetype')
}

.warn {
    font-size: 25px;
}

#warningIco {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 16px;
}

#lblSurge {
    font-family: MarkforHCA-Medium;
    font-size: 10pt;
    color: #A40008;
}

.timeLabel {
    font-family: MarkforHCA-book;
    font-size: 7pt;
    margin-bottom: 0.2rem;
    font-weight: normal;
}

.surgeStrip {
    width: 100%;
    background: #DDA7A7;
}

.liSurge {
    font-family: MarkforHCA-Book;
    font-size: 9pt;
    margin-bottom: 0.2rem;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0.3rem;
    color: #fff;
}


.surgeStrip > ul > li {
    float: left;
    list-style: none;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 4px;
    padding-top: 2px;
    color: red;
}

#surgeDiv {
    font-size: 14px;
    color: #A40008;
}

.surgeStrip > ul > li:first-child {
    padding-top: 7px;
    padding-right: 15px;   
    margin-left: 0px;
}

.surgeStrip > ul > li:not(:first-child) {
    color: white;
}

#lastLI {
    background-color: transparent;
    float: right;
    padding-right: 10px;
    padding-top: 6px;
}

#lblLastUpdated {
    font-family: MarkforHCA-Medium;
    font-size: 8pt;
    margin-bottom: 0.2rem;
    color: #5A0004;
    font-weight: 100;
    padding-top: 3px;
}


#linkSurgeDetailsDisplay {
    font-family: MarkforHCA-Bold;
    color: #5A0004;
    text-decoration: underline;
    font-size: 8pt;
    float: right;
}

.surgeStrip > ul > li a, surgeStrip > ul > li a:hover {
    text-decoration: none;
}

.linkDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

#divSurgeDisplayWindow {
    display: none;
}

.SurgeDisplayHide {
    display: none;
}

#containerDiv {
    width: 100%;
    height: 10%;
    background-color: #DDA7A7;
    padding-right: 2px;
}

#linkDisplay {
    font: bold;
    padding-top: 17px;
    padding-right: 17px;
}



.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 390px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: right;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    width: 330px;
}

    .popover .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
    }

        .popover .arrow::before, .popover .arrow::after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

    .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
        bottom: calc((0.5rem + 1px) * -1);
    }

        .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
            bottom: 0;
            border-width: 0.5rem 0.5rem 0;
            border-top-color: rgba(0, 0, 0, 0.25);
        }

        .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
            bottom: 1px;
            border-width: 0.5rem 0.5rem 0;
            border-top-color: #fff;
        }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

    .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
        left: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
    }

        .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
            left: 0;
            border-width: 0.5rem 0.5rem 0.5rem 0;
            border-right-color: rgba(0, 0, 0, 0.25);
        }

        .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
            left: 1px;
            border-width: 0.5rem 0.5rem 0.5rem 0;
            border-right-color: #fff;
        }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

    .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
        top: calc((0.5rem + 1px) * -1);
    }

        .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
            top: 0;
            border-width: 0 0.5rem 0.5rem 0.5rem;
            border-bottom-color: rgba(0, 0, 0, 0.25);
        }

        .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
            top: 1px;
            border-width: 0 0.5rem 0.5rem 0.5rem;
            border-bottom-color: #fff;
        }

    .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -0.5rem;
        content: "";
        border-bottom: 1px solid #f7f7f7;
    }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

    .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
        right: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
    }

        .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
            right: 0;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-left-color: rgba(0, 0, 0, 0.25);
        }

        .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
            right: 1px;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-left-color: #fff;
        }

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

    .popover-header:empty {
        display: none;
    }

.popover-body {
    padding: 0.3rem 1.4rem;
    color: #212529;
}

.k-list-container {
    background-color: white;
    border-color: white;
}


#searchTxt-list {
    font-size: 11px;
    position: absolute;
    font-family: MarkforHCA-Book;
    width: 299.656px;
    white-space: normal;
    height: auto;
    display: none;
    color: black;
}

#divMyMarketDetails {
    min-height: 420px;
}

.grid-box {
    width: 12px;
    height: 12px;
    margin-left: -6px;
}

.redClass {
    background-color: #A4010A;
}

.greenClass {
    background-color: #94C84F;
}

.yellowClass {
    background-color: #C69921;
}

.defaultClass {
    background-color: white;
}

.inline-block-child {
    display: inline-block;
}

.tabMarketDetails {
    border: 1.5px solid #CBCBD1;
    border-collapse: separate;
    border-radius: 6px;
    width: 100%;
}

.popoverline {
    margin-top: 10px;
    margin-bottom: 5px;
    border-width: 2px;
    position: inherit !important;
    right: 15px !important;
    width: 310px !important;
}

.popoverSubHeadtext {
    font-family: MarkforHCA-Book;
    font-size: 9pt;
    color: #15202E;
}

.popovertext {
    font-family: MarkforHCA-book;
    font-size: 9pt;
    color: #15202E;
    padding-top: 4px;
}

.valuesText {
    text-align: right;
    font-weight: normal;
}

.bsize {
    font-family: MarkforHCA-Medium;
    font-size: 13px;
    color: #15202E;
}

.headlabel {
    font-family: MarkforHCA-Bold;
    font-size: 16px;
    color: #15202E;
}

.tab {
    overflow: hidden;
}

.tablinks {
    font-family: MarkforHCA-Book;
    font-size: 14px;
    color: #15202E;
}

.tab button {
    font-family: MarkforHCA-Book;
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    color: #15202E;
}

    .tab button:hover {
        background-color: #ddd;
    }

    .tab button.active {
        color: #15202E;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid blue;
        font-family: MarkforHCA-Bold;
        font-size: 14px;
    }

.tabcontent {
    display: none;
    padding-top: 12px;
    border-top: none;
}

#hrSurgeDisplayWindowLine {
    border: 1px solid black;
    margin-top: 7px;
    margin-left: -26px;
    margin-right: -26px;
    margin-bottom: 7px;
}

.fontAwesomeEmptyHeart {
    font-weight: initial;
}

.colText {
    color: #15202E;
    text-align: left;
    font-size: 11px;
    font-family: MarkforHCA-Book;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}


.colTextnowrap {
    width: 1px;
    white-space: nowrap;
}

.iconclassColor {
    /*color: #898B8E;*/
    color: #696969;
}

.divclearfix{
    height:5px;
}

.colTextBold {
    font-family: MarkforHCA-Medium;
    font-weight: bold;
    font-size: 13px;
    color: #15202E;
}

.colImage {
    text-align: right;
    padding-right: 5px;
}

.fas-color {
    color: #15202E;
}



#divSurgeDisplayWindow {
    padding-top: 0px;
    padding-left: 26px;
    padding-right: 26px;
    overflow-y: hidden;
}

#spnSurgeDisplayWindowHeader, .k-window-actions, #divSurgeDisplayWindow_wnd_title {
    font-family: MarkforHCA-Medium;
    font-size: 19px;
    color: #15202E;
}

.divSurgeDisplayWindow_title {
    margin-left: 11px;
    padding-top: 14px;
}
.divSurgeDisplayWindow_title_dashboard {
    margin-left: 0px;
    padding-top: 0px;
}

#searchTxt:focus {
    outline: none;
}

#spanAddFac {
    font-family: MarkforHCA-Medium;
    font-size: 12px;
    font-weight: bold;
    color: #15202E;
}

#divAddFac {
    padding-bottom: 7px;
    padding-top: 7px;
}

#searchTxt {
    font-family: MarkforHCA-Book;
    font-size: 14px;
    border: 1px solid #CBCBD1;
    width: 100%;
    color: black;
    border-radius: 24px;
}

::placeholder {
    font-size: 12px;
    font-family: MarkforHCA-Medium;
}

:-ms-input-placeholder {
    font-size: 12px;
    font-family: MarkforHCA-Medium;
}

::-ms-input-placeholder {
    font-size: 12px;
    font-family: MarkforHCA-Medium;
}

.divMarginPrint {
    margin-top: 45px;
}

.divSurgeDisplay {
    width: 100%;
    margin-top: 0px;
}

.allmarket {
    overflow-y: auto;
    padding: 0px;
    margin: 0px;
    max-height: 250px;
    width: 100%;
}

.leftAlign {
    text-align: left;
}

.k-animation-container, .k-animation-container *, .k-animation-container ::after, .k-block .k-header, .k-dialog .k-window-content, .k-list-container, .k-widget, .k-widget *, .k-widget ::before {
    box-sizing: inherit;
}

.scheduleSurgeHeight {
    height: 51px;
}

.filterSurgeHeight {
    height: 35px;/*55px;*/
}

.dashboardSurgeHeight {
    height: 28px;
    margin-top: 15px;
}

.scheduleSurgeitemHeight {
    height: 28px;
}

.filterSurgeitemHeight {
    height: 29px;/*49px;*/
}

.dashboardSurgeitemHeight {
    height: 28px;
}

table.tabMarketDetails tr > td {
    padding-top:5px;
    padding-bottom:3px;
}

#divSurgeDisplayWindow .k-autocomplete {
    box-shadow: none;
    border: none;
    background-color: white;
    width:100%
}

.row.leftAlign{
    display:flex;
}

.col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.k-autocomplete, .k-draghandle, .k-dropdown-wrap, .k-grid-header, .k-grouping-header, .k-header, .k-numeric-wrap, .k-pager-wrap, .k-panelbar .k-tabstrip-items .k-item, .k-picker-wrap, .k-progressbar, .k-state-highlight, .k-tabstrip-items .k-item, .k-textbox, .k-toolbar, .km-pane-wrapper > .km-pane > .km-view > .km-content {
    /* background-image: url('../assets/textures/highlight.png'); */
    background-image: none,linear-gradient(to bottom,rgba(255,255,255,.4) 0,rgba(255,255,255,0) 100%);
    background-position: 50% 50%;
    background-color: white;
}

i.iconclassColor:hover {
    cursor:pointer;
}

.bgClrYellow{
    background-color: #C69921;
}

.bgClrRed{
    background-color: #A4010A;
}

.tooltipRight > .neu-tooltip--text {
    background-color: #fff !important;
    top : 20% !important;
    left : -15% !important;
    width : 350px !important;
    transform: translate(-100px,25px) !important;
}

.tooltipRight > .neu-tooltip--text::after{    
    content: "";
    position: absolute !important;
    bottom: 100% !important;
    left: 45% !important;
    margin-left: 5px !important;
    border-width: 10px !important;
    border-style: solid !important;
    border-color: transparent transparent white transparent !important;
}

.colRight{
    right: 13px !important;
}

.tooltipRight{
    margin-right: 5px;
    bottom: 2px;    
}
</style>
