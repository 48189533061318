import { vuexOidcCreateStoreModule } from "vuex-oidc";
 import axios from "axios";
// import NProgress from "nprogress";
import store from "@/store";
import http from "@/store/axios";
import { WebStorageStateStore } from 'oidc-client';

export default vuexOidcCreateStoreModule(
{
    authority: process.env.VUE_APP_AUTHORITY!,
    clientId: process.env.VUE_APP_CLIENTID!,
    redirectUri: process.env.VUE_APP_REDIRECTURI!,
    silentRedirectUri: process.env.VUE_APP_SILENTREDIRECTURI!,
    responseType: "code",
    scope: "openid profile",
    loadUserInfo: false,
    automaticSilentRenew: true,
    automaticSilentSignin: false,
    //@ts-ignore
    userStore: new WebStorageStateStore({ store: window.sessionStorage })
},
// Optional OIDC store settings
{
    dispatchEventsOnWindow: true,
    // namespaced: true, 
    isAuthenticatedBy: 'access_token'
},
// Optional OIDC event listeners
{
    userLoaded: (user: any) => {
        sessionStorage["token"] = user.access_token;

        http.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;

        axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("token")}`;

        window.dispatchEvent(new CustomEvent('token-loaded'));

        // store.dispatch("profile/getUserAccounts");
    },
  }
);
