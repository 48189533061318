<template>
    <div class="scrollableDiv-filter scrollBar">  
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label" id="dtStart-label">Starting Date:</label>
                    <Datepicker v-model="startDateTime" weekStart="0" class="datePickerWidth" :enableTimePicker="false" autoApply ></Datepicker>
                    <!--@change="startDateTimeChanged()" -->
                </div>
                <div class="form-group">
                    <label class="control-label" id="facilitiesFilter-dashboard-label">Facilities:</label>
                    <div class="close-parent multiselect-scroll">
                          <VueMultiselect v-model="selectedFacilities"
                                    :options="facilities"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="selectedFacilityChanged"
                                    placeholder="Select Facilities"
                                    label="Text"
                                    track-by="Text"
                                    id="selFacilities">
                    </VueMultiselect>
                    <label v-if="selectedFacilities != null && selectedFacilities.length > 0" class="close-child" @click="resetFacilityDropdown">X</label> 
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" id="clustersFilter-dashboard-label">Service Lines:</label>
                    <div class="close-parent multiselect-scroll">
                        <VueMultiselect v-model="selectedClusters"
                                    :options="clusters"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="selectedClusterChanged"
                                    placeholder="Select Service Lines"
                                    label="Text"
                                    track-by="Text"
                                    id="selClusters">
                    </VueMultiselect>
                    <label v-if="selectedClusters != null && selectedClusters.length > 0" class="close-child" @click="resetServiceLineDropdown">X</label> 
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label" id="departmentFilter-dashboard-label">Departments:</label>
                    <div class="close-parent multiselect-scroll"> 
                         <VueMultiselect v-model="selectedDepartments"
                                    :options="departments"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    @update:modelValue="selectedDeptChanged"
                                    placeholder="Select Departments"
                                    label="Text"
                                    track-by="Text"
                                    id="selDepartments">
                    </VueMultiselect>
                    <label v-if="selectedDepartments != null && selectedDepartments.length > 0" class="close-child" @click="resetDepartmentDropdown">X</label> 
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" id="shiftTypesFilter-dashboard-label">Skills:</label>
                    <div class="close-parent"> 
                         <VueMultiselect v-model="selectedSkill"
                                    :options="skills"
                                    :multiple="true"
                                    :searchable="true"
                                    :max="3"
                                    :close-on-select="true"
                                    placeholder="Select Skills"
                                    label="EntityName"
                                    track-by="EntityName"
                                    id="selSkill">
                    </VueMultiselect>
                    <label v-if="selectedSkill != null && selectedSkill.length > 0" class="close-child" @click="resetSkillDropdown">X</label> 
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" id="shiftTypesFilter-dashboard-label">Summary Periods:</label>
                    <br />
                    <select name="ddlShiftSummary" :class="['neu-select']" v-model="selectedSummaries" >
                        <option v-for="smry in summaries" :value="smry.Value" :key="smry.Value">
                            {{smry.Text}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label class="control-label" id="chkCurrent-label">
                    <neu-checkbox id="chkCurrent" name="chkCurrent" class="mr-10" v-model="isShowPremiumLabor"></neu-checkbox>Show Premium Labor
                </label>
            </div>
            <div class="col-md-4"> 
                <label class="control-label" id="chkCurrent-label">
                    <input class="form-check-input mr-10" type="radio" id="charge_yes" :value="true" data-type="boolean" 
                            v-model="isPI14Day"/> PI 14 Day Avg
                </label>
            </div>
            <div class="col-md-4">
                <label class="control-label" id="chkCurrent-label">
                    <input class="form-check-input mr-10" type="radio" id="charge_yes" :value="false" data-type="boolean" 
                            v-model="isPI14Day"/> PI 7 Day Avg
                </label>
            </div>
        </div>
        <div class="row text-center" style="margin-top: 20px; margin-bottom: 10px;">
            <div class="btn-group width100" role="group" aria-label="...">
                <div class="col-md-6"></div>
                <neu-button type="button" fill="raised" class="col-md-3 padding0" @click="applyFilters">
                    <!--:disabled="!enableApplyClear"-->
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'filter']" size="lg" />
                    </i> Apply Filters
                </neu-button>
                <button @click="clearFilters" class="btn btn-link col-md-3">
                    <i aria-hidden="true">
                        <font-awesome-icon :icon="['fas', 'undo']" size="lg" />
                    </i> Reset Filters
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import jslinq from "jslinq";
import moment from "moment";
import { mapState } from "vuex";
 
//imports models and enum
import { KeyPairModel } from '@/models/common/index';
import { Profile } from '@/models/profile/index';
import { SevenDayInput, Skill } from "@/models/dashboardSevenDay/index";

//import components
//@ts-ignore
import VueMultiselect from 'vue-multiselect';
//@ts-ignore
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {
    NeuCheckbox,
    NeuButton
} from "@neutron/vue";
 
class Props {
    dashboardConfig!: any
    skillData!: Skill[] 
}

@Options({
    computed: {
        ...mapState('profile', ['profileData']), 
        ...mapState('shared', ['skillByCategory']),
        ...mapState('dashboard', ['sevenDayViewFilter']),
    },
    components: {
        VueMultiselect,
        Datepicker,
        NeuCheckbox,
        NeuButton
    }
})
export default class FilterGrid extends Vue.with(Props) {
  //Data properties
    profileData!: Profile;
    skillByCategory!: any;
    sevenDayViewFilter!: any;
    facilities: KeyPairModel[] = [];
    selectedFacilities: any = null;
    selectedClusters: any = null;
    clusters: KeyPairModel[] = [];
    selectedDepartments: any = null;
    departments: KeyPairModel[] = [];
    selectedSummaries: any = null;
    summaries: KeyPairModel[] = [];
    selectedSkill: any = null;
    skills: Skill[] = [];
    startDateTime: any = null;
    isShowPremiumLabor: boolean = false;  
    isPI14Day: boolean = true;  
    filtersData!: any; 
    defaultFacilityId: string[] = [];
    shortDateFormat: string = 'YYYY-MM-DD';
    longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    defaultSkilllList: any[] = ["RN", "LPN", "Chg RN"];

    //Lifecycle hooks
    mounted() {
        if(this.sevenDayViewFilter != null){
            this.setFilterDataFromState();
        }
        else{
            this.loadFilterData();
        }
    }

    //Methods
    loadFilterData() {
        this.filtersData = this.dashboardConfig.Filters;

        this.defaultFacilityId = []; 

        var facility = this.sortAscByText(this.filtersData.Facilities)
        if (this.profileData.FacilityId != null && this.profileData.FacilityId != undefined && this.profileData.FacilityId != 0) {
            this.defaultFacilityId.push(this.profileData.FacilityId.toString());
        }
        else {
            this.defaultFacilityId.push(facility[0].Value);
        }

        this.getDDLMasterData();

        this.selectedFacilities = this.filtersData.Facilities.filter((value: KeyPairModel) => {
            return value.Value == this.defaultFacilityId[0];
        });

        this.selectedClusters = [];
        this.selectedDepartments = [];
        this.selectedSummaries = null;

        this.selectedSkill = this.skills.filter((value: Skill) => {
            return this.defaultSkilllList.includes(value.EntityName);
        });

        this.startDateTime = moment(new Date()).add(1, 'days').format('MM/DD/YYYY');
    }

    setFilterDataFromState(){
            //Get Master data for all dropdown
            this.filtersData = this.dashboardConfig.Filters;
            this.getDDLMasterData();
            
            //set Facility
            if(this.sevenDayViewFilter.Facilities != null && this.sevenDayViewFilter.Facilities.length > 0){
                let facilityList: any[] = [];
                this.sevenDayViewFilter.Facilities.forEach((facility: any) =>{
                    //@ts-ignore
                    var selectFacility = jslinq(this.facilities)
                                        .where((item: any) => {
                                            return item.Value == facility;
                                        }).firstOrDefault();
                    facilityList.push(selectFacility);
                });
                this.selectedFacilities = facilityList;
            }

            //set Cluster
            //fetch cluster list by selected facility
            var clusterItems = this.getDistinct(
                this.getItems(this.selectedFacilities === null || this.selectedFacilities.length === 0
                    ? this.filtersData.Facilities
                    : this.selectedFacilities,
                    this.filtersData.Clusters));
            this.clusters = this.sortAscByText(this.getDistinct(clusterItems));

            //set selected cluster
            if(this.sevenDayViewFilter.Clusters != null && this.sevenDayViewFilter.Clusters.length > 0){
                let clusterList: any[] = [];
                this.sevenDayViewFilter.Clusters.forEach((cluster: any) =>{
                    //@ts-ignore
                    var selectCluster = jslinq(this.clusters)
                                        .where((item: any) => {
                                            return item.Value == cluster;
                                        }).firstOrDefault();
                    clusterList.push(selectCluster);
                });
                this.selectedClusters = clusterList;
            }

            //set Department
            //fetch department list by selected cluster and facility
            //@ts-ignore
            var facilityIds: string[] = this.getIntCollection(this.selectedFacilities, this.filtersData.Facilities);

            var departmentItems: KeyPairModel[] =
                this.getDepartmentItems(this.selectedClusters === null || this.selectedClusters.length === 0
                    ? clusterItems
                    : this.selectedClusters,
                    this.filtersData.Departments,
                    facilityIds);
            this.departments = this.sortAscByText(departmentItems);

            //set selected department
            if(this.sevenDayViewFilter.Departments != null && this.sevenDayViewFilter.Departments.length > 0){
                let deptList: any[] = [];
                this.sevenDayViewFilter.Departments.forEach((dept: any) =>{
                    //@ts-ignore
                    var selectdept = jslinq(this.departments)
                                        .where((item: any) => {
                                            return item.Value == dept;
                                        }).firstOrDefault();
                    deptList.push(selectdept);
                });
                this.selectedDepartments = deptList;
            }

            //set Summary Time
            var summaryItems = this.getDistinct(this.getItems(
                this.selectedDepartments === null || this.selectedDepartments.length === 0
                    ? departmentItems
                    : this.selectedDepartments,
                this.filtersData.Summaries));
            this.summaries = summaryItems; 
            
            //set selected Skill
            this.selectedSkill = this.skills.filter((value: Skill) => {
                return this.sevenDayViewFilter.SkillEntityIds.includes(value.EntityId);
            });

            //set StartDate, EndDate and other flags
            this.isShowPremiumLabor = this.sevenDayViewFilter.isShowPremiumLabor;
            this.isPI14Day = this.sevenDayViewFilter.isPI14Day;

            //set selected summaries time
            setTimeout(() => {
                this.startDateTime = moment(this.sevenDayViewFilter.StartDate).format('MM/DD/YYYY');
                this.selectedSummaries = this.sevenDayViewFilter.SelectedSummary;
            }, 1000);  
        }

    getDDLMasterData() {
        this.facilities = this.sortAscByText(this.filtersData.Facilities);
        this.clusters = this.sortAscByText(this.getDistinct(this.filtersData.Clusters));
        this.departments = this.getDepartments();
        this.summaries = this.getSummaries();
        this.skills = this.skillData;
    }

    getDepartments() {
        if (this.filtersData.Departments != null) {
            var selectedDepartments = this.filtersData.Departments.filter((value: KeyPairModel) => {
                return value.FacilityId == this.defaultFacilityId[0];
            });
            return this.sortAscByText(selectedDepartments);
        }
        else {
            return [];
        }
    }

    getSummaries() {
        if (this.filtersData.Departments != null) {
            var thisVar = this;
            var selectedDepartments = this.filtersData.Departments.filter(function (value: KeyPairModel) {
                return value.FacilityId == thisVar.defaultFacilityId[0];
            });
            if (selectedDepartments !== null && selectedDepartments.length > 0) {
                var deptIds: any = this.getIntCollection(selectedDepartments, this.filtersData.Departments, false);
                if (this.filtersData.Summaries != null) {
                    var selectedSummaries: KeyPairModel[] = this.filtersData.Summaries.filter(function (value: KeyPairModel) {
                        return deptIds.indexOf(value.ParentValue) >= 0;
                    });

                    return this.getDistinct(selectedSummaries);
                }
                else
                    return [];
            }
            else
                return [];
        }
        else
            return [];
    }

    sortAscByText(collection: KeyPairModel[]) {
        //@ts-ignore
        var result = jslinq(collection).distinct().orderBy(function (item: any) {
            return item.Text;
        }).toList();

        return result;
    }

    getDistinct(collection: KeyPairModel[] | null) {
        if (collection != null) {
            //@ts-ignore
            var results = jslinq(collection)
                .select(function (item: any) {
                    return { Value: item.Value, Text: item.Text };
                })
                .distinct()
                .orderBy(function (item: any) {
                    return item.Value;
                })
                .toList();

            return results;
        }
        else {
            return [];
        }
    }

    getIntCollection(filters: any, collection: any, allowNull: boolean) {
        var items = [];
        if (filters === undefined || filters === null || filters.length === 0) {
            if (allowNull) {
                return null;
            }
            items = collection;
        } else {
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i];
                var it = { ParentValue: filter.ParentValue, Value: filter.Value, Text: filter.Text };
                items.push(it);
            }
        }

        //@ts-ignore
        var result: any[] = jslinq(items).select(function (item) { return item.Value; }).toList();

        return result;
    }

    selectedFacilityChanged(){
            if(this.selectedFacilities != null && this.selectedFacilities.length > 0){
                var selectedFacilities = this.selectedFacilities;
            
                //@ts-ignore
                var facilityIds: string[] = this.getIntCollection(selectedFacilities, this.filtersData.Facilities);
                
                var clusterItems = this.getDistinct(
                    this.getItems(selectedFacilities,
                        this.filtersData.Clusters));

                var departmentItems: KeyPairModel[] = [];
                if(this.selectedClusters != null && this.selectedClusters.length > 0){
                    departmentItems = this.getDepartmentItems(this.selectedClusters,
                            this.filtersData.Departments,
                            facilityIds);
                }
                else{
                    departmentItems = this.getDepartmentItems(clusterItems,
                            this.filtersData.Departments,
                            facilityIds);
                }
                var summaryItems = this.getDistinct(this.getItems(
                    departmentItems,
                    this.filtersData.Summaries));

                this.clusters = this.sortAscByText(this.getDistinct(clusterItems));
                this.departments = this.sortAscByText(departmentItems);
                this.summaries = summaryItems;

                if(this.selectedClusters != null && this.selectedClusters.length > 0){
                    //@ts-ignore
                    var clusterIds: any[] = jslinq(this.clusters).select(function (item) { return item.Value; }).toList();

                    this.selectedClusters = this.selectedClusters.filter(function (value: KeyPairModel) {
                        return clusterIds.indexOf(value.Value) >= 0;
                    });
                }

                if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
                    //@ts-ignore
                    var departmentIds: any[] = jslinq(this.departments).select(function (item) { return item.Value; }).toList();

                    this.selectedDepartments = this.selectedDepartments.filter(function (value: KeyPairModel) {
                        return departmentIds.indexOf(value.Value) >= 0;
                    });
                }
            }
            else{
                this.selectedClusters = [];
                this.selectedDepartments = [];
                this.getDDLMasterData();
            }
        }
       
        selectedClusterChanged(){
            if(this.selectedClusters != null && this.selectedClusters.length > 0){
                var selectedFacilities = this.selectedFacilities;
                var selectedClusters = this.selectedClusters;

                //@ts-ignore
                var facilityIds: string[] = this.getIntCollection(selectedFacilities, this.filtersData.Facilities);

                var departmentItems: KeyPairModel[] =
                    this.getDepartmentItems(selectedClusters,
                        this.filtersData.Departments,
                        facilityIds);

                var summaryItems = this.getDistinct(this.getItems(
                    departmentItems,
                    this.filtersData.Summaries));

                this.departments = this.sortAscByText(departmentItems);
                this.summaries = summaryItems;

                
                if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
                    //@ts-ignore
                    var departmentIds: any[] = jslinq(this.departments).select(function (item) { return item.Value; }).toList();

                    this.selectedDepartments = this.selectedDepartments.filter(function (value: KeyPairModel) {
                        return departmentIds.indexOf(value.Value) >= 0;
                    });
                }
            }
            else{
                this.selectedDepartments = [];
                this.clusters = this.sortAscByText(this.getDistinct(this.filtersData.Clusters));
                this.departments = this.getDepartments();
                this.summaries = this.getSummaries();
            }
        }

    selectedDeptChanged(){
        if(this.selectedDepartments != null && this.selectedDepartments.length > 0){
            var selectedDepartments = this.selectedDepartments;

            var summaryItems = this.getDistinct(this.getItems(
                selectedDepartments === null || selectedDepartments.length === 0
                    ? this.departments
                    : selectedDepartments,
                this.filtersData.Summaries));

            this.summaries = summaryItems;
        }
        else{
            this.departments = this.getDepartments();
            this.summaries = this.getSummaries();
        }
    }

    setSelectedFilters() {
        var selectedFacilities = this.selectedFacilities;
        var selectedClusters = this.selectedClusters;
        var selectedDepartments = this.selectedDepartments;

        //@ts-ignore
        var facilityIds: string[] = this.getIntCollection(selectedFacilities, this.filtersData.Facilities);
        //@ts-ignore
        var clusterIds: string[] = this.getIntCollection(selectedClusters, this.filtersData.Clusters);

        var clusterItems = this.getDistinct(
            this.getItems(selectedFacilities === null || selectedFacilities.length === 0
                ? this.filtersData.Facilities
                : selectedFacilities,
                this.filtersData.Clusters));
        var departmentItems: KeyPairModel[] =
            this.getDepartmentItems(selectedClusters === null || selectedClusters.length === 0
                ? clusterItems
                : selectedClusters,
                this.filtersData.Departments,
                facilityIds);
        var summaryItems = this.getDistinct(this.getItems(
            selectedDepartments === null || selectedDepartments.length === 0
                ? departmentItems
                : selectedDepartments,
            this.filtersData.Summaries));

        if (selectedClusters !== null && selectedClusters.length > 0
            && selectedDepartments !== null && selectedDepartments.length > 0) {
            selectedDepartments = selectedDepartments.filter(function (value: KeyPairModel) {
                return clusterIds.indexOf(value.ParentValue) >= 0;
            });

            this.selectedDepartments = selectedDepartments;
        }

        if (selectedDepartments !== null && selectedDepartments.length > 0) {
            selectedDepartments = selectedDepartments.filter(function (value: KeyPairModel) {
                return facilityIds.indexOf(value.FacilityId) >= 0;
            });

            this.selectedDepartments = selectedDepartments;
        }

        this.clusters = this.sortAscByText(this.getDistinct(clusterItems));
        this.departments = this.sortAscByText(departmentItems);
        this.summaries = summaryItems;
        this.selectedSummaries = null;
    }

    getItems(filters: any, collection: KeyPairModel[] | null) {
        var result: any[] = [];

        if (filters !== undefined && filters !== null && filters.length > 0) {
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i].Value;

                //@ts-ignore
                var items = jslinq(collection).where(function (item) { return item.ParentValue === filter; }).distinct().toList();

                items.forEach((value) => { result.push(value); });
            }
        }

        result = this.sortAscByText(result);

        return result;
    }

    getSkillList(){
        var filterSkills: any[] = [];

        //@ts-ignore
        filterSkills = jslinq(this.selectedSkill)
            .select((filter: any)=>{
                return filter.EntityId;
            }).toList();

        return filterSkills;
    }

    getDepartmentItems(filters: any, collection: any, facilityIds: string[]) {
        var result: KeyPairModel[] = [];

        if (filters !== undefined && filters !== null && filters.length > 0) {
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i].Value;
                //@ts-ignore
                var items = jslinq(collection.filter(function (item: any) {
                    return item.ParentValue === filter;
                })).orderBy(function (item: any) {
                    return item.Text;
                }).distinct().toList();

                items.forEach(function (value: any) {
                    result.push(value);
                });
            }

            result = result.filter(function (value: any) {
                return facilityIds.indexOf(value.FacilityId) >= 0;
            });

            return result;
        }
        else
            return [];
    }

    getCurrentDateTime() {
      var date = moment(new Date()).format(this.longDateFormat);
      return date;
    }

    applyFilters(){
        if(this.selectedSkill == null || this.selectedSkill.length == 0){
            var errorData = {
                    message: "Please select skill.",
                    flag: "error"
                }
            this.emitter.emit('showToasterNotification7DayView', errorData);
            return;
        }

        var facilityIds: any[] = this.getIntCollection(this.selectedFacilities, this.filtersData.Facilities, false) as any[];
        var clusterIds = this.getIntCollection(this.selectedClusters, this.filtersData.Clusters, true);
        var departmentIds = this.getIntCollection(this.selectedDepartments, this.filtersData.Departments, true);
        var skillIds: any = null;
        
        var startTime: string = "";
        var endTime: string = "";
        var startDate: string = "";
        var endDate: string = "";
        var isSameDate: boolean = false;
        var noOfDaysToAdd: number = 6;
        if(this.selectedSummaries != null){
            var selectedShift: any = this.selectedSummaries.split(' - ');
            startTime = moment(selectedShift[0]).format("HH:mm");
            endTime = moment(selectedShift[1]).format("HH:mm");
            startDate = moment(selectedShift[0]).format(this.shortDateFormat);
            endDate = moment(selectedShift[1]).format(this.shortDateFormat);
            isSameDate = moment(startDate).isSame(endDate);
            noOfDaysToAdd = isSameDate ? noOfDaysToAdd : 7;
        }

        var input: SevenDayInput = {
                DashboardId: this.profileData.DashboardId,
                StartDate: moment(this.startDateTime).format(this.shortDateFormat),
                EndDate: moment(this.startDateTime).add(noOfDaysToAdd, 'days').format(this.shortDateFormat),
                CurrentDateTime: this.getCurrentDateTime(),
                Facilities: facilityIds,
                Clusters: clusterIds,
                Departments: departmentIds,
                SkillEntityIds: this.getSkillList(),
                StartShiftSummary: startTime,
                EndShiftSummary: endTime,
                UserId: this.profileData.UserId,
                isShowPremiumLabor: this.isShowPremiumLabor,
                isPI14Day: this.isPI14Day,
                isPI7Day: !this.isPI14Day,
                SelectedSummary: this.selectedSummaries
            }
        
        this.emitter.emit('close7DayGridFilterPanel');
        this.saveAppliedFilterToState(input);
    }

    clearFilters(){
        this.loadFilterData();
        this.isShowPremiumLabor = false;
        this.isPI14Day = true;

        var facilityIds: any[] = this.getIntCollection(this.selectedFacilities, this.filtersData.Facilities, false) as any[];
        var clusterIds = this.getIntCollection(this.selectedClusters, this.filtersData.Clusters, true);
        var departmentIds = this.getIntCollection(this.selectedDepartments, this.filtersData.Departments, true);

        var input: SevenDayInput = {
                DashboardId: this.profileData.DashboardId,
                StartDate: moment(this.startDateTime).format(this.shortDateFormat),
                EndDate: moment(this.startDateTime).add(6, 'days').format(this.shortDateFormat),
                CurrentDateTime: this.getCurrentDateTime(),
                Facilities: facilityIds,
                Clusters: clusterIds,
                Departments: departmentIds,
                SkillEntityIds: this.getSkillList(),
                StartShiftSummary: "",
                EndShiftSummary: "",
                UserId: this.profileData.UserId,
                isShowPremiumLabor: this.isShowPremiumLabor,
                isPI14Day: this.isPI14Day,
                isPI7Day: !this.isPI14Day,
                SelectedSummary: null
            }
            
        this.emitter.emit('close7DayGridFilterPanel');
        this.saveAppliedFilterToState(input);
    }

    async saveAppliedFilterToState(filter: any){
        await this.$store
            .dispatch("dashboard/saveSevenDayViewFilter", filter)
            .then(() => {
                this.emitter.emit("loadDashboardGrid");
            })
            .catch((err: any) => {
                
            });
    }
    resetFacilityDropdown() {
        this.selectedFacilities = null;
        this.selectedFacilityChanged();
    }
    resetServiceLineDropdown() {
        this.selectedClusters = null;
        this.selectedClusterChanged();
    }
    resetDepartmentDropdown() {
        this.selectedDepartments = null;
        this.selectedDeptChanged();
    }
    resetSkillDropdown() {
           this.selectedSkill = null;
    }
}
</script>
<style scoped>
.dropdown-menu{
  width: 660px;
  padding: 15px !important;
}

.filterShow{
  display: block !important;
}
.close-child {
       position: absolute;
       float: right;
       right: 40px;
       top: 13px;
       cursor: pointer;
       font-size: 11px;
       z-index: 50;
    }

    .close-parent {
        position: relative;
    }

    .scrollableDiv-filter {
    overflow-y: auto!important;
    overflow-x: hidden;
    max-height: calc(100vh - 275px);
  }
  .width100{
    width:100%;
  }

</style>
<style>
 .multiselect-scroll > div > .multiselect__tags {
    max-height: 108px!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
   
    
  }

  .multiselect-scroll > div > .multiselect__select {
    margin-right: 7px!important;
   
    
  }
</style>