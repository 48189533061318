import { Profile } from "@/models/profile/index";
import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex';
import { RootState } from "../types";
import { sortAscByKey } from "@/utils/utils";

const namespaced = true

export interface ProfileState {
    profileData: Profile 
    userAccounts: any
    username: string
    appInsightEventData:any
}

//state
const state: ProfileState = {
    profileData: {
        TenantId: '',
        TenantName: '',
        ApiUrl: '',
        AppUrl: '',
        UserName: '',
        DashboardId: '',
        UserId: 0,
        FacilityId: 0,
        StaffingExceptionView: true,
        PlusMetricEnabled: true,
        IsSurgeAlertFeatureEnabled: true,
        ContractLaborEnabled: true, 
        ForecastCensusEditEnabled: true,
        ManageCensusEnabled: true,
        CensusEditEnabled: true,
        UserNotificationEnabled: true,
        ContractLaborUsingKafkaEnabled: true,
        ContractLaborBulkCreateEnabled: true,
        DashboardDailyRosterPrint: true,
        ContractLaborNeedAutomation: true,
        SchedulingDashboard: false,
        OptInForScheduleHelper: false,
        TrainingEnabled: false,
        Permissions: []
    },
    userAccounts: null,
    username: '',
    appInsightEventData : {
        Username: '',
        TenantName: '',
        FacilityName: '',
        COID: '',            
        DeptCode: '',            
        DeptName: '',
        Skill: ''
    }
}

//actions
const actions: ActionTree<ProfileState, RootState> = {
    getUserAccounts({ commit }) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Security/Account`;

        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null){
                    commit('setUserAccounts', res.data);
                    if(res.data.userAccounts != null && res.data.userAccounts.length > 0){
                       const urlTenantId = window.location.pathname.split('/').length > 2 && window.location.pathname.split('/')[1] == 'dashboard' ? window.location.pathname.split('/')[2] : '';
                        if(urlTenantId == null || urlTenantId == undefined || urlTenantId == "")
                        {

                             if (window.location.pathname.split('/').length == 2 && window.location.pathname.split('/')[1] == 'dashboard') {
                                window.location.replace(window.location.origin + "/dashboard/" + res.data.userAccounts[0].tenantId);
                                return;
                        }  
                        }
                        
                        let profileIndex = res.data.userAccounts.findIndex((a: { tenantId: string; }) => a.tenantId == urlTenantId);
                        if(profileIndex < 0 || profileIndex == null || profileIndex == undefined) {
                            profileIndex = 0;
                        }
                        const faciltyid = window.location.pathname.split('/').length > 3 && window.location.pathname.split('/')[1] == 'dashboard' ? window.location.pathname.split('/')[3] : '0';
                       
                        const input: any = {
                            profileIndex: profileIndex,
                            facilityId: faciltyid
                        }

                        commit('setProfile', input);
                    }
                }
                else{
                    commit('setUserAccounts', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    setProfileByTenant({ commit }, input: any) {
        commit('setProfile', input);
    },
    updateAppInsights({ commit }, input: any){
        commit('setAppInsights', input);
    }
}

// mutations
const mutations: MutationTree<ProfileState> = {
    setUserAccounts(state, result: any){
        state.username = result.username;
        state.userAccounts = sortAscByKey(result.userAccounts, 'tenantName'); // sorting

        // TO DO:  update as tenant selection changes.  Blocker for #2
        // TO DO:  Stop UI loading until user info supplied
        // TO DO:  Access Denied view if does not have any valid tenants with read dashboard permission - Discuss AM who can work on this?
        // TO DO:  How are other permissions being checked? - Ketan
        // TO DO:  Tenant feature toggles - Jon
    },
    setProfile(state, input: any) {

        const userAccount = state.userAccounts[input.profileIndex];
        const permissions = (userAccount.permissions != null && userAccount.permissions.length > 0) ? userAccount.permissions : null;
        const periodIndex = userAccount.tenantUrl.indexOf('.');
        const profileDetails: Profile = {
            TenantId: userAccount.tenantId,
            TenantName: userAccount.tenantName,
            ApiUrl: userAccount.tenantUrl.substring(0, periodIndex) + '.api' + userAccount.tenantUrl.substring(periodIndex, userAccount.tenantUrl.length) + 'api/v1/',
            AppUrl: userAccount.tenantUrl,
            UserName: state.username,
            DashboardId: process.env.VUE_APP_DASHBOARDID != undefined ? process.env.VUE_APP_DASHBOARDID  : '', // does this ever change?  Remove from API / DB, part of API migration along with camel-case
            UserId: userAccount.userId,
            FacilityId: input.facilityId, 
            StaffingExceptionView: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.StaffingExceptions'),
            PlusMetricEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.PlusMetrics'),
            IsSurgeAlertFeatureEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.SurgeAlertVisibility'),
            ContractLaborEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.ContractLabor'), 
            ForecastCensusEditEnabled: (permissions != null && permissions.indexOf('Department - Edit Forecast Census') != -1) ? true : false, 
            ManageCensusEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.ManageCensusInDashboard'),
            CensusEditEnabled: (permissions != null && permissions.indexOf('Census - Change') != -1) ? true : false, 
            UserNotificationEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.UserNotificationsManagement'),
            ContractLaborUsingKafkaEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.ContractLaborUsingKafka'),
            ContractLaborBulkCreateEnabled: (permissions != null && permissions.indexOf('Staffing Dashboard - Bulk CL') != -1) ? true : false, 
            DashboardDailyRosterPrint: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.DashboardDailyRosterPrint'),
            ContractLaborNeedAutomation: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.ContractLaborNeedAutomation'),
            SchedulingDashboard: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.SchedulingDashboard'),
            TrainingEnabled: userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.PointofUseTraining'),
            OptInForScheduleHelper: userAccount.optInForScheduleHelper && userAccount.featureToggles.includes('AAS.FacilityScheduler.UI.Web.Features.ScheduleMaintenance'),
            Permissions: userAccount.permissions
        }    
        
        sessionStorage["tenant"] = userAccount.tenantId;
        state.profileData = profileDetails;
        state.appInsightEventData = {
            Username:  state.username,
            TenantName:  userAccount.tenantName,
            FacilityName: input.FacilityName,
            COID: input.COID,            
            DeptCode: input.DeptCode,            
            DeptName: input.DeptName,
            Skill: input.Skill
        };

        

    },

    setAppInsights(state, input: any) {

        state.appInsightEventData = {
            Username:  input.Username,
            TenantName:  input.TenantName,
            FacilityName: input.FacilityName,
            COID: input.COID,            
            DeptCode: input.DeptCode,            
            DeptName: input.DeptName,
            Skill: input.Skill
        };
    }
}

export const profile: Module<ProfileState, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

