<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Department Summary Period Notes</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody scrollBar">
                <div id="departmentsummary-notes-header" class="txtLeft" style="width:inherit">
                    <div class="card shadow-sm mb-3 bg-white rounded">
                        <div class="card-body position-relative">
                            <div class="row">
                                <div class="col-md-9">
                                    <h6 class="card-title mb-2">{{facilityName}}</h6>
                                    <h6 class="card-subtitle mb-2 text-muted"><span>{{serviceLineName}}</span> > <span>{{departmentName}}</span></h6>
                                    <div><font-awesome-icon :icon="['far', 'calendar']" size="sm" class="mr-2" /><span class="font-weight-bold">{{startDate}}</span></div>
                                    <div><font-awesome-icon :icon="['far', 'clock']" size="sm" class="mr-1" /> <span class="font-weight-bold">{{startTime}}</span> - <span class="font-weight-bold">{{endTime}}</span></div>
                                </div>
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-9 text-end">Staff To:</div>
                                        <div class="col-md-3 font-weight-bold">{{staffTo}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 text-end">Actual:</div>
                                        <div class="col-md-3 font-weight-bold">{{actualCensus}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9 text-end">Forecast:</div>
                                        <div class="col-md-3 font-weight-bold">{{forecastCensus}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm mb-3 bg-white rounded content">
                    <div class="card-body position-relative" style="overflow: auto;">
                        <div class="row">
                            <div class="col">
                                <div id="departmentsummary-notes-container">
                                    <AddViewNote />
                                </div>
                            </div>
                        </div>       
                    </div>
                </div>
                <div id="departmentSummaryLoadingDiv" class="loadingDiv1" style="padding: 5px; display: none;">
                    <div id="actionable-content-loading" style="text-align: center">
                        <div class="fa-2x">
                            <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                        </div>
                        <p><b>Saving...</b></p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import moment from "moment";

//imports models and enums
import { NoteType, NoteRelationType} from "@/enums/enum";
import { Profile } from '@/models/profile/index';

//Import Componenets
import AddViewNote from '../assignment/AddViewNote.vue';
class Props {
    noteInput!: any
}

@Options({
  computed: {
    ...mapState('profile', ['profileData'])
  },
  components: {
      AddViewNote
  }
})
export default class DepartmentSummaryNote extends Vue.with(Props) {
    //Data properties
    profileData!: Profile;
    assignId: any = null;
    facilityId: any = null;
    facilityCoId: any = null;
    facilityName: any = null;
    clusterId: any = null;
    clusterName: any = null;
    departmentId: any = null;
    departmentName: any = null;
    departmentCode: any = null;
    departmentSummaryId: any = null;
    startDateTime: any = null;
    endDateTime: any = null;
    skillLevelId: any = null;
    skillLevelDesc: any = null;
    startDate: any = null;
    endDate: any = null;
    startTime: any = null;
    endTime: any = null;
    actualCensus: any = null;
    forecastCensus: number = 0;
    forecastCensusPreviousValue: number = 0;
    staffTo: any = null;
    staffToPreviousValue: any = null;
    scheduled: any = null;
    target: any = null;
    skillLevelDescription: any = null;            
    serviceLineName: any = null;
    note: any = null;
    shortDateFormatPresentation: string = 'MM/DD/YYYY';
    shortTimeFormat: string = 'hh:mm A'

    //Lifecycle hooks
    mounted(){
        this.loadNoteModule();
        this.mapDepartmentSummaryModel();
    }

    //Methods
    mapDepartmentSummaryModel(){
        var data: any = this.noteInput.DataSet;

        var startDateTime = new Date(data.StartDateTime);
        var endDateTime = new Date(data.EndDateTime);

        this.assignId = this.getValue(data.AssignId);
        this.facilityId = this.getValue(data.FacilityId);
        this.facilityCoId = this.getValue(data.FacilityCoid);
        this.facilityName = this.getValue(data.FacilityName) + ' (' + this.facilityCoId + ')';
        this.clusterId = this.getValue(data.ClusterId);
        this.clusterName = this.getValue(data.ClusterName);
        this.departmentId = this.getValue(data.DepartmentId);
        this.departmentCode = this.getValue(data.DepartmentCode);
        this.departmentName = this.getValue(data.DepartmentName)+ ' (' + this.departmentCode + ')';
        this.departmentSummaryId = this.getValue(data.DepartmentSummaryId);
        this.startDateTime = this.getValue(data.StartDateTime);
        this.endDateTime = this.getValue(data.EndDateTime);
        this.startDate = moment(startDateTime).format(this.shortDateFormatPresentation);
        this.endDate = moment(endDateTime).format(this.shortDateFormatPresentation);
        this.startTime = moment(startDateTime).format(this.shortTimeFormat);
        this.endTime = moment(endDateTime).format(this.shortTimeFormat);
        this.skillLevelId = this.getValue(data.SkillLevelId);
        this.skillLevelDesc = this.getValue(data.SkillLevelDesc);
        this.actualCensus = this.getValue(data.ActualCensus) || '-';
        this.forecastCensus = this.getValue(data.ForecastCensus);
        this.forecastCensusPreviousValue = this.getValue(data.ForecastCensus);
        this.staffTo = this.getValue(data.StaffTo) || '-';
        this.staffToPreviousValue = this.getValue(data.StaffTo);
        this.scheduled = this.getValue(data.Scheduled);
        this.target = this.getValue(data.Target);
        this.skillLevelDescription = this.getValue(data.SkillLevelDescription);
        this.skillLevelId = this.getValue(data.SkillLevelId);
        this.serviceLineName = this.clusterName;
        this.note = '';    
    }

    loadNoteModule(){
        var model = {
            NoteTypeId: NoteType.COMMENTS,
            DepartmentId: this.noteInput.DataSet.DepartmentId,
            RelationId: NoteRelationType.FS_DEPT,
            TenantId: this.profileData.TenantId,
            CostCenterId: this.noteInput.DataSet.FacilityId,
            RelationIdValue: this.noteInput.DataSet.DepartmentId,
            UserId: this.profileData.UserId,
            StartDateTime: this.noteInput.DataSet.StartDateTime,
            EndDateTime: this.noteInput.DataSet.EndDateTime,
        };

        this.emitter.emit('loadNotes', model);
    }

    getValue(obj: any) {
        if (typeof obj === undefined) {
            return null;
        }
        return obj;
    }
    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 65%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    padding: 5px 12px;
    height:85%;
    overflow: auto;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}
</style>
