import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";

const namespaced = true

interface State {
    metricsData: any,
    dashboardConfiguration: any,
    contractlaborCalloutData: any,
    deptForecastCensus: any
    deptCensus: any,
    patientClassificationRatingDetails: any,
    createPatientClassificationRatingResult: any,
    updatePatientClassificationRatingResult: any,
    pendingContractLaborResult: any,
    workforceRequestStatusResult: any,
    recentActivityLogs: any,
    sevenDayViewData: any,
    premiumLaborBreakdownByCL: any,
    aggregatedDashbaordFilter: any,
    sevenDayViewFilter: any,
    trainingVideos: any
}

//state
const state: State = {
    metricsData: null,
    dashboardConfiguration: null,
    contractlaborCalloutData: null,
    deptForecastCensus: null,
    deptCensus: null,
    patientClassificationRatingDetails: null,
    createPatientClassificationRatingResult: null,
    updatePatientClassificationRatingResult: null,
    pendingContractLaborResult: null,
    workforceRequestStatusResult: null,
    recentActivityLogs: null,
    sevenDayViewData: null,
    premiumLaborBreakdownByCL: null,
    aggregatedDashbaordFilter: null,
    sevenDayViewFilter: null,
    trainingVideos: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getDashboardConfiguration({ commit, rootState }, data: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Dashboards/config/${data.DashboardId}/${data.CurrentDateTime}`;
        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null) {
                    commit('setDashboardConfiguration', res.data.Data.Results);
                }
                //return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    getMetricsDetails({ commit, rootState }, metricsFilter: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Dashboards/metrics/`;

        return http
            .post(apiUrl, metricsFilter)
            .then((res) => {
                if (res != null) {
                    commit('setMetricsData', res.data.Data.Results);
                }
                //return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
                //return Promise.reject(err);
            });
    },
    getContractlaborCalloutDetails({ commit, rootState }, metricsFilter: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Dashboards/GetCalloutAndContractlaborStaffByDepartments/`;

        return http
            .post(apiUrl, metricsFilter)
            .then((res) => {
                if (res != null) {
                    commit('setContractlaborCalloutDetails', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDeptForecastCensus({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/forecastbydepartment/${inputData.departmentId}/${inputData.startDateTime}/${inputData.startDateTimeFormatted}/${inputData.tenantId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setDeptForecastCensus', res.data.data.results);
                }
                else{
                    commit('setDeptForecastCensus', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getDeptCensus({ commit, rootState }, inputs: any) {
        
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/getbydepartmentasync/${inputs.departmentId}/${inputs.startDateTime}/${inputs.tenantId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setDeptCensus', res.data.data.results);
                }
                else{
                    commit('setDeptCensus', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getPatientClassificationRatingDetails({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/pcsratings/getpatientclassificationratingwithdetails/${inputs.patientClassificationRatingId}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setPatientClassificationRatingDetails', res.data.data.results);
                }
                else{
                    commit('setPatientClassificationRatingDetails', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    updateStaffTo({ commit, rootState }, inputs: any){
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census/updatecensusformanagecensus`;
        
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                
            })
            .catch((err) => {
                throw err;
            });  
    },
    updateForecastCensus({ commit, rootState }, inputs: any){
        
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/census`;
        return http
            .patch(apiUrl, inputs)
            .then((res) => {
                
            })
            .catch((err) => {
                throw err;
            });  
    },
    createPatientClassificationRating({ commit, rootState }, inputs: any){
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/pcsratings/createpatientclassificationratingwithdetails`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setCreatePatientClassificationRating', res.data.data.results);
                }
                else{
                    commit('setCreatePatientClassificationRating', null);
                }
            })
            .catch((err) => {
                throw err;
            });  
    },
    updatePatientClassificationRating({ commit, rootState }, inputs: any){
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/pcsratings/updatepatientclassificationratingwithdetails`;

        return http
            .put(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setUpdatePatientClassificationRating', res.data.data.results);
                }
                else{
                    commit('setUpdatePatientClassificationRating', null);
                }
            })
            .catch((err) => {
                throw err;
            });  
    },
    getCLPendingConfirmation({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts/byUserDepartments?statusIds=${inputs.statusIds}&fromRequestDate=${inputs.requestDate}`;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.Data != null)){
                    commit('setCLPendingConfirmation', res.data.Data.Results);
                }
                else{
                    commit('setCLPendingConfirmation', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    postWorkforceRequestStatus({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlaborshifts/workforcerequeststatus`;
        
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null)){
                    commit('setWorkforceRequestStatus', res.data.data.results);
                }
                else{
                    commit('setWorkforceRequestStatus', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getRecentActivityLogs({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/contractlabortransactionlogs/search?FsUserId=`+ inputs.FsUserId + `&FromCreatedOnDateUtc=` + inputs.FromCreatedOnDateUtc + `&ToCreatedOnDateUtc=` + inputs.ToCreatedOnDateUtc
                                    + `&TenantId=` + inputs.TenantId;
        
        return http
            .get(apiUrl)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.data != null)){
                    commit('setRecentActivityLogs', res.data.data.results);
                }
                else{
                    commit('setRecentActivityLogs', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSevenDataViewData({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Dashboards/SevenDayView`;
        
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.Data != null)){
                    commit('setSevenDayViewData', res.data.Data.Results);
                }
                else{
                    commit('setSevenDayViewData', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getPremiumLaborBreakdownByCL({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Dashboards/PremiumLaborCount`;
        
        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if(res != null && 
                    (res.data != null && res.data != '') && 
                    (res.data.Data != null)){
                    commit('setPremiumLaborBreakdownByCL', res.data.Data.Results);
                }
                else{
                    commit('setPremiumLaborBreakdownByCL', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    }, 
    saveAggregatedDashbaordFilter({ commit, rootState }, inputs: any) {
        if(inputs != null){
            commit('setAggregatedDashbaordFilter', inputs);    
        }
        else{
            commit('setAggregatedDashbaordFilter', null); 
        }  
    }, 
    saveSevenDayViewFilter({ commit, rootState }, inputs: any) {
        if(inputs != null){
            commit('setSevenDayViewFilter', inputs);      
        }
        else{
            commit('setSevenDayViewFilter', null);  
        }      
    },
    getTrainingVideos({ commit, rootState }, data: any){
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/FacilityConfigs/getTrainingVideos/${data}`;

        return  http
            .get(apiUrl)
            .then((res) => {

                if(res != null && (res.data != null && res.data != '')){
                    commit('setTrainingVideos',res.data);

            }
            else{
                commit('setTrainingVideos', "");
            }
            })
            .catch((err) => {
                throw err;
            });



            
    }   
}

// mutations
const mutations: MutationTree<State> = {
    setMetricsData(state, result: any) {
        state.metricsData = result;
    },
    setDashboardConfiguration(state, result: any) {
        state.dashboardConfiguration = result;
    },
    setContractlaborCalloutDetails(state, result: any) {
        state.contractlaborCalloutData = result;
    },
    setDeptForecastCensus(state, result: any) {
        state.deptForecastCensus = result;
    },
    setDeptCensus(state, result: any) {
        state.deptCensus = result;
    },
    setPatientClassificationRatingDetails(state, result: any) {
        state.patientClassificationRatingDetails = result;
    },
    setCreatePatientClassificationRating(state, result: any) {
        state.createPatientClassificationRatingResult = result;
    },
    setUpdatePatientClassificationRating(state, result: any) {
        state.updatePatientClassificationRatingResult = result;
    },
    setCLPendingConfirmation(state, result: any) {
        state.pendingContractLaborResult = result;
    },
    setWorkforceRequestStatus(state, result: any) {
        state.workforceRequestStatusResult = result;
    },
    setRecentActivityLogs(state, result: any) {
        state.recentActivityLogs = result;
    },
    setSevenDayViewData(state, result: any) {
        state.sevenDayViewData = result;
    },
    setPremiumLaborBreakdownByCL(state, result: any) {
        state.premiumLaborBreakdownByCL = result;
    },
    setAggregatedDashbaordFilter(state, result: any) { 
        state.aggregatedDashbaordFilter = result;
    },
    setSevenDayViewFilter(state, result: any) {
        state.sevenDayViewFilter = result;
    },
    setTrainingVideos(state, result: any) {
        state.trainingVideos = result;
    }
}

export const dashboard: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}



