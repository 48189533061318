<template>
  <SubFilterComponent 
    show-date-range 
    :filter-columns="ScheduleFilterColumns" 
    :default-filters="[]" 
    @subnavinitialized="subNavInitialized"
    @filters-applied="filtersApplied"
    :schedule-phase-end="departmentSchedule?.schedulePhaseEnd"
    :schedule-status="departmentSchedule?.scheduleStatus"
    :helper-enabled="departmentSchedule?.helperEnabled"
    :is-editable="isEditable"
    :is-schedule-rejected = "isScheduleRejected"
    :tier-name="departmentSchedule?.tierName"
  >
  </SubFilterComponent>
  <div
    v-if="isGridVisible"
  >
    <neu-container
      v-if="!screenLoading"
      class="mt-2 mw-100 content-wrapper grid-margin-top"
    >
      
      <MetricComponent :schedule-status="scheduleStatus" :metrics="metrics" :helper-enabled="helperEnabled"></MetricComponent>
        
      <div class="grid-container" style="width: 100%">
        <GridComponent
          v-if="!gridLoading"
          ref="gridSection"
          :key="gridKey"
          :show-checkbox="false"
          :show-filters="true"
          :show-pagination="false"
          :show-row-count="false"
          :show-fixed-columns="true"
          :columns-data="gridColumns"
          :rows-data="scheduleRows"
          :is-api-data="true"
          :store-data="true"
          :infinity-scroll="true"
          :preferences-triangle-modal="preferencesTriangleToggle"
          :loader="false"
          :default-filters="[]"
          @filter-data="filterData"
          @clear-filter="clearFilter"
        >
          <template #indication="props">
            <div v-if="props.row.indicationType === 'OVER'">
              <neu-tooltip style="margin-left: 5px">
                <neu-label
                  v-if="props.row.indicationToolTip"
                  slot="tooltip"
                  class="left-text-align"
                >
                  {{ props.row.indicationToolTip }}
                </neu-label>
                <neu-icon slot="content" :left="true" color="red-40">event_busy</neu-icon>
              </neu-tooltip>
            </div>
            <div v-if="props.row.indicationType == 'UNDER'">
              <neu-tooltip style="margin-left: 5px">
                <neu-label
                  v-if="props.row.indicationToolTip"
                  slot="tooltip"
                  class="left-text-align"
                >
                  {{ props.row.indicationToolTip }}
                </neu-label>
                <neu-icon slot="content" :left="true" color="yellow-40">
                calendar_today
              </neu-icon>
              </neu-tooltip>
            </div>            
          </template>
          <template #icon="props">
            <div style="display: inline-flex">
              <div v-if="props.row.rnOneYear === '< 1 YR'">
                <neu-tooltip style="margin-left: 5px; top: 3px">
                  <neu-label
                    v-if="props.row.rnOneYear"
                    slot="tooltip"
                    class="left-text-align"
                  >
                    Staff with &lt;1 year experience
                  </neu-label>
                  <neu-icon slot="content" :left="true" color="blue-60">star_outline</neu-icon>
                </neu-tooltip>
              </div>      
              <div v-if="props.row.overtime === true">
                <neu-tooltip style="margin-left: 5px; top: 3px">
                  <neu-label
                    v-if="props.row.overtimeTooltip"
                    slot="tooltip"
                    class="left-text-align"
                  >
                    {{ props.row.overtimeTooltip }}
                  </neu-label>
                  <neu-icon slot="content" :left="true" color="red-60">more_time</neu-icon>
                </neu-tooltip>
              </div> 
              <div v-if="props.row.preceptor === true">
                <neu-tooltip style="margin-left: 5px; top: 3px">
                  <neu-label
                    v-if="props.row.preceptor === true"
                    slot="tooltip"
                    class="left-text-align"
                  >
                    {{ props.row.preceptorTooltip }}
                  </neu-label>
                  <neu-icon slot="content" :left="true" color="blue-60">supervisor_account</neu-icon>
                </neu-tooltip>
              </div>
              <div v-if="props.row.orientee === true">
                <neu-tooltip style="margin-left: 5px; top: 3px">
                  <neu-label
                    v-if="props.row.orientee === true"
                    slot="tooltip"
                    class="left-text-align"
                  >
                    {{ props.row.orienteeTooltip }}
                  </neu-label>
                  <neu-icon slot="content" :left="true" color="blue-60">school</neu-icon>
                </neu-tooltip>
              </div>    
            </div>
            <div v-if="props.row.departmentAssociationType == 2">
              <neu-tooltip style="margin-left: 5px">
                <neu-label
                  v-if="props.row.indicationToolTip"
                  slot="tooltip"
                  class="left-text-align"
                >
                  Secondary Association
                </neu-label>
                <neu-icon slot="content" :left="true" color="blue-60">
                  supervised_user_circle
              </neu-icon>
              </neu-tooltip>
            </div>
            <div v-if="props.row.departmentAssociationType == 3">
              <neu-tooltip style="margin-left: 5px">
                <neu-label
                  v-if="props.row.indicationToolTip"
                  slot="tooltip"
                  class="left-text-align"
                >
                  Unassociated
                </neu-label>
                <neu-icon slot="content" :left="true" color="blue-60">
                person_pin
              </neu-icon>
              </neu-tooltip>
            </div>        
          </template>
          <template #date="props">
            <div
              class="data_cell"
              :class="
                deriveDateCellClassesAndTooltip(
                  props,
                  props.column.id,
                  props.row.row_id
                )
              "
              @click="dateCellClick(props, $event)"
            >
              <div :key="`${props.row.row_id}-${list.type}`" v-for="list in getStaffAvailiblityIcon(props.column.id, props.row.row_id, props.column.year)">
                <neu-tooltip style="position: absolute !important; left: 0; top: 0;" v-if="list.display && list.img">
                  <neu-label
                    slot="tooltip"
                  >
                    {{ list.tooltip }}
                  </neu-label>
                  <neu-image
                    width="25px"
                    :src="getImgUrl(list)"
                    slot="content"
                  ></neu-image>
                </neu-tooltip>
              </div>
              <span :class="getAssignmentCodeColor(props.row[props.column.id])">
                {{ props.row[props.column.id]?.text }}</span
              >
            </div>
          </template>
          
        </GridComponent>
        <ScheduleSummaries
          v-if="!gridLoading"
          ref="scheduleSummary"
          :columns-data="gridColumns"
          :dateCellProps="dateCellProps"
          @schedule-data="triggerSchedule"
          :is-editable="isEditable"
        />
      </div>
    <LegendIcons  v-show="!gridLoading" :status="scheduleStatus" :helper-enabled="helperEnabled"/>
    </neu-container>
    <neu-container v-if="screenLoading || gridLoading" class="mt-9 mw-100">
      <div class="spinner-container">
        <neu-spinner class="spinner" color="primary" sm="true"> </neu-spinner>
      </div>
    </neu-container>
  </div>
  <NeuContainer v-else class="text-wrap">
    <neu-label
      v-if="       
        securityStore.getSelectedFacility === ''
      "
    >
      Select a facility to access available department list
    </neu-label>
    <neu-label
      v-if="
        securityStore.getSelectedFacility != '' &&
        securityStore.getSelectedDepartment === ''
      "
    >
      Select a department to access available date list
    </neu-label>
  </NeuContainer>
  <div
    v-if="isSidebarOpen && isViewable"
    class="sidebar-container"
    :class="{ sidebar_opened: isSidebarOpen }"
  >
   <AddOrCancelShiftModal
      v-if="openAddShift"
      :key="selectCellKey + 1"
      :date-cell-props="dateCellProps"
      :selected-department-id="selectedDepartmentId"
      @modal_close="closeSidebar"
      @submit-modal="submitData($event)"
      :is-editable="isEditable"
      :selected-date="selectedDate"
    />

    <AddOrCancelShiftModal
      v-if="openCancelShift"
      :key="selectCellKey + 2"
      :date-cell-props="dateCellProps"
      modal-title="Shift Details"
      :cancel-modal="true"
      :schedule-status="scheduleStatus"
      :helper-enabled="helperEnabled"
      :selected-department-id="selectedDepartmentId"
      :modal-save="'Cancel Shift'"
      @modal_close="closeSidebar"
      @submit-modal="submitData($event)"
      @update-modal="updateAssignmentData($event)"
      :is-editable="isEditable"
      :selected-date="selectedDate"
      @delete-modal="deleteAssignmentData()"      
    />
  </div>
</template>

<script lang="ts">
import { NeuContainer } from '@neutron/vue'
import { defineAsyncComponent, defineComponent } from '@vue/runtime-core'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import { GridColumnModel } from '@/models/scheduleManagement/grid.model'
import { AssignmentsModel } from '@/models/scheduleManagement'
import { WeeklyCommitmentsModel } from '@/models/scheduleManagement/department.schedule.model'
import shiftPanels from '@/mixins/shiftPanels'
import SecurityModule from '@/store/modules/security'
import ScheduleModule from '@/store/modules/schedule'
import {Permissions} from '@/common/permissions'
import { mapState } from 'vuex'
import {
  AssignmentCancelModel,
  AssignmentModel,
} from '@/models/scheduleManagement/shiftPanels.model'
const GridComponent = defineAsyncComponent(
  () =>
    import(
      '@/components/scheduleManagement/GridComponent.vue' /* webpackChunkName: "GridComponent" */
    )
)
const LegendIcons = defineAsyncComponent(
  () =>
    import(
      '@/components/scheduleManagement/LegendIcons.vue' /* webpackChunkName: "LegendIcons" */
    )
)
const SubFilterComponent = defineAsyncComponent(
  () =>
    import(
      '@/components/scheduleManagement/SubFilterComponent.vue' /* webpackChunkName: "SubFilterComponent" */
    )
)
const AddOrCancelShiftModal = defineAsyncComponent(
  () =>
    import(
      '@/components/scheduleManagement/AddOrCancelShiftModal.vue' /* webpackChunkName: "AddOrCancelShiftModal" */
    )
)
const ScheduleSummaries = defineAsyncComponent(
  () =>
    import(
      '@/components/scheduleManagement/ScheduleSummaries.vue' /* webpackChunkName: "ScheduleSummaries" */
    )
)
const MetricComponent = defineAsyncComponent(
  () =>
    import(
      '@/components/scheduleManagement/MetricComponent.vue' /* webpackChunkName: "MetricComponent" */
    )
)

let scheduleModule: ScheduleModule
let baseGridColumns: GridColumnModel[]
let scheduleRows: any[]
let dateColumns: GridColumnModel[]
baseGridColumns = [
  {
    id: 'indication',
    title: '',
    sort: false,
    slot: true,
    slotName: 'indication',
    filters: false,
    minWidth: 40,
    applySort: false,
  },
  {
    id: 'name',
    title: 'Name',
    iconSlot: true,
    slotName: 'icon',
    slotContainerStyle: 'display:inline-flex;',
    sort: true,
    applySort: true,
    filters: true,
    minWidth: 200,
  },
  {
    title: 'Skill',
    id: 'skill',
    sort: false,
    applySort: false,
    filters: true,
    minWidth: 100,
    slotContainerStyle: 'display:inline-flex;' 
  },
  {
    title: 'Type',
    id: 'type',
    sort: false,
    applySort: false,
    filters: true,
    minWidth: 95,
  },
  {
    title: 'FTE',
    id: 'fte',
    sort: false,
    applySort: false,
    filters: false,
    minWidth: 60,
    borderClass: true,
  }
]
const ScheduleFilterColumnsMock = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'skillIds',
    label: 'Skills',
  },
  {
    name: 'employeeTypeIds',
    label: 'Type',
  },
]

export default defineComponent({
  name: 'ScheduleView',
  components: {
    NeuContainer,
    GridComponent,
    SubFilterComponent,
    ScheduleSummaries,
    AddOrCancelShiftModal,
    LegendIcons,
    MetricComponent
},
  mixins: [shiftPanels],
  data: () => {
    return {
      statusText: '',
      gridColumns: baseGridColumns,
      dateColumns: dateColumns,
      scheduleRows: scheduleRows,
      screenLoading: true,
      gridLoading: false,
      dateCellProps: {} as any,
      selectedFacilityId: {} as any,
      selectedScheduleId: {} as any,
      selectedDepartmentId: '' as any,
      selectedTenantId: '',
      preferencesTriangleToggle: true,
      selectedDate: '',
      gridKey: Math.random(),
      selectCellKey: Math.random(),
      ScheduleFilterColumns: ScheduleFilterColumnsMock,
      name: '',
      skillIds: '',
      employeeTypeIds: '',
      metrics: {} as any,
      selectedFilters: [] as any,
      onMountedVal: false as any
    }
  },
  computed: {
    ...mapState('profile', ['profileData', 'userAccounts']),
    isGridVisible() {
      return (
        this.selectedDepartmentId != '' &&
        this.selectedFacilityId != '' &&
        this.selectedScheduleId != ''
      );
    },
    isEditable() {
      const startDate = moment(this.departmentSchedule?.scheduleStart);
      const isStarted = startDate.isBefore(moment());
          
        if(this.scheduleStatus == 'PLANNING' && (this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_CHANGE_PLANNINGPHASE) || this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_MUST_REQUEST_SHIFT))) 
        {         
            return true
        }
        else if(this.scheduleStatus == 'SELF SCHEDULING' && (this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_CHANGE_SELFSCHEDULINGPHASE) || this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_MUST_REQUEST_SHIFT)))
        {         
            return true
        }
        else if(this.scheduleStatus == 'BALANCING' && (this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_CHANGE_BALANCINGPHASE) || this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_MUST_REQUEST_SHIFT)))
        {         
            return true
        }
        else if(this.scheduleStatus == 'APPROVAL')
        {         
            return false
        }
        else if(this.scheduleStatus == 'POSTED')
        {        
          
            return !isStarted && (this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_CHANGE_POSTEDPHASE) || this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_MUST_REQUEST_SHIFT))
        }
        else
        {
          return false
        }
      
     },
    isViewable() {
        if(this.scheduleStatus == 'PLANNING' && this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_VIEW_PLANNINGPHASE)) 
        {         
            return true
        }
        else if(this.scheduleStatus == 'SELF SCHEDULING' && this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_VIEW_SELFSCHEDULINGPHASE))
        {         
            return true
        }
        else if(this.scheduleStatus == 'BALANCING' && this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_VIEW_BALANCINGPHASE))
        {         
            return true
        }
        else if(this.scheduleStatus == 'APPROVAL' && this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_VIEW_APPROVALPHASE))
        {         
            return true
        }
        else if(this.scheduleStatus == 'POSTED' && this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_VIEW_POSTEDPHASE))
        {         
            return true
        }
        else if(this.scheduleStatus == 'ARCHIVED' && this.profileData?.Permissions.includes(Permissions.ASSIGNMENT_VIEW_ARCHIVEDPHASE))
        {         
            return true
        }
        else
        {
          return false
        }
    },
   
    preferenceClassType() {
      return this.preferencesTriangleToggle === true ? 'triangle' : 'background'
    },
    departmentSchedule() {
      return scheduleModule?.departmentSchedule ?? {}
    },
    departmentShifts() {
      return scheduleModule?.departmentSchedule?.departmentShifts ?? []
    },
    scheduleStatus() {
      return this.departmentSchedule?.scheduleStatus ?? ''
    },
    cancelReasons() {
      return scheduleModule?.departmentSchedule?.cancelReasons ?? []
    },
    helperEnabled() {
      return this.departmentSchedule?.helperEnabled ?? false
    },
    isScheduleRejected() {
      return this.departmentSchedule?.isScheduleRejected ?? false
    },
    getSummaryUpdateData() {
      const scheduleModule = getModule(ScheduleModule, this.$store)
      return scheduleModule?.getSummaryUpdate
    }
  },
  watch: {
    selectedCell(newVal, oldVal) {
      if (newVal != oldVal) {
        oldVal?.classList?.remove('cell_selected')
      }
    },
    getSummaryUpdateData(val) {
      if (val) {
        this.deriveGridColumns()
        this.metricsData()
        this.emitter.emit('schedule-update')      
        this.emitter.emit('hideSpinner')
        scheduleModule.updateSummaries(false)
      }
    }
  },
  mounted() {
    this.emitter.emit('hideLastUpdatedTime');
  },
  created() {
    scheduleModule = getModule(ScheduleModule, this.$store)
    this.emitter.on('filter-data-reset', () => {
      this.selectedDepartmentId = ''
      this.selectedScheduleId = ''
    })
  },
  methods: {
    subNavInitialized(selected: Array<string>) {
      if (selected?.length >= 4) {
        this.selectedTenantId = selected[0]
        this.selectedFacilityId = selected[1]
        this.selectedDepartmentId = selected[2]
        this.selectedScheduleId = selected[3]
        this.selectedFilters = selected[4]
        this.onMountedVal = selected[5]
        this.$router.push(`/schedule`)
        sessionStorage.setItem('filters-data', JSON.stringify({
          selectedTenantId: selected[0],
          selectedFacilityId: selected[1],
          selectedDepartmentId: selected[2],
          selectedScheduleId: selected[3],
          selectedFilters: selected[4],
          onMountedVal: selected[5]
        }));
        if (this.onMountedVal) {
          this.populateDepartmentSchedule()
        } else {
          this.deriveGridColumns()
          this.metricsData()
        }
      }
    },
    async populateDepartmentSchedule() {
      if (
        this.selectedScheduleId &&
        this.selectedDepartmentId
      ) {
        this.gridLoading = true
        const params = {} as any
        scheduleModule = getModule(ScheduleModule, this.$store);
        const names: string[] = [];
        const skillIds: string[] = [];
        const employeeTypeIds: any[] = [];
        (this.selectedFilters || []).forEach((filter: any) => {
          if(filter.filterColumn === "name") {
            names.push(filter.filterValue)
          }
          if(filter.filterColumn === "skillIds") {
            skillIds.push(filter.filterValue)
          }
          if(filter.filterColumn === "employeeTypeIds") {
            employeeTypeIds.push(filter.filterValue)
          }
        })
        if (names.length) params.names = names.join(',');
        if (skillIds.length) params.skillIds = skillIds.join(',');
        if (employeeTypeIds.length) params.employeeTypeIds = employeeTypeIds.join(',');
        (params.selectedScheduleId = this.selectedScheduleId?.scheduleId),
          (params.selectedTenant = this.selectedTenantId),
          await scheduleModule.allDepartmentSchedule(params).then((result: any) => {   
            if (result?.message) {
             this.emitter.emit('toast', {
                text: result.message,
                autoHide: 0,
                type: 3,
              })
            }
          })
          this.deriveGridColumns()
          this.metricsData()
      }
    },
    onlyUnique(value: any, index: number, array: any) {
      return array.indexOf(value) === index;
    },
    metricsData() {
      const metricsData = this.departmentSchedule?.metrics
      let staffUnderCommitment = 0;
      const currentMetrics = this.departmentSchedule?.metrics?.filter(function(m:any){ return m.metricScoreType == 'Current' });
      if (currentMetrics?.length){
        staffUnderCommitment = currentMetrics[0]?.resourcesNotScheduledToCommitment;
      }
      
      return (this.metrics = {
        staffUnderCommitment: staffUnderCommitment,
        comparisons: [
          {
            type: 'Commitment',
            previous: metricsData?.length > 0 && metricsData[1]?.commitment || 0,
            current: metricsData?.length > 0 && metricsData[0]?.commitment || 0,
          },
          {
            type: 'Staff Under Commitment',
            count: true,
            current: currentMetrics?.length && currentMetrics[0].resourcesNotScheduledToCommitment || 0,
          },
          {
            type: 'Completeness',
            previous: metricsData?.length > 0 && metricsData[1]?.completeness || 0,
            current: metricsData?.length > 0 && metricsData[0]?.completeness || 0,
          },
          {
            type: 'Professional Completeness',
            previous: metricsData?.length > 0 && metricsData[1]?.professionalCompleteness || 0,
            current: metricsData?.length > 0 && metricsData[0]?.professionalCompleteness || 0,
          },
          {
            type: 'Support Completeness',
            previous: metricsData?.length > 0 && metricsData[1]?.supportCompleteness || 0,
            current: metricsData?.length > 0 && metricsData[0]?.supportCompleteness || 0,
          },
          {
            type: 'Healthiness',
            previous: metricsData?.length > 0 && metricsData[1]?.healthiness || 0,
            current: metricsData?.length > 0 && metricsData[0]?.healthiness || 0,
          },
          {
            type: 'Schedules Honored',
            previous: metricsData?.length > 0 && metricsData[1]?.honored || 0,
            current:  metricsData?.length > 0 && metricsData[0]?.honored || 0,
          }, 
        ],
      })
    },
    /**
     * Filter rowData base on filter params.
     * @param filter Object required to filter rowData.
     */
    filterData(filters: any) {
      this.clearFilter()
      if (filters.length) {
        filters.forEach((filter: any) => {
          // @ts-ignore
          this[filter.filterColumn] = filter.filterValue
       })
      }
      this.populateDepartmentSchedule()
    },
    filtersApplied(filters: any) {
      this.selectedFilters = filters
      this.populateDepartmentSchedule()
    },
    /**
     * Clear filter params.
     */
    clearFilter() {
      this.name = ''
      this.skillIds = ''
      this.employeeTypeIds = ''
    },
    getImgUrl(list: any) {
      const images = require.context('@/assets/', false, /\.png$/)
      return images('./' + list.img + ".png")
    },
    togglePreferenceStyle(event: any) {
      this.preferencesTriangleToggle = event.detail.checked
      this.gridLoading = true
      this.deriveGridColumns()
    },
    getStaffAvailiblityIcon(date: string, staffId: any, year: any) {
      const data = {
        display: false,
        tooltip: '',
        type: '',
        img: '',
      }
      const staffPreference = this.departmentSchedule?.staff
        .filter((f: any) => f.staffId == staffId)?.[0]
        ?.preferences?.filter(
          (d:any) => d.dayOfWeekId == moment(date + '/'+ year).day() + 1
        )[0]
        const unavilableStaff = this.departmentSchedule?.staff
        .filter((f: any) => f.staffId == staffId)?.[0]
        ?.unavailabilities?.filter(
          (d:any) => 
            moment(d).format('MM/DD') == moment(date).format('MM/DD')   
        )[0]
        if (unavilableStaff) {
            data.display = true;
            data.type = 'unavailable';
            data.img = 'Unavailable';
            data.tooltip = 'Unavailable';
          } else {
            switch (staffPreference?.preference) {
              case 'I prefer to work': // Preferred
                data.display = true;
                data.type = 'prefer';
                data.img = 'Preferred';
                data.tooltip = 'Preferred';
                break
              case 'I prefer not to work': // Not Preferred
                data.display = true;
                data.type = 'not-prefer'
                data.img = 'PreferNotToWork'
                data.tooltip = 'Prefer Not to Work';
                break
              default:
                break
            }
          }
      return [data];
    },
    deriveDateCellClassesAndTooltip(props: any, date: string, staffId: string) {
      const cellClasses = this.isEditable
        ? 'grid-row date-cell'
        : 'date-cell date-cell-archived'
      const staffPreference = this.departmentSchedule?.staff
        .filter((f: any) => f.staffId == staffId)?.[0]
        ?.preferences?.filter(
          (d:any) => d.dayOfWeekId == new Date(date).getDay() + 1
        )[0]
      let assignmentClass = ''
      const assignment = props.row[props.column.id]
      try {
        if (moment(assignment.date).format('M/D') == date) {
          if (this.helperEnabled) {
            if (assignment?.managerAdded) {
              assignmentClass = 'manager_added'
            } else if (assignment?.helperAdded) {
              assignmentClass = 'helper_added'
            } else if (assignment?.helperRemoved) {
              assignmentClass = 'helper_removed'
            }
          }
        }
      } catch (error) {
        this.emitter.emit('toast', { text: error, autoHide: 5, type: 3 })
      }
      
      return cellClasses + ' ' + assignmentClass
    },
    getAssignmentCodeColor(assignment: any) {
      let assignmentClass = ''
      if (assignment?.deleted || assignment?.canceled) {
        if (this.helperEnabled) {
          assignmentClass = 'balance-assignment-deleted'
        } else if (          
          this.scheduleStatus === 'APPROVAL' ||
          this.scheduleStatus === 'PLANNING' ||
          this.scheduleStatus === 'SELF SCHEDULING'
        ) {
          assignmentClass = 'posted-approval-assignment-deleted'
        } else if (this.scheduleStatus === 'POSTED' || this.scheduleStatus === 'BALANCING' && assignment?.canceled) {
          assignmentClass = 'balance-assignment-canceled'
        } else {
          assignmentClass = ''
        }
      }
      return assignmentClass
    },
    deriveIndication(
      assignments: AssignmentsModel[],
      weeklyCommitments: WeeklyCommitmentsModel[]
    ) {
      let indicationType = 'NONE'
      let overCommitmentIndication = 'OVER'
      let underCommitmentIndication = 'UNDER'
      let tooltipContent = ''

      let indicationTypeOvertime = false
      let tooltipContentOvertime = ''
      let overtimeDescription = '';

      if (weeklyCommitments?.length > 0) {
        let underCommitmentDescription = 'Under Commitments:'
        let underCommitments = underCommitmentDescription
        let overCommitmentDescription = 'Over Commitments:'
        let overCommitments = overCommitmentDescription
        
        //Overtime
        let getWeeklyOvertimeTrue = weeklyCommitments?.some((x: any) => x.overtime == true)
        let getWeeklyOvertimeFalse = weeklyCommitments?.some((x: any) => x.overtime == false)
        indicationTypeOvertime = getWeeklyOvertimeTrue == true ? true : false;
        if(getWeeklyOvertimeTrue == true && getWeeklyOvertimeFalse == false){
          overtimeDescription = "Weekly overtime for all weeks: ";
        }else if(getWeeklyOvertimeTrue == true && getWeeklyOvertimeFalse == true){
          overtimeDescription = "Weekly overtime: ";
        }

        for (let commitment of weeklyCommitments) {
          if (commitment?.isUndercommitted) {
            underCommitments +=
              '\n Week ' +
              moment(commitment.weekStartDate).startOf('day').format('M/D') +
              ' - ' +
              moment(commitment.weekEndDate).startOf('day').format('M/D')
          } else if (commitment.remainingHours < 0) {
            overCommitments +=
              '\n Week ' +
              moment(commitment.weekStartDate).startOf('day').format('M/D') +
              ' - ' +
              moment(commitment.weekEndDate).startOf('day').format('M/D')
          }

          if(commitment?.overtime) {
            overtimeDescription +=
                '\n Week ' +
                moment(commitment?.weekStartDate).startOf('day').format('M/D') +
                ' - ' +
                moment(commitment.weekEndDate).startOf('day').format('M/D');
          }
        }
        tooltipContentOvertime = overtimeDescription;
        if (underCommitments !== underCommitmentDescription) {
          tooltipContent += underCommitments
          indicationType = underCommitmentIndication
          if (overCommitments !== overCommitmentDescription) {
            tooltipContent += '\n\n'
          }
        }

        if (overCommitments !== overCommitmentDescription) {
          tooltipContent += overCommitments
          indicationType = overCommitmentIndication
        }
      }

      return [indicationType, tooltipContent, indicationTypeOvertime, tooltipContentOvertime]
    }, 
    derivePreceptorOrienteeIndicator(orientees: any, preceptors: any){
      let preceptortooltipContent = '';
      let orienteetooltipContent = '';
      let preceptorType = false
      let orienteeType = false
      if(orientees.length > 0){
        orienteeType = true;
        orienteetooltipContent += "Orientee: ";
        orientees.forEach((element: any) => {
          orienteetooltipContent += "\n\n " + element?.lastName + ", " + element?.firstName +
          ' Week ' +
          moment(element?.startDate).startOf('day').format('M/D') +
          ' - ' +
          moment(element?.endDate).startOf('day').format('M/D');
        });
      }
      if(preceptors.length > 0){
        preceptorType = true;
        preceptortooltipContent += "Preceptor: ";
        preceptors.forEach((element: any) => {
          preceptortooltipContent += "\n" + element?.lastName + ", " + element?.firstName +
          ' Week ' +
          moment(element?.startDate).startOf('day').format('M/D') +
          ' - ' +
          moment(element?.endDate).startOf('day').format('M/D');
        });
      }

      return [preceptorType, preceptortooltipContent, orienteeType, orienteetooltipContent]
    },   
    deriveGridColumns() {
      this.gridColumns?.splice(5, this.gridColumns?.length)
      this.dateColumns = []
      let startDate = this.departmentSchedule?.scheduleStart
      let endDate = this.selectedScheduleId?.endDate
      let currDate = moment(startDate).startOf('day')
      let lastDate = moment(endDate).startOf('day')
      let dayName = moment(startDate).format('ddd')

      // Add First Date
      let dateString = currDate.format('M/D')
      let yearString = currDate.year().toString()
      const columnDate = moment(startDate)
      const isToday =
        columnDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
      let dateColumn = {
        title: dateString,
        id: dateString,
        year: yearString,
        applySort: false,
        slot: true,
        slotName: 'date',
        filters: false,
        fixed: false,
        day: dayName,
        isToday,
      }
      this.gridColumns.push(dateColumn)
      this.dateColumns.push(dateColumn)

      while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        let dayName = currDate.format('ddd')
        const columnDate = moment(currDate)
        const isToday =
          columnDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
        let dateString = currDate.format('M/D')
        let yearString = currDate.year().toString()
        let dateColumn = {
          title: dateString,
          id: dateString,
          year: yearString,
          applySort: false,
          slot: true,
          slotName: 'date',
          filters: false,
          fixed: false,
          day: dayName,
          isToday,
        }
        this.gridColumns.push(dateColumn)
        this.dateColumns.push(dateColumn)
      }

      this.deriveRows()
    },
    getlistskillID(id : any,staff : any){
      let skillList: any = [];
      skillList = staff?.assignments?.filter((assignment : any) => assignment.skillLevelId === id);  
      if(skillList.length > 0){
        return skillList[0];
      }    
      else{
        return null;
      }  
    },
    deriveRows() {
      this.scheduleRows = []
      if (this.departmentSchedule?.staff?.length > 0) {
        for (let staff of this.departmentSchedule?.staff) {
          let derivedIndication = this.deriveIndication(
            staff?.assignments,
            staff?.weeklyCommitments
          )  
          let derivedPreceptorOrientee = this.derivePreceptorOrienteeIndicator(staff?.orientees, staff?.preceptors);
          let skillIDS = [...new Set(staff?.skills?.map((skillID:any) => skillID.skillLevelId))];
          let distinctskilllevelIds = [...new Set(staff?.assignments?.map((asignment:any) => asignment.skillLevelId))];
          let listskillIds = distinctskilllevelIds.filter(item => skillIDS.indexOf(item) < 0 )
          if(listskillIds?.length > 0){
            listskillIds?.forEach((id:any) => {
              let skill:any = this.getlistskillID(id,staff);
              let row = {
              row_id: staff?.staffId,
              selected: false,
              indicationType: derivedIndication[0],
              indicationToolTip: derivedIndication[1],
              indication: '',
              departmentAssociationType: staff?.departmentAssociationType,
              name: staff?.lastName + ', ' + staff?.firstName,
              skill: skill?.skillLevelDesc,
              skillId: skill?.skillLevelId,
              type: staff?.employeeTypeDesc,
              fte: staff?.fte,
              rnOneYear: staff?.rnOneYear ? '' : '< 1 YR',
              editable: this.isEditable,
              assignments: this.getAssignmentsBySkillId(skill, staff),
              overtime: derivedIndication[2],
              overtimeTooltip: derivedIndication[3],
              preceptor: derivedPreceptorOrientee[0],
              preceptorTooltip: derivedPreceptorOrientee[1],
              orientee: derivedPreceptorOrientee[2],
              orienteeTooltip: derivedPreceptorOrientee[3],
              endDate: staff?.endDate,
              ptoBalance: staff?.ptoBalance
            }            
            this.assignRow(row)
            })
          }
          if(staff?.skills.length < 1 && staff?.assignments?.length > 1)
          {  
            let distinctskilllevelIds = [...new Set(staff?.assignments?.map((asignment:any) => asignment.skillLevelId))];
            distinctskilllevelIds?.forEach((skilllevelid: any) => {            
            let skill:any = this.getSkill(skilllevelid) ;
            let row = {
              row_id: staff?.staffId,
              selected: false,
              indicationType: derivedIndication[0],
              indicationToolTip: derivedIndication[1],
              indication: '',
              departmentAssociationType: staff?.departmentAssociationType,
              name: staff?.lastName + ', ' + staff?.firstName,
              skill: skill.skillLevelDesc,
              skillId: skill.skillLevelId,
              type: staff?.employeeTypeDesc,
              fte: staff?.fte,
              rnOneYear: staff?.rnOneYear ? '' : '< 1 YR',
              editable: this.isEditable,
              assignments: this.getAssignmentsBySkillId(skill, staff),
              overtime: derivedIndication[2],
              overtimeTooltip: derivedIndication[3],
              preceptor: derivedPreceptorOrientee[0],
              preceptorTooltip: derivedPreceptorOrientee[1],
              orientee: derivedPreceptorOrientee[2],
              orienteeTooltip: derivedPreceptorOrientee[3],
              endDate: staff?.endDate,
              ptoBalance: staff?.ptoBalance
            }            
            this.assignRow(row)          
            })
          }
          else
          {
          staff?.skills?.forEach((skill: any) => {
            let row = {
              row_id: staff?.staffId,
              selected: false,
              indicationType: derivedIndication[0],
              indicationToolTip: derivedIndication[1],
              indication: '',
              departmentAssociationType: staff?.departmentAssociationType,
              name: staff?.lastName + ', ' + staff?.firstName,
              skill: skill.skillLevelDesc,
              skillId: skill.skillLevelId,
              type: staff?.employeeTypeDesc,
              fte: staff?.fte,
              rnOneYear: staff?.rnOneYear ? '' : '< 1 YR',
              editable: this.isEditable,
              assignments: this.getAssignmentsBySkillId(skill, staff),
              overtime: derivedIndication[2],
              overtimeTooltip: derivedIndication[3],
              preceptor: derivedPreceptorOrientee[0],
              preceptorTooltip: derivedPreceptorOrientee[1],
              orientee: derivedPreceptorOrientee[2],
              orienteeTooltip: derivedPreceptorOrientee[3],
              endDate: staff?.endDate,
              ptoBalance: staff?.ptoBalance
            }            
            this.assignRow(row)
          })}
        }
      }

      this.screenLoading = false
      this.gridLoading = false
    },
    getAssignmentsBySkillId(skill: any, staff: any) {
      return staff.assignments?.filter(
        (assignment: any) => assignment.skillLevelId === skill.skillLevelId
      )
    },
    getSkill(skillLevelId: any) {     
      let findSkill: any = null;
      this.departmentSchedule?.staff?.forEach(function (staff: any) {
        // names also an array
        staff.skills?.forEach(function (skill:any) {
            if (skill.skillLevelId === skillLevelId){
              findSkill =  skill //
          }
     })})
     return findSkill
    },
    assignRow(row: any) {
      const dateStrings:any = {}
      for (let dateColumn of this.dateColumns) {
        const dateString = dateColumn?.title?.toString()
        const columnDate = moment(`${dateColumn?.year}/${dateColumn?.title}`)

        const isToday =
          columnDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
        dateStrings[dateString] = {
          isToday,
          day: columnDate.format('ddd'),
          text: '',
        }
      }
      row = { ...row, ...dateStrings }
      let duplicateAssignments = {} as any
      row?.assignments?.forEach((c: any) => {
        const dateString = moment(c.startTime).format('M/D')
        const isToday =
          moment(c?.startTime).format('YYYY-MM-DD') ==
          moment().format('YYYY-MM-DD')
        row[dateString] = {
          isToday,
          day: moment(c?.startTime).format('ddd'),
          text: c?.code,
          id: c?.assignmentId,
          productiveId: c?.productiveId,
          departmentShiftId: c?.departmentShiftId,
          deleted: c?.deleted,
          date: c?.date,
          canceled: c?.canceled,
          charge: c?.charge,
          commitment: c?.commitment,
          helperAdded: c?.helperAdded,
          helperRemoved: c?.helperRemoved,
          managerAdded: c?.managerAdded,
          notes: c?.notes,
          needId: c?.needId,
          startTime: c?.startTime,
          endTime: c?.endTime,
          cancelReasonCategory: c?.cancelReasonCategory,
          cancelReason: c?.cancelReason
        }
        if (c.date in duplicateAssignments) {
          duplicateAssignments[c.date].push(c)
        } else {
          duplicateAssignments[c.date] = [c]
        }
      })

      this.scheduleRows.push(row)
      const {
        editable,
        fte,
        rnOneYear,
        indication,
        indicationToolTip,
        indicationType,
        name,
        row_id,
        selected,
        skill,
        skillId,
        type,
        assignments,
        ptoBalance
      } = row
      const newRow = {
        editable,
        fte,
        rnOneYear,
        indication,
        indicationToolTip,
        indicationType,
        name,
        row_id,
        selected,
        skill,
        skillId,
        type,
        assignments,
        ptoBalance,
        ...dateStrings,
      }
      const duplicatesRows = [] as any
      Object.keys(duplicateAssignments).forEach((key) => {
        const duplicates = duplicateAssignments[key]?.slice(
          0,
          duplicateAssignments[key]?.length - 1
        )
        duplicates.forEach((assignment: any, index: any) => {
          const dateString = moment(assignment.startTime).format('M/D')
          const isToday =
            moment(assignment.startTime).format('YYYY-MM-DD') ==
            moment().format('YYYY-MM-DD')
          if (duplicateAssignments[key].length > 1) {
            if (!duplicatesRows[index])
              duplicatesRows[index] = { ...newRow, text: '' }
            duplicatesRows[index][dateString] = {
              isToday,
              day: moment(assignment.startTime).format('ddd'),
              text: assignment.code,
              id: assignment.assignmentId,
              departmentShiftId: assignment.departmentShiftId,
              productiveId: assignment.productiveId,
              date: assignment.date,
              deleted: assignment.deleted,
              canceled: assignment.canceled,
              charge: assignment.charge,
              commitment: assignment.commitment,
              helperAdded: assignment.helperAdded,
              helperRemoved: assignment.helperRemoved,
              managerAdded: assignment.managerAdded,
              notes: assignment.notes,
              needId: assignment.needId,
              startTime: assignment.startTime,
              endTime: assignment.endtime,
              cancelReasonCategory: assignment.cancelReasonCategory,
              cancelReason: assignment.cancelReason
            }
          }
        })
      })
      this.scheduleRows.push(...duplicatesRows)
    },
    dateCellClick(props:any, e:any) {
      this.selectedDate = `${props.column.id}/${props.column.year}`
      if(props?.row?.endDate !=null && moment(props?.row?.endDate) < moment(this.selectedDate))
      {
        return
      }
      scheduleModule = getModule(ScheduleModule, this.$store)
      if (scheduleModule?.dirty) {
        this.emitter.emit('toast', {
          text: 'Your changes have not been saved. Please close current action or save changes.',
          autoHide: 0,
          type: 2,
          isDirty: true,
        })
      } else {
        let shiftAssigned: boolean
        if (props.row[props.column.id]?.text == '') {
          shiftAssigned = false
        } else {
          shiftAssigned = true
        }
       
        const rowData = {
          fte: props?.row?.fte,
          indication: props?.row?.indication,
          indicationToolTip: props?.row?.indicationToolTip,
          indicationType: props?.row?.indicationType,
          name: props?.row?.name,
          row_id: props?.row?.row_id,
          skill: props?.row?.skill,
          skillId: props?.row?.skillId,
          type: props?.row?.type,
          assignment: props.row[props.column.id],
          cancelReasons: this.departmentSchedule?.cancelReasons ?? [],
          cancelReasonCategories: this.departmentSchedule?.cancelReasonCategories ?? [],
          departmentShifts: this.departmentSchedule?.departmentShifts ?? [],
          formattedLocation: `(${this.departmentSchedule?.coid}) ${this.departmentSchedule?.facilityName}: (${this.departmentSchedule?.departmentCode}) ${this.departmentSchedule?.departmentName}`,
          ptoBalance: props?.row?.ptoBalance
        }

        if (shiftAssigned) {
          this.scheduleStore.setOpenCancelShift(true)
          this.scheduleStore.setOpenAddShift(false)
        } else {
          this.scheduleStore.setOpenAddShift(true)
          this.scheduleStore.setOpenCancelShift(false)
        }
        if (!this.isEditable && !shiftAssigned) {
          this.isSidebarOpen = false
        } else {
          this.isSidebarOpen = true
          this.selectedCell = e.target
          this.selectedCell.classList.add('cell_selected')
        }
        
        this.dateCellProps = rowData
        this.selectCellKey = Math.random()
      }
    },
    refreshGrid() {
      this.populateDepartmentSchedule()
    },
    closeSidebar() {
      this.scheduleStore?.setDirty(false)
      this.closeShiftSidePanel()
      this.selectedCell.classList.remove('cell_selected')
    },
    triggerSchedule(val: any) {
      if (val) {
        this.populateDepartmentSchedule()
      }
    },
    async updateAssignmentData(data: any) {
      const scheduleModule = getModule(ScheduleModule, this.$store)
      const securityModule = getModule(SecurityModule, this.$store)
      const name = this.dateCellProps?.name.split(',')
      const time = moment(this.selectedDate + ' ' + data?.shiftTime)
        .format()
        .slice(0, -6)
        const payload = {
          departmentId: this.selectedDepartmentId,
          departmentCode: this.departmentSchedule?.departmentCode,
          productiveId: data.productiveId,
          departmentShiftId: data.departmentShiftId,
          assignmentId: data?.assignmentId,
          date: moment(time).format('YYYY-MM-DD'),
          startTime: time,
          endTime: moment(time)
            .add(data?.selectedDuration, 'hours')
            .format()
            .slice(0, -6),
          hours: Number(data?.selectedDuration),
          staffId: data.staffId,
          staffFirstName: name[1]?.trim(),
          staffLastName: name[0],
          cancel: false,
          floatUserModified: false,
          cancelReasonId: 0,
          employeeType: this.dateCellProps?.type,
          fte: this.dateCellProps?.fte,
          facilityId: this.departmentSchedule?.facilityId,
          facilityName: this.departmentSchedule?.facilityName,
          tenantId:
            securityModule.getSelectedTenant,
          skillLevelId: this.dateCellProps?.skillId,
          skillLevelDesc: this.dateCellProps?.skill,
        } as unknown as AssignmentModel
        this.emitter.emit('showSpinner')
        await scheduleModule
          .updateAssignmentRequest(payload)
          .then(async (result) => {
            if (result?.message) {
             this.emitter.emit('toast', {
                text: result.message,
                autoHide: 0,
                type: 3,
              })
            } else {
             this.emitter.emit('toast', {
                text: 'Successfully saved',
                autoHide: 5,
                type: 1,
              })
              this.deriveGridColumns()
              this.metricsData()
            }
            this.emitter.emit('hideSpinner') 
          })
          .catch((e) => {
           this.emitter.emit('toast', { text: e, autoHide: 5, type: 3 })
          })
    },
  
    async submitData(data: any) {
      const scheduleModule = getModule(ScheduleModule, this.$store)
      const securityModule = getModule(SecurityModule, this.$store)
      if (this.openCancelShift) {
        const payload = {
          assignmentId: this.dateCellProps?.assignment?.id,
          cancelReasonId: data?.cancelReasonId,
          unscheduledAbsence: data?.unscheduledAbsence,
          unscheduledAbsenceOption: data?.unscheduledAbsenceOptionId,
          comment: data.comment,
          isCancelInitiatedFromWorkforce: true,
          tenantId: securityModule.getSelectedTenant
          
        } as AssignmentCancelModel
        this.emitter.emit('showSpinner')
        await scheduleModule
          .cancelAssignmentRequest(payload)
          .then(async (result: any) => {
            if (result?.message) {
             this.emitter.emit('toast', {
                text: result.message,
                autoHide: 0,
                type: 3,
              })
            } else {
             this.emitter.emit('toast', {
                text: 'Successfully saved',
                autoHide: 5,
                type: 1,
              })
              
              if(data.selectedShiftCode){
                this.addAssignment(data)
              } else {                
                this.deriveGridColumns()
                this.metricsData()
                this.emitter.emit('schedule-update')
                this.emitter.emit('hideSpinner')
              }
            } 
            
          })
          .catch((e: any) => {
           this.emitter.emit('toast', { text: e, autoHide: 5, type: 3 })
          })   
      } else {
        this.addAssignment(data)
      }
      this.closeSidebar()
    },
    async addAssignment(data: any){
      const scheduleModule = getModule(ScheduleModule, this.$store)
      const securityModule = getModule(SecurityModule, this.$store)
      const name = this.dateCellProps?.name.split(',')
      const time = moment(this.selectedDate + ' ' + data?.shiftTime)
      const payload = {
          departmentId: this.selectedDepartmentId?.value,
          departmentCode: this.departmentSchedule?.departmentCode,
          productiveId: data?.productiveId,
          departmentShiftId: data.selectedShiftId,
          shiftCode: data.selectedShiftCode, 
          date: moment(time).format('YYYY-MM-DD'),
          startTime:  moment(time).format().slice(0, -6),
          endTime: moment(time)
            .add(data?.selectedDuration, 'hours')
            .format()
            .slice(0, -6),
          hours: Number(data?.selectedDuration),
          staffId: data.staffId,
          staffFirstName: name[1]?.trim(),
          staffLastName: name[0],
          cancel: false,
          employeeType: this.dateCellProps?.type,
          fte: this.dateCellProps?.fte,
          facilityId: this.departmentSchedule?.facilityId,
          facilityName: this.departmentSchedule?.facilityName,
          tenantId: securityModule.getSelectedTenant,
          skillLevelId: this.dateCellProps?.skillId,
          skillLevelDesc: this.dateCellProps?.skill,
        } as unknown as AssignmentModel
        if (data?.comment) {
          payload.comment = data.comment
        }
        this.emitter.emit('showSpinner')
        await scheduleModule
          .addAssignmentRequest(payload)
          .then(async (result) => {
            if (result?.response) {
             this.emitter.emit('toast', {
                text: result?.response?.data,
                autoHide: 0,
                type: 3,
              })
            } else {
             this.emitter.emit('toast', {
                text: 'Successfully saved',
                autoHide: 5,
                type: 1,
              })               
            }
          })
          .catch((e) => {
           this.emitter.emit('toast', { text: e, autoHide: 5, type: 3 })
          })          
          
    },
    async deleteAssignmentData(){
      const scheduleModule = getModule(ScheduleModule, this.$store)
      const securityModule = getModule(SecurityModule, this.$store)
      const payload = {
        assignmentId: this.dateCellProps?.assignment?.id,
        tenantId: securityModule.getSelectedTenant,
      } as AssignmentCancelModel
      this.emitter.emit('showSpinner')
      await scheduleModule
        .deleteAssignmentRequest(payload)
        .then(async (result) => {
          if (result?.message) {
            this.emitter.emit('toast', {
              text: result.message,
              autoHide: 0,
              type: 3,
            })
          } else {
            this.emitter.emit('toast', {
              text: 'Successfully deleted',
              autoHide: 5,
              type: 1,
            })
          }
        })
        .catch((e) => {
          this.emitter.emit('toast', { text: e, autoHide: 5, type: 3 })
          this.emitter.emit('hideSpinner') 
        })
        this.closeSidebar()
        this.deriveGridColumns()
        this.metricsData()
        this.emitter.emit('schedule-update')
        this.emitter.emit('hideSpinner')
    }
  },
})
</script>

<style scoped lang="scss">
.content-wrapper {
  background: var(--neu-color-plain-0);
  :deep(.filter-section) {
    margin-top: 1rem; 
    .filter-modal {
      top: 4.125rem;
    }
  }
}
.not-today {
  .manager_added {
    min-width: 5.5rem !important;
  }
}
.is-today {
  .manager_added {
    min-width: 5.25rem !important;
  }
  .manager_added::after {
    left: 0px !important;
  }
}
.date-cell {
  max-width: 5.625rem;
  min-width: 5.625rem;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}
div.grid-container {
  transition: width 0.5s ease-out;
  overflow: hidden;
  :deep(.column-width) {
    display: flex;
    justify-content: center;
    max-width: 5.625rem;
    min-width: 5.625rem !important;
  }
}
div.sidebar-container {
  display: block;
  overflow: hidden;
  transition: right 0.6s ease-in;
  position: fixed;
  top: 72px;
  right: -100%;
  height: calc(100% - 135px);
  z-index: 99;
  .sidebar_opened {
    transition: width 0.5s ease-out !important;
  }
}
.text-wrap {
  margin: auto;
  font-size: 22px;
  text-align: center;
}
div.sidebar-container.sidebar_opened {
  display: block;
  transition: right 0.5s ease-out;
  right: 0;
  width: 380px;
  height: 100%;
  min-height: auto;
}
:deep(#grid) {
  overflow-y: auto !important;
}



.cell_selected {
  background-color: var(--neu-color-gray-40);
  height: 100%;
}

.helper_added {
  background-color: var(--neu-color-green-20);
  height: 100%;
}
.manager_added {
  background-color: var(--neu-color-yellow-10);
  height: 100%;
}
.helper_removed {
  background-color: var(--neu-color-blue-20);
  height: 100%;
}
.posted-approval-assignment-deleted {
  color: var(--neu-color-red-60);
}
.balance-assignment-deleted {
  color: var(--neu-color-red-60);
  text-decoration: line-through;
}
.balance-assignment-canceled {
  color: var(--neu-color-red-60);
  text-decoration: line-through;
}
.grid-cell {
  width: 40px;
  height: 30px;
}
.grid-cell:hover {
  border: 1px solid #2075ad;
}

.data_cell {
  position: relative;
}

</style>
