<template>
    <div id="smsNotifications-content-loading" class="text-center mt-3" v-show="isShowLoader">
        <div class="fa-2x">
            <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
        </div>
        <p><b>Loading...</b></p>
    </div>
    <div id="smsNotifications-content" v-if="isShowNotificationContent">
        <div id="grdSmsNotifications">
            <neu-card color="plain-0" class="p-0 m-0">
                <neu-table>
                    <neu-table-row :columns="columnHeaders" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column" style="padding-top: 25px !important;">
                            <template v-if="column == 'DepartmentGroup' || column == 'SkillGroup'">
                                <span></span>
                            </template>
                            <template v-else>
                                {{ column }}
                            </template>
                        </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                        <template v-if="smsNotificationData != null && smsNotificationData.length > 0">
                            <template v-for="dept in smsNotificationData" :key="dept">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row class="departmentGroupRow" :columns="columnHeaders" :slot="column">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="DepartmentGroup">
                                        <font-awesome-icon :icon="['fas', 'caret-right']" size="sm"
                                                           :class="[dept.isDeptExpand ? 'collapseIcon': '']"
                                                           @click="()=> dept.isDeptExpand = !dept.isDeptExpand"
                                                           style="padding-right: 5px;" />
                                        <b>{{dept.departmentGroup}}</b>
                                    </neu-label>
                                </neu-table-row>
                                <template v-for="skill in dept.skillList" :key="skill">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-table-row :columns="columnHeaders" :slot="column"
                                                   :class="[!dept.isDeptExpand ? 'd-none': '','skillGroupRow']">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="SkillGroup">
                                            <font-awesome-icon :icon="['fas', 'caret-right']" size="sm"
                                                               :class="[skill.isSkillExpand ? 'collapseIcon': '']"
                                                               @click="()=> skill.isSkillExpand = !skill.isSkillExpand"
                                                               style="padding-right: 5px;" />
                                            <b>{{skill.skillGroup}}</b>
                                        </neu-label>
                                    </neu-table-row>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-table-row :columns="columnHeaders" v-for="dataItem in skill.notifData" :slot="column"
                                                   :key="dataItem" :uid="dataItem.rowUniqueId" :staffId="dataItem.staffId"
                                                   :class="[!dept.isDeptExpand ? 'd-none' : '' , !skill.isSkillExpand ? 'd-none' : '']">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Name" :title="dataItem.staffName">
                                            {{ dataItem.staffName }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Department" :title="dataItem.departmentName">
                                            {{ dataItem.departmentName }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Phone Number">
                                            {{ dataItem.phoneNumber }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Received">
                                            {{ setGridDateFormat(dataItem.responseDateTime) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Message">
                                            {{ dataItem.responseText }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Actions">
                                            <div class="text-center" v-if="dataItem.action === receivedNotifType">
                                                <button type="button" :disabled="hasShiftEnded(dataItem.shiftStartDateTime, dataItem.shiftEndDateTime)" data-rowid="dataItem.rowUniqueId" data-requestid="dataItem.notificationRequestId"
                                                        class="btn btn-link btn-schedule" @click="scheduleNotificationRequest(dataItem.departmentId, dataItem.group, dataItem.staffId)">
                                                    SCHEDULE
                                                </button>
                                                <button type="button" :disabled="hasShiftEnded(dataItem.shiftStartDateTime, dataItem.shiftEndDateTime)" data-rowid="dataItem.rowUniqueId" data-requestid="dataItem.notificationRequestId"
                                                        class="btn btn-link btn-deny" @click="denyNotificationRequest(dataItem.departmentId, dataItem.group, dataItem.staffId)">
                                                    DENY
                                                </button>
                                            </div>
                                            <div class="text-center" v-else-if="dataItem.action === approvedNotifType"> <span class="scheduled "> SCHEDULED </span> </div>
                                            <div class="text-center" v-else-if="dataItem.action === deniedNotifType"> <span class="denied text-center"> DENIED </span> </div>
                                        </neu-label>
                                    </neu-table-row>
                                </template>
                            </template>
                        </template>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
    </div>
    <div id="smsNotifications-message" class="text-center mt-3" v-if="isShowNotificationMessage">
        <p id="smsNotifications-message-text">{{smsNotificationMessage}}</p>
    </div>
    <div id="care-assignment-container-smsNotifications"></div>
    <div v-if="isToasterVisible">
        <ToasterPopup @closeModal="closeToasterPopup"
                      :msgValue="msgValue"
                      :classFlag="classFlag"></ToasterPopup>
    </div>
</template>


<script lang="ts">
    //import plugins and modules
    import { Options, Vue } from 'vue-class-component';
    import moment from "moment";
    import { mapState } from "vuex";
    import jslinq from "jslinq";
    //imports models and enums
    import { NotificationActionTypes } from "@/enums/enum";
    //Import Componenets
    import ToasterPopup from "../../shared/ToasterPopup.vue";
    import {
        NeuTableRowCount,
        NeuTable,
        NeuTableRow,
        NeuLabel,
        NeuTableBody,
        NeuTableHeading,
        NeuCard
    } from "@neutron/vue";
    import { Profile } from '@/models/profile/index';
    import { useAppInsights } from '../../../store/modules/AppInsights';

    class Props {
        notificationTabInput!: any
    }

    @Options({
        computed: {
            ...mapState('assignments', ['notificationData']),
            ...mapState('profile', ['profileData', 'appInsightEventData']),
        },
        components: {
            ToasterPopup,
            NeuTableRowCount,
            NeuTable,
            NeuTableRow,
            NeuLabel,
            NeuTableBody,
            NeuTableHeading,
            NeuCard
        }
    })
    export default class Notifications extends Vue.with(Props) {
        notificationData!: any;
        smsNotificationData: any[] = [];
        isShowLoader: boolean = false;
        isShowNotificationContent: boolean = false;
        isShowNotificationMessage: boolean = false;
        isRNExperienceFeatureEnabled: boolean = false;
        smsNotificationMessage: string = "";
        receivedNotifType: any = NotificationActionTypes.received;
        approvedNotifType: any = NotificationActionTypes.approved;
        deniedNotifType: any = NotificationActionTypes.denied;
        pageNum!: number;
        rowsPerPage!: number;
        first!: number;
        last!: number;
        colors: any = {
            red: 'red',
            green: 'green',
            yellow: 'yellow',
            black: 'black',
            white: 'white'
        };
        columnHeaders: any = {
            "DepartmentGroup": "1%",
            "SkillGroup": "1%",
            "Name": "10%",
            "Department": "10%",
            "Phone Number": "10%",
            "Received": "20%",
            "Message": "20%",
            "Actions": "20%"
        };
        columnIcons: any = {
            "DepartmentGroup": "none",
            "SkillGroup": "none",
            "Name": "none",
            "Department": "none",
            "Phone Number": "none",
            "Received": "none",
            "Message": "none",
            "Actions": "none"
        };
        msgValue!: string;
        classFlag!: string;
        isToasterVisible: boolean = false;
        shortDateFormat: any = 'MM/DD/YYYY';
        shortTimeFormat: any = 'h:mm A';
        shortDateFormatPresentation: any = 'MM/DD/YYYY';
        shortDateTimeFormat: any = 'YYYY-MM-DDTHH:mm:ss';
        assignmentCreationErrorMessage: any = 'Assignment could not be created';
        assignmentDeniedErrorMessage: any = 'No new assignment will be scheduled at this time.';
        assignmentCreatedSuccessfullyErrorMessage: any = 'Assignment was created successfully.';
        notificationMessageTypes: any = {
            acknowledgment: 'Acknowledgment'
        };
        appInsightEventData!: any;
        profileData!: Profile;
        facilityCoid: any = null;
        departmentCode: any = null;
        facilityName: any = null;
        departmentName: any = null;

        created() {
            this.facilityCoid = this.appInsightEventData.COID != undefined ? this.appInsightEventData.COID : '';
            this.departmentCode = this.appInsightEventData.DeptCode != undefined  ? this.appInsightEventData.DeptCode : '';
            this.facilityName = this.appInsightEventData.FacilityName != undefined  ? this.appInsightEventData.FacilityName : '';
            this.departmentName = this.appInsightEventData.DeptName != null ? this.appInsightEventData.DeptName : '';
        }

        mounted() {
            this.refreshSmsNotificationGrid();
            this.updateAppInsights();
        }

        async updateAppInsights() {
            var inputData = {
                Username: this.profileData.UserName,
                TenantName: this.profileData.TenantName,
                FacilityName: this.facilityName,
                COID: this.facilityCoid,
                DeptCode: this.departmentCode,
                DeptName: this.departmentName
            };
            await this.$store
                .dispatch("profile/updateAppInsights", inputData)
                .then(() => {

                })
                .catch((err: any) => {
                    if (err) {
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        async refreshSmsNotificationGrid() {
            if (this.notificationTabInput != null) {
                this.showLoader();
                var inputData: any = {
                    departmentIds: [],
                    SummaryStartDateTime: moment(this.notificationTabInput.DataSet.StartDateTime).format(this.shortDateTimeFormat),
                    SummaryEndDateTime: moment(this.notificationTabInput.DataSet.EndDateTime).format(this.shortDateTimeFormat),
                    TenantId: this.profileData.TenantId
                };

                if (this.notificationTabInput.departmentIdsToLoad != undefined &&
                    this.notificationTabInput.departmentIdsToLoad != null &&
                    this.notificationTabInput.departmentIdsToLoad.length > 0) {
                    this.notificationTabInput.departmentIdsToLoad.forEach(function (departmentId: any) {
                        inputData.departmentIds.push(departmentId);
                    });
                } else {
                    inputData.departmentIds.push(this.notificationTabInput.DataSet.DepartmentId);
                }

                await this.$store
                    .dispatch("assignments/getSmsNotificationResponses", inputData)
                    .then(() => {
                        if (this.notificationData != null && this.notificationData.length > 0) {
                            var modifiedData = [];
                            modifiedData = this.buildNotificationsData(this.notificationData);
                            this.generateGridData(modifiedData);
                            this.onDataGridRefresh(modifiedData);
                            this.hideLoader();
                            this.isShowNotificationContent = true;
                            this.isShowNotificationMessage = false;
                            var that = this;
                            setTimeout(() => {
                                that.setGroupingColumnStyles();
                            }, 2000);
                        }
                        else {
                            this.smsNotificationMessage = "No resources found";
                            this.isShowNotificationMessage = true;
                            this.isShowNotificationContent = false;
                            this.smsNotificationData = [];
                        }
                        this.hideLoader();

                        useAppInsights().trackEvent({
                            name: 'DASHBOARD_SMS_STAFFING_NEED_STAFF_RESPONSE', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });

                    })
                    .catch((err: any) => {
                        if (err) {
                            this.hideLoader();
                            this.msgValue = "Error encountered while retrieving notification responses";
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                            console.error(err); // Handle errors any way you want
                        }
                    });
            }
        }

        generateGridData(data: any) {
            if (data != null && data.length > 0) {
                this.smsNotificationData = [];
                //@ts-ignore
                var distinctDepartmentIds: any = jslinq(data)
                    .orderBy((el: any) => {
                        return el.departmentName;
                    })
                    .select((x: any) => x.departmentId)
                    .distinct()
                    .toList();

                if (distinctDepartmentIds != null && distinctDepartmentIds.length > 0) {
                    distinctDepartmentIds.forEach((item: any) => {
                        var otherDeptItem: any = { departmentId: null, departmentName: null, departmentGroup: null, isDeptExpand: null, skillList: [] };
                        //@ts-ignore
                        var departmentList: any = jslinq(data)
                            .where((data: any) => {
                                if (data.departmentId == item) {
                                    return data;
                                }
                            })
                            .toList();

                        if (departmentList != null && departmentList.length > 0) {
                            otherDeptItem.departmentId = departmentList[0].departmentId;
                            otherDeptItem.departmentName = departmentList[0].departmentName;
                            otherDeptItem.departmentGroup = departmentList[0].department;
                            otherDeptItem.isDeptExpand = true;

                            //@ts-ignore
                            var skillList: any = jslinq(departmentList)
                                .orderBy((el: any) => {
                                    return el.group;
                                })
                                .select((x: any) => x.group)
                                .distinct()
                                .toList();

                            if (skillList != null && skillList.length > 0) {
                                skillList.forEach((slItem: any) => {
                                    //@ts-ignore
                                    var skillDeptList: any = jslinq(departmentList)
                                        .where((data: any) => {
                                            if (data.group == slItem) {
                                                return data;
                                            }
                                        })
                                        .toList();

                                    if (skillDeptList != null && skillDeptList.length > 0) {
                                        var skillItem: any = { SkillName: null, SkillGroup: null, isSkillExpand: null, notifData: null };

                                        skillItem.skillName = skillDeptList[0].skillName;
                                        skillItem.skillGroup = skillDeptList[0].group;
                                        skillItem.isSkillExpand = true;
                                        skillItem.notifData = skillDeptList;

                                        otherDeptItem.skillList.push(skillItem);
                                    }
                                });
                            }
                        }
                        this.smsNotificationData.push(otherDeptItem);
                    });
                }
            }
            else {
                this.smsNotificationData = [];
            }
        }

        onDataGridRefresh(smsNotifications: any) {
            var smsPendingNotifications = this.getPendingSmsNotifications(smsNotifications);
            var lastestNotificationReceived = smsPendingNotifications.reduce(function (prev: any, curr: any) {
                if (prev) {
                    return prev.responseDateTime < curr.responseDateTime ? prev.responseDateTime : curr.responseDateTime;
                } else {
                    return curr.responseDateTime;
                }
            }, null);

            var duration = moment().diff(moment(lastestNotificationReceived), 'seconds');
            var eventData = {
                notificationCount: smsPendingNotifications.length,
                lastNotificationSinceNowInSeconds: duration
            };
            this.refreshNotificationBadge(eventData);
        }

        refreshNotificationBadge(eventData: any) {
            var notificationBadgeData: any;
            var notificationCount: any = eventData.notificationCount;
            var backgroundColor: any;
            var color: any;
            if (eventData.notificationCount > 0) {
                backgroundColor = this.colors.green;
                var minutesSinceLastNotification = eventData.lastNotificationSinceNowInSeconds / 60;
                if (minutesSinceLastNotification > 5 && minutesSinceLastNotification <= 10) {
                    backgroundColor = this.colors.yellow;
                    color = this.colors.black;
                } else if (minutesSinceLastNotification > 10) {
                    backgroundColor = this.colors.red;
                    color = this.colors.white;
                }
            }
            notificationBadgeData = {
                notificationCount: notificationCount,
                backgroundColor: backgroundColor,
                color: color
            };

            this.emitter.emit('refreshNotificationBadge', notificationBadgeData);
        }

        hasShiftEnded(shiftStartDate: any, shiftEndDate: any) {
            var shiftEnd = moment(shiftEndDate);
            var effectiveDate = moment();
            return effectiveDate.isSameOrAfter(shiftEnd);
        }

        getPendingSmsNotifications(smsNotifications: any) {
            var that = this;
            return smsNotifications.filter(function (notification: any) {
                var hasShiftEnded = that.hasShiftEnded(notification.shiftStartDateTime, notification.shiftEndDateTime);
                return notification.action === NotificationActionTypes.received && !hasShiftEnded;
            });
        }

        buildNotificationsData(result: any) {
            var results: any = [];
            var counter = 0;
            var that = this;
            result.forEach(function (item: any) {
                item.details.forEach(function (detail: any) {
                    detail.staffResponses.forEach(function (response: any) {
                        var clone = Object.assign({},
                            that.removeCollectionProps(item),
                            that.removeCollectionProps(detail),
                            response);
                        clone.responseDateTime = moment.utc(clone.receivedTimeUtc).local().format(that.shortDateTimeFormat);
                        //clone.ResponseText = clone.ResponseText;
                        clone.rowUniqueId = counter++;
                        clone.group = that.buildTemplateSkillShiftGroupingHeader(clone);
                        clone.staffName = detail.firstName + ' ' + detail.lastName;
                        clone.staffId = detail.staffId;
                        clone.phoneNumber = detail.phoneNumber;
                        clone.department = item.departmentName + ' ' + '(' + item.facilityName + ')';
                        results.push(clone);
                    });
                });
            });
            return results;
        }

        removeCollectionProps(obj: any) {
            var resultObj: any = {};
            for (var prop in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, prop) && !Array.isArray(obj[prop])) {
                    resultObj[prop] = obj[prop];
                }
            }
            return resultObj;
        }

        buildTemplateSkillShiftGroupingHeader(notification: any) {
            var shiftHeaderInfo = '(' + notification.deptShiftCode.trim() + ' ' + moment(notification.shiftStartDateTime).format('h:mm A') + '-' + moment(notification.shiftEndDateTime).format('h:mm A');
            shiftHeaderInfo += ' on ' + moment(notification.shiftStartDateTime).format('MM/DD/YYYY') + ')';
            return notification.notificationEventName + ': ' + notification.skillName + ' ' + shiftHeaderInfo;
        }

        showLoader() {
            this.isShowLoader = true;
            this.isShowNotificationContent = false;
            this.isShowNotificationMessage = false;
        }

        hideLoader() {
            this.isShowLoader = false;
        }

        createAssigmentModel(notificationRequest: any, skillName: any, staffId: any) {
            if (notificationRequest) {
                var objSkill_Outer: any = notificationRequest[0].skillList.filter((objSkill: any) => objSkill.skillGroup == skillName);
                var objAssg: any = objSkill_Outer[0].notifData.filter((objNotifData: any) => objNotifData.staffId == staffId);
                if (objAssg.length > 0) {
                    var assignment = {
                        AssignmentId: null,
                        DepartmentId: objAssg[0].departmentId,
                        SkillLevelId: objAssg[0].fsSkillLevelId,
                        Date: moment(objAssg[0].shiftStartDateTime).format('YYYY-MM-DD'),
                        DepartmentShiftId: objAssg[0].fsDeptShiftId === 0 ? null : objAssg[0].fsDeptShiftId,
                        Charge: false,
                        Float: false,
                        StartTime: moment(objAssg[0].shiftStartDateTime).format(this.shortDateTimeFormat),
                        EndTime: moment(objAssg[0].shiftEndDateTime).format(this.shortDateTimeFormat),
                        StaffId: objAssg[0].staffId
                    };
                    return assignment;
                }
                return null;
            }
            return null;
        }

        updateNotificationRequestToDenied(DepartmentId: any, skillName: any, staffId: any) {
            var notificationRequest: any = this.smsNotificationData.filter((x: any) => x.departmentId == DepartmentId);
            var objSkill_Outer: any = notificationRequest[0].skillList.filter((objSkill: any) => objSkill.skillGroup == skillName);
            var objAssg: any = objSkill_Outer[0].notifData.filter((objNotifData: any) => objNotifData.staffId == staffId);
            if (objAssg.length > 0) {
                objAssg[0].action = NotificationActionTypes.denied;
            }
        }

        updateNotificationRequestToScheduled(DepartmentId: any, skillName: any, staffId: any) {
            var notificationRequest: any = this.smsNotificationData.filter((x: any) => x.departmentId == DepartmentId);
            var objSkill_Outer: any = notificationRequest[0].skillList.filter((objSkill: any) => objSkill.skillGroup == skillName);
            var objAssg: any = objSkill_Outer[0].notifData.filter((objNotifData: any) => objNotifData.staffId == staffId);
            if (objAssg.length > 0) {
                objAssg[0].action = NotificationActionTypes.approved;
            }
        }

        updateGridOnCreateAssignmentSuccess(DepartmentId: any, skillName: any, staffId: any) {
            this.updateNotificationRequestToScheduled(DepartmentId, skillName, staffId);
            this.onDataGridRefresh(this.smsNotificationData);
        }

        async updateNotificationRequestOnCreateAssignmentSuccess(notificationRequest: any, skillName: any, staffId: any) {
            var objSkill_Outer: any = notificationRequest[0].skillList.filter((objSkill: any) => objSkill.skillGroup == skillName);
            var objAssg: any = objSkill_Outer[0].notifData.filter((objNotifData: any) => objNotifData.staffId == staffId);
            if (objAssg.length > 0) {
                var requestData = {
                    StaffId: objAssg[0].staffId,
                    NotificationActionId: 1,
                    NotificationRequestId: objAssg[0].notificationRequestId,
                    SendNotificationToStaff: false,
                    NotificationCode: objAssg[0].notificationCode,
                    TenantId: this.profileData.TenantId
                };

                await this.$store
                    .dispatch("assignments/updateNotificationRequest", requestData)
                    .then(() => {
                        this.emitter.emit("refreshNotificationCount");
                        this.msgValue = this.assignmentCreatedSuccessfullyErrorMessage;
                        this.classFlag = "success";
                        this.isToasterVisible = true;
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.msgValue = "Error occurred while processing your request.";
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                            console.error(err); // Handle errors any way you want
                        }
                    });
            }
        }

        onCreateAssigmentFailure(request: any) {
            var errorMessage = request.response.data.Message;
            this.msgValue = this.assignmentCreationErrorMessage + ': ' + errorMessage;
            this.classFlag = "error";
            this.isToasterVisible = true;
        }

        async scheduleNotificationRequest(DepartmentId: any, skillName: any, staffId: any) {
            var notificationRequest = this.smsNotificationData.filter((x: any) => x.departmentId == DepartmentId);
            var assignment = this.createAssigmentModel(notificationRequest, skillName, staffId);
            if (assignment) {
                var hasShiftEnded = this.hasShiftEnded(assignment.StartTime, assignment.EndTime);
                if (!hasShiftEnded) {
                    useAppInsights().trackEvent({
                            name: 'DASHBOARD_SMS_ASSIGNMENT_CREATE', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                            });
                    
                    await this.$store
                        .dispatch("assignments/createAssignmentFromNotificationTab", assignment)
                        .then(() => {
                            this.updateGridOnCreateAssignmentSuccess(DepartmentId, skillName, staffId);
                            this.updateNotificationRequestOnCreateAssignmentSuccess(notificationRequest, skillName, staffId);
                        })
                        .catch((err: any) => {
                            this.onCreateAssigmentFailure(err);
                        });
                }
            }
        }

        async denyNotificationRequest(DepartmentId: any, skillName: any, staffId: any) {
            var notificationRequest: any = this.smsNotificationData.filter((x: any) => x.departmentId == DepartmentId);
            var objSkill_Outer: any = notificationRequest[0].skillList.filter((objSkill: any) => objSkill.skillGroup == skillName);
            var objAssg: any = objSkill_Outer[0].notifData.filter((objNotifData: any) => objNotifData.staffId == staffId);
            if (objAssg.length > 0) {
                var requestData = {
                    StaffId: objAssg[0].staffId,
                    NotificationActionId: 2,
                    NotificationRequestId: objAssg[0].notificationRequestId,
                    SendNotificationToStaff: true,
                    NotificationCode: objAssg[0].notificationCode,
                    TenantId: this.profileData.TenantId
                };

                useAppInsights().trackEvent({
                            name: 'DASHBOARD_SMS_SHIFT_REQUEST_DENIED', properties:
                                JSON.parse(JSON.stringify(this.appInsightEventData))
                        });

                await this.$store
                    .dispatch("assignments/updateNotificationRequest", requestData)
                    .then(() => {
                        this.updateNotificationRequestToDenied(DepartmentId, skillName, staffId);
                        this.onDataGridRefresh(this.smsNotificationData);
                        this.msgValue = this.assignmentDeniedErrorMessage;
                        this.classFlag = "success";
                        this.isToasterVisible = true;
                        this.emitter.emit("refreshNotificationCount");
                    })
                    .catch((err: any) => {
                        if (err) {
                            this.msgValue = "Error occurred while processing your request.";
                            this.classFlag = "error";
                            this.isToasterVisible = true;
                        }
                    });
            }
        }

        setGroupingColumnStyles() {
            if (this.smsNotificationData != null && this.smsNotificationData.length > 0) {
                var deptColLength: any = document.getElementsByClassName('departmentGroupRow').length;
                var skillColLength: any = document.getElementsByClassName('skillGroupRow').length;

                if (deptColLength > 0) {
                    for (let i = 0; i < deptColLength; i++) {
                        //@ts-ignore
                        document.getElementsByClassName('departmentGroupRow')[i].shadowRoot.childNodes[0].style.width = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('departmentGroupRow')[i].shadowRoot.childNodes[0].style.minWidth = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('departmentGroupRow')[i].shadowRoot.childNodes[0].style.maxWidth = '100%';
                    }
                }

                if (skillColLength > 0) {
                    for (let i = 0; i < skillColLength; i++) {
                        //@ts-ignore
                        document.getElementsByClassName('skillGroupRow')[i].shadowRoot.childNodes[1].style.width = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('skillGroupRow')[i].shadowRoot.childNodes[1].style.minWidth = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('skillGroupRow')[i].shadowRoot.childNodes[1].style.maxWidth = '100%';
                    }
                }
            }
        }

        setGridDateFormat(date: any) {
            if (date != null && date != '') {
                var formattedDate: any = moment(date).format("MM/DD/yyyy hh:mm A");
                return formattedDate;
            }
            else {
                return '';
            }
        }

        closeToasterPopup() {
            this.isToasterVisible = false;
        }
    }

</script>


<style scoped>
    .collapseIcon {
        transform: rotate(45deg) !important;
    }
</style>