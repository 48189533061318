import http from "@/store/axios";
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from "../types";
import moment from "moment";
import camelcaseKeys from "camelcase-keys";

const namespaced = true

interface State {
    assignmentHomeDept: any
    assignmentOtherDept: any
    editAssignmentResult: any
    cancelAssignmentResult: any
    splitAssignmentResult: any
    createAssignmentResult: any
    availableStaff: any,
    availableStaffSMSSubscription: any,
    smsNotificationStatus:any
    smsNotificationTemplates: any
    shiftByDepartments: any
    skillsByDepartment: any
    notificationData:any
    smsNotificationActivityData: any
}

//state
const state: State = {
    assignmentHomeDept: null,
    assignmentOtherDept: null,
    editAssignmentResult: null,
    cancelAssignmentResult: null,
    splitAssignmentResult: null,
    createAssignmentResult: null,
    availableStaff: null,
    availableStaffSMSSubscription: null,
    smsNotificationStatus:null,
    smsNotificationTemplates: null,
    shiftByDepartments: null,
    skillsByDepartment: null,
    notificationData: null,
    smsNotificationActivityData: null
}

//actions
const actions: ActionTree<State, RootState> = {
    getAssignmentsByHomeGrid({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/assignments/bydepartmentincludefloatedassignments/${inputs.deptId}/${inputs.startTime}/${inputs.endTime}/false/true/true`;

        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setAssignmentHomeDept', res.data.data.results);
                }
                else {
                    commit('setAssignmentHomeDept', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getAssignmentsByOtherGrid({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/Assignments/bydashboard/${inputs.tenantId}/${inputs.dashboardId}/${inputs.facilityId}/${inputs.clusterId}/${inputs.startTime}/${inputs.endTime}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setAssignmentOtherDept', res.data.data.results);
                }
                else {
                    commit('setAssignmentOtherDept', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    cancelAssignment({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/assignments/${inputs.AssignId}/cancel`;

        const assignment = {
            Comment: inputs.Note,
            CancelReasonId: inputs.CancelReasonId,
            UnscheduledAbsence: inputs.UnscheduledAbsence,
            UnscheduledAbsenceOption: inputs.UnscheduledAbsenceOptionId
        };

        return http
            .post(apiUrl, assignment)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setCancelAssignmentResult', data);
                }
                else {
                    commit('setCancelAssignmentResult', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    createAssignment({ commit, rootState }, data: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/assignments`;

        const assignmentData = {
            AssignmentId: data.AssignId,
            DepartmentId: data.DepartmentId,
            SkillLevelId: data.SkillLevelID,
            Date: moment(data.Date).format('YYYY-MM-DDTHH:mm:ss'),
            DepartmentShiftId: data.DepartmentShiftId === 0 ? null : data.DepartmentShiftId,
            Charge: data.Charge,
            Float: data.IsFloat,
            Comment: data.Note,
            StartTime: moment(data.StartTime).format('YYYY-MM-DDTHH:mm:ss'),
            EndTime: moment(data.EndTime).format('YYYY-MM-DDTHH:mm:ss'),
            Hours: data.DurationHours,
            Minutes: data.DurationMinutes,
            StaffId: data.StaffId,
            FloatUserModified: data.floatChangedByUser == 1 ? true : false,
            AssociationType: data.AssociationType,
            productiveId: data.ProductiveId,
            OrigDeptId: data.DepartmentId
        };

        return http
            .post(apiUrl, assignmentData)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setCreateAssignmentResult', data);
                }
                else {
                    commit('setCreateAssignmentResult', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    editAssignment({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/assignments/`;

        return http
            .put(apiUrl, inputData)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setEditAssignmentResult', data);
                }
                else {
                    commit('setEditAssignmentResult', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    splitAssignment({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/assignments/${inputData.assignmentId}/split`;

        return http
            .post(apiUrl, inputData.assignmentsData)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    const data = camelcaseKeys(res.data.data.results, {deep: true});
                    commit('setSplitAssignmentResult', data);
                }
                else {
                    commit('setSplitAssignmentResult', null);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getAvailableStaff({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/staffs/GetStaffNotAssigned`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setAvailableStaff', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getAvailableStaffSMSSubscription({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/staffs/GetStaffNotAssigned`;

        return http
            .post(apiUrl, inputs)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setAvailableStaffSMSSubscription', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    sendSmsNotifications({ commit, rootState }, smsMessage: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/notifications/requestresponse?tenantid=${smsMessage.TenantId}`;

        return http
            .post(apiUrl, smsMessage)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '')) {
                    commit('setSMSNotificationStatus', res.data);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSMSNotificationTemplates({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/notifications/templates/${inputs.transportMediaId}?eventIds=${inputs.eventIds}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setSMSNotificationTemplates', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSmsNotificationActivity({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/notifications/getactivity?tenantid=${inputData.TenantId}`;
        return http
          .post(apiUrl, inputData)
          .then((res) => {
            if (res != null &&
                (res.data != null && res.data != '')) {
                const data = camelcaseKeys(res.data.data.results, { deep: true });
                commit('setSmsNotificationActivityData', data);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
    updateNotificationRequest({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/notifications/action?tenantid=${inputData.TenantId}`;
        return http
          .put(apiUrl, inputData)
          .then((res) => {
            if (res != null &&
                (res.data != null && res.data != '')) {
                const data = camelcaseKeys(res.data, { deep: true });
                commit('setSMSNotificationData', data);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
     getShiftByDepartmentsAndShiftSummaries({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/DepartmentShifts/active/${inputs.departmentId}/${inputs.dateVal}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setShiftByDepartments', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },
    getSkillsByDepartment({ commit, rootState }, inputs: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/SkillLevel/ByDepartment/${inputs.departmentId}/${inputs.dateVal}`;

        return http
            .get(apiUrl)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    commit('setSkillsByDepartment', res.data.data.results);
                }
            })
            .catch((err) => {
                throw err;
            });
    },

    getSmsNotificationResponses({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/notifications/getresponses?tenantid=${inputData.TenantId}`;
        return http
            .post(apiUrl, inputData)
            .then((res) => {
                if (res != null &&
                    (res.data != null && res.data != '') &&
                    (res.data.data != null)) {
                    const data = camelcaseKeys(res.data.data.results, { deep: true });
                    commit('setSMSNotificationData', data);
                    return res.data.data;
                }
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    },

    createAssignmentFromNotificationTab({ commit, rootState }, inputData: any) {
        const apiUrl = `${process.env.VUE_APP_BASE_API_URL}/assignments`;
        return http
            .post(apiUrl, inputData)
            .then((res) => {
                //if (res != null &&
                //    (res.data != null && res.data != '')) {
                    
                //}
            })
            .catch((err) => {
                throw err;
            });
    }

}

// mutations
const mutations: MutationTree<State> = {
    setAssignmentHomeDept(state, result: any) {
        state.assignmentHomeDept = result;
    },
    setAssignmentOtherDept(state, result: any) {
        state.assignmentOtherDept = result;
    },
    setEditAssignmentResult(state, result: any) {
        state.editAssignmentResult = result;
    },
    setCancelAssignmentResult(state, result: any) {
        state.cancelAssignmentResult = result;
    },
    setSplitAssignmentResult(state, result: any) {
        state.splitAssignmentResult = result;
    },
    setCreateAssignmentResult(state, result: any) {
        state.createAssignmentResult = result;
    },
    setAvailableStaff(state, result: any) {
        state.availableStaff = result;
    },
    setAvailableStaffSMSSubscription(state, result: any) {
        state.availableStaffSMSSubscription = result;
    },
    setSMSNotificationStatus(state, result: any) {
        state.smsNotificationStatus = result;
    },
    setSMSNotificationTemplates(state, result: any) {
        state.smsNotificationTemplates = result;
    },  

    setShiftByDepartments(state, result: any) {
        state.shiftByDepartments = result;
    },

    setSkillsByDepartment(state, result: any) {
        state.skillsByDepartment = result;
    },
    setSMSNotificationData(state, result: any) {
        state.notificationData = result;
    },
    setSmsNotificationActivityData(state, result: any) {
        state.notificationData = result;
    },
}

export const assignments: Module<State, RootState> = {
    namespaced,
    state,
    actions,
    mutations
}

