<template>
    <div class="card shadow-sm mb-2 bg-white rounded txtLeft">
        <div class="card-header">
            <b>Find a specific employee by First and Last Name, Login, or EIN</b>
        </div>
        <div class="card-body position-relative" style="background-color: rgba(0, 0, 0, 0.03);">
            <form id="search-form">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="firstName">FIRST NAME</label>
                            <input type="text" class="neu-input" id="firstName" 
                                v-model="firstName" :min="minTxtLength" 
                                @keyup="EnableSearchButton" @change="EnableSearchButton"
                                placeholder="Enter" style="padding-left: 5px !important;">
                        </div> 
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="lastName">LAST NAME</label>
                            <input type="text" class="neu-input" id="lastName" 
                                v-model="lastName" :min="minTxtLength" 
                                @keyup="EnableSearchButton" @change="EnableSearchButton"
                                placeholder="Enter" style="padding-left: 5px !important;">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="login">LOGIN</label>
                            <input type="text" class="neu-input" id="login" 
                                v-model="login" :min="minTxtLength" 
                                @keyup="EnableSearchButton" @change="EnableSearchButton"
                                placeholder="Enter" style="padding-left: 5px !important;">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="ein">EIN</label>
                            <input type="text" class="neu-input" id="ein" 
                                v-model="ein" :min="minEinTxtLength" 
                                @keyup="EnableSearchButton" @change="EnableSearchButton"
                                placeholder="Enter" style="padding-left: 5px !important;">
                        </div>
                    </div>
                    <div class="col text-end">
                        <neu-button type="button" fill="raised" 
                            style=" position: relative; top: 20px; min-width: 50%; float: right !important;"
                            :disabled="isDisableSearchBtn" @click="isDisableSearchBtn ? null : searchFormOnSubmit()"
                            class="col-md-3 padding0" >
                            SEARCH
                        </neu-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div id="search-content-loading" class="text-center mt-3" v-show="isShowLoader">
        <div class="fa-2x">
            <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
        </div>
        <p><b>Searching...</b></p>
    </div>
    <div id="search-content" v-if="isShowStaffingContent">
        <p style="padding-bottom: 15px;" class="txtLeft">Possible matches identified.</p>
        <p style="padding-bottom: 15px;" class="txtLeft">Select an employee in the grid below to create an assignment for them in your department.</p>
        <div id="grdSearch">
            <neu-card color="plain-0" class="p-0 m-0" v-if="staffSearchResult != null && staffSearchResult.length > 0">
                <neu-table>
                    <neu-table-row :columns="columnHeaders" header>
                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                    <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column" style="padding-top: 25px !important;" @v-neu-change="sortData($event,column)">
                        <template v-if="(column != 'RN Experience' || (column == 'RN Experience' && isRNExperienceFeatureEnabled))">
                            {{ column }}
                        </template>
                    </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-row :columns="columnHeaders" v-for="dataItem in staffSearchResult" :slot="column" 
                                            :key="dataItem" :class="[dataItem.Charge ? 'chargeAssignment': '']"
                                            @click="onRowSelect(dataItem)">            
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="First Name" :title="dataItem.firstName">
                                    {{ dataItem.firstName }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Name" :title="dataItem.lastName">
                                    {{ dataItem.lastName }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Facility" :title="dataItem.facilityName">
                                    {{ dataItem.facilityName }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Department" :title="dataItem.departmentDescription">
                                    {{ dataItem.departmentDescription }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Skills" :title="dataItem.skills"
                                            :class="[dataItem.isOvertime ? 'gridOverTime': '']">
                                    {{ dataItem.skills }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Type">
                                    {{ dataItem.employeeType }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="FTE">
                                    {{ dataItem.fte }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Hours">
                                    {{ dataItem.hours }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="RN Experience" v-if="dataItem.rnExperienceFeatureEnabled"
                                            :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '']">
                                    {{ dataItem.rnExperience }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Hire Date">
                                    {{ setGridDateFormat(dataItem.hireDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Seniority Date">
                                    {{ setGridDateFormat(dataItem.seniorityDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="End Date">
                                    {{ setGridDateFormat(dataItem.endDate) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Cancelled">
                                    {{ setGridDateFormat(dataItem.lastCancelled) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Last Floated">
                                    {{ setGridDateFormat(dataItem.lastFloated) }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="Login" :title="dataItem.threeFourId">
                                    {{ dataItem.threeFourId }}
                                </neu-label>
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-label slot="EIN" :title="dataItem.ein">
                                    {{ dataItem.ein }}
                                </neu-label>
                            </neu-table-row>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
    </div>
    <div id="search-message" class="text-center mt-3" v-if="isShowStaffingMessage">
        <p id="search-message-text">{{staffingMessage}}</p>
    </div>
    <div id="care-assignment-container-staffsearch"></div>
    <!-- Toaster -->
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import moment from "moment";

//imports models and enums
import { AppMode} from "@/enums/enum";

//Import Componenets
import ToasterPopup from "../../shared/ToasterPopup.vue";
import {
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuButton
} from "@neutron/vue";

class Props {
    staffingSerachTabInput!: any 
}

@Options({
  computed: {
    ...mapState('actionable', ['staffSearch']),
  },
  components: {
    ToasterPopup,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuButton
  }
}) 
export default class StaffSearch extends Vue.with(Props) {
    //Data properties
    staffSearch!: any;
    staffSearchResult: any = null;
    isShowLoader: boolean = false;
    isShowStaffingContent: boolean = false;
    isShowStaffingMessage: boolean = false;
    staffingMessage: string = "";
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    isDisableSearchBtn: boolean = true;
    firstName!: string;
    lastName!: string; 
    login!: string; 
    ein!: string;
    minTxtLength: number = 3;
    minEinTxtLength: number = 9;
    isRNExperienceFeatureEnabled: boolean = false;
    columnHeaders: any ={ 
        "First Name": "6%",
        "Last Name": "6%",
        Facility: "8%",
        Department: "8%",
        Skills: "4%",
        Type: "5%",
        FTE: "4%",
        Hours: "4%",
        "RN Experience": "5%",
        "Hire Date": "8%",
        "Seniority Date": "8%",
        "End Date": "7%",
        "Last Cancelled": "8%",
        "Last Floated": "8%",
        Login: "5%",
        EIN: "6%"
    };
    columnIcons: any = {  
        "First Name": "desc",
        "Last Name": "desc",
        Facility: "desc",
        Department: "desc",
        Skills: "desc",
        Type: "desc",
        FTE: "desc",
        Hours: "desc",
        "RN Experience": "desc",
        "Hire Date": "desc",
        "Seniority Date":"desc",
        "End Date": "desc",
        "Last Cancelled": "desc",
        "Last Floated": "desc",
        Login: "desc",
        EIN: "desc"
    };

    columnForSort: any = { 
        "First Name": "firstName",
        "Last Name": "lastName",
        "Facility": "facilityName",
        "Department": "departmentDescription",
        "Skills": "skills",
        "Type": "employeeType",
        "FTE": "fte",
        "Hours": "hours",
        "RN Experience": "rnExperience",
        "Hire Date": "hireDate",
        "Seniority Date":"seniorityDate",
        "End Date": "endDate",
        "Last Cancelled": "lastCancelled",
        "Last Floated": "lastFloated",
        "Login": "threeFourId",
        "EIN": "ein"
	};

    //Lifecycle hooks
    created(){
        this.emitter.on("refreshStaffSearch", this.refreshStaffSearch);
    }

    unmounted(){
        this.emitter.off("refreshStaffSearch", this.refreshStaffSearch);
    }

    refreshStaffSearch(){
        this.firstName = '';
        this.lastName = '';
        this.login = '';
        this.ein = '';
        this.isShowStaffingContent = false;
        this.isDisableSearchBtn = true;
        this.staffSearchResult = [];
    }
    //Methods
    EnableSearchButton() {
        if(this.firstName != null && this.firstName != '' && this.firstName.length >= this.minTxtLength){
            this.isDisableSearchBtn = false;
            return;
        }
        if(this.lastName != null && this.lastName != '' && this.lastName.length >= this.minTxtLength){
            this.isDisableSearchBtn = false;
            return;
        }
        if(this.login != null && this.login != '' && this.login.length >= this.minTxtLength){
            this.isDisableSearchBtn = false;
            return;
        }
        if(this.ein != null && this.ein != '' && this.ein.length >= this.minEinTxtLength){
            this.isDisableSearchBtn = false;
            return;
        }

        this.isDisableSearchBtn = true;
    }

    async searchFormOnSubmit(){
        var inputParam = this.getSerachUrl();
        this.showLoader();
        await this.$store
                .dispatch("actionable/getStaffSearch", inputParam)
                .then(() => {
                    if(this.staffSearch != null && this.staffSearch.length > 0){
                        this.staffSearchResult = JSON.parse(JSON.stringify(this.staffSearch));
                        this.isRNExperienceFeatureEnabled = this.staffSearchResult[0].rnExperienceFeatureEnabled
                        this.isShowStaffingMessage = false;
                        this.isShowStaffingContent = true;  
                    }
                    else{
                        this.staffingMessage = "No resources found";
                        this.isShowStaffingMessage = true;
                        this.isShowStaffingContent = false;
                    }
                    this.hideLoader();
                    
                    var that = this;
                    setTimeout(() =>{
                        this.scrollToTop();
                    }, 1000);
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        this.msgValue = "Error encountered while retrieving Staff details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    getSerachUrl(){
        var params = this.firstName ? 'FirstName=' + this.firstName : '';

        params += params && this.lastName ? '&' : '';

        params += this.lastName ? 'LastName=' + this.lastName : '';

        params += params && this.login ? '&' : '';

        params += this.login ? 'Username=' + this.login : '';

        params += params && this.ein ? '&' : '';

        params += this.ein ? 'Ein=' + this.ein : '';

        params = params ? '?' + params : '';

        return params;
    }

    setGridDateFormat(date: any){
        if(date != null && date != ''){
            var formattedDate: any = moment(date).format("MM/DD/YYYY");
            return formattedDate;
        }
        else{
            return '';
        }
    }

    onRowSelect(dataItem: any){
        if(this.staffingSerachTabInput.AppMode === AppMode.FullAccess){
            var currentDataItem = dataItem;

            var info = this.staffingSerachTabInput.DataSet;
            info.AssignId = '00000000-0000-0000-0000-000000000000';

            currentDataItem.actionableDepartment = info;
            currentDataItem.tabName = "Staff Search";
            currentDataItem.config = this.staffingSerachTabInput.Config;

            this.emitter.emit('createAssignment', currentDataItem);
        }
    }

    showLoader(){
        this.isShowLoader = true;
        this.isShowStaffingContent = false;
        this.isShowStaffingMessage = false;
    }

    hideLoader(){
        this.isShowLoader = false;
    }

    closeToasterPopup() {
      this.isToasterVisible = false;
    }

    scrollToTop(){
        var actionableDiv: any = document.getElementById("actionable");
        actionableDiv.scrollTop = 250;
    }

    sortData(ev: any, columnName: any){
        var column = this.columnForSort[columnName];
        
        this.staffSearchResult.sort((col1: any,col2: any) => {
            let modifier = 1;
            if (ev.detail.direction.toLowerCase() === 'desc') modifier = -1;

            if (col1[column] < col2[column]) return -1 * modifier; 
            if (col1[column] > col2[column]) return 1 * modifier;
        });
    }
}
</script>
<style scoped>

</style>