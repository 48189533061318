<template>
<div>
    <div class="row">
        <div class="col-xl txtLeft">

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Facility</label>
                        <input class="form-control" readonly :value="FacilityName" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Service Line</label>
                        <input class="form-control" readonly :value="ClusterName" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Department</label>
                        <input class="form-control" readonly :value="DepartmentName" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Status</label>
                        <input class="form-control" readonly :value="ProductiveDescription" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Shift Code</label>
                        <input class="form-control" readonly :value="ShiftCode" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Skill</label>
                        <input class="form-control" readonly :value="SkillLevelDescription" />
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Date</label>
                        <input class="form-control" readonly :value="ShiftDate" />
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="font-weight-bold">Start Time</label>
                                <input class="form-control" readonly :value="StartTime" />
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="form-group">
                                    <label class="font-weight-bold">End Time</label>
                                    <input class="form-control" readonly :value="EndTime" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="font-weight-bold">Charge</label>
                                <div class="assignmentTxt" v-html="ChargeStatus"></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="font-weight-bold">Float</label>
                                <div class="assignmentTxt" v-html="FloatStatus"></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="font-weight-bold">Cancelled</label>
                                <div class="assignmentTxt" v-html="CancelStatus"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="showCancelReason">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Cancellation Reason</label>
                        <input class="form-control" readonly :value="CancelReason" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Unscheduled Absence</label>
                        <input class="form-control" readonly :value="UnscheduledAbsenceText" />
                    </div>
                </div>
            </div>

        </div>

        <div class="col-xl-5">
            <div class="form-group">
                <div class="txtLeft">
                    <label class="font-weight-bold">Notes</label>
                </div>
                <div id="assignmentedit-notes-container">
                    <AddViewNote />
                </div>
            </div>
        </div>

    </div>
</div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//imports models and enums
import { NoteType, NoteRelationType} from "@/enums/enum";
import { Profile } from '@/models/profile/index';

//Import Componenets
import AddViewNote from './AddViewNote.vue';

class Props {
    assignmentViewInput!: any 
}

@Options({
  computed: {
    ...mapState('actionable', ['deptCancelReasons']),
    ...mapState('profile', ['profileData'])
  },
  components: {
      AddViewNote
  }
}) 
export default class AssignmentView extends Vue.with(Props) {
    //Data Properties
    profileData!: Profile;
    shortDateFormat: string = 'MM/DD/YYYY';
    shortTimeFormat: string = 'hh:mm A';
    shortDateFormatPresentation: string = 'MM/DD/YYYY';
    deptCancelReasons!: any;

    ActualEndTime: any = null;
    ActualStartTime: any = null;
    AssignId: any = null;
    AssignmentDateCreated: any = null;
    AssignmentDateChanged: any = null;
    CancelComment: any = null;
    CancelReason: any = null;
    CancelReasonId: any = null;
    Charge: boolean = false;
    ClusterId: any = null;
    ClusterName: any = null;
    CountTypeId: any = null;
    CredentialCode: any = null;
    CredentialNumber: any = null;
    CredentialRenewalDate: any = null;
    CredentialTrackingStatusId: any = null;
    ShiftDate: any = null;
    Day: any = null;
    DepartmentCode: any = null;
    DepartmentId: any = null;
    DepartmentName: any = null;
    DepartmentShiftId: any = null;
    EmployeeName: any = null;
    EmployeeType: any = null;
    EndTime: any = null;
    FacilityId: any = null;
    FacilityName: any = null;
    FTE: any = null;
    FirstName: any = null;
    HireDate: any = null;
    Hours: any = null;
    IsCancelled: boolean = false;
    IsFloat: boolean = false;
    IsOvertime: boolean = false;
    LastName: any = null;
    NeedStatusId: any = null;
    OrigDepartmentCode: any = null;
    OrigDepartmentId: any = null;
    OrigDepartmentName: any = null;
    OverTimeOccurence: any = null;
    OvertimeShift: any = false;
    ProductiveDescription: any = null;
    ProductiveId: any = null;
    ProfileCharge: boolean = false;
    SeniorityDate: any = null;
    ServiceLineId: any = null;
    ShiftCode: any = null;
    SkillLevelDescription: any = null;
    SkillLevelId: any = null;
    SkillSort: any = null;
    StaffId: any = null;
    StaffShift: any = null;
    StartTime: any = null;
    UnscheduledAbsence: boolean = false;
    UnscheduledAbsenceText: any = null;
    ChargeStatus: any = false;
    FloatStatus: any = false;
    CancelStatus: any = false;
    ShiftDuration: any = null;
    showCancelReason: boolean = false;

    //list
    ProductiveCodes: any = [
        { fsProductiveID: 1, Description: 'Productive' },
        { fsProductiveID: 2, Description: 'Other-productive' },
        { fsProductiveID: 3, Description: 'Non-productive' },
        { fsProductiveID: 4, Description: 'Call' },
        { fsProductiveID: 5, Description: 'Placeholder' }
    ];

    UnscheduledAbsenceOptions = [
        { value: 0, text: 'N/A' },
        { value: 1, text: 'Employee made contact' },
        { value: 2, text: 'No show / No call' }
    ];        
        
     //Lifecycle hooks
    async mounted(){
        await this.loadAssignmentView();
    }

    //Methods
    loadAssignmentView(){
        this.map();
        this.loadNoteModule();
    }
    async map(){
        var facilityId = this.getValue(this.assignmentViewInput.facilityId);
        var facilityName = this.getValue(this.assignmentViewInput.facilityName);
        var clusterId = this.getValue(this.assignmentViewInput.clusterId);
        var clusterName = this.getValue(this.assignmentViewInput.clusterName);
        var deptId = this.getValue(this.assignmentViewInput.departmentId);
        var deptName = this.getValue(this.assignmentViewInput.departmentName);
        var date = moment(this.getValue(this.assignmentViewInput.date));
        var startTime = moment(this.getValue(this.assignmentViewInput.startTime));
        var endTime = moment(this.getValue(this.assignmentViewInput.endTime));
        var productiveId = this.getValue(this.assignmentViewInput.productiveId);
        var chargeStatus = this.getValue(this.assignmentViewInput.charge) ? 'Yes' : 'No';
        var floatStatus = this.getValue(this.assignmentViewInput.isFloat) ? 'Yes' : 'No';
        var cancelStatus = this.getValue(this.assignmentViewInput.isCancelled) ? 'Yes' : 'No';
        var shiftDuration = endTime.diff(startTime, 'hours', true);
        var UnscheduledAbsence = this.getValue(this.assignmentViewInput.unscheduledAbsence);
        var cancelReasonId = this.getValue(this.assignmentViewInput.cancelReasonId) === null ? 0 : this.getValue(this.assignmentViewInput.cancelReasonId);
        var unScheduledAbsenceOptionId = this.getValue(this.assignmentViewInput.unscheduledAbsenceOption) === null ? 0 : this.getValue(this.assignmentViewInput.unscheduledAbsenceOption);

        if (facilityId == null) { facilityId = this.getValue(this.assignmentViewInput.actionableDepartment.FacilityId); }
        if (facilityName == null) { facilityName = this.getValue(this.assignmentViewInput.actionableDepartment.FacilityName); }
        if (clusterId == null) { clusterId = this.getValue(this.assignmentViewInput.actionableDepartment.ClusterId); }
        if (clusterName == null) { clusterName = this.getValue(this.assignmentViewInput.actionableDepartment.ClusterName); }
        if (deptId == null) { deptId = this.getValue(this.assignmentViewInput.actionableDepartment.DepartmentId); }
        if (deptName == null) { deptName = this.getValue(this.assignmentViewInput.actionableDepartment.DepartmentName); }

        //@ts-ignore
        var shiftType: any = jslinq(this.ProductiveCodes)
                                .where((data: any) => { 
                                    if(data.fsProductiveID == productiveId){
                                        return data;
                                    }})
                                .toList();
        //@ts-ignore
        var uaOption: any = jslinq(this.UnscheduledAbsenceOptions)
                                .where((data: any) => { 
                                    if(data.value == unScheduledAbsenceOptionId){
                                        return data;
                                    }})
                                .toList(); 

        if (cancelReasonId !== null) {
            var inputData = {
                deptId: deptId,
                tenantId: this.profileData.TenantId
            }

            await this.$store
                .dispatch("actionable/getDepartmentCancelReasons", inputData)
                .then(() => {
                    if (this.deptCancelReasons != null && this.deptCancelReasons.length > 0) {
                        //@ts-ignore
                        var cancelReasons: any = jslinq(this.deptCancelReasons)
                                .where((data: any) => { 
                                    if(data.reasonId == cancelReasonId){
                                        return data;
                                    }})
                                .toList(); 

                        this.CancelReason = (cancelReasons != null && cancelReasons.length > 0) ? cancelReasons[0].reason : '';
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        console.error(err); // Handle errors any way you want
                    }
                });
        }

        // load model data
        this.ActualEndTime = this.getValue(this.assignmentViewInput.actualEndTime);
        this.ActualStartTime = this.getValue(this.assignmentViewInput.actualStartTime);
        this.AssignId = this.getValue(this.assignmentViewInput.assignId);
        this.AssignmentDateCreated = this.getValue(this.assignmentViewInput.assignmentDateCreated);
        this.AssignmentDateChanged = this.getValue(this.assignmentViewInput.assignmentDateChanged);
        this.CancelComment = this.getValue(this.assignmentViewInput.cancelComment);
        //this.CancelReason = this.getValue(this.assignmentViewInput.CancelReason);
        this.CancelReasonId = this.getValue(this.assignmentViewInput.cancelReasonId);
        this.Charge = this.getValue(this.assignmentViewInput.charge);
        this.ClusterId = clusterId;
        this.ClusterName = clusterName;
        this.CountTypeId = this.getValue(this.assignmentViewInput.countTypeId);
        this.CredentialCode = this.getValue(this.assignmentViewInput.credentialCode);
        this.CredentialNumber = this.getValue(this.assignmentViewInput.credentialNumber);
        this.CredentialRenewalDate = this.getValue(this.assignmentViewInput.credentialRenewalDate);
        this.CredentialTrackingStatusId = this.getValue(this.assignmentViewInput.credentialTrackingStatusId);
        this.ShiftDate =  moment(date).format(this.shortDateFormat);
        this.Day = this.getValue(this.assignmentViewInput.day);
        this.DepartmentCode = this.getValue(this.assignmentViewInput.departmentCode);
        this.DepartmentId = deptId;
        this.DepartmentName = deptName;
        this.DepartmentShiftId = this.getValue(this.assignmentViewInput.departmentShiftId);
        this.EmployeeName = this.getValue(this.assignmentViewInput.lastName) + ', ' + this.getValue(this.assignmentViewInput.firstName);
        this.EmployeeType = this.getValue(this.assignmentViewInput.employeeType);
        this.EndTime = endTime.format(this.shortTimeFormat);
        this.FacilityId = facilityId;
        this.FacilityName = facilityName;
        this.FTE = this.getValue(this.assignmentViewInput.fte);
        this.FirstName = this.getValue(this.assignmentViewInput.firstName);
        this.HireDate = this.getValue(this.assignmentViewInput.hireDate);
        this.Hours = this.getValue(this.assignmentViewInput.hours);
        this.IsCancelled = this.getValue(this.assignmentViewInput.isCancelled);
        this.IsFloat = this.getValue(this.assignmentViewInput.isFloat);
        this.IsOvertime = this.getValue(this.assignmentViewInput.isOvertime);
        this.LastName = this.getValue(this.assignmentViewInput.lastName);
        this.NeedStatusId = this.getValue(this.assignmentViewInput.needStatusId);
        this.OrigDepartmentCode = this.getValue(this.assignmentViewInput.origDepartmentCode);
        this.OrigDepartmentId = this.getValue(this.assignmentViewInput.origDepartmentId);
        this.OrigDepartmentName = this.getValue(this.assignmentViewInput.origDepartmentName);
        this.OverTimeOccurence = this.getValue(this.assignmentViewInput.overTimeOccurence);
        this.OvertimeShift = this.getValue(this.assignmentViewInput.overtimeShift);
        this.ProductiveDescription = (shiftType != null && shiftType.length > 0) ? shiftType[0].Description : '';
        this.ProductiveId = productiveId;
        this.ProfileCharge = this.getValue(this.assignmentViewInput.profileCharge);
        this.SeniorityDate = this.getValue(this.assignmentViewInput.seniorityDate);
        this.ServiceLineId = this.getValue(this.assignmentViewInput.serviceLineId);
        this.ShiftCode = this.getValue(this.assignmentViewInput.shiftCode);
        this.SkillLevelDescription = this.getValue(this.assignmentViewInput.skillLevelDescription);
        this.SkillLevelId = this.getValue(this.assignmentViewInput.skillLevelId);
        this.SkillSort = this.getValue(this.assignmentViewInput.skillSort);
        this.StaffId = this.getValue(this.assignmentViewInput.staffId);
        this.StaffShift = this.getValue(this.assignmentViewInput.staffShift);
        this.StartTime = moment(startTime).format(this.shortTimeFormat);
        this.UnscheduledAbsence = UnscheduledAbsence;
        this.UnscheduledAbsenceText = (uaOption != null && uaOption.length > 0) ? uaOption[0].text : '';

        this.ShiftDuration =  '(' + shiftDuration + ' hours)';
        this.ChargeStatus =  chargeStatus;
        this.FloatStatus = floatStatus;
        this.CancelStatus = cancelStatus;

        this.showCancelReason = this.assignmentViewInput.isCancelled;
    }

    getValue(obj: any) {
        if (typeof obj === undefined) {
            return null;
        }
        return obj;
    }

    loadNoteModule(){
        var model = {
            NoteTypeId: NoteType.COMMENTS,
            DepartmentId: this.assignmentViewInput.departmentId,
            RelationId: NoteRelationType.FS_ASSIGN,
            TenantId: this.profileData.TenantId,
            CostCenterId: this.assignmentViewInput.actionableDepartment.FacilityId,
            RelationIdValue: this.assignmentViewInput.assignId,
            UserId: this.profileData.UserId,
            StartDateTime: this.assignmentViewInput.actualStartTime,
            EndDateTime: this.assignmentViewInput.actualEndTime,
            NeedId: this.assignmentViewInput.needId,
            AssignmentId: this.assignmentViewInput.assignId,
            DepartmentShiftId: this.assignmentViewInput.departmentShiftId,
        };

        this.emitter.emit('loadNotes', model);
    }

    
}

</script>
<style scoped>

#Actionable .content {
    height: calc(100vh - 240px) !important;
}

.assignment {
    position: relative;
    background-color: #fff;
    z-index: 1050;
    height: calc(100vh - 285px) !important;
}

.assignment-notes-container {
    overflow: auto;
    max-height: 300px;
}

.form-control {
    font-size: 14px !important;
    min-height: 35px !important;
}

.assignmentTxt{
    font-size: 14px !important;
}
</style>