<template>
    <div class="modalBackdrop flex absolute z-max">
        <div class="save-modal absolute" id="saveAlertPopUpModal" aria-labelledby="savePopUpModalLabel" aria-hidden="true">
            <div class="modalContent">
                <div :class="['neu-snackbar', calssName]">
                    <div>
                        <i class="material-icons mr0 neu-snackbar__feedback-icon iconPadding">
                            <neu-icon>
                            {{
                                iconName
                            }}
                            </neu-icon>
                        </i>
                        <!-- <span>{{ headerText}}</span> -->
                    </div>
                    <span :class="['neu-snackbar__text', notificationBody]" v-html="msgValue">
                    </span>
                    <button :class="[btnClassName, 'neu-button neu-button--small neu-margin--left-auto']" @click="closeButtonClicked">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    //import plugins and modules
    import { Vue, prop } from 'vue-class-component';

    class Props {
        msgValue!: string
        classFlag!: string 
    }

    export default class FeedbackSnackbar extends Vue.with(Props) {

        calssName: string = "";
        iconName: string = "";
        btnClassName: string = "";
        headerText: string = "";
        notificationBody: string = "";

        created() {
            if (this.classFlag.toLowerCase() == 'success') {
                this.calssName = 'neu-snackbar--success';
                this.iconName = 'check_circle';
                this.btnClassName = 'neu-button--success btnNotificationSuccess';
                this.headerText = 'Success';
                this.notificationBody = 'txtSuccessMsg';
            }
            else if (this.classFlag.toLowerCase() == 'error') {
                this.calssName = 'neu-snackbar--error';
                this.iconName = 'error';
                this.btnClassName = 'neu-button--danger btnNotificationError';
                this.headerText = 'Alert';
                this.notificationBody = 'txtErrorMsg';
            }
            else if (this.classFlag.toLowerCase() == 'warning') {
                this.calssName = 'neu-snackbar--warning';
                this.iconName = 'warning';
                this.btnClassName = 'neu-button--warning btnNotificationWarning';
                this.headerText = 'Alert';
                this.notificationBody = 'txtErrorMsg';
            }
        }

        mounted() {
            var toasterPopupElement = document.getElementById('savePopUpModal') as HTMLElement;
            var alertPopupElement = document.getElementById('saveAlertPopUpModal') as HTMLElement;

            if(toasterPopupElement != null){
                var toasterPopupHeight = toasterPopupElement.clientHeight + 20;
                if(alertPopupElement != null){
                    alertPopupElement.style.top = toasterPopupHeight + "px";
                }
            }
        }

        closeButtonClicked() {
            this.$emit('closeModal')
        }
    }

</script>
<style >
.btnNotificationError{
    color: #ffffff !important;
    background-color: #960b1c !important;
}
.iconPadding{
    padding: 10px 0px !important;
    position: relative;
    top: 10px;
}
.txtSuccessMsg{
    padding: 10px !important;
    display: block !important;
    font-weight: 100 !important;
}

.btnNotificationSuccess{
    color: #ffffff !important;
    background-color: #285c4d !important;
}

.btnNotificationWarning{
    color: #ffffff !important;
    background-color: #6f4705 !important;
}

.boldTxt{
    font-weight: 700 !important;
}

.txtErrorMsg{
    padding: 10px !important;
    display: block !important;
}

.ulList{
    padding-left: 2rem !important;
}

.neu-snackbar{
    font-size: .875rem !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
}

.material-icons{
    line-height: inherit !important
}
</style>