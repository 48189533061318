<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Staff To / Forecast Census</span>
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div class="panleBody scrollBar">
                <div id="StaffToForecast" class="container" style="width:inherit">
                    <div id="census-header">
                        <div class="card shadow-sm mb-3 bg-white rounded">
                            <div class="card-body position-relative">
                                <div class="row">
                                    <div class="col-sm-7 txtLeft">
                                        <h5 class="card-title mb-2">{{facilityName}}</h5>
                                        <h6 class="card-subtitle mb-2 text-muted"><span>{{serviceLineName}}</span> > <span>{{departmentName}}</span></h6>
                                        <div><font-awesome-icon :icon="['far', 'calendar']" size="sm" class="mr-2" /> <span class="font-weight-bold">{{startDate}}</span></div>
                                        <div><font-awesome-icon :icon="['far', 'clock']" size="sm" class="mr-1" /> <span class="font-weight-bold">{{startTime}}</span> - <span class="font-weight-bold" >{{endTime}}</span></div>
                                    </div>
                                    <div class="col text-end">
                                        <div v-show="pcsEnabled">
                                            <h6>PCS</h6>
                                            <span class="pcstotalcensus">Count: <span class="digits" >{{pcsTotalCensus != null ? pcsTotalCensus: '00'}}</span></span><br />
                                            <span class="pcsrating">Rating: <span class="digits">{{pcsRating != null ? pcsRating: '00'}}</span></span><br />
                                            <span class="pcsrangename">Level: <span>{{pcsRangeName}}</span></span><br />
                                        </div>
                                    </div> 
                                    <div class="col-sm-3 text-end">
                                        <h6><span>{{statDescription}}</span></h6>
                                        <span class="staffto" :class="[isHightlightStaffTo ? 'census-highlight' : '']">Staff To: <span class="digits">{{staffTo != null ? staffTo: '00'}}</span></span><br />
                                        <span class="actual" :class="[isHightlightActualCensus ? 'census-highlight' : '']">Actual: <span class="digits">{{actualCensus != null ? actualCensus: '00'}}</span></span><br />
                                        <span class="forecast" :class="[isHightlightForecastCensus ? 'census-highlight' : '']">Forecast: <span class="digits" >{{forecastCensus != null ? forecastCensus: '00'}}</span></span><br />
                                        <span class="census-data">Volume Level</span>: <span class="digits">{{censusLow}}</span>-<span class="digits">{{censusPeak}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-end">
                                        <div>Last Census Update: <span>{{lastCensusUpdateStartDateTime}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card shadow-sm mb-3 bg-white rounded content">
                            <div class="card-body position-relative" style="overflow: auto;">
                                <div class="census-control-alerts my-3"></div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="font-weight-bold">Forecast</label>
                                            <neu-input name="forecastCensus" readonly disabled v-model="forecastCensus"></neu-input>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="font-weight-bold">Actual Census</label>
                                            <neu-input name="actualCensus" readonly disabled  v-model="actualCensus"></neu-input>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="font-weight-bold">Staff To</label>
                                            <neu-input name="staffTo" v-model="staffTo" 
                                                inputmode="numeric"
                                                type="number"
                                                :max="999" 
                                                :min="0"
                                                :disabled="isDisable"
                                                @keyup="validateStaffTo"
                                                @change="onChangeStaffTo">
                                            </neu-input>
                                        </div>
                                    </div>
                                    <div class="col" v-show="pcsEnabled">
                                        <div class="form-group">
                                            <label class="font-weight-bold">PCS</label>
                                            <neu-input name="pcsTotalCensus" readonly disabled v-model="pcsTotalCensus"></neu-input>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col txtLeft">
                                        <div>
                                            <div v-show="pcsEnabled">
                                                <h6 class="font-weight-bold">Patient Classification Levels:</h6>
                                                <div class="row">
                                                    <!-- PCS Levels -->
                                                    <div data-role="listview" class="col form-inline form-pcs" >
                                                        <template v-for="pcslvl in pcsLevels" :key="pcslvl">
                                                            <div class="form-group mb-0">
                                                                <label class="font-weight-bold">{{pcslvl.Level}}</label>
                                                                <neu-input name="Census" v-model="pcslvl.Census" 
                                                                    inputmode="numeric"
                                                                    type="number"
                                                                    :max="999" 
                                                                    :min="0"
                                                                    class="pcsLevelTxt"
                                                                    @change="pcsOnChange">
                                                                </neu-input>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="font-weight-bold">Avg. PCS Rating: <span>{{pcsNewRating}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="font-weight-bold">Notes</label>
                                            <div id="census-notes-container">
                                                <AddViewNote />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="text-end">
                                                <neu-button type="button" fill="raised" id="btnClose"
                                                    @click="close"
                                                    class="close-census-button" >
                                                    <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="sm" class="mr-2" /> Close
                                                </neu-button>
                                                <neu-button type="button" fill="raised" id="btnSaveCensus"
                                                    @click="btnSaveCensus_OnClick">
                                                    <font-awesome-icon :icon="['fas', 'save']" size="sm" class="mr-2" /> Save Changes
                                                </neu-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="staffingForecastLoadingDiv" class="loadingDiv1" style="padding: 5px;" v-if="isShowStaffingForecastLoadingDiv">
                    <div id="actionable-content-loading" style="text-align: center">
                        <div class="fa-2x">
                            <i class="fas fa-circle-notch fa-spin"></i>
                        </div>
                        <p><b>Saving...</b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum
import { AppMode, NoteType, NoteRelationType } from "@/enums/enum";
import { Profile } from '@/models/profile/index';
import { useAppInsights } from '../../store/modules/AppInsights';

//Import Componenets
import {
    NeuInput,
    NeuButton
} from "@neutron/vue";
import AddViewNote from '../actionable/assignment/AddViewNote.vue';

class Props {
    staffToForecastInput!: any 
}

@Options({
  computed: {
    ...mapState('shared', ['deparmentMetrics']),
    ...mapState('dashboard', ['deptForecastCensus', 'deptCensus', 'patientClassificationRatingDetails', 'createPatientClassificationRatingResult', 'updatePatientClassificationRatingResult']),
    ...mapState('actionable', ['patientClassification']),
    ...mapState('profile', ['profileData', 'appInsightEventData']),
  },
  components: {
    NeuInput,
    NeuButton,
    AddViewNote
  }
}) 
export default class StaffToForecastCensus extends Vue.with(Props) {
    //Data properties
    deparmentMetrics!: any;
    deptForecastCensus!: any;
    deptCensus!: any;
    patientClassification!: any;
    profileData!: Profile;
    patientClassificationRatingDetails!: any;
    createPatientClassificationRatingResult!: any;
    updatePatientClassificationRatingResult!: any;

    assignId: any = null;
    facilityId: any = null;
    facilityCoId: any = null;
    facilityName: any = null;
    clusterId: any = null;
    clusterName: any = null;
    departmentId: any = null;
    departmentName: any = null;
    departmentCode: any = null;
    departmentSummaryId: any = null;
    startDateTime: any = null;
    endDateTime: any = null;
    skillLevelId: any = null;
    skillLevelDesc: any = null;
    startDate: any = null;
    endDate: any = null;
    startTime: any = null;
    endTime: any = null;
    actualCensus: any = null;
    forecastCensus: number = 0;
    forecastCensusPreviousValue: number = 0;
    staffTo: any = null;
    staffToPreviousValue: any = null;
    scheduled: any = null;
    target: any = null;
    skillLevelDescription: any = null;
    serviceLineName: any = null;
    note: any = null;
    
    pcsLevels: any = [];
    pcsRanges: any = [];
    pcsEnabled: boolean = false;
    isPCSImport: boolean = false;
    shortDateFormat: string = 'MM/DD/YYYY';
    shortTimeFormat: string = 'h:mm A';
    shortDateFormatPresentation: string = 'MM/DD/YYYY';
    shortDateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    shortDateFormatYYYYMMDD: string = 'YYYY-MM-DD';
    longDateTimeFormatPresentation: string = 'MM/DD/YYYY  hh:mm a';
    isDisable: boolean = false;
    isEnabled: boolean = false;
    pcsTotalCensus: any = null;
    pcsPreviousTotalCensus: any = null;
    pcsRating: any = null;
    pcsNewRating: any = null;
    pcsRangeName: any = null;
    patientClassificationRatingId: any = null;
    lastCensusUpdateStartDateTime: any = null;
    statDescription: any = null;
    censusLow: any = null;
    censusPeak: any = null;
    deptForecastCensusId: string = '00000000-0000-0000-0000-000000000000';
    defaultGuid: string = '00000000-0000-0000-0000-000000000000';
    isShowStaffingForecastLoadingDiv: boolean = false;
    noteTxt: any = null;

    isHightlightStaffTo: boolean = false;
    isHightlightActualCensus: boolean = false; 
    isHightlightForecastCensus: boolean = false;
    appInsightEventData!:any ;

    //Lifecycle hooks
    created(){
        this.emitter.on('updateNoteText', this.updateNoteText);
    }

    async mounted(){
        await this.loadStafToForcastData();
        this.updateAppInsights();
    }

    unmounted(){
        this.emitter.off('updateNoteText', this.updateNoteText);
    }

    async updateAppInsights(){
          var inputData =  {
                Username:  this.profileData.UserName,
                TenantName:  this.profileData.TenantName,
                FacilityName: this.facilityName,
                COID: this.facilityCoId,            
                DeptCode: this.departmentCode ,            
                DeptName: this.departmentName 
             };
             await this.$store
                .dispatch("profile/updateAppInsights", inputData)
                .then(() => {
                    
                    })
                .catch((err: any) => {
                    if (err) { 
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
    //Methods
    async loadStafToForcastData(){
        this.mapData();
        await this.getDeptMatrix();
        await this.getDeptForecastCensusId();
        await this.getDeptCensus();
        await this.getPatientClassificationRange();
        this.loadNoteModule();
        this.highlightCensusCell();
        this.emitter.emit("hideAddNoteBtn");
    }

    mapData(){
        var data: any = this.staffToForecastInput.DataSet;
        var startDateTime = moment(data.StartDateTime);
        var endDateTime = moment(data.EndDateTime);
        var lastCensusUpdateStartDateTime = moment.utc(data.LastCensusUpdateStartDateTime).toDate();

        this.assignId = this.getValue(data.AssignId);
        this.facilityId = this.getValue(data.FacilityId);
        this.facilityCoId = this.getValue(data.FacilityCoid);
        this.facilityName = this.getValue(data.FacilityName) + ' (' + this.getValue(data.FacilityCoid) + ')';
        this.clusterId = this.getValue(data.ClusterId);
        this.clusterName = this.getValue(data.ClusterName);
        this.serviceLineName = this.getValue(data.ClusterName);
        this.departmentId = this.getValue(data.DepartmentId);
        this.departmentName = this.getValue(data.DepartmentName) + ' (' + this.getValue(data.DepartmentCode) + ')' ;
        this.departmentCode = this.getValue(data.DepartmentCode);
        this.departmentSummaryId = this.getValue(data.DepartmentSummaryId);
        this.startDateTime = this.getValue(data.StartDateTime);
        this.endDateTime = this.getValue(data.EndDateTime);
        this.startDate = startDateTime.format(this.shortDateFormatPresentation);
        this.endDate = endDateTime.format(this.shortDateFormatPresentation);
        this.startTime = startDateTime.format(this.shortTimeFormat);
        this.endTime = endDateTime.format(this.shortTimeFormat);
        this.skillLevelId = this.getValue(data.SkillLevelId);
        this.skillLevelDesc = this.getValue(data.SkillLevelDesc);
        this.actualCensus = this.getValue(data.ActualCensus) || '-';
        this.forecastCensus = this.getValue(data.ForecastCensus);
        this.forecastCensusPreviousValue = this.getValue(data.ForecastCensus);
        this.staffTo = this.getValue(data.StaffTo) || '-';
        this.staffToPreviousValue = this.getValue(data.StaffTo) || '-';
        this.scheduled = this.getValue(data.Scheduled);
        this.target = this.getValue(data.Target);
        this.skillLevelDescription = this.getValue(data.SkillLevelDescription);
        this.skillLevelId = this.getValue(data.SkillLevelId);
        this.note = '';
        this.pcsTotalCensus = data.PCSTotalCensus != null && data.PCSTotalCensus != undefined ? Number(this.getValue(data.PCSTotalCensus)) : '-';
        this.pcsPreviousTotalCensus = data.PCSTotalCensus != null && data.PCSTotalCensus != undefined? Number(this.getValue(data.PCSTotalCensus)) : '-';            
        this.pcsRating = this.getValue(data.PCSRating) || '-';
        this.pcsNewRating = this.getValue(data.PCSRating) || '-';            
        this.pcsRangeName = this.getValue(data.PCSRangeName);
        this.pcsEnabled = this.getValue(data.PCSEnabled);
        this.isPCSImport = false;
        
        this.lastCensusUpdateStartDateTime = moment(lastCensusUpdateStartDateTime).local().format(this.longDateTimeFormatPresentation);
        this.patientClassificationRatingId = this.getValue(data.PatientClassificationRatingId);
        
        if (!data.PatientClassificationRatingId) {
            this.PatientClassificationRatingDetailsEmpty();
        }
        else {             
            this.PatientClassificationRatingDetails(data.PatientClassificationRatingId);
        }

        if (this.staffToForecastInput.AppMode === AppMode.ReadOnlyDetails) {
            this.isDisable = true;
            this.isEnabled = false;
        }
    }

    PatientClassificationRatingDetailsEmpty() {
        this.pcsLevels = [];

        for (var i = 1; i < 8; i++) {
            this.pcsLevels.push({
                FsPatientClassificationRatingDetailID: this.defaultGuid,
                FsPatientClassificationRatingID: this.defaultGuid,
                Level: i,
                Census: null
            });
        }
    }

    async PatientClassificationRatingDetails(PatientClassificationRatingId: any){
        var input: any = {
            patientClassificationRatingId: PatientClassificationRatingId
        };

        await this.$store
            .dispatch("dashboard/getPatientClassificationRatingDetails", input)
            .then(() => {
                if (this.patientClassificationRatingDetails != null) {
                    this.pcsLevels = this.getPatientClassificationRatingDetail(this.patientClassificationRatingDetails.fsPatientClassificationRatingDetails);
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while retrieving patient classification rating details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }
    async getDeptMatrix(){
         var deptMatrix: any = {
            tenantId: this.profileData.TenantId,
            departmentId: this.staffToForecastInput.DataSet.DepartmentId,
            deptSummaryId: this.staffToForecastInput.DataSet.DepartmentSummaryId,
            startDateTime: this.staffToForecastInput.DataSet.StartDateTime
        };

        await this.$store
            .dispatch("shared/getDeparmentMetrics", deptMatrix)
            .then(() => {
                this.emitter.emit('hideLoader');
                if (this.deparmentMetrics != null) {
                    var censusValue = this.forecastCensus;
                    if(this.staffTo != '-') {
                        censusValue = this.staffTo;
                    }
                    else if (this.actualCensus != '-') {
                        censusValue = this.actualCensus;
                    }

                    var filterData = this.deparmentMetrics.filter(
                    function (value: any) {
                        return (censusValue >= value.volumeLow && censusValue <= value.volumePeak);
                    });

                    if (filterData.length > 0) {
                        this.statDescription = this.getValue(filterData[0].statDescription);
                        this.censusLow = this.getValue(filterData[0].volumeLow);
                        this.censusPeak = this.getValue(filterData[0].volumePeak);
                    }
                    else {
                        filterData = this.deparmentMetrics.filter(
                            function (value: any) {
                                return (censusValue < value.volumeLow);
                            });
                        if (filterData.length > 0) {
                            this.statDescription =  this.getValue(filterData[0].statDescription);
                            this.censusLow =  this.getValue(filterData[0].volumeLow);
                            this.censusPeak = this.getValue(filterData[0].volumePeak);
                        } 
                        else {
                            filterData = this.deparmentMetrics.filter(
                                function (value: any) {
                                    return (censusValue > value.volumePeak);
                                });
                            if (filterData.length > 0) {
                                this.statDescription = this.getValue(filterData[filterData.length - 1].statDescription);
                                this.censusLow = this.getValue(filterData[filterData.length - 1].volumeLow);
                                this.censusPeak = this.getValue(filterData[filterData.length - 1].volumePeak);
                            }
                        }
                    }
                }
            })
            .catch((err: any) => {
                this.emitter.emit('hideLoader');
                if (err) {
                    var errorData = {
                        message: "Error encountered while retrieving department Metrics details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async getDeptForecastCensusId(){
        var deptMatrix: any = {
            departmentId: this.staffToForecastInput.DataSet.DepartmentId,
            startDateTime: this.staffToForecastInput.DataSet.StartDateTime,
            startDateTimeFormatted:  moment(this.staffToForecastInput.DataSet.StartDateTime).format(this.shortDateFormatYYYYMMDD),
            tenantId: this.profileData.TenantId,
        };

        await this.$store
            .dispatch("dashboard/getDeptForecastCensus", deptMatrix)
            .then(() => {
                if (this.deptForecastCensus != null) {
                    this.deptForecastCensusId = this.deptForecastCensus.fsDeptForecastCensusID;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while retrieving department forcast census details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async getDeptCensus(){
        var deptMatrix: any = {
            departmentId: this.staffToForecastInput.DataSet.DepartmentId,
            startDateTime: this.staffToForecastInput.DataSet.StartDateTime,
            tenantId: this.profileData.TenantId,
        };

        await this.$store
            .dispatch("dashboard/getDeptCensus", deptMatrix)
            .then(() => {
                if (this.deptCensus != null) {
                    //Do not let user change PCS values
                    if (this.deptCensus.ImportedOnUtc) {
                        this.isPCSImport = true; 
                    }
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while retrieving department census details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async getPatientClassificationRange(){
        var inputData = {
            departmentId: this.staffToForecastInput.DataSet.DepartmentId,
            startDateTime: this.staffToForecastInput.DataSet.StartDateTime,
            tenantId: this.profileData.TenantId,
        }
        await this.$store
            .dispatch("actionable/getPatientClassificationRange", inputData)
            .then(() => {
                if(this.patientClassification != null){
                    this.pcsRanges = this.patientClassification;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while retrieving PCS classification details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    loadNoteModule(){
        var model = {
            NoteTypeId: NoteType.COMMENTS,
            DepartmentId: this.staffToForecastInput.DataSet.DepartmentId,
            RelationId: NoteRelationType.FS_DEPTFORECASTCENSUS,
            TenantId: this.profileData.TenantId,
            CostCenterId: this.staffToForecastInput.DataSet.FacilityId,
            RelationIdValue: this.deptForecastCensusId,
            UserId: this.profileData.UserId,
            StartDateTime: this.staffToForecastInput.DataSet.StartDateTime,
            EndDateTime: this.staffToForecastInput.DataSet.EndDateTime,
        };

        this.emitter.emit('loadNotes', model);
    }

    sortAscByLevel(collection: any) {
        //@ts-ignore
        var result = jslinq(collection).distinct().orderBy(function (item: any) {
            return item.level;
        }).toList();

        return result;
    }

    getPatientClassificationRatingDetail(collection: any) {
        var items = [];

        for (var i = 0; i < collection.length; i++) {
            var obj = collection[i];
            var it = { FsPatientClassificationRatingDetailID: obj.fsPatientClassificationRatingDetailID, fsPatientClassificationRatingID: obj.fsPatientClassificationRatingID, Level: obj.level, Census: obj.census };
            items.push(it);
        }          

        return items;
    }

    getValue(obj: any) {
        if (typeof obj === undefined) {
            return null;
        }
        return obj;
    }

    onChangeStaffTo(){
        this.enableSaveButton();
    }

    validateStaffTo(){
        if (this.staffTo < 0) this.staffTo = 0;
        if (this.staffTo > 999) this.staffTo = 999;
    }

    enableSaveButton() {
        if (this.staffToForecastInput.AppMode === AppMode.ReadOnlyDetails) {
            this.isEnabled = false;
        } else {
            this.isEnabled = true;
        }
    }

    pcsOnChange(){
        this.calculateAveragePCSScore();
        this.calculateTotalCensus();
    }

    calculateAveragePCSScore() {
        var collection = JSON.parse(JSON.stringify(this.pcsLevels));
        var avgPCSScore = null;
        var scoreNumerator = 0;
        var scoreDenominator = null;
        var pcsRanges = this.pcsRanges;

        for (var i = 0; i < collection.length; i++) {
            var c = collection[i];
            if (c.Census != null) {
                // Using calculation from FS DailyRosterCensusEdit.aspx.cs -- WeightedAveragePCSCalculator Interface
                scoreNumerator += c.Census * c.Level;
                scoreDenominator += c.Census;
            }
        }

        if (scoreDenominator != null && scoreDenominator >= 0) {
            avgPCSScore = Number(scoreNumerator / (scoreDenominator || 1)).toFixed(2);
        }

        this.pcsNewRating = this.getValue(avgPCSScore) || '-';
        this.pcsRating = this.getValue(avgPCSScore) || '-';

        if (avgPCSScore) {
            for (var j = 0; j < pcsRanges.length; j++) {
                var p = pcsRanges[j];

                if (Number(avgPCSScore) >= p.rangeStart && Number(avgPCSScore) <= p.rangeEnd) {
                    this.pcsRangeName = p.name;
                }
            }
        }

        return avgPCSScore;
    }

    calculateTotalCensus() {
        var collection = JSON.parse(JSON.stringify(this.pcsLevels));
        var totalCensus: any = null;
        var isPCSImport = this.isPCSImport;

        for (var i = 0; i < collection.length; i++) {
            var c = collection[i];
            if (c.Census != null && c.Census != undefined) {
                totalCensus += Number(c.Census);
            }
        }       

        this.pcsTotalCensus = this.getValue(totalCensus) || '-';

        if (!isPCSImport) {
            this.actualCensus = this.getValue(totalCensus) || '-';
        }

        return totalCensus;
    }

    updateNoteText(note: any){
        this.noteTxt = note;
    }

    async btnSaveCensus_OnClick(){
        this.showLoader();
        var changed: boolean = false

        if (this.noteTxt != null) {
            changed = true;
            this.emitter.emit('createSendNote');
            this.hideLoader();
        }

        if (this.staffTo !== this.staffToPreviousValue) {
            changed = true;
            await this.updateStaffTo_OnSave();
        }

        if (this.forecastCensus !== this.forecastCensusPreviousValue) {
            changed = true;
            await this.updateForecastCensus_OnSave();
        }

        if (this.pcsEnabled) {

            if (!this.patientClassificationRatingId
                && this.pcsTotalCensus !== this.pcsPreviousTotalCensus) {
                changed = true;
                await this.createPatientClassificationRating_OnSave();
            }
            else if (!!this.patientClassificationRatingId
                && this.pcsTotalCensus !== this.pcsPreviousTotalCensus) {
                changed = true;
                await this.updatePatientClassificationRating_OnSave();
            }                
        }

        if (!changed) {
            this.hideLoader();
        }
    }
    
    async updateStaffTo_OnSave(){
        var note = 'Changed from ' + this.staffToPreviousValue + ' to ' + this.staffTo + '.';
            
        var modifiedStaffTo = [
            {
                DeptId: this.departmentId,
                StartTime: this.startDateTime,
                StaffTo: this.staffTo == '-'? null : this.staffTo,
                ActualCensus: this.actualCensus == '-'? null : this.actualCensus,
                ForecastCensus: this.forecastCensus,
                Note: note,
                AveragePatientClassificationRating: this.calculateAveragePCSScore(), // Insert calcuation9
                CensusId: 1, // Find cenusus Id
                TenantId : this.profileData.TenantId
            }
        ];

        await this.$store
            .dispatch("dashboard/updateStaffTo", modifiedStaffTo)
            .then(() => {
                var note = 'Staff To Census changed from ' + this.staffToPreviousValue + ' to ' + this.staffTo + '.';
                this.emitter.emit("updateNote", note);
                this.emitter.emit("createSendNote");

                this.staffToPreviousValue = this.getValue(this.staffTo) || '-';

                this.highlightCensusCell();
                this.updatelastCensusUpdateStartDateTime();

                var successData = {
                    message: "Staff To Census updated successfully.",
                    flag: "success"
                }
                this.emitter.emit('showToasterNotificationDashboardMain', successData);
                this.hideLoader();
                useAppInsights().trackEvent({
                        name: 'DASHBOARD_STAFF_TO_CENSUS_UPDATE', properties:
                            JSON.parse(JSON.stringify(this.appInsightEventData))
                    });
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while updating staff to details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
                this.hideLoader();
            });
    }

    async updateForecastCensus_OnSave(){
 
        var modifiedCensus = {
            FsDeptForecastCensusID: this.deptForecastCensusId,
            FsDeptID: this.departmentId,
            Date: this.startDate,
            StartTime: '1/1/1900 ' +  this.startTime,
            EndTime: '1/1/1900 ' +  this.endTime,
            Census: this.forecastCensus,
            TenantId : this.profileData.TenantId
        };

        await this.$store
            .dispatch("dashboard/updateForecastCensus", modifiedCensus)
            .then(() => {
                var note = 'Forecast Census changed from ' + this.forecastCensusPreviousValue + ' to ' + this.forecastCensus + '.';
                this.emitter.emit("updateNote", note);
                this.emitter.emit("createSendNote");

                this.forecastCensusPreviousValue = this.getValue(this.forecastCensus) || '-';

                this.highlightCensusCell();
                this.updatelastCensusUpdateStartDateTime();

                var successData = {
                    message: "Forecast census updated successfully.",
                    flag: "success"
                }
                this.emitter.emit('showToasterNotificationDashboardMain', successData);
                this.hideLoader();
                useAppInsights().trackEvent({
                        name: 'DASHBOARD_FORECAST_CENSUS_UPDATE', properties:
                            JSON.parse(JSON.stringify(this.appInsightEventData))
                    }); 

            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while updating forecast census details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
                this.hideLoader();
            });
    }

    async createPatientClassificationRating_OnSave(){
        var patientClassificationRating = {
            FsPatientClassificationRatingID: this.defaultGuid,
            FsDeptID: this.departmentId,
            StartDateAndTime: this.startDateTime,
            TotalCensus: this.calculateTotalCensus(),
            AverageRating: this.calculateAveragePCSScore() || 0.0,
            FsPatientClassificationRatingDetails: this.pcsLevels
        };

        await this.$store
            .dispatch("dashboard/createPatientClassificationRating", patientClassificationRating)
            .then(() => {
                if(this.createPatientClassificationRatingResult != null){
                    var result = this.createPatientClassificationRatingResult;
                    this.pcsTotalCensus = this.getValue(result.totalCensus) || '-';
                    this.pcsPreviousTotalCensus = this.getValue(result.totalCensus) || '-';
                    this.pcsRating = this.getValue(result.averageRating);
                    this.pcsLevels = this.getPatientClassificationRatingDetail(result.fsPatientClassificationRatingDetails);
                    this.patientClassificationRatingDetails = this.pcsLevels;
                    this.patientClassificationRatingId = result.fsPatientClassificationRatingID;

                    this.highlightCensusCell();
                    this.updatelastCensusUpdateStartDateTime();

                    var successData = {
                        message: "Patient Classification Rating created successful.",
                        flag: "success"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', successData);
                    this.hideLoader();
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while creating patient classification rating details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
                this.hideLoader();
            });
    }

    async updatePatientClassificationRating_OnSave(){
        var patientClassificationRating = {
            FsPatientClassificationRatingID: this.patientClassificationRatingId,
            FsDeptID: this.departmentId,
            StartDateAndTime: this.startDateTime,
            TotalCensus: this.calculateTotalCensus(),
            AverageRating: this.calculateAveragePCSScore() || 0.0,
            FsPatientClassificationRatingDetails: this.pcsLevels
        };

        await this.$store
            .dispatch("dashboard/updatePatientClassificationRating", patientClassificationRating)
            .then(() => {
                if(this.updatePatientClassificationRatingResult != null){
                    var result = this.updatePatientClassificationRatingResult;
                    this.pcsTotalCensus = this.getValue(result.totalCensus) || '-';
                    this.pcsPreviousTotalCensus = this.getValue(result.totalCensus) || '-';
                    this.pcsRating = this.getValue(result.averageRating);
                    this.pcsLevels = this.getPatientClassificationRatingDetail(result.fsPatientClassificationRatingDetails);
                    this.patientClassificationRatingDetails = this.pcsLevels;
                    this.patientClassificationRatingId = result.fsPatientClassificationRatingID;

                    this.highlightCensusCell();
                    this.updatelastCensusUpdateStartDateTime();

                    var successData = {
                        message: "Patient Classification Rating updated successful.",
                        flag: "success"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', successData);
                    this.hideLoader();
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "Error encountered while updating patient classification rating details.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationDashboardMain', errorData);
                    console.error(err); // Handle errors any way you want
                }
                this.hideLoader();
            });
    }

    highlightCensusCell(){
        this.isHightlightStaffTo = false;
        this.isHightlightActualCensus = false;
        this.isHightlightForecastCensus = false;

        if (this.staffTo >= 0) {
            this.isHightlightStaffTo = true;
            return;
        }

        if (this.actualCensus >= 0) {
            this.isHightlightActualCensus = true;
            return;
        }

        if (this.forecastCensus > 0) {
            this.isHightlightForecastCensus = true;
            return;
        }
    }

    updatelastCensusUpdateStartDateTime() {
        var lastCensusUpdateStartDateTime = moment.utc().toDate();
        this.lastCensusUpdateStartDateTime = moment(lastCensusUpdateStartDateTime).local().format(this.longDateTimeFormatPresentation);
    }

    close(){
        this.$emit("close-modal");
    }

    showLoader(){
        this.isShowStaffingForecastLoadingDiv = true;
    }

    hideLoader(){
        this.isShowStaffingForecastLoadingDiv = false;
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 85%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    padding: 5px 12px;
    height:85%;
    overflow: auto;
}
.panelTabData{
    height: 85%;
    overflow: auto;
    word-break: break-all;
    padding-bottom: 23px;
}

.pcsLevelTxt{
    padding-left: 10px !important;
    top: -14px;
}
</style>
