<template>
    <div id="other-content-loading" class="text-center mt-3" v-show="isShowLoader">
        <div class="fa-2x">
            <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
        </div>
        <p><b>Loading...</b></p>
    </div>
    <div id="other-content" v-if="isShowOtherContent">
        <div id="grdOther">
            <neu-card color="plain-0" class="p-0 m-0">
                <neu-table>
                    <neu-table-row :columns="columnHeaders" header>
                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                    <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column" style="padding-top: 25px !important;" @v-neu-change="sortData($event,column)">
                        <template v-if="(column != 'RN Experience' || (column == 'RN Experience' && isRNExperienceFeatureEnabled))">
                            <template v-if="column == 'DepartmentGroup' || column == 'SkillGroup'">
                                <span></span>
                            </template>
                            <template v-else>
                                {{ column }}
                                <font-awesome-icon  :icon="['fas', 'filter']" size="lg"
                                                :id="`filterIcon-${column}`" :column="column"
                                                @click="onFilterVisible($event, column)" 
                                                v-if="filterColumnList.includes(column)"
                                                style="margin-left: 5px;" />
                            </template>
                        </template>
                    </neu-table-heading>
                    </neu-table-row>
                    <neu-table-body align="left">
                        <template v-if="otherDepartmentList != null && otherDepartmentList.length > 0">
                            <template v-for="dept in otherDepartmentList" :key="dept">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row class="departmentGroupRow" :columns="columnHeaders" :slot="column">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="DepartmentGroup">
                                        <font-awesome-icon :icon="['fas', 'caret-right']" size="sm"
                                                           :class="[dept.isDeptExpand ? 'collapseIcon': '']"
                                                           @click="()=> dept.isDeptExpand = !dept.isDeptExpand"
                                                           style="padding-right: 5px;" />
                                        <b>{{dept.DepartmentName}}</b>
                                        <template v-for="skillVol in dept.SkillList" :key="skillVol">
                                            <template v-if="skillVol.StaffVolume < 0">
                                                &nbsp;
                                                <span class="status-under-staffed">{{skillVol.SkillName}} ({{skillVol.StaffVolume}})</span>
                                            </template>
                                            <template v-if="skillVol.StaffVolume > 0">
                                                &nbsp;
                                                <span class="status-over-staffed">{{skillVol.SkillName}} ({{skillVol.StaffVolume}})</span>
                                            </template>
                                        </template>
                                    </neu-label>
                                </neu-table-row>
                                <template v-for="skill in dept.SkillList" :key="skill">
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-table-row :columns="columnHeaders" :slot="column"
                                                   :class="[!dept.isDeptExpand ? 'd-none': '','skillGroupRow']">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="SkillGroup">
                                            <font-awesome-icon :icon="['fas', 'caret-right']" size="sm"
                                                               :class="[skill.isSkillExpand ? 'collapseIcon': '']"
                                                               @click="()=> skill.isSkillExpand = !skill.isSkillExpand"
                                                               style="padding-right: 5px;" />
                                            <b>{{skill.SkillName}}</b>
                                            <template v-if="skill.StaffVolume < 0">
                                                &nbsp;
                                                <span class="status-under-staffed">Under Staffed ({{skill.StaffVolume}})</span>
                                            </template>
                                            <template v-if="skill.StaffVolume > 0">
                                                &nbsp;
                                                <span class="status-over-staffed">Over Staffed ({{skill.StaffVolume}})</span>
                                            </template>
                                        </neu-label>
                                    </neu-table-row>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-table-row :columns="columnHeaders" v-for="dataItem in skill.otherGridData" :slot="column"
                                                   :key="dataItem" :uid="dataItem.uid" :staffId="dataItem.staffId"
                                                   :class="[dataItem.profileCharge && dataItem.charge? 'chargeAssignment': '',
                                                   !dept.isDeptExpand ? 'd-none' : '' ,
                                                   !skill.isSkillExpand ? 'd-none' : '' ,
                                                   (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                        0) || dataItem.isCancelled) ? 'cancelledAssignment': '',
                                        dataItem.origDepartmentId !== dataItem.departmentId && !(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'floatedAssignment' : '']"
                                        @click="onRowSelect(dataItem)">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Name" :title="dataItem.name">
                                            {{ dataItem.name }}
                                            <span v-if="dataItem.charge">
                                                <font-awesome-icon :icon="['fa', 'user-md']" size="sm" style="float: right; font-size: 16px;" />
                                            </span>
                                            <span v-if="dataItem.associationType === associationTypes.secondary">
                                                <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                            </span>
                                            <span v-if="dataItem.associationType === associationTypes.unassociated">
                                                <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                            </span>
                                            <span v-if="dataItem.isFloat">
                                                <img style="width: 24px;float: right" src="@/assets/float.png" alt="Float" />
                                            </span>
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Skill" :title="dataItem.skillLevelDescription"
                                                   :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            {{ dataItem.skillLevelDescription }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Comps" style="white-space:nowrap;" class="col-comps"
                                                   :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            {{ dataItem.competencyCodes }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Department" :title="dataItem.departmentName"
                                                   :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            <span v-if="dataItem.origDepartmentId !== dataItem.departmentId">
                                                {{ dataItem.departmentName }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentName}}  </span>
                                            </span>
                                            <span v-else> {{ dataItem.departmentName }}</span>
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Shift"
                                                   :class="[dataItem.overtimeShift ? 'gridOverTime': '',
                                                   (dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length>
                                            0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            {{ dataItem.shiftCode }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Start Time"
                                                   :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            {{ setGridTimeFormat(dataItem.actualStartTime) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="End Time"
                                                   :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            {{ setGridTimeFormat(dataItem.actualEndTime) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Type"
                                                   :class="[(dataItem.cancelReasonId || (dataItem.cancelComment && dataItem.cancelComment.length > 0) || dataItem.isCancelled) ? 'strikethrough': '']">
                                            {{ dataItem.employeeType }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Hours"
                                                   :class="[dataItem.isOvertime ? 'gridOverTime': '']">
                                            {{ dataItem.hours }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="RN Experience" v-if="dataItem.rnExperienceFeatureEnabled"
                                                   :class="[dataItem.rnExperience == '< 1 Year' ? 'rn-experience': '']">
                                            {{ dataItem.rnExperience }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Hire Date" :title="setGridDateFormat(dataItem.hireDate)">
                                            {{ setGridDateFormat(dataItem.hireDate) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Seniority Date" :title="setGridDateFormat(dataItem.seniorityDate)">
                                            {{ setGridDateFormat(dataItem.seniorityDate) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Last Cancelled" :title="setGridDateFormat(dataItem.lastCanceledDate)">
                                            {{ setGridDateFormat(dataItem.lastCanceledDate) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Last Floated" :title="setGridDateFormat(dataItem.lastFloatedDate)">
                                            {{ setGridDateFormat(dataItem.lastFloatedDate) }}
                                        </neu-label>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <neu-label slot="Notes">
                                            <span v-if="dataItem.hasNotes">
                                                <font-awesome-icon :icon="['fa', 'sticky-note']" size="sm" />
                                            </span>
                                        </neu-label>
                                    </neu-table-row>
                                </template>
                            </template>
                        </template>
                    </neu-table-body>
                </neu-table>
            </neu-card>
        </div>
        <div id="filterModelOtherDept" class="filterPanel d-none">
            <div class="p-2">
                <div class="pb-2">Filter by: </div>
                <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedNumberFilter" v-if="filterColumn == 'Hours'">
                    <option v-for="numFtr in numberFilter" :value="numFtr.Id" :key="numFtr.Id">{{numFtr.Value}}</option>
                </select>
                <select data-role="dropdownlist" class="neu-select mb-2 filterInput" v-model="selectedTextFilter" v-else>
                    <option v-for="txtFtr in textFilter" :value="txtFtr.Id" :key="txtFtr.Id">{{txtFtr.Value}}</option>
                </select>
                <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                <input title="Value" v-model="NumberfilterText" v-if="filterColumn == 'Hours'" class="neu-input mb-2 filterInput" type="number">
                <input title="Value" v-model="filterText" class="neu-input mb-2 filterInput" type="text" v-else>
                <div>
                    <button type="submit" title="Filter" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilter">Filter</button>
                    <button type="reset" title="Clear" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClear">Clear</button>
                    <button type="button" title="Close" class="neu-button--primary neu-button neu-button--raised filterBtn" @click="onGridFilterClose">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div id="other-message" class="text-center mt-3" v-if="isShowOtherMessage">
        <p id="other-message-text">{{otherMessage}}</p>
    </div>
    <div id="care-assignment-container-other"></div>
    <!-- Toaster -->
    <div v-if="isToasterVisible">
        <ToasterPopup @closeModal="closeToasterPopup"
                      :msgValue="msgValue"
                      :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isShowExportToExcel">
        <ExportToExcelForTabs @closeExcelTemplate="closeOtherTabExportToExcel" :payloadExportToExcel="payloadExportToExcel">
        </ExportToExcelForTabs>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//imports models and enums
import { CountType, ProductiveTypes, NeedStatus, NumberFilterType } from "@/enums/enum";
import { OtherDepartments, Skill } from "@/models/actionable/index";
import { AppMode} from "@/enums/enum";
import ExportToExcelForTabs from '../header/ExportToExcelForTabs.vue';

//Import Componenets
import ToasterPopup from "../../shared/ToasterPopup.vue";
import {
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard
} from "@neutron/vue";

class Props {
    otherTabInput!: any 
}

@Options({
  computed: {
    ...mapState('assignments', ['assignmentOtherDept']),
  },
  components: {
    ToasterPopup,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
      NeuCard,
      ExportToExcelForTabs
  }
}) 
export default class OtherDepartment extends Vue.with(Props) {
    //Data properties
    assignmentOtherDept!: any;
    isShowLoader: boolean = false;
    isShowOtherContent: boolean = false;
    isShowOtherMessage: boolean = false;
    isFilterDataFromHeader: boolean = false;
    isRNExperienceFeatureEnabled: boolean = false;
    otherMessage: string = "";
    otherGridData: any = null;
    otherDepartmentList: OtherDepartments[] = [];
    filterColumn: string = "";
    filterText: string = "";
    NumberfilterText: string = "";
    filterTextList: any[] = [];
    filterColumnList: any[] = ["Name", "Skill", "Comps", "Department", "Shift", "Type", "Hours"];
    textFilter: any[] = [{
        Id: 1,
        Value: "Contains"
    }];
    numberFilter: any[] = [
        {
            Id: 2,
            Value: "Is equal to"
        },
        {
            Id: 3,
            Value: "Greater than or equal to"
        },
        {
            Id: 4,
            Value: "Less than or equal to"
        }
    ];
    selectedNumberFilter: any = 2;
    selectedTextFilter: any = 1;
    filteredData: any = null;
    associationTypes: any = {
        primary: 1,
        secondary: 2,
        unassociated: 3
    };
    columnHeaders: any ={ 
        DepartmentGroup: "1%",
        SkillGroup: "1%",
        Name: "8%", 
        Skill: "6%", 
        Comps: "7%", 
        Department: "8%",
        Shift: "5%", 
        "Start Time": "7%", 
        "End Time": "7%", 
        Type: "5%", 
        Hours: "5%", 
        "RN Experience": "6%",
        "Hire Date": "7%",
        "Seniority Date": "7%",
        "Last Cancelled": "7%",
        "Last Floated": "7%",
        "Notes": "4%"
    };
    columnIcons: any = { 
        DepartmentGroup: "none",
        SkillGroup: "none",   
        Name: "desc", 
        Skill: "desc",
        Comps: "desc",
        Department: "desc",
        Shift: "desc",
        "Start Time": "desc",
        "End Time": "desc",
        Type: "desc", 
        Hours: "desc",
        "RN Experience": "desc",
        "Hire Date": "desc",
        "Seniority Date": "desc",
        "Last Cancelled": "desc",
        "Last Floated": "desc",
        "Notes": "none"
    };

    columnForSort: any = { 
        "Name": "name", 
        "Skill": "skillLevelDescription",
        "Comps": "competencyCodes",
        "Department": "departmentName",
        "Shift": "shiftCode",
        "Start Time": "actualStartTime",
        "End Time": "actualEndTime",
        "Type": "employeeType", 
        "Hours": "hours",
        "RN Experience": "rnExperience",
        "Hire Date": "hireDate",
        "Seniority Date": "seniorityDate",
        "Last Cancelled": "lastCanceledDate",
        "Last Floated": "lastFloatedDate"
	};
    
    msgValue!: string;
    classFlag!: string;
    isToasterVisible: boolean = false;
    isShowExportToExcel: boolean = false;
    payloadExportToExcel!: any;

    //Lifecycle hooks
    async mounted(){
        await this.loadOtherGridData();
        this.closeFilterBox();
    }

    created(){
        this.emitter.on("filterOtherGridDataBySkill", this.filterOtherGridDataBySkill);
        this.emitter.on("clearOtherGridDataBySkill", this.clearOtherGridDataBySkill);
        this.emitter.on("refreshOtherDepartmentTab", this.loadOtherGridData);
        this.emitter.on("otherTabExportToExcel", this.otherTabExportToExcel);
    }

    unmounted(){
        this.emitter.off("filterOtherGridDataBySkill", this.filterOtherGridDataBySkill);
        this.emitter.off("clearOtherGridDataBySkill", this.clearOtherGridDataBySkill);
        this.emitter.off("refreshOtherDepartmentTab", this.loadOtherGridData);
        this.emitter.off("otherTabExportToExcel", this.otherTabExportToExcel);
    }
    //Methods
    async loadOtherGridData(){
        if(this.otherTabInput != null){
            this.showLoader();
            var inputData = {
                tenantId: this.otherTabInput.TenantId,
                dashboardId: this.otherTabInput.DataSet.DashboardId,
                facilityId: this.otherTabInput.DataSet.FacilityId,
                clusterId: this.otherTabInput.DataSet.ClusterId,
                startTime: this.otherTabInput.DataSet.StartDateTime,
                endTime: this.otherTabInput.DataSet.EndDateTime
            }
            await this.$store
                .dispatch("assignments/getAssignmentsByOtherGrid", inputData)
                .then(() => {
                    if(this.assignmentOtherDept != null && this.assignmentOtherDept.length > 0){
                        var filterOtherDeptData = this.assignmentOtherDept.filter((item: any) => {
                            return item.departmentId != this.otherTabInput.DataSet.DepartmentId;
                        });

                        this.mapHomeOtherAssignments(filterOtherDeptData);

                        this.otherGridData = this.filterOtherAssignments(filterOtherDeptData);

                        if(this.otherGridData != null && this.otherGridData.length > 0){
                            this.isRNExperienceFeatureEnabled = this.otherGridData[0].rnExperienceFeatureEnabled
                        }
                        this.generateGridData();
                        this.isShowOtherMessage = false;
                        this.isShowOtherContent = true;
                        var that = this;
                        setTimeout(() =>{
                            that.setGroupingColumnStyles();
                        }, 2000);
                    }
                    else{
                        this.otherMessage = "No resources found";
                        this.otherDepartmentList = [];
                        this.isShowOtherMessage = true;
                        this.isShowOtherContent = false;
                    }
                    this.hideLoader();
                })
                .catch((err: any) => {
                    if (err) {
                        this.hideLoader();
                        this.msgValue = "Error encountered while retrieving other department details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
    }
    generateGridData(){
        if(this.otherGridData != null && this.otherGridData.length > 0){
            this.otherDepartmentList = [];
            //@ts-ignore
            var distinctDepartmentIds: any = jslinq(this.otherGridData)
                    .orderBy((el: any) => {
                        return el.departmentName;
                    })
                    .select((x: any)=> x.departmentId)
                    .distinct()
                    .toList();

            if(distinctDepartmentIds != null && distinctDepartmentIds.length > 0){
                distinctDepartmentIds.forEach((item: any)=>{
                    var otherDeptItem: OtherDepartments = { DepartmentId: null, DepartmentName: null, isDeptExpand: null, SkillList: [] };
                    //@ts-ignore
                    var departmentList: any = jslinq(this.otherGridData)
                    .where((data: any) => { 
                        if(data.departmentId == item){
                            return data;
                        }})
                    .toList();

                    if(departmentList != null && departmentList.length > 0){
                        otherDeptItem.DepartmentId = departmentList[0].departmentId;
                        otherDeptItem.DepartmentName = departmentList[0].departmentName;
                        otherDeptItem.isDeptExpand = true;

                        //@ts-ignore
                        var skillList: any = jslinq(departmentList)
                                    .orderBy((el: any) => {
                                        return el.skillLevelDescription;
                                    })
                                    .select((x: any) => x.skillLevelId)
                                    .distinct()
                                    .toList();

                        if(skillList != null && skillList.length > 0){
                            skillList.forEach((slItem: any)=>{
                                //@ts-ignore
                                var skillDeptList: any = jslinq(departmentList)
                                        .where((data: any) => { 
                                            if(data.skillLevelId == slItem){
                                                return data;
                                            }})
                                        .toList();

                                if(skillDeptList != null && skillDeptList.length > 0){
                                    var skillItem: Skill = { SkillName: null, StaffVolume: 0, isSkillExpand: null, otherGridData: null };

                                    skillItem.SkillName = skillDeptList[0].skillLevelDescription;
                                    skillItem.StaffVolume = skillDeptList[0].staffVolume;
                                    if(skillItem.StaffVolume < 0){
                                       skillItem.isSkillExpand = false; 
                                    }
                                    else{
                                        skillItem.isSkillExpand = true;
                                    }
                                    skillItem.otherGridData = skillDeptList;

                                    otherDeptItem.SkillList.push(skillItem);
                                }
                            });
                        }
                    }
                    this.otherDepartmentList.push(otherDeptItem);
                });
            }
        }
        else{
            this.otherDepartmentList = [];
        }
    }
    setGroupingColumnStyles(){
        if(this.otherGridData != null && this.otherGridData.length > 0){
            var deptColLength: any = document.getElementsByClassName('departmentGroupRow').length;
            var skillColLength: any = document.getElementsByClassName('skillGroupRow').length;
            
            if(deptColLength > 0){
                    for(let i=0; i<deptColLength; i++){
                        //@ts-ignore
                        document.getElementsByClassName('departmentGroupRow')[i].children[0].style.width = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('departmentGroupRow')[i].children[0].style.minWidth = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('departmentGroupRow')[i].children[0].style.maxWidth = '100%';
                    }
            }

            if(skillColLength > 0){
                    for(let i=0; i<skillColLength; i++){
                        //@ts-ignore
                        document.getElementsByClassName('skillGroupRow')[i].children[1].style.width = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('skillGroupRow')[i].children[1].style.minWidth = '100%';
                        //@ts-ignore
                        document.getElementsByClassName('skillGroupRow')[i].children[1].style.maxWidth = '100%';
                    }
            }
        }
    }
    filterOtherAssignments(assignments: any) {
        return assignments.filter(function (item: any) {
            var countType = item.countTypeId;
            var productive = item.productiveId;
            var needStatus = item.needStatusId;
            return !(item.cancelReasonId || (item.cancelComment && item.cancelComment.length > 0) || item.isCancelled)
                    && ((countType === CountType.COUNT || countType === CountType.DONT_COUNT_BUT_SHOW && productive !== ProductiveTypes.NON_PRODUCTIVE) || (!item.ShiftCode && (productive === ProductiveTypes.PRODUCTIVE || productive === ProductiveTypes.OTHER_PRODUCTIVE)))
                    && (needStatus === NeedStatus.CONFIRMED || needStatus === NeedStatus.CANCELED);
        });
    }
    mapHomeOtherAssignments(assignments: any) {
        var info: any = this.otherTabInput.DataSet;

        assignments.forEach((value: any) => {
            value.name = value.lastName + ', ' + value.firstName;
            value.shiftCode = value.shiftCode ? value.shiftCode.trim() : '???';
            value.staffVolume = this.deptStaffVolume(value.departmentId, value.departmentSummaryId, value.skillLevelId);
            value.allSkills = value.skillLevelDescription + (value.secondarySkills ? ', ' + value.secondarySkills : '');
            value.facilityId = info.FacilityId;
            value.facilityName = info.FacilityName;
            value.clusterId = info.ClusterId;
            value.clusterName = info.ClusterName;

            if (value.competencies && value.competencies.length > 0) {
                value.competencyCodes = value.competencies.map(function (competency: any) {
                    return competency.code;
                }).join(", ");
            }
        });              
    }
    deptStaffVolume(deptId: any, summaryId: any, skillId: any) {
        var deptStats: any = this.otherTabInput.DeptStats;
        for (var i = 0; i < deptStats.length; i++) {
            var d = deptStats[i];
            if (d.DepartmentId === deptId && d.DeptSummaryId === summaryId && d.SkillLevelId === skillId) {
                return Math.round((d.Scheduled - d.Target) * 100) / 100;
            }
        }
        return 0;
    }
    setGridDateFormat(date: any){
        if(date != null && date != ''){
            var formattedDate: any = moment(date).format("MM/DD/YYYY");
            return formattedDate;
        }
        else{
            return '';
        }
    }
    setGridTimeFormat(time: any){
        if(time != null && time != ''){
            var formattedTime: any = moment(time).format("hh:mm A");
            return formattedTime;
        }
        else{
            return '';
        }
    }
    showLoader(){
        this.isShowLoader = true;
        this.isShowOtherContent = false;
        this.isShowOtherMessage = false;
    }

    hideLoader(){
        this.isShowLoader = false;
    }

    closeToasterPopup() {
      this.isToasterVisible = false;
    }
    onRowSelect(dataItem: any){
        if(this.otherTabInput.AppMode === AppMode.FullAccess){
            var currentDataItem = dataItem;
            var info = {
                FacilityId: currentDataItem.facilityId,
                FacilityName: currentDataItem.facilityName,
                ClusterId: currentDataItem.clusterId,
                ClusterName: currentDataItem.clusterName,
                DepartmentId: currentDataItem.departmentId,
                DepartmentName: currentDataItem.departmentName
            };

            currentDataItem.actionableDepartment = info;
            currentDataItem.config = this.otherTabInput.Config;

            this.emitter.emit('loadAssignment', currentDataItem);
        }
    }
    onFilterVisible(event: any, columnName: any){
        event.stopPropagation();
        var filterPanel: any = document.getElementById('filterModelOtherDept');
        filterPanel.style.left = event.clientX + 'px';
        if(this.otherGridData != null && this.otherGridData.length > 4){
            filterPanel.style.top = event.clientY - 58 + 'px';
        }
        else{
            filterPanel.style.top = event.clientY - 225 + 'px';
        }
            
        this.filterColumn = columnName;
        this.filterText = "";
        this.setFilterText();

        filterPanel.classList.remove('d-none');
    }
    onGridFilter(){
        var filterPanel: any = document.getElementById('filterModelOtherDept');
        if((this.filterColumn.toLowerCase() != 'Hours'.toLowerCase() && this.filterText != null && this.filterText != '') ||
            (this.filterColumn.toLowerCase() == 'Hours'.toLowerCase() && this.NumberfilterText != null && this.NumberfilterText != '')) {
                if(this.filteredData == null){
                    this.filteredData = JSON.parse(JSON.stringify(this.otherGridData));
                }
                if(this.filterTextList != null && this.filterTextList.length > 0)
                {
                    var that= this;
                    //@ts-ignore
                    var isFilterExists= jslinq(this.filterTextList)
                                        .where(function (data: any) { 
                                            if(data.filterColumn == that.filterColumn){
                                                return data;
                                            }})
                                        .toList();
                    
                    if(isFilterExists != null && isFilterExists.length > 0){
                        this.filterTextList.forEach((data)=>{
                            if(data.filterColumn == this.filterColumn){
                                if(this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()){
                                    data.filterText = this.NumberfilterText;
                                    data.filterSelectedValue = this.selectedNumberFilter;
                                }
                                else{
                                    data.filterText = this.filterText;
                                    data.filterSelectedValue = this.selectedTextFilter;
                                }
                            }
                        })
                    }
                    else{
                        this.AddFilterListItem();
                    }
                }
                else{
                    this.AddFilterListItem();
                }
                this.updateGridDataBySelectedFilter();
                var selectedFilterIcon:any = document.getElementById("filterIcon-"+this.filterColumn);
                selectedFilterIcon.classList.add('filterSelectedIcon');
                filterPanel.classList.add('d-none');
        }
        else {
            this.msgValue = "Please enter text to filter";
            this.classFlag = "error";
            this.isToasterVisible = true;
        }
    }
    onGridFilterClear(){
        if(this.filterTextList != null && this.filterTextList.length > 0){
            for( var i = 0; i < this.filterTextList.length; i++){ 
                if ( this.filterTextList[i].filterColumn == this.filterColumn) { 
                    this.filterTextList.splice(i, 1);
                    var selectedFilterIcon:any = document.getElementById("filterIcon-"+this.filterColumn);
                    selectedFilterIcon.classList.remove('filterSelectedIcon'); 
                }
            }
            if(this.filterTextList != null && this.filterTextList.length > 0){
                var lastItemIndex = this.filterTextList.length - 1;
                this.filterColumn = this.filterTextList[lastItemIndex].filterColumn;
                if(this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()){
                    this.NumberfilterText = this.filterTextList[lastItemIndex].filterText;
                    this.selectedNumberFilter = this.filterTextList[lastItemIndex].filterSelectedValue;
                }
                else{
                    this.filterText = this.filterTextList[lastItemIndex].filterText;
                    this.selectedTextFilter = this.filterTextList[lastItemIndex].filterSelectedValue;
                }
                this.otherGridData = this.filterTextList[lastItemIndex].filterData;
            }
            else{
                this.otherGridData = JSON.parse(JSON.stringify(this.filteredData));
                this.NumberfilterText = "";
                this.filterText = "";
                this.selectedTextFilter = 1;
                this.selectedNumberFilter = 2;
                this.isFilterDataFromHeader = false;
            }
            this.generateGridData();
            var that = this;
            setTimeout(() =>{
                that.setGroupingColumnStyles();
            }, 2000);
        }
        var filterPanel: any = document.getElementById('filterModelOtherDept');        
        filterPanel.classList.add('d-none');
    }
    AddFilterListItem(){
        var fText;
        var fSelectedValue;
        if(this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()){
            fText = this.NumberfilterText;
            fSelectedValue = this.selectedNumberFilter;
        }
        else{
            fText = this.filterText;
            fSelectedValue = this.selectedTextFilter;
        }
        var data: any ={
                    filterColumn: this.filterColumn,
                    filterText: fText,
                    filterSelectedValue: fSelectedValue,
                    filterData: null
                }
        this.filterTextList.push(data);
    }
    setFilterText(){
        if(this.filterTextList != null && this.filterTextList.length > 0)
        {
            var that= this;
            //@ts-ignore
            var isFilterExists= jslinq(this.filterTextList)
                                .where(function (data: any) { 
                                    if(data.filterColumn == that.filterColumn){
                                        return data;
                                    }})
                                .toList();
            
            if(isFilterExists != null && isFilterExists.length > 0){
                this.filterTextList.forEach((data)=>{
                    if(data.filterColumn == this.filterColumn){
                        if(this.filterColumn.toLowerCase() == 'Hours'.toLowerCase()){
                            this.NumberfilterText = data.filterText;
                            this.selectedNumberFilter = data.filterSelectedValue;
                        }
                        else{
                            this.filterText = data.filterText;
                            this.selectedTextFilter = data.filterSelectedValue;
                        }
                    }
                })
            }
        }
    }
    updateGridDataBySelectedFilter(){
        if (this.filterColumn.toLowerCase() == "Name".toLowerCase()){
            var data = this.otherGridData.filter((dt: any) => dt.name.toLowerCase().includes(this.filterText.toLowerCase()));
            this.otherGridData = data;
        }
        if (this.filterColumn.toLowerCase() == "Skill".toLowerCase()){
            var data = this.otherGridData.filter((dt: any) => dt.skillLevelDescription.toLowerCase().includes(this.filterText.toLowerCase()));
            this.otherGridData = data;
        }
        if (this.filterColumn.toLowerCase() == "Comps".toLowerCase()){
            var data = this.otherGridData.filter((dt: any) => dt.competencyCodes && dt.competencyCodes.toLowerCase().includes(this.filterText.toLowerCase()));
            this.otherGridData = data;
        }
        if (this.filterColumn.toLowerCase() == "Shift".toLowerCase()){
            var data = this.otherGridData.filter((dt: any) => dt.shiftCode.toLowerCase().includes(this.filterText.toLowerCase()));
            this.otherGridData = data;
        }
        if (this.filterColumn.toLowerCase() == "Department".toLowerCase()){
            var data = this.otherGridData.filter((dt: any) => dt.departmentName.toLowerCase().includes(this.filterText.toLowerCase()));
            this.otherGridData = data;
        }
        if (this.filterColumn.toLowerCase() == "Type".toLowerCase()){
            var data = this.otherGridData.filter((dt: any) => dt.employeeType.toLowerCase().includes(this.filterText.toLowerCase()));
            this.otherGridData = data;
        }
        if (this.filterColumn.toLowerCase() == "Hours".toLowerCase()){
            var data;
            if(this.selectedNumberFilter ==  NumberFilterType.IS_EQUAL_TO){
               var data = this.otherGridData.filter((dt: any) => dt.hours == this.NumberfilterText);
            }
            else if(this.selectedNumberFilter ==  NumberFilterType.GREATER_THAN_OR_EQUAL_TO){
                var data = this.otherGridData.filter((dt: any) => dt.hours >= this.NumberfilterText);
            }
            else if(this.selectedNumberFilter ==  NumberFilterType.LESS_THAN_OR_EQUAL_TO){
                var data = this.otherGridData.filter((dt: any) => dt.hours <= this.NumberfilterText);
            }
            this.otherGridData = data;
        }

        this.generateGridData();
        var that = this;
        setTimeout(() =>{
            that.setGroupingColumnStyles();
        }, 2000);

        this.filterTextList.forEach((data)=>{
            if(data.filterColumn == this.filterColumn){
                data.filterData = JSON.parse(JSON.stringify(this.otherGridData));
            }
        });
    }

    onGridFilterClose(){
        var filterPanel: any = document.getElementById('filterModelOtherDept');        
        filterPanel.classList.add('d-none');
    }

    filterOtherGridDataBySkill(skillDesc: any){
        if(this.isFilterDataFromHeader){
            this.otherGridData = JSON.parse(JSON.stringify(this.filteredData));
        }
        else{
            this.filteredData = null;
            this.filteredData = JSON.parse(JSON.stringify(this.otherGridData));
            this.isFilterDataFromHeader = true;
        }

        if(this.filterTextList != null && this.filterTextList.length > 0){
            this.filterTextList.forEach((data)=>{
                var selectedFilterIcon:any = document.getElementById("filterIcon-"+data.filterColumn);
                if(selectedFilterIcon != null){
                    selectedFilterIcon.classList.remove('filterSelectedIcon');
                }
            });
            this.filterTextList = [];
        }

        this.filterText = skillDesc;
        this.filterColumn = "Skill";

        this.AddFilterListItem();
        this.updateGridDataBySelectedFilter();

        var selectedFilterIcon:any = document.getElementById("filterIcon-Skill");
        if(selectedFilterIcon != null){
            selectedFilterIcon.classList.add('filterSelectedIcon');
        }
        else{
            setTimeout(() =>{
                var selectedFilterIcon:any = document.getElementById("filterIcon-Skill");
                selectedFilterIcon.classList.add('filterSelectedIcon');
            }, 1000);
        }
    }

    clearOtherGridDataBySkill(){
        if(this.isFilterDataFromHeader){
            this.otherGridData = JSON.parse(JSON.stringify(this.filteredData));

            this.generateGridData();
            var that = this;
            setTimeout(() =>{
                that.setGroupingColumnStyles();
            }, 2000);

            if(this.filterTextList != null && this.filterTextList.length > 0){
                this.filterTextList.forEach((data)=>{
                    var selectedFilterIcon:any = document.getElementById("filterIcon-"+data.filterColumn);
                    selectedFilterIcon.classList.remove('filterSelectedIcon');
                });
                this.filterTextList = [];
            }

            this.filterText = "";
            this.NumberfilterText = "";
            this.filterColumn = "";
            this.filteredData = null;
            this.isFilterDataFromHeader = false;
        }
    }

    closeFilterBox(){
        document.addEventListener('click', function handleClickOutsideFilterBox(event) {
            const box: any = document.getElementById('filterModelOtherDept');

            if(box != null){
                if (!box.contains(event.target)) {
                    box.classList.add('d-none');
                }
            }
        });

        var actionableDiv: any = document.getElementById("actionable");
        actionableDiv.addEventListener('scroll', function hideFilterBox() {
            const box: any = document.getElementById('filterModelOtherDept');
            if(box != null){
                box.classList.add('d-none');
            }
        });

        var otherDataDiv: any = document.getElementById("other");
        otherDataDiv.addEventListener('scroll', function hideFilterBoxDiv() {
            const box: any = document.getElementById('filterModelOtherDept');
            if(box != null){
                box.classList.add('d-none');
            }
        });
    }

    sortData(ev: any, columnName: any){
        var column = this.columnForSort[columnName];
        
        if(this.otherDepartmentList != null && this.otherDepartmentList.length > 0){
            this.otherDepartmentList.forEach((dept: any) =>{
                if(dept.SkillList != null && dept.SkillList.length > 0){
                    dept.SkillList.forEach((skill: any) =>{
                        if(skill.otherGridData != null && skill.otherGridData.length > 1){
                            skill.otherGridData.sort((col1: any,col2: any) => {
                                let modifier = 1;
                                if (ev.detail.direction.toLowerCase() === 'desc') modifier = -1;

                                if (col1[column] < col2[column]) return -1 * modifier; 
                                if (col1[column] > col2[column]) return 1 * modifier;
                            });
                        }
                    });
                }
            })
        }
        // this.generateGridData();
        //     var that = this;
        //     setTimeout(() =>{
        //         that.setGroupingColumnStyles();
        //     }, 2000);
    }

    otherTabExportToExcel(headerInfo: any) {
        this.payloadExportToExcel = {
            headerInfo: headerInfo,
            skillLevelDescription: this.otherTabInput.DataSet.SkillLevelDescription,
            dataSource: this.otherDepartmentList,
            departmentId: this.otherTabInput.DataSet.DepartmentId,
            tabName: 'other'
        };
        this.isShowExportToExcel = true;
    }

    closeOtherTabExportToExcel() {
        this.isShowExportToExcel = false;
    }
}   
</script>
<style scoped>
.filterPanel{
    width: 200.797px;
    min-height: 162px;
    overflow: visible;
    top: 553.703px;
    z-index: 10013;
    left: 254.562px;
    box-sizing: content-box;
    display: block;
    position: absolute;
    text-align: left;
    border: 1px solid #939393;
    background-color: white;
    :deep(.neu-button) {
            background: var(--neu-color-primary);
        }
}

.filterSelectedIcon{
    background-color: #164D87;
    color: whitesmoke;
    padding: 2px;
}

.filterInput{
    width: 100% !important;
    min-height: 2rem !important;
}

.filterBtn{
    min-width: 30px !important;
    height: 30px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.filterBtn:hover{
    color: #fff !important;
}

.filterBtn:hover::after{
    background: none !important;
}

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.82rem;
    right: 0.938rem;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.collapseIcon{
    transform: rotate(45deg) !important;
}
</style>