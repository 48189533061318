<template>
    <template v-if="isLoadHomePage">
        <template v-if="profileData.IsSurgeAlertFeatureEnabled">
            <Surge />
        </template>
        <template v-if="isShowAggregatedSkillDashboard">
            <FilterBar :dashboardConfig="dashboardConfig" :filterPreferences="filterPreferences"/>
            <DashboardMain :dashboardConfig="dashboardConfig" :filterPreferences="filterPreferences"/>
        </template>
        <template v-if="isShow7DayStaffingView">
            <DashboardSevenDay  />
        </template>        
    </template>
   <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isShowWorkforceRequestPopup">
        <WorkforceRequest :workforceRequestInput="workforceRequestInput"
                        @close-modal="closeWorkforceRequesPopup"></WorkforceRequest>
    </div>
    <div v-if="isShowContractLaborManagementPopup">
        <ContractLaborManagement :contractLaborInput="contractLaborManagementInput"
            @close-modal="closeContractLaborPopup">
        </ContractLaborManagement>
    </div>
    <div v-if="isShowMyPreference">
        <UserPreferences @close-modal="closeUserPreferencesPopup" />
    </div>
    <div v-if="isShowManageCensusPopup">
        <CensusModal :manageCensusInput="manageCensusInput"
            @close-modal="closeManageCensusPopup">
        </CensusModal>
    </div>
    <div v-if="isShowDailyRosterFilterReport">
        <DailyRoster :dailyRosterInput="dailyRosterInput"
            @close-modal="closeDailyRosterFilterReportPopup">
        </DailyRoster>
    </div>
    <div v-if="isSMSNotification">
                    <SMSNotifications @close-modal="closeSMSNotificationsPopup" />
                    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import { mapState } from "vuex";
import moment from 'moment';
import router from '@/router';
import { NotificationActionTypes } from "@/enums/enum";

//import model and enum
import { Profile } from '@/models/profile/index';

//import components
import DashboardMain from "./DashboardMain.vue";
import FilterBar from "./FilterBar.vue";
import ToasterPopup from '../shared/ToasterPopup.vue';
import WorkforceRequest from '../lifecyclereporting/WorkforceRequest.vue';
import ContractLaborManagement from '../contractLaborManagementModal/ContractLaborManagement.vue';
import UserPreferences from '../userpreferencesmodal/UserPreference.vue';
import DashboardSevenDay from '../dashboardSevenDay/DashboardSevenDay.vue';
import CensusModal from '../manageCensus/CensusModal.vue';
import DailyRoster from '../DailyRoster/DailyRoster.vue';
import Surge from '../surge/Surge.vue';
import ScheduleView from './ScheduleView.vue'
import SMSNotifications from '../smsnotifications/SMSNotifications.vue'

@Options({
  computed: {
    ...mapState('dashboard', ['dashboardConfiguration']),
    ...mapState('userstaffpreferences', ['filterPreferences']),
    ...mapState('profile', ['profileData', 'userAccounts']),
    ...mapState('shared', ['departmentsByUser']),
    ...mapState('assignments', ['notificationData']),
  },
  components: {
    DashboardMain,
    FilterBar,
    ToasterPopup,
    WorkforceRequest,
    ContractLaborManagement,
    UserPreferences,
    DashboardSevenDay,
    CensusModal,
    DailyRoster,
    Surge,
    ScheduleView,
    SMSNotifications
  },
  watch: {
    $route(to, from) {
        if (to.path === from.path) {
            return;
        }
        if (to.path.startsWith('/dashboard') || to.path.startsWith('/staff')) {
            this.isShowAggregatedSkillDashboard= false
            this.isShow7DayStaffingView = false
            this.loadProfileData();
        }
    }
  },
})

export default class Home extends Vue {
    departmentsByUser!: any;
    notificationData!: any;
    //Data properties
    dashboardConfiguration!: any;
    filterPreferences!: any;
    profileData!: Profile;
    dashboardConfig: any = null; 
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    isLoadHomePage: boolean = false;
    isShowMyPreference: boolean = false;
    longDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    filterPreferenceDetails: any = null;
    isShowWorkforceRequestPopup: boolean = false;
    workforceRequestInput: any;
    isShowContractLaborManagementPopup: boolean = false;
    contractLaborManagementInput: any;
    isShowAggregatedSkillDashboard: boolean = false;
    isShow7DayStaffingView: boolean = false;
    isShowManageCensusPopup: boolean = false;
    manageCensusInput: any;
    userAccounts!: any;
    isShowDailyRosterFilterReport: boolean = false;
    isSMSNotification: boolean = false;
    isShowNotifictionBadge: any = false;
    notificationCount: any = 0;
    shortDateTimeFormat: any = 'YYYY-MM-DDTHH:mm:ss';
    dailyRosterInput: any;
    colors: any = {
        red: 'red',
        green: 'green',
        yellow: 'yellow',
        black: 'black',
        white: 'white'
    };

    //Lifecycle hooks
    created(){
        this.emitter.on("loadUserPreferencesPopup", this.loadUserPreferencesPopup);
        this.emitter.on('loadWorkforceRequestStatusPopup', this.loadWorkforceRequestStatusPopup);
        this.emitter.on('loadWorkforceRequestStatusPopupWithSelectedFacAndDept', this.loadWorkforceRequestStatusPopupWithSelectedFacAndDept);
        this.emitter.on('loadContractLaborPopup', this.loadContractLaborPopup);
        this.emitter.on('loadAggregatedSkillDashboard', this.loadAggregatedSkillDashboard);
        this.emitter.on('load7DayStaffingView', this.load7DayStaffingView);
        this.emitter.on('showToasterPopupByHome', this.showToasterPopupByHome);
        this.emitter.on('loadManageCensusPopup', this.loadManageCensusPopup);
        this.emitter.on('loadDailyRosterFilterReportPopup', this.loadDailyRosterFilterReportPopup);
        this.emitter.on("loadUserSMSNotificationsPopup", this.loadUserSMSNotificationsPopup);
        this.emitter.on("refreshNotificationCount", this.refreshNotificationCount);

    }

    unmounted(){
        this.emitter.off("loadUserPreferencesPopup", this.loadUserPreferencesPopup);
        this.emitter.off('loadWorkforceRequestStatusPopup', this.loadWorkforceRequestStatusPopup);
        this.emitter.off('loadWorkforceRequestStatusPopupWithSelectedFacAndDept', this.loadWorkforceRequestStatusPopupWithSelectedFacAndDept);
        this.emitter.off('loadContractLaborPopup', this.loadContractLaborPopup);
        this.emitter.off('loadAggregatedSkillDashboard', this.loadAggregatedSkillDashboard);
        this.emitter.off('load7DayStaffingView', this.load7DayStaffingView);
        this.emitter.off('showToasterPopupByHome', this.showToasterPopupByHome);
        this.emitter.off('loadManageCensusPopup', this.loadManageCensusPopup);
        this.emitter.off('loadDailyRosterFilterReportPopup', this.loadDailyRosterFilterReportPopup);
        this.emitter.off("loadUserSMSNotificationsPopup", this.loadUserSMSNotificationsPopup);   
        this.emitter.off("refreshNotificationCount", this.refreshNotificationCount);
    }

    async mounted(){
        this.emitter.emit('showTopNavBarMenus');
         this.loadProfileData();
        
    }

    async refreshNotificationCount() {
        await this.loadDepartments();
    }

    async loadDepartments() {
        var inputData = {
            UserId: this.profileData.UserId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                var departmentIds = [];
                if (this.departmentsByUser != null && this.departmentsByUser.length > 0) {
                    departmentIds = this.departmentsByUser.map(function (dept: any) { return +dept.DepatmentId; });
                }
                var notificationRequest = {
                    departmentIds: departmentIds,
                    ResponseReceivedStartDateTimeUtc: moment().utc().subtract(2, 'days'),
                    ResponseReceivedEndDateTimeUtc: moment().utc().add(30, 'days'),
                    TenantId: this.profileData.TenantId
                };

                this.getSmsNotifications(notificationRequest);
            })
            .catch((err: any) => {
               
            });
    }

    async getSmsNotifications(notificationRequest: any) {
        await this.$store
            .dispatch("assignments/getSmsNotificationResponses", notificationRequest)
            .then(() => {
                if (this.notificationData != null && this.notificationData.length > 0) {
                    var modifiedData = [];
                    modifiedData = this.buildNotificationsData(this.notificationData);
                    this.displayNotificationBadge(modifiedData);
                }
            })
            .catch((err: any) => {
               
            });
    }

    buildNotificationsData(result: any) {
        var results: any = [];
        var counter = 0;
        var that = this;
        result.forEach(function (item: any) {
            item.details.forEach(function (detail: any) {
                detail.staffResponses.forEach(function (response: any) {
                    var clone = Object.assign({},
                        that.removeCollectionProps(item),
                        that.removeCollectionProps(detail),
                        response);
                    clone.responseDateTime = moment.utc(clone.receivedTimeUtc).local().format(that.shortDateTimeFormat);
                    //clone.responseText = clone.responseText;
                    clone.rowUniqueId = counter++;
                    //clone.group = that.buildTemplateSkillShiftGroupingHeader(clone);
                    clone.staffName = detail.firstName + ' ' + detail.lastName;
                    clone.staffId = detail.staffId;
                    clone.phoneNumber = detail.phoneNumber;
                    clone.department = item.departmentName + ' ' + '(' + item.facilityName + ')';
                    results.push(clone);
                });
            });
        });
        return results;
    }

    removeCollectionProps(obj: any) {
        var resultObj: any = {};
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop) && !Array.isArray(obj[prop])) {
                resultObj[prop] = obj[prop];
            }
        }
        return resultObj;
    }

    displayNotificationBadge(smsNotifications: any) {
        var smsPendingNotifications = this.getPendingSmsNotifications(smsNotifications);
        if (smsPendingNotifications.length > 0) {
            this.isShowNotifictionBadge = true;
            this.notificationCount = smsPendingNotifications.length;
            var lastestNotificationReceived = smsPendingNotifications.reduce(function (prev: any, curr: any) {
                if (prev) {
                    return prev.responseDateTime < curr.responseDateTime ? prev.responseDateTime : curr.responseDateTime;
                } else {
                    return curr.responseDateTime;
                }
            }, null);

            var duration = moment().diff(moment(lastestNotificationReceived), 'seconds');
            var backgroundColor: any = this.colors.green;
            var color: any;            
            
            var minutesSinceLastNotification = duration / 60;
            if (minutesSinceLastNotification > 5 && minutesSinceLastNotification <= 10) {
                backgroundColor = this.colors.yellow;
                color = this.colors.black;
            } else if (minutesSinceLastNotification > 10) {
                backgroundColor = this.colors.red;
                color = this.colors.white;
            }
            
            var badgeElement: any = document.getElementById('sms-notifications-count');
            if (badgeElement != null) {
                badgeElement.style.backgroundColor = backgroundColor;
                badgeElement.style.color = color;
            }
        }
        else {
            this.isShowNotifictionBadge = false;
        }
    }

    getPendingSmsNotifications(smsNotifications: any) {
        var that = this;
        return smsNotifications.filter(function (notification: any) {
            var hasShiftEnded = that.hasShiftEnded(notification.shiftStartDateTime, notification.shiftEndDateTime);
            return notification.action === NotificationActionTypes.received && !hasShiftEnded;
        });
    }

    hasShiftEnded(shiftStartDate: any, shiftEndDate: any) {
        var shiftEnd = moment(shiftEndDate);
        var effectiveDate = moment();
        return effectiveDate.isSameOrAfter(shiftEnd);
    }

    //Methods
    async loadProfileData(){
      this.getInitialDashboardConfig();
    }


    async getInitialDashboardConfig(){
        if(this.profileData.DashboardId == null || this.profileData.DashboardId == '' || this.profileData.UserId == null ||  this.profileData.UserId == 0)
            await this.$store.dispatch("profile/getUserAccounts");
        var configInput: any = {
            DashboardId: this.profileData.DashboardId ,
            CurrentDateTime: this.getCurrentDateTime()
        }

        var userPreferenceInput = {
            sourceId: 'Dashboard',
            dashboardId: this.profileData.DashboardId ,
            fsUserId: this.profileData.UserId,
            fsStaffId: 0
        }

        //this.emitter.emit('showLoader');
      
        Promise.all([
            this.$store.dispatch('dashboard/getDashboardConfiguration', configInput),
            this.$store.dispatch('userstaffpreferences/getUserPreferences', userPreferenceInput),
            

        ])
        .then(() => {
            this.filterPreferenceDetails = this.filterPreferences;
            this.dashboardConfig = this.dashboardConfiguration;
            this.isLoadHomePage = true;

            this.isShowAggregatedSkillDashboard = this.$route.path.startsWith('/dashboard');
            if (this.isShowAggregatedSkillDashboard) {
                this.loadAggregatedSkillDashboard();
            } else if(this.$route.path.startsWith('/staff')) {
                this.isShow7DayStaffingView = true;
                this.load7DayStaffingView();
            }
            if(this.$route.query.Source=='ScheduleBrowse')
            {
            var deptId=this.$route.query.DepartmentId;
              var facilityId=this.$route.query.FacilityId
              var rosterDate=this.$route.query.RosterDate;
              
              var selectedFacility = [{FacilityId:null,GuidValue:null,ParentValue:"",SortOrder:null,Text:"",Value:facilityId}]
              var selecteddept=[{Value:deptId}]    
             var input={
            startDate:rosterDate,
            endDate:rosterDate,
            selectedFacilities:selectedFacility,
            clusterDepartments:null,
            selectedDepartments:selecteddept,
            isDefaultReport:false
           }
               this.loadDailyRosterFilterReportPopup(input);
             } 
        })
        .catch((err: any) => {
            if (err) {
                this.emitter.emit('hideLoader');
                this.msgValue = 'Could not load the Dashboard Metrics. This could be due to your session timing out. Please refresh the page to reload this session.';
                this.classFlag = 'error';
                this.isToasterVisible = true;
                console.error(err); // Handle errors any way you want
            }
        });
    }
    getCurrentDateTime() {
      var date = moment(new Date()).format(this.longDateFormat);
      return date;
    }

    showToasterPopupByHome(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    loadWorkforceRequestStatusPopup() {
        this.workforceRequestInput = {
            fsUserId: this.profileData.UserId,
            dashboardId: this.profileData.DashboardId,
            tenantId: this.profileData.TenantId,
            sourceId: 'Dashboard',
            facilities: this.dashboardConfig.Filters.Facilities,
            departments: this.dashboardConfig.Filters.Departments,
            fsStaffId: 0,
            selectedFacilityId: null,
            selectedDepartments: null
        }
        this.isShowWorkforceRequestPopup = true;
    }

    loadWorkforceRequestStatusPopupWithSelectedFacAndDept(dataSet: any){
        this.workforceRequestInput = {
            fsUserId: this.profileData.UserId,
            dashboardId: this.profileData.DashboardId,
            tenantId: this.profileData.TenantId,
            sourceId: 'Dashboard',
            facilities: this.dashboardConfig.Filters.Facilities,
            departments: this.dashboardConfig.Filters.Departments,
            fsStaffId: 0,
            selectedFacilityId: dataSet.FacilityId,
            selectedDepartments: [{Value: dataSet.DepartmentId, Text: dataSet.DepartmentName, 
                    FacilityId: dataSet.FacilityId, DepartmentCode: dataSet.DepartmentCode}]
        }, 
        this.isShowWorkforceRequestPopup = true;
    }

    loadContractLaborPopup(){
        this.contractLaborManagementInput = {
            userId: this.profileData.UserId,
            dashboardId: this.profileData.DashboardId,
            facilities: this.dashboardConfig.Filters.Facilities,
            departments: this.dashboardConfig.Filters.Departments,
            clusters: this.dashboardConfig.Filters.Clusters,
            contractLaborUsingKafkaEnabled: this.profileData.ContractLaborUsingKafkaEnabled,
            tenantId: this.profileData.TenantId
        }
        this.isShowContractLaborManagementPopup = true;
    }

    loadManageCensusPopup(){
        this.manageCensusInput={
            userId: this.profileData.UserId,
            dashboardId: this.profileData.DashboardId,
            facilities: this.dashboardConfig.Filters.Facilities,
            departments: this.dashboardConfig.Filters.Departments,
            clusters: this.dashboardConfig.Filters.Clusters,
            tenantId: this.profileData.TenantId
        }
        this.isShowManageCensusPopup = true;
    }

    closeWorkforceRequesPopup(){
        this.isShowWorkforceRequestPopup = false;
    }

    closeContractLaborPopup(){
        this.isShowContractLaborManagementPopup = false;
    }

    closeUserPreferencesPopup(){
        this.isShowMyPreference = false;
    }
    loadUserPreferencesPopup(){
        this.isShowMyPreference = true;
    }
    loadUserSMSNotificationsPopup(){
    this.isSMSNotification = true;
    }
  closeSMSNotificationsPopup(){
    this.isSMSNotification = false;
  }
    loadAggregatedSkillDashboard(){
        this.isShow7DayStaffingView = false;
        this.emitter.emit('showLoader');
    }

    load7DayStaffingView(){
        this.isShowAggregatedSkillDashboard = false;
        this.emitter.emit('showLoader');
    }

    closeManageCensusPopup(){
        this.isShowManageCensusPopup = false;
    }

    closeDailyRosterFilterReportPopup(){
        this.isShowDailyRosterFilterReport = false;
    }
    
    loadDailyRosterFilterReportPopup(input : any){
        if(this.profileData.DashboardDailyRosterPrint){
         
        this.dailyRosterInput={
            userId: this.profileData.UserId,
            dashboardId: this.profileData.DashboardId,
            facilities: this.dashboardConfig.Filters.Facilities,
            departments: this.dashboardConfig.Filters.Departments,
            clusters: this.dashboardConfig.Filters.Clusters,
            startDate: input.startDate,
            endDate: input.endDate,
            selectedFacilities: input.selectedFacilities,
            clusterDepartments: input.clusterDepartments,
            selectedDepartments: input.selectedDepartments,
            isDefaultReport: input.isDefaultReport,
            tenantId: this.profileData.TenantId
        }
        
        this.isShowDailyRosterFilterReport = true;
    }
    }
    
}
</script>
<style scoped>

</style>
