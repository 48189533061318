<template>
    <div class="modalBackdrop flex absolute z-max">  
        <div class="c4c-modal">
            <header class="panelheader">
                <div class="headerTitleContainer">
                    <div class="headerActions">
                        <span class="headerTitle">Manage Census &nbsp;</span>
                        <a v-if="isShowTrainingLink && profileData.TrainingEnabled"
                            href="#"
                            class="modaltoolbarIcon neu-tooltip"
                            style="cursor: pointer; color:#3673AA; text-align: left;" @click="fnOpenVideo">
                                <span aria-hidden="true">
                                    <font-awesome-icon :icon="['fas', 'fa-video']" size="lg"></font-awesome-icon> Learn How
                                </span>
                            <span class="neu-tooltip--text tooltipTxt">{{ trainingLinkToolTip }}</span>
                        </a>
                    </div>
                </div> 
                <a href="#" class="closeBtn neu-tooltip"  @click="close">
                    <i aria-hidden="true">
                    <font-awesome-icon
                        :icon="['fas', 'close']"
                        size="lg" />
                    </i>
                    <span class="neu-tooltip--text">Close</span>
                </a>
            </header>
            <div id="manageCensusModal" class="panleBody scrollBar border" style="overflow:scroll;">                                
                <div v-if="isShowTabList">
                    <neu-tablist :value="selectedTab" class="mx-4 tabList">
                        <neu-tab
                            white
                            tab="Current" 
                            @click="setSelectedTab('Current')">
                            <div>Current</div>
                        </neu-tab>
                        <neu-tab
                            white
                            tab="ActivityLog"
                            @click="setSelectedTab('ActivityLog')">
                            <div>Activity Log</div>
                        </neu-tab>
                        <neu-tab
                            white
                            tab="CenusTrend"
                            @click="setSelectedTab('CensusTrend')">
                            <div>Census Trend</div>
                        </neu-tab>
                    </neu-tablist> 
                    <div id="currentCensusTabWrapper" class="panelTabData scrollBar" v-if="isCurrentTab"  role="tabpanel">
                        <CurrentCensusTab :currentCensusTabInput="tabInput"/>
                    </div>
                    <div id="activityLogTabWrapper" class="panelTabData scrollBar" v-if="isActivityLogTab"  role="tabpanel">
                        <ActivityLogTab :activityLogTabInput="tabInput"/>
                    </div>
                    <div id="trendTabWrapper" class="panelTabData scrollBar" v-show="isCensusTrendTab"  role="tabpanel">
                        <CensusTrendTab :censusTrendTabInput="tabInput" />
                    </div> 
                </div>        
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import {
  NeuTab,
  NeuTablist
} from "@neutron/vue";

import CurrentCensusTab from './CurrentCensusTab.vue';
import ActivityLogTab from './ActivityLogTab.vue';
import CensusTrendTab from './CensusTrendTab.vue';
import { Profile } from '@/models/profile/index';

class Props {
    manageCensusInput!: any 
}

@Options({
  computed: {
    ...mapState('shared', ['facilitiesByUser', 'departmentsByUser']),
    ...mapState('profile', ['profileData']),
  },
  components: {
    CurrentCensusTab,
    ActivityLogTab,
    NeuTab,
    NeuTablist,
    CensusTrendTab
  }
}) 
export default class CensusModal extends Vue.with(Props) {
    //Data properties
    isShowTabList: boolean = false;
    selectedTab: string = 'Current';
    tabInput: any = null;
    facilitiesByUser!: any;
    departmentsByUser!: any;
    facilities: any = [];
    allDepartments: any = [];
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;
    isCurrentTab:boolean = true;
    isActivityLogTab:boolean = false;
    isCensusTrendTab:boolean = false;
    profileData!: Profile;

    //Lifecycle hooks
    async mounted(){
        await this.loadManageCensusTabInput()
    }

    //Methods
    async loadManageCensusTabInput(){
        await this.loadFacilities();
        await this.loadDepartments();
        this.tabInput = {
            userId: this.manageCensusInput.userId,
            facilities: this.facilities,
            departments: this.allDepartments,
            tenantId: this.manageCensusInput.tenantId
        }
        this.isShowTabList = true;
    }

    fnOpenVideo(){
        window.open(this.trainingCurrentLink, '', 'addressbar=false,toolbar=false,width=900,height=600,left=200,top=200,resizable=yes,title=Learn How');
    } 

    trainingLinkToolTip: string = 'Click here to learn how to manage census';
    trainingCurrentLink: string = 'https://mediaconnect.app.medcity.net/embed?id=ef0f4db0-5912-4245-ba5b-c064111a9037';
    isShowTrainingLink: boolean = true;

    setSelectedTab(tab: string){
        this.isCurrentTab = false;
        this.isActivityLogTab = false;
        this.isCensusTrendTab = false;
        this.selectedTab = tab;       
        
        switch (this.selectedTab) {
            case 'Current':  
                this.isCurrentTab = true;
                break;
            case 'ActivityLog':
                this.isActivityLogTab = true;
                break;
            case 'CensusTrend':
                this.isCensusTrendTab = true;
                break;
        }
    }
    
    async loadFacilities(){
        var inputData = {
            UserId: this.manageCensusInput.userId,
            TenantId: this.manageCensusInput.tenantId
        }
        await this.$store
            .dispatch("shared/getFacilitiesByUser", inputData)
            .then(() => {
                if(this.facilitiesByUser != null && this.facilitiesByUser.length > 0){
                    var facilitiesByUser = this.facilitiesByUser;
                    var facilityIds = this.manageCensusInput.facilities.map((facility: any) => { return +facility.Value; });
                    var facilities: any = [];
                    facilitiesByUser = this.sortBy(this.facilitiesByUser, "name", "asc");
                    facilitiesByUser.forEach((facility: any) => {
                        if (facilityIds.indexOf(+facility.facilityId) >= 0) {
                            facilities.push({
                                FacilityId: facility.facilityId,
                                Text: facility.name + ' - ' + facility.coid,
                                Value: facility.facilityId,
                                Coid: facility.coid
                            });
                        }
                    });
                    this.facilities = facilities;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Facility.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadDepartments(){
        var inputData = {
            UserId: this.manageCensusInput.userId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                if(this.departmentsByUser != null && this.departmentsByUser.length > 0){
                    var finalDepartments: any = [];
                    var allDepartmentsLookup: any = {};                    
                    var departmentsByUser = this.departmentsByUser;

                    //Sorting based on dept code
                    this.manageCensusInput.departments.forEach((department: any) => {
                        allDepartmentsLookup[department.Value] = {
                            clusterId: department.ParentValue
                        };
                    });

                    departmentsByUser.forEach((department: any) => {
                        var departmentFound = allDepartmentsLookup[+department.DepatmentId];
                        if (departmentFound) {
                            finalDepartments.push({
                                FacilityId: department.FacilityId,
                                Text: department.DepartmentCode + ' - ' + department.DepartmentName,
                                Value: department.DepatmentId,
                                DepartmentCode: department.DepartmentCode,
                                ClusterId: departmentFound.clusterId
                            });
                        }
                    });

                    this.allDepartments = finalDepartments;
                }
            })
            .catch((err: any) => {
                if (err) {
                     var errorData = {
                            message: "An error occured while retrieving Department.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }
    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }
    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }
    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>
.c4c-modal {
    width: 90%;
    height: 92%;
    overflow: hidden;
    font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
    background: white;
    box-shadow: 0px 16px 30px 0px;
}
.panleBody{
    margin: 5px;
    padding: 5px 12px;
    height:90%;
}
.panelTabData{
    word-break: break-all;
    padding-bottom: 23px;
}
.headerTitleContainer{
    float: left;
    display: inline-flex;
    margin-right: 10px;
}

.headerActions{
    display: inline-flex;
    align-items: center;
}
</style>