<template>
   <div id="add-view-notes">
        <div class="row">
            <div class="col-md-11">
                <div id="listViewNotes" class="note-list txtLeft scrollBar">
                    <template v-if="notesData != null && notesData.length > 0 && isAssignmentNoteTemplate">
                        <div class="note" v-for="notes in notesData"  :key="notes">
                            <div>
                                <span class="text-muted">{{notes.createDate}}&nbsp;</span>
                                <span class="font-weight-bold">{{notes.createUser}}</span>&nbsp;-&nbsp;
                                <span class="action bg-info text-white p-1 rounded">{{notes.action}}</span>&nbsp;-&nbsp;
                                <span class="shift">Shift: <strong>{{notes.shiftCode}}</strong></span>&nbsp;
                            </div>
                            <div class="py-2" :class="[ notes.note == '' || notes.note == null ? 'd-none' : '']">
                                <span class="font-weight-bold">Note:</span>&nbsp;{{notes.note}}
                            </div>
                        </div>
                    </template>
                    <template v-if="notesData != null && notesData.length > 0 && isOtherNoteTemplate ">
                        <div v-for="notes in notesData"  :key="notes">
                            <div style="padding-left: 10px; font-size: 1rem">
                                <span class="text-muted">{{notes.createdDate}}&nbsp;</span>
                                <span class="font-weight-bold">{{notes.userLastName}},&nbsp;{{notes.userFirstName}}&nbsp;-&nbsp;</span>
                                <span>{{notes.noteContent}}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-11">
                <br />
                <textarea id="txtNote" class="form-control" rows="3" v-model="noteTxt" placeholder="Start Typing..." @keyup="sendNoteText"></textarea>
                <h5 id="lblRemaining" class="card-title txtLeft">Remaining: <span id="remaining" class="text-muted">
                {{ nMaxLength - (noteTxt != undefined || noteTxt!= "" ? noteTxt.length: 0) }}</span></h5>
            </div>
        </div>
        <div class="card-body position-relative" style="overflow: auto;" v-if="isShowAddNoteBtn">
            <div class="text-center">
                <neu-button type="button" fill="raised" id="btnSaveNote" @click="createSendNote">
                    <font-awesome-icon :icon="['fas', 'save']" size="sm" class="mr-2" /> Add Note
                </neu-button>
            </div>
        </div>
   </div> 
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";

//imports models and enums
import { NoteType, NoteMaxLength, NoteRelationType } from "@/enums/enum";

//Import Componenets
import {
    NeuButton
} from "@neutron/vue";

@Options({
  computed: {
    ...mapState('actionable', ['costCenter', 'notesByAssignment', 'notesByDept', 'notesByEntity']),
  },
  components: {
    NeuButton
  }
}) 
export default class AddViewNote extends Vue {
    //Data Properties
    shortDateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ss';
    notesByAssignment!: any;
    costCenter!: any;
    notesByDept!: any;
    notesByEntity!: any;
    notesData: any = [];
    nMaxLength: number = NoteMaxLength.FIVEHUNDRED;
    noteTxt: string = "";
    notesInput: any = null;
    isAssignmentNoteTemplate: boolean = false;
    isOtherNoteTemplate: boolean = false;
    isShowAddNoteBtn: boolean = true;

    //Lifecycle hooks
    created(){
        this.emitter.on("loadNotes", this.loadNotes);
        this.emitter.on("hideAddNoteBtn", this.hideAddNoteBtn);
        this.emitter.on("createSendNote", this.createSendNote);
        this.emitter.on("updateNote", this.updateNote);
    }
    unmounted(){
        this.emitter.off("loadNotes", this.loadNotes);
        this.emitter.off("hideAddNoteBtn", this.hideAddNoteBtn);
        this.emitter.off("createSendNote", this.createSendNote);
        this.emitter.off("updateNote", this.updateNote);
    }

    //Methods
    async loadNotes(params: any){
        this.notesInput = JSON.parse(JSON.stringify(params));
        var jsonNote = {
            NoteTypeId: NoteType.COMMENTS,
            DepartmentId: params.DepartmentId,
            TenantId: params.TenantId,
            RelationId: params.RelationId,
            RelationIdValue: params.RelationIdValue,
            CostCenterId: params.CostCenterId,
            StartDateTime: moment(params.StartDateTime).format(this.shortDateTimeFormat),
            EndDateTime: moment(params.EndDateTime).subtract(1, 'minutes').format(this.shortDateTimeFormat)
        };

        switch (params.RelationId) {
                case NoteRelationType.FS_ASSIGN:
                    this.getCostCenterByAssignment(jsonNote);
                    break;
                case NoteRelationType.FS_DEPT:
                    this.getNotesByDepartment(jsonNote);
                    break;
                case NoteRelationType.FS_DEPTFORECASTCENSUS:
                    this.getNotesByEntity(jsonNote);
                    break;
        }
    }

    async getCostCenterByAssignment(jsonNote: any){
        await this.$store
                .dispatch("actionable/getCostCenter", jsonNote)
                .then(() => {
                    this.getNotesByAssignment(jsonNote);
                })
                .catch((err: any) => {
                    if (err) {
                        // var erroData = {
                        //     message: "An error occured while retrieving cost center.",
                        //     flag: "error"
                        // }
                        // this.emitter.emit('showToasterNotificationAssignment', erroData)
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async getNotesByAssignment(jsonNote: any){
        if(this.costCenter != null){
            jsonNote.CostCenterId = this.costCenter.costCenterId;
        }
        await this.$store
                .dispatch("actionable/getNotesByAssignment", jsonNote)
                .then(() => {
                    this.notesData = this.notesByAssignment;
                    this.displayAssignmentNotes();
                    this.isAssignmentNoteTemplate = true;
                    this.isOtherNoteTemplate = false;
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while retrieving notes.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', erroData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async getNotesByDepartment(jsonNote: any){
        await this.$store
                .dispatch("actionable/getNotesByDepartment", jsonNote)
                .then(() => {
                    this.notesData = this.notesByDept;
                    this.displayNotes();
                    this.isAssignmentNoteTemplate = false;
                    this.isOtherNoteTemplate = true;
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while retrieving notes.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationActionable', erroData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async getNotesByEntity(jsonNote: any){
        await this.$store
                .dispatch("actionable/getNotesByEntity", jsonNote)
                .then(() => {
                    this.notesData = this.notesByEntity;
                    this.displayNotes();
                    this.isAssignmentNoteTemplate = false;
                    this.isOtherNoteTemplate = true;
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while retrieving notes.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationDashboardMain', erroData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }
    
    displayAssignmentNotes(){
        if (this.notesData != null) {
            this.clearNotes();

            this.notesData.forEach((value: any, index: any) =>  {
                var utcDateTime = moment.utc(value.createDate).toDate();
                this.notesData[index].createDate = moment(utcDateTime).format('MM/DD/YYYY HH:mm A');
                if (this.notesData[index].shiftCode === null) {
                    this.notesData[index].shiftCode = "???";
                }
            });
        }
    }

    displayNotes(){
        if (this.notesData != null) {
            this.clearNotes();

            this.notesData.forEach((value: any, index: any) =>  {
                this.notesData[index].createdDate = moment(value.createdDate).format('MM/DD/YYYY HH:mm A');
            });
        }
    }

    clearNotes() {
        this.noteTxt = "";
        this.nMaxLength = NoteMaxLength.FIVEHUNDRED;
    }

    async createSendNote(){
        if(this.noteTxt == "" || this.noteTxt == null){
            var erroData = {
                message: "Please enter a note.",
                flag: "error"
            }
            this.emitter.emit('showToasterNotificationActionable', erroData);
            return;
        }

        var currentDateTime = new Date();
        var startDateTime = new Date(this.notesInput.StartDateTime);

        var jsonGetNote = {
            NoteTypeId: NoteType.COMMENTS,
            DepartmentId: this.notesInput.DepartmentId,
            TenantId: this.notesInput.TenantId,
            RelationId: this.notesInput.RelationId,
            RelationIdValue: this.notesInput.RelationIdValue,
            CostCenterId: this.notesInput.RelationId == NoteRelationType.FS_ASSIGN ? this.costCenter.costCenterId : this.notesInput.CostCenterId,
            StartDateTime: moment(this.notesInput.StartDateTime).format(this.shortDateTimeFormat),
            EndDateTime: moment(this.notesInput.EndDateTime).subtract(1, 'minutes').format(this.shortDateTimeFormat)
        };

        var jsonPostNote = {
            NoteId: '00000000-0000-0000-0000-000000000000',
            NoteTypeId: NoteType.COMMENTS,
            DepartmentId: this.notesInput.DepartmentId,
            RelationId: this.notesInput.RelationId,
            FacilityId: this.notesInput.RelationId == NoteRelationType.FS_ASSIGN ? this.costCenter.facilityId : this.notesInput.CostCenterId,
            RelationIdValue: this.notesInput.RelationIdValue.toString(),
            NoteContent: this.noteTxt,
            UserId: this.notesInput.UserId.toString(),
            NoteCreatedDate: moment(startDateTime).format(this.shortDateTimeFormat),
            CreatedDate: moment(currentDateTime).format(this.shortDateTimeFormat),
            ModifiedDate: moment(startDateTime).format(this.shortDateTimeFormat),
            ExistInFS: this.notesInput.RelationId === NoteRelationType.FS_ASSIGN ? true : false
        };

        var jsonPostNeedNote = {
            NeedId: this.notesInput.NeedId,
            AssignmentId: this.notesInput.AssignmentId,
            DepartmentShiftId: this.notesInput.DepartmentShiftId,
            Note: this.noteTxt,
            CreatedDate: moment(currentDateTime).format(this.shortDateTimeFormat),
            ActionId: 6, // Edit Assignment
        };

        switch (this.notesInput.RelationId) {
                case NoteRelationType.FS_ASSIGN:
                    this.postNeedNotes(jsonPostNeedNote, jsonPostNote, jsonGetNote)
                    break;
                case NoteRelationType.FS_DEPT:
                    this.postDepartmentNotes(jsonPostNote, jsonGetNote);
                    break;
                case NoteRelationType.FS_DEPTFORECASTCENSUS:
                    this.postEntityNotes(jsonPostNote, jsonGetNote);
                    break;
        }

        
    }

    async postNeedNotes(jsonPostNeedNote: any, jsonPostNote: any, jsonGetNote: any){
        await this.$store
                .dispatch("actionable/postNeedNote", jsonPostNeedNote)
                .then(() => {
                    this.postAssignmentNotes(jsonPostNote, jsonGetNote);
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while creating a note.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', erroData)
                        console.error(err); // Handle errors any way you want
                    }
                });
    }
    async postAssignmentNotes(jsonPostNote: any, jsonGetNote: any){
        await this.$store
                .dispatch("actionable/postNote", jsonPostNote)
                .then(() => {
                    this.getNotesByAssignment(jsonGetNote);
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while creating a note.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', erroData)
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async postDepartmentNotes(jsonPostNote: any, jsonGetNote: any){
        await this.$store
                .dispatch("actionable/postNote", jsonPostNote)
                .then(() => {
                    this.getNotesByDepartment(jsonGetNote);
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while creating a note.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationActionable', erroData)
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async postEntityNotes(jsonPostNote: any, jsonGetNote: any){
        await this.$store
                .dispatch("actionable/postNote", jsonPostNote)
                .then(() => {
                    this.getNotesByEntity(jsonGetNote);
                })
                .catch((err: any) => {
                    if (err) {
                        var erroData = {
                            message: "An error occured while creating a note.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationDashboardMain', erroData)
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    hideAddNoteBtn(){
        this.isShowAddNoteBtn = false;
    }

    sendNoteText(){
        //@ts-ignore
        if(this.noteTxt != null && this.noteTxt != undefined || this.noteTxt.trim() != ""){
            this.emitter.emit('updateNoteText', this.noteTxt);
        }
    }

    updateNote(note: any){
        this.noteTxt = note;
    }
}
</script>
<style scoped>
.note-list {
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    list-style: outside none none;
    border: 1px solid #CED1D7;
}

.note-list .note {
    margin: 0 5px;
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    font-weight: 400;
}
</style>