<template>
  <div>
    <div class="neu-404 neu-404--centered neu-404--centered__content">
      <span class="neu-text--h3 neu-text--bold">
        Application Error
      </span>
      <span class="neu-text--body neu-margin--top-30">
        There was an error loading the User Account associated with your User ID..
      </span>
       <span class="neu-text--body neu-margin--top-10">
        If the issue continues, please contact the help desk or
        your <a class="neu-link" href="https://hcahealthcare.sharepoint.com/:x:/r/sites/CORP-pio/Directory/Facility_Scheduler_Champion_Directory.xlsx?d=w1264cdbd4dcf42ceaa7c9311920281f6&csf=1&web=1&e=TPYcZh">FSC</a>.
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';


export default class ApplicationError extends Vue {  
  mounted(){
    this.emitter.emit('hideTopNavBarMenus');
    setTimeout(() => {
          this.emitter.emit('hideLoader');
      }, 2000);
  }
}
</script>
<style scoped>
.neu-404.neu-404--centered__content, .neu-404.neu-404--left-aligned__content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%,-50%);
    width: 300px;
    text-align: center;
}

.neu-text--bold {
    font-family: HCA-Mark-Bold,Arial,sans-serif!important;
}
.neu-text--h3, h3 {
    font-size: 2.125rem;
    line-height: 2.5rem;
}

.neu-text--body, .neu-text--product, p {
    font-size: 1em;
    line-height: 1.625rem;
}

.neu-text--body, .neu-text--caption, .neu-text--h1, .neu-text--h2, .neu-text--h3, .neu-text--h4, .neu-text--h5, .neu-text--h6, .neu-text--subhead, .neu-text--support, h1, h2, h3, h4, h5, h6, p, small {
    display: block;
    line-height: 1.5;
}

.neu-margin--top-30 {
    margin-top: 1.875em!important;
}

.neu-margin--top-10 {
    margin-top: 0.625em!important;
}

</style>