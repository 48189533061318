<template>
    <div class="modalBackdrop flex absolute z-max">
        <div class="c4c-modal">
            <header class="panelheader">
                <span class="headerTitle">Confirm</span>
            </header>
            <div class="align-content-center">
                <p> {{msgValue}} </p>
            </div>
            <div class="mt-3 mb-3 neu-text--align-center">
                <button @click="onConfirmClick()" class="neu-button--primary neu-button neu-button--raised adjustWidth">
                    Ok
                </button>
                <button @click="onCancelClick()" class="neu-button--primary neu-button neu-button--raised adjustWidth ml-2">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    //import plugins and modules
    import { Vue } from 'vue-class-component';
    class Props {
        msgValue!: string
    }

    export default class ConfirmationPopup extends Vue.with(Props) {

        onConfirmClick() { this.$emit('confirmed'); }

        onCancelClick() { this.$emit('cancelled'); }
    }

</script>

<style scoped lang="css">

    .c4c-modal {
        width: 50%;

        max-width: 50%;
        overflow: hidden;
        font-family: "HCA-Mark-Medium", "Arial", sans-serif !important;
        background: white;
        box-shadow: 0px 16px 30px 0px;
    }


    .adjustWidth {
        min-width: auto !important;
    }
</style>
