<template>
    <div class="modalBackdrop flex absolute z-max">
        <div class="save-modal absolute" id="savePopUpModal" aria-labelledby="savePopUpModalLabel" aria-hidden="true">
            <div class="modalContent">
                <div :class="['neu-snackbar', className]">
                    <i class="material-icons neu-snackbar__feedback-icon">
                        <neu-icon>
                        {{
                            iconName
                        }}
                        </neu-icon>
                    </i>
                    <span class="neu-snackbar__text">
                        {{
                            msgValue
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Vue, prop } from 'vue-class-component';

class Props {
    msgValue!: string
    classFlag!: string 
}

export default class ToasterPopup extends Vue.with(Props) {
    //Data properties
    className: string = "";
    iconName: string = "";

    //Lifecycle hooks
    mounted() {
        let vm = this;
        if (this.classFlag.toLowerCase() == 'success') {
            this.className = 'neu-snackbar--success';
            this.iconName = 'check_circle';
        }
        else if (this.classFlag.toLowerCase() == 'error') {
            this.className = 'neu-snackbar--error';
            this.iconName = 'error';
        }
        else if (this.classFlag.toLowerCase() == 'warning') {
            this.className = 'neu-snackbar--warning';
            this.iconName = 'warning';
        }
        setTimeout(function () { vm.closeButtonClicked() }, 3000);
    }

    //Methods
    closeButtonClicked() {
        this.$emit('closeModal');
    }
}
</script>
<style lang="css" scoped>
    .neu-snackbar{
        font-size: .875rem !important;
        font-weight: 600 !important;
        border-radius: 5px !important;
    }

    .material-icons{
        line-height: inherit !important
    }
</style>
