<template>
    <div class="tab-pane fade show active m-2 tab-content" id="CurrentCensusTabContent" role="tabpanel" aria-labelledby="home-tab">
        <div id="censusFormContainer" class="txtLeft">
            <div class="row mb-3">
                <div class="col-3">
                    <label class="text-uppercase default-font-size">facility</label>
                    <VueMultiselect v-model="selectedFacilities"   
                            :options="facilities"
                            :multiple="true"
                            :searchable="true"
                            :close-on-select="true" 
                            @update:modelValue="onFacilityChange"                         
                            label="Text"
                            track-by="Text"
                            placeholder="All Facilities..."
                            id="selFacilities">                            
                    </VueMultiselect>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">department</label>
                     <VueMultiselect v-model="selectedDepartments"
                                    :options="departments"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="true"
                                    placeholder="All Departments..."
                                    label="Text"
                                    track-by="Text"
                                    id="selDepartments">
                    </VueMultiselect>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">FROM</label>
                    <Datepicker v-model="selectedStartDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                    :enableTimePicker="true" autoApply ></Datepicker>
                </div>
                <div class="col-3">
                    <label class="text-uppercase default-font-size">TO</label>
                    <Datepicker v-model="selectedEndDate" weekStart="0" inputClassName="dp-custom-input" class="form-control requestDate" style="width: 100%;"
                                :enableTimePicker="true" autoApply ></Datepicker>
                </div>
            </div>
            <div class="row mb-1">
                 <div class="col-3">
                    <label class="text-uppercase default-font-size">PART OF DAY</label>
                    <select class="neu-select" name="facility" id="partOfDayDropdown"
                            v-model="selectedPartOfDay">
                        <option :value="-1" selected>All Templates...</option> 
                        <option v-for="partOfDay in partOfDays" :value="partOfDay.partOfDayId" :key="partOfDay.partOfDayId">
                            {{partOfDay.partOfDayName}}
                        </option>    
                    </select>
                    <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                </div>
                <div class="col-2 search-wrapper" style="padding-top:20px">
                    <neu-button type="button" fill="raised" id="manageCensusSearch" 
                        class="float-end me-1" @click="searchClick">
                        SEARCH
                    </neu-button>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-6 text-right">
                    <span title="Interface Enabled">
                        <i aria-hidden="true">
                            <font-awesome-icon class="meditechEnabledIcon" :icon="['fas', 'check']" size="lg" />
                        </i>
                        {{"Interface Enabled"}}
                    </span>
                </div>
                <div class="col-3 text-right">                       
                    <span class="spanCircleGreen"></span>
                    <span style="padding-right:20px;"> Entered Before Shift Start </span>
                </div>
                <div class="col-3">
                    <span class="spanCircleRed"></span>
                    <span> Entered After Shift Start </span>
                </div>
            </div>
        </div>
        
        <div class="activityLog-grid-container mt-2">
            <div id="census-content-loading" class="text-center mt-3"  v-show="isShowLoader">
                <div class="fa-2x">
                   <font-awesome-icon :spin="true" :icon="['fas', 'circle-notch']" size="lg" />
                </div>
                <p><b>Loading...</b></p>
            </div>
            <div id="statusGrid">
                <neu-card color="plain-0" class="p-0 m-0">
                    <neu-table class="activityLogTbl">
                        <neu-table-row size="small" :columns="columnHeaders" header>
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                            <neu-table-heading v-for="column in Object.keys(columnHeaders)" :key="column" :icon="columnIcons[column]" :slot="column"
                                style="padding-top: 25px !important;">
                            {{ column }}
                            </neu-table-heading>
                        </neu-table-row>
                        <neu-table-body align="left" class="scrollBar">
                            <template v-if="activityLogGridData != null && activityLogGridData.length > 0">
                                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                <neu-table-row size="small" :columns="columnHeaders" v-for="activityLog in activityLogGridDataList" :key="activityLog" :slot="column">
                                    
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Facility" :title=activityLog.facilityName>{{ activityLog.facilityName }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Department" :title=activityLog.deptName>{{ activityLog.deptName }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Date">
                                        {{ buildDateTemplate(activityLog.startDateTime) }}
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Start Time">{{ buildTimeTemplate(activityLog.startDateTime) }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="End Time">{{ buildTimeTemplate(activityLog.endTime) }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Forecast">{{ activityLog.forecast }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Actual">
                                        <span v-if="activityLog.importedOnUtc != null" title="Interface Enabled">
                                            {{ activityLog.actual }}                                           
                                            <i aria-hidden="true">
                                                <font-awesome-icon class="meditechEnabledIcon" :icon="['fas', 'check']" size="lg" />
                                            </i>
                                        </span>
                                        <span v-else>{{ activityLog.actual }}
                                        </span>
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Staff To">{{ activityLog.staffTo }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Entered At">
                                        <span v-if="buildFormattedDateTemplate(activityLog.startDateTime, activityLog.dateChangedUtc)"
                                            :title="'Entered After Shift Start - ' + activityLog.dateChangedUtc">
                                            <span>{{buildEnteredDateTemplate(activityLog.dateChangedUtc)}} </span>
                                            <span class="spanCircleRed"></span>
                                        </span>
                                        <span v-else :title= "'Entered After Shift Start - ' + activityLog.dateChangedUtc">
                                            <span>{{buildEnteredDateTemplate(activityLog.dateChangedUtc)}} 
                                            </span><span class="spanCircleGreen"></span>
                                        </span>
                                    </neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Entered By" :title="activityLog.modifiedBy">{{ activityLog.modifiedBy }}</neu-label>
                                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                    <neu-label slot="Notes" :title="activityLog.notes">{{ activityLog.notes }}</neu-label>                                                            
                                </neu-table-row>
                            </template>
                            <template v-else>
                                <p id="period-message-text">No data available on current page.</p>
                            </template>
                        </neu-table-body>
                    </neu-table>
                </neu-card>
                <neu-container fluid="" v-if="activityLogGridData != null && activityLogGridData.length > 0">
                    <neu-row>
                        <neu-col md="6" cols="12" class="neu-text-center neu-text-md-left">
                            <!-- eslint-disable-next-line vue/no-parsing-error  -->
                            <neu-paginator class="justify-content-start" @v-neu-change="(ev: CustomEvent) => this.pageNum = ev.detail.pageNumber"
                                :total="activityLogGridData != null && activityLogGridData.length"
                                :rowsPerPage="rowsPerPage"
                                >
                            </neu-paginator>
                        </neu-col>
                        <neu-col md="6" cols="12" class="neu-text-center neu-text-md-right">
                            <neu-table-row-count
                                class="mt-3"
                                section="number"
                                :total="activityLogGridData != null && activityLogGridData.length"
                                :page-number="pageNum"
                                @v-neu-change="rowCountChange"
                            >
                            </neu-table-row-count>
                            
                        </neu-col>
                    </neu-row>
                </neu-container>
            </div>
        </div>

    </div>
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import { KeyPairModel } from '@/models/common/index';
import jslinq from "jslinq";

//import model and enum
//import model and enum
import { Profile } from '@/models/profile/index';

//Import Componenets
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ToasterPopup from '../shared/ToasterPopup.vue';
import {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox
} from "@neutron/vue";
//@ts-ignore
import VueMultiselect from 'vue-multiselect';

class Props {
    activityLogTabInput!: any 
}

@Options({
  computed: {
    ...mapState('shared', ['facilitiesByUser', 'departmentsByUser']),
    ...mapState('profile', ['profileData']),
    ...mapState('managecensus', ['partOfDayTemplates', 'censusActivityLog']),
    activityLogGridDataList() {
      if (this.activityLogGridData && this.activityLogGridData.length === 0)
        return [];
      else return this.activityLogGridData.slice(this.first - 1, this.last);
    },
  },
  components: {
    NeuButton,
    NeuContainer,
    NeuInput,
    NeuRow,
    NeuTableRowCount,
    NeuTable,
    NeuTableRow,
    NeuLabel,
    NeuTableBody,
    NeuTableHeading,
    NeuCard,
    NeuPaginator,
    NeuCol,
    NeuCheckbox,
    Datepicker,
    VueMultiselect,
    ToasterPopup
  }
}) 
export default class ActivityLogTab extends Vue.with(Props) {
    //Data properties
    profileData!: Profile;
    facilitiesByUser!: any;
    departmentsByUser!: any;
    censusActivityLog: any;
    partOfDayTemplates: any;

    allDepartments: any = [];
    facilities: any = [];
    selectedFacilities: any = null;
    departments: any = [];
    selectedDepartments: any = null;
    partOfDays: any = [];
    selectedPartOfDay: any = [];
    startDate: Date = new Date();
    endDate: Date = new Date();
    selectedStartDate: Date = new Date();
    selectedEndDate: Date = new Date();
   
    activityLogGridData: any = null;
    isShowLoader: boolean = false;
    msgValue!: string;
    classFlag!: string;
    isToasterVisible:boolean = false;

    columnHeaders: any ={ 
        "Facility": "8%", 
        Department: "9%", 
        Date: "10%", 
        "Start Time": "9.5%",
        "End Time": "9%",
        Forecast: "8.5%",
        Actual: "7%",
        "Staff To": "8%",
        "Entered At": "15%",
        "Entered By": "9%",
        Notes:"7%"
    };
    columnIcons: any = {    
        "Facility": "none", 
        Department: "none", 
        Date: "none", 
        "Start Time": "none",
        "End Time": "none",
        Forecast: "none",
        Actual: "none",
        "Staff To": "none",
        "Entered At": "none",
        "Entered By": "none",
        Notes:"none"
    };

    pageNum: number = 1;
    rowsPerPage: number = 10;
    first: number = 1;
    last: number = 10;

    //Lifecycle hooks
    async mounted(){
       await this.loadActivityLogTab();
       this.selectedPartOfDay = -1;
    }

    //Methods
    async loadActivityLogTab(){
        await this.loadFacilities();
        await this.loadDepartments();
        await this.loadPartOfDay();
    }
    
    async loadPartOfDay(){
        var inputData = {
         
            TenantId: this.activityLogTabInput.tenantId
        }
        await this.$store
            .dispatch("managecensus/getPartOfDayTemplates",inputData)
            .then(() => {
                if(this.partOfDayTemplates != null && this.partOfDayTemplates.length > 0){
                    this.partOfDays = this.partOfDayTemplates;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Part of Day.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadFacilities(){
        var inputData = {
            UserId: this.activityLogTabInput.userId,
            TenantId: this.activityLogTabInput.tenantId
        }
        await this.$store
            .dispatch("shared/getFacilitiesByUser", inputData)
            .then(() => {
                if(this.facilitiesByUser != null && this.facilitiesByUser.length > 0){
                    var facilitiesByUser = this.facilitiesByUser;
                    var facilityIds = this.activityLogTabInput.facilities.map((facility: any) => { return +facility.Value; });
                    var facilities: any = [];
                    facilitiesByUser.sort(this.GetSortOrder("name"));
                    facilitiesByUser.forEach((facility: any) => {
                        if (facilityIds.indexOf(+facility.facilityId) >= 0) {
                            facilities.push({
                                FacilityId: facility.facilityId,
                                Text: facility.name + ' - ' + facility.coid,
                                Value: facility.facilityId,
                                COID: facility.coid
                            });
                        }
                    });
                    this.facilities = facilities;
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                            message: "An error occured while retrieving Facility.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadDepartments(){
        var inputData = {
            UserId: this.activityLogTabInput.userId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByUser", inputData)
            .then(() => {
                if(this.departmentsByUser != null && this.departmentsByUser.length > 0){
                    var finalDepartments: any = [];
                    var departmentIds = this.activityLogTabInput.departments.map((department: any) => { return +department.Value; });
                    var departmentsByUser = this.departmentsByUser;

                    //Sorting based on dept code
                    departmentsByUser.sort(this.GetSortOrder("DepartmentCode"));

                    departmentsByUser.forEach((department: any) => {
                        if (departmentIds.indexOf(+department.DepatmentId) >= 0) {
                            finalDepartments.push({
                                FacilityId: department.FacilityId,
                                Text: department.DepartmentName + ' - ' + department.DepartmentCode,
                                Value: department.DepatmentId,
                                DepartmentCode: department.DepartmentCode
                            });
                        }
                    });

                    this.allDepartments = finalDepartments;
                    this.refreshDepartmentList();
                }
            })
            .catch((err: any) => {
                if (err) {
                     var errorData = {
                            message: "An error occured while retrieving Department.",
                            flag: "error"
                        }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    refreshDepartmentList() {
        var selectedFacilities = this.selectedFacilities;

        if (selectedFacilities) {
            //@ts-ignore            
            var filteredDepartments = this.sortAscByText(this.getDistinct(
                this.getDepartments(selectedFacilities, this.allDepartments)));

            this.departments = filteredDepartments;

        } else {
            this.departments = this.allDepartments;
        }
    }

    getDepartments(selectedFaciities: any[], collection: KeyPairModel[] | null) {
        var result: any[] = [];
       
        for (var i = 0; i < selectedFaciities.length; i++) {
            var facilityId = selectedFaciities[i].FacilityId;

            //@ts-ignore
            var items = jslinq(collection).where(function (item) { return item.FacilityId === facilityId; }).distinct().toList();

            items.forEach((value) => { result.push(value); });
        }        

        result = this.sortAscByText(result);

        return result;
    }
    
    getDistinct(collection: KeyPairModel[] | null) {
        if (collection != null) {
            //@ts-ignore
            var results = jslinq(collection)
                .select(function (item: any) {
                    return { Value: item.Value, Text: item.Text };
                })
                .distinct()
                .orderBy(function (item: any) {
                    return item.Value;
                })
                .toList();

            return results;
        }
        else {
            return [];
        }
    }

    sortAscByText(collection: KeyPairModel[]) {
            //@ts-ignore
            var result = jslinq(collection).distinct().orderBy(function (item: any) {
                return item.Text;
            }).toList();

            return result;
        }

    GetSortOrder(prop: any) {
        return function (a: any, b: any) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            } return 0;
        }
    }

    onFacilityChange(){
        this.refreshDepartmentList();
        this.selectedDepartments = null;
    }      

    sortBy = function (array: any, key: any, direction: any) {
        var sortDirection = direction || 'asc';
        return array.sort(function (a: any, b: any) {
            if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
                return 0;
            }
            var x = a[key].toUpperCase();
            var y = b[key].toUpperCase();
            if (sortDirection.toLowerCase() === 'asc') {
                return (x < y) ? -1 : (x > y) ? 1 : 0;
            } else if (sortDirection.toLowerCase() === 'desc') {
                return (x > y) ? -1 : (x < y) ? 1 : 0;
            }
        });
    }

    async loadActivityLogGrid(inputData: any){
        this.showLoader();
        this.activityLogGridData = null;
        await this.$store
            .dispatch("managecensus/getDashboardCensusActivityLog", inputData)
            .then(() => {
                this.hideLoader();
                if (this.censusActivityLog != null) {
                    this.activityLogGridData = this.censusActivityLog;//this.sortBy(this.censusActivityLog,"Date", 'asc')           
                }              
            })
            .catch((err: any) => {
                if (err) {                 
                    var errorData = {
                        message: "An error occured while retrieving manage census data.",
                        flag: "error"
                    }
                    this.showToasterPopup(errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    rowCountChange(ev: CustomEvent) {
      if (ev.detail && ev.detail.first && ev.detail.last) {
        this.first = ev.detail.first;
        this.last = ev.detail.last;
      }
      if (ev.detail && ev.detail.value) this.rowsPerPage = ev.detail.value;
    }

    buildDateTemplate(Date: any) {
        var date = moment(Date).format('MM/DD/YYYY');
        return date;
    }

    buildTimeTemplate(Date: any) {
        var date = moment(Date).format('hh:mm A');
        return date;
    }

    buildFormattedDateTemplate(StartDateTime: any, DateChangedUtc: any, ) {
        var shiftStartTime = moment(StartDateTime).format("MM/DD/YYYY HH:mm");
        var enteredTime = moment(DateChangedUtc + 'Z').format("MM/DD/YYYY HH:mm");
        var valid = false
        if (enteredTime !== null) {
            if (new Date(shiftStartTime).getTime() < new Date(enteredTime).getTime()) {
                valid = true;
            }
            else {
                valid = false;
            }
        } 
        return valid;
    }

    buildEnteredDateTemplate( DateChangedUtc: any, ) {
        var enteredDateTime = moment(DateChangedUtc + 'Z').format("MM/DD/YYYY HH:mm");
        return enteredDateTime;
    }

    async searchClick(){
        var model: any = {};
        if (this.selectedFacilities == null) {
            var errorData = {
                message: "Please select Facility.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
        else if (this.selectedDepartments == null) {
            var errorData = {
                message: "Please select Department.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
        else if (this.selectedStartDate == null) {
            var errorData = {
                message: "Please enter valid From Date.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
        else if (this.selectedEndDate == null) {
            var errorData = {
                message: "Please enter valid To Date.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
        else if (moment(this.selectedStartDate ) > moment(this.selectedEndDate )) {
            var errorData = {
                message: "From Date should be less than To Date.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
        else if (Math.abs(moment(this.selectedStartDate ).diff(moment(this.selectedEndDate ), 'day')) > 30) {
            var errorData = {
                message: "Please filter within a 30 day range.",
                flag: "error"
            }
            this.showToasterPopup(errorData);
            return;
        }
      
        var deptIds: any = [];
        this.selectedDepartments.forEach((obj: any) => {
            deptIds.push(obj.Value);
        });
        model.DepartmentIds = deptIds;
        model.StartDateTime = moment(this.selectedStartDate).utc();
        model.EndDateTime = moment(this.selectedEndDate).utc();
        model.PartOfDayIds = this.selectedPartOfDay == -1 ? [1,2,4,3] : [this.selectedPartOfDay] ;
        model.TenantId = this.activityLogTabInput.tenantId;
        await this.loadActivityLogGrid(model);
    }

    showLoader(){
        this.isShowLoader = true;
    }

    hideLoader(){
        this.isShowLoader = false;
    }

    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }

    closeToasterPopup() {
        this.isToasterVisible = false;
    }

    close(){
        this.$emit("close-modal");
    }
}
</script>
<style scoped>

.neu-input__select-icon {
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
    top: 2.125rem;
    right: 20px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.meditechEnabledIcon {
    background-color: green;
    color: white;
    height: 13px;
    width: 13px;
    margin: 0 0 5px 5px;
    border-radius: 6px;
}

.text-right{
    text-align: right;
}

.spanCircleRed {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
}

.spanCircleGreen {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: green;
    display: inline-block;
}

.activityLogTbl{
    max-height: 300px;
}
</style>