export class Permissions {
    public static readonly ASSIGNMENT_VIEW_PLANNINGPHASE = "Assignment - View 1 - BEFORE Self-Scheduling Phase";
    
    public static readonly ASSIGNMENT_VIEW_SELFSCHEDULINGPHASE = "Assignment - View 2 - DURING Self-Scheduling Phase";
    
    public static readonly ASSIGNMENT_VIEW_BALANCINGPHASE = "Assignment - View 3 - AFTER Self-Scheduling Phase";
    
    public static readonly ASSIGNMENT_VIEW_APPROVALPHASE = "Assignment - View 4 - AFTER First Approval";
    
    public static readonly ASSIGNMENT_VIEW_POSTEDPHASE = "Assignment - View 5 - AFTER Posted";
    
    public static readonly ASSIGNMENT_VIEW_ARCHIVEDPHASE = "Assignment - View 5 - AFTER Posted";
    
    public static readonly ASSIGNMENT_CHANGE_PLANNINGPHASE = "Assignment - Change 1 - BEFORE Self-Scheduling Phase";
    
    public static readonly ASSIGNMENT_CHANGE_SELFSCHEDULINGPHASE = "Assignment - Change 2 - DURING Self-Scheduling Phase";
    
    public static readonly ASSIGNMENT_CHANGE_BALANCINGPHASE = "Assignment - Change 3 - AFTER Self-Scheduling Phase";
    
    public static readonly ASSIGNMENT_CHANGE_APPROVALPHASE = "Assignment - Change 4 - AFTER First Approval";
    
    public static readonly ASSIGNMENT_CHANGE_POSTEDPHASE = "Assignment - Change 5 - AFTER Posted";

    public static readonly  ASSIGNMENT_MUST_REQUEST_SHIFT = "Assignment - Enter Must Request Shift";

    public static readonly SCHEDULE_FIRST_APPROVAL = "Schedule - First Approval";

    public static readonly SCHEDULE_POST = "Schedule - Post";

    public static readonly SCHEDULE_OPTIMIZATION_ALGO_RUN = "Schedule - Optimization Algorithm Run";
}